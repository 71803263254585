import { GET_CURRENT_PAGE_URL } from "../actions/types";
import { produce } from "immer";
const state = {
    url: "",
};

function CurrentPageReducer(mState = { ...state }, action) {
    switch (action.type) {
        case GET_CURRENT_PAGE_URL:
            return produce(mState, (draftState) => {
                draftState.url = action.payload;
            });
        default:
            return { ...mState };
    }
}

export default CurrentPageReducer;
