export const checkPlan = (type, name, payments) => {
    if (type === "number") {
        let maxCount = 1;
        payments?.forEach((plan) => {
            plan.plan.plan_features.forEach((plan_feature) => {
                if (plan_feature.name === name) {
                    if (plan_feature.quantity > maxCount) {
                        maxCount = plan_feature.quantity;
                    }

                }
            })
        });
        return maxCount;
    }
    else if (type === "sum") {
        // if (name = "No. of RFQs" && payments.length === 0) {
        //     return -1;
        // }
        let sum = 1;
        payments?.forEach((plan) => {
            plan.plan.user_plan_features
                .forEach((plan_feature) => {
                    if (plan_feature.name === name) {
                        sum = sum + parseInt(plan_feature.quantity);
                    }
                })
        });
        return sum;
    }
    else if (type === "bool payment") {
        // console.log('payments', payments);
        let isAllowed = false;
        payments?.forEach((plan) => {
            if (plan.plan.title === "Restricted Plan" || plan.plan.title === "Full Fledge Plan" || plan.plan.title === "Profile Custom Plan") {
                isAllowed = true;
            }
        });
        return isAllowed;
    }
    else {
        let isAllowed = false;
        if (payments) {
            payments?.forEach((plan) => {
                if (plan.plan.plan_features) {
                    plan.plan.plan_features.forEach((plan_feature) => {
                        if (plan_feature.name === name) {
                            isAllowed = true;
                        }
                    });
                }
            });
        }
        return isAllowed;
    }

};