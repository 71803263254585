import { BiLockAlt } from "react-icons/bi";
import logo from "../../../../assets/images/logo.png";
import { Buttonlink, Loader } from "components";
import { Component } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { seo } from "../../../../helpers";
import { toast, ToastContainer } from "react-toastify";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import axios from "axios";

class Resetpswd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      token: "",
      loading: false,
      change_password: false,
      res_from_token: "",
      new_password: "",
      redirect: "/login",
      confirm_password: "",
      pswdType: true,
      cnfrmPswdType: true,
      disabled: true
    };
  }

  componentDidMount() {
    seo({
      title: "Reset Password | Sourcing Genie",
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.email.length > 3 && prevState.disabled) {
      this.setState({
        disabled: false
      })
    }
  }

  handleInputChange = event => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
      disabled: false
    });
  };

  notify = (message = "", type = "error") => {
    // Dismiss any existing toasts before showing a new one
    toast.dismiss();
    if (type === "error") {
      return toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        closeOnClick: true,
      });
    }
    toast.success(message, { position: "top-right" });
  };



  handleSubmit = e => {
    e.preventDefault();
    const { email, new_password, confirm_password } = this.state;
    if (this.props.type === "mail") {
      if (email !== "") {
        this.setState({ disabled: true })
        var pattern = new RegExp(
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!pattern.test(email)) {
          setTimeout(() => {
            this.setState({ disabled: true, email: "" })
          })
          return this.notify("Please enter valid email address", "error");
        }
      }
      if (email.length < 1) {
        return this.notify("Email address is required", "error");
      }
      this.setState({ loading: true });
      axios
        .post(`${process.env.REACT_APP_MAIN_URL}/api/forget/password`, {
          email: email,
          device_token: "token",
        })
        .then(res => {
          console.log(res);
          if (!res.data.status) {
            this.props.navigate("/email-send-notification");
          } else {
            this.setState({ loading: false, disabled: true });
            return this.notify(res.data.message, "error");
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      if (new_password.length < 1) {
        return this.notify("New password is required", "error");
      }
      if (confirm_password.length < 1) {
        return this.notify("New password is required", "error");
      }
      this.setState({ loading: true });
      axios
        .post(`${process.env.REACT_APP_MAIN_URL}/api/reset/password`, {
          new_password: new_password,
          confirm_password: confirm_password,
          token: this.props.params.token,
        })
        .then(res => {
          console.log(res);
          if (res.data.status) {
            this.props.navigate("/login");
          } else {
            this.setState({ loading: false });
            return this.notify(res.data.message, "error");
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  showPswd = () => {
    this.setState({ pswdType: !this.state.pswdType });
  };
  showCnfrmPswd = () => {
    this.setState({ cnfrmPswdType: !this.state.cnfrmPswdType });
  };

  render() {
    const { loading, disabled } = this.state;
    // console.log("disabled",disabled)
    return (
      <div className="min-h-full container px-4 sm:px-6 lg:px-8">
        <ToastContainer />
        {this.props.type === "mail" ? (
          <div className="flex items-center flex-grow min-h-[100vh]">
            <div className="md:grid md:grid-cols-12 flex-grow">
              <div className="intro-y col-span-6 mb-10 md:mb-0 flex flex-col justify-center items-center relative">
                <div className="absolute left-0 md:top-[-100px] top-0 pt-10 md:pt-0">
                  <Buttonlink
                    text="Back"
                    prefix={<IoIosArrowBack size={16} />}
                    onClick={() => this.props.navigate(-1)}
                    className="bg-gray-300 hover:opacity-80 fs-13 flex items-center justify-center gap-x-1 h-9 rounded-full darkGray w-24 border-0 shadow-none"
                  />
                </div>
                <img
                  src={logo}
                  alt="logo"
                  className="w-72 object-contain mx-auto md:mt-0 mt-[100px]"
                />
                <h2 className="mt-14 text-center lg:text-6xl text-3xl Medium text-[#5C0632] font-bold italic">
                  Reset Password
                </h2>
              </div>
              <form className="bg-white p-10 rounded-xl intro-y col-span-5">
                <div className="space-y-6">
                  <div className="intro-x">
                    <input
                      id="email-address"
                      name="email"
                      onChange={this.handleInputChange}
                      type="email"
                      placeholder="email"
                      autoComplete="email"
                      required
                      className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-200 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                    />
                  </div>
                  {loading ? (
                    <Loader spinner={true} />
                  ) : (
                    <Buttonlink
                      disabled={disabled}
                      onClick={this.handleSubmit}
                      text="Password Reset"
                      title="Password Reset"
                      rel="nofollow"
                      prefix={
                        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                          <BiLockAlt
                            className="h-5 w-5 white"
                            aria-hidden="true"
                          />
                        </span>
                      }
                      className={`${disabled ? "bg--gray hover:bg--gray" : "bg--primary hover:bg--primary"} relative intro-x w-full hover:white hover:opacity-80 flex justify-center py-2.5 px-4 border border-transparent rounded-lg text-white  focus:outline-none`}
                    />
                  )}
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div className="flex items-center flex-grow min-h-[100vh]">
            <div className="md:grid md:grid-cols-12 flex-grow">
              <div className="intro-y col-span-6 mb-10 md:mb-0 flex flex-col justify-center items-center relative">
                <div className="absolute left-0 md:top-[-100px] top-0 pt-10 md:pt-0">
                  <Buttonlink
                    text="Back"
                    prefix={<IoIosArrowBack size={16} />}
                    onClick={() => this.props.navigate(-1)}
                    className="bg-gray-300 hover:opacity-80 fs-13 flex items-center justify-center gap-x-1 h-9 rounded-full darkGray w-24 border-0 shadow-none"
                  />
                </div>
                <div className="intro-y">
                  <img
                    src={logo}
                    alt="logo"
                    className="w-72 object-contain mx-auto md:mt-0 mt-[100px]"
                  />
                  <h2 className="mt-14 text-center lg:text-6xl text-3xl Medium text-[#5C0632] font-bold italic">
                    Set New Password
                  </h2>
                </div>
              </div>
              <form className="bg-white p-10 rounded-xl intro-y col-span-5">
                <div className="space-y-6">
                  <div className="intro-x">
                    <div className="flex items-center relative">
                      <input
                        id="new_password"
                        name="new_password"
                        onChange={this.handleInputChange}
                        type={this.state.pswdType ? "password" : "text"}
                        placeholder="New password"
                        required
                        className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-200 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                      />
                      <div
                        onClick={this.showPswd}
                        className="cursor-pointer h-12 w-12 flex items-center justify-center absolute right-0"
                      >
                        {this.state.pswdType ? (
                          <BsEye size={20} />
                        ) : (
                          <BsEyeSlash size={20} />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="intro-x">
                    <div className="flex items-center relative">
                      <input
                        id="confirm_password"
                        name="confirm_password"
                        onChange={this.handleInputChange}
                        type={this.state.cnfrmPswdType ? "password" : "text"}
                        placeholder="Confirm password"
                        required
                        className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-200 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                      />
                      <div
                        onClick={this.showCnfrmPswd}
                        className="cursor-pointer h-12 w-12 flex items-center justify-center absolute right-0"
                      >
                        {this.state.cnfrmPswdType ? (
                          <BsEye size={20} />
                        ) : (
                          <BsEyeSlash size={20} />
                        )}
                      </div>
                    </div>
                  </div>
                  {loading ? (
                    <Loader spinner={true} />
                  ) : (
                    <Buttonlink
                      onClick={this.handleSubmit}
                      text="Change my password"
                      title="Change my password"
                      rel="nofollow"
                      prefix={
                        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                          <BiLockAlt
                            className="h-5 w-5 white"
                            aria-hidden="true"
                          />
                        </span>
                      }
                      className="relative intro-x w-full hover:white hover:opacity-80 flex justify-center py-2.5 px-4 border border-transparent rounded-lg text-white bg--primary hover:bg--primary focus:outline-none "
                    />
                  )}
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Resetpswd;
