import { gql } from "@apollo/client";

export default gql`
  query ($first: Int!, $page: Int!, $key: String) {
    quick_search_suppliers(first: $first, page: $page, key: $key) {
      data {
        id
        username
        firstname
        lastname
        profile_photo
        state{
          id
          name
        }
        country{
          id
          name
        }
      }
      paginatorInfo {
        hasMorePages
        total
        perPage
        lastItem
        currentPage
      }
    }
  }
`;
