import { gql } from "@apollo/client";

export default gql`
    mutation addCompanyExecutives($request_by:Int!,$alert_id:Int!,$company_title:String!){
        addCompanyExecutives(
            alert_id:$alert_id,
            request_by:$request_by,
            company_title:$company_title
        ){
            error
            message
        }
    }
`;
