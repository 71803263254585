import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import { AiFillCloseCircle } from "react-icons/ai";
import { FcDocument } from "react-icons/fc";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./styles.css";
import { FiLink } from "react-icons/fi";
import { connect } from "react-redux";
import client from "graphql/client";
import axios from "axios";
import createOfferAttachment from "graphql/mutations/createOfferAttachmentMutation";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserSearch from "../../../../graphql/queries/searchUsers";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import sendEmailAndAlertsToInvitedUsers from "graphql/mutations/sendEmailAndAlertsToInvitedUsers";
import Lightbox from "react-image-lightbox";
import { Buttonlink } from "components";
import Cookies from "js-cookie";

let mentionss = [];

class VideOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: "",
      imagePreviewUrl: props?.data?.attachments ? true : "",
      imageType: props?.data?.attachments ?? "",
      images: [],
    };
    this.photoRef = React.createRef();
    this.images = {
      "3ds": require("../../../../assets/icon/3ds.png"),
      aac: require("../../../../assets/icon/aac.png"),
      ai: require("../../../../assets/icon/ai.png"),
      avi: require("../../../../assets/icon/avi.png"),
      bmp: require("../../../../assets/icon/bmp.png"),
      cad: require("../../../../assets/icon/cad.png"),
      cdr: require("../../../../assets/icon/cdr.png"),
      css: require("../../../../assets/icon/css.png"),
      dat: require("../../../../assets/icon/dat.png"),
      dll: require("../../../../assets/icon/dll.png"),
      dmg: require("../../../../assets/icon/dmg.png"),
      doc: require("../../../../assets/icon/doc.png"),
      eps: require("../../../../assets/icon/eps.png"),
      fla: require("../../../../assets/icon/fla.png"),
      flv: require("../../../../assets/icon/flv.png"),
      gif: require("../../../../assets/icon/gif.png"),
      html: require("../../../../assets/icon/html.png"),
      indd: require("../../../../assets/icon/indd.png"),
      iso: require("../../../../assets/icon/iso.png"),
      jpg: require("../../../../assets/icon/jpg.png"),
      jpeg: require("../../../../assets/icon/jpeg.png"),
      js: require("../../../../assets/icon/js.png"),
      midi: require("../../../../assets/icon/midi.png"),
      mov: require("../../../../assets/icon/mov.png"),
      mp3: require("../../../../assets/icon/mp3.png"),
      mpg: require("../../../../assets/icon/mpg.png"),
      pdf: require("../../../../assets/icon/pdf.png"),
      php: require("../../../../assets/icon/php.png"),
      png: require("../../../../assets/icon/png.png"),
      ppt: require("../../../../assets/icon/ppt.png"),
      pptx: require("../../../../assets/icon/pptx.png"),
      ps: require("../../../../assets/icon/ps.png"),
      psd: require("../../../../assets/icon/psd.png"),
      raw: require("../../../../assets/icon/raw.png"),
      sql: require("../../../../assets/icon/sql.png"),
      svg: require("../../../../assets/icon/svg.png"),
      tif: require("../../../../assets/icon/tif.png"),
      txt: require("../../../../assets/icon/txt.png"),
      text: require("../../../../assets/icon/txt.png"),
      wmv: require("../../../../assets/icon/wmv.png"),
      xls: require("../../../../assets/icon/xls.png"),
      xml: require("../../../../assets/icon/xml.png"),
      zip: require("../../../../assets/icon/zip.png"),
      unkown: require("../../../../assets/icon/unkown.png"),
      csv: require("../../../../assets/icon/csv.png"),
    };
  }
  componentDidMount() {
    if (this.props.data?.files?.length > 0) {
      const paths = this.props.data.files.map(file => file.path);

      this.setState(prevState => ({
        images: [...prevState.images, ...paths]
      }));
    }
  }
  imageUploadDelete = () => {
    this.setState({
      imagePreviewUrl: "",
      image: "",
      imageType: "",
    });
    this.photoRef.current.value = null;
  };

  formatBytes = async (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  imageUpload = async (e) => {
    let _this = this;
    const { createdOffer } = this.props;
    const reader = new FileReader();
    const file = e.target.files[0];
    const size = e.target.files[0].size;
    if (this.state.images?.length > 4) {
      this.notify("You can't add more than 5 files", "error");
      return;
    }
    const zipMimeTypes = [
      "application/zip",
      "application/x-zip-compressed",
      "multipart/x-zip",
      "application/x-zip"
    ];

    if (zipMimeTypes.includes(file.type)) {
      this.notify("You can't add a zip file", "error");
      return;
    }
    if (size > 10e6) {
      this.notify("Please upload a file smaller than 10 MB!", "error");
      return;
    }
    reader.onload = () => {
      this.setState({
        vidoe: file,
        imagePreviewUrl: reader.result,
        imageType: file.type.split("/"),
      });
    };

    reader.readAsDataURL(file);
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("offer_id", createdOffer)
    const Url = process.env.REACT_APP_MAIN_URL + "/api/offer/upload";
    const token = Cookies.get("GRAPH_MRO_USER_AUTH_TOKEN");

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "Authorization": `Bearer ${token}`,  // Add the Bearer token to the headers
      },
    };

    //axios call for uploading image
    axios
      .post(Url, formData, config)
      .then(function (response) {
        if (response && response.data) {
          this.setState((prevState) => ({
            images: [...prevState.images, response.data.path]
          }));
        }
      }.bind(this))
      .catch(function (error) { });
  };

  notify = (message = "", type = "error") => {
    // Dismiss any existing toasts before showing a new one
    toast.dismiss();
    if (type === "error") {
      return toast.error(message, { position: "top-right" });
    }
    toast.success(message, { position: "top-right" });
  };
  handleDeleteImage = (image, index) => {
    let formData = new FormData();
    formData.append("path", image);
    formData.append("offer_id", this.props.createdOffer)
    const Url = process.env.REACT_APP_MAIN_URL + "/api/offer/delete-image";
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    //axios call for uploading image
    axios
      .post(Url, formData, config)
      .then(function (response) {
        if (response && response.data) {
          this.setState((prevState) => ({
            images: prevState.images.filter((_, i) => i !== index)
          }));
        }
      }.bind(this))
      .catch(function (error) { });
  }
  render() {
    let image = "unkown";
    if (
      this.images[
      this.state.imageType[0] && this.state.imageType[0]?.type
      ]
    ) {
      image = this.state.imageType[0]?.type;
    }

    return (
      <div className="AttachmentsOption toolbar-icon">

        <div className="flex flex-row space-x-4">
          {this.state.images.map((image, index) => (
            <div key={index} className="relative">
              <FcDocument className="h-24 w-24" />
              <button
                onClick={() => this.handleDeleteImage(image, index)}
                className="absolute top-[-3px] left-1 bg-red-500 text-white px-2 py-1 text-xs rounded-full hover:bg-red-700"
              >
                X
              </button>
              <p className="fs-13 pt-2 text-black">{image?.split("/")[3]?.slice(0, 15) + "..."}</p>
            </div>
          ))}

        </div>
        {/* {this.state.imageType[0] === "image" ? (
          <div className="VideoAttachments relative h-24 w-24">
            <AiFillCloseCircle
              className="VideoAttachmentsIcon dakGray cursor-pointer"
              onClick={() => this.imageUploadDelete()}
            />
            <div className="h-24 w-24 box overflow-hidden rounded-xl">
              <img
                className="h-24 w-24 object-cover"
                controls
                autoPlay
                alt=""
                src={this.state.imagePreviewUrl}
              />
            </div>
          </div>
        ) : this.state.imageType[0] === "video" ? (
          <div className="VideoAttachments relative h-24 w-24">
            <AiFillCloseCircle
              className="VideoAttachmentsIcon dakGray cursor-pointer"
              onClick={() => this.imageUploadDelete()}
            />
            <div className="h-24 w-24 box overflow-hidden rounded-xl">
              <video
                className="h-24 w-24"
                controls
                autoPlay
                src={this.state.imagePreviewUrl}
              />
            </div>
          </div>
        ) : this.state.imageType[0] === "application" ? (
          <div className="VideoAttachments relative h-24 w-24">
            <AiFillCloseCircle
              className="VideoAttachmentsIcon dakGray cursor-pointer"
              onClick={() => this.imageUploadDelete()}
            />
            <div className="h-24 w-24 box overflow-hidden rounded-xl">
              <FcDocument className="h-24 w-24" />
            </div>
          </div>
        ) : this.state.imageType[0]?.mime === "image" ? (

          <div className="overflow-hidden bg-gray-200 rounded-lg sm:w-20 w-30 h-30">
            <div className="flex items-center justify-center w-full h-full">
              <div className="flex flex-col items-center">
                <img
                  className="object-cover object-center cursor-pointer sm:w-20 w-30 h-30"
                  src={`${process.env.REACT_APP_MAIN_URL}/${this.state.imageType[0]?.url}`}
                  alt={this.state.imageType[0]?.title}
                //onClick={() => this.setState({ isOpen: true })}
                />

                {this.state.isOpen && (
                  <Lightbox
                    mainSrc={`${process.env.REACT_APP_MAIN_URL}/${this.state.imageType[0]?.url}`}
                    //onCloseRequest={() => this.setState({ isOpen: false })}
                    enableZoom={false}
                  />
                )}
              </div>
            </div>
          </div>
        ) : this.state.imageType[0]?.mime === "application" ?
          (<div className="bg-gray-200 rounded-lg sm:w-30 w-40 h-35">
            <div className="flex items-center justify-center w-full h-full">
              <div className="flex flex-col items-center">
                <img
                  className="object-contain"
                  src={this.images[image]}
                  style={{ height: 60, width: 60 }}
                  alt=""
                />
                {
                  <Buttonlink
                    href={`${process.env.REACT_APP_MAIN_URL}/${this.state.imageType[0]?.url}`}
                    text={"Download"}
                    className="px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white hover:white block mt-4"
                  />
                }
              </div>
            </div>
          </div>)
          : this.state.imageType[0]?.mime === "video" ?
            (<div className="overflow-hidden bg-gray-200 rounded-lg sm:w-40 w-30 h-30">
              <div className="flex items-center justify-center w-full h-full">
                <div className="flex flex-col items-center">
                  <video
                    className="sm:w-60 w-40 h-40"
                    controls
                    autoPlay
                    src={`${process.env.REACT_APP_MAIN_URL}/${this.state.imageType[0]?.url}`}
                  />
                </div>
              </div>
            </div>)
            : this.state.imageType[0] === "csv" || this.state.imageType[0] == 'text' || this.state.imageType[1] == 'xlsx' ?
              (<div className="overflow-hidden bg-gray-200 rounded-lg sm:w-40 w-30 h-30">
                <div className="flex items-center justify-center w-full h-full">
                  <div className="flex flex-col items-center">
                    <img
                      className="object-contain"
                      src={this.images[this.state.imageType[1]]}
                      style={{ height: 60, width: 60 }}
                      alt=""
                    />
                  </div>
                </div>
              </div>) : null
        } */}
        <label className={`${this.state.images.length === 0 ? '' : 'mt-3'} bg-gray-100 hover:bg-gray-200 px-4 primary flex items-center justify-center py-2.5 rounded-xl absolute fs-13`}>
          <FiLink className="text-color mr-1" />
          Add attachments
          <input
            className="cursor-pointer opacity-0 h-full w-full absolute"
            onChange={(e) => this.imageUpload(e)}
            // value={this.props.data.attachment}
            type="file"
            ref={this.photoRef}
            size="60"
          />
        </label>
      </div >
    );
  }
}

function uploadImageCallBack(file) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
    xhr.open("POST", "https://api.imgur.com/3/image");
    xhr.setRequestHeader("Authorization", "Client-ID 8d26ccd12712fca");
    const data = new FormData(); // eslint-disable-line no-undef
    data.append("image", file);
    xhr.send(data);
    xhr.addEventListener("load", () => {
      const response = JSON.parse(xhr.responseText);
      resolve(response);
    });
    xhr.addEventListener("error", () => {
      const error = JSON.parse(xhr.responseText);
      reject(error);
    });
  });
}

class TextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question: "",
      editorState: EditorState.createEmpty(),
      suggestions: [],
      allUsers: [],
      myArray: [],
    };
  }

  handleMentionedUsers = () => {
    this.props.sendMailToMentionedUsers(mentionss);
  };

  async componentDidMount() {
    try {
      const result = await client.query({
        query: UserSearch,
        variables: { q: "A" },
      });

      const { data } = result.data.UserSearch;
      const newArray = data.reduce((arr, user) => {
        arr.push({
          text: user.username,
          value: user.username,
          url: user.username,
        });
        return arr;
      }, []);

      const allUsers = data.map((user) => ({
        mention: `@${user.username}`,
      }));

      const { data: propsData } = this.props;
      if (propsData && Object.keys(propsData).length !== 0) {
        this.addTags("tags", propsData.tags);
        this.setState({
          question: propsData.description,
          editorState: EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(propsData.description)
            )
          ),
        });
      }

      this.setState({
        suggestions: newArray,
        allUsers: allUsers,
      });
    } catch (error) {
      console.log("error", error);
    }
  }

  addTags = (key, value) => {
    let form = Object.assign({}, this.props.fields);

    form[key] = value;

    // console.log("tags form", form);
    this.props.addRfqFields(form);
    this.props.selectTags(form);
  };
  addFields = (key, value) => {
    let form = Object.assign({}, this.props.fields);
    form[key] = value;
    this.props.addRfqFields(form);
  };

  onEditorStateChange = (value) => {
    this.setState(
      {
        editorState: value,
        question: draftToHtml(convertToRaw(value.getCurrentContent())),
      },
      () => {
        this.addFields("description", this.state.question);
        this.handleMentionedUsers();
      }
    );
  };

  render() {
    //console.log("this.props.dataaaaaaaaaa", this.props.data)
    const placeholder =
      "1. Type your question text  \n2. Add tags - Search and select tags for better reach \n3. Add members - Search and select members from Procurement League community";

    // console.log("MY ARRAY", mentionss);

    // {
    //   this.state.question &&
    //     this.state.question.split(" ").map((text) => {
    //       // console.log("text", text);
    //
    //       const test1 = this.state.allUsers.some((el) =>
    //         text.includes(el.mention)
    //       );
    //       // console.log("test1", test1);
    //
    //       if (test1) {
    //         let v = mentionss.includes(text);
    //         if (v) {
    //           // console.log("already exist");
    //         } else {
    //           mentionss.push(text);
    //         }
    //       }
    //     });
    // }

    // {
    //   this.state.question.split(" ").map((text) => {
    //     if (text === "@") {
    //       client
    //         .query({
    //           query: UserSearch,
    //           variables: { q: this.state.question },
    //         })
    //         .then((result) => {
    //           console.log("result", result);
    //           // this.setState({
    //           //   suggestions: [
    //           //     ...this.state.suggestions,
    //           //     { text: "HONEYDEW", value: "shoneydew", url: "honeydews" },
    //           //   ],
    //           // });
    //         })
    //         .catch((error) => {
    //           console.log("error", error);
    //         });
    //     }
    //   });
    // }
    const { question, editorState } = this.state;
    // console.log("question", question);
    return (
      <Editor
        toolbarCustomButtons={[
          <VideOption {...this.props} addFields={this.addFields} />,
          // <AttachmentsOption />,
        ]}
        editorState={editorState}
        onEditorStateChange={this.onEditorStateChange}
        placeholder={placeholder}
        wrapperClassName="wrapper-class p-0 rounded--xl flex flex-col-reverse"
        editorClassName="editor-class pl-3 pr-3"
        toolbarClassName="toolbar-class Light p-2 mt-auto"
        toolbar={{
          options: ["inline", "remove"],
          inline: {
            options: ["bold", "italic", "underline"],
            bold: { className: "editor-buttons" },
            italic: { className: "editor-buttons" },
            underline: { className: "editor-buttons" },
          },
          remove: { className: "editor-buttons " },
          image: {
            className: "toolbar-icon",
            popupClassName: "toolbar-popUp",
            urlEnabled: false,
            uploadEnabled: true,
            alignmentEnabled: true,
            uploadCallback: uploadImageCallBack,
            previewImage: true,
            inputAccept: "image/gif,image/jpeg,image/jpeg,image/png,image/svg",
            alt: { present: false, mandatory: false },
            defaultSize: {
              height: "300px",
              width: "300px",
            },
          },
        }}
        hashtag={{
          separator: " ",
          trigger: "#",
          suggestions: [
            { text: "APPLE", value: "sapple", url: "apples" },
            { text: "BANANA", value: "sbanana", url: "bananas" },
            { text: "CHERRY", value: "scherry", url: "cherrys" },
            { text: "DURIAN", value: "sdurian", url: "durians" },
            { text: "EGGFRUIT", value: "seggfruit", url: "eggfruits" },
            { text: "FIG", value: "sfig", url: "figs" },
            { text: "GRAPEFRUIT", value: "sgrapefruit", url: "grapefruits" },
            { text: "HONEYDEW", value: "shoneydew", url: "honeydews" },
          ],
        }}
        mention={{
          separator: " ",
          trigger: "@",
          suggestions: this.state.suggestions,
        }}
      />
    );
  }
}

const mapStateToProps = ({ Rfq }) => {
  return {
    form: Rfq.form,
    fields: Rfq.fields,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("store/redux/RFQRedux");
  return {
    ...ownProps,
    ...stateProps,
    addRfqForm: (data) => {
      dispatch(actions.addRfqForm(data));
    },
    addRfqFields: (data) => {
      dispatch(actions.addRfqFields(data));
    },

    clearFields: (data) => {
      dispatch(actions.clearFields(data));
    },

    selectTags: (data) => {
      dispatch(actions.selectTags(data));
    },
    // addImage: (data) => {
    //   dispatch(actions.addImage(data));
    // },
  };
};
export default connect(mapStateToProps, undefined, mergeProps)(TextEditor);
