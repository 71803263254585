import { gql } from "@apollo/client";

export default gql`
  mutation createAddress(
    $id: Int
    $name: String
    $address_1: String
    $address_2: String
    $city: String
    $state_id: Int
    $zip_code: Int
    $country_code: String
    $phone: String
    $email: String
    $addressType: Boolean
    $is_primary: Boolean
    $country_id: Int
  ) {
    createAddress(
      id: $id
      name: $name
      address_1: $address_1
      address_2: $address_2
      city: $city
      state_id: $state_id
      zip_code: $zip_code
      country_code: $country_code
      phone: $phone
      email: $email
      is_primary:$is_primary
      addressType: $addressType
      country_id:$country_id
    ) {
      id
      name
      address_1
      address_2
      email
      phone
      city
      state_id
      is_primary
      state {
        id
        name
      }
      country_code
      zip_code
    }
  }
`;
