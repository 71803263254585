import React from "react";
import { Loader } from "semantic-ui-react";
import loader from "../../assets/images/loader.gif";

export default function index(props) {
  const { spinner, className } = props;
  // className = "h-52 mb-4";
  return (
    <>
      {spinner ? (
        <Loader active inline="centered" size="small" />
      ) : (
        <div className="flex items-center justify-center flex-col">
          <img
            alt="loader"
            src={loader}
            className={className ? className : "h-52 mb-4"}
          />
        </div>
      )}
    </>
  );
}
