import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Loader } from "components";
import { NavLink } from "react-router-dom";
import { nFormatter } from "helpers";
const GET_RFQ = gql`
  query {
    statsQuery {
      rfqs
      categories
      suppliers
      buyers
      countries
      avg_duration_hours
      avg_duration_minutes
    }
  }
`;
export default function RFQstats() {
  const { loading, error, data } = useQuery(GET_RFQ);

  if (loading) return <Loader spinner={true} />;
  if (error) return `Error! ${error.message}`;
  return (
    <div className="grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 gap-4 mb-20">
      <div className="">
        <NavLink to="/rfq/0" state="0" className="zoom-in intro-y">
          <div className="box p-5 space-y-2 text-center h-40 flex flex-col justify-center">
            <p className="text-[30px] primary Medium">
              {nFormatter(data.statsQuery.rfqs)}
            </p>
            <p className="fs-16 black Medium">RFQs</p>
          </div>
        </NavLink>
      </div>
      <div className="">
        <div className="zoom-in intro-y">
          <div className="box p-5 space-y-2 text-center h-40 flex flex-col justify-center">
            <p className="text-[30px] primary Medium">
              {nFormatter(data.statsQuery.categories)}
            </p>
            <p className="fs-16 black Medium">Categories</p>
          </div>
        </div>
      </div>
      <div className="">
        <div className="zoom-in intro-y">
          <div className="box p-5 space-y-2 text-center h-40 flex flex-col justify-center">
            <p className="text-[30px] primary Medium">
              {nFormatter(data.statsQuery.suppliers)}
            </p>
            <p className="fs-16 black Medium">Suppliers</p>
          </div>
        </div>
      </div>
      <div className="">
        <div className="zoom-in intro-y">
          <div className="box p-5 space-y-2 text-center h-40 flex flex-col justify-center">
            <p className="text-[30px] primary Medium">
              {nFormatter(data.statsQuery.buyers)}
            </p>
            <p className="fs-16 black Medium">Buyers</p>
          </div>
        </div>
      </div>
      <div className="">
        <div className="zoom-in intro-y">
          <div className="box p-5 space-y-2 text-center h-40 flex flex-col justify-center">
            <p className="text-[30px] primary Medium">
              {nFormatter(data.statsQuery.countries)}
            </p>
            <p className="fs-16 black Medium">Countries</p>
          </div>
        </div>
      </div>
    </div>
  );
}
