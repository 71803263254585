import {
  ALL_CATEGORIES,
  SEARCHED_RFQS,
  SELECTED_CATEGORIES,
  SELECTED_TAGS,
  SET_PRIMARY_ADDRESS,
  ALL_BUSINESS_ADDRESS,
  PREFERRED_VENDORS,
  REMOVED_VENDOR,
} from "./types";
import store from "../index";

class SelectedRFQsAction {
  constructor() {
    this.allCategories = (categories) => {
      store.dispatch({
        type: ALL_CATEGORIES,
        payload: categories,
      });
    };

    this.selectCategory = (selectedCategories) => {
      store.dispatch({
        type: SELECTED_CATEGORIES,
        payload: selectedCategories,
      });
    };

    this.selectTags = (selectedTags) => {
      store.dispatch({
        type: SELECTED_TAGS,
        payload: selectedTags,
      });
    };

    this.searchedRfqs = (searchedRfqs) => {
      store.dispatch({
        type: SEARCHED_RFQS,
        payload: searchedRfqs,
      });
    };
    this.allBusinessAddresses = (data) => {
      store.dispatch({
        type: ALL_BUSINESS_ADDRESS,
        payload: data,
      });
    };
    this.setPrimaryAddress = (id) => {
      store.dispatch({
        type: SET_PRIMARY_ADDRESS,
        payload: id,
      });
    };

    this.prefferedVendors = (data) => {
      store.dispatch({
        type: PREFERRED_VENDORS,
        payload: data,
      });
    };
    this.removedVendor = (id) => {
      store.dispatch({
        type: REMOVED_VENDOR,
        payload: id,
      });
    };
  }
}
const MySelectedRFQsAction = new SelectedRFQsAction();
export default MySelectedRFQsAction;
