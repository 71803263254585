import { gql } from "@apollo/client";

export default gql`
  mutation offerParticipant($id: Int, $status: Int, $product_id: Int ) {
    offerParticipant(id: $id, status: $status, product_id: $product_id) {
      id
      isRevision
      blocked
      approved
    }
  }
`;
