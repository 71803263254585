import { gql } from "@apollo/client";

export default gql`
  mutation ($contact_ids: [Int]) {
    createUserContact(contact_ids: $contact_ids) {
      id
      firstname
      lastname
      username
      email
      profile_photo
      large_photo: profile_photo(size: "large")
      small_photo: profile_photo(size: "small")
    }
  }
`;
