export const extractValidationErrors = (error) => {
    try {
        const graphQLErrors = error?.graphQLErrors;

        if (graphQLErrors && graphQLErrors[0]?.extensions?.category === "validation") {
            const validationErrors = graphQLErrors[0]?.extensions?.validation || {};

            return Object.values(validationErrors).map((errors) => errors[0]);
        }

    } catch (e) {
        console.log("validation errors not found: ", e);
    }

    return [];
};