import { gql } from "@apollo/client";

export default gql`
  mutation generalOfferPermission(
    $user_id: Int
    $offer_id: Int
    $is_pending: Boolean
    $is_permitted: Boolean
  ) {
    generalOfferPermission(
      user_id: $user_id
      offer_id: $offer_id
      is_pending: $is_pending
      is_permitted: $is_permitted
    ) {
      user_id
      offer_id
      is_pending
      is_permitted
    }
  }
`;
