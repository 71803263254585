export default function displayError(error){
    let validationErrors = [];
    try {
        if (error) {
            let { graphQLErrors } = error;

            if (graphQLErrors[0].extensions.category === "validation") {
                validationErrors = graphQLErrors[0].extensions.validation;
                let errorMessage = [];
                for (var key in validationErrors) {
                    var value = validationErrors[key];
                    errorMessage.push(value[0]);
                }
                return errorMessage;
            }else if(graphQLErrors[0].extensions.category === 'graphql'){
                validationErrors = graphQLErrors[0].message;
                return validationErrors
            }
        }

    } catch (e) {
        console.log("displayError",e)
    }
}