import { gql } from "@apollo/client";

export default gql`
  query ($cursor: Int, $me: Boolean) {
    alerts(first: 10, me: $me, page: $cursor) {
      data {
        id
        message
        created_at
        is_pending
        alert_model_type
        read_at
        sender {
          id
          firstname
          lastname
          profile_photo
        }
        offer {
          id
          title
          isPending
        }
      }
      paginatorInfo {
        total
        firstItem
        lastItem
        lastPage
        currentPage
        hasMorePages
      }
    }
  }
`;
