import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import {
  Home,
  SignInScreen,
  RegisterScreen,
  RFQdetailScreen,
  SeeAllViewsScreen,
  AllRFQScreen,
  CategoriesScreen,
  NewRFQScreen,
  AnalyticScreen,
  ProfileScreen,
  PreferredVendorScreen,
  RfqLedgerScreen,
  EditProfileScreen,
  LeaderScreen,
  RecommendationScreen,
  Pagenotfound,
  ResetpswdScreen,
  BookmarksScreen,
  NotificationScreen,
  AskQuotationScreen,
  DetailScreen,
  ChangePswdScreen,
  ContactScreen,
  PricingScreen,
  PaymentScreen,
  RFQquoteScreen,
  HelpSearchScreen,
  DeactivateScreen,
  PrivacyPolicyScreen,
  DisclaimerScreen,
  TermsServiceScreen,
  DoBuying,
  MyTeamsScreen,
  LearnMore,
  MyWorkSpace,
  DoBuyingAbout,
  Aggregate,
} from "./navigation";
import ActivationEmail from "./components/ActivationEmail";
import ScrollToTop from "react-scroll-to-top";
import Auth from "./Auth";
import { connect } from "react-redux";
import { AUTH_USER_MRO } from "./config/constants";
import {
  Addressdialog,
  ChatBot,
  Footer,
  Header,
  ImportUserDialog,
  Subheader,
} from "./components";

const auth = new Auth();

function PrivateRoute({ children }) {
  return auth.isAuthenticated() ? children : <Navigate to="/login" />;
}

const CustomRoutes = (props) => {
  const HasAddress = React.createRef();

  function BuyerRoute({ children }) {
    let newObject = localStorage.getItem(AUTH_USER_MRO);
    let authUser = props.authUser.firstname
      ? props.authUser
      : JSON.parse(newObject);
    return !authUser.is_seller ? children : <Navigate to="/" />;
  }

  function SellerRoute({ children }) {
    let newObject = localStorage.getItem(AUTH_USER_MRO);
    let authUser = props.authUser.firstname
      ? props.authUser
      : JSON.parse(newObject);
    return authUser.is_seller ? children : <Navigate to="/" />;
  }

  function UserLoggedIn({ children }) {
    return auth.isAuthenticated() ? <Navigate to="/" /> : children;
  }

  // function openAddress() {
  //   setTimeout(() => HasAddress.current.openPopup(), 2000);
  // }

  return (
    <BrowserRouter>
      {auth.isAuthenticated() ? (
        <div>
          <Header />
          <Subheader />
        </div>
      ) : (
        <>
          <NavLink
            to=""
            className={({ isActive }) => (isActive ? "" : "hidden plus")}
          ></NavLink>
          <Header />
        </>
      )}
      <div className={`flex-grow ${auth.isAuthenticated() ? "pb-10" : ""}`}>
        <Routes>
          <Route
            exact
            path="/email-send-notification"
            element={<ActivationEmail />}
          />
          <Route
            exact
            path="/activation/:token"
            element={<ActivationEmail />}
          />
          <Route exact path="/" element={<Home />} />
          <Route exact path="/auto-login/:token/:role/:id" element={<Home />} />
          <Route
            exact
            path="/login"
            element={
              <UserLoggedIn>
                <SignInScreen />
              </UserLoggedIn>
            }
          />
          <Route
            exact
            path="/reset-password"
            element={
              <UserLoggedIn>
                <ResetpswdScreen type={"mail"} />
              </UserLoggedIn>
            }
          />
          <Route
            exact
            path="/reset/password/:token"
            element={<ResetpswdScreen type={"token"} />}
          />
          <Route
            exact
            path="/registration"
            element={
              <UserLoggedIn>
                <RegisterScreen />
              </UserLoggedIn>
            }
          />
          <Route exact path="/dobuying" element={<DoBuying />} />
          <Route exact path="/learnmore" element={<LearnMore />} />
          <Route
            exact
            path="/workspace"
            element={
              <PrivateRoute>
                <BuyerRoute>
                  <MyWorkSpace authUser={props?.authUser} />
                </BuyerRoute>
              </PrivateRoute>
            }
          />
          <Route exact path="/dobuying-about" element={<DoBuyingAbout />} />
          <Route exact path="/aggregate/:offer_id" element={<Aggregate />} />
          <Route exact path="/rfq/:id/:details" element={<RFQdetailScreen />} />

          <Route
            exact
            path="/see-all-views"
            element={
              <PrivateRoute>
                <SeeAllViewsScreen />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/submit-rfq-quote"
            element={
              <PrivateRoute>
                <SellerRoute>
                  <RFQquoteScreen />
                </SellerRoute>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/rfq/:current_card"
            element={
              <PrivateRoute>
                <AllRFQScreen authUser={props?.authUser} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/categories"
            element={
              <PrivateRoute>
                <CategoriesScreen />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/post-rfq/:option"
            element={
              <PrivateRoute>
                <BuyerRoute>
                  <NewRFQScreen />
                </BuyerRoute>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/analytics"
            element={
              <PrivateRoute>
                <AnalyticScreen is_seller={props?.authUser?.is_seller} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/:id/profile"
            element={
              <PrivateRoute>
                <ProfileScreen />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/preferred-vendor"
            element={
              <PrivateRoute>
                <BuyerRoute>
                  <PreferredVendorScreen />
                </BuyerRoute>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/my-teams"
            element={
              <PrivateRoute>
                <BuyerRoute>
                  <MyTeamsScreen />
                </BuyerRoute>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/rfq-ledger"
            element={
              <PrivateRoute>
                <RfqLedgerScreen />
              </PrivateRoute>
            }
          />
          {/*<Route
                        exact
                        path="/analytics"
                                element={
                                <PrivateRoute>
                                    <BuyerRoute>
                                        <ProfileScreen/>
                                    </BuyerRoute>
                                </PrivateRoute>
                            }
                    />*/}
          <Route
            exact
            path="/profile/:edit_profile"
            element={
              <PrivateRoute>
                <EditProfileScreen />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/change-password"
            element={
              <PrivateRoute>
                <ChangePswdScreen />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/leaders"
            element={
              <PrivateRoute>
                <LeaderScreen is_seller={props?.authUser?.is_seller} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/recommendation"
            element={
              <PrivateRoute>
                <BuyerRoute>
                  <RecommendationScreen />
                </BuyerRoute>
              </PrivateRoute>
            }
          />
          <Route path="*" element={<Pagenotfound />} />
          <Route
            exact
            path="/bookmarks"
            element={
              <PrivateRoute>
                <BookmarksScreen />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/notifications"
            element={
              <PrivateRoute>
                <NotificationScreen />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/ask-quotation"
            element={
              <PrivateRoute>
                <BuyerRoute>
                  <AskQuotationScreen />
                </BuyerRoute>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/details"
            element={
              <PrivateRoute>
                <DetailScreen />
              </PrivateRoute>
            }
          />
          <Route exact path="/contact-us" element={<ContactScreen />} />
          <Route
            exact
            path="/pricing/:name"
            element={
              <PrivateRoute>
                <PricingScreen />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/payment"
            element={
              <PrivateRoute>
                <PaymentScreen authUserEmail={props?.authUser?.email} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/quick-help-detail"
            element={<HelpSearchScreen />}
          />
          <Route
            exact
            path="/deactivate-account"
            element={
              <PrivateRoute>
                <DeactivateScreen />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/privacy-policy"
            element={<PrivacyPolicyScreen />}
          />
          <Route exact path="/disclaimer" element={<DisclaimerScreen />} />
          <Route
            exact
            path="/term-of-service"
            element={<TermsServiceScreen />}
          />
        </Routes>
      </div>
      {auth.isAuthenticated() && props?.authUser?.id ? (
        <Footer />
      ) : (
        <>
          <NavLink
            to=""
            className={({ isActive }) => (isActive ? "" : "hidden plus")}
          ></NavLink>
          <Footer />
        </>
      )}
      <ScrollToTop
        smooth
        top={300}
        style={{ padding: 6, backgroundColor: "#fff" }}
        color="black"
      />
      {/*<PricingPopup ref={pricingPopups} />*/}
      {auth.isAuthenticated() && (
        <>
          <ChatBot />
          {/* {props?.authUser &&
            props?.authUser?.is_seller == 1 &&
            props?.authUser?.isSellerProfileCompleted == false &&
            openAddress()}
          <Addressdialog
            ref={HasAddress}
            user_id={props?.authUser?.id}
            location={"route"}
          /> */}
          {props?.authUser &&
            props?.authUser?.is_seller == 1 &&
            props?.authUser?.isSellerProfileCompleted == true &&
            props?.authUser?.company_linkedin_profile !== null && (
              <ImportUserDialog location={"route"} open={true} />
            )}
        </>
      )}
    </BrowserRouter>
  );
};

const mapStateToProps = ({ Auth }) => {
  return {
    authUser: Auth.authUserInformation,
  };
};

export default connect(mapStateToProps, null)(CustomRoutes);
