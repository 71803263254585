import { NewRFQ } from "..";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import MyCurrentPageAction from "../../store/actions/MyCurrentPageAction";
import { useEffect } from "react";

export default function NewRFQScreen() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  document.title = "NewRFQ | SourcingGenie";
  //
  useEffect(() => {
    MyCurrentPageAction.currentPageUrl("post-rfq");
    document.querySelector(".create_rfq").classList.add("hidden");
    return () => {
      document.querySelector(".create_rfq").classList.remove("hidden");
    };
  }, []);

  return (
    <NewRFQ
      // menu={
      //   state?.menu
      //     ? state?.menu
      //     : {
      //         id: "3",
      //         code: "send_mro_category",
      //         name: "Send RFQ for Item/Category",
      //         parent: 1,
      //         __typename: "Menu",
      //       }
      // }
      data={state && state.data}
      executive={state && state.executive}
      editable={state && state.editable}
      navigate={navigate}
      params={params}
      inPage={false}
    />
  );
}
