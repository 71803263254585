import React, { Component } from "react";
import { HiOutlineSearch } from "react-icons/hi";
import { Avatarimage } from "components";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { Loader, Noconnection } from "components";
import { Query } from "@apollo/client/react/components";
import searchUsers from "../../../graphql/queries/searchUsers";
import { connect } from "react-redux";
import LoadMoreData from "../../../components/loadMoreData";

class AddMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchName: "",
      loadMoreQuestions: true,
    };
  }

  handleClicked = (item) => {
    let selectedMembers = [...(this.props.members || [])];

    if (selectedMembers.length >= 5) {
      let found = selectedMembers.find((data) => data.id === item.id);
      if (!found) {
        // Do not add the item if the limit of 5 members is already reached
        return;
      }
      selectedMembers = selectedMembers.filter((data) => data.id !== item.id);
    } else {
      let found = selectedMembers.find((data) => data.id === item.id);
      if (!found) {
        selectedMembers.push(item);
      } else {
        selectedMembers = selectedMembers.filter((data) => data.id !== item.id);
      }
    }
    selectedMembers = selectedMembers.filter(
      (member) => member.id !== this.props.authUser?.id
    );
    this.props.selectMembers(selectedMembers);
  };

  loadMoreRFQ = (data, fetchMore, refetch) => {
    this.setState({
      loading: true,
    });
    fetchMore({
      variables: {
        cursor: data.UserSearch.paginatorInfo.currentPage + 1,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newQuestions = fetchMoreResult.UserSearch.data;
        const pageInfo = fetchMoreResult.UserSearch.paginatorInfo;
        if (data.UserSearch.paginatorInfo.total !== pageInfo.total) {
          refetch();
        }
        if (pageInfo.hasMorePages) {
          this.setState({
            loadMoreQuestions: true,
            loading: false,
          });
        } else {
          this.setState({
            loadMoreQuestions: false,
            loading: false,
          });
        }

        return newQuestions.length
          ? {
              UserSearch: {
                __typename: previousResult.UserSearch.__typename,
                data: [...previousResult.UserSearch.data, ...newQuestions],
                paginatorInfo: pageInfo,
              },
            }
          : previousResult;
      },
    });
  };

  render() {
    return (
      <div className="relative p-6">
        <div className="bg--lightGray rounded-xl flex items-center h-10 px-3">
          <HiOutlineSearch className="darkGray" />
          <input
            placeholder="Enter to search and select"
            className="bg-transparent h-full w-full pl-3"
            value={this.state.searchName}
            onChange={(e) =>
              this.setState({
                searchName: e.target.value,
              })
            }
          />
        </div>
        <div className="divider w-full my-3" />
        <div className="-mx-4">
          <Query
            query={searchUsers}
            variables={{
              q: this.state.searchName,
              is_buyer: true,
              notIn: [this.props.authUser?.id],
            }}
          >
            {({ loading, error, data, fetchMore, refetch }) => {
              if (loading) return <Loader />;

              if (error) return <Noconnection />;

              if (data) {
                return (
                  <>
                    {data.UserSearch &&
                      data.UserSearch.data &&
                      data.UserSearch.data.map((member) => (
                        <ListItem
                          disablePadding
                          className="rounded-lg overflow-hidden"
                        >
                          <ListItemButton dense>
                            <ListItemText>
                              <div className="flex">
                                <Avatarimage
                                  imagesrc={member.profile_photo}
                                  firstname={member.firstname}
                                  imagesize={{ width: 42, height: 42 }}
                                  className="rounded-full mr-2 shrink-0"
                                />
                                <div>
                                  <div className="black Medium fs-14">
                                    {`${member.firstname} ${member.lastname}`}
                                  </div>
                                  <div className="darkGray fs-11 Regular">
                                    {`@${member.username}`}
                                  </div>
                                </div>
                              </div>
                            </ListItemText>
                            <ListItemIcon
                              onClick={() => this.handleClicked(member)}
                              className="min-w-auto"
                            >
                              <Checkbox
                                checked={
                                  this.props.members &&
                                  this.props.members.find(
                                    (data) => data.id === member.id
                                  )
                                    ? true
                                    : false
                                }
                                className="primary-checkbox"
                              />
                            </ListItemIcon>
                          </ListItemButton>
                        </ListItem>
                      ))}
                    <div className="text-center mt-5">
                      {this.state.loadMoreQuestions &&
                        this.state.searchName.length < 1 && (
                          <LoadMoreData
                            title="Load More Users"
                            data={data}
                            fetchMore={fetchMore}
                            refetch={refetch}
                            callback={this.loadMoreRFQ}
                            loading={this.state.loading}
                          />
                        )}
                    </div>
                  </>
                );
              }
            }}
          </Query>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ Rfq, Auth }) => {
  return {
    members: Rfq.members,
    authUser: Auth.authUserInformation,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("store/redux/RFQRedux");
  return {
    ...ownProps,
    ...stateProps,

    selectMembers: (data) => {
      dispatch(actions.selectMembers(data));
    },
  };
};
export default connect(mapStateToProps, undefined, mergeProps)(AddMembers);
