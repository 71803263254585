import React, { Component } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { CgCloseO } from "react-icons/cg";
import {
  Loader,
  Noconnection,
  Nocontent,
  RFQcard,
  Buttonlink,
  Fullscreenpopup,
} from "components";
import { Link } from "react-router-dom";
import GET_OFFERS from "graphql/queries/getOffers";
import { Query } from "@apollo/client/react/components";
import { connect } from "react-redux";
import { IoIosCloseCircle } from "react-icons/io";
import MyCurrentPageAction from "../../../store/actions/MyCurrentPageAction";

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: "",
      loadMoreQuestions: false,
      modalType: "",
    };
    this.SelectFullPopup = React.createRef();
  }

  componentDidMount() {
    MyCurrentPageAction.currentPageUrl("categories");
    this.props.addRfqForm({});
    this.props.selectCategories([]);
    this.props.selectTags({});
    this.props.addRfqFields({ tags: [] });
  }

  choseCategory = () => {
    this.SelectFullPopup.current.openPopup();
    this.setState({ modalType: "Choose Category" });
  };
  choseTags = () => {
    this.SelectFullPopup.current.openPopup();
    this.setState({ modalType: "Tags" });
  };
  render() {
    const orderBy = [{ field: "updated_at", order: "DESC" }]
    let variables = { orderBy };

    variables.me = false;
    variables.is_public = true;
    let categories = [];

    if (
      this.props.categories.category &&
      this.props.categories.category.length > 0
    ) {
      this.props.categories.category.map((item, key) => {
        categories.push(parseInt(item.id));
      });
    }

    if (categories.length > 0) {
      variables.category = categories;
    } else if (
      this.props.tagsList.tags &&
      this.props.tagsList.tags.length > 0
    ) {
      let tag = [];
      this.props.tagsList.tags.map((t) => {
        tag.push(t.id);
      });

      variables.tags = tag;
    }
    console.log("THISLL", this.props);
    return (
      <div className="container mt-10">
        <div className="mb-5">
          <div className="flex items-center gap-x-2 mb-5">
            <Link to="/" className="primary fs-13">
              Home
            </Link>
            <IoIosArrowForward className="text-gray-400" />
            <p className=" text-gray-600 fs-13">Categories</p>
          </div>
          <h2 className="text-3xl SemiBold">Search Other Users RFQ's</h2>
        </div>
        <div className="grid xl:grid-cols-3 grid-cols-0 gap-4">
          <div className="w-full sm:max-w-xl max-w-full mx-auto">
            <div
              className={`box overflow-hidden mb-5 p-5 xl:sticky xl:top-3  ${this.props.selectedCategory > 0 ? "space-y-5" : "space-y-5"
                }`}
            >
              <div className="flex justify-between items-center">
                <h3 className="text-xl SemiBold">Categories:</h3>
                <Buttonlink
                  onClick={this.choseCategory}
                  className="bg-gray-100 hover:bg-gray-200 px-6 primary flex items-center justify-center py-2.5 rounded-xl"
                  text="Select"
                />
              </div>
              {/* <div>
                <div className="bg--lightGray rounded-xl flex items-center h-11 px-3">
                  <HiOutlineSearch className="darkGray" />
                  <input
                    placeholder="Search"
                    className="bg-transparent h-full w-full pl-3"
                  />
                </div>
              </div> */}

              <div className="category-sidebar" id="scrollbar">
                <div className="flex items-center flex-wrap gap-x-4 selected-tags">
                  <div className="gap-2 flex items-center flex-wrap">
                    {this.props.categories?.category?.length > 0 ? (
                      this.props.categories.category.map((cat) => (
                        <div class="relative intro-x" id={cat.id}>
                          <div class="bg--lightPrimary hover:opacity-70 rounded-lg px-4 py-1.5 cursor-pointer flex items-center gap-x-2">
                            <p class="fs-13">{cat.name}</p>
                            <div>
                              <IoIosCloseCircle
                                onClick={() => {
                                  let updatedCat =
                                    this.props.categories.category.filter(
                                      (data) => data.id != cat.id
                                    );

                                  let form = Object.assign({}, this.props.form);

                                  // console.log("updatedCat", updatedCat);

                                  form["category"] = updatedCat;

                                  this.props.selectCategories(form);
                                  this.props.addRfqForm(form);
                                }}
                                size={20}
                                className="primary"
                              />
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="relative intro-x">
                        <p className="fs-13">No Category Selected!</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`box overflow-hidden p-5  xl:sticky xl:top-40 ${this.props.tagsList.tags && this.props.tagsList.tags.length > 0
                ? "space-y-5"
                : ""
                }`}
            >
              <div className="flex justify-between items-center">
                <h3 className="text-xl SemiBold">Industry Tags:</h3>
                <Buttonlink
                  onClick={this.choseTags}
                  className="bg-gray-100 hover:bg-gray-200 px-6 primary flex items-center justify-center py-2.5 rounded-xl"
                  text="Select"
                />
              </div>
              <div className="flex items-center flex-wrap gap-2 selected-tags">
                {this.props.tagsList.tags &&
                  this.props.tagsList.tags.length > 0 ? (
                  this.props.tagsList.tags.map((tag) => (
                    <div className="relative intro-x">
                      <label className="border-2 rounded-full border--primary hover:opacity-70 px-4 py-1.5 cursor-pointer flex items-center gap-x-2">
                        <p className="fs-13">{tag.name}</p>
                        <CgCloseO
                          onClick={() => {
                            let updatedTags = this.props.tagsList.tags.filter(
                              (data) => data.id !== tag.id
                            );

                            let form = {};

                            form["tags"] = updatedTags;

                            this.props.selectTags(form);
                            this.props.addRfqFields(form);
                          }}
                          className="primary"
                        />
                      </label>
                    </div>
                  ))
                ) : (
                  <div className="relative intro-x">
                    <p className="fs-13">No Industry Tag Selected!</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="xl:col-span-2">
            <Query
              query={GET_OFFERS}
              variables={variables}
              fetchPolicy={"cache-and-network"}
            >
              {({ loading, data, error, fetchMore, refetch }) => {
                if (loading) {
                  return <Loader />;
                }
                if (error) {
                  return <Noconnection />;
                }

                if (data.offers.data.length <= 0) {
                  return <Nocontent text="It's quite empty out here, let's crowd it with RFQs!" />;
                }
                if (data) {
                  return (
                    <div className="grid xl:grid-cols-3 md:grid-cols-2 gap-4">
                      <RFQcard
                        publicRfqs={data}
                        home={false}
                        column="flex-col"
                        imageHeight="h-72 w-full"
                        contentSpacing="p-3 w-full"
                        onLoadMore={() => {
                          fetchMore({
                            variables: {
                              cursor: data.offers.paginatorInfo.currentPage + 1,
                            },
                            updateQuery: (
                              previousResult,
                              { fetchMoreResult }
                            ) => {
                              const newQuestions = fetchMoreResult.offers.data;
                              const pageInfo =
                                fetchMoreResult.offers.paginatorInfo;
                              if (
                                data.offers.paginatorInfo.total !==
                                pageInfo.total
                              ) {
                                refetch();
                              }

                              if (pageInfo.hasMorePages) {
                                this.setState({ loadMoreQuestions: true });
                              } else {
                                this.setState({ loadMoreQuestions: false });
                              }

                              return newQuestions.length
                                ? {
                                  offers: {
                                    __typename:
                                      previousResult.offers.__typename,
                                    data: [
                                      ...previousResult.offers.data,
                                      ...newQuestions,
                                    ],
                                    paginatorInfo: pageInfo,
                                  },
                                }
                                : previousResult;
                            },
                          });
                        }}
                      />
                    </div>
                  );
                }
              }}
            </Query>
            {this.state.loadMoreQuestions ? (
              <div className={"mt-4"}>
                <Loader />
              </div>
            ) : null}
          </div>
        </div>
        <Fullscreenpopup
          ref={this.SelectFullPopup}
          type={this.state.modalType}
          categories={this.props.categories.category}
          tags={this.props.tagsList.tags}
          clearState={(value) => console.log("value", value)}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ Rfq, RFQs }) => {
  return {
    categories: Rfq.categories,
    tagsList: Rfq.selectedTags,
    fields: Rfq.fields,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("store/redux/RFQRedux");
  return {
    ...ownProps,
    ...stateProps,

    selectTags: (data) => {
      dispatch(actions.selectTags(data));
    },
    selectCategories: (data) => {
      dispatch(actions.selectCategories(data));
    },
    addRfqForm: (data) => {
      dispatch(actions.addRfqForm(data));
    },
    addRfqFields: (data) => {
      dispatch(actions.addRfqFields(data));
    },
  };
};

export default connect(mapStateToProps, undefined, mergeProps)(Categories);
