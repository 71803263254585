import { Buttonlink, Loader } from "components";
import React, { useEffect, useState } from "react";
import ReactCodeInput from "react-code-input";
import "./styles.css";
import cookie from "js-cookie";
import { AUTH_USER_MRO, GRAPH_MRO_USER_AUTH_TOKEN } from "../../../config/constants";
import { gql } from "@apollo/client";
import Alert from "@mui/material/Alert";
import client from "../../../graphql/client";
import { toast } from "react-toastify";

function Deactivate(props) {
    const [code, setCode] = useState("");
    const [disable, setDisable] = useState(true);

    useEffect(() => {
        if (code.length === 6) {
            setDisable(false)
        } else {
            setDisable(true)
        }
    }, [code]);

    const confirm_deactivation = gql`
        mutation ($otp:String) {
            confirm_deactivation(otp:$otp) {
                status
                message
                error
            }
        }
    `;

    const sendcode = () => {
        client.mutate({
            mutation: confirm_deactivation,
            variables: {
                otp: code
            }
        }).then(res => {
            console.log(res)
            if (res.data.confirm_deactivation.status) {
                cookie.remove(GRAPH_MRO_USER_AUTH_TOKEN);
                cookie.remove(AUTH_USER_MRO);
                localStorage.removeItem(AUTH_USER_MRO);
                client.clearStore();
                window.location.href = "/";
            } else {

                notify("Wrong code entered", "error");
                setCode("");
            }

        }).catch(err => {
            console.log(err)
        })
    }
    const notify = (message = "", type = "error") => {
        // Dismiss any existing toasts before showing a new one
        toast.dismiss();
        if (type === "error") {
            return toast.error(message, { position: "top-right" });
        }
        toast.success(message, { position: "top-right" });
    };
    return (
        <>
            <div className="container my-20 space-y-20 ">
                <div className="md:grid md:grid-cols-6 gap-4">
                    <div className="md:col-start-2 md:col-span-4 space-y-10">
                        <div className="report-box">
                            <div className="intro-y box p-5 overflow-hidden">
                                <h4 className="text-2xl Medium text-center text-red-500">
                                    Account Deactivation
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="md:grid md:grid-cols-6 gap-4">
                    <div className="md:col-start-2 md:col-span-4 space-y-10">
                        <div className="report-box">
                            <div className="intro-y box p-10 overflow-hidden space-y-5">
                                <div className="lg:grid lg:grid-cols-6 gap-4">
                                    <div className="lg:col-start-2 lg:col-span-4 space-y-5">
                                        <div className="intro-y">
                                            <label htmlFor="password" className="Regular">
                                                Enter Code
                                            </label>
                                            <ReactCodeInput
                                                type="text"
                                                fields={6}
                                                inputMode={'full-width-latin'}
                                                value={code}
                                                onChange={(e) => setCode(e)} />
                                        </div>
                                        <div className="intro-y">
                                            <Buttonlink
                                                disabled={disable}
                                                onClick={sendcode}
                                                className={`${disable ? "bg--gray" : "bg--primary"} Regular text-white hover:text-white hover:opacity-80 flex-1 grow w-full flex items-center justify-center h-11 rounded-xl`}
                                                text={"Submit code"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DeactivateAccountEmail />
                    </div>
                </div>
            </div>
        </>
    );
}


function DeactivateAccountEmail(props) {
    const [request, setRequest] = useState(false);
    const [loading, setLoading] = useState(false);


    const sendEmail = () => {
        setLoading(true)

        const deactivate_mutation = gql`
        mutation{
            deactivate{
                error
                message
                status
            }
        }
    `;

        client.mutate({
            mutation: deactivate_mutation,
        }).then(res => {
            // console.log(res)
            if (res.data.deactivate.status) {
                setRequest(true)
                setLoading(false)
            }
        }).catch(err => {
            setLoading(false)
            console.log(err)
        })
    };

    return (
        <div className="intro-y">
            {!request ? loading ? <Loader /> : <Buttonlink
                onClick={sendEmail}
                className="bg--primary Regular text-white hover:text-white hover:opacity-80 flex-1 grow w-full flex items-center justify-center h-11 rounded-xl"
                text={"Send Me The Code"}
            /> :
                <Alert severity="info">Account deactivation code is send to your registered email.</Alert>
            }
        </div>
    );
}

export default Deactivate;
