import { gql } from "@apollo/client";

export default gql`
  query ($search: String, $parent_id: Int) {
    categories_web(
      first: 50
      system: true
      search: $search
      parent_id: $parent_id
      orderBy: [{ field: "code", order: ASC }]
    ) {
      data {
        id
        name
        parent_id
        items {
          id
          name
          items {
            id
            name
            code
            parent_id
          }
        }
      }
    }
  }
`;
