import { gql } from "@apollo/client";

export default gql`
    mutation offerUserChat(
        $user_id:ID,
        $room_id:ID,
        $receiver_id:ID,
        $message:String,
        $is_buyer:Boolean
    )
    {
        offerUserChat(
            user_id: $user_id
            room_id: $room_id
            receiver_id: $receiver_id
            message: $message
            is_buyer: $is_buyer
        ) {
            id
            alerts {
                id
                is_pending
                sender {
                    id
                    firstname
                    lastname
                    profile_photo
                    username
                }
            }
            messages {
                message_id
                room_id
                user_id
                receiver_id
                is_buyer
                message
                user {
                    id
                    username
                    firstname
                    lastname
                    profile_photo
                }
                receiver {
                    id
                    username
                    firstname
                    lastname
                    profile_photo
                }
                is_seen
                created_at
            }
        }
    }
`;
