import React, { Component } from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { IoCloseCircleOutline } from "react-icons/io5";
import MoreCategories from "navigation/RfqLedgerScreen/components/morecategories";
import MoreTags from "navigation/RfqLedgerScreen/components/moretags";

class MoreCategoriesPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  openPopup = () => {
    this.setState({ open: true });
  };

  closePopup = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    return (
      <div>
        <Dialog
          fullWidth={false}
          maxWidth={"sm"}
          scroll="body"
          open={open}
          onClose={this.closePopup}
          className="rounded--xl">
          <div className="shadow-none bg-gray-100">
            <Toolbar className="flex items-center justify-between">
              <h3 className="black Medium text-xl">{this.props.type}</h3>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.closePopup}
                aria-label="close">
                <IoCloseCircleOutline size={32} className="primary" />
              </IconButton>
            </Toolbar>
          </div>
          <div className="p-6">
            {this.props.type === "More Categories" ? (
              <MoreCategories data={this.props.data} />
            ) : this.props.type === "More Tags" ? (
              <MoreTags data={this.props.tagData} />
            ) : (
              ""
            )}
          </div>
        </Dialog>
      </div>
    );
  }
}

export default MoreCategoriesPopup;
