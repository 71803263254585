import { gql } from "@apollo/client";

export default gql`
query ($search: String,$page:Int) {
  unit_of_measure_web(first:12, page:$page,search: $search) {
    data {
      id
      dimension
      unit
    }
    paginatorInfo {
      total
      lastItem
      currentPage
      hasMorePages
    }
  }
}
`;
