import React from "react";
import { Subbanner } from "components";
import Sellertabsrafqs from "./sellertabsrafqs";
import Buyertabsrafqs from "./buyertabsrafqs";

function AllRFQ({ authUser, cardId }) {
    // Determine if user is a seller
    const isSeller = authUser?.is_seller;

    // Determine the appropriate subbanner title based on user type
    const subBannerTitle = isSeller ? "Need Fast Quotes? Try RFQ!" : "Want Quick Quotes? Use RFQ!";

    // Determine which tab component to render based on user type
    const RFQTabComponent = isSeller === undefined ? null : isSeller ? <Sellertabsrafqs cardId={cardId} /> : <Buyertabsrafqs cardId={cardId} />;

    return (
        <div className="h-full">
            <Subbanner title={subBannerTitle} text="" />
            <div className="container -mt-28">
                {RFQTabComponent}
            </div>
        </div>
    );
}

export default AllRFQ;
