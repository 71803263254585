import Buttonlink from "components/buttonlink";
import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Auth from "../../../src/Auth";

const auth = new Auth();

const images = {
  "3ds": require("../../assets/icon/3ds.png"),
  aac: require("../../assets/icon/aac.png"),
  ai: require("../../assets/icon/ai.png"),
  avi: require("../../assets/icon/avi.png"),
  bmp: require("../../assets/icon/bmp.png"),
  cad: require("../../assets/icon/cad.png"),
  cdr: require("../../assets/icon/cdr.png"),
  css: require("../../assets/icon/css.png"),
  dat: require("../../assets/icon/dat.png"),
  dll: require("../../assets/icon/dll.png"),
  dmg: require("../../assets/icon/dmg.png"),
  doc: require("../../assets/icon/doc.png"),
  docx: require("../../assets/icon/doc.png"),
  eps: require("../../assets/icon/eps.png"),
  fla: require("../../assets/icon/fla.png"),
  flv: require("../../assets/icon/flv.png"),
  gif: require("../../assets/icon/gif.png"),
  html: require("../../assets/icon/html.png"),
  indd: require("../../assets/icon/indd.png"),
  iso: require("../../assets/icon/iso.png"),
  jpg: require("../../assets/icon/jpg.png"),
  jpeg: require("../../assets/icon/jpeg.png"),
  js: require("../../assets/icon/js.png"),
  midi: require("../../assets/icon/midi.png"),
  mov: require("../../assets/icon/mov.png"),
  mp3: require("../../assets/icon/mp3.png"),
  mpg: require("../../assets/icon/mpg.png"),
  pdf: require("../../assets/icon/pdf.png"),
  php: require("../../assets/icon/php.png"),
  png: require("../../assets/icon/png.png"),
  ppt: require("../../assets/icon/ppt.png"),
  pptx: require("../../assets/icon/pptx.png"),
  ps: require("../../assets/icon/ps.png"),
  psd: require("../../assets/icon/psd.png"),
  raw: require("../../assets/icon/raw.png"),
  sql: require("../../assets/icon/sql.png"),
  svg: require("../../assets/icon/svg.png"),
  tif: require("../../assets/icon/tif.png"),
  txt: require("../../assets/icon/txt.png"),
  text: require("../../assets/icon/txt.png"),
  wmv: require("../../assets/icon/wmv.png"),
  xls: require("../../assets/icon/xls.png"),
  xlsx: require("../../assets/icon/xls.png"),
  xml: require("../../assets/icon/xml.png"),
  zip: require("../../assets/icon/zip.png"),
  csv: require("../../assets/icon/unkown.png"),
  unknown: require("../../assets/icon/unkown.png"),
};

const ImageDisplay = ({ attachment, index, altText = "none", notify }) => {
  const [isOpen, setIsOpen] = useState(false);

  // Extract file extension if attachment.type is not available
  let type = attachment.type || attachment.split('.').pop().toLowerCase();
  // Check if the extracted type exists in the images, if not, set it to "unknown"
  if (!images[type]) {
    type = "unknown";
  }

  switch (type) {
    case "png":
    case "jpeg":
    case "jpg":
    case "gif":
    case "bmp":
    case "svg+xml":
      return (
        <div key={index} className="overflow-hidden py-2 rounded-lg">
          <div className="flex items-center justify-center w-full h-full">
            <div className="flex flex-col items-center">
              <img
                className="object-cover object-center cursor-pointer h-[100px] w-[200px]"
                src={process.env.REACT_APP_MAIN_URL + "/" + (attachment?.path ?? attachment)}
                alt={altText}
                onClick={() => setIsOpen(true)}
              />

              {isOpen === index ||
                (isOpen && (
                  <Lightbox
                    mainSrc={
                      process.env.REACT_APP_MAIN_URL + "/" + (attachment?.path ?? attachment)
                    }
                    onCloseRequest={() => setIsOpen(false)}
                    enableZoom={false}
                  />
                ))}
            </div>
          </div>
        </div>
      );
    case "mp4":
    case "avi":
    case "mpeg":
    case "quicktime":
    case "x-ms-wmv":
    case "x-flv":
      return (
        <div key={index} className="overflow-hidden py-2 rounded-lg">
          <div className="flex items-center justify-center w-full h-full">
            <div className="flex flex-col items-center">
              <video
                className="sm:w-96 w-80 h-52"
                controls
                autoPlay
                src={process.env.REACT_APP_MAIN_URL + "/" + (attachment?.path ?? attachment)}
              />
            </div>
          </div>
        </div>
      );
    case "application":
    case "pdf":
    case "doc":
    case "docx":
    case "xlsx":
    case "xlsb":
    case "xls":
    case "xlx":
    case "csv":
    case "msword":
    case "vnd.ms-excel":
    case "vnd.ms-powerpoint":
    case "vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case "vnd.openxmlformats-officedocument.presentationml.presentation":
    case "zip":
    case "x-rar-compressed":
      return (
        <div key={index} className="py-2 rounded-lg">
          <div className="flex items-center justify-center w-full h-full">
            <div className="flex flex-col items-center">
              {auth.isAuthenticated() ? (
                <a
                  href={process.env.REACT_APP_MAIN_URL + "/" + (attachment?.path ?? attachment)}
                  className="flex flex-col items-center"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="object-contain"
                    src={images[type]}
                    style={{ height: 80, width: 120 }}
                    alt=""
                  />
                </a>
              ) : (
                <div onClick={() => notify("Please Login First to download the attachment", "error")}>
                  <img
                    className="object-contain cursor-pointer"
                    src={images[type]}
                    style={{ height: 80, width: 120 }}
                    alt=""
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      );
    default:
      return (
        <div key={index} className="py-2 rounded-lg">
          <div className="flex items-center justify-center w-full h-full">
            <div className="flex flex-col items-center">
              {auth.isAuthenticated() ? (
                <a
                  href={process.env.REACT_APP_MAIN_URL + "/" + (attachment?.path ?? attachment)}
                  className="flex flex-col items-center"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="object-contain"
                    src={images?.unknown}
                    style={{ height: 80, width: 120 }}
                    alt="unknow"
                  />
                </a>
              ) : (
                <div onClick={() => notify("Please Login First to download the attachment", "error")}>
                  <img
                    className="object-contain cursor-pointer"
                    src={images?.unknown}
                    style={{ height: 80, width: 120 }}
                    alt=""
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      );
  }
};

export default ImageDisplay;
