import {
  Loader,
  Noconnection,
  Nocontent,
  RFQcard,
  RFQstats,
  TopRFQcard,
} from "components";
import React, { useEffect, useState } from "react";
import Homebanner from "./homebanner";
import GET_OFFERS from "graphql/queries/getOffers";
import { Query } from "@apollo/client/react/components";
import { connect } from "react-redux";
import Auth from "Auth";
import LoadMoreData from "../../../components/loadMoreData";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AUTH_USER_MRO,
  GRAPH_MRO_USER_AUTH_TOKEN,
} from "../../../config/constants";
import AUTO_LOGIN_OR_CREATE_USER from "graphql/mutations/autologin";
import cookie from "js-cookie";
import client from "../../../graphql/client";
import { toast } from "react-toastify";
import MyAuthUserAction from "../../../store/actions/MyAuthUserAction";
import MyCurrentPageAction from "../../../store/actions/MyCurrentPageAction";
import MySelectedRFQsAction from "../../../store/actions/MySelectedRFQsAction";

const auth = new Auth();

function Home(props) {
  const [loadMoreQuestions, setloadMoreQuestions] = useState(false);
  const [loading, setloading] = useState(false);
  const { token, role, id } = useParams();
  const [open, setOpen] = useState(id == 0);
  const location = useLocation();
  const navigate = useNavigate();
  let path = location.pathname.split("/");
  const notify = (message = "", type = "error") => {
    // Dismiss any existing toasts before showing a new one
    toast.dismiss();
    if (type === "error")
      return toast.error(message, { position: "top-right" });
    else return toast.success(message, { position: "top-right" });
  };

  useEffect(() => {
    document.title = "Home | SourcingGenie";
    MyCurrentPageAction.currentPageUrl("home");
    MySelectedRFQsAction.searchedRfqs("");
    if (path[1] === "auto-login") {
      cookie.remove(GRAPH_MRO_USER_AUTH_TOKEN);
      localStorage.removeItem(AUTH_USER_MRO);
      client
        .mutate({
          mutation: AUTO_LOGIN_OR_CREATE_USER,
          variables: {
            token: token,
            role: role,
            id: id,
          },
        })
        .then((response) => {
          // console.log("response", response)
          if (response?.data?.autoLoginByEmailAddress?.error) {
            let value = response?.data?.autoLoginByEmailAddress;
            navigate("/login", {
              state: { message: value.message, status: value.error },
            });
          } else {
            cookie.set(
              GRAPH_MRO_USER_AUTH_TOKEN,
              response?.data?.autoLoginByEmailAddress?.token
            );
            localStorage.setItem(
              AUTH_USER_MRO,
              JSON.stringify(response.data.autoLoginByEmailAddress.user)
            );
            if (response?.data?.autoLoginByEmailAddress?.token) {
              if (role === "seller") {
                notify("You are logged In on SG via PL account", "success");
                // notify('You are logged In on SG via PL account , To access SG account check email if you are new user', 'success');
                setTimeout(() => {
                  window.location.href =
                    process.env.REACT_APP_WEB_URL +
                    "/rfq/" +
                    response?.data?.autoLoginByEmailAddress.message;
                }, 3000);
              } else {
                MyAuthUserAction.getAuthUserInformation()
                  .then((res) => {
                    notify(
                      `You are logged In on SG via PL account , ${open ? "To access SG account check email" : ""
                      }`,
                      "success"
                    );
                    navigate("/");
                  })
                  .catch((error) => {
                    console.log("asdf", error);
                  });
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.notify("oops something went wrong", "error");
        });
    }
    // console.log("pageeeee",props.showCurrentPage)
  }, []);

  const loadMoreRFQ = (data, fetchMore, refetch) => {
    setloading(true);
    fetchMore({
      variables: {
        cursor: data.offers.paginatorInfo.currentPage + 1,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newQuestions = fetchMoreResult.offers?.data;
        const pageInfo = fetchMoreResult.offers.paginatorInfo;
        if (data.offers.paginatorInfo.total !== pageInfo.total) {
          refetch();
        }
        if (pageInfo.hasMorePages) {
          setloadMoreQuestions(true);
          setloading(false);
        } else {
          setloadMoreQuestions(false);
          setloading(false);
        }

        return newQuestions.length
          ? {
            offers: {
              __typename: previousResult.offers.__typename,
              data: [...previousResult.offers.data, ...newQuestions],
              paginatorInfo: pageInfo,
            },
          }
          : previousResult;
      },
    });
  };

  const orderBy = [{ field: "updated_at", order: "DESC" }];
  let variables = { orderBy };

  // if (auth.isAuthenticated()) {
  //   variables.me = false;
  //   variables.awarded = false;
  //   variables.interest = true;
  // } else {
  //   variables.awarded = false;
  //   variables.status = "publish";
  // }

  variables.visibility = "1";
  variables.is_public = true;

  if (props.searchText !== "" || props.searchText !== null) {
    variables.search = props.searchText;
  }
  return (
    <div className={`h-full ${auth.isAuthenticated() ? "" : "pb-10"}`}>
      {props.authUser && (
        <Homebanner is_seller={props.authUser.is_seller} open={open} />
      )}
      <div className="container xl:-mt-10 mt-10">
        {props.searchText?.length === 0 && props.authUser && <RFQstats />}
        {props.searchText?.length === 0 && props.authUser && (
          <TopRFQcard is_seller={props.authUser.is_seller} />
        )}
        <div className="mt-20">
          <div className="mb-5 intro-y">
            <h2 className="text-3xl Medium">You may be interested in</h2>
            <p className="Light darkGray">
              Effortlessly create a RFQ with the templates below!
            </p>
          </div>
          <Query
            query={GET_OFFERS}
            variables={variables}
            fetchPolicy={"cache-and-network"}
          >
            {({ loading, data, error, fetchMore, refetch }) => {
              if (loading && props.authUser === undefined) {
                return <Loader />;
              }
              if (error) {
                return <Noconnection />;
              }

              if (data?.offers?.data?.length === 0) {
                return <Nocontent />;
              }
              if (!loading && data?.offers?.paginatorInfo?.hasMorePages) {
                setloadMoreQuestions(true);
              } else {
                if (!error) {
                  setloadMoreQuestions(false);
                }
              }
              return (
                <>
                  <div className="grid xl:grid-cols-3 md:grid-cols-2 gap-4">
                    <RFQcard
                      publicRfqs={data}
                      home={true}
                      imageHeight="h-32 w-32"
                      cardSpacing="p-5"
                      imageRounded="rounded-xl"
                      cardBorder="border"
                    />
                  </div>
                  <div className="text-center mt-5">
                    {loadMoreQuestions && (
                      <LoadMoreData
                        title="Load More RFQ"
                        data={data}
                        fetchMore={fetchMore}
                        refetch={refetch}
                        callback={loadMoreRFQ}
                        loading={loading}
                      />
                    )}
                  </div>
                </>
              );
            }}
          </Query>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ Auth, RFQs, Rfq, Page }) => {
  return {
    authUser: Auth.authUserInformation,
    searchText: RFQs.searchedRfqs,
    recallRef: Rfq.recallRef,
    showCurrentPage: Page.url,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("store/redux/RFQRedux");
  return {
    ...ownProps,
    ...stateProps,
    rfqPublishorUnpublished: (data) => {
      dispatch(actions.rfqPublishorUnpublished(data));
    },
  };
};

export default connect(mapStateToProps, undefined, mergeProps)(Home);
