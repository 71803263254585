import React, {useEffect, useState} from "react";
import { Buttonlink } from "components";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import { IoCloseCircleOutline } from "react-icons/io5";
import Toolbar from "@mui/material/Toolbar";


import { gql, useMutation } from '@apollo/client';

const SET_OFFER_AGGREGATION = gql`
  mutation setOfferAggregation($offer_id:ID!,$is_aggregated:Boolean){
    setOfferAggregation(id:$offer_id, is_aggregated:$is_aggregated){
      id
      is_aggregated
    }
  }
`;

const Alertpopup = (props) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if(props.found){
      setOpen(true)
    }
  }, []);

  const openPopup = () => {
    setOpen(true);
  };

  const closePopup = () => {
    setOpen(false);
  };

  const [udpateOfferAggregation, { error }] = useMutation(SET_OFFER_AGGREGATION,{
    onCompleted(data) {
      closePopup()
    }
  });
  let offer_id = parseInt(props.rfqDetail.id)
  return (
      <div>
        <Dialog
            fullWidth={true}
            maxWidth="xs"
            open={open}
            onClose={closePopup}
            id="scrollbar"
        >
          <div className="shadow-none bg-gray-100" sx={{ position: "relative" }}>
            <Toolbar className="flex items-center justify-between">
              <h3 className="black Medium text-xl">Start Using Aggregate</h3>

              <IconButton
                  edge="start"
                  color="inherit"
                  onClick={closePopup}
                  aria-label="close"
                  className="rounded--lg hover:bg--gray"
              >
                <IoCloseCircleOutline size={32} className="primary" />
              </IconButton>
            </Toolbar>
          </div>
          <div className="p-5">
            <p>"If you're interested in utilizing the <b>Sourcing Genie Aggregate service</b>, we can assist you in obtaining your desired <b>product or item</b> from the seller. Alternatively, you have the option to handle it independently."</p>
          </div>
          <div className="divider mt-6" />
          <div className="p-3 flex items-center justify-end gap-x-3">
            <Buttonlink
                onClick={()=> udpateOfferAggregation({ variables: { offer_id:offer_id, is_aggregated:false } })}
                text="Cancel"
                title="Cancel"
                className="w-32 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover: bg--lightGray hover:opacity-70 hover:black gap-x-3 flex items-center justify-center bg--lightGray"
            />
            <Buttonlink
                onClick={()=> udpateOfferAggregation({ variables: { offer_id: offer_id, is_aggregated:true } })}
                text="Agree"
                title="Agree"
                className="w-32 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:bg--primary hover:opacity-70 bg--primary white hover:white gap-x-3 flex items-center justify-center"
            />
          </div>
        </Dialog>
      </div>
  );
};

export default Alertpopup;