import {GET_CURRENT_PAGE_URL} from "./types";
import store from "../index";

class CurrentPage {
    constructor() {
        this.currentPageUrl = (route) => {
            return new Promise((resolve, reject) => {
                store.dispatch({
                    type: GET_CURRENT_PAGE_URL,
                    payload: route,
                });
            });
        };
    }
}

const MyCurrentPageAction = new CurrentPage();
export default MyCurrentPageAction;