const types = {
  FETCH_COLLECTION_PENDING: "FETCH_COLLECTION_PENDING",
  FETCH_COLLECTION_SUCCESS: "FETCH_COLLECTION_SUCCESS",
  ADD_COLLECTION_FORM: "ADD_COLLECTION_FORM",
  ADD_RFQ_FORM: "ADD_RFQ_FORM",
  REMOVE_RFQ_FORM: "REMOVE_RFQ_FORM",
  ADD_QUOT_ID: "ADD_QUOT_ID",
  ADD_RFQ_FIELDS: "ADD_RFQ_FIELDS",
  REMOVE_RFQ_FIELDS: "REMOVE_RFQ_FIELDS",
  SELECT_TAGS: "SELECT_TAGS",
  ADD_MEMBERS: "ADD_MEMBERS",
  SELECT_STATE: "SELECT_STATE",
  SELECT_CATEGORIES: "SELECT_CATEGORIES",
  RFQ_SELECTED_FILTER: "RFQ_SELECTED_FILTER",
  RFQ_PUBLISH_OR_UNPUBLISH: "RFQ_PUBLISH_OR_UNPUBLISH",
};

export const actions = {
  fetchCollections: async (dispatch, per_page, page) => {
    dispatch({ type: types.FETCH_COLLECTION_PENDING });
  },
  addRfqForm: (data) => ({
    type: types.ADD_RFQ_FORM,
    data,
  }),
  clearForm: () => ({
    type: types.REMOVE_RFQ_FORM,
  }),
  addRfqFields: (data) => ({
    type: types.ADD_RFQ_FIELDS,
    data,
  }),
  addQuotId: (data) => ({
    type: types.ADD_QUOT_ID,
    data,
  }),
  clearFields: () => ({
    type: types.REMOVE_RFQ_FIELDS,
  }),
  selectTags: (data) => ({
    type: types.SELECT_TAGS,
    data,
  }),
  selectMembers: (data) => ({
    type: types.ADD_MEMBERS,
    data,
  }),
  selectState: (data) => ({
    type: types.SELECT_STATE,
    data,
  }),
  selectCategories: (data) => ({
    type: types.SELECT_CATEGORIES,
    data,
  }),
  rfqSelectedFilter: (data) => ({
    type: types.RFQ_SELECTED_FILTER,
    data,
  }),
  rfqPublishorUnpublished: (data) => ({
    type: types.RFQ_PUBLISH_OR_UNPUBLISH,
    data,
  }),
};

const initialState = {
  isFetching: false,
  isProcessing: false,
  data: [],
  error: null,
  page: 1,
  total: 0,
  form: {},
  fields: {},
  menu: null,
  categories: [],
  selectedTags: {},
  members: [],
  _state: {},
  selectedFilter: "",
  recallRef: false,
};

export const reducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case types.ADD_RFQ_FORM: {
      return {
        ...state,
        form: data,
      };
    }
    case types.REMOVE_RFQ_FORM: {
      return {
        ...state,
        form: {},
      };
    }

    case types.ADD_RFQ_FIELDS: {
      return {
        ...state,
        fields: data,
      };
    }
    case types.ADD_QUOT_ID: {
      return {
        ...state,
        menu: data,
      };
    }

    case types.REMOVE_RFQ_FIELDS: {
      return {
        ...state,
        fields: {},
      };
    }

    case types.SELECT_TAGS: {
      return {
        ...state,
        selectedTags: data,
      };
    }

    case types.ADD_MEMBERS: {
      return {
        ...state,
        members: data,
      };
    }
    case types.SELECT_STATE: {
      return {
        ...state,
        _state: data,
      };
    }
    case types.SELECT_CATEGORIES: {
      return {
        ...state,
        categories: data,
      };
    }

    case types.RFQ_SELECTED_FILTER: {
      return {
        ...state,
        selectedFilter: data,
      };
    }

    case types.RFQ_PUBLISH_OR_UNPUBLISH: {
      return {
        ...state,
        recallRef: data,
      };
    }

    default: {
      return state;
    }
  }
};
