import React from 'react'
import { Buttonlink } from 'components';
import { encryptfunction } from 'helpers';
import { VscFilePdf } from "react-icons/vsc";
import { Link } from 'react-router-dom';
import Avatarimage from 'components/avatarimage';
import {
    AiOutlineCloudDownload,
} from "react-icons/ai";

const ExecutivePermissions = ({ rfqDetail, auth_user, state, quotationApproved, openChatPopup, rejectRfqReq }) => {
    const url = process.env.REACT_APP_WEB_URL;
    const hasDev = url.includes("dev");
    let matchingExecutive = rfqDetail?.executives.find(
        (executive) => executive.id === auth_user?.id
    );

    let permissionNames = [];

    if (matchingExecutive) {
        permissionNames =
            matchingExecutive?.offer_executive_user_roles[0]?.permissions.map(
                (permission) => permission.name
            );
    }
    const doNothing = () => {

    }
    return (
        <>

            {!rfqDetail.products?.length > 0 && permissionNames.length !== 0 && !rfqDetail.isOwner ? (
                <>
                    <div className="flex justify-between mt-12">
                        <div>
                            <p className="Medium">Permissions</p>
                            <p className="gray fs-13">
                                Following is the Permissions as an Executive
                            </p>
                        </div>
                        {permissionNames.includes("download-report") && (
                            <Buttonlink
                                className="flex items-center h-10 px-6 leading-5 rounded-lg shadow bg--lightGray darkGray fs-15 Regular hover:opacity-80 gap-x-2"
                                href={`${process.env.REACT_APP_MAIN_URL
                                    }/rfq-progress/${encryptfunction(
                                        rfqDetail.id + "," + hasDev
                                    )}`}
                                target="_blank"
                                prefix={
                                    <VscFilePdf size={16} className="darkGray" />
                                }
                                text="Report"
                            />
                        )}
                    </div>

                    {rfqDetail?.participants?.data?.length > 0
                        ? rfqDetail?.participants?.data.map((item) => (
                            <div
                                key={item.id}
                                className="pb-10 my-5 mb-10 border-b"
                            >
                                <Link
                                    to={`/${encryptfunction(item.user.id)}/profile`}
                                    state={{
                                        value: "edit",
                                        id: item.user.id,
                                    }}
                                >
                                    <div className="flex items-center gap-x-3">
                                        <Avatarimage
                                            imagesrc={item.user.profile_photo}
                                            userId={item.user.id}
                                        />
                                        <h3 className="leading-5 Medium text-md">
                                            {item.user.firstname} {item.user.lastname}
                                        </h3>
                                    </div>
                                </Link>
                                <p className="items-center mt-5 Medium text-md">
                                    {item.comment}
                                </p>
                                <div className="flex">
                                    {item.document && (

                                        <Buttonlink
                                            href={`${process.env.REACT_APP_MAIN_URL}/${item.document}`}
                                            prefix={
                                                <AiOutlineCloudDownload size={22} />
                                            }
                                            text={"Download Attachment"}
                                            className="flex items-center px-6 py-2 my-3 leading-5 rounded-lg shadow gap-x-2 fs-13 Regular hover:opacity-70 bg--lightGray darkGray"
                                        />
                                    )}
                                </div>

                                <div className="flex items-center mt-2 gap-x-1">
                                    <p className="Medium primary text-md ">
                                        Quoted Amount:
                                    </p>
                                    <p className="Medium">${item.total}</p>
                                    <div>
                                        <p
                                            className={`rounded-full fs-13 ${item.readable_status === "Revision"
                                                ? "bg-orange-500"
                                                : item.readable_status === "Rejected"
                                                    ? "bg-red-500"
                                                    : item.readable_status === "Pending"
                                                        ? "bg-gray-500"
                                                        : item.readable_status === "Waiting"
                                                            ? "bg-pink-400"
                                                            : item.readable_status === "Complete"
                                                                ? "bg-green-500"
                                                                : "bg-amber-500"
                                                } text-white px-4 py-1 inline-flex`}
                                        >
                                            {item.readable_status === "Waiting"
                                                ? "Waiting For Seller Response"
                                                : item.readable_status === "Rejected"
                                                    ? "Rejected"
                                                    : item.readable_status}
                                        </p>
                                    </div>
                                </div>
                                <div className={`flex items-center justify-center ${item.action_user_id !== null ? 'mt-4' : 'mt-10'} gap-x-4`}>
                                    {permissionNames.includes("canMessage") ? (
                                        <Buttonlink
                                            onClick={() =>
                                                openChatPopup(item.user.id)
                                            }
                                            className="h-10 px-6 leading-5 rounded-lg shadow bg--lightGray darkGray fs-15 Regular hover:opacity-80"
                                            text="Message"
                                        />
                                    ) : null}
                                    {permissionNames.includes(
                                        "reject-or-approve"
                                    ) ? (
                                        item.approved == true ||
                                            item.blocked == true ? (
                                            false
                                        ) :
                                            item.action_user_id !== null ? (
                                                <div className="flex-grow self-start">
                                                    Rejection of Quotation from Seller Side
                                                    <br />
                                                    <strong>Reason:</strong> {item?.reason ?? ""}
                                                </div>
                                            ) : (
                                                <>
                                                    <Buttonlink
                                                        onClick={() => {
                                                            item.blocked
                                                                ? doNothing()
                                                                : rejectRfqReq(item.id);
                                                        }}
                                                        className={`bg--lightGray darkGray h-10 px-6 fs-15 leading-5 Regular rounded-lg shadow hover:opacity-80 ${item.isRevision || item.blocked
                                                            ? "opacity-75 cursor-default"
                                                            : ""
                                                            }`}
                                                        text="Reject"
                                                    />

                                                    <Buttonlink
                                                        disabled={
                                                            !!(
                                                                item.isRevision ||
                                                                item.blocked ||
                                                                state.offerAcceptLoader
                                                            )
                                                        }
                                                        onClick={() =>
                                                            quotationApproved(item.id)
                                                        }
                                                        text="Approve"
                                                        className={
                                                            item.isRevision || item.blocked
                                                                ? "h-10 px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow opacity-70 bg--primary white hover:text-white"
                                                                : "h-10 px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white"
                                                        }
                                                    />
                                                </>
                                            )
                                    ) : (
                                        false
                                    )}
                                </div>
                            </div>
                        ))
                        : <p className="text-center my-7">
                            No Offer found, We'll notify you as soon as you a get a new offer.
                        </p>}
                </>
            ) : rfqDetail.products?.length && !rfqDetail.isOwner > 0 ?
                <>
                    <div className='mb-5'>
                        <p className="Medium">Permissions</p>
                        <p className="gray fs-13">
                            Following is the Permissions as an Executive
                        </p>
                    </div>
                    {rfqDetail?.products?.map((product, index) => (
                        <div className={` ${index !== rfqDetail?.products?.length - 1 && 'border-b pb-5'}`} key={index}>
                            <p className='primary font-semibold text-lg pb-3'>{product.title}</p>
                            {rfqDetail?.participants?.data?.length > 0 ?
                                rfqDetail?.participants?.data?.filter(dat => dat.product_id == product.id).map((item) => {
                                    return (
                                        <>
                                            <div className="flex justify-between">
                                                {permissionNames.includes("download-report") && (
                                                    <Buttonlink
                                                        className="flex items-center h-10 px-6 leading-5 rounded-lg shadow bg--lightGray darkGray fs-15 Regular hover:opacity-80 gap-x-2"
                                                        href={`${process.env.REACT_APP_MAIN_URL
                                                            }/rfq-progress/${encryptfunction(
                                                                rfqDetail.id + "," + hasDev
                                                            )}`}
                                                        target="_blank"
                                                        prefix={
                                                            <VscFilePdf size={16} className="darkGray" />
                                                        }
                                                        text="Report"
                                                    />
                                                )}
                                            </div>
                                            <div
                                                key={item.id}
                                                className=""
                                            >
                                                <Link
                                                    to={`/${encryptfunction(item.user.id)}/profile`}
                                                    state={{
                                                        value: "edit",
                                                        id: item.user.id,
                                                    }}
                                                >
                                                    <div className="flex items-center gap-x-3">
                                                        <Avatarimage
                                                            imagesrc={item.user.profile_photo}
                                                            userId={item.user.id}
                                                        />
                                                        <h3 className="leading-5 Medium text-md">
                                                            {item.user.firstname} {item.user.lastname}
                                                        </h3>
                                                    </div>
                                                </Link>
                                                <p className="items-center mt-5 Medium text-md">
                                                    {item.comment}
                                                </p>
                                                <div className="flex">
                                                    {item.document && (

                                                        <Buttonlink
                                                            href={`${process.env.REACT_APP_MAIN_URL}/${item.document}`}
                                                            prefix={
                                                                <AiOutlineCloudDownload size={22} />
                                                            }
                                                            text={"Download Attachment"}
                                                            className="flex items-center px-6 py-2 my-3 leading-5 rounded-lg shadow gap-x-2 fs-13 Regular hover:opacity-70 bg--lightGray darkGray"
                                                        />
                                                    )}
                                                </div>

                                                <div className="flex items-center mt-2 gap-x-1">
                                                    <p className="Medium primary text-md ">
                                                        Quoted Amount:
                                                    </p>
                                                    <p className="Medium">${item.total}</p>
                                                    <div>
                                                        <p
                                                            className={`rounded-full fs-13 ${item.readable_status === "Revision"
                                                                ? "bg-orange-500"
                                                                : item.readable_status === "Rejected"
                                                                    ? "bg-red-500"
                                                                    : item.readable_status === "Pending"
                                                                        ? "bg-gray-500"
                                                                        : item.readable_status === "Waiting"
                                                                            ? "bg-pink-400"
                                                                            : item.readable_status === "Complete"
                                                                                ? "bg-green-500"
                                                                                : "bg-amber-500"
                                                                } text-white px-4 py-1 inline-flex`}
                                                        >
                                                            {item.readable_status === "Waiting"
                                                                ? "Waiting For Seller Response"
                                                                : item.readable_status === "Rejected"
                                                                    ? "Rejected"
                                                                    : item.readable_status}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className={`flex items-center justify-center ${item.action_user_id !== null ? 'mt-4' : 'mt-10'} gap-x-4`}>
                                                    {permissionNames.includes("canMessage") ? (
                                                        <Buttonlink
                                                            onClick={() =>
                                                                openChatPopup(item.user.id)
                                                            }
                                                            className="h-10 px-6 leading-5 rounded-lg shadow bg--lightGray darkGray fs-15 Regular hover:opacity-80"
                                                            text="Message"
                                                        />
                                                    ) : null}
                                                    {permissionNames.includes(
                                                        "reject-or-approve"
                                                    ) && !rfqDetail?.approvedParticipant?.some(ap => ap.product_id == product.id) ? (
                                                        item.approved == true ||
                                                            item.blocked == true ? (
                                                            false
                                                        ) :
                                                            item.action_user_id !== null ? (
                                                                <div className="flex-grow self-start">
                                                                    Rejection of Quotation from Seller Side
                                                                    <br />
                                                                    <strong>Reason:</strong> {item?.reason ?? ""}
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <Buttonlink
                                                                        onClick={() => {
                                                                            item.blocked
                                                                                ? doNothing()
                                                                                : rejectRfqReq(item.id);
                                                                        }}
                                                                        className={`bg--lightGray darkGray h-10 px-6 fs-15 leading-5 Regular rounded-lg shadow hover:opacity-80 ${item.isRevision || item.blocked
                                                                            ? "opacity-75 cursor-default"
                                                                            : ""
                                                                            }`}
                                                                        text="Reject"
                                                                    />

                                                                    <Buttonlink
                                                                        disabled={
                                                                            !!(
                                                                                item.isRevision ||
                                                                                item.blocked ||
                                                                                state.offerAcceptLoader
                                                                            )
                                                                        }
                                                                        onClick={() =>
                                                                            quotationApproved(item.id)
                                                                        }
                                                                        text="Approve"
                                                                        className={
                                                                            item.isRevision || item.blocked
                                                                                ? "h-10 px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow opacity-70 bg--primary white hover:text-white"
                                                                                : "h-10 px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white"
                                                                        }
                                                                    />
                                                                </>
                                                            )
                                                    ) : (
                                                        false
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                )
                                : <p className="text-center my-7">
                                    No Offer found, We'll notify you as soon as you a get a new offer.
                                </p>
                            }
                        </div>
                    ))}
                </>
                : null}
        </>
    )
}

export default ExecutivePermissions