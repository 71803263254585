import { gql } from "@apollo/client";

export default gql`
  mutation ($user_id: ID!) {
    updateRole(user_id: $user_id) {
      id
      is_seller
      become_seller
    }
  }
`;
