import { combineReducers } from "redux";
import AuthUserReducer from "./AuthUserReducer";
import AlertReducer from "./AlertsReducer";
import SelectedRFQsReducer from "./SelectedRFQsReducer";
import { reducer as RfqReducer } from "../redux/RFQRedux";
import CurrentPageReducer from "./CurrentPageReducer";

const appReducer = combineReducers({
  Rfq: RfqReducer,
  Auth: AuthUserReducer,
  RFQs: SelectedRFQsReducer,
  Alerts: AlertReducer,
  Page: CurrentPageReducer
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
