import { Popover } from "@headlessui/react";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { DateRange } from "react-date-range";
import { BsFillCalendarCheckFill } from "react-icons/bs";
import { FiChevronDown } from "react-icons/fi";
import { Transition } from "semantic-ui-react";

class OrderDateRange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: "",
            datePicker: [
                {
                    startDate: new Date(),
                    endDate: null,
                    key: "selection",
                },
            ],
        };
    }
    handleDateRange = (item) => {
        this.setState({
            datePicker: [item.selection],
        });
    };
    render() {
        const today = new Date();
        const maxDate = new Date();
        maxDate.setMonth(today.getMonth() + 3);
        const { datePicker } = this.state;
        var endTime = "";
        var startTime = "";
        let final = "";
        if (
            datePicker[0].startDate !== datePicker[0].endDate &&
            datePicker[0].endDate !== null
        ) {
            endTime = moment(datePicker[0].endDate).format("YYYY-MM-DD");
            startTime = moment(datePicker[0].startDate).format("YYYY-MM-DD");
            final = startTime + "," + endTime;

            this.props.onSubmit(final);
        } else {
            const today = moment().subtract(6, "month").format("YYYY-MM-DD");
            const endTime = moment().add(6, "month").format("YYYY-MM-DD");
            const final = today + "," + endTime;
            this.props.onSubmit(final);
        }

        const minDate = new Date();
        minDate.setFullYear(minDate.getFullYear() - 2);

        return (
            <Popover className="relative flex">
                <Popover.Button className="bg--primary py-1 rounded-lg xl:h-auto h-11 px-5 ">
                    <p className="white whitespace-nowrap gap-x-2 text-left fs-13 hover:primary-all-text hover:primary--text flex items-center justify-start relative">
                        <div className="w-9 h-9 flex items-center justify-center bg-white rounded-md">
                            <BsFillCalendarCheckFill
                                size={18}
                                className="primary"
                            />
                        </div>
                        {datePicker[0].startDate !== datePicker[0].endDate &&
                        datePicker[0].endDate !== null
                            ? final
                            : "Choose Date Range"}

                        <div className="flex justify-end">
                            <FiChevronDown size={18} className="white" />
                        </div>
                    </p>
                </Popover.Button>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-300"
                    enterFrom="opacity-0 translate-y-0"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-250"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-0"
                >
                    <Popover.Panel className="absolute z-10 right-0 top-3 transform mt-10 w-full md:w-screen max-w-lg sm:px-0">
                        <div className="p-6 rounded-lg shadow-lg bg-white relative ring-1 ring-black w-full ring-opacity-5 overflow-hidden">
                            <DateRange
                                editableDateInputs={true}
                                onChange={this.handleDateRange}
                                moveRangeOnFirstSelection={false}
                                ranges={datePicker}
                                className="w-full"
                                minDate={minDate}
                                maxDate={maxDate}
                                rangeColors={["#ef553b", "#ef553b"]}
                                color={"#ef553b"}
                            />
                        </div>
                    </Popover.Panel>
                </Transition>
            </Popover>
        );
    }
}

export default OrderDateRange;
