import { gql } from "@apollo/client";
export default gql`
  query offers(
    $cursor: Int
    $comment_cursor: Int
    $me: Boolean
    $is_public: Boolean
    $saved: Boolean
    $active: Boolean
    $expired: Boolean
    $work_in_progress: Boolean
    $submited: Boolean
    $approved: Int
    $general: Boolean
    $awarded: Boolean
    $latest_action: Boolean
    $most_responsed: Boolean
    $seller_most_responsed: Boolean
    $vendor_reference: Boolean
    $help_expert: Boolean
    $no_response: Boolean
    $invitation: Boolean
    $draft: Boolean
    $visibility: String
    $tags: [Int]
    $category: [Int]
    $status: String
    $search: String
    $spend: Boolean
    $byMonth: Int
    $lost: Boolean
    $created:String
    $executive_offers:String
    $interest: Boolean
  ) {
    offers(
      first: 12
      me: $me
      is_public: $is_public
      saved: $saved
      active: $active
      expired: $expired
      work_in_progress: $work_in_progress
      submited: $submited
      approved: $approved
      general: $general
      awarded: $awarded
      lost: $lost
      latest_action: $latest_action
      most_responsed: $most_responsed
      seller_most_responsed: $seller_most_responsed
      vendor_reference: $vendor_reference
      help_expert: $help_expert
      no_response: $no_response
      invitation: $invitation
      visibility: $visibility
      tags: $tags
      category: $category
      search: $search
      spend: $spend
      draft: $draft
      status: $status
      page: $cursor
      byMonth: $byMonth
      orderBy: [{ field: "updated_at", order: DESC }]
      created:$created
      executive_offers:$executive_offers
      interest:$interest
    ) {
      data {
        id
        title
        description
        last_date
        created_at
        reference_number
        quantity
        uom
        type
        is_public
        readable_status
        status
        visibility
        isLiked
        totalLikes
        isBookmarked
        isAccess
        isAuthor
        isRecommendation
        hasProducts
        productsQuantity
        menu {
          id
          code
        }
        recommendations{
          id
          title
          description
        }
        user {
          id
          username
          firstname
          lastname
          profile_photo
        }
        attachment {
          id
          url
          type
          size
        }
        tags {
          id
          name
        }
        categories {
          id
          name
          image_url
        }
        company {
          id
          title
          slug
        }
        comments(first: 2, page: $comment_cursor) {
          data {
            id
            comment_body
            created_at
          }
          paginatorInfo {
            total
            lastItem
            currentPage
            hasMorePages
          }
        }
        participants(first: 10, page: $cursor) {
          data {
            id
            user {
              id
              firstname
              lastname
              username
              profile_photo
              email
            }
            total
            created_at
            accepted
            approved
            blocked
            readable_status
            comment
            isRevision
            document
          }
          paginatorInfo {
            total
            lastItem
            currentPage
            hasMorePages
          }
        }
      }
      paginatorInfo {
        total
        lastItem
        currentPage
        hasMorePages
      }
    }
  }
`;
