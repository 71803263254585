import {
  ALL_CATEGORIES,
  SEARCHED_RFQS,
  SELECTED_CATEGORIES,
  SELECTED_TAGS,
  ALL_BUSINESS_ADDRESS,
  SET_PRIMARY_ADDRESS,
  PREFERRED_VENDORS,
  REMOVED_VENDOR,
} from "../actions/types";
import { produce } from "immer";
const state = {
  allCategories: [],
  selectedCategories: [],
  selectedTags: [],
  searchedRfqs: "",
  addresses: [],
  prefferedVendors: [],
};

function SelectedRFQsReducer(mState = { ...state }, action) {
  switch (action.type) {
    case ALL_CATEGORIES:
      return produce(mState, (draftState) => {
        draftState.allCategories = [];
        action.payload.forEach((category) => {
          draftState.allCategories.push(category);
        });
      });

    case SELECTED_CATEGORIES:
      return produce(mState, (draftState) => {
        draftState.selectedCategories = [];
        action.payload.forEach((category) => {
          draftState.selectedCategories.push(category);
        });
      });

    case SELECTED_TAGS:
      return produce(mState, (draftState) => {
        draftState.selectedTags = [];
        action.payload.forEach((tag) => {
          draftState.selectedTags.push(tag);
        });
      });

    case SEARCHED_RFQS:
      return produce(mState, (draftState) => {
        draftState.searchedRfqs = action.payload;

        // console.log("action.payload", action.payload);
      });

    case ALL_BUSINESS_ADDRESS:
      return produce(mState, (draftState) => {
        draftState.addresses = [];
        action.payload.forEach((address) => {
          draftState.addresses.push(address);
        });
      });

    case SET_PRIMARY_ADDRESS:
      return produce(mState, (draftState) => {
        // console.log("action.payload", action.payload);

        draftState.addresses.findIndex(
          (data) => parseInt(data.id) == action.payload
        );
      });

    case PREFERRED_VENDORS:
      return produce(mState, (draftState) => {
        draftState.prefferedVendors = [];
        action.payload.forEach((vendor) => {
          draftState.prefferedVendors.push(vendor);
        });
      });

    case REMOVED_VENDOR:
      return produce(mState, (draftState) => {
        let filterVendors = mState.prefferedVendors.filter(
          (vendor) => vendor.seller_id != action.payload
        );

        draftState.prefferedVendors = filterVendors;
      });

    default:
      return { ...mState };
  }
}

export default SelectedRFQsReducer;
