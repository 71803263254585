import { GET_AUTH_USER_ALERTS, DELETE_ALERT } from "../actions/types";
import { produce } from "immer";

const state = {
  alerts: [],
  paginatorInfo: {},
  deletedAlerts: [],
};

function AlertReducer(mState = { ...state }, action) {
  switch (action.type) {
    case GET_AUTH_USER_ALERTS:
      return produce(mState, draftState => {
        draftState.alerts = action.payload.data;
        draftState.paginatorInfo = action.payload.paginatorInfo;
      });
    case DELETE_ALERT:
      return produce(mState, draftState => {
        // console.log("delete", action.payload);
        // console.log("draftState.alerts", mState.alerts);
        let newCountries = mState.alerts.filter(o => o.id !== action.payload);
        // console.log("newCountries", newCountries);
        draftState.alerts = newCountries;
      });
    default:
      return { ...mState };
  }
}

/*function onDeleteObject(id) {
  const newCountries = this.state.alerts.filter(
    o => !this.state.deletedAlerts.includes(+o.id)
  );
  this.setState({ countries: newCountries });
}*/

export default AlertReducer;
