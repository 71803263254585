import { Buttonlink } from "components";
import { IoIosArrowForward } from "react-icons/io";
import React, { Component } from "react";

const data = [
  {
    id: 0,
    title: "Get quotes quickly with RFQ",
    text: "Discover popular products from featured suppliers",
    img: "https://media.istockphoto.com/photos/forklift-driver-loading-goods-pallet-into-the-truck-container-freight-picture-id1182359410?b=1&k=20&m=1182359410&s=170667a&w=0&h=FX1eY8wEJS-hwDarJT3vzpjHpzeme7XpbbHlCKJzqp4=",
  },
  {
    id: 1,
    title: "Latest action",
    text: "Source in a safer and more efficient way",
    img: "https://media.istockphoto.com/photos/asian-male-farmer-loading-freshly-harvested-goods-onto-pickup-truck-picture-id1280906902?b=1&k=20&m=1280906902&s=170667a&w=0&h=K9ovIH-STXI9hceCEP-LFM-33LOXmpzB5kIrLOxd2g4=",
  },
  {
    id: 2,
    title: "Most responded",
    text: "Meet million dollar transaction sellers with RFQ",
    img: "https://images.unsplash.com/photo-1531683760080-7bb28a630bd7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dHJ1Y2slMjBsb2FkfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
  },
];

const seller_data = [
  {
    id: 0,
    title: "General RFQ",
    text: "Discover request for proposal",
    img: "https://media.istockphoto.com/photos/forklift-driver-loading-goods-pallet-into-the-truck-container-freight-picture-id1182359410?b=1&k=20&m=1182359410&s=170667a&w=0&h=FX1eY8wEJS-hwDarJT3vzpjHpzeme7XpbbHlCKJzqp4=",
  },
  {
    id: 1,
    title: "Invitation",
    text: "Recommended Rfq's as per your selected category",
    img: "https://media.istockphoto.com/photos/asian-male-farmer-loading-freshly-harvested-goods-onto-pickup-truck-picture-id1280906902?b=1&k=20&m=1280906902&s=170667a&w=0&h=K9ovIH-STXI9hceCEP-LFM-33LOXmpzB5kIrLOxd2g4=",
  },
  {
    id: 2,
    title: "Latest action",
    text: "Source in a safer and more efficient way",
    img: "https://images.unsplash.com/photo-1531683760080-7bb28a630bd7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dHJ1Y2slMjBsb2FkfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
  },
  {
    id: 4,
    title: "Approved",
    text: "RFQ's which is approved from buyer",
    img: "https://images.unsplash.com/photo-1531683760080-7bb28a630bd7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dHJ1Y2slMjBsb2FkfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
  },
];

export default class TopRFQcard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_seller: false,
    };
  }

  componentDidMount() {
    this.is_seller(this.props.is_seller);
  }

  is_seller = (prvState) => {
    if (prvState !== this.state.is_seller) {
      this.setState({
        is_seller: prvState,
      });
    }
  };

  render() {
    return (
      <div
        className={`${
          this.props.is_seller ? "lg:grid-cols-4" : "lg:grid-cols-3"
        } grid md:grid-cols-2 lg:gap-4 gap-7 sm:px-20 md:px-0`}
      >
        {this.props.is_seller
          ? seller_data.map((items) => (
              <div
                className="report-box zoom-in intro-y flex items-stretch"
                key={items.id}
              >
                <div className="box p-5 space-y-3 overflow-hidden relative">
                  {/* overlay for whole area clickable */}
                  <Buttonlink
                    to={`/rfq/${items.id}`}
                    state={{ cardId: items.id }}
                    text={items.title}
                    title="Create Account"
                    rel="nofollow"
                    suffix={<IoIosArrowForward />}
                    className="opacity-0 absolute inset-0"
                  />
                  <Buttonlink
                    to={`/rfq/${items.id}`}
                    state={{ cardId: items.id }}
                    text={items.title}
                    title="Create Account"
                    rel="nofollow"
                    suffix={<IoIosArrowForward />}
                    className="text-left Regular fs-17 w-full hover:opacity-80 flex justify-between items-center py-2.5 px-4 border border-transparent rounded-lg primary bg--lightPrimary  focus:outline-none "
                  />
                  <p className="Light darkGray fs-14 text-ellipsis overflow-hidden sm:whitespace-nowrap">
                    {items.text}
                  </p>
                  <img
                    alt={items.title}
                    src={items.img}
                    className="h-80 object-cover w-full rounded-xl"
                  />
                </div>
              </div>
            ))
          : data.map((items) => (
              <div
                className="report-box zoom-in intro-y flex items-stretch"
                key={items.id}
              >
                <div className="box p-5 space-y-3 relative w-full">
                  {/* overlay for whole area clickable */}
                  <Buttonlink
                    to={`/rfq/${items.id}`}
                    state={{ cardId: items.id }}
                    text={items.title}
                    title="Create Account"
                    rel="nofollow"
                    suffix={<IoIosArrowForward />}
                    className="opacity-0 absolute inset-0"
                  />
                  <Buttonlink
                     to={`/rfq/${items.id}`}
                    state={{ cardId: items.id }}
                    text={items.title}
                    title="Create Account"
                    rel="nofollow"
                    suffix={<IoIosArrowForward />}
                    className="text-left Regular fs-17 w-full hover:opacity-80 flex justify-between items-center py-2.5 px-4 border border-transparent rounded-lg primary bg--lightPrimary  focus:outline-none "
                  />
                  <p className="Light darkGray fs-14">{items.text}</p>
                  <img
                    alt={items.title}
                    src={items.img}
                    className="h-80 object-cover w-full rounded-xl"
                  />
                </div>
              </div>
            ))}
      </div>
    );
  }
}
