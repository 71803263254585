import React from "react";
import {
  EmailIcon,
  EmailShareButton,
  FacebookShareButton,
  FacebookIcon,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterIcon,
  TwitterShareButton,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";

export default function SocialShare(props) {
  return (
    <div className="flex items-center justify-evenly gap-x-3">
      {/* <a href={`mailto:rajiv@procurementleague.com`}>
        <EmailIcon
          size={props.size}
          className={props.className}
          round={true}
          {...props}
        />
      </a> */}
      <EmailShareButton
        subject={props.subject}  // Email subject
        body={`Check out this RFQ: ${props.title}. ${props.url}`} // Email body content
        url={'rajiv@procurementleague.com'}  // URL to be shared via email (included in the email body)
      >
        <EmailIcon
          size={props.size}
          className={props.className}
          round={true}
          {...props}
        />
      </EmailShareButton>
      <FacebookShareButton
        quote={props.title}
        hashtag={props.hashtag}
        url={props.url}
      >
        <FacebookIcon
          size={props.size}
          className={props.className}
          round={true}
          {...props}
        />
      </FacebookShareButton>
      <WhatsappShareButton
        title={props.title}
        // separator={""}
        url={props.url}
      >
        <WhatsappIcon
          size={props.size}
          className={props.className}
          round={true}
          {...props}
        />
      </WhatsappShareButton>
      <TwitterShareButton
        title={props.title}
        via={props.via}
        hashtags={props.hashtags}
        url={props.url}
      >
        <TwitterIcon
          size={props.size}
          className={props.className}
          round={true}
          {...props}
        />
      </TwitterShareButton>

      <LinkedinShareButton
        title={props.title}
        // summary={props.summary}
        source={props.source}
        url={props.url}
      >
        <LinkedinIcon
          size={props.size}
          className={props.className}
          round={true}
          {...props}
        />
      </LinkedinShareButton>
    </div>
  );
}
