import { gql } from "@apollo/client";

export default gql`
  query ($q: String, $cursor: Int, $orderBy: [OrderByClause!]) {
    tagSearch(first: 20, page: $cursor, name: $q, orderBy: $orderBy) {
      data {
        id
        name
      }
      paginatorInfo {
        total
        perPage
        lastItem
        currentPage
        hasMorePages
      }
    }
  }
`;
