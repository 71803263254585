import { Link } from "react-router-dom";

function Nocontent({ text = "No Record Found.", to, title, noContent }) {
    return (
        <div className="flex items-center flex-col justify-center h-[150px] gap-y-5">
            <p className="items-center lg:text-4xl sm:text-3xl text-xl Medium gray text-center">
                {noContent?.text || text}
            </p>
            {(noContent?.to || to) && (noContent?.title || title) && (
                <Link to={noContent?.to || to} className="bg--primary fs-14 px-4 py-2 rounded-lg hover:opacity-80 !text-white">
                    {noContent?.title || title}
                </Link>
            )}
        </div>
    );
}

export default Nocontent;
