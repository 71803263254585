import { gql } from "@apollo/client";

export default gql`
  query offer(
    $id: ID
    $cursor: Int
    $sortField: String
    $order: String
    $product: Int
  ) {
    offer(id: $id) {
      id
      title
      description
      last_date
      reference_number
      quantity
      lost_offer
      uom
      type
      status
      isPublic
      isOwner
      isLock
      isParticipant
      visibility
      isLiked
      isWatched
      isBookmarked
      isBlocked
      isAuthor
      isApproved
      isAccepted
      isRequestSent
      isRecommendation
      offer_permission
      isPending
      isPermitted
      isAccess
      isExpired
      isRevision
      offerAnswerType
      readable_status
      totalLikes
      is_closed
      product_file_path
      platform
      offerSignature
      isAnswerRequired
      menu_id
      is_public
      executives {
        id
        firstname
        lastname
        email
        username
        profile_photo
        offer_executive_user_roles(offerId: $id) {
          id
          name
          pivot {
            offer_id
            company_id
          }
          permissions {
            name
          }
        }
      }
      alerts {
        id
        is_pending
        sender {
          id
          firstname
          lastname
          profile_photo
          username
        }
      }
      messages {
        message_id
        room_id
        user_id
        receiver_id
        is_buyer
        message
        user {
          id
          username
          firstname
          lastname
          profile_photo
        }
        receiver {
          id
          username
          firstname
          lastname
          profile_photo
        }
        is_seen
        created_at
      }
      review {
        id
        offer_id
        product_id
        seller_id
        buyer_id
        rate
        comment
        is_buyer
        responsiveness
        shipment
        lead_time
        delivered
        compliance
      }
      menu {
        id
        name
        code
        fields {
          id
          name
          code
          placeholder
          type
        }
      }
      user {
        id
        firstname
        lastname
        profile_photo
        username
        email
        primaryAddress {
          id
          address_1
          address_2
          email
          phone
          city
          state_id
          state {
            id
            name
          }
          country_code
          zip_code
        }
      }
      attachment {
        id
        url
        type
        size
      }
      attachments {
        url
        type
        mime
        size
        width
        height
      }
      files {
        id
        path
        mime
        type
      }
      questions {
        id
        question
      }
      invites {
        id
        firstname
        lastname
        email
      }
      categories {
        id
        name
        code
      }
      tags {
        id
        name
      }
      company {
        id
        title
        address
        city
        country_code
        country
        email
        phone
        logo
        postal_code
        state {
          id
          name
        }
        executives {
          id
          firstname
          lastname
          email
          username
          profile_photo
        }
      }
      products {
        id
        offer_id
        quantity
        uom
        title
        description
        last_date
        status
        created_at
      }
      comments(first: 10, page: $cursor) {
        data {
          id
          user
          comment_body
          created_at
          is_verified
        }
        paginatorInfo {
          total
          lastItem
          currentPage
          hasMorePages
        }
      }
      participant {
        id
        # product_id
        total
        comment
        reason
        document
        participant_files {
          id
          path
          created_at
        }
      }
      approvedParticipant {
        id
        total
        product_id
        approved
        accepted
        revision
        blocked
        is_approve
        expected_date
      }

      executive_permissions {
        id
        name
      }
      participantsOrder(
        sortField: $sortField
        order: $order
        product: $product
      ) {
        id
        user {
          id
          firstname
          lastname
          username
          profile_photo
          email
        }
        product_id
        total
        approved
        is_approve
        accepted
        blocked
        revision
        comment
        reason
        user_id
        action_user_id
        readable_status
        expected_date
        isRevision
        document
      }
      participants(first: 20, page: $cursor) {
        data {
          id
          user {
            id
            firstname
            lastname
            username
            profile_photo
            email
          }
          product_id
          total
          approved
          is_approve
          accepted
          blocked
          revision
          comment
          reason
          user_id
          action_user_id
          readable_status
          expected_date
          isRevision
          offer_file_path
          userSignature
          participant_files {
            id
            path
            created_at
          }
          document
        }
        paginatorInfo {
          total
          lastItem
          currentPage
          hasMorePages
        }
      }
      offer_views(first: 3) {
        data {
          id
          username
          firstname
          lastname
          email
          is_seller
          become_seller
          tagline
          profile_photo
        }
        paginatorInfo {
          total
          count
          currentPage
          lastPage
        }
      }
    }
  }
`;
