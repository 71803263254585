import React, { useState } from "react";
import { Buttonlink } from "components";
import { IoIosArrowBack } from "react-icons/io";
import { TextareaAutosize } from "@mui/material";
import { Dropdown } from "semantic-ui-react";
import { useMutation } from "@apollo/client";
import { GETFORM_DATA } from "../../../graphql/mutations/addContactFormMutation";
import { toast, ToastContainer } from "react-toastify";
import { useEffect } from "react";

export default function ContactUs(props) {
  const options = [
    {
      key: "1",
      text: "Please Select the your ticket type ",
      value: "Please Select the your ticket type ",
    },
    {
      key: "2",
      text: "Want to share a perspective for showcase",
      value: "Want to share a perspective for showcase",
    },
    {
      key: "3",
      text: "Want to showcase an expert",
      value: "Want to showcase an expert",
    },
    {
      key: "4",
      text: "Want to post a job",
      value: "Want to post a job",
    },
    {
      key: "5",
      text: "Want to advertise on Procurement League",
      value: "Want to advertise on Procurement League",
    },
    {
      key: "6",
      text: "Want to report an issue",
      value: "Want to report an issue",
    },
    {
      key: "7",
      text: "Want to join the team",
      value: "Want to join the team",
    },
    {
      key: "8",
      text: "Want to partner",
      value: "Want to partner",
    },
    {
      key: "9",
      text: "want a consulting services",
      value: "want a consulting services",
    },
    {
      key: "10",
      text: "want to subscribe for newsletter",
      value: "want to subscribe for newsletter",
    },
    {
      key: "11",
      text: "want a training",
      value: "want a training",
    },
    {
      key: "12",
      text: "want a best procurement contents",
      value: "want a best procurement contents",
    },
    {
      key: "13",
      text: "want a conference pass at our special price",
      value: "want a conference pass at our special price",
    },
    {
      key: "14",
      text: "want a development league mentorship sessions",
      value: "want a development league mentorship sessions",
    },
    {
      key: "15",
      text: "want a digital procurement updates",
      value: "want a digital procurement updates",
    },
    {
      key: "16",
      text: "want help in research",
      value: "want help in research",
    },
    {
      key: "17",
      text: "want help in procurement outsourcing",
      value: "want help in procurement outsourcing",
    },
    {
      key: "18",
      text: "want events updates",
      value: "want events updates",
    },
    {
      key: "19",
      text: "wants help in media solutions",
      value: "wants help in media solutions",
    },
    {
      key: "20",
      text: "want to join happy subscribers",
      value: "want to join happy subscribers",
    },
    {
      key: "21",
      text: "other",
      value: "other",
    },
  ];

  const [inputField, setinputField] = useState({
    name: "",
    email: "",
    reason: "",
    description: "",
    ticket_type: "",
  });
  const [disabled, setdisabled] = useState(false);

  const handleInputChange = (event) => {
    setinputField({
      ...inputField,
      [event.target.name]: event.target.value,
    });
  };

  const handleInputTopicsChange = (event, data) => {
    setinputField({
      ...inputField,
      ticket_type: data.value,
    });
  };

  useEffect(() => {
    if (
      inputField.name.length === 0 ||
      inputField.email.length === 0 ||
      inputField.description.length === 0 ||
      inputField.reason.length === 0 ||
      inputField.ticket_type.length === 0
    ) {
      setdisabled(true);
    } else {
      setdisabled(false);
    }
  });

  const [create_contactus, { error }] = useMutation(GETFORM_DATA);

  const formsubmit = () => {
    create_contactus({
      variables: {
        name: inputField.name,
        email: inputField.email,
        description: inputField.description,
        reason: inputField.reason,
        ticket_type: inputField.ticket_type,
      },
    })
      .then((res) => {
        setdisabled(true);
      })
      .catch((e) => {
        setdisabled(false);
      });
  };

  const SubmitData = (e) => {
    e.preventDefault();
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (inputField.name.length === 0) {
      return toast.error("Please Enter Your Name");
    } else if (inputField.reason.length === 0) {
      return toast.error("Please Enter Your Subject");
    } else if (!pattern.test(inputField.email)) {
      return toast.error("Please Enter Valid Email");
    } else if (inputField.ticket_type.length === 0) {
      return toast.error("Please Select Some Value");
    } else {
      formsubmit();
      toast.success("Your Form has been Submitted");
      let cleartext = "";
      setinputField({
        name: cleartext,
        email: cleartext,
        description: cleartext,
        ticket_type: cleartext,
        reason: cleartext,
      });
    }
  };
  return (
    <div className="container mt-10">
      <div className="mx-auto">
        <div className="grid lg:grid-cols-5 gap-4">
          <div className="lg:col-start-2  lg:col-span-3 bg-white p-10 rounded-xl">
            <div className="relative mb-10">
              <Buttonlink
                text="Back"
                prefix={<IoIosArrowBack size={16} />}
                onClick={() => props.navigate(-1)}
                className="bg-gray-200 hover:bg-gray-300 fs-13 flex items-center justify-center gap-x-1 h-9 rounded-full darkGray w-24 hover:opacity-80 border-0 shadow-none"
              />
              <h1 className="Medium text-4xl text-center">Contact Us</h1>
            </div>
            <form>
              <div className="mb-3">
                <div className="grid md:grid-cols-2 gap-4 mb-4">
                  <div>
                    <input
                      type="text"
                      placeholder="Your Name"
                      maxLength={50}
                      className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                      name="name"
                      value={inputField.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="Your Subject"
                      maxLength={50}
                      className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                      name="reason"
                      value={inputField.reason}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <input
                    type="email"
                    placeholder="Your Email"
                    maxLength={50}
                    aria-describedby="inputGroupPrepend"
                    className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                    name="email"
                    value={inputField.email}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-4">
                  <Dropdown
                    className="appearance-none rounded-md Light relative block w-full px-3 py-5 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                    placeholder="Topic"
                    fluid
                    selection
                    options={options}
                    name="ticket_type"
                    value={inputField.ticket_type}
                    onChange={handleInputTopicsChange}
                  />
                </div>
                <div className="mb-4">
                  <div>
                    <TextareaAutosize
                      minRows={6}
                      maxLength={300}
                      placeholder="descriptions..."
                      className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                      name="description"
                      value={inputField.description}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <Buttonlink
                className={`${disabled ? "bg--gray hover:bg--gray focus:bg--gray" : "bg--primary hover:bg--primary focus:bg--primary"} mt-5 flex items-center justify-center white hover:white Regular hover:opacity-70 h-11 rounded--xl border-0 shadow-none w-full`}
                text="Submit"
                disabled={disabled}
                onClick={SubmitData}
              />
            </form>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={5000} />
    </div>
  );
}
