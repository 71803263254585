import { gql } from "@apollo/client";

export default gql`
  query ($user_type: Int, $plan_type: Int!) {
  getPlans(user_type: $user_type, plan_type: $plan_type) {
    plan_id
    title
    subtitle
    price
    validity
    plan_features {
      plan_feature_id
      name
      tooltip_note
      quantity
      __typename
    }
    __typename
  }
}

`;
