import { Tab } from "@headlessui/react";
import { Loader, Noconnection, Nocontent, RFQcard } from "components";
import React, { Fragment, useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { IoIosArrowDown } from "react-icons/io";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Menu } from "@headlessui/react";
import GET_OFFERS from "../../../graphql/queries/getOffers";
import { Query } from "@apollo/client/react/components";
import { connect } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import MySelectedRFQsAction from "store/actions/MySelectedRFQsAction";
import client from "../../../graphql/client";
import getExecutiveRoles from "../../../graphql/queries/getExecutiveRoles"

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function Buyertabsrafqs(props) {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    // Get the value of a specific parameter
    const paramValue = searchParams.get("searched");
    const navigate = useNavigate();
    const params = useParams();
    let insertUrl = "";
    let queryValue = "";

    let { current_card } = params;
    switch (current_card) {
        case "my-rfqs":
            current_card = 0;
            break;
        case "latest-action":
            current_card = 1;
            break;
        case "most-reponded":
            current_card = 2;
            break;
        case "no-respone":
            current_card = 3;
            break;
        case "view-as":
            current_card = 4;
            break;
        default:
            current_card = 0;
    }
    const [selectedIndex, setSelectedIndex] = useState(
        props.cardId ? props.cardId : current_card
    );

    const [subSelectedIndex, setSubSelectedIndex] = useState(
        paramValue ? paramValue : "published"
    );

    const [subSelectedRoles, setSubSelectedRoles] = useState("Manager");
    const [roleName, setRoleName] = useState([]);

    useEffect(() => {
        getExecutive()
    }, [])

    const getExecutive = () => {
        client
            .query({
                query: getExecutiveRoles
            })
            .then((res) => {
                setRoleName(res.data.executive_roles)
            })
            .catch((err) => {
                console.log("executives roles error", err);
            });
    };

    useEffect(() => {
        MySelectedRFQsAction.searchedRfqs("");
        if (selectedIndex === 0) {
            insertUrl = "my-rfqs";
            queryValue = subSelectedIndex;
        } else if (selectedIndex === 1) {
            insertUrl = "latest-action";
        } else if (selectedIndex === 2) {
            insertUrl = "most-reponded";
        } else if (selectedIndex === 3) {
            insertUrl = "no-respone";
        } else if (selectedIndex === 4) {
            insertUrl = "view-as";
        }
        if (insertUrl === "my-rfqs" && queryValue !== "") {
            navigate({
                pathname: `/rfq/${insertUrl}`,
                search: `?searched=${queryValue}`,
            });
        } else {
            navigate(`/rfq/${insertUrl}`);
        }

    }, [selectedIndex, props.cardId, subSelectedIndex]);


    // Update the selectedIndex state outside the useEffect
    //props.rfqSelectedFilter = "";
    const orderBy = [{ field: "updated_at", order: "DESC" }]
    let variables = { me: true, orderBy };
    props.rfqSelectedFilter(subSelectedIndex);

    if (selectedIndex === 0) {
        switch (props.selectedFilter) {
            case "published":
                variables.status = "publish";
                variables.executive_offers = subSelectedRoles;
                break;
            case "draft":
                variables.status = "draft";
                variables.executive_offers = subSelectedRoles;
                break;
            case "awarded":
                variables.status = "complete";
                variables.executive_offers = subSelectedRoles;
                break;
            case "expired":
                variables.status = "expire";
                variables.executive_offers = subSelectedRoles;
                break;
            case "clear":
                variables.me = true;
                delete variables.status;
                break;
            default:
                // Handle the default case if needed
                break;
        }
        // Rest of the code that uses the updated `variables` object
    }

    if (selectedIndex === 1) {
        variables.latest_action = true;
    }
    if (selectedIndex === 2) {
        variables.most_responsed = true;
    }
    if (selectedIndex === 3) {
        variables.no_response = true;
    }

    if (selectedIndex === 4) {
        switch (props.selectedFilter) {
            case "published":
                variables.status = "publish";
                break;
            case "draft":
                variables.status = "draft";
                break;
            case "awarded":
                variables.status = "complete";
                break;
            case "expired":
                variables.status = "expire";
                break;
            case "clear":
                variables.me = true;
                delete variables.status;
                break;
            default:
                // Handle the default case if needed
                break;
        }
    }

    variables.executive_offers = subSelectedRoles;


    const subSelection = (e) => {
        setSubSelectedIndex(e.target.value);
        props.rfqSelectedFilter(e.target.value);
    };

    const subSelectionRolesHandler = (e) => {
        setSubSelectedRoles(e.target.value);
    };

    if (props.searchText !== "" || props.searchText !== null) {
        variables.search = props.searchText;
    }

    return (
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
            <Tab.List
                className="lg:sticky top-0 z-full lg:flex relative lg:p-2 md:p-3 p-4 lg:h-20 lg:space-y-0 space-y-3 lg:space-x-3 bg-gray-300 rounded-xl">
                <Tab
                    className={({ selected }) =>
                        classNames(
                            "w-full py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:bg--primary hover:white gap-x-3 flex items-center justify-center",
                            selected ? "bg--primary white" : "bg-white"
                        )
                    }
                >
                    My RFQs
                    <Menu as="div" className="inline-block text-left">
                        <div>
                            <Menu.Button
                                className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-black rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                <IoIosArrowDown size={18} />
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items
                                className="absolute  z-xlfull left-0 w-screen xl:max-w-md max-w-full mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg focus:outline-none">
                                <div className="rounded-lg shadow-lg bg-white relative p-6">
                                    <h4 className="text-left text-2xl">Filters</h4>
                                    <div className="divider w-full my-3" />
                                    <div>
                                        <Menu.Item>
                                            <FormControl className="w-full">
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    value={subSelectedIndex}
                                                    name="radio-buttons-group"
                                                    className="primary-all w-full -mx-3"
                                                >
                                                    <FormControlLabel
                                                        onChange={subSelection}
                                                        value="draft"
                                                        control={<Radio />}
                                                        label={
                                                            <div>
                                                                <p className="black text-lg">Draft</p>
                                                                <p className="Light fs-12 darkGray">
                                                                    Select Draft option
                                                                </p>
                                                            </div>
                                                        }
                                                        className="flex items-center justify-between ml--0 hover:bg-gray-100 rounded-xl py-2 px-3"
                                                        labelPlacement="start"
                                                        checked={
                                                            subSelectedIndex === "draft" ||
                                                            props.selectedFilter === "draft"
                                                        }
                                                    />
                                                    <FormControlLabel
                                                        onChange={subSelection}
                                                        value="published"
                                                        control={<Radio />}
                                                        label={
                                                            <div>
                                                                <p className=" black text-lg">Published</p>
                                                                <p className="Light fs-12 darkGray">
                                                                    Select Published option
                                                                </p>
                                                            </div>
                                                        }
                                                        labelPlacement="start"
                                                        className="flex items-center justify-between ml--0 hover:bg-gray-100 rounded-xl py-2 px-3"
                                                        checked={
                                                            subSelectedIndex === "published" ||
                                                            props.selectedFilter === "published"
                                                        }
                                                    />
                                                    <FormControlLabel
                                                        onChange={subSelection}
                                                        value="awarded"
                                                        control={<Radio />}
                                                        label={
                                                            <div>
                                                                <p className=" black text-lg">Completed</p>
                                                                <p className="Light fs-12 darkGray">
                                                                    Select Completed option
                                                                </p>
                                                            </div>
                                                        }
                                                        labelPlacement="start"
                                                        className="flex items-center justify-between ml--0 hover:bg-gray-100 rounded-xl py-2 px-3"
                                                        checked={
                                                            subSelectedIndex === "awarded" ||
                                                            props.selectedFilter === "awarded"
                                                        }
                                                    />
                                                    <FormControlLabel
                                                        onChange={subSelection}
                                                        value="expired"
                                                        control={<Radio />}
                                                        label={
                                                            <div>
                                                                <p className=" black text-lg">Expired</p>
                                                                <p className="Light fs-12 darkGray">
                                                                    Select Expired option
                                                                </p>
                                                            </div>
                                                        }
                                                        labelPlacement="start"
                                                        className="flex items-center justify-between ml--0 hover:bg-gray-100 rounded-xl py-2 px-3"
                                                        checked={
                                                            subSelectedIndex === "expired" ||
                                                                props.selectedFilter === "expired"
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    <FormControlLabel
                                                        onChange={subSelection}
                                                        value="clear"
                                                        control={<Radio />}
                                                        label={
                                                            <div>
                                                                <p className=" black text-lg">Clear</p>
                                                                <p className="Light fs-12 darkGray">
                                                                    Select Clear option
                                                                </p>
                                                            </div>
                                                        }
                                                        labelPlacement="start"
                                                        className="flex items-center justify-between ml--0 hover:bg-gray-100 rounded-xl py-2 px-3"
                                                        checked={
                                                            subSelectedIndex === "clear" ||
                                                                props.selectedFilter === "clear"
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Menu.Item>
                                    </div>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </Tab>
                <Tab
                    className={({ selected }) =>
                        classNames(
                            "w-full lg:py-2.5 py-4 fs-15 leading-5 Regular rounded-lg shadow-md hover:bg--primary hover:white",
                            selected ? "bg--primary white" : "bg-white"
                        )
                    }
                >
                    Latest action
                </Tab>
                <Tab
                    className={({ selected }) =>
                        classNames(
                            "w-full lg:py-2.5 py-4 fs-15 leading-5 Regular rounded-lg shadow-md hover:bg--primary hover:white",
                            selected ? "bg--primary white" : "bg-white"
                        )
                    }
                >
                    Most responded
                </Tab>
                <Tab
                    className={({ selected }) =>
                        classNames(
                            "w-full lg:py-2.5 py-4 fs-15 leading-5 Regular rounded-lg shadow-md hover:bg--primary hover:white",
                            selected ? "bg--primary white" : "bg-white"
                        )
                    }
                >
                    No response
                </Tab>
                <Tab
                    className={({ selected }) =>
                        classNames(
                            "w-full py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:bg--primary hover:white gap-x-3 flex items-center justify-center",
                            selected ? "bg--primary white" : "bg-white"
                        )
                    }
                >
                    View as
                    <Menu as="div" className="inline-block text-left">
                        <div>
                            <Menu.Button
                                className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-black rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                <IoIosArrowDown size={18} />
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items
                                className="absolute z-xlfull right-0 w-screen xl:max-w-md max-w-full mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg focus:outline-none">
                                <div className="rounded-lg shadow-lg bg-white relative p-6">
                                    <h4 className="text-left text-2xl">Filter By Role</h4>
                                    <div className="divider w-full my-3" />
                                    <div>
                                        <Menu.Item>
                                            <FormControl className="w-full">
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    value={subSelectedIndex}
                                                    name="radio-buttons-group"
                                                    className="primary-all w-full -mx-3"
                                                >
                                                    {roleName?.map(role => (
                                                        <FormControlLabel
                                                            onChange={subSelectionRolesHandler}
                                                            value={role.name} // Use the role name as the value
                                                            control={<Radio />}
                                                            label={
                                                                <div>
                                                                    <p className="black text-lg">{role.name}</p>
                                                                    <p className="Light fs-12 darkGray">
                                                                        Select {role.name} option
                                                                    </p>
                                                                </div>
                                                            }
                                                            className="flex items-center justify-between ml--0 hover:bg-gray-100 rounded-xl py-2 px-3"
                                                            labelPlacement="start"
                                                            checked={
                                                                subSelectedRoles === role.name
                                                            }
                                                        />
                                                    ))}
                                                </RadioGroup>
                                            </FormControl>
                                        </Menu.Item>
                                    </div>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </Tab>
            </Tab.List>
            <Tab.Panels className="mt-2">
                <Tab.Panel className="bg-transparent rounded-xl mt-20">
                    <All_RFQ_Query variables={variables} selectedIndex={selectedIndex} />
                </Tab.Panel>
                <Tab.Panel className="bg-transparent rounded-xl mt-20">
                    <All_RFQ_Query variables={variables} selectedIndex={selectedIndex} />
                </Tab.Panel>
                <Tab.Panel className="bg-transparent rounded-xl mt-20">
                    <All_RFQ_Query variables={variables} selectedIndex={selectedIndex} />
                </Tab.Panel>
                <Tab.Panel className="bg-transparent rounded-xl mt-20">
                    <All_RFQ_Query variables={variables} selectedIndex={selectedIndex} />
                </Tab.Panel>
                <Tab.Panel className="bg-transparent rounded-xl mt-20">
                    <All_RFQ_Query variables={variables} selectedIndex={selectedIndex} />
                </Tab.Panel>
            </Tab.Panels>
        </Tab.Group>
    );
}

function All_RFQ_Query(props) {
    const [loadMoreRFQ, SetLoadMoreRFQ] = useState(true);
    let variables = props.variables;
    return (
        <Query
            query={GET_OFFERS}
            variables={variables}
            fetchPolicy={"cache-and-network"}
        >
            {({ loading, data, error, fetchMore, refetch }) => {
                if (loading) {
                    return <Loader />;
                }
                if (error) {
                    return <Noconnection />;
                }

                if (data.offers.data.length <= 0) {
                    return <Nocontent text="It's quite empty out here, let's crowd it with RFQs!" />;
                }

                if (props.recallRef) {
                    refetch();
                    props.rfqPublishorUnpublished(false);
                }
                return (
                    <div className="grid xl:grid-cols-3 lg:grid-cols-3 sm:grid-cols-2 gap-4">
                        <RFQcard
                            selectedIndex={props.selectedIndex}
                            home={false}
                            publicRfqs={data}
                            imageHeight="h-32 w-32"
                            cardSpacing="p-5"
                            paginatorInfo={data.offers?.paginatorInfo}
                            imageRounded="rounded-xl"
                            cardBorder="border"
                            onLoadMore={() => {
                                fetchMore({
                                    variables: {
                                        cursor: data.offers.paginatorInfo.currentPage + 1,
                                    },
                                    updateQuery: (previousResult, { fetchMoreResult }) => {
                                        const newQuestions = fetchMoreResult.offers.data;
                                        const pageInfo = fetchMoreResult.offers.paginatorInfo;
                                        if (data.offers.paginatorInfo.total !== pageInfo.total) {
                                            refetch();
                                        }
                                        if (pageInfo.hasMorePages) {
                                            SetLoadMoreRFQ(true);
                                        } else {
                                            SetLoadMoreRFQ(false);
                                        }
                                        return newQuestions.length
                                            ? {
                                                offers: {
                                                    __typename: previousResult.offers.__typename,
                                                    data: [
                                                        ...previousResult.offers.data,
                                                        ...newQuestions,
                                                    ],
                                                    paginatorInfo: pageInfo,
                                                },
                                            }
                                            : previousResult;
                                    },
                                });
                            }}
                        />
                    </div>
                );
            }}
        </Query>
    );
}

const mapStateToProps = ({ Auth, Rfq, RFQs }) => {
    return {
        authUser: Auth.authUserInformation,
        selectedFilter: Rfq.selectedFilter,
        recallRef: Rfq.recallRef,
        searchText: RFQs.searchedRfqs,
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { actions } = require("store/redux/RFQRedux");
    return {
        ...ownProps,
        ...stateProps,
        rfqSelectedFilter: (data) => {
            dispatch(actions.rfqSelectedFilter(data));
        },

        rfqPublishorUnpublished: (data) => {
            dispatch(actions.rfqPublishorUnpublished(data));
        },

    };
};

export default connect(mapStateToProps, undefined, mergeProps)(Buyertabsrafqs);
