import { gql } from "@apollo/client";

export default gql`
  query ($id: ID, $offer_id: Int) {
    company(id: $id) {
      id
      title
      slug
      address
      city
      state_id
      latitude
      longitude
      country
      country_code
      postal_code
      phone
      email
      history
      linked_in_profile
      logo
      is_active
      is_deleted
      added_from
      is_primary
      state {
        id
        name
      }
      executives {
        id
        firstname
        lastname
        email
        username
        profile_photo
        userCompanyOfferRole(company_id: $id, offer_id: $offer_id)
      }
    }
  }
`;
