import React from 'react'
import {
    IoIosArrowForward,
    IoIosArrowBack,
} from "react-icons/io";
import { FiCheck, FiX } from "react-icons/fi";
import { Buttonlink, Loader } from "components";
import { connect } from 'react-redux';

const SystemPlan = (props) => {
    // const user_type = props.user.is_seller ? 2 : 1;
    const freePlanIds = props.user.payments?.reduce((ids, payment) => {
        if (payment.plan.title === "Free Plan") {
            ids.push(payment.plan.plan_id);
        }
        return ids;
    }, []);
    return (
        <div className="shadow--sm rounded-lg bg-white overflow-hidden border sm:p-5">
            <div className="flex overflow-x-auto overflow-y-hidden pb-6">
                <div className="grow shrink-0">
                    <div className="flex items-center h-16 relative">
                        <h1 className="Medium text-lg lg:text-2xl lg:flex hidden">
                            What kind of plan are you interested in?
                        </h1>
                        <h1 className="Medium text-lg lg:text-2xl lg:hidden flex">
                            Plan Features
                        </h1>
                        {/* {singlePackage === false ? ( */}
                        <div
                            // onClick={prevSinglePackageClick}
                            className="absolute right-0 lg:hidden block"
                        >
                            <IoIosArrowBack className="primary" size={24} />
                            <p className="fs-12 primary">prev</p>
                        </div>
                        <div className="absolute right-0 lg:hidden block">
                            <IoIosArrowForward className="primary" size={24} />
                            <p className="fs-12 primary">next</p>
                        </div>
                    </div>

                    <div>
                        <div className="h-12 lg:h-16 flex items-center"></div>
                        <div className="h-12 lg:h-16 flex items-center">
                            <h4 className="Regular text-sm lg:text-lg mr-1 text-color">
                                Validity
                            </h4>
                        </div>
                        {props.FeaturesData.map((features, index) => (
                            <div key={index}>
                                <div className="h-12 lg:h-16 flex items-center">
                                    <h4 className="Regular text-sm lg:text-lg mr-1 text-color">
                                        {features.name}
                                    </h4>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {props.PlansData.length !== 0 &&
                    props.PlansData.map((plan, planIndex) => (
                        <div
                            key={planIndex}
                            className="relative shrink-0 grow border rounded-[27px] border--primary lg:min-w-[219px] ml-2"
                        >
                            <div className="flex items-center justify-center w-full Regular h-16">
                                <div className="w-full h-[48px] bg--primary text-white hover:text-white max-w-[179px] absolute top-[-10px] flex items-center justify-center border-0 rounded-lg  px-5">
                                    {plan.title}
                                </div>
                            </div>
                            <div className="flex items-center justify-center flex-col border-b h-16">
                                <h4 className="Medium text-sm lg:text-3xl">
                                    {plan.price + "$"}
                                </h4>
                            </div>
                            <div className="h-12 lg:h-16 flex items-center justify-center border-b">
                                <p>{plan.subtitle}</p>
                            </div>
                            {/* Find matching feature based on plan_feature_id */}
                            {props.FeaturesData.map((features, index) => {
                                const matchingPlanFeature = plan.plan_features.find(
                                    (planFeature) =>
                                        features.plan_feature_id === planFeature.plan_feature_id
                                );
                                return (
                                    <div
                                        key={index}
                                        className="h-12 lg:h-16 flex items-center justify-center border-b"
                                    >
                                        <p>
                                            {matchingPlanFeature?.quantity === "Check Box" ? (
                                                <FiCheck className="success" size={20} />
                                            ) : matchingPlanFeature?.quantity ? (
                                                matchingPlanFeature.quantity
                                            ) : (
                                                <FiX className="danger" size={20} />
                                            )}
                                        </p>
                                    </div>
                                );
                            })}
                            <div className="h-20 lg:h-20 flex items-center justify-center">
                                <Buttonlink
                                    to={`/payment`}
                                    state={{ plan: plan }}
                                    disabled={plan.price === "0" && freePlanIds?.includes(plan.plan_id) ? true : false}
                                    title={"Select"}
                                    text={plan.price === "0" && freePlanIds?.includes(plan.plan_id) ? "Already Purchased" : "Select"}
                                    rel="nofollow"
                                    className={`${plan.price === "0" && freePlanIds?.includes(plan.plan_id) ? "bg--gray hover:bg--gray focus:bg--gray pointer-events-none" : "bg-[#EF553B] hover:bg--primary focus:bg--primary"} text-white hover:text-white flex-grow flex items-center justify-center max-w-[179px] w-full absolute bottom-[-10px] Regular  border-0 rounded--full shadow-none h-12 px-5`}
                                />
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    )
}
const mapStateToProps = ({ Auth }) => {
    return {
        user: Auth.authUserInformation,
    };
};

export default connect(mapStateToProps, null)(SystemPlan);