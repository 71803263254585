import React, { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import "./styles.css";

const DateRangePicker = ({ startDate, className, callback }) => {
  const [state, setState] = useState([
    {
      startDate,
      endDate: null,
      key: "selection",
    },
  ]);

  useEffect(() => {
    callback(state);
  }, [state, callback]);

  const handleDateChange = (item) => {
    setState([item.selection]);
  };

  return (
      <DateRange
          className={className}
          editableDateInputs={true}
          showDateDisplay={false}
          onChange={handleDateChange}
          moveRangeOnFirstSelection={false}
          minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 2))}
          maxDate={new Date(new Date().setDate(new Date().getDate() + 1))}
          ranges={state}
          rangeColors="#ef553b"
          color="#ef553b"
      />
  );
};

export default DateRangePicker;
