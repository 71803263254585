import { Payment } from "..";
import { useNavigate, useLocation } from "react-router-dom";
import { ElementsConsumer } from "@stripe/react-stripe-js";

export default function PaymentScreen({ authUserEmail }) {
	const navigation = useNavigate();
	const { state } = useLocation();
	return (
		<ElementsConsumer>
			{({ stripe, elements }) => (
				<Payment
					navigate={navigation}
					state={state}
					authUserEmail={authUserEmail}
					stripe={stripe}
					elements={elements}
				/>
			)}
		</ElementsConsumer>
	);
}
