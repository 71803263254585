import { gql } from "@apollo/client";

export default gql`
  mutation($company_id:Int) {
  removeUserCompany(company_id:$company_id)
  {
      status
      message
  }
}

`;
