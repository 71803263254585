import { gql } from "@apollo/client";

export default gql`
mutation setPrimaryAddress(
    $id: Int
  ) {
    setPrimaryAddress(
      id: $id
    ) {
      id
      is_primary
    }
  }
`;
