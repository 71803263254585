import React, { Component } from "react";
import { Buttonlink } from "components";
import { RiFileEditFill } from "react-icons/ri";
import { IoIosArrowBack } from "react-icons/io";
import dss from "assets/images/dss.png";
import master from "assets/images/master.png";
import visa from "assets/images/visa.png";
import "react-toastify/dist/ReactToastify.css";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { FiChevronDown } from "react-icons/fi";
import { PaymentInputsContainer } from "react-payment-inputs";
import GET_PAYMENTDATA from "../../../graphql/mutations/createPayeezyPaymentsMutaTion";
import client from "graphql/client";
import { toast } from "react-toastify";
import cookie from "js-cookie";
import {
  GOOGLE_PAY_MERCHANT_ID,
  GOOGLE_PAY_MERCHANT_NAME,
  PAYEEZY_PAY_PAYMENT_GATEWAY_NAME,
  PAYMENT_GATEWAY_ENVIRONMENT,
  PAYMENT_GATEWAY_MERCHANT_ID,
  PL_WEB_URL,
} from "../../../config/constants";
import GooglePayButton from "@google-pay/button-react";
import { connect } from "react-redux";
import { encryptfunction } from "../../../helpers";
import MyAuthUserAction from "../../../store/actions/MyAuthUserAction";
import getPayment from "graphql/queries/getPayment";
import store from "store";
import { UPDATE_AUTH_USER_PROPERTY } from "store/actions/types";
import { CardElement, withstripe, withelements } from "@stripe/react-stripe-js";
import { Navigate } from "react-router";
import { Loader } from "semantic-ui-react";

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      checked: false,
      isReadyToPay: false,
      disabled: true,
      plan: "",
      paymentLoader: false,
    };
    let customPlanArray = {};
    const customPlanCookie = cookie.get("CustomPlan");
    if (Object.keys(this.props.state.plan).length !== 0) {
      try {
        customPlanArray = JSON.parse(customPlanCookie); // Parse the JSON string to an array
        this.setState({ plan: customPlanArray });
        //setCustomPlan(customPlanArray);
      } catch (error) {
        console.error("Error parsing CustomPlan cookie:", error);
      }
    }
  }
  // componentWillUnmount() {
  //   console.log("OKKK");
  //   MyAuthUserAction.getAuthUserInformation();
  //   if (this.props.socket?.connected) {
  //     this.props?.socket?.emit("notify", "Notification data");
  //   }
  // }
  getPayments = () => {
    client
      .query({
        query: getPayment,
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        // This code will run after the state is updated.
        // this.setState({ loader: false });
        //this.setState({ payments: res.data.me.payments });
        store.dispatch({
          type: UPDATE_AUTH_USER_PROPERTY,
          payload: { key: "payments", value: res.data.me.payments },
        });
      });
  };
  handleCheck = (event) => {
    this.setState({
      checked: event.target.checked,
    });
  };
  notify = (message = "", type = "error") => {
    // Dismiss any existing toasts before showing a new one
    toast.dismiss();
    if (type === "error") {
      return toast.error(message, { position: "top-right" });
    }
    if (type === "success") {
      toast.success(message, { position: "top-right" });
    }
    if (type === "info") {
      toast.info(message, { position: "top-right" });
    }
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    if (window.location.pathname === "/payment") {
      let urled = encryptfunction(this.props.auth_user.id) + "/profile";
      window.history.replaceState(null, "", "/" + urled);
    }
  };

  paymentdata = async (plan) => {
    this.setState({
      disabled: true,
      name: "",
      // checked: false,
    });
    const { stripe, elements } = this.props;
    if (!stripe || !elements) {
      this.notify("Please insert accurate card information", "error");
      return;
    }
    const cardElement = elements.getElement(CardElement);
    if (this.props.state?.plan?.price !== "0") {
      const { error: stripeError, paymentMethod } =
        await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: {
            name: this.state.name,
            email: this.props.authUserEmail,
          },
        });
      if (stripeError) {
        toast.error(stripeError);
        return;
      }
      const payment_method_id = paymentMethod?.id;
    }
    this.setState({ paymentLoader: true });
    if (this.props.state?.plan?.price === "0") {
      client
        .mutate({
          mutation: GET_PAYMENTDATA,
          variables: {
            plan_id: this.props.state?.plan?.plan_id ?? plan.plan_id,
            email: this.props.authUserEmail,
          },
        })
        .then(async (res) => {
          if (res.data.createStripePayment.response.status) {
            this.notify(
              res.data.createStripePayment.response.message,
              "success"
            );

            setTimeout(() => {
              this.setState({ paymentLoader: false });
              this.getPayments();
              this.props.navigate("/");
              // if (this.props.auth_user?.is_seller) {
              //   window.location.href = `${process.env.REACT_APP_PL_WEB_URL}/auto-login/${this.props.auth_user?.cross_token}}`;
              // }
            }, 1000);
          } else {
            this.notify(res.data.createStripePayment.response.message, "error");
          }
        })
        .catch((e) => {
          console.log("e", e);
          this.notify("Payment Failed!!", "error");
        });
    } else {
      const { stripe, elements } = this.props;
      if (!stripe || !elements) {
        return;
      }
      const cardElement = elements.getElement(CardElement);

      const { error: stripeError, paymentMethod } =
        await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: {
            name: this.state.name,
            email: this.props.authUserEmail,
          },
        });
      if (stripeError) {
        toast.error(stripeError);
        return;
      }
      const payment_method_id = paymentMethod?.id;

      let id = this.props.state?.plan?.plan_id ?? plan.plan_id;
      let name = this.state.name;
      client
        .mutate({
          mutation: GET_PAYMENTDATA,
          variables: {
            plan_id: id,
            cardholder_name: name,
            payment_method_id: payment_method_id,
            email: this.props.authUserEmail,
          },
        })
        .then((res) => {
          // console.log("res", res);
          if (res.data.createStripePayment.response.status) {
            this.notify(
              res.data.createStripePayment.response.message,
              "success"
            );
            this.setState({
              name: "",
              checked: false,
            });
            setTimeout(() => {
              this.setState({ paymentLoader: false });
              this.getPayments();
              this.props.navigate("/");
              // return <Navigate to="/" />;
              // if (this.props.auth_user?.is_seller) {
              //   window.location.href = `${process.env.REACT_APP_PL_WEB_URL}/profile`;
              //   //window.location.href = `${process.env.REACT_APP_PL_WEB_URL}/auto-login/${this.props.auth_user?.cross_token}}`;
              // }
            }, 1000);
          } else {
            this.setState({
              checked: false,
            });
            this.notify(res.data.createStripePayment.response.message, "error");
          }
        })
        .catch((e) => {
          console.log("e", e);
          this.notify("Payment Failed!!", "error");
        });
    }
    // MyAuthUserAction.getAuthUserInformation()
    //   .then((res) => {
    //     //
    //   })
    //   .catch((error) => {
    //     console.log("error", error);
    //   });
  };

  proceedData = (plan) => {
    //if (plan.plan_id)
    if (this.props.state.plan.price !== "0") {
      if (this.state.name.length === 0) {
        return toast.error("Please Enter Your CardHolder Name");
      } else if (!this.state.checked) {
        return toast.error("Please Agree Terms and Conditions");
      } else {
        this.paymentdata(plan);
        const emptyCustomPlan = JSON.stringify({});
        cookie.set("CustomPlan", emptyCustomPlan);
      }
    } else {
      this.paymentdata(plan);
    }
  };

  handleDataPay = (e) => {
    this.setState(
      {
        ...this.state,
        [e.target.name]: e.target.value,
      },
      () => {
        if (this.state.name.length !== 0) {
          this.setState({
            disabled: false,
            checked: false,
          });
        } else {
          this.setState({
            disabled: true,
            checked: false,
          });
        }
      }
    );
  };

  // handleExpiryDate = (text) => {
  //   this.setState({
  //     expiryDate:
  //       text.length === 6 && !text.includes("/")
  //         ? `${text.substring(0, 2)}/${text.substring(2)}`
  //         : text,
  //     expiryMonth: text.substring(0, 2),
  //     expiryYear: text.substr(text.length - 2),
  //   });
  // };

  onReadyToPayChange = (data) => {
    this.setState({ isReadyToPay: true });
    if (data.isReadyToPay && this.state.isReadyToPay) {
      // this.notify(`Google Pay is Ready`, "info");
    }
  };

  onSuccessNotification = (data) => {
    //  console.log(data.paymentMethodData.info.cardDetails)
    //  console.log(data.paymentMethodData.info.cardNetwork)
    // PAYMENT_GATEWAY
    //  console.log(data.paymentMethodData.tokenizationData.type)
    //5- coupon number does not exist
    /*    console.log(data.paymentMethodData.tokenizationData.type);
    console.log(data.paymentMethodData);
    console.log(data.paymentMethodData.tokenizationData);
    console.log(data);*/
    // if (data.paymentMethodData.tokenizationData.type) {
    //   this.notify("Purchase Successful", "success");
    //   this.setState({ loading: true });
    //   client
    //     .mutate({
    //       mutation: PAY_FROM_GOOGLE_PAY,
    //       variables: {
    //         card_type: data.paymentMethodData.tokenizationData.type,
    //         package_id:
    //           this.props.type === "subscription"
    //             ? this.props.item.id
    //             : this.props.item.price,
    //         course_id:
    //           this.props.type === "item_based" ? this.props.item.id : null,
    //         // coupon_code,
    //       },
    //     })
    //     .then((results) => {
    //       this.setState({ loading: false });
    //       if (this.state.type === "item_based") {
    //         window.location.href = "/products";
    //       } else {
    //         window.location.href = "/classes";
    //       }
    //     })
    //     .catch((error) => {
    //       this.setState({ loading: false });
    //       this.notify(`Payment Error...1:, ${error}`, "error");
    //     });
    // } else {
    //   this.setState({ loading: false });
    //   this.notify("Payment Failed", "error");
    // }
  };

  render() {
    let customPlanArray = "";
    const customPlanCookie = cookie.get("CustomPlan");
    try {
      customPlanArray = JSON.parse(customPlanCookie); // Parse the JSON string to an array
      //setCustomPlan(customPlanArray);
    } catch (error) {
      // console.error("Error parsing CustomPlan cookie:", error);
    }
    //let cookiePlan = customPlanArray;
    let plan = {};
    if (Object.keys(this.props.state.plan).length !== 0) {
      plan = this.props.state.plan;
    } else {
      plan = customPlanArray;
    }
    const { disabled, checked } = this.state;
    const { stripe } = this.props;
    return (
      <div className="container mt-20">
        <div className="grid lg:grid-cols-6 gap-4">
          <div className="lg:col-start-3 lg:col-span-2">
            <div className="mb-5">
              <Buttonlink
                text="Back"
                prefix={<IoIosArrowBack size={16} />}
                onClick={() => this.props.navigate(-1)}
                className="bg--primary fs-13 flex items-center justify-center gap-x-1 h-9 rounded-full text-white w-24 hover:opacity-80 border-0 shadow-none"
              />
            </div>
            {/* <div className="shadow--sm p-3 bg-white rounded-lg mb-5">
                        <div className="flex items-center justify-between gap-x-1">
                            <div className="flex-grow">
                                <input
                                    type="text"
                                    placeholder="Coupon Code"
                                    className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                                />
                            </div>
                            <div>
                                <Buttonlink
                                    to="/"
                                    text="Apply"
                                    className="Regular white fs-13 bg--primary hover:bg--primary focus:bg--primary hover:opacity-80 h-11 px-4 w-full border-0 flex items-center justify-center rounded-lg hover:text-white"
                                />
                            </div>
                        </div>
                    </div>*/}
            <div className="shadow--sm p-3 bg-white rounded-xl mb-5">
              <p className="gray fs-12">Subscription Plan:</p>
              <p className="Medium">{plan.title}</p>
              <div className="flex items-center justify-between mt-2">
                <p className="fs-14">{plan.subtitle}</p>
                <div className="flex items-center">
                  <p className="Medium">{plan.price}</p>
                  <p className="ml-1"> USD</p>
                </div>
              </div>
            </div>

            {plan.price === "0" ? (
              <Buttonlink
                onClick={() => this.proceedData(plan)}
                text="Proceed"
                disabled={!checked}
                // className="Regular white bg--primary hover:text-white hover:bg--primary focus:bg--primary hover:opacity-80 h-11 w-full border-0 flex items-center justify-center rounded-lg"
                className={`relative w-full hover:white hover:opacity-80 flex justify-center py-2.5 px-4 border border-transparent rounded-lg ${
                  !checked
                    ? "text-white bg--gray hover:bg--gray"
                    : "text-white bg--primary hover:bg--primary"
                } focus:outline-none`}
              />
            ) : (
              <>
                {this.state.name.length !== 0 ? null : (
                  <>
                    <div>
                      <div>
                        <GooglePayButton
                          environment={PAYMENT_GATEWAY_ENVIRONMENT}
                          existingPaymentMethodRequired={false}
                          paymentRequest={{
                            apiVersion: 2,
                            apiVersionMinor: 0,
                            emailRequired: true,
                            allowedPaymentMethods: [
                              {
                                type: "CARD",
                                parameters: {
                                  allowedAuthMethods: [
                                    "PAN_ONLY",
                                    "CRYPTOGRAM_3DS",
                                  ],
                                  allowedCardNetworks: [
                                    "AMEX",
                                    "DISCOVER",
                                    "INTERAC",
                                    "JCB",
                                    "MASTERCARD",
                                    "VISA",
                                  ],
                                },
                                tokenizationSpecification: {
                                  type: "PAYMENT_GATEWAY",
                                  parameters: {
                                    gateway: PAYEEZY_PAY_PAYMENT_GATEWAY_NAME,
                                    gatewayMerchantId:
                                      PAYMENT_GATEWAY_MERCHANT_ID,
                                  },
                                },
                              },
                            ],
                            merchantInfo: {
                              merchantId: GOOGLE_PAY_MERCHANT_ID,
                              merchantName: GOOGLE_PAY_MERCHANT_NAME,
                            },
                            transactionInfo: {
                              totalPriceStatus: "FINAL",
                              totalPriceLabel: "Total",
                              totalPrice: plan.price,
                              // this.state.amount !== ""
                              //   ? this.state.amount.toString()
                              //   : item.price.toString(),
                              currencyCode: "USD",
                              countryCode: "US",
                              transactionNote: "All purchases are final",
                              checkoutOption: "DEFAULT",
                            },
                            // shippingAddressRequired: item.is_shippable === true,
                            shippingAddressRequired: false,
                            callbackIntents: ["PAYMENT_AUTHORIZATION"],
                          }}
                          // onLoadPaymentData={(paymentRequest) => {
                          //   console.log("onLoadPaymentData", paymentRequest);
                          //   // this.onSuccessNotification(paymentRequest);
                          // }}
                          onPaymentAuthorized={(paymentData) => {
                            this.onSuccessNotification(paymentData);
                          }}
                          onReadyToPayChange={(result) => {
                            this.onReadyToPayChange(result);
                          }}
                          buttonColor="black"
                          buttonSizeMode={"fill"}
                          className="w-full-all w-full"
                        />
                      </div>
                    </div>
                    <div className="flex flex-row items-center my-4">
                      <div className="divider flex-grow rounded-full" />
                      <p className="mx-4 gray">Or</p>
                      <div className="divider flex-grow rounded-full" />
                    </div>
                  </>
                )}

                <div className="shadow--sm bg-white p-3 rounded-lg">
                  <div className="mb-3">
                    <p className="fs-14 mb-1">Cardholder name</p>
                    <input
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleDataPay}
                      className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                    />
                  </div>
                  <CardElement
                    className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                    autoComplete="off"
                  />

                  <Buttonlink
                    onClick={() => this.proceedData(plan)}
                    text={
                      this.state.paymentLoader ? (
                        <Loader active inline="centered" size="small" />
                      ) : (
                        "Proceed"
                      )
                    }
                    disabled={disabled || !checked || !stripe}
                    // className="Regular white bg--primary hover:text-white hover:bg--primary focus:bg--primary hover:opacity-80 h-11 w-full border-0 flex items-center justify-center rounded-lg"
                    className={`relative mt-3 w-full hover:white hover:opacity-80 flex justify-center py-2.5 px-4 border border-transparent rounded-lg ${
                      disabled || !checked || !stripe
                        ? "text-white bg--gray hover:bg--gray"
                        : "text-white bg--primary hover:bg--primary"
                    } focus:outline-none`}
                  />
                </div>
                <div className="flex items-center mt-2.5 gap-2">
                  <img src={visa} alt="visa" className="h-3 object-contain" />
                  <img
                    src={master}
                    alt="master"
                    className="h-3 object-contain"
                  />
                  <img src={dss} alt="dss" className="h-3 object-contain" />
                </div>
              </>
            )}
            <div className="shadow--sm rounded-lg mt-3 mb-1  bg-white overflow-hidden payment-accordian">
              <Accordion className="important:shadow--none rounded--xl overflow-hidden p-0 bg-transparent">
                <AccordionSummary
                  expandIcon={
                    <FiChevronDown className="black ml-5" size={20} />
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div className="flex">
                    <RiFileEditFill size={20} className="primary mr-3" />
                    <p className="primary fs-16">Terms and Conditions</p>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="fs-16 Medium black mb-2">All sales are final</p>
                  <p className="black">
                    Sourcing Genie and its subsidiaries reserve the right to
                    accept or decline any request for a refund.
                  </p>
                </AccordionDetails>
              </Accordion>
            </div>
            <FormControlLabel
              control={
                <Checkbox checked={checked} className="primaryCheckboxes" />
              }
              className="mb-3"
              onChange={this.handleCheck}
              label={
                <>
                  <p className="black">Agree Terms and Conditions</p>
                </>
              }
              labelPlacement="end"
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ Auth }) => {
  return {
    auth_user: Auth.authUserInformation,
    socket: Auth.socket,
  };
};

export default connect(mapStateToProps, null)(Payment);
