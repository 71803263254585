import { gql } from "@apollo/client";

export default gql`
  query {
    me {
      id
      analtics {
        quote
        approved
        lost
        total
      }
    }
  }
`;
