import { gql } from "@apollo/client";

export default gql`
  query ($month: Int, $dateRange: String, $categories: [Int]) {
    analytics(month: $month, dateRange: $dateRange, categories: $categories) {
      offer_count
      offer_past_month_percentage
      offer_spend
      offer_spendPercentage
      offer_suppliersCount
      offer_suppliersPercentage
      draft_offer_count
      draft_offer_past_percentage
      awarded_offer_count
      published_offer_count
      awarded_offer_past_percentage
      closed_offer_amount
      published_offer_amount
      awarded_offer_amount
      expired_offer_count
      executive_exist_count
      
    }
  }
`;
