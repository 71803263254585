import React from "react";
import "./styles.css";
import Bannergif from "../../assets/images/banner-loader.gif";

const Subbanner = (props) => {
  const { title, text } = props;

  return (
    <div className="sub-banner">
      <div className="overlay:bg--gray-600 h-full relative overflow-hidden">
        <div className="container h-full pt-20">
          <div className="xl:grid grid-cols-5 gap-4 h-full">
            <div className="lg:col-span-3 space-y-8 h-full">
              <h4 className="Regular black lg:text-xl text-xl -intro-x">
                <span className="animate-bounce mr-4 before:block  before:absolute before:-inset-1 before:skew-y-12 before:bg-pink-500 relative inline-block">
                  <div className="relative black italic text-xl Regular rotateZ ">
                    Sourcing Genie
                  </div>
                </span>
                Request for Quote
              </h4>
              <h1 className="black Medium 2xl:text-5xl -intro-x xl:text-5xl text-3xl">
                {title !== 'undefined' ? title : ''}
              </h1>
              <h4 className="Regular black text-xl -intro-x">{text}</h4>
            </div>
          </div>
        </div>
        {/* <img src={Bannergif} alt="avatar" width="230" className="banner-gif absolute -right-3 bottom-0 md:block hidden"/> */}
      </div>
    </div>
  );
};

export default Subbanner;
