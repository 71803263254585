import React, { useState } from "react";
import { HiOutlineSearch } from "react-icons/hi";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { Loader, Noconnection } from "components";
import { Query, useQuery } from "@apollo/client";
import states from "../../../graphql/queries/states";
import { connect } from "react-redux";

const SelectState = ({ country_id, _state, selectState }) => {
  const [searchName, setSearchName] = useState("");

  const handleClicked = (state) => {
    selectState(state);
  };

  const { loading, error, data } = useQuery(states, {
    variables: { q: searchName + "%", country_id: country_id === 0 ? _state.country_id : country_id },
  });

  if (loading) return <Loader />;
  if (error) return <Noconnection />;
  // console.log("heeeeee",_state)
  return (
    <div className="relative p-6">
      <div className="bg--lightGray rounded-xl flex items-center h-10 px-3">
        <HiOutlineSearch className="darkGray" />
        <input
          placeholder="Enter to search and select"
          className="bg-transparent h-full w-full pl-3"
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
        />
      </div>
      <div className="divider w-full my-3" />
      <div className="-mx-4">
        {data.states.data.length === 0 ? (
          searchName === '' ?
            <div>Set Country First!</div>
            :
            <div>Search Name not Exist!</div>
        ) : (
          data.states.data.map((state) => (
            <ListItem
              key={state.id}
              disablePadding
              className="rounded-lg overflow-hidden"
            >
              <ListItemButton dense className="hover:primary-all">
                <ListItemText>
                  <div className="darkGray">{state.name}</div>
                </ListItemText>
                <ListItemIcon className="min-w-auto">
                  <Checkbox
                    checked={_state !== null && _state.id === state.id}
                    onChange={() => handleClicked(state)}
                    className="primary-checkbox"
                  />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ Rfq }) => {
  return {
    _state: Rfq._state,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { actions } = require("store/redux/RFQRedux");
  return {
    selectState: (data) => {
      dispatch(actions.selectState(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectState);
