import { gql } from "@apollo/client";

export default gql`
  query ($q: String, $cursor: Int, $is_buyer: Boolean, $category:[Int], $notIn:[Int] ) {
    UserSearch(first: 10, page: $cursor, email: $q, is_buyer: $is_buyer, category:$category, notIn:$notIn) {
      data {
        id
        username
        firstname
        lastname
        email
        tagline
        profile_photo
        is_seller
      }
      paginatorInfo {
        total
        count
        hasMorePages
        currentPage
        lastPage
      }
    }
  }
`;