import React, { useState } from 'react'
import { Tab } from '@headlessui/react';
import { Checkbox } from '@mui/material';

const DoBuyingAbout = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }
    return (
        <div className='container mt-20 intro-y'>
            <div className='inline-flex items-center gap-2'>
                <h6 className='font-bold text-lg Medium'>Result</h6>
                <span className='Regular text-base'>(15 items)</span>
            </div>
            <div className='grid mt-3 lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4'>
                <div className='border px-3 py-2'>
                    <div className='flex justify-between items-center'>
                        <h6 className='font-semibold Medium'>Owner client</h6>
                        <span className='font-semibold Medium'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </span>
                    </div>
                </div>
                <div className='border px-3 py-2'>
                    <div className='flex justify-between items-center'>
                        <h6 className='font-semibold Medium'>2 Industries</h6>
                        <span className='font-semibold Medium'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </span>
                    </div>
                </div><div className='border px-3 py-2'>
                    <div className='flex justify-between items-center'>
                        <h6 className='font-semibold Medium'>Buy Zone</h6>
                        <span className='font-semibold Medium'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </span>
                    </div>
                </div><div className='border px-3 py-2'>
                    <div className='flex justify-between items-center'>
                        <h6 className='font-semibold Medium'>Engry Strike</h6>
                        <span className='font-semibold Medium'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </span>
                    </div>
                </div><div className='border px-3 py-2'>
                    <div className='flex justify-between items-center'>
                        <h6 className='font-semibold Medium'>2 Categories</h6>
                        <span className='font-semibold Medium'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </span>
                    </div>
                </div>
            </div>
            <div className='flex justify-between items-center flex-wrap gap-3 mt-10'>
                <h6 className='text-lg font-medium'>Filter</h6>
                <div>
                    <input type="text" placeholder='Search here...' className="relative h-12 w-96 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10" />
                </div>
            </div>
            <div className='bg-white p-5 mt-4'>
                <h6 className='Medium font-bold'>Category</h6>
                <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                    <Tab.List className={'grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 py-10'}>
                        <Tab
                            className={({ selected }) =>
                                classNames(
                                    "w-full lg:py-2.5 py-4 fs-15 rounded-md leading-5 Regular hover:bg--primary hover:white",
                                    selected ? "bg--primary white rounded-md" : ""
                                )
                            }
                        >
                            Spend
                        </Tab>
                        <Tab
                            className={({ selected }) =>
                                classNames(
                                    "w-full lg:py-2.5 py-4 fs-15 rounded-md leading-5 Regular hover:bg--primary hover:white",
                                    selected ? "bg--primary white rounded-md" : ""
                                )
                            }
                        >
                            Supplier
                        </Tab>
                        <Tab
                            className={({ selected }) =>
                                classNames(
                                    "w-full lg:py-2.5 py-4 fs-15 rounded-md leading-5 Regular hover:bg--primary hover:white",
                                    selected ? "bg--primary white rounded-md" : ""
                                )
                            }
                        >
                            Contract
                        </Tab>
                    </Tab.List>
                    <Tab.Panels>
                        <Tab.Panel className="bg-transparent rounded-xl">
                            <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5'>
                                <div className='border'>
                                    <div className="flex items-center">
                                        <Checkbox
                                            className="primary-checkbox"
                                        />
                                        <p className="fs-12 font-bold">
                                            Energy Upstream
                                        </p>
                                    </div>
                                    <div className="flex items-center">
                                        <Checkbox
                                            className="primary-checkbox"
                                        />
                                        <p className="fs-12 font-bold">
                                            Energy Midstream
                                        </p>
                                    </div>
                                    <div className="flex items-center">
                                        <Checkbox
                                            className="primary-checkbox"
                                        />
                                        <p className="fs-12 font-bold">
                                            Energy Downstream
                                        </p>
                                    </div>
                                </div>
                                <div className='border'>
                                    <div className="flex items-center">
                                        <Checkbox
                                            className="primary-checkbox"
                                        />
                                        <p className="fs-12 font-bold">
                                            Energy Upstream
                                        </p>
                                    </div>
                                    <div className="flex items-center">
                                        <Checkbox
                                            className="primary-checkbox"
                                        />
                                        <p className="fs-12 font-bold">
                                            Energy Midstream
                                        </p>
                                    </div>
                                    <div className="flex items-center">
                                        <Checkbox
                                            className="primary-checkbox"
                                        />
                                        <p className="fs-12 font-bold">
                                            Energy Downstream
                                        </p>
                                    </div>
                                </div>
                                <div className='border'>
                                    <div className="flex items-center">
                                        <Checkbox
                                            className="primary-checkbox"
                                        />
                                        <p className="fs-12 font-bold">
                                            Energy Upstream
                                        </p>
                                    </div>
                                    <div className="flex items-center">
                                        <Checkbox
                                            className="primary-checkbox"
                                        />
                                        <p className="fs-12 font-bold">
                                            Energy Midstream
                                        </p>
                                    </div>
                                    <div className="flex items-center">
                                        <Checkbox
                                            className="primary-checkbox"
                                        />
                                        <p className="fs-12 font-bold">
                                            Energy Downstream
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Tab.Panel>
                        <Tab.Panel className="bg-transparent rounded-xl">
                            <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5'>
                                <div className='border'>
                                    <div className="flex items-center">
                                        <Checkbox
                                            className="primary-checkbox"
                                        />
                                        <p className="fs-12 font-bold">
                                            Energy Upstream
                                        </p>
                                    </div>
                                    <div className="flex items-center">
                                        <Checkbox
                                            className="primary-checkbox"
                                        />
                                        <p className="fs-12 font-bold">
                                            Energy Midstream
                                        </p>
                                    </div>
                                    <div className="flex items-center">
                                        <Checkbox
                                            className="primary-checkbox"
                                        />
                                        <p className="fs-12 font-bold">
                                            Energy Downstream
                                        </p>
                                    </div>
                                </div>
                                <div className='border'>
                                    <div className="flex items-center">
                                        <Checkbox
                                            className="primary-checkbox"
                                        />
                                        <p className="fs-12 font-bold">
                                            Energy Upstream
                                        </p>
                                    </div>
                                    <div className="flex items-center">
                                        <Checkbox
                                            className="primary-checkbox"
                                        />
                                        <p className="fs-12 font-bold">
                                            Energy Midstream
                                        </p>
                                    </div>
                                    <div className="flex items-center">
                                        <Checkbox
                                            className="primary-checkbox"
                                        />
                                        <p className="fs-12 font-bold">
                                            Energy Downstream
                                        </p>
                                    </div>
                                </div>
                                <div className='border'>
                                    <div className="flex items-center">
                                        <Checkbox
                                            className="primary-checkbox"
                                        />
                                        <p className="fs-12 font-bold">
                                            Energy Upstream
                                        </p>
                                    </div>
                                    <div className="flex items-center">
                                        <Checkbox
                                            className="primary-checkbox"
                                        />
                                        <p className="fs-12 font-bold">
                                            Energy Midstream
                                        </p>
                                    </div>
                                    <div className="flex items-center">
                                        <Checkbox
                                            className="primary-checkbox"
                                        />
                                        <p className="fs-12 font-bold">
                                            Energy Downstream
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Tab.Panel>
                        <Tab.Panel className="bg-transparent rounded-xl">
                            <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5'>
                                <div className='border'>
                                    <div className="flex items-center">
                                        <Checkbox
                                            className="primary-checkbox"
                                        />
                                        <p className="fs-12 font-bold">
                                            Energy Upstream
                                        </p>
                                    </div>
                                    <div className="flex items-center">
                                        <Checkbox
                                            className="primary-checkbox"
                                        />
                                        <p className="fs-12 font-bold">
                                            Energy Midstream
                                        </p>
                                    </div>
                                    <div className="flex items-center">
                                        <Checkbox
                                            className="primary-checkbox"
                                        />
                                        <p className="fs-12 font-bold">
                                            Energy Downstream
                                        </p>
                                    </div>
                                </div>
                                <div className='border'>
                                    <div className="flex items-center">
                                        <Checkbox
                                            className="primary-checkbox"
                                        />
                                        <p className="fs-12 font-bold">
                                            Energy Upstream
                                        </p>
                                    </div>
                                    <div className="flex items-center">
                                        <Checkbox
                                            className="primary-checkbox"
                                        />
                                        <p className="fs-12 font-bold">
                                            Energy Midstream
                                        </p>
                                    </div>
                                    <div className="flex items-center">
                                        <Checkbox
                                            className="primary-checkbox"
                                        />
                                        <p className="fs-12 font-bold">
                                            Energy Downstream
                                        </p>
                                    </div>
                                </div>
                                <div className='border'>
                                    <div className="flex items-center">
                                        <Checkbox
                                            className="primary-checkbox"
                                        />
                                        <p className="fs-12 font-bold">
                                            Energy Upstream
                                        </p>
                                    </div>
                                    <div className="flex items-center">
                                        <Checkbox
                                            className="primary-checkbox"
                                        />
                                        <p className="fs-12 font-bold">
                                            Energy Midstream
                                        </p>
                                    </div>
                                    <div className="flex items-center">
                                        <Checkbox
                                            className="primary-checkbox"
                                        />
                                        <p className="fs-12 font-bold">
                                            Energy Downstream
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </div>
    )
}

export default DoBuyingAbout