import { gql } from "@apollo/client";

export default gql`
mutation updateCategories(
    $categories:[Int!]!
  ) {
    updateCategories(
      categories:$categories
      ) {
          id
          categories{
              id
              name
          }
      }
    }
`;