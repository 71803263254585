import React from "react";
import { Loader, Noconnection } from "components";
import { FiLayers } from "react-icons/fi";
import { GiMoneyStack } from "react-icons/gi";
import { Query } from "@apollo/client/react/components";
import getAnaltics from "graphql/queries/getAnaltics";
import { RiNewspaperLine } from "react-icons/ri";
import amountFormat from "components/amountFormat/amountFormat";
import BuyerAnalytic from "../../../components/buyeranalytic";

function Analytic(props) {
  return (
    <div className="h-full">
      <div className="container mt-20 intro-y">
        {props?.is_seller ? (
          <div>
            <div className="intro-y flex items-center h-10">
              <h2 className="text-2xl Medium truncate">Analytics Summary</h2>
              {/* <div className="ml-auto flex Light primary items-center gap-x-3 cursor-pointer">
              <ImLoop2 size={15} />
              Reload Data
            </div>*/}
            </div>
            <Query query={getAnaltics} fetchPolicy="cache-and-network">
              {({ loading, error, data, fetchMore, refetch }) => {
                if (loading) return <Loader />;
                if (error) return <Noconnection />;
                if (data) {
                  if (
                    data.me.analtics &&
                    typeof data.me.analtics !== "undefined"
                  ) {
                    return (
                      <div className="grid grid-cols-12 gap-6 mt-5">
                        <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                          <div className="report-box zoom-in">
                            <div className="box p-5">
                              <div className="flex">
                                <FiLayers className="success" size={28} />
                              </div>
                              <div className="text-3xl Regular leading-8 mt-6">
                                {data.me.analtics.quote}
                              </div>
                              <div className="text-base text-gray-500 mt-1">
                                Quote Submited
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                          <div className="report-box zoom-in">
                            <div className="box p-5">
                              <div className="flex">
                                <RiNewspaperLine
                                  className="warning"
                                  size={32}
                                />
                              </div>
                              <div className="text-3xl Regular leading-8 mt-6">
                                {data.me.analtics.approved}
                              </div>
                              <div className="text-base text-gray-500 mt-1">
                                Rfqs Approved
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                          <div className="report-box zoom-in">
                            <div className="box p-5">
                              <div className="flex">
                                <GiMoneyStack className="warning" size={32} />
                              </div>
                              <div className="text-3xl Regular leading-8 mt-6">
                                {amountFormat(data.me.analtics.lost)}
                              </div>
                              <div className="text-base text-gray-500 mt-1">
                                Rfqs Lost
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                          <div className="report-box zoom-in">
                            <div className="box p-5">
                              <div className="flex">
                                <GiMoneyStack className="warning" size={32} />
                                {/* <div className="ml-auto">
                                  <Tooltips
                                    trigger={
                                      <div className="report-box__indicator fs-14 px-2 py-1 bg--warning  cursor-pointer">
                                        23
                                        <CgMathPercent />
                                        <FiChevronUp />
                                      </div>
                                    }
                                    toolcontent={"23% Heigher than last month"}
                                  />
                                </div> */}
                              </div>
                              <div className="text-3xl Regular leading-8 mt-6">
                                {amountFormat(data.me.analtics.total)}
                              </div>
                              <div className="text-base text-gray-500 mt-1">
                                Total Earned
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                }
              }}
            </Query>
          </div>
        ) : (
          <BuyerAnalytic />
        )}
      </div>
    </div>
  );
}

export default Analytic;
