import React, { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Link, useLocation, useParams } from "react-router-dom";
import client from "../../graphql/client";
import ACCOUNT_ACTIVATION from "../../graphql/mutations/accountActivationMutation";
import { toast } from "react-toastify";

export default function ActivationEmail() {
    const [message, setMessage] = useState('Please Wait...');
    const { token } = useParams();
    const location = useLocation();
    let path = location.pathname.split("/");

    const notify = (message = "", type = "error") => {
        // Dismiss any existing toasts before showing a new one
        toast.dismiss();
        if (type === "error") return toast.error(message, { position: "top-right" });
        else return toast.success(message, { position: "top-right" });
    };

    useEffect(() => {
        if (path[1] === 'activation') {
            client
                .mutate({
                    mutation: ACCOUNT_ACTIVATION,
                    variables: {
                        token: token
                    },
                })
                .then((response) => {
                    setMessage(response?.data?.accountActivation?.message)
                })
                .catch((error) => {
                    console.log(error);
                    notify("oops something went wrong", "error");
                });
        }
    }, []);

    return (
        <div className="h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-100">
            <div className="max-w-xl w-full space-y-8">
                <div className="report-box">
                    {path[1] !== 'activation' ?
                        <div className="intro-y box px-5 py-20 mt-5 overflow-hidden">
                            <div className="flex flex-col items-center space-y-5">
                                <h1 className="Medium lg:text-3xl text-4xl success mb-0">Thank You</h1>
                                <p className="text-xl text-center md:text-xl text-gray-500">
                                    Password reset link sent to your registered email address. Please log in and ensure the new password is working.
                                </p>

                                <Link
                                    to={"/"}
                                    className="bg-gradient-to-r from-yellow-500 to-yellow-400 text-yellow-900 hover:text-yellow-900  flex items-center h-12 rounded-xl px-10 hover:opacity-60"
                                >
                                    <IoIosArrowBack /> Go to Home
                                </Link>
                            </div>
                        </div> :
                        <div className="intro-y box px-5 py-20 mt-5 overflow-hidden">
                            <div className="flex flex-col items-center space-y-5">
                                <h1 className="Medium lg:text-3xl text-4xl success mb-0">Thank You</h1>
                                <p className="text-xl text-center md:text-xl text-gray-500">
                                    {message}
                                </p>

                                <Link
                                    to={"/login"}
                                    className="bg-gradient-to-r from-yellow-500 to-yellow-400 text-yellow-900 hover:text-yellow-900  flex items-center h-12 rounded-xl px-10 hover:opacity-60"
                                >
                                    <IoIosArrowBack /> Go to Login
                                </Link>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
