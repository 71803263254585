import {
  AUTH_USER_TOKEN,
  GET_AUTH_USER_INFORMATION,
  SET_SOCKET_ID,
  UPDATE_AUTH_USER_PROPERTY,
} from "../actions/types";
import { produce } from "immer";
const state = {
  authUserInformation: {},
  authUserToken: "",
  socket: {},
};

function AuthUserReducer(mState = { ...state }, action) {
  switch (action.type) {
    case GET_AUTH_USER_INFORMATION:
      return produce(mState, (draftState) => {
        draftState.authUserInformation = action.payload;
      });
    case UPDATE_AUTH_USER_PROPERTY: {
      const { key, value } = action.payload;
      return {
        ...mState,
        authUserInformation: { ...mState.authUserInformation, [key]: value }
      };
    }
    // return produce(mState, (draftState) => {
    //   // Update the specified property based on the payload
    //   const { key, value } = action.payload;
    //   // console.log('authUserInformation', key, value, mState.authUserInformation);
    //   console.log('draftState', draftState.authUserInformation);
    //   draftState.authUserInformation[key] = value;
    // });
    case AUTH_USER_TOKEN:
      return produce(mState, (draftState) => {
        draftState.authUserToken = action.payload;
      });
    case SET_SOCKET_ID:
      return produce(mState, (draftState) => {
        draftState.socket = action.payload;
      });
    default:
      return { ...mState };
  }
}

export default AuthUserReducer;
