export const GRAPH_MRO_USER_AUTH_TOKEN = "GRAPH_MRO_USER_AUTH_TOKEN";
export const AUTH_USER_MRO = "AUTH_USER_MRO";
export const WEB_URL = "https://sourcinggenie.io";
export const PROFILE_PHOTO_URL = "/uploads/profile_images/";

/*PL LINKS*/
export const PL_WEB_URL = "https://web.procurementleague.com";
export const PL_PROFILE_PHOTO_URL = "https://procurementleague.com/uploads/profile_images/";
// export const PL_WEB_URL = "http://localhost:3000";
// export const PL_PROFILE_PHOTO_URL = "http://192.168.18.14:9000/uploads/profile_images/";

export const MAIN_URL = "https://mro.procurementleague.com";
export const GRAPH_URL = "https://mro.procurementleague.com/graphql";

// export const MAIN_URL = "http://192.168.18.14:8000";
// export const GRAPH_URL = "http://192.168.18.14:8000/graphql";

export const CDN = "https://d2pjglp4tgg94c.cloudfront.net/general.jpg";
export const S3 = "https://mro-prod-content.s3.amazonaws.com/";

/*SOCIAL LINKS*/
export const ANDROID_APP_URL = "https://play.google.com/store/apps/details?id=com.pro.procurementleague&hl=en";
export const IOS_APP_URL = "https://apps.apple.com/us/app/procurement-league/id1494128878";
export const FACEBOOK_URL = "https://www.facebook.com/procurementLeague/";
export const LINKEDIN_URL = "https://www.linkedin.com/company/procurement-league/";
export const TWITTER_URL = "https://twitter.com/procurementlea";
/*------SOCIAL LINKS END------*/

/*GOOGLE PAYMENT CONFIGURATION*/
export const PAYEEZY_PAY_PAYMENT_GATEWAY_NAME = "firstdata";

/*Live Payment*/
// export const PAYMENT_GATEWAY_ENVIRONMENT = "PRODUCTION";
// export const PAYMENT_GATEWAY_MERCHANT_ID = "374251806880";

/*Sandbox Payment*/
export const PAYMENT_GATEWAY_ENVIRONMENT = "TEST";
export const PAYMENT_GATEWAY_MERCHANT_ID = "3176752955";

/*GOOGLE PAY ACCOUNT DETAILS*/
export const GOOGLE_PAY_MERCHANT_ID = "BCR2DN4T3DA2VPYB";
export const GOOGLE_PAY_MERCHANT_NAME = "Procurement League Inc";
