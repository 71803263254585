import { gql } from "@apollo/client";

export default gql`
  query Me {
    me {
      id
      username
      firstname
      lastname
      country_id
      cross_token
      is_verified
      phone_number
      profile_photo
      plan_expire_date
      has_pl_account
      plan_id
      is_plan_expired
      plan_status
      role_allowed
      tagline
      plan_id
      plan_expire_date
      email
      timestamp
      is_seller
      become_seller
      company_linkedin_profile
      address
      file_type
      isSellerProfileCompleted
      sellerReviews {
        id
        offer_id
        seller_id
        buyer_id
        offer {
          id
          title
          categories {
            id
            name
            code
          }
          tags {
            id
            name
          }
        }
        rate
        comment
        is_buyer
        # seller
        buyer {
          id
          username
          firstname
          lastname
          profile_photo
        }
        responsiveness
        shipment
        lead_time
        delivered
        compliance
      }
      invitation_count
      #      sellerReviews {
      #        id
      #        offer_id
      #        seller_id
      #        buyer_id
      #        rate
      #        comment
      #        is_buyer
      #        # seller
      #        # buyer
      #        responsiveness
      #        shipment
      #        lead_time
      #        delivered
      #        compliance
      #      }
      companies(first: 20) {
        data {
          id
          title
          slug
          address
          city
          state_id
          latitude
          longitude
          country
          country_code
          postal_code
          phone
          email
          history
          linked_in_profile
          logo
          is_active
          is_deleted
          added_from
          is_primary
          state {
            id
            name
          }
          executives {
            id
            firstname
            lastname
            email
            username
            profile_photo
          }
        }
      }
      primaryAddress {
        id
        name
        address_1
        address_2
        email
        phone
        city
        state_id
        is_primary
        state {
          id
          name
        }
        country_code
        zip_code
      }
      country {
        id
        name
        sortname
        phonecode
      }
      state {
        id
        name
      }
      categories {
        id
        name
        code
      }
      keyContacts {
        id
        username
        firstname
        lastname
        profile_photo
      }
      preffered_vendors {
        preffered_vendor_id
        seller_id
        buyer_id
        buyer_reviews_avg_rate
        seller_reviews_avg_rate
        seller_reviews_avg_responsiveness
        seller_reviews_avg_shipment
        seller_reviews_avg_lead_time
        seller_reviews_avg_delivered
        seller_reviews_avg_compliance
        user {
          id
          username
          firstname
          lastname
          profile_photo
          is_seller
        }
      }
      reviews {
        id
        offer_id
        seller_id
        buyer_id
        rate
        comment
        is_buyer
        # seller
        # buyer
        responsiveness
        shipment
        lead_time
        delivered
        compliance
      }
      payments {
        id
        plan_id
        user_id
        status
        transaction_id
        currency_code
        total_payment
        is_active
        plan_expire_date
        created_at
        plan {
          plan_id
          title
          subtitle
          price
          validity
          plan_features {
            plan_feature_id
            name
            feature_type
            quantity
          }
          user_plan_features {
            plan_feature_id
            name
            feature_type
            payment_id
            quantity
          }
        }
      }
    }
  }
`;
