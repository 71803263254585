import { gql } from "@apollo/client";

export default gql`
  mutation createReview(
    $seller_id: Int
    $buyer_id: Int
    $offer_id: Int
    $product_id: Int
    $comment: String
    $rate: Float
    $is_buyer: Boolean
    $responsiveness: Float
    $shipment: Float
    $lead_time: Float
    $delivered: Float
    $compliance: Float
  ) {
    createReview(
      seller_id: $seller_id
      buyer_id: $buyer_id
      product_id: $product_id
      comment: $comment
      rate: $rate
      offer_id: $offer_id
      is_buyer: $is_buyer
      responsiveness: $responsiveness
      shipment: $shipment
      lead_time: $lead_time
      delivered: $delivered
      compliance: $compliance
    ) {
      id
      offer_id
      product_id
      seller_id
      buyer_id
      rate
      comment
      is_buyer
      responsiveness
      shipment
      lead_time
      delivered
      compliance
    }
  }
`;
