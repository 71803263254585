import { gql } from "@apollo/client";

export default gql`
  mutation createOfferParticipant(
  $offer_id:Int!
  $amount:Float!
  $comment:String
  $document:String
  $again: Boolean
  $expected_date:String
  $products: [Object]
) {
    createOfferParticipant(
      offer_id:$offer_id
      amount:$amount
      comment:$comment
      products:$products
      document:$document
      again:$again
        expected_date:$expected_date
    ) {
        id
    }
  }
`;

