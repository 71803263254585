import { gql } from "@apollo/client";

export default gql`
  query ($offer_id: ID) {
    offerViews(offer_id: $offer_id) {
      id
      created_at
      user {
        id
        username
        firstname
        lastname
        profile_photo
        is_seller
        become_seller
      }
    }
  }
`;
