import React, { useState, useEffect } from "react";
import { Buttonlink, Loader } from "components";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import PaymentPlanNavigation from "./navigation";
// import { AiOutlineQuestionCircle } from "react-icons/ai";
// import { FiCheck, FiX } from "react-icons/fi";
// import { Popup } from "semantic-ui-react";
// import { gql, useQuery } from "@apollo/client";
// import { Query } from "@apollo/client/react/components";
// import client from "graphql/client";
import { connect } from "react-redux";
// import GET_PLANS from "../../../graphql/queries/getPlans";

// const GET_FEATURES = gql`
//   query {
//     getFeatures {
//       plan_feature_id
//       name
//       tooltip_note
//     }
//   }
// `;

function BuyerSidePlan(props) {
  // const [singlePackage, setsinglePackage] = useState(true);
  // const [singlePackage2, setsinglePackage2] = useState(true);
  // const [windowSize, setwindowSize] = useState(window.innerWidth);
  // const [FeaturesData, setFeaturesData] = useState([]);
  // const [PlansData, setPlansData] = useState([]);
  // const [disabled, setdisabled] = useState(null);

  // const resizeWidth = () => {
  //   setwindowSize({ windowSize: window.innerWidth });
  // };

  // useEffect(() => {
  //   window.addEventListener("resize", resizeWidth);
  //   client
  //     .query({
  //       query: GET_FEATURES,
  //     })
  //     .then((res) => {
  //       setFeaturesData(res.data.getFeatures);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });

  //   client
  //     .query({
  //       query: GET_PLANS,
  //     })
  //     .then((res) => {
  //       setPlansData(res.data.getPlans);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);
  // const singlePackageClick = () => {
  //   setsinglePackage({ singlePackage: false });
  //   setsinglePackage2({ singlePackage2: true });
  // };
  // const prevSinglePackageClick = () => {
  //   setsinglePackage({ singlePackage: true });
  //   setsinglePackage2({ singlePackage2: false });
  // };

  // const { loading, error, data } = useQuery(GET_FEATURES);

  // if (loading) return <Loader spinner={true} />;
  // if (error) return `Error! ${error.message}`;
  // console.log("props.user", props.user);
  // console.log("item", PlansData);
  return (
    <div className="container mt-20">
      <div className="relative mb-20">
        <h2 className="Medium text-4xl text-center font-bold">Payment Plan</h2>
      </div>
      <div className="relative mb-20">
        <PaymentPlanNavigation />
      </div>
      <div className="shadow--sm rounded-lg bg-white overflow-hidden border sm:p-10">
        <div className="flex ">
          <div className="grow">
            <div className="flex items-center h-16 relative">
              <h1 className="Medium text-lg lg:text-2xl lg:flex hidden">
                What kind of plan are you interested in?
              </h1>
              <h1 className="Medium text-lg lg:text-2xl lg:hidden flex">
                Plan Features
              </h1>
              {/* {singlePackage === false ? ( */}
              <div
                // onClick={prevSinglePackageClick}
                className="absolute right-0 lg:hidden block"
              >
                <IoIosArrowBack className="primary" size={24} />
                <p className="fs-12 primary">prev</p>
              </div>
              {/* ) : null} */}
              {/* {singlePackage === true ? ( */}
              <div
                // onClick={singlePackageClick}
                className="absolute right-0 lg:hidden block"
              >
                <IoIosArrowForward className="primary" size={24} />
                <p className="fs-12 primary">next</p>
              </div>
              {/* ) : null} */}
            </div>
            <div>
              <div class="h-12 lg:h-16 flex items-center"></div>
              <div className="h-12 lg:h-16 flex items-center">
                <h4 className="Regular text-sm lg:text-lg mr-1 text-color">
                  Validity
                </h4>
              </div>
              <div className="h-12 lg:h-16 flex items-center">
                <h4 className="Regular text-sm lg:text-lg mr-1 text-color">
                  No. of RFQs per month
                </h4>
              </div>
              <div className="h-12 lg:h-16 flex items-center">
                <h4 className="Regular text-sm lg:text-lg mr-1 text-color">
                  No. of teams per month
                </h4>
              </div>
              <div className="h-12 lg:h-16 flex items-center">
                <h4 className="Regular text-sm lg:text-lg mr-1 text-color">
                  Dual Role on same email account
                </h4>
              </div>
              <div className="h-12 lg:h-16 flex items-center">
                <h4 className="Regular text-sm lg:text-lg mr-1 text-color">
                  Assigned role to team
                </h4>
              </div>
              <div className="h-12 lg:h-16 flex items-center">
                <h4 className="Regular text-sm lg:text-lg mr-1 text-color">
                  seller reviews
                </h4>
              </div>
              <div className="h-12 lg:h-16 flex items-center">
                <h4 className="Regular text-sm lg:text-lg mr-1 text-color">
                  Switch Profile PL - SG / SG - PL button
                </h4>
              </div>
              <div className="h-12 lg:h-16 flex items-center">
                <h4 className="Regular text-sm lg:text-lg mr-1 text-color">
                  no. of views of sellers on a RFQ
                </h4>
              </div>
            </div>
          </div>
          {/* {singlePackage || windowSize > 1023
            ? PlansData.map((item, index) => {
                return (
                  <> */}
          <div className="relative grow border rounded-[27px] border-[#F68020] lg:min-w-[219px]">
            <div className="flex items-center justify-center w-full Regular h-16">
              <div className="w-full h-[48px] bg-[#F68020] text-white hover:text-white max-w-[179px] absolute top-[-10px] flex items-center justify-center border-0 rounded-lg  px-5">
                Free Plan
              </div>
            </div>
            <div className="flex items-center justify-center flex-col border-b h-16">
              <h4 className="Medium text-sm lg:text-3xl">$ 0</h4>
            </div>
            <div className="h-12 lg:h-16 flex items-center justify-center border-b">
              <p>2 Month</p>
            </div>
            <div className="h-12 lg:h-16 flex items-center justify-center border-b">
              <p>3</p>
            </div>
            <div className="h-12 lg:h-16 flex items-center justify-center border-b">
              <p>1</p>
            </div>
            <div className="h-12 lg:h-16 flex items-center justify-center border-b">
              <p>No</p>
            </div>
            <div className="h-12 lg:h-16 flex items-center justify-center border-b">
              <p>No</p>
            </div>
            <div className="h-12 lg:h-16 flex items-center justify-center border-b">
              <p>Limited</p>
            </div>
            <div className="h-12 lg:h-16 flex items-center justify-center border-b">
              <p>No</p>
            </div>
            <div className="h-12 lg:h-16 flex items-center justify-center border-b">
              <p>No</p>
            </div>
            <div className="h-20 lg:h-20 flex items-center justify-center">
              <Buttonlink
                to={"#"}
                // state={item}
                text={"Select"}
                rel="nofollow"
                className={`bg-[#EF553B] text-white hover:text-white flex-grow flex items-center justify-center max-w-[179px] w-full absolute bottom-[-10px] Regular hover:bg--primary hover:opacity-70 focus:bg--primary border-0 rounded--full shadow-none h-12 px-5`}
              />
            </div>
          </div>
          <div className="relative grow border rounded-[27px] border-[#F68020] lg:min-w-[219px]">
            <div className="flex items-center justify-center w-full Regular h-16">
              <div className="w-full h-[48px] bg-[#F68020] text-white hover:text-white max-w-[179px] absolute top-[-10px] flex items-center justify-center border-0 rounded-lg  px-5">
                Free Plan
              </div>
            </div>
            <div className="flex items-center justify-center flex-col border-b h-16">
              <h4 className="Medium text-sm lg:text-3xl">$ 0</h4>
            </div>
            <div className="h-12 lg:h-16 flex items-center justify-center border-b">
              <p>2 Month</p>
            </div>
            <div className="h-12 lg:h-16 flex items-center justify-center border-b">
              <p>3</p>
            </div>
            <div className="h-12 lg:h-16 flex items-center justify-center border-b">
              <p>1</p>
            </div>
            <div className="h-12 lg:h-16 flex items-center justify-center border-b">
              <p>No</p>
            </div>
            <div className="h-12 lg:h-16 flex items-center justify-center border-b">
              <p>No</p>
            </div>
            <div className="h-12 lg:h-16 flex items-center justify-center border-b">
              <p>Limited</p>
            </div>
            <div className="h-12 lg:h-16 flex items-center justify-center border-b">
              <p>No</p>
            </div>
            <div className="h-12 lg:h-16 flex items-center justify-center border-b">
              <p>No</p>
            </div>
            <div className="h-20 lg:h-20 flex items-center justify-center">
              <Buttonlink
                to={"#"}
                // state={item}
                text={"Select"}
                rel="nofollow"
                className={`bg-[#EF553B] text-white hover:text-white flex-grow flex items-center justify-center max-w-[179px] w-full absolute bottom-[-10px] Regular hover:bg--primary hover:opacity-70 focus:bg--primary border-0 rounded--full shadow-none h-12 px-5`}
              />
            </div>
          </div>
          <div className="relative grow border rounded-[27px] border-[#F68020] lg:min-w-[219px]">
            <div className="flex items-center justify-center w-full Regular h-16">
              <div className="w-full h-[48px] bg-[#F68020] text-white hover:text-white max-w-[179px] absolute top-[-10px] flex items-center justify-center border-0 rounded-lg  px-5">
                Free Plan
              </div>
            </div>
            <div className="flex items-center justify-center flex-col border-b h-16">
              <h4 className="Medium text-sm lg:text-3xl">$ 0</h4>
            </div>
            <div className="h-12 lg:h-16 flex items-center justify-center border-b">
              <p>2 Month</p>
            </div>
            <div className="h-12 lg:h-16 flex items-center justify-center border-b">
              <p>3</p>
            </div>
            <div className="h-12 lg:h-16 flex items-center justify-center border-b">
              <p>1</p>
            </div>
            <div className="h-12 lg:h-16 flex items-center justify-center border-b">
              <p>No</p>
            </div>
            <div className="h-12 lg:h-16 flex items-center justify-center border-b">
              <p>No</p>
            </div>
            <div className="h-12 lg:h-16 flex items-center justify-center border-b">
              <p>Limited</p>
            </div>
            <div className="h-12 lg:h-16 flex items-center justify-center border-b">
              <p>No</p>
            </div>
            <div className="h-12 lg:h-16 flex items-center justify-center border-b">
              <p>No</p>
            </div>
            <div className="h-20 lg:h-20 flex items-center justify-center">
              <Buttonlink
                to={"#"}
                // state={item}
                text={"Select"}
                rel="nofollow"
                className={`bg-[#EF553B] text-white hover:text-white flex-grow flex items-center justify-center max-w-[179px] w-full absolute bottom-[-10px] Regular hover:bg--primary hover:opacity-70 focus:bg--primary border-0 rounded--full shadow-none h-12 px-5`}
              />
            </div>
          </div>
          {/* </>
                );
              })
            : null} */}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ Auth }) => {
  return {
    user: Auth.authUserInformation,
  };
};
export default connect(mapStateToProps, null)(BuyerSidePlan);
