import React, { Component } from "react";
import { Allquestions } from "navigation";
import { Subbanner } from "components";
import { MdQuestionAnswer } from "react-icons/md";

export default class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <Subbanner title="Details" />
        <div className="container -mt-20">
          <div className="grid xl:grid-cols-4 lg:grid-cols-1 gap-4">
            <div className="hidden xl:block">
              <div className="overflow-hidden intro-y">
                <div className="px-5 py-10 space-y-5">
                  <div className="flex justify-center">
                    <MdQuestionAnswer size={200} className="primary" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-2 rounded--xl overflow-hidden">
              <Allquestions />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
