import React, { useState, useEffect } from "react";
import { Buttonlink } from "components";
import { FiWifiOff } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";

export default function Noconnection() {
  const [path, setPath] = useState("");
  useEffect(() => {
    setPath(window.location.pathname);
  });

  return (
    <div className="flex items-center justify-center flex-col">
      <FiWifiOff size={82} className="gray text-center" />
      <p className="text-center text-3xl darkGray my-3">
        No Internet Connection
      </p>
      <p className="text-center gray">Could not connected to the network</p>
      <p className="text-center gray">Please check and try again.</p>
      {path != "/" ? (
        <Buttonlink
          text="Go to Home"
          prefix={<IoIosArrowBack />}
          to="/"
          className="primary hover:primary flex items-center h-10 rounded-xl px-4 hover:opacity-80 mt-3"
        />
      ) : null}
    </div>
  );
}
