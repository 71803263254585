import { gql } from "@apollo/client";

export default gql`
  mutation setUserRolesForCompany(
    $company_id: ID
    $offer_id: Int
    $userRoles: [Object!]!
  ) {
    setUserRolesForCompany(userRoles: $userRoles) {
      id
      company_id
      executives {
        id
        username
        userCompanyOfferRole(company_id: $company_id, offer_id: $offer_id)
      }
    }
  }
`;
