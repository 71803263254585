import { gql } from "@apollo/client";

export default gql`
   query nonAdminCompanies($slug: String) {
  nonAdminCompanies(slug: $slug) {
    id
    title
    slug
    address
    city
    country
    is_active
    is_deleted
    is_primary
    executives {
      id
      username
      firstname
      lastname
      __typename
    }
    users{
      id
      username
    }
     
    __typename
  }
}
`;
