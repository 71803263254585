import React from 'react'
import { Pagination } from 'semantic-ui-react'

function PaginationCompact(props) {

    // console.log("props", props)
    let payload = props.payload
    return (
        <Pagination
            activePage={payload.currentPage}
            onPageChange={(event, data) => props.callback(data.activePage)}
            pointing
            secondary
            totalPages={payload.lastPage}
        />
    )
}

export default PaginationCompact