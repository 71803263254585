import { gql } from "@apollo/client";
export default gql`
    query offers(
        $cursor: Int
        $me: Boolean
        $my: Boolean
        $public: Boolean
        $saved: Boolean
        $active: Boolean
        $expired: Boolean
        $work_in_progress: Boolean
        $submited: Boolean
        $approved: Int
        $general: Boolean
        $awarded: Boolean
        $latest_action: Boolean
        $most_responsed: Boolean
        $seller_most_responsed: Boolean
        $vendor_reference: Boolean
        $help_expert: Boolean
        $no_response: Boolean
        $invitation: Boolean
        $draft: Boolean
        $visibility: String
        $tags: [Int]
        $category: [Int]
        $status: String
        $search: String
        $spend: Boolean
        $byMonth: Int
        $lost: Boolean
        $created:String
        $date_range:Object
        $executive_offers:String
        $orderBy:[OrderByClause!]
    ) {
        offers(
            first: 12
            me: $me
            my: $my
            public: $public
            saved: $saved
            active: $active
            expired: $expired
            work_in_progress: $work_in_progress
            submited: $submited
            approved: $approved
            general: $general
            awarded: $awarded
            lost: $lost
            latest_action: $latest_action
            most_responsed: $most_responsed
            seller_most_responsed: $seller_most_responsed
            vendor_reference: $vendor_reference
            help_expert: $help_expert
            no_response: $no_response
            invitation: $invitation
            visibility: $visibility
            tags: $tags
            category: $category
            search: $search
            spend: $spend
            draft: $draft
            status: $status
            page: $cursor
            byMonth: $byMonth
            orderBy: $orderBy
            created:$created
            date_range:$date_range
            executive_offers:$executive_offers
        ) {
            data {
                id
                title
                last_date
                created_at
                type
                readable_status
                status
                isBookmarked
                isAccess
                isAuthor
                isRecommendation
                menu {
                    id
                    code
                }
                recommendations{
                    id
                    title
                    description
                }
                user {
                    id
                    username
                    firstname
                    lastname
                    profile_photo
                }
                tags {
                    id
                    name
                }
                categories {
                    id
                    name
                    image_url
                }
                participants(first: 10) {
                    data {
                        id
                        created_at
                        total
                        user {
                            id
                            firstname
                            lastname
                            username
                            profile_photo
                            email
                        }
                        readable_status
                    }
                    paginatorInfo {
                        total
                        lastItem
                        currentPage
                        hasMorePages
                    }
                }
            }
            paginatorInfo {
                total
                lastItem
                currentPage
                hasMorePages
            }
        }
    }
`;
