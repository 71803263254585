import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Buttonlink,
  Fullscreenpopup,
  Subbanner,
  Loader,
  Noconnection,
  Nocontent,
  MoreCategoriesPopup,
} from "components";
import offerSaveBookmark from "../../../graphql/mutations/offerSaveBookmark";
import offerUnSaveBookmark from "../../../graphql/mutations/offerUnSaveBookmark";
import { convertToSlug, encryptfunction } from "helpers";
import Moment from "react-moment";
import { IoIosArrowBack, IoIosCloseCircle } from "react-icons/io";
import {
  HiOutlineSearch,
  HiOutlineChevronDoubleRight,
  HiOutlineChevronDoubleLeft,
} from "react-icons/hi";
import { BsBookmark } from "react-icons/bs";
import { BsThreeDots } from "react-icons/bs";
import { Dropdown } from "semantic-ui-react";
import GET_OFFERS from "../../../graphql/queries/get_offers_for_ledger";
import client from "graphql/client";
import { connect } from "react-redux";
import { Query } from "@apollo/client/react/components";
import { gql } from "@apollo/client";
import "./styles.css";
import { GRAPH_MRO_USER_AUTH_TOKEN } from "../../../config/constants";
import { VscFilePdf } from "react-icons/vsc";
import cookie from "js-cookie";
import LoadMoreData from "components/loadMoreData";
import DateRangePicker from "../../../components/daterangepicker";
import moment from "moment";
import { BiSortAlt2 } from "react-icons/bi";
import getExecutiveRoles from "graphql/queries/getExecutiveRoles";

const buyerStatus = [
  {
    key: "publish",
    text: "Publish",
    value: "publish",
  },
  {
    key: "draft",
    text: "Draft",
    value: "draft",
  },
  {
    key: "expire",
    text: "Expire",
    value: "expire",
  },
  {
    key: "pending",
    text: "Pending",
    value: "pending",
  },
  {
    key: "closed",
    text: "Closed",
    value: "closed",
  },
  {
    key: "complete",
    text: "Complete",
    value: "complete",
  },
  {
    key: "revise",
    text: "Revise",
    value: "revise",
  },
  {
    key: "block",
    text: "Rejected",
    value: "block",
  },
];
const sellerStatus = [
  {
    key: "complete",
    text: "Complete",
    value: "complete",
  },
  {
    key: "revise",
    text: "Revise",
    value: "revise",
  },
  {
    key: "block",
    text: "Rejected",
    value: "block",
  },
];

class RfqLedger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: "",
      search: "",
      ticket_type: this.props.params ?? "",
      loadMoreQuestions: true,
      Offersdata: [],
      loading: false,
      catdata: [],
      tagData: [],
      date_range: {},
      scrollX: 0,
      scrolEnd: false,
      orderByState: [{ field: "updated_at", order: 'DESC' }],
      executiveOptions: [],
      executiveMode: 'Manager'
    };
    this.MoreCategoryPopup = React.createRef();
    this.SelectFullPopup = React.createRef();
    this.lastScroll = React.createRef();
  }
  openPopupModal = (data) => {
    const catepush = data.categories;
    this.setState({
      open: false,
      catdata: catepush,
    });
    this.MoreCategoryPopup.current.openPopup();
  };

  openPopupModalTag = (data) => {
    const tagPush = data.tags;
    this.setState({
      open: true,
      tagData: tagPush,
    });
    this.MoreCategoryPopup.current.openPopup();
  };

  ChooseCategores = () => {
    // this.setState({
    //   open: true,
    // });
    this.SelectFullPopup.current.openPopup();
  };

  moveForwrd = (slide) => {
    this.lastScroll.current.scrollLeft += slide;
    this.setState({ scrollX: this.state.scrollX + slide });

    if (
      Math.floor(
        this.lastScroll.current.scrollWidth - this.lastScroll.current.scrollLeft
      ) <= this.lastScroll.current.offsetWidth
    ) {
      this.setState({ scrolEnd: true });
    } else {
      this.setState({ scrolEnd: false });
    }
  };
  scrollCheck = () => {
    this.setState({ scrollX: this.lastScroll.current.scrollLeft });
    if (
      Math.floor(
        this.lastScroll.current.scrollWidth - this.lastScroll.current.scrollLeft
      ) <= this.lastScroll.current.offsetWidth
    ) {
      this.setState({ scrolEnd: true });
    } else {
      this.setState({ scrolEnd: false });
    }
  };

  handlesearch = (e) => {
    this.setState({
      search: e.target.value,
    });
  };

  handlestatus = (event, data) => {
    this.setState({
      ...this.state,
      ticket_type: data.value,
    });

    // if (
    //   this. &&
    //   variables.status !== undefined &&
    //   variables.status !== ""
    // ) {
    this.props.navigate({
      search: `?searched=${data.value}`,
    });
    // }
  };
  handleExecutiveStatus = (event, data) => {
    this.setState({
      executiveMode: data.value,
    });

    // if (
    //   this. &&
    //   variables.status !== undefined &&
    //   variables.status !== ""
    // ) {
    this.props.navigate({
      search: `?searched=${data.value}`,
    });
    // }
  };

  loadMoreUsers = (data, fetchMore, refetch) => {
    this.setState({
      loading: true,
    });
    fetchMore({
      variables: {
        cursor: data.offers.paginatorInfo.currentPage + 1,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newQuestions = fetchMoreResult.offers.data;
        const pageInfo = fetchMoreResult.offers.paginatorInfo;
        /* if (data.offers.paginatorInfo.total !== pageInfo.total) {
          refetch();
        }*/
        if (pageInfo.hasMorePages) {
          this.setState({
            loadMoreQuestions: true,
            loading: false,
          });
        } else {
          this.setState({
            loadMoreQuestions: false,
            loading: false,
          });
        }

        return newQuestions.length
          ? {
            offers: {
              __typename: previousResult.offers.__typename,
              data: [...previousResult.offers.data, ...newQuestions],
              paginatorInfo: pageInfo,
            },
          }
          : previousResult;
      },
    });
  };

  componentDidMount = () => {
    this.getExecutive();
    this.getOffersQuery();
    this.props.addRfqForm({});
    this.props.selectCategories([]);
    this.props.selectTags({});
  };

  getOffersQuery = () => {
    const orderBy = [{ field: "updated_at", order: "DESC" }]
    let variables = { saved: true, orderBy };
    client
      .query({
        query: GET_OFFERS,
        fetchPolicy: "network-only",
        variables,
      })
      .then((res) => {
        this.setState({
          Offersdata: res.data.offers,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  addFields = (new_date) => {
    // console.log("new_date",new_date)
    this.setState({ date_range: new_date[0] });
    //   const last_date = moment(new_date).format(
    //       "YYYY-MM-DD hh:mm:ss"
    //   );
    //   this.addFields("last_date", last_date);
  };

  addBookmark = (id, bookMark) => {
    client
      .mutate({
        mutation: offerSaveBookmark,
        variables: {
          offer_id: id,
        },
        optimisticResponse: {
          __typename: "Mutation",
          offerSaveBookmark: {
            __typename: "offerSaveBookmark",
            id: id,
            isBookmarked: true,
          },
        },
        update: (store, { data: { offerSaveBookmark } }) => {
          try {
            store.writeQuery({
              query: gql`
                query Offer($id: ID) {
                  offer(id: $id) {
                    id
                    isBookmarked
                  }
                }
              `,
              data: {
                offer: {
                  __typename: "Offer",
                  id: offerSaveBookmark.id,
                  isBookmarked: offerSaveBookmark.isBookmarked,
                },
              },
              variables: {
                id: id,
              }.then(() => {
                // console.log("thenennene");
              }),
            });
          } catch (e) { }
        },
      })
      .then(() => {
        this.getOffersQuery();
      })
      .catch(() => { });
    this.props.rfqPublishorUnpublished(true);
  };

  removeBookmark = (id, bookMark) => {
    client
      .mutate({
        mutation: offerUnSaveBookmark,
        variables: {
          offer_id: id,
        },
        optimisticResponse: {
          __typename: "Mutation",
          offerUnSaveBookmark: {
            __typename: "OfferUnSaveBookmark",
            id: id,
            isBookmarked: false,
          },
        },

        update: (store, { data: { OfferUnSaveBookmark } }) => {
          try {
            store.writeQuery({
              query: gql`
                query Offer($id: ID) {
                  offer(id: $id) {
                    id
                    isBookmarked
                  }
                }
              `,
              data: {
                offer: {
                  __typename: "Offer",
                  id: OfferUnSaveBookmark.id,
                  isBookmarked: OfferUnSaveBookmark.isBookmarked,
                },
              },
              variables: {
                id: id,
              },
            });
          } catch (e) { }
        },
      })
      .then(() => {
        this.getOffersQuery();
      })
      .catch(() => { });
    this.props.rfqPublishorUnpublished(true);

    // setTimeout(() => {
    //   this.getOffersQuery();
    // }, 500);
  };

  handleDateChangeRaw = (newField) => {
    let newOrder = "ASC"; // The default order
    const { orderByState } = this.state;

    // Check if the field already exists in orderByState
    const existingField = orderByState.find(item => item.field === newField);

    if (existingField) {
      // Toggle the order
      newOrder = existingField.order === "ASC" ? "DESC" : "ASC";
    }

    // Update the state
    this.setState({
      orderByState: [{ field: newField, order: newOrder }]
    });
  };

  resetAllSearchedStates = () => {
    this.setState({
      search: "",
      date_range: {
        ...this.state.date_range,
        startDate: '', // Empty string to reset the date
        endDate: '',
      },
      ticket_type: ""
    }, () => {
      this.props.addRfqForm({});
      this.props.selectCategories([]);
      this.props.selectTags({});
    })
  }
  getExecutive = () => {
    client
      .query({
        query: getExecutiveRoles
      })
      .then((res) => {
        let options = res.data.executive_roles.map((e, index) => ({
          key: index,
          text: e.name,
          value: e.name,
        }));
        this.setState({
          executiveOptions: options,
        });
      })
      .catch((err) => {
        console.log("executives roles error", err);
      });
  };
  render() {
    const url = process.env.REACT_APP_WEB_URL;
    const hasDev = url.includes("dev");
    let ids = [];
    if (this.props.form && this.props.form.category) {
      this.props.form.category.map((category) => {
        ids.push(parseInt(category.id));
      });
    }

    const variables = {};
    variables.orderBy = this.state.orderByState
    if (this.props.user.is_seller) {
      variables.submited = true;
      delete variables.my;
    } else {
      delete variables.submited;
      variables.me = true;
    }
    if (this.state.search.length > 0) {
      variables.search = this.state.search;
    }
    if (this.state.date_range?.startDate && this.state.date_range?.endDate) {
      variables.date_range = this.state.date_range;
    }
    if (ids.length > 0) {
      variables.category = ids;
    }
    if (this.state.ticket_type.length !== 0) {
      variables.status = this.state.ticket_type;
    }
    if (this.state.executiveMode !== '' && !this.props.user.is_seller) {
      variables.executive_offers = this.state.executiveMode;
    }

    const { Offersdata } = this.state;
    // console.log("date_range",this.state.date_range)
    let today = new Date();
    let hasInputValues = !!(variables.search || variables.category || variables.date_range || variables.status);
    return (
      <>
        <Subbanner title="My RFQ Ledger" />
        <div className="container -mt-28">
          <div className="mb-5">
            <Buttonlink
              text="Back"
              prefix={<IoIosArrowBack size={16} />}
              onClick={() => this.props.navigate(-1)}
              className="relative bg-gray-200 hover:bg-gray-300 fs-13 flex items-center justify-center gap-x-1 h-9 rounded-full darkGray w-24 hover:opacity-80 border-0 shadow-none"
            />
          </div>
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 intro-y grid grid-cols-12 gap-6 z-full">
              <div className="box rounded--xl lg:col-span-8 md:col-span-6 col-span-12 shadow-lg">
                <div className="px-5 py-8 grid gap-3 grid-cols-12">
                  <div
                    className={`shadow-sm ${this.props.user.is_seller ? 'lg:col-span-12' : 'lg:col-span-8'} md:col-span-6 col-span-12 rounded-lg flex items-center h-11 px-3 border border-gray-300`}
                  >
                    <HiOutlineSearch className="darkGray" />
                    <input
                      placeholder="Search"
                      className="bg-transparent h-full w-full pl-3"
                      value={this.state.search}
                      onChange={this.handlesearch}
                    />
                  </div>

                  {!this.props.user.is_seller &&
                    <div className="shadow-sm lg:col-span-4 md:col-span-6 col-span-12 rounded-lg">
                      <Dropdown
                        placeholder="Manager"
                        options={this.state.executiveOptions}
                        selection
                        className="w-full"
                        onChange={(event, data) => {
                          this.handleExecutiveStatus(event, data);
                          //this.state.isRoleChanged = true;
                        }}
                      />

                    </div>}
                  <div className="lg:col-span-6 md:col-span-6 col-span-12">
                    <Buttonlink
                      onClick={this.ChooseCategores}
                      text="Choose Categories"
                      title="Choose Categories"
                      rel="nofollow"
                      className="w-full hover:white flex justify-center items-center border border-transparent rounded-lg primary bg--lightPrimary hover:bg--primary focus:outline-none h-11"
                    />
                  </div>
                  {this.props.user.is_seller ? (
                    <div className="shadow-sm lg:col-span-6 md:col-span-6 col-span-12 rounded-lg">
                      <Dropdown
                        className="Light-all z-xlfull"
                        placeholder="Status"
                        name="ticket_type"
                        fluid
                        selection
                        options={sellerStatus}
                        value={this.state.ticket_type}
                        onChange={this.handlestatus}
                      />
                    </div>
                  ) : (
                    <div className="shadow-sm lg:col-span-6 md:col-span-6 col-span-12 rounded-lg">
                      <Dropdown
                        className="Light-all z-xl"
                        placeholder="Status"
                        name="ticket_type"
                        fluid
                        selection
                        options={buyerStatus}
                        value={this.state.ticket_type}
                        onChange={this.handlestatus}
                      />
                    </div>
                  )}
                  {this.props.categories.category && (
                    <div className="gap-2 col-span-12 flex items-center flex-wrap">
                      {this.props?.categories?.category?.map((cat) => (
                        <div
                          key={cat.id}
                          className="relative !z-[1] intro-x"
                          id={cat.id}
                        >
                          <div
                            class="bg--lightPrimary hover:opacity-70 rounded-lg px-4 py-1.5 cursor-pointer flex items-center gap-x-2">
                            <p class="fs-13">{cat.name}</p>
                            <div
                              onClick={() => {
                                let updatedCat =
                                  this.props.categories.category.filter(
                                    (data) => data.id != cat.id
                                  );

                                let form = Object.assign({}, this.props.form);

                                form["category"] = updatedCat;

                                this.props.selectCategories(form);
                                this.props.addRfqForm(form);
                              }}
                            >
                              <IoIosCloseCircle
                                size={20}
                                className="primary"
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  <div
                    className="shadow-sm lg:col-span-6 md:col-span-6 col-span-12 rounded-lg flex items-center h-11 px-3 border border-gray-300">
                    <input
                      type="date"
                      min={moment()
                        .subtract(2, "months")
                        .format("YYYY-MM-DD")}
                      max={moment().format("YYYY-MM-DD")}
                      placeholder="Start date"
                      value={this.state.date_range.startDate} // Controlled input value
                      onChange={(event) => {
                        const selectedStartDate = event.target.value;
                        const endDate = this.state.date_range.endDate;

                        if (endDate && selectedStartDate > endDate) {
                          // Prevent setting a start date greater than the end date
                          event.target.value = endDate;
                        } else {
                          this.setState({
                            date_range: {
                              ...this.state.date_range,
                              startDate: selectedStartDate,
                            },
                          });
                        }
                      }}
                      className="bg-transparent h-full w-full pl-3"
                    />

                  </div>
                  <div
                    className="shadow-sm lg:col-span-6 md:col-span-6 col-span-12 rounded-lg flex items-center h-11 px-3 border border-gray-300">
                    <input
                      type="date"
                      min={
                        this.state.date_range?.startDate ??
                        moment().subtract(2, "months").format("YYYY-MM-DD")
                      }
                      max={moment().format("YYYY-MM-DD")}
                      value={this.state.date_range.endDate} // Controlled input value
                      onChange={(event) => {
                        const selectedEndDate = event.target.value;
                        const startDate = this.state.date_range.startDate;

                        if (startDate && selectedEndDate < startDate) {
                          // Prevent setting an end date smaller than the start date
                          event.target.value = startDate;
                        } else {
                          this.setState({
                            date_range: {
                              ...this.state.date_range,
                              endDate: selectedEndDate,
                            },
                          });
                        }
                      }}
                      placeholder="End date"
                      className="bg-transparent h-full w-full pl-3"
                    />

                  </div>
                  {
                    hasInputValues &&
                    <div className="lg:col-span-12 md:col-span-12 col-span-12">
                      <Buttonlink
                        onClick={() => this.resetAllSearchedStates()}
                        text="Clear All Filters"
                        title="Clear All Filters"
                        className="w-full hover:white flex justify-center items-center border border-transparent rounded-lg primary bg--lightPrimary hover:bg--primary focus:outline-none h-11"
                      />
                    </div>
                  }
                  {/* daterange hide due to requirments */}
                  <div className="shadow-sm hidden col-span-6 rounded-lg ledger-datepicker w-full">
                    <DateRangePicker
                      startDate={
                        new Date(today.getFullYear(), today.getMonth(), 1)
                      }
                      callback={this.addFields}
                      className={"border-gray-300 w-full"}
                    />
                  </div>
                </div>
              </div>
              <div className="box rounded--xl lg:col-span-4 md:col-span-6 col-span-12 shadow-lg">
                <div className="px-5 py-8 space-y-5">
                  <div className="flex justify-between items-center">
                    <h5>Bookmarked Rfqs</h5>
                  </div>
                  {Offersdata?.data?.slice(0, 3).map((item) => {
                    return item.isBookmarked ? (
                      <div className="space-y-5 mt-6" id={item.title}>
                        <div className="flex items-center justify-between">
                          <div>
                            <p className="Medium fs-14 black">{item.title}</p>
                            <p className="Regular fs-13 darkGray">
                              {item.user.firstname} {item.user.lastname}
                            </p>
                          </div>
                          {/* <div className="h-9 w-9 cursor-pointer bg-[#EF553B] rounded-full flex items-center justify-center">
                                <BsBookmark className="text-white" />
                              </div> */}
                        </div>
                      </div>
                    ) : null;
                  })}
                  {Offersdata?.data && (
                    <div className="flex justify-end items-end">
                      <Buttonlink
                        to="/bookmarks"
                        text="See More"
                        className="primary fs-12 hover:opacity-70"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-span-12 relative">
              {this.state.scrollX !== 0 && (
                <HiOutlineChevronDoubleLeft
                  onClick={() => this.moveForwrd(-300)}
                  size={18}
                  className="absolute -left-8 top-7 shadow-2xl opacity-50 hover:opacity-100 animate-bounce z-full cursor-pointer"
                />
              )}
              {!this.state.scrolEnd && (
                <HiOutlineChevronDoubleRight
                  onClick={() => this.moveForwrd(300)}
                  size={18}
                  className="absolute -right-8 top-7 shadow-2xl opacity-50 hover:opacity-100 animate-bounce z-full cursor-pointer"
                />
              )}
              <div className="box overflow-hidden intro-y">
                <div
                  className="overflow-x-auto relative scroll-smooth"
                  id="scrollbar"
                  onScroll={this.scrollCheck}
                  ref={this.lastScroll}
                >
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50 intro-y">
                      <tr>
                        <th scope="col" className="px-6 py-6">
                          <div className={'flex'}>
                            <p className="darkGray fs-13 Light uppercase tracking-wider text-left">
                              #
                            </p>
                            <BiSortAlt2 className="primary cursor-pointer"
                              onClick={() => this.handleDateChangeRaw('id')} />
                          </div>
                        </th>
                        <th scope="col" className="px-3 py-6">
                          <div className={'flex'}>
                            <p className="darkGray fs-12 Light uppercase tracking-wider text-left pl-12">
                              Name
                            </p>
                            <BiSortAlt2 className="primary cursor-pointer"
                              onClick={() => this.handleDateChangeRaw('title')} />
                          </div>
                        </th>
                        <th scope="col" className="px-3 py-6">
                          <p className="darkGray fs-12 Light uppercase tracking-wider text-left">
                            Status
                          </p>
                        </th>
                        <th scope="col" className="px-3 py-6">
                          <p className="darkGray fs-12 Light uppercase tracking-wider text-left">
                            Type
                          </p>
                        </th>
                        <th scope="col" className="px-3 py-6">
                          <p className="darkGray fs-12 Light uppercase tracking-wider text-left whitespace-nowrap">
                            {this.props?.user?.is_seller
                              ? "Buyer Name"
                              : "Supplier Name"}
                          </p>
                        </th>
                        <th scope="col" className="px-3 py-6">
                          <div className={'flex'}>
                            <p className="darkGray fs-12 Light uppercase tracking-wider text-left whitespace-nowrap">
                              Created
                            </p>
                            <BiSortAlt2 className="primary cursor-pointer"
                              onClick={() => this.handleDateChangeRaw('created_at')} />
                          </div>
                        </th>
                        <th scope="col" className="px-3 py-6">
                          <div className={'flex'}>
                            <p className="darkGray fs-12 Light uppercase tracking-wider text-left whitespace-nowrap">
                              Deadline
                            </p>
                            <BiSortAlt2 className="primary cursor-pointer"
                              onClick={() => this.handleDateChangeRaw('last_date')} />
                          </div>
                        </th>
                        {!this.props?.user?.is_seller && (
                          <th scope="col" className="px-3 py-6">
                            <p className="darkGray fs-12 Light uppercase tracking-wider text-left">
                              Report
                            </p>
                          </th>
                        )}
                        <th scope="col" className="px-3 py-6">
                          <p className="darkGray fs-12 Light uppercase tracking-wider text-left">
                            Categories
                          </p>
                        </th>
                        <th scope="col" className="px-3 py-6">
                          <p className="darkGray fs-12 Light uppercase tracking-wider text-left">
                            Industry tag
                          </p>
                        </th>
                        <th scope="col" className="px-3 py-6">
                          <p className="darkGray fs-12 Light uppercase tracking-wider text-left">
                            Date
                          </p>
                        </th>
                        <th scope="col" className="px-3 py-6">
                          <p className="darkGray fs-12 Light uppercase tracking-wider text-left">
                            Amount
                          </p>
                        </th>
                        <th scope="col" className="px-3 py-6">
                          <p className="darkGray fs-12 Light uppercase tracking-wider text-left whitespace-nowrap">
                            Participants
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      <Query
                        query={GET_OFFERS}
                        variables={variables}
                        id={1}
                        fetchPolicy={"cache-and-network"}
                      >
                        {({ loading, data, error, fetchMore, refetch }) => {
                          if (loading) {
                            return (
                              <tr>
                                <td colSpan={11} className="py-20">
                                  <Loader />
                                </td>
                              </tr>
                            );
                          }
                          if (error) {
                            return (
                              <tr>
                                <td colSpan={11} className="py-20">
                                  <Noconnection />
                                </td>
                              </tr>
                            );
                          }
                          if (data.offers.data.length <= 0) {
                            return (
                              <tr>
                                <td colSpan={11} className="py-20">
                                  <Nocontent text="It's quite empty out here, let's crowd it with RFQs!" />
                                </td>
                              </tr>
                            );
                          }
                          return (
                            <>
                              {data.offers.data.map((user, index) => {
                                return (
                                  <>
                                    <tr
                                      className="intro-y zoom-in translate-x-0"
                                      key={index}
                                    >
                                      <td className="px-3 py-5">
                                        {index + 1}
                                      </td>
                                      <td className="px-3 py-5 flex items-center">
                                        {user.isBookmarked ? (
                                          <div
                                            className="h-9 w-9 cursor-pointer z-50 mr-3 bg-[#ef553b] rounded-full flex items-center justify-center shrink-0"
                                            onClick={() =>
                                              this.removeBookmark(
                                                user.id,
                                                user.isBookmarked
                                              )
                                            }
                                          >
                                            <BsBookmark className="text-white" />
                                          </div>
                                        ) : (
                                          <div
                                            className="h-9 w-9 cursor-pointer z-50 mr-3 bg-gray-200 rounded-full flex items-center justify-center shrink-0"
                                            onClick={() =>
                                              this.addBookmark(
                                                user.id,
                                                user.isBookmarked
                                              )
                                            }
                                          >
                                            <BsBookmark className="gray" />
                                          </div>
                                        )}
                                        <p className="fs-13 black Medium min-w-[12rem] break-all">
                                          <Link
                                            rel="nofollow"
                                            to={`/rfq/${encryptfunction(
                                              user.id
                                            )}/${convertToSlug(user.title)}`}
                                            state={{ alert: null }}
                                            className="intro-y hover:primary-only-text"
                                          >
                                            {user.title}
                                          </Link>
                                        </p>
                                      </td>
                                      <td className="px-3 py-5 whitespace-nowrap">
                                        {user &&
                                          user.readable_status.length !== 0 ? (
                                          <p
                                            className={`rounded-full fs-10 ${user.readable_status ===
                                              "Revision"
                                              ? "bg-orange-500"
                                              : user.readable_status ===
                                                "Rejected"
                                                ? "bg-red-500"
                                                : user.readable_status ===
                                                  "Pending"
                                                  ? "bg-gray-500"
                                                  : user.readable_status ===
                                                    "Waiting"
                                                    ? "bg-pink-400"
                                                    : user.readable_status ===
                                                      "Complete"
                                                      ? "bg-green-500"
                                                      : user.readable_status ===
                                                        "Expired"
                                                        ? "bg-red-500"
                                                        : user.readable_status ===
                                                          "Draft"
                                                          ? "bg-gray-500"
                                                          : user.readable_status ===
                                                            "Publish"
                                                            ? "bg-amber-500"
                                                            : "bg-indigo-500"
                                              } text-white px-3 py-1 inline-flex`}
                                          >
                                            {user.readable_status}
                                          </p>
                                        ) : (
                                          <p className="text-center">---</p>
                                        )}
                                      </td>
                                      <td className="pr-6 py-5 whitespace-nowrap">
                                        <p className="fs-11 black Medium">
                                          {user.menu?.id == 6 ||
                                            user.menu?.id == 7
                                            ? "Recommendation"
                                            : "RFQ"}
                                        </p>
                                      </td>
                                      <td className="px-3 py-5 whitespace-nowrap">
                                        {this.props?.user?.is_seller ? (
                                          <p className="fs-11 black Medium">
                                            {user.user.firstname.toUpperCase()}
                                            {" "}
                                            {user.user.lastname.toUpperCase()}

                                          </p>

                                        ) : user?.participants?.data?.length >
                                          0 ? (
                                          user.participants.data.map(
                                            (name) => {
                                              return (
                                                <p
                                                  className="fs-11 black Medium"
                                                  id={name.username}
                                                >
                                                  {name.user.firstname.toUpperCase()}{" "}
                                                  {name.user.lastname.toUpperCase()}
                                                </p>
                                              );
                                            }
                                          )
                                        ) : (
                                          <p>----</p>
                                        )}
                                      </td>

                                      <td className="px-3 py-5 whitespace-nowrap">
                                        {
                                          <p className="fs-11 black Medium">
                                            <Moment
                                              format="D MMM YYYY"
                                              withTitle
                                              className="fs-11"
                                            >
                                              {user.created_at}
                                            </Moment>
                                          </p>
                                        }
                                      </td>
                                      <td className="px-3 py-5 whitespace-nowrap">
                                        {
                                          <p className="fs-11 black Medium">
                                            <Moment
                                              format="D MMM YYYY"
                                              withTitle
                                              className="fs-11"
                                            >
                                              {user.last_date}
                                            </Moment>
                                          </p>
                                        }
                                      </td>
                                      {!this.props?.user?.is_seller && (
                                        <td className="px-3 py-5 whitespace-nowrap">
                                          {/* <p className="fs-11 darkGray Medium">
                                            Yes
                                          </p>*/}
                                          <a
                                            rel="noreferrer"
                                            className="fs-11 darkGray Medium"
                                            href={`${process.env.REACT_APP_MAIN_URL
                                              }/rfq-progress/${encryptfunction(
                                                user.id + "," + hasDev
                                              )}`}
                                            target="_blank"
                                          >
                                            <VscFilePdf size={22} />
                                          </a>
                                        </td>
                                      )}
                                      <td className="px-3 py-5 whitespace-nowrap">
                                        <div className="gap-2 flex items-center selected-tags">
                                          {user.categories
                                            .slice(0, 1)
                                            .map((cat_name, i) => {
                                              return (
                                                <div
                                                  className="relative intro-x"
                                                  key={i}
                                                >
                                                  <label
                                                    className="bg--lightPrimary rounded-lg border--primary hover:opacity-80 px-4 py-1.5 cursor-pointer flex items-center gap-x-2">
                                                    <p className="fs-11">
                                                      {cat_name.name}
                                                    </p>
                                                  </label>
                                                </div>
                                              );
                                            })}
                                          {user.categories.length <
                                            2 ? null : (
                                            <div>
                                              <BsThreeDots
                                                size={20}
                                                onClick={() =>
                                                  this.openPopupModal(user)
                                                }
                                                className="text-gray-400 hover:text-gray-900 cursor-pointer"
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                      <td className="px-3 py-5 whitespace-nowrap">
                                        <div className="gap-2 flex items-center selected-tags">
                                          {user.tags
                                            .slice(0, 1)
                                            .map((tag) => {
                                              return (
                                                <div
                                                  className="relative intro-x"
                                                  id={tag.id}
                                                >
                                                  <label
                                                    className="bg-gray-200 rounded-lg border-gray-200 hover:opacity-80 px-4 py-1.5 cursor-pointer flex items-center gap-x-2">
                                                    <p className="fs-11">
                                                      {tag.name}
                                                    </p>
                                                  </label>
                                                </div>
                                              );
                                            })}
                                          {user.tags.length < 2 ? null : (
                                            <div>
                                              <BsThreeDots
                                                size={20}
                                                className="text-gray-400 hover:text-gray-900 cursor-pointer"
                                                onClick={() =>
                                                  this.openPopupModalTag(user)
                                                }
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                      <td className="px-3 py-5 whitespace-nowrap">
                                        {user.participants.data.length > 0 ? (
                                          user.participants.data.map(
                                            (date) => {
                                              return (
                                                <p
                                                  className="fs-11 darkGray Medium my-2"
                                                  id={date.id}
                                                >
                                                  <Moment
                                                    format="D MMM YYYY"
                                                    withTitle
                                                    className="fs-11"
                                                  >
                                                    {date.created_at}
                                                  </Moment>
                                                </p>
                                              );
                                            }
                                          )
                                        ) : (
                                          <p>---</p>
                                        )}
                                      </td>
                                      <td className="px-3 py-6 whitespace-nowrap ">
                                        {user.participants.data.length > 0 ? (
                                          user.participants.data.map(
                                            (amount) => {
                                              return (
                                                <p className="fs-11 darkGray Medium">
                                                  ${amount.total}
                                                </p>
                                              );
                                            }
                                          )
                                        ) : (
                                          <p>---</p>
                                        )}
                                      </td>

                                      <td className="px-3 py-5 whitespace-nowrap gap-x-1">
                                        {user &&
                                          user.participants.data.length !==
                                          0 ? (
                                          user.participants.data.map(
                                            (date) => {
                                              return (
                                                <div>
                                                  <p
                                                    className={`rounded-full fs-10 ${date.readable_status ===
                                                      "Revision"
                                                      ? "bg-orange-500"
                                                      : date.readable_status ===
                                                        "Rejected"
                                                        ? "bg-red-500"
                                                        : date.readable_status ===
                                                          "Pending"
                                                          ? "bg-gray-500"
                                                          : date.readable_status ===
                                                            "Waiting"
                                                            ? "bg-pink-400"
                                                            : date.readable_status ===
                                                              "Complete"
                                                              ? "bg-green-500"
                                                              : date.readable_status ===
                                                                "Draft"
                                                                ? "bg-gray-500"
                                                                : date.readable_status ===
                                                                  "Expired"
                                                                  ? "bg-red-500"
                                                                  : date.readable_status ===
                                                                    "Publish"
                                                                    ? "bg-amber-500"
                                                                    : "bg-indigo-500"
                                                      } text-white px-3 py-1 inline-flex mb-1`}
                                                  >
                                                    {date.readable_status}
                                                  </p>
                                                </div>
                                              );
                                            }
                                          )
                                        ) : (
                                          <p className="text-center">---</p>
                                        )}
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                              <tr>
                                <td colSpan={11}>
                                  <div className="my-3 border-none flex items-center justify-center">
                                    {this.state.loadMoreQuestions &&
                                      data.offers.paginatorInfo
                                        .hasMorePages && (
                                        <LoadMoreData
                                          title="Load More"
                                          data={data}
                                          fetchMore={fetchMore}
                                          refetch={refetch}
                                          callback={this.loadMoreUsers}
                                          loading={this.state.loading}
                                        />
                                      )}
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        }}
                      </Query>
                    </tbody>
                  </table>
                  {/* <div className="flex justify-end pr-6 py-6">
                      <Pagination
                        boundaryRange={1}
                        activePage={paginationData}
                        siblingRange={1}
                        totalPages={10}
                        showEllipsis={true}
                        prevItem={{
                          content: <FiChevronLeft />,
                          icon: true,
                        }}
                        nextItem={{
                          content: <FiChevronRight />,
                          icon: true,
                        }}
                        firstItem={null}
                        lastItem={null}
                        onPageChange={this.paginationInfo}
                      />
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <MoreCategoriesPopup
          ref={this.MoreCategoryPopup}
          data={this.state.catdata}
          tagData={this.state.tagData}
          type={this.state.open ? "More Tags" : "More Categories"}
        />
        <Fullscreenpopup
          ref={this.SelectFullPopup}
          maxValue={5}
          type={"Choose Category"}
          clearState={(value) => console.log("value", value)}
        />
      </>
    );
  }
}

const mapStateToProps = ({ Rfq, Auth }) => {
  return {
    user: Auth.authUserInformation,
    form: Rfq.form,
    categories: Rfq.categories,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("store/redux/RFQRedux");
  return {
    ...ownProps,
    ...stateProps,
    rfqPublishorUnpublished: (data) => {
      dispatch(actions.rfqPublishorUnpublished(data));
    },
    selectCategories: (data) => {
      dispatch(actions.selectCategories(data));
    },
    addRfqForm: (data) => {
      dispatch(actions.addRfqForm(data));
    },
    selectTags: (data) => {
      dispatch(actions.selectTags(data));
    },
  };
};
export default connect(mapStateToProps, undefined, mergeProps)(RfqLedger);
