import {
    ApolloClient,
    HttpLink,
    InMemoryCache,
    concat,
    ApolloLink,
} from "@apollo/client";
import cookie from "js-cookie";
import {
    GRAPH_URL,
    GRAPH_MRO_USER_AUTH_TOKEN,
} from "../config/constants";

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPH_URL });

const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            Authorization: `Bearer ${cookie.get(GRAPH_MRO_USER_AUTH_TOKEN)}`,
            'client-name': process.env.REACT_APP_MAIN_URL,
            'client-version': '1.0.1'
        },
    }));
    return forward(operation);
});

const client = new ApolloClient({
    uri: process.env.REACT_APP_GRAPH_URL,
    cache: new InMemoryCache(),
    link: concat(authMiddleware, httpLink),
});

export default client;
