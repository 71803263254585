import { gql } from "@apollo/client";

export default gql`
  query ($id: ID) {
    offer(id: $id) {
      id
      isAccepted
      isRating
      is_aggregated
      review {
        id
        offer_id
        product_id
        seller_id
        buyer_id
        rate
        comment
        is_buyer
        responsiveness
        shipment
        lead_time
        delivered
        compliance
      }
      winners {
        id
        product_id
        user {
          id
          firstname
          lastname
          profile_photo
          username
          email
          primaryAddress {
            id
            address_1
            address_2
            email
            phone
            city
            state_id
            state {
              id
              name
            }
            country_code
            zip_code
          }
        }
        total
        comment
        approved
      }
    }
  }
`;
