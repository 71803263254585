import { useNavigate } from "react-router-dom";
export default function DecryptUrl(id) {
  const navigate = useNavigate();
  try {
    return window.atob(id);
  } catch (error) {
    // Redirect to a different route
    navigate("/404");
  }
}
