import { gql } from "@apollo/client";

export default gql`
    mutation ($email: String!, $password: String!, $longitude: String, $latitude: String) {
        login(email: $email, password: $password, longitude: $longitude, latitude: $latitude) {
            token
            error
            message
        }
}
`;
