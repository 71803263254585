import React, { Component } from "react";
import {
  Loader,
  Noconnection,
  Nocontent,
  Subbanner,
  Buttonlink,
} from "components";
import { Rfqquestion } from "navigation";
import direction from "../../../assets/images/direction.png";
import {
  BsBookmark,
  BsChatSquareQuoteFill,
  BsShare,
  BsSuitHeartFill,
} from "react-icons/bs";
import { IoIosArrowBack } from "react-icons/io";
import GET_OFFERS from "graphql/queries/getOffers";
import { Query } from "@apollo/client/react/components";
import { actions } from "store/redux/RFQRedux";
import { connect } from "react-redux";
import LoadMoreData from "../../../components/loadMoreData";
import { Tab } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class Bookmarks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadMoreQuestions: true,
    };
  }

  loadMoreRFQ = (data, fetchMore, refetch) => {
    fetchMore({
      variables: {
        cursor: data.offers.paginatorInfo.currentPage + 1,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newQuestions = fetchMoreResult.offers.data;
        const pageInfo = fetchMoreResult.offers.paginatorInfo;
        if (data.offers.paginatorInfo.total !== pageInfo.total) {
          refetch();
        }
        if (pageInfo.hasMorePages) {
          this.setState({ loadMoreQuestions: true });
        } else {
          this.setState({ loadMoreQuestions: false });
        }

        return newQuestions.length
          ? {
            offers: {
              __typename: previousResult.offers.__typename,
              data: [...previousResult.offers.data, ...newQuestions],
              paginatorInfo: pageInfo,
            },
          }
          : previousResult;
      },
    });
  };

  render() {
    return (
      <div>
        <Subbanner title="Bookmarks RFQs" />
        <div className="container mt-[-100px] relative">
          <div className="grid gap-4 xl:grid-cols-4 lg:grid-cols-1">
            <div className="hidden xl:block">
              <div className="h-12 mt-2" />
              <div className="overflow-hidden xl:sticky top-3">
                <div className="px-5 py-20 space-y-5">
                  <div className="flex justify-center animate-bounce">
                    <BsChatSquareQuoteFill size={200} className="primary" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-2 overflow-hidden rounded--xl intro-y">
              <div className="relative mb-5">
                <Buttonlink
                  text="Back"
                  prefix={<IoIosArrowBack size={16} />}
                  onClick={() => this.props.navigate(-1)}
                  className="flex items-center justify-center w-24 bg-gray-300 border-0 rounded-full shadow-none hover:opacity-80 fs-13 gap-x-1 h-9 darkGray"
                />
              </div>
              <Tab.Group>
                <Tab.List className="flex p-1 space-x-1 rounded-xl bg-[white]">
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        "w-full rounded-lg py-3 text-lg font-medium leading-5  shadow-none outline-none ring-0",
                        selected
                          ? "bg--primary text-white"
                          : " hover:bg-[black]/10 bg-[black]/[0.05] primary"
                      )
                    }>
                    Rfq Question
                  </Tab>
                  {/*<Tab
                    className={({ selected }) =>
                      classNames(
                        "w-full rounded-lg py-3 text-lg font-medium leading-5  shadow-none outline-none ring-0",
                        selected
                          ? "bg--primary text-white"
                          : " hover:bg-[black]/10 bg-[black]/[0.05] primary"
                      )
                    }>
                    Products
                  </Tab>*/}
                </Tab.List>
                <Tab.Panels className="mt-5">
                  <Tab.Panel>
                    <Query
                      query={GET_OFFERS}
                      variables={{ saved: true, orderBy: [{ field: "updated_at", order: "DESC" }] }}
                      fetchPolicy={"cache-and-network"}>
                      {({ loading, error, data, fetchMore, refetch }) => {
                        this.refetch = refetch;
                        if (loading) return null;

                        if (error) return <Noconnection />;

                        // if (this.props.recallRef) {
                        //   refetch();
                        //   this.props.rfqPublishorUnpublished(false);
                        // }

                        if (!data && data?.offers?.data?.length === 0) {
                          this.setState({ loadMoreQuestions: false });
                          return <Nocontent text={"Bookmark your favorite RFQs to engage with them later"} />;
                        }
                        if (data) {
                          return (
                            <>
                              <Rfqquestion data={data?.offers?.data} />
                              <div className="mt-5 text-center">
                                {data?.offers?.paginatorInfo?.hasMorePages && (
                                  <LoadMoreData
                                    title="Load More RFQ"
                                    data={data}
                                    fetchMore={fetchMore}
                                    refetch={refetch}
                                    callback={this.loadMoreRFQ}
                                    loading={this.state.loading}
                                  />
                                )}
                              </div>
                            </>
                          );
                        }
                      }}
                    </Query>
                  </Tab.Panel>
                  <Tab.Panel>
                    <div class="grid grid-cols-1 gap-4 mt-10 xl:grid-cols-2 lg:grid-cols-3 sm:grid-cols-2">
                      <div class="intro-y box">
                        <a
                          class="block bg-white hover:bg-gray-100 rounded-t-xl group"
                          href="#">
                          <div class="border-b">
                            <img
                              alt="Knowledge"
                              src="https://mro.procurementleague.com/uploads/products/1668505118.jpg"
                              class="w-full mx-auto h-[250px] rounded-t-xl object-cover"
                            />
                          </div>
                          <div class="px-5 py-3 border-b border-gray-200 h-[70px]">
                            <h4 class="mr-auto text-lg capitalize Medium group-hover:text-[#f58020] text-ellipsis overflow-hidden whitespace-nowrap block">
                              mouse
                            </h4>
                            <p class="text-sm darkGray custom-truncate ">
                              this is mouse product
                            </p>
                          </div>
                        </a>
                        <div class="px-5 py-3 border-t border-gray-200">
                          <div class="flex items-center justify-between mt-3">
                            <div class="flex items-center cursor-pointer gap-x-1 ">
                              <BsSuitHeartFill className="primary" size={17} />
                              <p class="text-[11px] primary">0</p>
                            </div>
                            <div class="flex items-center justify-center cursor-pointer gap-x-1 ">
                              <BsShare
                                size={18}
                                className="hover:text-[#f58020]"
                              />
                            </div>
                            <div class="flex items-center justify-center cursor-pointer gap-x-1 ">
                              <BsBookmark
                                className="hover:text-[#f58020]"
                                size={17}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="intro-y box">
                        <a
                          class="block bg-white hover:bg-gray-100 rounded-t-xl group"
                          href="#">
                          <div class="border-b">
                            <img
                              alt="Knowledge"
                              src="https://mro.procurementleague.com/uploads/products/1668505118.jpg"
                              class="w-full mx-auto h-[250px] rounded-t-xl object-cover"
                            />
                          </div>
                          <div class="px-5 py-3 border-b border-gray-200 h-[70px]">
                            <h4 class="mr-auto text-lg capitalize Medium group-hover:text-[#f58020] text-ellipsis overflow-hidden whitespace-nowrap block">
                              mouse
                            </h4>
                            <p class="text-sm darkGray custom-truncate ">
                              this is mouse product
                            </p>
                          </div>
                        </a>
                        <div class="px-5 py-3 border-t border-gray-200">
                          <div class="flex items-center justify-between mt-3">
                            <div class="flex items-center cursor-pointer gap-x-1 ">
                              <BsSuitHeartFill className="primary" size={17} />
                              <p class="text-[11px] primary">0</p>
                            </div>
                            <div class="flex items-center justify-center cursor-pointer gap-x-1 ">
                              <BsShare
                                size={18}
                                className="hover:text-[#f58020]"
                              />
                            </div>
                            <div class="flex items-center justify-center cursor-pointer gap-x-1 ">
                              <BsBookmark
                                className="hover:text-[#f58020]"
                                size={17}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>

            {/* <div className="hidden xl:block">
              <div className="h-12 mt-1" />
              <div className="overflow-hidden box xl:sticky top-10">
                <div className="px-5 py-10 pt-20 animate-bounce">
                  <div className="flex justify-center">
                    <img
                      alt="direction"
                      src={direction}
                      className="w-28 h-28"
                    />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ Rfq }) => {
  return {
    recallRef: Rfq.recallRef,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("store/redux/RFQRedux");
  return {
    ...ownProps,
    ...stateProps,
    rfqPublishorUnpublished: (data) => {
      dispatch(actions.rfqPublishorUnpublished(data));
    },
  };
};

export default connect(mapStateToProps, undefined, mergeProps)(Bookmarks);
