import { gql } from "@apollo/client";

export default gql`
mutation offerClosed(
    $offer_id:Int
  ) {
    offerClosed(
        offer_id: $offer_id
      ) {
        id
        is_closed
      }
    }
`;
