import { gql } from "@apollo/client";

export default gql`
  query ($id: ID) {
    user(id: $id) {
      id
      username
      firstname
      lastname
      phone_number
      profile_photo
      is_plan_expired
      tagline
      email
      timestamp
      is_seller
      become_seller
      file_type
      isSellerProfileCompleted
      pricing_inquiries
      vendor_inquiries
      companies(first: 5) {
        data {
          id
          title
          address
          city
          country
          is_primary
          logo
        }
      }
      addresses {
        id
        name
        address_1
        address_2
        email
        phone
        city
        state_id
        is_primary
        state {
          id
          name
        }
        country_code
        zip_code
      }
      primaryAddress {
        id
        name
        address_1
        address_2
        email
        phone
        city
        state_id
        state {
          id
          name
        }
        country_code
        zip_code
      }
      country {
        id
        name
      }
      state {
        id
        name
      }
      categories {
        id
        name
        code
      }
      keyContacts {
        id
        username
        firstname
        lastname
        profile_photo
      }
      sellerReviews {
        id
        offer_id
        seller_id
        buyer_id
        offer {
          id
          title
          categories {
            id
            name
            code
          }
          tags {
            id
            name
          }
        }
        rate
        comment
        is_buyer
        # seller
        buyer {
          id
          username
          firstname
          lastname
          profile_photo
        }
        responsiveness
        shipment
        lead_time
        delivered
        compliance
      }
    }
  }
`;
