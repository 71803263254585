import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { BsHash } from "react-icons/bs";
import { FaHeart } from "react-icons/fa";
import { encryptfunction } from "helpers";
import { Loader, Nocontent, ProfileAvatar } from 'components';
import client from 'graphql/client';
import addPrefferedVendoMutation from 'graphql/mutations/addPrefferedVendoMutation';
import MySelectedRFQsAction from 'store/actions/MySelectedRFQsAction';
import { PREFERRED_VENDORS } from 'store/actions/types';
import { HiOutlineSearch } from "react-icons/hi";
import PREFERRED_VENDOR from "../../../graphql/queries/me";


const LikedVendors = (props) => {
    // console.log('props', props);
    const [idsArray, setIdsArray] = useState([]);
    const [searchusers, setSearchUsers] = useState([]);
    const [timeout, setTimeout] = useState(0);
    const [inputval, setInputval] = useState('');
    const [loader, setLoader] = useState('');
    const [prefferedVendors, setPrefferedVendors] = useState(props?.preffered_vendors?.length > 0 ? props?.preffered_vendors : []);

    useEffect(() => {
        if (props.preffered_vendors.length === 0) {
            setLoader(true);
            loadVender();
        }

    }, []);
    const loadVender = () => {
        client
            .query({
                query: PREFERRED_VENDOR,
                fetchPolicy: "network-only",
            })
            .then((responce) => {
                MySelectedRFQsAction.prefferedVendors(
                    responce.data.me.preffered_vendors
                );
                setLoader(false);
            })
            .catch((e) => {
                console.log(e);
            });
    };
    const dislikeVendor = (id) => {
        client
            .mutate({
                mutation: addPrefferedVendoMutation,
                variables: {
                    seller_id: [parseInt(id)],
                    action: "dislike",
                },
            })
            .then((res) => {
                MySelectedRFQsAction.removedVendor(parseInt(id));
            })
            .catch((e) => {
                console.log(e);
            });
    };
    const _handleInputval = (e) => {
        if (timeout) clearTimeout(timeout);

        setTimeout(setTimeout(() => {
            setInputval(e.target.value);
        }, 1000));
    };
    useEffect(() => {
        const filteredVendors = props?.preffered_vendors
            ?.filter((item) => {
                const inputValLower = inputval.replace(/\s/g, '').toLowerCase();
                return !inputval || (
                    (item.user.firstname + item.user.lastname)
                        .replace(/\s/g, '')
                        .toLowerCase()
                        .includes(inputValLower)
                );
            });
        setPrefferedVendors(filteredVendors);
    }, [inputval, props.preffered_vendors]);
    return (
        <>

            {!loader ? <div className="box overflow-hidden intro-y">
                <div
                    id="scrollbar"
                    className={`${prefferedVendors.length > 10 &&
                        "h-[675px] overflow-y-auto"
                        }`}
                >
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50 intro-y">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    <div className=" flex items-center">
                                        <div className="w-20">
                                            <BsHash size={18} className="darkGray" />
                                        </div>
                                        <p className="darkGray fs-12 Light uppercase tracking-wider">
                                            Name
                                        </p>
                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    <div className="bg--lightGray rounded-xl flex items-center h-10 px-3 w-72 focus-within:w-80 ease-in-out duration-300 ml-auto">
                                        <HiOutlineSearch className="darkGray" />
                                        <input
                                            placeholder="Search"
                                            className="bg-transparent h-full w-full px-3"
                                            onChange={(e) => {
                                                _handleInputval(e);
                                            }}
                                        />

                                    </div>
                                </th>
                            </tr>
                        </thead>

                        {/* preffered vendors list */}
                        <tbody className="bg-white divide-y divide-gray-200">
                            {prefferedVendors?.length === 0 ?
                                <tr>
                                    <td colSpan={2}>
                                        <Nocontent />
                                    </td>
                                </tr> :
                                prefferedVendors?.map((item, index) => {
                                    return (
                                        <tr className="intro-y zoom-in" key={index}>
                                            <td className="px-6 py-3 whitespace-nowrap">
                                                <div className=" flex items-center">
                                                    <div className="w-20">
                                                        <div className="w-7 h-7 bg--primary rounded-full flex items-center justify-center">
                                                            <p className="white fs-12 Light uppercase tracking-wider">
                                                                {index + 1}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <Link
                                                        to={`/${encryptfunction(
                                                            item.user.id
                                                        )}/profile`}
                                                        state={{
                                                            value: "edit",
                                                            id: item.user.id,
                                                        }}
                                                        className="flex items-center gap-x-3"
                                                    >
                                                        <div className="flex-shrink-0">
                                                            <ProfileAvatar
                                                                singleUser={item.user}
                                                                imagesize={{
                                                                    width: 42,
                                                                    height: 42,
                                                                }}
                                                            />
                                                        </div>
                                                        <div>
                                                            <p className="fs-13 Medium">
                                                                {item.user.firstname}{" "}
                                                                {item.user.lastname}
                                                            </p>
                                                            <p className="fs-10 gray">
                                                                @{item.user.username}
                                                            </p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </td>
                                            <td className="px-6 py-3 whitespace-nowrap text-right lg:pr-20">
                                                <FaHeart
                                                    size={18}
                                                    className="ml-auto cursor-pointer"
                                                    onClick={() =>
                                                        dislikeVendor(item.seller_id)
                                                    }
                                                    color={`${searchusers.filter(
                                                        (o) => o.id === item.seller_id
                                                    )
                                                        ? "#ef553b"
                                                        : ""
                                                        }`}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            </div> : <Loader />}
        </>
    )
}
const mapStateToProps = ({ Rfq, Auth, RFQs }) => {
    return {
        user: Auth,
        form: Rfq.form,
        categories: Rfq.categories,
        preffered_vendors: RFQs.prefferedVendors,
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { actions } = require("store/redux/RFQRedux");
    return {
        ...ownProps,
        ...stateProps,
        selectCategories: (data) => {
            dispatch(actions.selectCategories(data));
        },
        addRfqForm: (data) => {
            dispatch(actions.addRfqForm(data));
        },
    };
};
export default connect(mapStateToProps, null, mergeProps)(LikedVendors);
