import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { BsHash } from "react-icons/bs";
import { FaHeart } from "react-icons/fa";
import { encryptfunction } from "helpers";
import { Loader, Noconnection, Nocontent, ProfileAvatar } from 'components';
import client from 'graphql/client';
import addPrefferedVendoMutation from 'graphql/mutations/addPrefferedVendoMutation';
import MySelectedRFQsAction from 'store/actions/MySelectedRFQsAction';
import PREFERRED_VENDOR from "../../../graphql/queries/me";
import { Query } from '@apollo/client/react/components';
//import SearchVendor from "../../../graphql/queries/searchUsers";
import { HiOutlineSearch } from "react-icons/hi";
import { IoIosArrowBack, IoIosCloseCircle } from "react-icons/io";
import { FiEdit2 } from 'react-icons/fi';
import { Fullscreenpopup, Subbanner } from "components";
import { checkPlan } from 'helpers/checkPlan';
import { toast } from 'react-toastify';
import { gql } from '@apollo/client';

const SearchVendor = gql`
query ($q: String, $is_buyer: Boolean, $category: [Int], $notIn: [Int]) {
SearchVendor(
  email: $q
  is_buyer: $is_buyer
  category: $category
  notIn: $notIn
  )
  {
      id
      username
      firstname
      lastname
      email
      tagline
      profile_photo
      is_seller
      distance
}
}
`;

const NotLikedVendors = (props) => {
    const [inputval, setInputval] = useState('');
    const [idsArray, setIdsArray] = useState([]);
    const [timeout, setTimeout] = useState(0);
    const [newLike, setNewLike] = useState(0);
    const [toastVisibility, setToastVisibility] = useState(true);

    const selectFullPopup = React.createRef();

    useEffect(() => {
        setToastVisibility(true);

    }, [inputval, newLike])
    const openPopupModal = () => {
        selectFullPopup.current.openPopup();
    };
    const loadVender = () => {
        client
            .query({
                query: PREFERRED_VENDOR,
                fetchPolicy: "network-only",
            })
            .then((responce) => {
                MySelectedRFQsAction.prefferedVendors(
                    responce.data.me.preffered_vendors
                );
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const notify = (message = "", type = "error") => {
        // const toastIsActive = toast.isActive(toastId);
        // console.log('first', toast.isActive(1))
        // Dismiss any existing toasts before showing a new one
        toast.dismiss();

        if (type === "error") {
            return toast.error(message, { position: "top-right", toastId: 1 });
        }
        return toast.success(message, { position: "top-right" });
    };

    const likeVendor = (id) => {
        const canLikeVendor = checkPlan("bool", "Select Preferred Vendor", props?.user?.authUserInformation?.payments);

        if (!canLikeVendor) {
            setToastVisibility(false);
            notify("You are not allowed for this feature, Please upgrade your plan");
        } else {
            setIdsArray((prevIds) => [...prevIds, id]);
            client
                .mutate({
                    mutation: addPrefferedVendoMutation,
                    variables: {
                        seller_id: [parseInt(id)],
                        action: "like",
                    },
                })
                .then((res) => {
                    setNewLike(newLike + 1);
                    loadVender();
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    };

    const _handleInputval = (e) => {
        if (timeout) clearTimeout(timeout);

        setTimeout(setTimeout(() => {
            setInputval(e.target.value);
        }, 1000));
    };
    let variables = { is_buyer: false };
    if (inputval !== "") {
        variables.q = inputval;
    }
    let categories = [];
    let vendor_ids = [];

    props?.preffered_vendors?.map((vendor) => {
        vendor_ids.push(parseInt(vendor.seller_id));
    });

    if (props?.preffered_vendors !== undefined && vendor_ids.length > 0) {
        variables.notIn = vendor_ids ?? [];
    }

    if (props.categories.category?.length > 0) {
        props.categories.category?.map((item, key) => {
            categories.push(parseInt(item.id));
        });
    }

    if (categories.length > 0) {
        variables.category = categories;
    }

    return (
        <>

            <div className="box overflow-hidden intro-y p-6 bg-white rounded-xl my-5 relative">
                <div className="flex items-center">
                    <h4 className="text-lg mr-auto">My Preferred Categories</h4>
                    <div className="ml-auto">
                        <div
                            onClick={openPopupModal}
                            className="h-8 w-8 bg-slate-100 hover:bg-slate-200 flex items-center justify-center rounded-full cursor-pointer"
                        >
                            <FiEdit2 size={14} className="primary" />
                        </div>
                    </div>
                </div>
                {props.categories.category && (
                    <div className="gap-3 flex items-center flex-wrap selected-tags mt-6">
                        {props.categories?.category?.map((r_cat) => (
                            <div className="relative intro-x" key={r_cat.id}>
                                <label className="bg--lightPrimary rounded-lg border--primary hover:opacity-80 px-4 py-1.5 flex items-center gap-x-2">
                                    <p className="fs-13">{r_cat.name}</p>
                                    <div className="cursor-pointer">
                                        <IoIosCloseCircle
                                            onClick={() => {
                                                let updatedCat =
                                                    props.categories.category.filter(
                                                        (data) => data.id !== r_cat.id
                                                    );
                                                let form = Object.assign({}, props.form);
                                                form["category"] = updatedCat;
                                                props.selectCategories(form);
                                                props.addRfqForm(form);
                                            }}
                                            size={20}
                                            className="primary"
                                        />
                                    </div>
                                </label>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className="box overflow-hidden intro-y mt-5">
                <div id="scrollbar">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50 intro-y">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    <div className=" flex items-center">
                                        <div className="w-20">
                                            <BsHash size={18} className="darkGray" />
                                        </div>
                                        <p className="darkGray fs-12 Light uppercase tracking-wider">
                                            Name
                                        </p>
                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    <div className="bg--lightGray rounded-xl flex items-center h-10 px-3 w-72 focus-within:w-80 ease-in-out duration-300 ml-auto">
                                        <HiOutlineSearch className="darkGray" />
                                        <input
                                            placeholder="Search"
                                            className="bg-transparent h-full w-full px-3"
                                            onChange={(e) => {
                                                _handleInputval(e);
                                            }}
                                        />
                                    </div>
                                </th>
                            </tr>
                        </thead>

                        {/* preffered vendors list */}
                        <tbody className="bg-white divide-y divide-gray-200">
                            {/* searchig vendors listing */}
                            <Query
                                query={SearchVendor}
                                variables={variables}
                                fetchPolicy={"cache-and-network"}
                            >
                                {({ loading, error, data, fetchMore }) => {
                                    if (loading)
                                        return (
                                            <tr>
                                                <td colSpan={2}>
                                                    <Loader />
                                                </td>
                                            </tr>
                                        );

                                    if (error)
                                        return (
                                            <tr>
                                                <td colSpan={2}>
                                                    <Noconnection />
                                                </td>
                                            </tr>
                                        );
                                    if (data.SearchVendor?.length === 0) {
                                        <Nocontent />
                                    }
                                    if (data.SearchVendor?.length === 0)
                                        return (
                                            <tr>
                                                <td colSpan={2}>
                                                    <Nocontent />
                                                </td>
                                            </tr>
                                        );
                                    if (data) {
                                        return data.SearchVendor.map((user, index) => (
                                            <tr className="intro-y zoom-in">
                                                <td className="px-6 py-3 whitespace-nowrap">
                                                    <div className=" flex items-center">
                                                        <div className="w-20">
                                                            <div className="w-7 h-7 bg--primary rounded-full flex items-center justify-center">
                                                                <p className="white fs-12 Light uppercase tracking-wider">
                                                                    {index + 1}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <Link
                                                            to={`/${encryptfunction(
                                                                user.id
                                                            )}/profile`}
                                                            state={{
                                                                value: "edit",
                                                                id: user.id,
                                                            }}
                                                            className="flex items-center gap-x-3"
                                                        >
                                                            <div className="flex-shrink-0">
                                                                <ProfileAvatar
                                                                    singleUser={user}
                                                                    imagesize={{
                                                                        width: 42,
                                                                        height: 42,
                                                                    }}
                                                                />
                                                            </div>
                                                            <div>
                                                                <p className="fs-14 Medium cursor-pointer">
                                                                    {user.firstname} {user.lastname}
                                                                </p>
                                                                <p className="fs-10 gray cursor-pointer">
                                                                    @{user.username}
                                                                </p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-3 whitespace-nowrap text-right lg:pr-20">
                                                    <FaHeart
                                                        size={18}
                                                        className="ml-auto cursor-pointer"
                                                        onClick={() => likeVendor(user.id)}
                                                        color={`${vendor_ids.indexOf(user.id) !== -1
                                                            ? "#ef553b"
                                                            : "#9da4b4"
                                                            }`}
                                                    />
                                                </td>
                                            </tr>
                                        ));
                                    }
                                }}
                            </Query>
                        </tbody>
                    </table>
                </div>
            </div>
            <Fullscreenpopup
                ref={selectFullPopup}
                type="Choose Category"
                clearState={(value) => console.log("value", value)}
            />
        </>
    )
}
const mapStateToProps = ({ Rfq, Auth, RFQs }) => {
    return {
        user: Auth,
        form: Rfq.form,
        categories: Rfq.categories,
        preffered_vendors: RFQs.prefferedVendors,
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { actions } = require("store/redux/RFQRedux");
    return {
        ...ownProps,
        ...stateProps,
        selectCategories: (data) => {
            dispatch(actions.selectCategories(data));
        },
        addRfqForm: (data) => {
            dispatch(actions.addRfqForm(data));
        },
    };
};
export default connect(mapStateToProps, null, mergeProps)(NotLikedVendors);