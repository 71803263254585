export const AUTH_USER_TOKEN = "AUTH_USER_TOKEN";
export const GET_AUTH_USER_INFORMATION = "GET_AUTH_USER_INFORMATION";
export const UPDATE_AUTH_USER_PROPERTY = "UPDATE_AUTH_USER_PROPERTY";
export const GET_AUTH_USER_ALERTS = "GET_AUTH_USER_ALERTS";
export const SET_SOCKET_ID = "SET_SOCKET_ID";
export const DELETE_ALERT = "DELETE_ALERT";

export const ALL_CATEGORIES = "ALL_CATEGORIES";
export const SELECTED_CATEGORIES = "SELECTED_CATEGORIES";
export const SELECTED_TAGS = "SELECTED_TAGS";
export const SEARCHED_RFQS = "SEARCHED_RFQS";
export const ALL_BUSINESS_ADDRESS = "ALL_BUSINESS_ADDRESS";
export const SET_PRIMARY_ADDRESS = "SET_PRIMARY_ADDRESS";
export const PREFERRED_VENDORS = "PREFERRED_VENDORS";
export const REMOVED_VENDOR = "REMOVED_VENDOR";
export const GET_CURRENT_PAGE_URL = "GET_CURRENT_PAGE_URL";
