import React, { Component } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { IoIosArrowDown } from "react-icons/io";
import { connect } from "react-redux";
import getCategories from "graphql/queries/getCategories";
import ChooseRFQCategories from "./chooseRFQCategories";
import { Loader, Noconnection } from "components";
import { Query } from "@apollo/client/react/components";
import { HiOutlineSearch } from "react-icons/hi";
import { toast } from "react-toastify";

class ChooseRFQCategoryaside extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: "",
      searchingCat: "",
    };

  }

  handleChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      this.setState({ expanded: panel });
    } else {
      this.setState({ expanded: false });
    }
  };

  notify = (message = "", type = "error") => {
    // Dismiss any existing toasts before showing a new one
    toast.dismiss();
    if (type === "error") {
      return toast.error(message, { position: "top-right" });
    } else if (type === "warning") {
      return toast.warning(message, { position: "top-right" });
    } else {
      return toast.success(message, { position: "top-right" });
    }
  };

  selectCategory = (event, category) => {
    const isChecked = event.target.checked;
    let categories = Object.assign([], this.props.form.category);

    if (isChecked) {
      if (categories?.length < this.props.maxValue) {
        categories.push(category);
        this.addForm("category", categories);
      } else {
        this.notify(`You can only select ${this.props.maxValue} categories`, "error");
        this.props.closePopup();
        // if (this.props.showCurrentPage !== 'profile'){
        //   this.notify('you can only select 5 categories', 'error');
        //   this.props.closePopup()
        // }else {
        //   categories.push(category);
        //   this.addForm("category", categories);
        //   this.notify('Adding more than 5 categories', 'warning');
        // }
      }
    } else {
      const index = categories.indexOf(category);
      categories.splice(index, 1);
      this.addForm("category", categories);
    }
  };

  addForm = (key, value) => {
    let form = Object.assign({}, this.props.form);
    form[key] = value;
    this.props.addRfqForm(form);
    this.props.selectCategories(form);
  };

  render() {
    const { expanded, searchingCat } = this.state;

    let variables = {};

    if (searchingCat === "") {
      variables.parent_id = 0;
    }

    if (searchingCat != "") {
      variables.search = searchingCat;
    }

    // console.log("this.props.categories",this.props.categories)

    return (
      <div>
        <div className="px-5 my-3">
          <div className="bg--lightGray rounded-xl flex items-center h-11 px-3">
            <HiOutlineSearch className="darkGray" />
            <input
              placeholder="Search"
              className="bg-transparent h-full w-full pl-3"
              value={this.state.searchingCat}
              onChange={(e) =>
                this.setState({
                  searchingCat: e.target.value,
                })
              }
            />
          </div>
        </div>

        <Query
          query={getCategories}
          variables={variables}
          fetchPolicy={"cache-and-network"}
          id={1}
        >
          {({ loading, error, data, fetchMore }) => {
            if (loading) return <Loader />;

            if (error) {
              console.log("error", error);
              return <Noconnection />;
            }

            // console.log(data?.categories_web?.data);

            return data?.categories_web?.data?.map((category) => (
              <React.Fragment key={category.id}>
                <Accordion
                  expanded={expanded === category.id}
                  onChange={this.handleChange(category.id)}
                  className="shadow--none"
                >
                  <AccordionSummary
                    expandIcon={<IoIosArrowDown />}
                    aria-controls={`${category.id}-content`}
                    id={`${category.id}-header`}
                  >
                    <p>{category.name}</p>
                  </AccordionSummary>
                  <div className="my-2 flex flex-wrap gap-2">
                    <div className="gap-2 flex items-center flex-wrap">
                      <div className="relative intro-x">
                        <input
                          type="checkbox"
                          onChange={(e) => this.selectCategory(e, category)}
                          className="z-0 absolute left-0 invisible"
                          id={category.id}
                          checked={
                            this.props.categories &&
                              this.props.categories.category &&
                              this.props.categories.category.find(
                                (data) => data.id == category.id
                              )
                              ? true
                              : false
                          }
                        />
                        <label
                          htmlFor={category.id}
                          className={
                            this.props.categories &&
                              this.props.categories.category &&
                              this.props.categories.category.find(
                                (data) => data.id == category.id
                              )
                              ? "bg-red-200 rounded-lg px-4 py-1.5 cursor-pointer inline-block"
                              : "bg-gray-200 hover:opacity-70 rounded-lg px-4 py-1.5 cursor-pointer inline-block"
                          }
                        >
                          <p className="fs-13">{category.name}</p>
                        </label>
                      </div>
                    </div>

                    {category.items.map((subCategory) => (
                      <AccordionDetails
                        className="p-[0!important]"
                        key={subCategory.id}
                      >
                        <ChooseRFQCategories
                          closePopup={this.props.closePopup}
                          category={category}
                          subCategory={subCategory}
                          maxValue={this.props.maxValue ?? 1}
                        />
                      </AccordionDetails>
                    ))}
                  </div>
                </Accordion>
                <div className="divider" />
              </React.Fragment>
            ));
          }}
        </Query>
      </div>
    );
  }
}
const mapStateToProps = ({ Rfq, Auth, Page }) => {
  return {
    user: Auth,
    form: Rfq.form,
    categories: Rfq.categories,
    showCurrentPage: Page.url,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("store/redux/RFQRedux");
  return {
    ...ownProps,
    ...stateProps,
    addRfqForm: (data) => {
      dispatch(actions.addRfqForm(data));
    },
    selectCategories: (data) => {
      dispatch(actions.selectCategories(data));
    },
  };
};
export default connect(
  mapStateToProps,
  undefined,
  mergeProps
)(ChooseRFQCategoryaside);
