import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";
import messages from "../../../assets/images/messages.png";
import { Popover, Transition } from "@headlessui/react";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { BsTrash } from "react-icons/bs";
import { IoCloseCircleOutline } from "react-icons/io5";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import { MdOutlineUnpublished } from "react-icons/md";
import Profileavatar from "../../../components/profileavatar";
import { convertToSlug, encryptfunction } from "helpers";
import client from "graphql/client";
import DELETE_OFFER from "../../../graphql/mutations/deleteOfferMutation";
import PUBLISH_OR_UNPUBLISH from "../../../graphql/mutations/offerUpdateStatusMutation";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Buttonlink, ConfirmationDialog } from "components";
import Lightbox from "react-image-lightbox";

export default class RecommendationQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      publish: false,
      open: false,
      rfqId: "",
    };
    this.images = {
      "3ds": require("../../../assets/icon/3ds.png"),
      aac: require("../../../assets/icon/aac.png"),
      ai: require("../../../assets/icon/ai.png"),
      avi: require("../../../assets/icon/avi.png"),
      bmp: require("../../../assets/icon/bmp.png"),
      cad: require("../../../assets/icon/cad.png"),
      cdr: require("../../../assets/icon/cdr.png"),
      css: require("../../../assets/icon/css.png"),
      dat: require("../../../assets/icon/dat.png"),
      dll: require("../../../assets/icon/dll.png"),
      dmg: require("../../../assets/icon/dmg.png"),
      doc: require("../../../assets/icon/doc.png"),
      eps: require("../../../assets/icon/eps.png"),
      fla: require("../../../assets/icon/fla.png"),
      flv: require("../../../assets/icon/flv.png"),
      gif: require("../../../assets/icon/gif.png"),
      html: require("../../../assets/icon/html.png"),
      indd: require("../../../assets/icon/indd.png"),
      iso: require("../../../assets/icon/iso.png"),
      jpg: require("../../../assets/icon/jpg.png"),
      jpeg: require("../../../assets/icon/jpeg.png"),
      js: require("../../../assets/icon/js.png"),
      midi: require("../../../assets/icon/midi.png"),
      mov: require("../../../assets/icon/mov.png"),
      mp3: require("../../../assets/icon/mp3.png"),
      mpg: require("../../../assets/icon/mpg.png"),
      pdf: require("../../../assets/icon/pdf.png"),
      php: require("../../../assets/icon/php.png"),
      png: require("../../../assets/icon/png.png"),
      ppt: require("../../../assets/icon/ppt.png"),
      pptx: require("../../../assets/icon/pptx.png"),
      ps: require("../../../assets/icon/ps.png"),
      psd: require("../../../assets/icon/psd.png"),
      raw: require("../../../assets/icon/raw.png"),
      sql: require("../../../assets/icon/sql.png"),
      svg: require("../../../assets/icon/svg.png"),
      tif: require("../../../assets/icon/tif.png"),
      txt: require("../../../assets/icon/txt.png"),
      text: require("../../../assets/icon/txt.png"),
      wmv: require("../../../assets/icon/wmv.png"),
      xls: require("../../../assets/icon/xls.png"),
      xml: require("../../../assets/icon/xml.png"),
      zip: require("../../../assets/icon/zip.png"),
      unkown: require("../../../assets/icon/unkown.png"),
    };
  }

  notify = (message = "", type = "error") => {
    // Dismiss any existing toasts before showing a new one
    toast.dismiss();
    if (type === "error") {
      return toast.error(message, { position: "top-right" });
    }
    toast.success(message, { position: "top-right" });
  };

  Deleteitemrfq = (id) => {
    client
      .mutate({
        mutation: DELETE_OFFER,
        variables: {
          offer_id: id,
        },
        optimisticResponse: (vars) => {
          console.log("vars", vars);
          return {
            deleteOffer: {
              id: vars.offer_id,
              __typename: "Offer",
            },
          };
        },
        update: (cache, result) => {
          // console.log("result", result)
          const removeObject = cache.identify(result.data.deleteOffer);
          this.closePopup();
          cache.modify({
            fields: {
              Offers: (existingObjectRefs) => {
                console.log("existingObjectRefs", existingObjectRefs);
                return existingObjectRefs?.data?.filter((ObjectRef) => {
                  return cache.identify(ObjectRef) !== removeObject;
                });
              },
            },
          });
          cache.evict({ id: removeObject });
        },
      })
      .then((response) => {
        this.setState({ rfqId: "" });
        this.notify("Recomendation Deleted Successfully", "success");
        // setTimeout(() => {
        //   window.location.reload();
        // }, 500);
      })
      .catch((errors) => {
        console.log(errors);
        this.notify("OOPSS Some Error Occured", "error");
      });
  };

  Updateitemrfq = (id, value) => {
    let status = value ? "0" : "1";

    client
      .mutate({
        mutation: PUBLISH_OR_UNPUBLISH,
        variables: {
          offer_id: id,
          status: status,
        },
      })
      .then((response) => {
        this.notify("success", "Offer Unpublished");
        this.setState({
          publish: true,
        });
        // console.log(response, this.state.publish);
      })
      .catch((error) => {
        // this.notify("error");
        this.setState({
          publish: false,
        });
        // console.log(error, this.state.publish);
      });
  };
  openPopup = (id) => {
    this.setState({ open: true });
    this.setState({ rfqId: id });
  };

  closePopup = () => {
    this.setState({ open: false });
  };
  renderAttachmentComponent = (rfqDetail) => {
    if (rfqDetail && rfqDetail.attachment === null) {
      return;
    }

    let image = "unkown";
    if (
      this.images[
      rfqDetail && rfqDetail.attachment && rfqDetail.attachment.type
      ]
    ) {
      image = rfqDetail.attachment.type;
    }
    { console.log('rfqDetail.type', rfqDetail.type, rfqDetail.attachment.url) }
    switch (rfqDetail.type) {
      case "image":
        return (
          <div className="sm:w-96 w-80 h-52 bg-gray-200 rounded-lg overflow-hidden my-2">
            <div className="flex items-center justify-center h-full w-full">
              <div className="flex flex-col items-center">
                <img
                  className="object-cover sm:w-96 w-80 h-52 object-center cursor-pointer"
                  src={`${process.env.REACT_APP_MAIN_URL}/${rfqDetail.attachment.url}`}
                  alt={rfqDetail.title}
                  onClick={() => this.setState({ isOpen: true })}
                />

                {this.state.isOpen && (
                  <Lightbox
                    mainSrc={`${process.env.REACT_APP_MAIN_URL}/${rfqDetail.attachment.url}`}
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    enableZoom={false}
                  />
                )}
              </div>
            </div>
          </div>
        );
      case "video":
        return (
          <div className="sm:w-96 w-80 h-52 bg-gray-200 rounded-lg overflow-hidden my-2">
            <div className="flex items-center justify-center h-full w-full">
              <div className="flex flex-col items-center">
                <video
                  className="sm:w-96 w-80  h-52"
                  controls
                  autoPlay
                  src={`${process.env.REACT_APP_MAIN_URL}/${rfqDetail.attachment.url}`}
                />
              </div>
            </div>
          </div>
        );
      case "application":
        return (
          <div className="sm:w-96 w-80 h-52 bg-gray-200 rounded-lg my-2">
            <div className="flex items-center justify-center h-full w-full">
              <div className="flex flex-col items-center">
                <img
                  className="object-contain"
                  src={this.images[image]}
                  style={{ height: 100, width: 300 }}
                  alt=""
                />
                <Buttonlink
                  href={`${process.env.REACT_APP_MAIN_URL}/${rfqDetail.attachment.url}`}
                  text={"Download"}
                  className="px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white hover:white block mt-4"
                />
              </div>
            </div>
          </div>
        );
      case "text":
        return (
          <div className="sm:w-96 w-80 h-52 bg-gray-200 rounded-lg my-2">
            <div className="flex items-center justify-center h-full w-full">
              <div className="flex flex-col items-center">
                <img
                  className="object-contain"
                  src={this.images[image]}
                  style={{ height: 100, width: 300 }}
                  alt=""
                />
                <Buttonlink
                  href={`${process.env.REACT_APP_MAIN_URL}/${rfqDetail.attachment.url}`}
                  text={"Download"}
                  className="px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white hover:white block mt-4"
                />
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div className="sm:w-96 w-80 h-52 bg-gray-200 rounded-lg my-2">
            <div className="flex items-center justify-center h-full w-full">
              <div className="flex flex-col items-center">
                <img
                  className="object-contain"
                  src={this.images[image]}
                  style={{ height: 100, width: 300 }}
                  alt=""
                />
                <Buttonlink
                  href={`${process.env.REACT_APP_MAIN_URL}/${rfqDetail.attachment.url}`}
                  text={"Download"}
                  className="px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white hover:white block mt-4"
                />
              </div>
            </div>
          </div>
        );
    }
  };
  CustomHtmlRenderer = ({ htmlContent }) => {
    // Split the HTML content into lines
    const lines = htmlContent?.split("\n");

    // Process each line to generate JSX elements
    const renderedLines = lines?.map((line, index) => {
      if (line?.trim().startsWith("<li")) {
        // If the line starts with an <li> tag, create a bullet point
        return (
          <div key={index} className="flex items-start">
            <div
              className="leading-snug"
              dangerouslySetInnerHTML={{ __html: line }}
            />
          </div>
        );
      } else {
        // Otherwise, render the line as is
        return <div key={index} dangerouslySetInnerHTML={{ __html: line }} />;
      }
    });

    return <div>{renderedLines}</div>;
  };

  render() {
    // console.log("this.props?.questions", this.props?.questions);
    return (
      <div className="space-y-5">
        {this.props?.questions?.map((items) => (
          <div className="box overflow-hidden mx-1 intro-y" key={items.id}>
            <div className="p-5 pb-0 space-y-5">
              <div className="flex items-center gap-x-3 intro-y z-xlfull">
                <Profileavatar singleUser={items.user} />
                <div>
                  <Link
                    to={`/${encryptfunction(items.user.id)}/profile`}
                    state={{ value: "edit", id: items.user.id }}
                    className="SemiBold"
                  >
                    {items.user.firstname} {items.user.lastname}
                  </Link>
                  <p className="Light gray fs-11">{items.user.username}</p>
                </div>
                <div className="ml-auto">
                  <Popover className="relative intro-x">
                    <Popover.Button className="bg-slate-50 w-9 h-9 rounded-full flex items-center justify-center">
                      <HiOutlineDotsVertical size={16} />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-300"
                      enterFrom="opacity-0 translate-y-0"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-250"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-0"
                    >
                      <Popover.Panel className="absolute right-0 mt-3 w-screen max-w-xs">
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <div className="relative bg-white p-2">
                            {/* <Popover.Button className="block py-3 hover:bg-gray-100 rounded-xl px-3 w-full cursor-pointer">
                              <div className="flex items-center cursor-pointer w-full">
                                <HiPencil size={20} />
                                <div className="ml-2">
                                  <p>Edit</p>
                                </div>
                              </div>
                            </Popover.Button> */}
                            {/* <Popover.Button className="block py-3 hover:bg-gray-100 rounded-xl px-3 w-full cursor-pointer">
                              <div
                                className="flex items-center cursor-pointer w-full"
                                onClick={() =>
                                  this.Updateitemrfq(items.id, items.visibility)
                                }
                              >
                                <MdOutlineUnpublished size={20} />
                                <div className="ml-2">
                                  <p>Unpublished</p>
                                </div>
                              </div>
                            </Popover.Button> */}
                            <Popover.Button className="block py-3 hover:bg-gray-100 rounded-xl px-3 w-full cursor-pointer">
                              <div
                                className="flex items-center cursor-pointer w-full"
                                onClick={() => this.openPopup(items.id)}
                              >
                                <BsTrash size={20} />
                                <div className="ml-2">
                                  <p>Delete</p>
                                </div>
                              </div>
                            </Popover.Button>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </Popover>
                </div>
              </div>
              <div className="flex items-center flex-wrap gap-2 ">
                {items?.categories?.map((category) => (
                  <div
                    className="bg-gray-100 hover:opacity-70 rounded-lg px-5 py-1.5 inline-block intro-x"
                    key={category.id}
                  >
                    <p className="fs-13"> {category.name} </p>
                  </div>
                ))}
              </div>
              <div>
                <Link
                  to={`/rfq/${encryptfunction(items.id)}/${convertToSlug(
                    items?.title
                  )}`}
                  state={{ alert: null }}
                  className="Medium fs-14 block  intro-y"
                >
                  {items?.description !== null && items?.description?.length > 0
                    ? this.CustomHtmlRenderer({
                      htmlContent: items.description,
                    })
                    : items.title}
                </Link>
                {/*{
                    items?.attachment?.url &&
                    <div className="sm:w-96 w-80 h-52 bg-gray-200 rounded-lg overflow-hidden my-2">
                      <div className="flex items-center justify-center h-full w-full">
                        <div className="flex flex-col items-center">
                          <img
                              className="object-cover sm:w-96 w-80 h-52 object-center cursor-pointer"
                              src={`${process.env.REACT_APP_MAIN_URL}/${items.attachment.url}`}
                              alt={items.title}
                          />
                        </div>
                      </div>
                    </div>
                }*/}
                {this.renderAttachmentComponent(items)}
              </div>
              <div className="border-t py-3 intro-y flex items-center justify-between">
                <div className="flex items-center gap-x-2">
                  <img
                    alt="messages"
                    src={messages}
                    className="h-7 w-7 cursor-pointer"
                  />
                  <p className="gray fs-13 Light">
                    {items.comments?.paginatorInfo?.total ?? 0}
                  </p>
                </div>
                <Buttonlink
                  href={
                    items?.id
                      ? `${process.env.REACT_APP_PL_WEB_URL
                      }/recommendation/${encryptfunction(
                        items.id
                      )}/${convertToSlug(items.title)}`
                      : `${process.env.REACT_APP_PL_WEB_URL}`
                  }
                  text="Post to community"
                  className={`px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 white hover:text-white whitespace-nowrap ${items?.readable_status === "Closed"
                    ? "pointer-events-none cursor-none bg--gray"
                    : "bg--primary "
                    }`}
                />
              </div>
            </div>
          </div>
        ))}

        <Dialog
          fullWidth={false}
          maxWidth={"sm"}
          open={this.state.open}
          onClose={this.closePopup}
          className="rounded--xl"
        >
          <div className="p-10 mt-5 relative">
            <div className="absolute right-3 -top-2 bg-white">
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.closePopup}
                aria-label="close"
              >
                <IoCloseCircleOutline size={32} className="primary" />
              </IconButton>
            </div>
            <h2 className="fs-28 text-center Medium">
              Are you sure to perform this action
            </h2>
            <div className="flex items-center justify-center gap-x-3 mt-6">
              <Buttonlink
                onClick={() => this.Deleteitemrfq(this.state.rfqId)}
                text="Yes"
                title="Yes"
                className="w-32 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:bg--primary hover:opacity-70 bg--primary white hover:white gap-x-3 flex items-center justify-center"
              />
              <Buttonlink
                onClick={this.closePopup}
                text="Cancel"
                title="Cancel"
                className="w-32 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover: bg--lightGray hover:opacity-70 hover:black gap-x-3 flex items-center justify-center bg--lightGray"
              />
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}
