import { gql } from "@apollo/client";

export default gql`
    mutation updatePassword(
        $current_password: String!
        $new_password: String!
        $confirm_password: String!
    ) {
        updatePassword(
            current_password: $current_password
            new_password: $new_password
            confirm_password: $confirm_password
        ) {
            error
            message
        }
    }
`;