import Avatar from "@mui/material/Avatar";
import * as React from "react";
import { PROFILE_PHOTO_URL } from "../../config/constants";
import { connect } from "react-redux";

function ProfileAvatar(props) {
  const [userStatus, setUserStatus] = React.useState(false);
  function addDefaultSrc(ev) {
    ev.target.src =
      "https://images.unsplash.com/photo-1635701886662-53dc6aae71c7?ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDJ8dG93SlpGc2twR2d8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60";
  }

  const { singleUser } = props; // Destructure the prop for easier access
  React.useEffect(() => {
    if (props.socket?.connected) {
      props.socket?.on("checkActive", (data) => {
        if (data?.id === singleUser?.id) {
          setUserStatus(true);
        }
      });
      props.socket?.on("checkInActive", (data) => {
        if (data.id === singleUser?.id) {
          setUserStatus(false);
        }
      });
    }
  }, [props.socket]);

  return (
    <div className="relative">
      {singleUser?.profile_photo ? (
        <>
          <Avatar
            alt={singleUser.firstname}
            src={
              process.env.REACT_APP_MAIN_URL +
              PROFILE_PHOTO_URL +
              `/${singleUser.profile_photo}`
            }
            sx={props.sizeStyle}
            className={props.className}
            key={singleUser.id}
            onError={(ev) => addDefaultSrc(ev)}
          />
          {userStatus && (
            <div className="absolute -top-1 rounded-full h-4 border-2 border-white w-4 bg-green-500" />
          )}
        </>
      ) : (
        <>
          <Avatar
            alt={singleUser?.firstname}
            sx={props.sizeStyle}
            className={props.className}
            key={singleUser?.id}
            onError={(ev) => addDefaultSrc(ev)}
          />
          {userStatus && (
            <div className="absolute -top-1 rounded-full h-4 border-2 border-white w-4 bg-green-500" />
          )}
        </>
      )}
    </div>
  );
}
const mapStateToProps = ({ Auth }) => {
  return {
    authUser: Auth.authUserInformation,
    socket: Auth.socket,
  };
};

export default connect(mapStateToProps, null)(ProfileAvatar);
