import React, { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import { Buttonlink, Loader } from "components";
import { IoIosArrowBack } from "react-icons/io";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import { DecryptUrl, encryptfunction } from "../../../helpers";
import getOffer from "graphql/queries/getOffer";
import client from "../../../graphql/client";
import { gql, useQuery } from "@apollo/client";
import cookie from "js-cookie";
import Moment from "react-moment";
import { Alert } from '@mui/material';

const GET_OFFER_TRACK = gql`
  query getOfferTrack($offer_id: ID!, $user_id: ID!) {
    getOfferTrack(offer_id: $offer_id, user_id: $user_id) {
      id
      offer_id
      user_id
      track_comments
      track_date
      track_status {
        status
        percentageMap
      }
    }
  }
`;

const Aggregate = () => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [numericValue, setNumericValue] = useState(0);
  const [loader, setLoader] = useState(true);


  const { offer_id } = params;
  let getOfferFromCookie = null;
  try {
    getOfferFromCookie = JSON.parse(cookie.get('Selected-Offer'));
  } catch (error) {
    getOfferFromCookie = null;
  }
  const id = DecryptUrl(offer_id) ?? parseInt(location.state?.offer?.id);

  const [offer, setOffer] = useState(null);
  const [filteredParticipants, setFilteredParticipants] = useState(null);

  const { loading, error, data } = useQuery(GET_OFFER_TRACK, {
    variables: {
      offer_id: offer?.id,
      user_id: offer?.user?.id,
    },
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("Error message:", error);
  }

  useEffect(() => {
    if (data && offer?.id && data.getOfferTrack.length > 0) {
      const lastObject = data.getOfferTrack[data.getOfferTrack.length - 1];
      const percentageMap = lastObject.track_status.percentageMap;
      const numericValue = parseInt(percentageMap, 10);
      setNumericValue(numericValue);
    }
  }, [data, offer]);

  useEffect(() => {
    // Function to fetch initial data
    const fetchInitialData = async () => {
      if (!id) {
        setLoader(false);
        return;
      }

      try {
        const getOfferVariable = await client.query({
          query: getOffer,
          variables: { id: id },
        });

        if (getOfferVariable && getOfferVariable.data && getOfferVariable.data.offer) {
          const offerData = getOfferVariable.data.offer;
          const offerString = JSON.stringify(offerData);
          cookie.set("Selected-Offer", offerString, { expires: 1 });
          setOffer(offerData);

          // Fetch filtered participants after getting the offer data
          const participants = offerData?.participants?.data.filter(
            (participant) =>
              participant.approved && participant.readable_status === "Complete"
          );

          if (participants && participants[0]) {
            setFilteredParticipants(participants[0]);
          }
        } else if (location.state?.offer?.id) {
          setOffer(location.state.offer);
        } else if (getOfferFromCookie?.id) {
          setOffer(getOfferFromCookie);
        } else {
          setLoader(false);
          return;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoader(false);
      }
    };

    fetchInitialData().then(r => {
      setLoader(false);
    });
  }, []);

  useEffect(() => {
    if (offer) {
      const participants = offer?.participants?.data.filter(
        (participant) =>
          participant.approved && participant.readable_status === "Complete"
      );

      if (participants && participants[0]) {
        setFilteredParticipants(participants[0]);
      }
    }
  }, [offer]);

  const url = process.env.REACT_APP_WEB_URL;
  const hasDev = url.includes("dev");
  return (
    <>
      {!loader ? (
        <div className="container mt-20 intro-y">
          <div className="relative mb-5">
            <Buttonlink
              text="Back"
              prefix={<IoIosArrowBack size={16} />}
              onClick={() => navigate(-1)}
              className="bg-gray-300 hover:opacity-80 fs-13 flex items-center justify-center gap-x-1 h-9 rounded-full darkGray w-24 border-0 shadow-none"
            />
          </div>
          <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
            <Tab.List
              className="z-full lg:flex relative lg:p-2 md:p-3 p-4 lg:h-16 lg:space-y-0 space-y-3 lg:space-x-3 bg-gray-300 rounded-xl">
              <Tab
                className={({ selected }) =>
                  classNames(
                    "w-full whitespace-nowrap lg:py-2.5 rounded-md bg-white py-4 px-3 fs-15 leading-5 Regular hover:bg--primary hover:white",
                    selected ? "bg--primary white" : ""
                  )
                }
              >
                Spend Analytics
              </Tab>
              <Tab
                className={({ selected }) =>
                  classNames(
                    "w-full whitespace-nowrap lg:py-2.5 rounded-md bg-white py-4 px-3 fs-15 leading-5 Regular hover:bg--primary hover:white",
                    selected ? "bg--primary white" : ""
                  )
                }
              >
                Trace your sourcing
              </Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                {
                  selectedIndex === 0 &&
                  <div
                    className="intro-x p-5 w-full mt-5 transform overflow-hidden rounded-2xl bg-white text-left align-middle transition-all">
                    <div className="flex flex-col">
                      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                          <div
                            className="overflow-x-hidden overflow-y-auto max-h-[550px] h-full">
                            <table className="min-w-full text-left text-sm font-light">
                              <thead className="border-b bg-white font-medium">
                                <tr>
                                  <th scope="col" className="px-6 py-4">
                                    RFQ Type
                                  </th>
                                  <th scope="col" className="px-6 py-4">
                                    RFQ Title
                                  </th>
                                  <th scope="col" className="px-6 py-4">
                                    UOM
                                  </th>
                                  <th scope="col" className="px-6 py-4">
                                    Quantity
                                  </th>
                                  <th scope="col" className="px-6 py-4">
                                    Last date
                                  </th>
                                  <th scope="col" className="px-6 py-4">
                                    Supplier Name
                                  </th>
                                  <th scope="col" className="px-6 py-4">
                                    Invoice Amount
                                  </th>
                                  <th scope="col" className="px-6 py-4">
                                    File
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="border bg-neutral-100">
                                  <td className="whitespace-nowrap border px-6 py-4 font-medium">
                                    {offer.menu?.name}
                                  </td>
                                  <td className="whitespace-nowrap border px-6 py-4">
                                    {offer.title?.slice(0, 20)}....
                                  </td>
                                  <td className="whitespace-nowrap border px-6 py-4">
                                    {offer?.uom}
                                  </td>
                                  <td className="whitespace-nowrap border px-6 py-4">
                                    {offer?.quantity}
                                  </td>
                                  <td className="whitespace-nowrap border px-6 py-4">
                                    {offer?.last_date}
                                  </td>
                                  <td className="whitespace-nowrap border px-6 py-4">
                                    {filteredParticipants?.user?.firstname}{" "}
                                    {filteredParticipants?.user?.lastname}
                                  </td>
                                  <td className="whitespace-nowrap border px-6 py-4">
                                    $ {filteredParticipants?.total}
                                  </td>
                                  <td className="whitespace-nowrap border px-6 py-4">
                                    <Buttonlink
                                      href={`${process.env.REACT_APP_MAIN_URL
                                        }/print-po/${encryptfunction(
                                          offer.id + "," + hasDev
                                        )}`}
                                      text={"PO"}
                                      className="px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </Tab.Panel>
              <Tab.Panel>
                {
                  selectedIndex === 1 &&
                  <>
                    <div className="intro-x mt-5 p-5">
                      {
                        data?.getOfferTrack.length === 0 ?
                          <Alert severity="error">"No Record Found"</Alert> :
                          data?.getOfferTrack.map((val, index) => (
                            <div className="flex relative items-start pb-8" key={val.id}>
                              <div className="flex-shrink-0 w-32 mr-2">
                                {/* val.track_date goes here */}
                                <Moment format="DD-MMM-YYYY" withTitle className="fs-11">
                                  {val.track_date}
                                </Moment>
                              </div>
                              <div className="h-full ml-20 w-6 absolute inset-0 flex items-center justify-center">
                                <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                              </div>
                              <div className="flex-shrink-0 w-6 h-6 rounded-full inline-flex items-center justify-center bg--primary text-white relative z-10 font-medium text-sm">
                                {index + 1}
                              </div>
                              <div className="flex-grow flex sm:items-center items-start flex-col sm:flex-row">
                                <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                                  <h2 className="font-medium Medium text-gray-900 mb-1 text-md">
                                    {val.track_status.status}
                                  </h2>
                                  <p className="leading-relaxed text-sm">{val.track_comments}</p>
                                </div>
                              </div>
                            </div>
                          ))
                      }
                    </div>
                    {numericValue !== 0 && (
                      <div className="p-5 relative">
                        <LinearProgress
                          variant="determinate"
                          value={numericValue}
                          color="success"
                        />
                        <div className="absolute right-[13px] -top-[7px]">
                          <span className="Regular">{numericValue}%</span>
                        </div>
                      </div>
                    )}
                  </>
                }
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Aggregate;
