import { gql } from "@apollo/client";

export default gql`
  mutation uploadProfilePic($file: String!) {
    uploadProfilePic(file: $file) {
      error
      message
      path
    }
  }
`;
