import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";

export default function CustomMessage(props) {
    return (
        <div className="h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-100">
            <div className="max-w-xl w-full space-y-8">
                <div className="report-box">
                    <div className="intro-y box px-5 py-20 mt-5 overflow-hidden">
                        <div className="flex flex-col items-center">
                            {/*<h4 className="Medium lg:text-9xl text-4xl">  No Record Found !</h4>*/}

                            <h1 className="Medium mb-3 text-2xl text-center md:text-3xl">
                                {props.message}
                            </h1>

                            {/*<p className="mb-8 Regular text-center text-gray-500 md:text-lg">*/}
                            {/*    {props.message}*/}
                            {/*</p>*/}
                            <Link
                                to={"/"}
                                className="bg-gradient-to-r from-yellow-500 to-yellow-400 text-yellow-900 hover:text-yellow-900  flex items-center h-12 rounded-xl px-10 hover:opacity-60"
                            >
                                <IoIosArrowBack /> Go to Home
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
