import * as React from "react";
import { NavLink } from "react-router-dom";

const PaymentPlanNavigation = (props) => {
    return (

        <div className="flex w-1/2 lg:mt-0 my-14 bg-white p-1.5 gap-x-2 rounded-lg mx-auto">
            {props.type === "profile_systemplan" || props.type === "profile_customplan" ?
                <>
                    <NavLink
                        to="/pricing/profile_systemplan"
                        className={({ isActive }) =>
                            isActive
                                ? "bg--primary text-white hover:text-white hover:bg--primary rounded-lg  text-center w-full py-2.5 lg:text-[16px] text-[14px] leading-5  outline-none"
                                : "text-black hover:bg-[#edf0f4] rounded-lg  text-center w-full py-2.5 lg:text-[16px] text-[14px] leading-5 outline-none"
                        }
                    >
                        Systems Plans
                    </NavLink>
                    <NavLink
                        to="/pricing/profile_customplan"
                        className={({ isActive }) =>
                            isActive
                                ? "bg--primary text-white hover:text-white hover:bg--primary rounded-lg  text-center w-full py-2.5 lg:text-[16px] text-[14px] leading-5  outline-none"
                                : "text-black hover:bg-[#edf0f4] rounded-lg  text-center w-full py-2.5 lg:text-[16px] text-[14px] leading-5 outline-none"
                        }
                    >
                        Custom Plan
                    </NavLink>
                </>
                : props.type === "system" || props.type === "custom" ?
                    <>
                        <NavLink
                            to="/pricing/system"
                            className={({ isActive }) =>
                                isActive
                                    ? "bg--primary text-white hover:text-white hover:bg--primary rounded-lg  text-center w-full py-2.5 lg:text-[16px] text-[14px] leading-5  outline-none"
                                    : "text-black hover:bg-[#edf0f4] rounded-lg  text-center w-full py-2.5 lg:text-[16px] text-[14px] leading-5 outline-none"
                            }
                        >
                            Systems Plans
                        </NavLink>
                        <NavLink
                            to="/pricing/custom"
                            className={({ isActive }) =>
                                isActive
                                    ? "bg--primary text-white hover:text-white hover:bg--primary rounded-lg  text-center w-full py-2.5 lg:text-[16px] text-[14px] leading-5  outline-none"
                                    : "text-black hover:bg-[#edf0f4] rounded-lg  text-center w-full py-2.5 lg:text-[16px] text-[14px] leading-5 outline-none"
                            }
                        >
                            Custom Plan
                        </NavLink>
                    </>
                    : null}

        </div>
    );
};

export default PaymentPlanNavigation;
