import React, { Component } from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { IoCloseCircleOutline } from "react-icons/io5";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { VscFilePdf } from "react-icons/vsc";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import getPayment from "graphql/queries/getPayment";
import Nocontent from "components/Nocontent";
import { IoIosArrowBack } from "react-icons/io";
import Buttonlink from "components/buttonlink";
import { encryptfunction } from "helpers";
import client from "graphql/client";
import { Loader } from "components";
import store from "store";
import { UPDATE_AUTH_USER_PROPERTY } from "store/actions/types";

class PurchaseHistoryDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      purchase_detail: false,
      purchase_item: {},
      loader: false,
      payments: [],
    };
  }

  openPopup = () => {
    this.setState({ open: true, loader: true });
    this.getPayments();
  };

  getPayments = () => {
    client.query({
      query: getPayment,
      fetchPolicy: 'no-cache',
    }).then((res) => {
      this.setState({ payments: res.data.me.payments }, () => {
        // This code will run after the state is updated.
        this.setState({ loader: false });
      });
      this.setState({ payments: res.data.me.payments });
      store.dispatch({
        type: UPDATE_AUTH_USER_PROPERTY,
        payload: { key: "payments", value: res.data.me.payments },
      });
    })
  }


  closePopup = () => {
    this.setState({ open: false });
    this.setState({ purchase_detail: false });
    this.setState({ purchase_item: {} });
  };

  noContent = {
    text: "No Purchases Yet? Lets do some enjoy features",
    to: '/pricing/system',
    title: "Go to Plans"
  }

  render() {
    const { open, purchase_item } = this.state;
    const { payments } = this.state;
    const url = process.env.REACT_APP_WEB_URL;
    const hasDev = url.includes("dev");
    return (
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        scroll="body"
        open={open}
        onClose={this.closePopup}
        className="rounded--xl"
      >
        <div className="shadow-none bg-gray-100">
          <Toolbar className="flex items-center w-full justify-between relative">
            {this.state.purchase_detail && (
              <IconButton
                edge="start"
                color="inherit"
                onClick={() =>
                  this.setState({
                    purchase_detail: false,
                  })
                }
                className=""
              >
                <IoIosArrowBack size={30} className="primary" />
              </IconButton>
            )}
            {this.state.purchase_detail ? (
              <h3 className="black Medium text-xl">Purchase Detail</h3>
            ) : (
              <h3 className="black Medium text-xl">Purchase History</h3>
            )}
            <IconButton
              edge="start"
              color="inherit"
              onClick={this.closePopup}
              aria-label="close"
              className=""
            >
              <IoCloseCircleOutline size={32} className="primary" />
            </IconButton>
          </Toolbar>
        </div>
        <div className="p-6">
          <div className="box overflow-hidden intro-y">
            {this.state.purchase_detail && purchase_item ? (
              <>
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50 intro-y">
                    <tr>
                      <th scope="col" className="px-6 py-6">
                        <p className="darkGray fs-13 Light uppercase tracking-wider text-left">
                          Features
                        </p>
                      </th>
                      <th scope="col" className="pr-6 py-6">
                        <p className="darkGray fs-12 Light uppercase tracking-wider text-left">
                          Remaining Features
                        </p>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    <tr className="intro-y zoom-in">
                      <td className="py-5 px-6">
                        <p className="fs-14">Validity</p>
                      </td>
                      <td className="py-5 px-6">
                        <p className="fs-14">
                          {purchase_item?.plan?.subtitle}
                        </p>
                      </td>
                    </tr>
                    {purchase_item?.plan?.user_plan_features?.filter((o) => purchase_item.id == o.payment_id).map((planFeatures, index) => (
                      <tr
                        key={index}
                        className={`intro-y zoom-in ${index % 2 === 0 ? "bg-gray-200" : ""
                          }`}
                      >
                        <td className="py-5 px-6">
                          <p className="fs-14">{planFeatures.name}</p>
                        </td>
                        <td className="py-5 px-6">
                          <p className="fs-14">
                            {planFeatures.quantity === "Check Box"
                              ? "Unlimited"
                              : planFeatures.quantity}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <div className="overflow-x-auto">
                {payments && payments.length !== 0 ? (
                  <table className="min-w-full divide-y divide-gray-200 ">
                    <thead className="bg-gray-50 intro-y">
                      <tr>
                        <th scope="col" className="px-6 py-6">
                          <p className="darkGray fs-13 Light uppercase tracking-wider text-left">
                            RECEIPT ID
                          </p>
                        </th>
                        <th scope="col" className="pr-6 py-6">
                          <p className="darkGray fs-12 Light uppercase tracking-wider text-left">
                            Name
                          </p>
                        </th>
                        <th scope="col" className="pr-6 py-6">
                          <p className="darkGray fs-12 Light uppercase tracking-wider text-left">
                            CREATED AT
                          </p>
                        </th>
                        <th scope="col" className="pr-6 py-6">
                          <p className="darkGray fs-12 Light uppercase tracking-wider text-left">
                            VALIDITY
                          </p>
                        </th>
                        <th scope="col" className="pr-6 py-6">
                          <p className="darkGray fs-12 Light uppercase tracking-wider text-left">
                            tOTAL PRICE
                          </p>
                        </th>
                        <th scope="col" className="pr-6 py-6">
                          <p className="darkGray fs-12 Light uppercase tracking-wider text-left">
                            EXPIRY
                          </p>
                        </th>
                        <th scope="col" className="pr-6 py-6">
                          <p className="darkGray fs-12 Light uppercase tracking-wider text-left">
                            PDF
                          </p>
                        </th>
                        <th scope="col" className="pr-6 py-6">
                          <p className="darkGray fs-12 Light uppercase tracking-wider text-left">
                            Detail
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {payments &&
                        payments.map((item) => {
                          return (
                            <tr className="intro-y zoom-in" key={item.id}>
                              <td className="px-6 py-5">
                                <p className="fs-14">{item.transaction_id} </p>
                              </td>
                              <td className="pr-6 py-5 flex items-center">
                                <p className="fs-14">{item?.plan?.title}</p>
                              </td>
                              <td className="pr-6 py-5 whitespace-nowrap">
                                <div className="rounded-full px-3 py-1 bg-teal-100 text-teal-600 Medium fs-13 inline-block">
                                  <Moment
                                    format="D MMM YYYY"
                                    withTitle
                                    className="fs-11"
                                  >
                                    {item.created_at}
                                  </Moment>
                                </div>
                              </td>
                              <td className="pr-6 py-5 whitespace-nowrap">
                                <div className="rounded-full px-3 py-1 bg-red-100 text-red-800 Medium fs-13 inline-block">
                                  {item?.plan?.subtitle}
                                </div>
                              </td>
                              <td className="pr-6 py-5 whitespace-nowrap">
                                <p className="fs-14">${item?.plan?.price}</p>
                              </td>
                              <td className="pr-6 py-5 whitespace-nowrap">
                                <div className="rounded-full px-3 py-1 bg-teal-100 text-teal-600 Medium fs-13 inline-block">
                                  <Moment
                                    format="D MMM YYYY"
                                    withTitle
                                    className="fs-11"
                                  >
                                    {item.plan_expire_date}
                                  </Moment>
                                </div>
                              </td>
                              <td className="pr-6 py-5 whitespace-nowrap">
                                <a
                                  href={`${process.env.REACT_APP_MAIN_URL
                                    }/payment-invoice/${encryptfunction(
                                      item.id + "," + hasDev
                                    )}`}
                                  target="_blank"
                                >
                                  <VscFilePdf size={22} />
                                </a>
                              </td>
                              <td className="pr-6 py-5 whitespace-nowrap">
                                <button
                                  onClick={() =>
                                    this.setState({
                                      purchase_detail: true,
                                      purchase_item: item,
                                    })
                                  }
                                >
                                  <AiOutlineInfoCircle size={22} />
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                ) : this.state.loader ? (<Loader />) : (
                  <Nocontent noContent={this.noContent} />
                )}
              </div>
            )}
          </div>
        </div>
      </Dialog>
    );
  }
}

export default PurchaseHistoryDialog;
