import React, { useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import Buttonlink from "components/buttonlink";
import SignatureCanvas from "react-signature-canvas";
import { Checkbox, IconButton } from "@mui/material";
import { IoCloseCircleOutline } from "react-icons/io5";
import axios from "axios";
import { toast } from "react-toastify";
import { GRAPH_MRO_USER_AUTH_TOKEN } from "config/constants";
import cookie from "js-cookie";
import docu from "../../assets/file/Buying Simplified_MNDA_15072024.docx";
import logo from "../../assets/images/buying-simplified.png";
import moment from "moment";

const RFQSignaturePopup = ({
  isDocu,
  closePopup,
  closeSignature,
  offer_id,
}) => {
  const [values, setValues] = useState({
    date: moment().format("YYYY-MM-DD"),
    company: "",
    lawsOf: "",
    supplier: "",
  });
  const [docuFile, setDocuFile] = useState(null);
  const [term_condition, setTermCondition] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [isImage, setIsImage] = useState(null);
  const sigCanvas = useRef({});
  const fileRef = useRef(null);
  const docuFileRef = useRef(null);

  const handleFile = async (event) => {
    const token = cookie.get(GRAPH_MRO_USER_AUTH_TOKEN);
    const file = event.target.files[0];
    sigCanvas.current.clear();
    if (file) {
      const validTypes = ["image/jpeg", "image/png", "image/gif"];

      // Check file type
      if (!validTypes.includes(file.type)) {
        toast.error("Invalid file type. Please select a image(jpg, png, gif).");
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        toast.error("File size exceeds 5MB. Please choose a smaller file.");
        return;
      }
      const reader = new FileReader();
      reader.onloadend = async () => {
        if (typeof reader.result === "string") {
          const formData = new FormData();
          formData.append("offer_id", offer_id);
          formData.append("file", reader.result);
          if (docuFile !== null) {
            formData.append("pdf_file", docuFile);
          } else {
            const data = Object.assign(values, {});
            formData.append("fields", JSON.stringify(data));
          }
          try {
            const response = await axios.post(
              `${process.env.REACT_APP_MAIN_URL}/api/rfq-signature`,
              formData,
              { headers: { Authorization: `Bearer ${token}` } }
            );
            if (response.status === 200) {
              closeSignature();
              setIsVerify(false);
            }
          } catch (error) {}
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const verifySignature = async () => {
    const token = cookie.get(GRAPH_MRO_USER_AUTH_TOKEN);
    const formData = new FormData();
    formData.append("offer_id", offer_id);
    if (isImage !== null) {
      formData.append("file", isImage);
    }

    if (docuFile !== null) {
      formData.append("pdf_file", docuFile);
    } else {
      const data = Object.assign(values, {});
      formData.append("fields", JSON.stringify(data));
    }

    if (isImage === null && docuFile === null) {
      return toast.error("Please upload signature first");
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_MAIN_URL}/api/rfq-signature`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        closeSignature();
        setIsVerify(false);
      }
    } catch (error) {}
  };

  const handleSignatureEnd = () => {
    setIsImage(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
  };
  const handleState = () => {
    if (docuFile === null) {
      setIsVerify(true);
    } else {
      verifySignature();
    }
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const acceptedFileTypes = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    if (file && acceptedFileTypes.includes(file.type)) {
      setDocuFile(file);
      toast.success("Your file is uploaded successfully");
    } else {
      toast.error("Please upload a valid Word document (.doc or .docx)");
      e.target.value = null; // Reset the file input
    }
  };

  const allFieldsNonEmpty =
    values.date !== "" &&
    values.company !== "" &&
    values.lawsOf !== "" &&
    values.supplier !== "";

  const disable = (allFieldsNonEmpty || docuFile !== null) && term_condition;
  console.log("disable", disable);
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={isDocu}
        onClose={closePopup}
        PaperProps={{
          style: {
            borderRadius: "20px",
          },
        }}
        className="rounded--xl"
      >
        <div className="p-10 mt-5 relative flex flex-col gap-5 overflow-y-scroll">
          <div className="flex items-center justify-between">
            <img src={logo} className="w-28 object-contain" />
            <h1 className="text-center font-semibold Medium text-xl">
              CONFIDENTIALITY & NON-DISCLOSURE AGREEMENT
            </h1>
            <img src={'https://mro.procurementleague.com/assets/images/logo.png'} alt="sg" className="w-[100px]" />
          </div>
          <IconButton
            edge="start"
            color="inherit"
            onClick={closePopup}
            aria-label="close"
            className="!absolute -top-2 right-2"
          >
            <IoCloseCircleOutline size={32} className="primary" />
          </IconButton>
          <p className="text-lg font-medium text-black Regular leading-9">
            This Confidentiality and Non-Disclosure Agreement (“Agreement”)
            dated {values.date}{" "}
            {/* <input
              type="date"
              value={values.date}
              // onChange={(e) => setValues({ ...values, date: e.target.value })}
              min={moment().format("YYYY-MM-DD")}
              className="border-black border-b w-36 placeholder:text-lg placeholder:font-medium placeholder:text-black Regular"
            />{" "} */}
            (“Effective Date”) is entered into between{" "}
            <strong className="Bold">Buying Simplified LLC</strong>, a US based
            Limited Liability Corporation with its principal place of business
            at 4 Esprit Terrace, Chesterbrook, PA - 19087, USA, (“BS”), and{" "}
            <input
              type="text"
              value={values.company}
              max={50}
              maxLength={50}
              onChange={(e) => {
                setValues({ ...values, company: e.target.value });
                e.target.style.width = "auto";
                e.target.style.width = e.target.scrollWidth + "px";
              }}
              className="border-black border-b placeholder:text-lg placeholder:font-medium placeholder:text-black Regular"
            />{" "}
            , a corporation existing under the laws of{" "}
            <input
              type="text"
              value={values.lawsOf}
              max={50}
              maxLength={50}
              onChange={(e) => {
                setValues({ ...values, lawsOf: e.target.value });
                e.target.style.width = "auto";
                e.target.style.width = e.target.scrollWidth + "px";
              }}
              className="border-black border-b placeholder:text-lg placeholder:font-medium placeholder:text-black Regular"
            />{" "}
            with its principal place of business at a company, with its place of
            business at{" "}
            <input
              type="text"
              value={values.supplier}
              max={50}
              maxLength={50}
              onChange={(e) => {
                setValues({ ...values, supplier: e.target.value });
                e.target.style.width = "auto";
                e.target.style.width = e.target.scrollWidth + "px";
              }}
              className="border-black border-b placeholder:text-lg placeholder:font-medium placeholder:text-black Regular"
            />{" "}
            (“Supplier”).
          </p>
          <p className="text-lg font-medium text-black Regular">
            BS and Supplier shall collectively be referred to as “Parties” and
            individually as “Party”.
          </p>
          <p className="Bold text-lg text-black">WHEREAS:</p>
          <ul style={{ listStyle: "upper-roman" }} className="space-y-5">
            <li>
              <p className="text-lg font-medium text-black Regular">
                Whereas, BS and Supplier propose to enter into certain
                negotiations and discussions for exploring mutual business
                opportunities <strong className="Bold">(“Purpose”)</strong>, in
                course whereof either of the Party (the “
                <strong className="Bold">Disclosing Party</strong>
                ”) may disclose to the other Party (the “
                <strong className="Bold">Recipient</strong>”) certain
                Confidential Information (as defined hereunder).
              </p>
            </li>
            <li>
              <p className="text-lg font-medium text-black Regular">
                Whereas, the Disclosing Party intends that the Recipient uses
                the Confidential Information only for the Purpose of enabling
                the Parties to assess and evaluate the possibility of mutually
                beneficial collaboration and subject to the terms and conditions
                agreed herein.
              </p>
            </li>
          </ul>
          <p className="Bold text-lg text-black">
            NOW THEREFORE in consideration of the premises and mutual
            obligations hereinafter described the receipt and sufficiency of
            which is hereby acknowledged, Parties hereby covenant and agree as
            follows:
          </p>
          <p className="text-lg font-medium text-black Regular">
            1.{" "}
            <u className="text-lg font-medium text-black Regular">Definition</u>
          </p>
          <p className="text-lg font-medium text-black Regular">
            1.1 For purposes of this Agreement, “Confidential Information” means
            any and all information disclosed to, or otherwise acquired or
            observed by, a Party including its directors, officers and employees
            (all of the foregoing collectively referred to as “Recipient’s
            Representatives”), from the Disclosing Party, relating to the
            business of the Disclosing Party, whether communicated in writing,
            orally, electronically, photographically, or in recorded or any
            other form, including, but not limited to, all sales and operating
            information, customer lists and customer information, existing and
            potential business and marketing plans and strategies, vendor
            information, financial information, cost and pricing information,
            data media, know-how, designs, drawings, specifications, source
            codes, object codes, technical information, concepts, reports,
            methods, processes, techniques, operations, devices, and the like,
            whether or not the foregoing information is patented, tested,
            reduced to practice, or subject to copyright.
          </p>
          <p className="text-lg font-medium text-black Regular">
            1.2 The term “Confidential Information” does not include information
            which (i) becomes generally available to the public other than as a
            result of disclosure by Recipient in breach of this Agreement; (ii)
            was available to Recipient on a non-confidential basis as shown in
            written records prior to its disclosure to Recipient by Disclosing
            Party; (iii) becomes available to Recipient on a non-confidential
            basis from a source other than Disclosing Party; provided that such
            source is not bound by a confidentiality agreement with Disclosing
            Party or is not otherwise prohibited from transferring the
            information to Recipient by a contractual, legal or fiduciary
            obligation; or (iv) is independently developed by Recipient without
            any use of or benefit from the Confidential Information and such
            independent development can be documented by Recipient with written
            records.
          </p>
          <p className="text-lg font-medium text-black Regular">
            2.{" "}
            <u className="text-lg font-medium text-black Regular">
              Non-Disclosure.
            </u>{" "}
            In consideration for the receipt of Confidential Information,
            Recipient must hold all Confidential Information in confidence and
            protect that Confidential Information with the same degree of care
            it uses to keep its own similar information confidential, but in no
            event may it use less than a reasonable degree of care; and
            Recipient may not, without the prior written consent of Disclosing
            Party, disclose any Confidential Information to any person for any
            reason at any time;{" "}
            <u className="text-lg font-medium text-black Regular">
              provided, however
            </u>
            , it is understood that Recipient may disclose the Confidential
            Information to those of the Recipient’s Representatives and to its
            tax, accounting and legal advisers who actually need the
            Confidential Information for the purpose of evaluating the proposed
            Transaction on the condition that, prior to such disclosure,
            Recipient’s Representatives and advisers have been advised of the
            non-public nature of the Confidential Information and directed to
            treat the Confidential Information in accordance with the terms of
            this Agreement. Each Recipient shall be responsible for any breach
            of this Agreement by any the Recipient’s Representatives or
            Recipient’s advisers.
          </p>
          <p className="text-lg font-medium text-black Regular">
            3. <u className="text-lg font-medium text-black Regular">Use.</u>{" "}
            Recipient may not use any Confidential Information for any reason or
            purpose other than as necessary in regard to the Transaction.
            Recipient may not make any other use of the Confidential Information
            or incorporate any Confidential Information into any work or
            product.
          </p>
          <p className="text-lg font-medium text-black Regular">
            4.{" "}
            <u className="text-lg font-medium text-black Regular">Ownership.</u>{" "}
            All tangible and intangible information relating to Confidential
            Information, including notes, memoranda, reports, compilations,
            derivative works, or other documents or material whether in tangible
            or intangible form prepared by Recipient in connection with the
            evaluation of the proposed Transaction, including all copies thereof
            (collectively “Derivative Works”), are and will remain the sole
            property of Disclosing Party, and Recipient must keep the same at
            all times in its custody and subject to Recipient’s control.
          </p>
          <p className="text-lg font-medium text-black Regular">
            5.{" "}
            <u className="text-lg font-medium text-black Regular">
              Compelled Disclosure.
            </u>{" "}
            If Recipient or any of Recipient’s Representatives or advisers is
            requested or required (by oral questions, interrogatories, requests
            for information or documents, subpoena, civil investigative demand
            or similar process) to disclose any of the Confidential Information,
            Recipient will provide Disclosing Party with prompt notice of such
            request(s) so Disclosing Party may seek an appropriate protective
            order or other appropriate remedy and/or waive compliance with the
            confidentiality provisions of this Agreement. In the event that such
            protective order or other remedy is not obtained, or Disclosing
            Party grants a waiver hereunder, Recipient or Recipient’s
            Representative or adviser may furnish that portion (and only that
            portion) of the Confidential Information which, in the written
            opinion of Recipient’s counsel, Recipient is legally compelled to
            disclose and must exercise its best efforts to obtain reliable
            assurance that confidential treatment will be accorded any
            Confidential Information so furnished.
          </p>
          <p className="text-lg font-medium text-black Regular">
            6.{" "}
            <u className="text-lg font-medium text-black Regular">
              Return of Confidential Information.
            </u>{" "}
            Promptly following the written request of Disclosing Party,
            Recipient will either (i) deliver to Disclosing Party all documents
            or other materials furnished by Disclosing Party to Recipient
            constituting Confidential Information, including Derivative Works,
            together with all copies thereof, including computer disks; or (ii)
            destroy all other documents or other materials constituting
            Confidential Information, including Derivative Works, together with
            all copies thereof in the possession of Recipient, with such
            destruction confirmed by Recipient in writing to Disclosing Party.
          </p>
          <p className="text-lg font-medium text-black Regular">
            7.{" "}
            <u className="text-lg font-medium text-black Regular">
              No Warranties.
            </u>{" "}
            Neither Party makes any representation or warranty as to the
            accuracy or completeness of the Confidential Information and the
            Confidential Information is provided “as is.”
          </p>
          <p className="text-lg font-medium text-black Regular">
            8.{" "}
            <u className="text-lg font-medium text-black Regular">
              No Obligation.
            </u>{" "}
            Neither Party may make any commitment or incur any expense or charge
            for or in the name of the other Party. Neither Party has any
            obligation by virtue of this Agreement to enter into the transaction
            or any other relationship with the other Party.
          </p>
          <p className="text-lg font-medium text-black Regular">
            9.{" "}
            <u className="text-lg font-medium text-black Regular">
              Agreement not to deal without consent.
            </u>{" "}
            <br />
            The Parties hereby legally, wholly and irrevocably bind themselves
            and guarantee to one another that they shall not directly or
            indirectly interfere with, circumvent or attempt to circumvent,
            avoid, by-pass or obviate each other’s interest, or the interest or
            relationship between the Parties, by means of any procedures,
            sellers, buyers, brokers, dealers, distributors, refiners, shippers,
            financial instructions, technology owners or manufacturers, for the
            purpose of changing, increasing or avoiding, directly or indirectly,
            payments of established or to be established fees, commissions, or
            the continuance of pre- established relationships, or to intervene
            in un-contracted relationships with manufacturers or technology
            owners, intermediaries, entrepreneurs, legal counsel, or to initiate
            any buy/sell or any transactional relationship that by-passes one of
            the Parties in favor of any other individual or entity, in
            connection with the subject transaction or project or any related
            future transaction or project.
          </p>
          <p className="text-lg font-medium text-black Regular">
            10.{" "}
            <u className="text-lg font-medium text-black Regular">
              Agreement to honor commissions / payments.
            </u>{" "}
            <br />
            Parties agree that any commissions, fees, compensation or
            remuneration which the Parties agree with respect to any potential
            business transaction will be adhered to and complied by the Parties
            till a detailed understanding of the same is agreed between the
            Parties and captured in detail in the long form agreement.
          </p>
          <p className="text-lg font-medium text-black Regular">
            11.{" "}
            <u className="text-lg font-medium text-black Regular">
              Agreement to inform.
            </u>{" "}
            <br />
            In the specific situation where a Party acting as an agent of the
            buyer allows the buyer or the buyer’s representative and the seller
            to deal directly with one another, said agent shall be informed of
            the subsequent development of all transactions between the buyer or
            the buyer’s representative, and shall be provided timely copies of
            all pertinent developmental and/or transactional correspondence and
            documentation relative thereto by the buyer or the buyer’s
            representative and/or the seller.
          </p>
          <p className="text-lg font-medium text-black Regular">
            12.{" "}
            <u className="text-lg font-medium text-black Regular">
              Agreement not to circumvent.
            </u>{" "}
            <br />
            The Parties agree not to circumvent or attempt to circumvent this
            agreement in an effort to gain fees, commissions, remunerations or
            considerations to the benefit of the one or more of the Parties with
            the full knowledge and acquiescence of all necessary Parties,
            whether or not such fees, commissions remunerations or
            considerations gained through circumvention would otherwise be
            deemed the rightful property of any one or several of the Parties.
          </p>
          <p className="text-lg font-medium text-black Regular">
            13.{" "}
            <u className="text-lg font-medium text-black Regular">
              Non-Solicitation.
            </u>{" "}
            <br />
            Parties agree not to solicit any potential or existing clients
            and/or customers of either Party, the information of which is shared
            during the term of this Agreement. Neither Party shall anytime
            during or 3 (three) years after the term of this Agreement, enter
            into a direct deal or solicit any client or customer of other Party
            or entice them to break their existing or potential relationship
            with the Party that introduced them to the other. Further, if the
            Parties are collectively discussing or negotiating any potential
            business opportunity during the Term of the Agreement, the said
            potential client or customer shall not be individually pitched to by
            the other Party upon expiry or termination of this Agreement without
            prior written approval of the concerned Party.
          </p>
          <p className="text-lg font-medium text-black Regular">
            14.{" "}
            <u className="text-lg font-medium text-black Regular">
              Term and Termination.
            </u>{" "}
          </p>
          <p className="text-lg font-medium text-black Regular">
            14.1. This Agreement shall come into existence from the Effective
            Date and shall be valid for a period of 1 (one) year or until the
            Parties enter into a long form agreement, whichever is earlier,
            unless terminated earlier by the Parties.
          </p>
          <p className="text-lg font-medium text-black Regular">
            14.2. Parties shall have the right to terminate this Agreement by
            serving 30 (thirty) days written notice to the other Party. Upon
            termination, Recipient will be required to return or delete all
            Confidential Information in its possession and certify the same in
            writing.
          </p>
          <p className="text-lg font-medium text-black Regular">
            14.3. Clauses which by its very nature requires survival shall
            survive the termination or expiry of this Agreement and Parties
            obligation under this Agreement shall survive for a period of 1
            (one) year from date of expiry or termination of this Agreement.
          </p>
          <p className="text-lg font-medium text-black Regular">
            15.{" "}
            <u className="text-lg font-medium text-black Regular">Notices.</u>{" "}
            <br />
            Except as expressly stated herein to the contrary, all notices and
            other communications required or permitted to be given under this
            Agreement shall be in writing and shall be deemed to have been
            properly given if delivered by hand or by courier, or sent by
            prepaid registered mail or by facsimile, addressed to intended
            recipient's address as specified below or such other address as
            either Party may notify to the other for this purpose from time to
            time or by e-mail. Any notice shall be treated as having been served
            on delivery if delivered by hand, two (2) working days after
            dispatch if sent by courier, on confirmation of transmission if sent
            by facsimile and four (4) working days after posting if sent by pre-
            paid registered mail and immediately if sent through e-mail.
          </p>
          <p className="text-lg font-medium text-black Regular">
            16.{" "}
            <u className="text-lg font-medium text-black Regular">Remedies.</u>{" "}
            <br />
            Money damages would be both incalculable and an insufficient remedy
            for any breach of this Agreement by Recipient and any such breach
            would cause Disclosing Party irreparable harm. In the event of any
            breach or threatened breach of this Agreement, Disclosing Party, in
            addition to any other remedies at law or in equity it may have, will
            be entitled to seek, without the requirement of posting a bond or
            other security, equitable relief, including injunctive relief and
            specific performance. If either party brings suit against the other
            to enforce any right hereunder or arising out of this Agreement, the
            prevailing party shall be entitled to recover reasonable attorneys’
            fees and costs in addition to all other relief to which such party
            may be entitled.
          </p>
          <p className="text-lg font-medium text-black Regular">
            17.{" "}
            <u className="text-lg font-medium text-black Regular">Waivers.</u>{" "}
            <br />
            No failure or delay in exercising any right, power or privilege
            hereunder shall operate as a waiver thereof, and no single or
            partial exercise thereof shall preclude any other or further
            exercise thereof or the exercise of any right, power or privilege
            hereunder.
          </p>
          <p className="text-lg font-medium text-black Regular">
            18.{" "}
            <u className="text-lg font-medium text-black Regular">
              Severability.
            </u>{" "}
            <br />
            The invalidity or unenforceability of any provision hereof in any
            jurisdiction shall not affect the validity, legality or
            enforceability of the remainder hereof in such jurisdiction or the
            validity, legality or enforceability hereof, including any such
            provision, in any other jurisdiction, it being intended that all
            rights and obligations of the parties hereunder shall be enforceable
            to the fullest extent permitted by law.
          </p>
          <p className="text-lg font-medium text-black Regular">
            19.{" "}
            <u className="text-lg font-medium text-black Regular">
              Relationship.
            </u>{" "}
            <br />
            Neither the execution nor performance of this Agreement will
            establish any joint venture or partnership or create the
            relationship of principal and agent between the Parties. Parties
            shall enter into this Agreement on principal-to-principal basis.
          </p>
          <p className="text-lg font-medium text-black Regular">
            20.{" "}
            <u className="text-lg font-medium text-black Regular">
              Governing Law; Dispute Resolution.
            </u>{" "}
            <br />
            This Agreement shall be subject to and governed by the laws of the
            State of Pennsylvania without reference to its conflicts of law
            rules. Any disputes arising under this Agreement shall be heard only
            before the state or federal courts located in Philadelphia, and the
            Parties shall not contest jurisdiction or venue thereof.
          </p>
          <p className="text-lg font-medium text-black Regular">
            21.{" "}
            <u className="text-lg font-medium text-black Regular">
              Assignment.
            </u>{" "}
            <br />
            This Agreement shall not be assigned by either Party without prior
            written consent of the other Party, provided however, Parties shall
            have the right to assign this Agreement in case of mergers,
            acquisition, transfer, sale, corporate restructuring etc. without
            prior intimation to the other Party.
          </p>
          <p className="text-lg font-medium text-black Regular">
            22.{" "}
            <u className="text-lg font-medium text-black Regular">Amendment.</u>{" "}
            <br />
            This Agreement may not be modified, amended, rescinded or canceled
            in whole or in part, except by written instrument signed by the
            Parties hereto which makes specific reference to this Agreement and
            which specifies that this Agreement is being modified, amended,
            rescinded or canceled.
          </p>
          <p className="text-lg font-medium text-black Regular">
            23.{" "}
            <u className="text-lg font-medium text-black Regular">
              Counterparts.
            </u>{" "}
            <br />
            This Agreement may be executed and delivered in two or more
            counterparts, each of which, when so executed and delivered, shall
            be an original, and such counterparts together shall constitute but
            one and the same Agreement and shall not be binding on any Party
            until all Parties have executed it.
          </p>
          <p className="text-lg font-medium text-black Regular">
            24.{" "}
            <u className="text-lg font-medium text-black Regular">
              Entire Agreement.
            </u>{" "}
            <br />
            This Agreement contains the entire agreement and understanding
            between the Parties hereto relating to the subject matter hereof and
            supersedes all other prior agreements and understandings, both
            written and oral, between the Parties with respect to the subject
            matter hereof.
          </p>
          <p className="text-lg font-medium text-black Regular">
            In Witness Whereof, this Agreement has been executed by duly
            authorised representatives of the Parties.
          </p>
          <div className="flex justify-between items-start">
            <p className="text-lg font-medium text-black Regular">
              Agreed and executed for and on behalf of <br />
              <strong className="Bold">Buying Simplified LLC</strong>
            </p>
            <p className="text-lg font-medium text-black Regular">
              Agreed and executed for and on behalf of
            </p>
          </div>
          <div className="flex items-center">
            <Checkbox
              className="primary-checkbox"
              onChange={() => setTermCondition(!term_condition)}
              checked={term_condition}
            />
            <p className="fs-14 font-bold">Accpet Term & Conditions </p>
          </div>
          <div className="flex justify-between items-start">
            <div className="flex items-center gap-x-3">
              <a
                href={docu}
                className="w-32 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:bg--primary hover:opacity-70 bg--primary white hover:white gap-x-3 flex items-center justify-center"
                download
                target="_blank"
              >
                Download
              </a>
              <div>
                <input
                  onChange={handleFileChange}
                  type="file"
                  accept=".doc,.docx"
                  ref={docuFileRef}
                  className="hidden"
                />
                <Buttonlink
                  className="w-32 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:bg--primary hover:opacity-70 bg--primary white hover:white gap-x-3 flex items-center justify-center"
                  onClick={() => docuFileRef.current?.click()}
                  text="Upload"
                ></Buttonlink>
              </div>
            </div>
            <Buttonlink
              className={`w-32 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md 
              ${
                disable
                  ? "bg--primary white hover:bg--primary hover:opacity-70 hover:white"
                  : "bg-grey-900 text-gray-400"
              } 
             gap-x-3 flex items-center justify-center cursor-pointer`}
              onClick={() => handleState()}
              disabled={!disable}
              text="Verify"
            />
          </div>
        </div>
      </Dialog>
      <Dialog
        fullWidth={false}
        maxWidth={"sm"}
        open={isVerify && docuFile === null}
        PaperProps={{
          style: {
            borderRadius: "20px",
          },
        }}
        onClose={() => {}}
        className="rounded--xl"
      >
        <div className="p-10 mt-5 relative flex flex-col gap-5">
          <div className="absolute right-3 -top-2 bg-white">
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setIsVerify(false)}
              aria-label="close"
            >
              <IoCloseCircleOutline size={32} className="primary" />
            </IconButton>
          </div>
          <div className="flex justify-between items-center mt-5">
            <p className="Medium text-lg">Write your signature</p>
            <Buttonlink
              className="px-5 py-2.5 fs-15 leading-5 Regular hover:opacity-80 rounded-lg shadow-md bg-gray-500 white hover:white gap-x-3 flex items-center justify-center"
              onClick={() => {
                sigCanvas.current.clear();
                setIsImage(null);
              }}
              text="Clear"
            ></Buttonlink>
          </div>
          <SignatureCanvas
            penColor="black"
            onEnd={handleSignatureEnd}
            canvasProps={{
              width: 500,
              height: 200,
              className: "sigCanvas bg-gray-300 mb-3 rounded-lg",
            }}
            ref={sigCanvas}
          />
          <div className="flex justify-between items-cente">
            <div>
              <input
                onChange={handleFile}
                type="file"
                accept="image/jpeg,image/png,image/gif"
                ref={fileRef}
                className="hidden"
              />
              <Buttonlink
                className="w-32 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:bg--primary hover:opacity-70 bg--primary white hover:white gap-x-3 flex items-center justify-center"
                onClick={() => fileRef.current?.click()}
                text="Upload"
              ></Buttonlink>
            </div>
            <Buttonlink
              className="w-32 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:bg--primary hover:opacity-70 bg--primary white hover:white gap-x-3 flex items-center justify-center"
              onClick={verifySignature}
              text="Verify"
            ></Buttonlink>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default RFQSignaturePopup;
