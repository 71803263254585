import client from "../../graphql/client";
import GET_ALERTS from "../../graphql/queries/getAlerts";
import store from "../index";
import { GET_AUTH_USER_ALERTS } from "./types";
import cookie from "js-cookie";
import { AUTH_USER_MRO, GRAPH_MRO_USER_AUTH_TOKEN } from "../../config/constants";

class AlertsAction {
  constructor(props) {
    this.getAuthUserAlerts = (props) => {
      return new Promise((resolve, reject) => {
        let variables = {};
        variables.me = true;
        variables.cursor = props.page;
        client
          .query({
            query: GET_ALERTS,
            variables,
            fetchPolicy: "no-cache"
          })
          .then((result) => {
            // console.log("Alerts_Action", result);
            resolve(true);
            store.dispatch({
              type: GET_AUTH_USER_ALERTS,
              payload: result.data.alerts,
            });
          })
          .catch((error) => {
            console.log('error', error);
            // Delay for 5 seconds
            setTimeout(() => {
              reject(false);
              console.log("Alerts error!!", error);
              cookie.remove(GRAPH_MRO_USER_AUTH_TOKEN);
              cookie.remove(AUTH_USER_MRO);
              localStorage.removeItem(AUTH_USER_MRO);
              // Remove the following line if you don't want the delay for the other actions
              window.location.href = "/login";
            }, 5000);

          });
      });
    };
  }
}

const MyAlertsAction = new AlertsAction();
export default MyAlertsAction;
