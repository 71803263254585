import { gql } from "@apollo/client";

export default gql`
  query {
    executive_roles {
      name
      id
      description
      permissions {
        id
        name
      }
    }
  }
`;
