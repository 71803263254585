import { Profile } from "..";
import { useLocation, useParams } from "react-router-dom";
import { DecryptUrl } from "../../helpers";
export default function ProfileScreen() {
  const params = useParams();
  const { state } = useLocation();
  const newUrl = DecryptUrl(params.id);
  return (
    <Profile
      value={state?.value ? state.value : ""}
      id={newUrl}
      params={params}
    />
  );
}
