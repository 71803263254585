import React from "react";
import { useNavigate } from "react-router-dom";

const DoBuying = () => {
  const navigate = useNavigate();
  document.title = "DoBuying | SourcingGenie";
  return (
    <>
      <div className="h-[calc(100vh-300px)]">
        <div className="h-full flex justify-center items-center gap-6 flex-col">
          <h2 className="text-black fs-30 Medium font-bold">
            Procrutment buying Concierge
          </h2>
          <p className="text-black fs-20 Medium">
            Simplify buying while maximizing value, Procrutment just got easier.
          </p>
          <button
            onClick={() => navigate("/learnmore")}
            className="bg--primary h-12 px-20 white rounded-full text-lg"
          >
            Learn More
          </button>
          <button
            onClick={() => navigate("/workspace")}
            className="bg--primary h-12 px-20 white rounded-full text-lg"
          >
            My workspace
          </button>
          <button
            onClick={() => navigate("/dobuying-about")}
            className="bg--primary h-12 px-20 white rounded-full text-lg"
          >
            About
          </button>
        </div>
      </div>
    </>
  );
};

export default DoBuying;
