import React, { Component } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { IoIosArrowDown } from "react-icons/io";
import { ChooseCategories } from "navigation";
import LoadMoreData from "../../../components/loadMoreData";
import { connect } from "react-redux";
import MySelectedRFQsAction from "store/actions/MySelectedRFQsAction";

class Categoryaside extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: "",
      // categories: "",
    };
  }
  componentDidMount() {
    this.props.selectedCategory &&
      this.setState({ expanded: this.props.selectedCategory.id });
  }

  handleChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      this.setState({ expanded: panel });
    } else {
      this.setState({ expanded: false });
    }
  };

  saveCategory = (category) => {
    let selectedCategories = Object.assign([], this.props.selectedCategories);
    let found = selectedCategories.find((data) => data.id === category.id);
    if (!found) {
      selectedCategories.push(category);
    } else {
      selectedCategories = selectedCategories.filter(
        (data) => data.id !== category.id
      );
    }

    MySelectedRFQsAction.selectCategory(selectedCategories);
  };

  render() {
    const { expanded } = this.state;
    const { categories } = this.props;
    return (
      <div>
        {categories &&
          categories.map((category) => (
            <>
              <Accordion
                expanded={expanded === category.id}
                onChange={this.handleChange(category.id)}
                className="shadow--none"
              >
                <AccordionSummary
                  expandIcon={<IoIosArrowDown />}
                  aria-controls={`${category.id}-content`}
                  id={`${category.id}-header`}
                >
                  <p>{category.name}</p>
                </AccordionSummary>

                {expanded === category.id && (
                  <div className="relative intro-x mx-5 mb-2 mt-7">
                    <input
                      type="checkbox"
                      onClick={(e) => this.saveCategory(category)}
                      className="z-0 absolute left-0 invisible"
                      id={category.id}
                      // checked={true}
                      checked={
                        this.props.selectedCategories &&
                        this.props.selectedCategories.length > 0 &&
                        this.props.selectedCategories.find(
                          (data) => parseInt(data.id) === category.id
                        )
                          ? true
                          : false
                      }
                    />
                    <label
                      htmlFor={category.id}
                      className="bg-gray-200 hover:opacity-70 rounded-lg px-4 py-1.5 cursor-pointer inline-block"
                    >
                      <p className="fs-13">{category.name}</p>
                    </label>
                  </div>
                )}

                <AccordionDetails>
                <div className="flex items-center flex-wrap gap-2">

                {category.items.map((subCategory) => (
                    <ChooseCategories
                      parentCatId={expanded}
                      subCategory={subCategory}
                    />
                ))}
                    </div>
                    

                  </AccordionDetails>
              </Accordion>
              <div className="divider" />
            </>
          ))}
          <div className="text-center my-3">
            <LoadMoreData
              title="Load More"
            />
          </div>
      </div>
    );
  }
}
const mapStateToProps = ({ Rfq, Auth, RFQs }) => {
  return {
    user: Auth,
    form: Rfq.form,
    categories: RFQs.allCategories,
    selectedCategories: RFQs.selectedCategories,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("store/redux/RFQRedux");
  return {
    ...ownProps,
    ...stateProps,
    addRfqForm: (data) => {
      dispatch(actions.addRfqForm(data));
    },
  };
};
export default connect(mapStateToProps, undefined, mergeProps)(Categoryaside);
