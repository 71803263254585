import { createStore, compose, applyMiddleware } from "redux";
import rootReducer from "./reducers/index";

import thunk from "redux-thunk";

const initialState = {};
const middleware = [thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
);
