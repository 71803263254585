import logo from "../../../../assets/images/logo.png";
import { Buttonlink } from "components";
import { IoIosArrowBack } from "react-icons/io";
import { seo } from "helpers";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import client from "../../../../graphql/client";
import REGISTER from "../../../../graphql/mutations/createUserMutation";
import { Link, useNavigate } from "react-router-dom";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import Checkbox from "@mui/material/Checkbox";

export default function Registration() {

  const [info, setinfo] = useState({
    email: "",
    username: "",
    firstname: "",
    lastname: "",
    phone: "",
    password: "",
    is_seller: ""
  });
  // const [username, setUsername] = useState("");
  // const [firstname, setFirstname] = useState("");
  // const [lastname, setLastname] = useState("");
  // const [phone, setPhone] = useState("");
  // const [password, setPassword] = useState("");
  const [LoggedIn, setLoggedIn] = useState(false);
  const [pswdType, setPswdType] = useState(true);
  const [disabled, setdisabled] = useState(false);
  const [error, seterror] = useState(false);
  const [termsAccepted, setTerms] = useState(true);
  let navigate = useNavigate();
  useEffect(() => {
    seo({
      title: "Signup | Sourcing League",
    });
  });

  let promise = (error, message) => {
    const resolveAfter3Sec = new Promise((resolve, reject) => {
      if (error) {
        setTimeout(reject, 2000);
      } else {
        setTimeout(resolve, 10);
      }
    });
    toast.promise(resolveAfter3Sec, {
      pending: "Please Wait...",
      success: message,
      error: message,
    });
  };

  let notify = (type, message) => {
    // Dismiss any existing toasts before showing a new one
    toast.dismiss();

    if (type === "error") {
      return toast.error(message);
    }
    if (type === "success") {
      return toast.success(message);
    }
    if (type === "warn") {
      return toast.warn(message);
    }
  };

  const handleInfo = (event) => {
    setinfo({
      ...info,
      [event.target.name]: event.target.value,
    });
    if (event.target.value) {
      seterror(false);
    }
  };
  const handleDigitInfo = (event) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;

    // Use a regular expression to check if the input contains only digits
    if (/^\d*$/.test(inputValue) || inputValue === "") {
      setinfo({
        ...info,
        [inputName]: inputValue,
      });
      seterror(false);
    } else {
      seterror(true); // Set an error state when non-digit characters are entered
    }
  };
  const handleCharactersInfo = (event) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;

    // Use a regular expression to check if the input contains only letters
    if (/^[A-Za-z]+$/.test(inputValue) || inputValue === "") {
      setinfo({
        ...info,
        [inputName]: inputValue,
      });
      seterror(false);
    } else {
      seterror(true); // Set an error state when invalid characters are entered
    }
  };

  useEffect(() => {
    if (
      info.username.length === 0 ||
      info.firstname.length === 0 ||
      info.lastname.length === 0 ||
      info.email.length === 0 ||
      info.phone.length === 0 ||
      info.password.length === 0 ||
      termsAccepted === false
    ) {
      setdisabled(true);
    } else {
      setdisabled(false);
    }
  });

  const handleRegisterUser = (event) => {
    event.preventDefault();

    if (info.username === "") {
      return notify("error", "Please Enter Username");
    }

    if (info.firstname === "") {
      return notify("error", "Please Enter Firstname");
    }

    if (info.lastname === "") {
      return notify("error", "Please Enter Lastname");
    }

    if (info.email === "") {
      return notify("error", "Please Enter Email Address");
    }

    if (info.email) {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      if (!pattern.test(info.email)) {
        seterror(true);
        return notify("error", "Please enter valid email address");
      }
    }

    if (info.phone === "") {
      return notify("error", "Please Enter Your Phone Number");
    }

    if (info.phone !== "undefined" && info.phone !== "") {
      var pattern = new RegExp(/^[0-9\b]+$/);

      if (!pattern.test(info.phone)) {
        return notify("error", "Please Use Only Numbers For Phone Number");
      } else if (info.phone.length < 10) {
        return notify("error", "Please Enter Valid Phone Number");
      }
    }
    if (info.password === "") {
      return notify("error", "Please Enter Your Password");
    }

    client
      .mutate({
        mutation: REGISTER,
        variables: {
          username: info.username,
          email: info.email,
          firstname: info.firstname,
          lastname: info.lastname,
          phone_number: info.phone,
          password: info.password,
          is_seller: info.is_seller
        },
      })
      .then((res) => {
        if (res.data.createUser.id) {
          promise(
            false,
            `Congratulations your account has been created ${res.data.createUser.firstname} ${res.data.createUser.lastname}`
          );
          setTimeout((window.location.href = "/login"), 20000);
        } else {
          promise(true, "Oops! something went wrong please try again later");
        }
      })
      .catch((errors) => {
        displayError(errors).map((error) => notify("error", error));
      });
  };

  const displayError = (error) => {
    let validationErrors = [];
    try {
      if (error) {
        let { graphQLErrors } = error;
        if (graphQLErrors[0].extensions.category === "validation") {
          validationErrors = graphQLErrors[0].extensions.validation;
        }
      }
      let errorMessage = [];
      for (var key in validationErrors) {
        var value = validationErrors[key];
        errorMessage.push(value[0]);
      }
      return errorMessage;
    } catch (e) {
      console.log("displayError", e)
    }
  };

  const showEyePswd = () => {
    setPswdType(!pswdType);
  };

  return (
    <div className="min-h-full container px-4 sm:px-6 lg:px-8">
      <ToastContainer position="top-right" />
      <div className="flex items-center flex-grow min-h-[100vh]">
        <div className="md:grid md:grid-cols-2 flex-grow ">
          <div className="intro-y mb-10 md:mb-0 flex flex-col justify-center items-center relative">
            {/*<div className="absolute left-0 top-0 pt-10 md:pt-0">
              <Buttonlink
                text="Back"
                prefix={<IoIosArrowBack size={16} />}
                onClick={() => props.navigate(-1)}
                className="bg-gray-300 hover:opacity-80 fs-13 flex items-center justify-center gap-x-1 h-9 rounded-full darkGray w-24 border-0 shadow-none"
              />
            </div>*/}
             <img
              src={logo}
              alt="logo"
              onClick={()=>navigate("/")}
              className="w-72 object-contain cursor-pointer mx-auto md:mt-0 mt-[100px]"
            />
            <h2 className="mt-5 md:mt-14 text-center lg:text-6xl text-3xl Medium text-[#5C0632] font-bold italic">
              Registration
            </h2>
          </div>
          <form
            className="bg-white p-10 rounded-xl intro-y"
            onSubmit={handleRegisterUser}
            autoComplete="off"
          >
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="space-y-5">
              <div className="intro-x">
                <label htmlFor="email-address" className="Regular">
                  User Name
                </label>
                <input
                  id="username"
                  name="username"
                  type="text"
                  value={info.username}
                  maxLength={26}
                  onChange={handleCharactersInfo}
                  autoComplete="User Name"
                  required
                  className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-200 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                />
              </div>
              <div className="grid sm:grid-cols-2 gap-5">
                <div>
                  <label htmlFor="email-address" className="Regular">
                    First Name
                  </label>
                  <input
                    id="first-name"
                    name="firstname"
                    type="text"
                    maxLength={26}
                    value={info.firstname}
                    onChange={handleCharactersInfo}
                    autoComplete="First Name"
                    required
                    className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-200 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                  />
                </div>
                <div className="intro-x">
                  <label htmlFor="email-address" className="Regular">
                    Last Name
                  </label>
                  <input
                    id="last-name"
                    name="lastname"
                    maxLength={26}
                    type="text"
                    value={info.lastname}
                    onChange={handleCharactersInfo}
                    autoComplete="Last Name"
                    required
                    className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-200 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                  />
                </div>
              </div>
              <div className="intro-x">
                <label htmlFor="email-address" className="Regular">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  value={info.email}
                  onChange={handleInfo}
                  required
                  maxLength={64}
                  className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-200 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                />
              </div>
              <div className="intro-x">
                <label htmlFor="phone-number" className="Regular">
                  Phone Number
                </label>
                <div className="relative flex items-center">
                  <input
                    id="phone-number"
                    name="phone"
                    type="text"
                    autoComplete="phone-number"
                    value={info.phone}
                    onChange={handleDigitInfo}
                    required
                    maxLength={15}
                    className="appearance-none rounded-md relative block w-full pr-20 px-3 py-3 border border-gray-200 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                  />
                  {/*<p className="primary absolute right-2 cursor-pointer">*/}
                  {/*  Change*/}
                  {/*</p>*/}
                </div>
              </div>
              <div className="intro-x">
                <label htmlFor="password" className="Regular">
                  Password
                </label>
                <div className="flex items-center relative">
                  <input
                    id="password"
                    name="password"
                    type={pswdType ? "password" : "text"}
                    value={info.password}
                    onChange={handleInfo}
                    required
                    maxLength={16}
                    className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-200 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                  />
                  <div
                    onClick={showEyePswd}
                    className="cursor-pointer h-12 w-12 flex items-center justify-center absolute right-0"
                  >
                    {pswdType ? <BsEye size={20} /> : <BsEyeSlash size={20} />}
                  </div>
                </div>
                <div className="flex items-center mt-1">
                  <Checkbox
                    className="primary-checkbox"
                    onChange={() => setTerms(!termsAccepted)}
                    checked={termsAccepted}
                  />
                  <p className="fs-12">
                    Confirm that you agree to
                    <Link to="/term-of-service" className="primary fs-12 px-1">
                      Sourcing Genie's Term of Service
                    </Link>
                    and
                    <Link to="/privacy-policy" className="primary fs-12 px-1">
                      Privacy Policies.
                    </Link>
                  </p>
                </div>
              </div>
              {/* <Buttonlink
                to="/term-and-conditions"
                text="Terms and conditions"
                title="Terms and conditions"
                rel="nofollow"
                className="relative intro-x hover:opacity-80 primary inline-block"
              /> */}

              <div className="flex">
                <div className="flex items-center mr-4">
                  <input id="buyer" type="radio" value="0" name="is_seller" tabIndex={1} defaultChecked={true}
                    onChange={handleInfo}
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300" />
                  <label htmlFor="inline-2-radio"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">For Buyer Role</label>
                </div>
                <div className="flex items-center mr-4">
                  <input id="seller" type="radio" value="1" name="is_seller" tabIndex={2} onChange={handleInfo}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300" />
                  <label htmlFor="seller" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">For Seller Role</label>
                </div>
                <div className="flex items-center">
                  <input disabled={true} id="both" type="radio" value="" name="is_seller" tabIndex={3}
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300" />
                  <label htmlFor="both"
                    className="ml-2 text-sm font-medium text-gray-400 dark:text-gray-500">For Both (Seller & Buyer)</label>
                </div>
              </div>
              <Buttonlink
                onClick={handleRegisterUser}
                text="Sign Up"
                title="sign up"
                rel="nofollow"
                disabled={disabled || error}
                className={`relative w-full hover:white text-center hover:opacity-80 flex items-center justify-center py-2.5 px-4 border border-transparent rounded-lg ${error || disabled
                  ? "text-white bg--gray hover:bg--gray"
                  : "text-white bg--primary hover:bg--primary"
                  } focus:outline-none`}
              />

              <div className="h-1" />
              <div className="flex items-center gap-x-4 intro-x">
                <div className="divider w-full" />
                <p className="whitespace-nowrap Light fs-13 darkGray">
                  Have an account
                </p>
                <div className="divider w-full" />
              </div>
              <Buttonlink
                to="/Login"
                text="Sign In"
                title="Create Account"
                rel="nofollow"
                className="relative intro-x w-full hover:white hover:opacity-80 flex justify-center py-2.5 px-4 border border-transparent rounded-lg primary bg--lightPrimary  hover:bg--primary focus:outline-none "
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
