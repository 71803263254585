import React, { useState } from "react";
import OrderModal from "./OrderModal";

const MyOrders = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal(id) {
    setSelectedId(id);
    setIsOpen(true);
  }

  const orderStatus = [
    {
      id: 0,
      label: "Request received but not ordered yet",
    },
    {
      id: 1,
      label: "Request received and ordered",
    },
    {
      id: 2,
      label: "Request ordered and received by supplier",
    },
    {
      id: 3,
      label: "Request Fulfilled by supplier",
    },
  ];

  return (
    <div className="p-5 bg-white ml-3">
      <h6 className="Medium fs-18 font-semibold pb-6">My Orders</h6>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-10">
        {orderStatus?.map((status) => (
          <button
            key={status.id}
            onClick={() => openModal(status.id)}
            className="inline-flex py-3 rounded-md gap-4 cursor-pointer flex-col items-center justify-center bg--lightPrimary"
          >
            <h6 className="Medium fs-20 font-medium">
              {data[status.id].length}
            </h6>
            <p className="Regular fs-14 text-center">{status.label}</p>
          </button>
        ))}
      </div>

      <OrderModal
        isOpen={isOpen}
        closeModal={closeModal}
        data={data[selectedId]}
      />
    </div>
  );
};

export default MyOrders;
