import { Avatarimage } from "components";
import React, { Component } from "react";
import { FaBookmark } from "react-icons/fa";
import { Link } from "react-router-dom";
import messages from "../../../assets/images/messages.png";

export default class Rfqquestion extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="space-y-5">
        <div className="box overflow-hidden mx-1 intro-y">
          <div className="p-5 pb-0 space-y-5">
            <div className="flex items-center gap-x-3 intro-y">
              <Avatarimage />
              <div>
                <Link to="/profile" className="SemiBold">
                  User Name
                </Link>
                <p className="Regular gray fs-12">@user</p>
              </div>
            </div>
            <div>
              <Link
                to="/rfq_details"
                className="SemiBold fs-15 inline-block intro-y"
              >
                Nulla quis lorem ut libero malesuada feugiat. Curabitur non
                nulla sit amet nisl tempus convallis quis ac lectus.
              </Link>
            </div>
            <div className="border-t py-3 intro-y flex items-center justify-between">
              <div className="flex items-center gap-x-2">
                <img
                  alt="messages"
                  src={messages}
                  className="h-7 w-7 cursor-pointer"
                />
                <p className="gray fs-13 Regular">1</p>
              </div>

              <div className="h-9 w-9 border cursor-pointer z-10 rounded-full flex items-center justify-center">
                <FaBookmark className="gray" />
              </div>
            </div>
          </div>
        </div>
        <div className="box overflow-hidden mx-1 intro-y">
          <div className="p-5 pb-0 space-y-5">
            <div className="flex items-center gap-x-3 intro-y">
              <Avatarimage />
              <div>
                <Link to="/profile" className="SemiBold">
                  User Name
                </Link>
                <p className="Regular gray fs-12">@user</p>
              </div>
            </div>
            <div>
              <Link
                to="/rfq_details"
                className="SemiBold fs-15 inline-block intro-y"
              >
                Nulla quis lorem ut libero malesuada feugiat. Curabitur non
                nulla sit amet nisl tempus convallis quis ac lectus.
              </Link>
            </div>
            <div className="border-t py-3 intro-y flex items-center justify-between">
              <div className="flex items-center gap-x-2">
                <img
                  alt="messages"
                  src={messages}
                  className="h-7 w-7 cursor-pointer"
                />
                <p className="gray fs-13 Regular">1</p>
              </div>

              <div className="h-9 w-9 border cursor-pointer z-10 rounded-full flex items-center justify-center">
                <FaBookmark className="gray" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
