import { Tab } from "@headlessui/react";
import { Loader, Noconnection, Nocontent, RFQcard } from "components";
import React, { Fragment, useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { IoIosArrowDown } from "react-icons/io";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Menu } from "@headlessui/react";
import GET_OFFERS from "../../../graphql/queries/getOffers";
import { Query } from "@apollo/client/react/components";
import { connect } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import MySelectedRFQsAction from "../../../store/actions/MySelectedRFQsAction";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Sellertabsrafqs(props, navigation) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Get the value of a specific parameter
  const paramValue = searchParams.get("searched");

  const navigate = useNavigate();
  const params = useParams();
  let insertUrl = "";
  let queryValue = "";
  let { current_card } = params;
  switch (current_card) {
    case "general":
      current_card = 0;
      break;
    case "invitations":
      current_card = 1;
      break;
    case "latest-action":
      current_card = 2;
      break;
    case "my-rfqs":
      current_card = 3;
      break;
    case "appoved":
      current_card = 4;
      break;
    default:
      current_card = 0;
  }
  const [selectedIndex, setSelectedIndex] = useState(
    props.cardId ? props.cardId : current_card
  );
  const [subSelectedIndex, setSubSelectedIndex] = useState(
    paramValue ? paramValue : ""
  );

  useEffect(() => {
    //console.log(props.cardId);
    //setSelectedIndex(props.cardId);
    MySelectedRFQsAction.searchedRfqs("");
    if (selectedIndex === 0) {
      insertUrl = "general";
    } else if (selectedIndex === 1) {
      insertUrl = "invitations";
    } else if (selectedIndex === 2) {
      insertUrl = "latest-action";
    } else if (selectedIndex === 3) {
      insertUrl = "my-rfqs";
      queryValue = subSelectedIndex;
    } else if (selectedIndex === 4) {
      insertUrl = "approved";
    }

    if (insertUrl === "my-rfqs" && queryValue !== "") {
      navigate({
        pathname: `/rfq/${insertUrl}`,
        search: `?searched=${queryValue}`,
      });
      //console.log("PARAMAA", params);
    } else {
      navigate(`/rfq/${insertUrl}`);
    }
  }, [selectedIndex, props.cardId, subSelectedIndex]);
  const orderBy = [{ field: "updated_at", order: "DESC" }]
  let variables = { orderBy };
  props.rfqSelectedFilter(subSelectedIndex);
  // console.log("Filter Id", selectedIndex);

  if (selectedIndex === 0) {
    variables.general = true;
  }
  if (selectedIndex === 1) {
    variables.invitation = true;
  }
  if (selectedIndex === 2) {
    variables.latest_action = true;
  }
  if (selectedIndex === 3) {
    if (props.selectedFilter === "submitted") {
      variables.submited = true;
    } else if (props.selectedFilter === "awarded") {
      variables.awarded = true;
    } else if (props.selectedFilter === "lost") {
      variables.lost = true;
    } else if (props.selectedFilter === "clear") {
      variables.clear = true;
    } else {
      variables.submited = true;
    }
  }
  if (selectedIndex === 4) {
    variables.approved = true;
  }

  if (props.searchText !== "" || props.searchText !== null) {
    // console.log("1 props.searchText", props.searchText);
    variables.search = props.searchText;
  }
  const subSelection = (e) => {
    setSubSelectedIndex(e.target.value);
    props.rfqSelectedFilter(e.target.value);
  };

  // console.log("serler",variables)
  return (
    <>
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <Tab.List className="lg:flex relative lg:p-2 md:p-3 p-4 lg:h-20 lg:space-y-0 space-y-3 lg:space-x-3 bg-gray-300 rounded-xl">
          <Tab
            className={({ selected }) =>
              classNames(
                "w-full lg:py-2.5 py-4 fs-15 leading-5 Regular rounded-lg shadow-md hover:bg--primary hover:white",
                selected ? "bg--primary white" : "bg-white"
              )
            }
          >
            General
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                "w-full lg:py-2.5 py-4 fs-15 leading-5 Regular rounded-lg shadow-md hover:bg--primary hover:white",
                selected ? "bg--primary white" : "bg-white"
              )
            }
          >
            Invitations
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                "w-full lg:py-2.5 py-4 fs-15 leading-5 Regular rounded-lg shadow-md hover:bg--primary hover:white",
                selected ? "bg--primary white" : "bg-white"
              )
            }
          >
            Latest action
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                "w-full relative py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:bg--primary hover:white gap-x-3 flex items-center justify-center",
                selected ? "bg--primary white" : "bg-white"
              )
            }
          >
            My RFQs
            <Menu as="div" className="inline-block text-left">
              <div>
                <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-black rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                  <IoIosArrowDown size={18} />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute  z-xlfull left-0 right-0 w-screen xl:max-w-md max-w-full mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg focus:outline-none">
                  <div className="rounded-lg shadow-lg bg-white relative p-6">
                    <h4 className="text-left text-2xl">Filters</h4>
                    <div className="divider w-full my-3" />
                    <div>
                      <Menu.Item>
                        <FormControl className="w-full">
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={subSelectedIndex}
                            name="radio-buttons-group"
                            className="primary-all w-full -mx-3"
                          >
                            <FormControlLabel
                              onChange={subSelection}
                              value="submitted"
                              control={<Radio />}
                              label={
                                <div>
                                  <p className=" black text-lg">
                                    Submitted RFQ Quote
                                  </p>
                                  <p className="Light fs-12 darkGray">
                                    Select Submitted RFQ Quote option
                                  </p>
                                </div>
                              }
                              labelPlacement="start"
                              className="flex items-center justify-between ml--0 hover:bg-gray-100 rounded-xl py-2 px-3"
                              checked={
                                subSelectedIndex === "submitted" ||
                                props.selectedFilter === "submitted"
                              }
                            />
                            <FormControlLabel
                              onChange={subSelection}
                              value="awarded"
                              control={<Radio />}
                              label={
                                <div>
                                  <p className=" black text-lg">Awarded</p>
                                  <p className="Light fs-12 darkGray">
                                    Select Awarded option
                                  </p>
                                </div>
                              }
                              labelPlacement="start"
                              className="flex items-center justify-between ml--0 hover:bg-gray-100 rounded-xl py-2 px-3"
                              checked={
                                subSelectedIndex === "awarded" ||
                                props.selectedFilter === "awarded"
                              }
                            />
                            <FormControlLabel
                              onChange={subSelection}
                              value="lost"
                              control={<Radio />}
                              label={
                                <div>
                                  <p className=" black text-lg">RFQ Lost</p>
                                  <p className="Light fs-12 darkGray">
                                    Select RFQ Lost option
                                  </p>
                                </div>
                              }
                              labelPlacement="start"
                              className="flex items-center justify-between ml--0 hover:bg-gray-100 rounded-xl py-2 px-3"
                              checked={
                                subSelectedIndex === "lost" ||
                                props.selectedFilter === "lost"
                              }
                            />
                            <FormControlLabel
                              onChange={subSelection}
                              value="clear"
                              control={<Radio />}
                              label={
                                <div>
                                  <p className=" black text-lg">Clear</p>
                                  <p className="Light fs-12 darkGray">
                                    Select Clear option
                                  </p>
                                </div>
                              }
                              labelPlacement="start"
                              className="flex items-center justify-between ml--0 hover:bg-gray-100 rounded-xl py-2 px-3"
                              checked={
                                subSelectedIndex === "clear" ||
                                props.selectedFilter === "clear"
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </Menu.Item>
                    </div>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                "w-full lg:py-2.5 py-4 fs-15 leading-5 Regular rounded-lg shadow-md hover:bg--primary hover:white",
                selected ? "bg--primary white" : "bg-white"
              )
            }
          >
            Approved
          </Tab>
        </Tab.List>
        <Tab.Panels className="mt-2">
          <Tab.Panel className="bg-transparent rounded-xl mt-20">
            <All_RFQ_Query
              variables={variables}
              selectedIndex={selectedIndex}
            />
          </Tab.Panel>
          <Tab.Panel className="bg-transparent rounded-xl mt-20">
            <All_RFQ_Query
              variables={variables}
              selectedIndex={selectedIndex}
            />
          </Tab.Panel>
          <Tab.Panel className="bg-transparent rounded-xl mt-20">
            <All_RFQ_Query
              variables={variables}
              selectedIndex={selectedIndex}
            />
          </Tab.Panel>
          <Tab.Panel className="bg-transparent rounded-xl mt-20">
            <All_RFQ_Query
              variables={variables}
              selectedIndex={selectedIndex}
            />
          </Tab.Panel>
          <Tab.Panel className="bg-transparent rounded-xl mt-20">
            <All_RFQ_Query
              variables={variables}
              selectedIndex={selectedIndex}
            />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </>
  );
}

function All_RFQ_Query(props) {
  const [loadMoreRFQ, SetLoadMoreRFQ] = useState(false);
  let variables = props.variables;
  let selectedIndex = props.selectedIndex;
  // console.log("variables",variables)
  // console.log("props.selectedIndex",props.selectedIndex)
  return (
    <div>
      <Query
        query={GET_OFFERS}
        variables={variables}
        fetchPolicy={"cache-and-network"}
      >
        {({ loading, data, error, fetchMore, refetch }) => {
          if (loading) {
            return <Loader />;
          }
          if (error) {
            return <Noconnection />;
          }

          if (data?.offers?.data?.length <= 0) {
            return <Nocontent text="It's quite empty out here, let's crowd it with RFQs!" />;
          }

          return (
            <div className="grid xl:grid-cols-3 lg:grid-cols-3 sm:grid-cols-2 gap-4">
              <RFQcard
                publicRfqs={data}
                home={false}
                selectedIndex={selectedIndex}
                imageHeight="h-32 w-32"
                cardSpacing="p-5"
                imageRounded="rounded-xl"
                paginatorInfo={data.offers?.paginatorInfo}
                cardBorder="border"
                onLoadMore={() => {
                  fetchMore({
                    variables: {
                      cursor: data.offers.paginatorInfo.currentPage + 1,
                    },
                    updateQuery: (previousResult, { fetchMoreResult }) => {
                      const newQuestions = fetchMoreResult.offers.data;
                      const pageInfo = fetchMoreResult.offers.paginatorInfo;
                      if (data.offers.paginatorInfo.total !== pageInfo.total) {
                        refetch();
                      }

                      if (pageInfo.hasMorePages) {
                        SetLoadMoreRFQ(true);
                      } else {
                        SetLoadMoreRFQ(false);
                      }
                      return newQuestions.length
                        ? {
                          offers: {
                            __typename: previousResult.offers.__typename,
                            data: [
                              ...previousResult.offers.data,
                              ...newQuestions,
                            ],
                            paginatorInfo: pageInfo,
                          },
                        }
                        : previousResult;
                    },
                  });
                }}
              />
            </div>
          );
        }}
      </Query>
      {/*{loadMoreRFQ ? (
        <div className={"mt-4"}>
          <Loader />
        </div>
      ) : null}*/}
    </div>
  );
}

const mapStateToProps = ({ Auth, Rfq, RFQs }) => {
  return {
    authUser: Auth.authUserInformation,
    selectedFilter: Rfq.selectedFilter,
    searchText: RFQs.searchedRfqs,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("store/redux/RFQRedux");
  return {
    ...ownProps,
    ...stateProps,
    rfqSelectedFilter: (data) => {
      dispatch(actions.rfqSelectedFilter(data));
    },
  };
};
export default connect(mapStateToProps, undefined, mergeProps)(Sellertabsrafqs);
