import { gql } from "@apollo/client";

export default gql`
mutation readAlert($id: Int!) {
    readAlert(id: $id) {
           id
      read_at
    }
  }
`;
