import React, { Component } from "react";

class MyTeams extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }


    render() {
        return (
            // <TeamsDialog />
            <p>qwerty</p>
        );
    }
}

export default MyTeams;
