import React, { Component } from "react";
import { connect } from "react-redux";
import MySelectedRFQsAction from "store/actions/MySelectedRFQsAction";

class ChooseCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      selectedCategories: [],
      expanded: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleOnScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
  }

  handleOnScroll = () => {
    var scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    var scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    var clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    var scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
    if (scrolledToBottom) {
      this.props.onLoadMore();
    }
  };

  toggleExpand = category => {
    this.setState({ expanded: !this.state.expanded });
    let { selectedCategories } = this.state;
    let found = selectedCategories.find(data => data.id === category.id);
    if (!found) {
      selectedCategories.push(category);
    } else {
      selectedCategories = selectedCategories.filter(
        data => data.id !== category.id
      );
    }

    this.setState({ selectedCategories });
    MySelectedRFQsAction.selectCategory(selectedCategories);
  };

  saveCategory = category => {
    let { selectedCategories } = this.state;
    let found = selectedCategories.find(data => data.id === category.id);
    if (!found) {
      selectedCategories.push(category);
    } else {
      selectedCategories = selectedCategories.filter(
        data => data.id !== category.id
      );
    }

    this.setState({ selectedCategories });
    MySelectedRFQsAction.selectCategory(selectedCategories);
  };

  render() {
    const { subCategory } = this.props;

    return (
      <>
        <div className="flex items-center flex-wrap gap-2 selected-tags">
          <div className="relative intro-x">
            <input
              type="checkbox"
              onClick={e => this.toggleExpand(subCategory)}
              className="z-0 absolute left-0 invisible"
              id={subCategory.id}
              checked={
                this.props.selectedCategories.find(
                  data => data.id === subCategory.id
                )
                  ? true
                  : false
              }
            />
            <label
              htmlFor={subCategory.id}
              className="bg--lightPrimary hover:opacity-70 rounded-lg px-4 py-1.5 cursor-pointer inline-block"
            >
              <p className="fs-13">{subCategory.name}</p>
            </label>
          </div>

          {this.state.expanded &&
            subCategory.items &&
            subCategory.items.map((item, index) => (
              <>
                <div key={index} className="relative intro-x">
                  <input
                    type="checkbox"
                    onClick={e => this.saveCategory(item)}
                    className="z-0 absolute left-0 invisible"
                    id={item.id}
                    checked={
                      this.props.selectedCategories.find(
                        data => data.id === item.id
                      )
                        ? true
                        : false
                    }
                  />
                  <label
                    onClick={() => {}}
                    htmlFor={item.id}
                    className="bg-gray-200 hover:opacity-70 rounded-lg px-4 py-1.5 cursor-pointer inline-block"
                  >
                    <p className="fs-13">{item.name}</p>
                  </label>
                </div>
              </>
            ))}
        </div>
      </>
    );
  }
}
const mapStateToProps = ({ Rfq, Auth, RFQs }) => {
  return {
    user: Auth,
    form: Rfq.form,
    selectedCategories: RFQs.selectedCategories,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("store/redux/RFQRedux");
  return {
    ...ownProps,
    ...stateProps,
    addRfqForm: data => {
      dispatch(actions.addRfqForm(data));
    },
  };
};
export default connect(
  mapStateToProps,
  undefined,
  mergeProps
)(ChooseCategories);
