import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/animated.css";
import "./assets/css/semantic.css";
import "./index.css";
import "./assets/css/style.css";
import "./config/fonts/fontfamily.css";
import "./config/color/color.css";
import CustomRoutes from "./Route";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import store from "./store";
import client from "./graphql/client";
import StripeProvider from "components/StripeProvider";

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider dateAdapter={AdapterDateFns} store={store}>
      <StripeProvider>
        <CustomRoutes />
      </StripeProvider>
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
