// NotificationScreen.js

import React, { useEffect, useState } from "react";
import { Notification } from "components";
import { connect } from "react-redux";

export function NotificationScreen(props) {
  const [notificationProp, setNotificationProp] = useState(0);
  document.title = "Notifications | SourcingGenie";
  useEffect(() => {
    if (props.socket.connected) {
      props?.socket?.on("receive_notification", (data) => {
        // console.log("receive_notification", data);
        setNotificationProp(notificationProp + 1);
      });
    }
  }, [props?.socket]);

  return (
    <div>
      <div className="container mt-20 intro-y">
        <div>
          <div className="grid grid-cols-5 mt-5">
            <div className="xl:col-start-2 xl:col-span-3 col-span-5 intro-y">
              <div className="box p-5">
                <Notification showAllAlerts={true} showAlerts={false} size="text-3xl" notif={notificationProp} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ Auth }) => {
  return {
    socket: Auth.socket,
  };
}

export default connect(mapStateToProps, null)(NotificationScreen);
