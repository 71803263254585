import React, { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { IoIosArrowDown } from "react-icons/io";
import { BsBarChartLine, BsListTask } from "react-icons/bs";
import { IoDocumentTextOutline } from "react-icons/io5";
import { BiConversation } from "react-icons/bi";
import { SiKnowledgebase } from "react-icons/si";
import { NavLink } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import { RiInfraredThermometerLine } from "react-icons/ri";
import { Getquotation, Loader, Search } from "components";
import { connect } from "react-redux";
import { IoHomeOutline } from "react-icons/io5";
import { GiBuyCard } from "react-icons/gi";
import './styles.css'
import AlertsAction from "../../store/actions/MyAlertsAction";

const Subheader = (props) => {
  const [ranOnce, setRanOnce] = useState(true);
  useEffect(() => {
    if (!ranOnce) {
      if (props.socket?.connected) {
        console.log("K");
        props.socket?.on("receive_notification", (data) => {
          AlertsAction.getAuthUserAlerts({ page: 1 });
        });
      }

    } else {
      // After 5 seconds, run the code again.
      const timer = setTimeout(() => {
        console.log("Running again after 5 seconds");
        setRanOnce(false);
        // Put the code you want to run after 5 seconds here.
      }, 15000); // 5000 milliseconds = 5 seconds

      // Clear the timer when the component unmounts or when 'props.socket' changes.
      return () => clearTimeout(timer);
    }
  }, [props.socket, ranOnce]);
  let userIsActive = false;
  let inactivityTimeout;
  let isInactive = false; // Track inactivity state

  // Add a function to notify inactivity
  function notifyInactivity() {
    if (props.socket?.connected) {
      props.socket?.emit("inactive", { id: props.authUser.id, active: false });
    }

    // Perform actions when the user becomes inactive (e.g., log them out).
  }

  // Add a function to set the user as active
  function setUserActive() {
    if (!userIsActive) {
      userIsActive = true;
      if (props.socket?.connected) {
        props.socket?.emit("active", { id: props.authUser.id, active: true });
      }
    }

    // Clear any existing inactivity timeout
    clearTimeout(inactivityTimeout);

    // Set a new inactivity timeout
    inactivityTimeout = setTimeout(() => {
      userIsActive = false;
      isInactive = true; // Mark as inactive
      notifyInactivity();
    }, 10000); // 10 seconds of inactivity (adjust to your needs)
  }

  // Set initial user activity when the page loads
  useEffect(() => {
    setUserActive();
  }, [])

  window.addEventListener('mousemove', setUserActive);
  window.addEventListener('keydown', setUserActive);
  window.addEventListener('focus', () => {
    if (isInactive) {
      setUserActive();
      isInactive = false; // Mark as active
    }
  });
  return (
    <div className="xl:bg-white bg-gray-100 w-full border-b">
      <div className="container-fluid px-3 mx-auto" style={{ maxWidth: "1500px" }}>
        {/* Search bar only visible for home screen */}
        <NavLink to="" className={({ isActive }) => (isActive ? "" : "hidden plus")}></NavLink>
        <div className="xxl:hidden flex pt-3 pb-3">
          <Search />
        </div>
        {/* Search bar only visible for my rfq screen */}
        <NavLink to="/rfq/0" className={({ isActive }) => (isActive ? "" : "hidden plus")}></NavLink>
        <div className="xxl:hidden flex pt-3 pb-3">
          <Search />
        </div>
        <div className="flex justify-center items-center xxl:space-x-10">
          <div className="lg:w-0 lg:flex-1 xxl:gap-x-10 xl:gap-x-7 hidden xxl:flex py-1">
            <NavLink to="/" exact className="items-center flex rounded-full black Medium fs-13 gap-x-2">
              <IoHomeOutline />
              Home
            </NavLink>

            <div className="w-px h-8 bg-slate-100 shrink-0" />
            <NavLink
              to="/rfq/0"
              state={{ cardId: props.authUser.is_seller ? 2 : 0 }}
              className="cursor-pointer intro-y black gap-x-2 text-left fs-13 hover:primary-all-text hover:primary--text flex xl:items-center xl:justify-start relative whitespace-nowrap"
            >
              <IoDocumentTextOutline size={18} className="shrink-0" />
              {/* {props.authUser.is_seller ? "RFQ" : "My RFQ"} */}
              My RFQ
            </NavLink>

            <div className="w-px h-8 bg-slate-100 shrink-0" />

            <NavLink
              to="/rfq-ledger"
              className="cursor-pointer intro-y black gap-x-2 text-left fs-13 hover:primary-all-text hover:primary--text flex xl:items-center xl:justify-start relative whitespace-nowrap"
            >
              <SiKnowledgebase size={16} className="shrink-0" />
              My RFQ Ledger
            </NavLink>

            <div className="w-px h-8 bg-slate-100 shrink-0" />
            <NavLink
              to="/analytics"
              state={{ value: "analytics", id: props.authUser.id }}
              className="cursor-pointer intro-y black gap-x-2 text-left fs-13 hover:primary-all-text hover:primary--text flex xl:items-center xl:justify-start relative"
            >
              <BsBarChartLine size={18} />
              Analytics
            </NavLink>

            <div className="w-px h-8 bg-slate-100 shrink-0" />
            <NavLink
              to="/categories"
              className="cursor-pointer intro-y black gap-x-2 text-left fs-13 hover:primary-all-text hover:primary--text flex xl:items-center xl:justify-start relative whitespace-nowrap"
            >
              <BsListTask size={20} />
              Categories
            </NavLink>
            {/* {props.authUser.is_seller ? null : (
              <>
                <div className="w-px h-8 bg-slate-100 shrink-0" />
                <NavLink
                  to="/recommendation"
                  state={{ selectedIndex: 0 }}
                  className="cursor-pointer intro-y black gap-x-2 text-left fs-13 hover:primary-all-text hover:primary--text flex xl:items-center xl:justify-start relative"
                >
                  <BiConversation size={18} />
                  Recommendation
                </NavLink>
              </>
            )} */}

            <div className="w-px h-8 bg-slate-100 shrink-0" />
            <NavLink
              to="/leaders"
              className="cursor-pointer intro-y black gap-x-2 text-left fs-13 hover:primary-all-text hover:primary--text flex xl:items-center xl:justify-start relative"
            >
              <RiInfraredThermometerLine size={18} />
              Leaders
            </NavLink>
            <div className="w-px h-8 bg-slate-100 shrink-0" />
            {!props.authUser.is_seller && (
              <NavLink
                to="/workspace"
                className="whitespace-nowrap cursor-pointer intro-y black gap-x-2 text-left fs-13 hover:primary-all-text hover:primary--text flex xl:items-center xl:justify-start relative"
              >
                <GiBuyCard size={18} />
                Do Buying
              </NavLink>
            )}
          </div>
          {props.authUser && props.authUser.is_seller ? null : (
            <div className="xxl:border-t-0 border-t xxl:py-1 pt-3 pb-3 flex xxl:justify-end justify-center flex-grow create_rfq">
              <Popover.Group className="flex items-center justify-between intro-x z-xlfull w-full md:w-auto">
                <Popover className="relative h-full flex w-full md:w-auto">
                  <Popover.Button className="bg--primary py-1 rounded-lg w-full md:w-56 xl:h-auto h-12 xl:px-1 px-5 ">
                    <p className="white gap-x-2 text-left fs-13 hover:primary-all-text hover:primary--text flex items-center justify-start relative">
                      <div className="w-8 h-7 flex items-center justify-center bg-white rounded-md">
                        <FiPlus size={20} className="primary" />
                      </div>
                      Create an RFQ
                      <div className="flex-grow flex justify-end">
                        <IoIosArrowDown size={18} className="white" />
                      </div>
                    </p>
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-300"
                    enterFrom="opacity-0 translate-y-0"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-250"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-0"
                  >
                    <Popover.Panel className="absolute z-10 right-0 transform mt-10 w-full md:w-screen max-w-lg sm:px-0">
                      <div className="p-6 rounded-lg shadow-lg bg-white relative ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <h2 className="Medium text-2xl mb-4">
                          How can we help you today?
                        </h2>
                        <Getquotation />
                      </div>
                    </Popover.Panel>
                  </Transition>
                </Popover>
              </Popover.Group>
            </div>
          )}

        </div>
      </div>
    </div>

  );
};

const mapStateToProps = ({ Auth }) => {
  return {
    authUser: Auth.authUserInformation,
    socket: Auth.socket,
  };
};

export default connect(mapStateToProps, null)(Subheader);
