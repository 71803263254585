import { gql } from "@apollo/client";

export default gql`
mutation setPrimaryCompany(
    $id: Int
  ) {
    setPrimaryCompany(
      id: $id
    ) {
      id
      title
      slug
      address
      city
      state_id
      latitude
      longitude
      country
      country_code
      postal_code
      phone
      email
      history
      linked_in_profile
      logo
      is_active
      is_deleted
      added_from
      is_primary
      state {
        id
        name
      }
      executives {
        id
        firstname
        lastname
        email
        username
        profile_photo
      }
    }
  }
`;
