import React from "react";
import LikedVendors from "./likedVendors";
import NotLikedVendors from "./notLikedVendors";
import { IoIosArrowBack } from "react-icons/io";
import { Buttonlink, Subbanner } from "components";

const PreferredVendor = (props) => {
  return (
    <>
      <div>
        <Subbanner title="Preferred Vendors" />
        <div className="container -mt-28">
          <div className="grid xl:grid-cols-5 grid-cols-1 gap-4">
            <div className="xl:col-start-2 xl:col-span-3">
              <div className="mb-5 relative">
                <Buttonlink
                  text="Back"
                  prefix={<IoIosArrowBack size={16} />}
                  onClick={() => props.navigate(-1)}
                  className="relative bg-gray-200 hover:bg-gray-300 fs-13 flex items-center justify-center gap-x-1 h-9 rounded-full darkGray w-24 hover:opacity-80 border-0 shadow-none"
                />
              </div>
              {/* System will get Liked vendors from redux first time */}
              <LikedVendors />
              <div className="mt-7 mb-3">
                <h3 className="Medium text-xl font-semibold">
                  You May Be Interested In
                </h3>
                {/* Non liked vendors from 1st 10 based on nearest location will show */}
                <NotLikedVendors />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreferredVendor;
