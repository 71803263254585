import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { FiChevronDown } from "react-icons/fi";

export default function Faqs(props) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {props.data &&
        props.data.map((item) => {
          return (
            <div className="box overflow-hidden mx-1 intro-y" key={item.id}>
              <Accordion
                expanded={expanded === item.id}
                onChange={handleChange(item.id)}
              >
                <AccordionSummary
                  expandIcon={
                    <FiChevronDown className="black ml-5" size={20} />
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <h4 className="text-center fs-14 black">{item.title}</h4>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="fs-14 p-1">{item.description}</p>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })}
      {/* <div className="box overflow-hidden mx-1 intro-y">
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<FiChevronDown className="black ml-5" size={20} />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <h4 className="text-center fs-14 black">
              I tried to reset my password but didn't receive a reset email?
            </h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className="fs-14 p-1">
              Unfortunately, spam blockers sometimes prevent our emails from
              getting through. If your Internet provider or email application
              filters incoming email, please add procurementleague.com to your
              list of approved senders to ensure that you receive our reset
              password email. After doing so, you may need to go through the
              reset process again.
            </p>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="box overflow-hidden mx-1 intro-y">
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<FiChevronDown className="black ml-5" size={20} />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <h4 className="text-center fs-14 black">
              Why should I register as a Member?
            </h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className="fs-14 p-1">
              Most whitepapers, articles, free access to events and classes, and
              other material on procurementleague.com are exclusively available
              to our members. Most of the time, we will ask you to register or
              log in to our site before registering for an event or downloading
              the whitepapers. As a registered member of procurementleague.com,
              you can also sign up for our regular email newsletters and receive
              alerts when new content is available. Your registration
              credentials will also work on the Procurement League app, offering
              mobile access to our content and additional personalization
              options. To take advantage of these services, please register now
              (add the link to Sign-in)
            </p>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="box overflow-hidden mx-1 intro-y">
        {" "}
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<FiChevronDown className="black ml-5" size={20} />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <h4 className="text-center fs-14 black">
              What is the membership fee for joining Procurement League?
            </h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className="fs-14 p-1">
              Procurement League is an education platform where procurement and
              supply chain enthusiasts can come and learn about how to be a
              better procurement professional, and we do it all for FREE.
            </p>
          </AccordionDetails>
        </Accordion>
      </div> */}
    </>
  );
}
