import React from 'react'
import verify from '../../assets/images/verified.png'

const VerifyIcon = (props) => {
    return (
        <>
            {
                props.user?.is_verified && props.user?.is_seller &&
                <img src={verify} alt='verify' className='w-full h-full object-contain' />
            }
        </>
    )
}

export default VerifyIcon