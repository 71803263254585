import { gql } from "@apollo/client";

export default gql`
  query addressByUser($user_id: ID!) {
    addressByUser(user_id: $user_id) {
      id
      name
      addressType
      address_1
      address_2
      city
      state_id
      state {
        id
        country_id
        name
      }
      country{
        id
        sortname
        name
        phonecode
      }
      zip_code
      country_code
      phone
      email
      is_primary
      created_at
    }
  }
`;
