import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Popup } from "semantic-ui-react";
import { encryptfunction } from "helpers";
import { Avatarimage } from "components";
import { Loader, Buttonlink } from "components";
import Moment from "react-moment";
import ReactStars from "react-rating-stars-component";
import { Menu, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import { FiChevronDown } from "react-icons/fi";
import { Component, Fragment } from "react";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import SocialShare from "components/socialShare";
import { IoCloseCircleOutline } from "react-icons/io5";
import { FiX } from "react-icons/fi";
import { HiDotsHorizontal } from "react-icons/hi";
import { VscFilePdf } from "react-icons/vsc";

const ProductParticipents = ({
  rfqDetail,
  shareOptions,
  state,
  updateParentState,
  auth_user,
  quotationApproved,
  OfferClosed,
  quotationRevised,
  rejectRfqReq,
  openChatPopup,
  handleMenuItemClick,
  isRejectToastVisible,
  isReviseToastVisible,
  loader,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenProductModal, setIsOpenProductModal] = useState({
    open: false,
    item: null,
    product: null,
  });
  const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  let matchingExecutive = rfqDetail?.executives.find(
    (executive) => executive.id === auth_user?.id
  );

  let permissionNames = [];

  if (matchingExecutive) {
    permissionNames =
      matchingExecutive?.offer_executive_user_roles[0]?.permissions.map(
        (permission) => permission.name
      );
  }
  const sellerratting = rfqDetail?.review?.filter((r) => r.is_buyer === true);
  let categoryNames = [];
  rfqDetail.categories &&
    rfqDetail.categories.data &&
    rfqDetail.categories?.data?.map((category) =>
      categoryNames.push(category.name)
    );
  const allParticipantsOrderEmpty = rfqDetail?.products?.every((product) => {
    return (
      rfqDetail?.participantsOrder?.filter(
        (dat) => dat.product_id == product.id
      )?.length == 0
    );
  });
  return (
    <>
      {loader ? (
        <Loader />
      ) : !allParticipantsOrderEmpty ? (
        rfqDetail?.products?.map((product, index) =>
          rfqDetail?.participantsOrder?.filter(
            (dat) => dat.product_id == product.id
          )?.length > 0 && rfqDetail.isOwner ? (
            <div
              className={`${index !== rfqDetail?.products?.length - 1 && "border-b"
                } my-5`}
            >
              {rfqDetail?.isOwner &&
                rfqDetail?.participants?.data?.filter(
                  (dat) => dat.product_id == product.id
                )?.length > 0 && (
                  <p className="primary Bold font-semibold text-lg pb-3">
                    {product.title}
                  </p>
                )}
              {rfqDetail?.isOwner &&
                rfqDetail?.participants?.data?.length > 0 ? (
                rfqDetail?.participants?.data
                  ?.filter((dat) => dat.product_id == product.id)
                  ?.map((item) =>
                    item.product_id == product.id ? (
                      <div className="pb-10 flex items-center gap-x-3">
                        <div className="w-full max-w-[160px]">
                          <Popup
                            trigger={
                              <Link
                                className="flex items-center truncate leading-5 gap-x-3 Medium text-md hover:primary"
                                to={`/${encryptfunction(item.user.id)}/profile`}
                                state={{ value: "edit", id: item.user.id }}
                              >
                                <Avatarimage
                                  imagesrc={item.user.profile_photo}
                                  userId={item.user.id}
                                />
                                {capitalizeFirst(item.user.firstname)}{" "}
                                {capitalizeFirst(item.user.lastname)}
                              </Link>
                            }
                            content={
                              <div className="profile-stats">
                                <p className="Medium fs-20 black">
                                  Supplier Qulaity Rating System
                                </p>
                                <p className="px-3 py-1 text-white bg-black rounded-sm Medium fs-16">
                                  Rating Scale
                                </p>
                                <div className="hidden grid-cols-5 gap-3 px-3 py-1 bg-gray-300 rounded-sm lg:grid">
                                  <p className="Medium fs-14 black">
                                    5 - Excellent
                                  </p>
                                  <p className="Medium fs-14 black">4 - Good</p>
                                  <p className="Medium fs-14 black">3 - Fair</p>
                                  <p className="Medium fs-14 black">2 - Poor</p>
                                  <p className="Medium fs-14 black">
                                    1 - Extremely Poor
                                  </p>
                                </div>
                                <div className="grid grid-cols-2 gap-3 mt-2 lg:grid-cols-5 md:grid-cols-3">
                                  <div className="px-3 pt-4 pb-5 text-center bg-gray-300 rounded-md">
                                    <p className="mb-3 fs-14 min-h-[60px] flex items-center justify-center">
                                      Responsiveness to RFQs
                                    </p>
                                    <p className="mb-2 fs-14">
                                      Average Rating:
                                    </p>
                                    <p className="bg-gray-200 rounded-md py-1 px-4 inline-flex items-center justify-center mx-auto mb-4 w-[50px] h-[32px]">
                                      1.22
                                    </p>
                                  </div>
                                  <div className="px-3 pt-4 pb-5 text-center bg-gray-300 rounded-md">
                                    <p className="mb-3 fs-14 min-h-[60px] flex items-center justify-center">
                                      Ability to Qoute Parts Listed
                                    </p>
                                    <p className="mb-2 fs-14">
                                      Average Rating:
                                    </p>
                                    <p className="bg-gray-200 rounded-md py-1 px-4 inline-flex items-center justify-center mx-auto mb-4 w-[50px] h-[32px]">
                                      1.22
                                    </p>
                                  </div>
                                  <div className="px-3 pt-4 pb-5 text-center bg-gray-300 rounded-md">
                                    <p className="mb-3 fs-14 min-h-[60px] flex items-center justify-center">
                                      Ability to Ship Parts Ordered
                                    </p>
                                    <p className="mb-2 fs-14">
                                      Average Rating:
                                    </p>
                                    <p className="bg-gray-200 rounded-md py-1 px-4 inline-flex items-center justify-center mx-auto mb-4 w-[50px] h-[32px]">
                                      1.22
                                    </p>
                                  </div>
                                  <div className="px-3 pt-4 pb-5 text-center bg-gray-300 rounded-md">
                                    <p className="mb-3 fs-14 min-h-[60px] flex items-center justify-center">
                                      Delivered Parts Met Order Specification
                                    </p>
                                    <p className="mb-2 fs-14">
                                      Average Rating:
                                    </p>
                                    <p className="bg-gray-200 rounded-md py-1 px-4 inline-flex items-center justify-center mx-auto mb-4 w-[50px] h-[32px]">
                                      1.22
                                    </p>
                                  </div>
                                  <div className="px-3 pt-4 pb-5 text-center bg-gray-300 rounded-md">
                                    <p className="mb-3 fs-14 min-h-[60px] flex items-center justify-center">
                                      Handling of Return Requests
                                    </p>
                                    <p className="mb-2 fs-14">
                                      Average Rating:
                                    </p>
                                    <p className="bg-gray-200 rounded-md py-1 px-4 inline-flex items-center justify-center mx-auto mb-4 w-[50px] h-[32px]">
                                      1.22
                                    </p>
                                  </div>
                                </div>
                              </div>
                            }
                            className="shadow-lg profile-tooltip"
                            inverted
                          />
                        </div>

                        {/* <div className="flex">
                                            {item.document && (
                                                <Buttonlink
                                                    className="flex items-center px-6 py-2 my-3 leading-5 rounded-lg shadow gap-x-2 fs-13 Regular hover:opacity-70 bg--lightGray darkGray"
                                                    href={`${process.env.REACT_APP_MAIN_URL}/${item.document}`}
                                                    target="_blank"
                                                    prefix={<AiOutlineCloudDownload size={22} />}
                                                    text="Download Attachment"
                                                />
                                            )}
                                        </div> */}
                        {item.action_user_id !== null ? (
                          <p>Rejection of Quotation from Seller Side</p>
                        ) : (
                          <>
                            {item?.expected_date && (
                              <div>
                                <div className={"flex"}>
                                  <p className="Medium primary text-md ">
                                    Expected delivery date:
                                  </p>
                                  <p>
                                    <Moment
                                      date={item?.expected_date}
                                      format={"LL"}
                                      className="ml-2 text-right"
                                    />
                                  </p>
                                </div>
                              </div>
                            )}
                            <div className="flex items-center gap-x-5">
                              <p className="Medium flex items-center shrink-0 gap-x-1 primary ">
                                Quoted Amount:{" "}
                                <span className="Medium text-black">
                                  ${item.total}
                                </span>
                              </p>
                              <div>
                                <p
                                  className={`rounded-full fs-13 ${item.readable_status === "Revision"
                                    ? "bg-orange-500"
                                    : item.readable_status === "Rejected"
                                      ? "bg-red-500"
                                      : item.readable_status === "Pending"
                                        ? "bg-gray-500"
                                        : item.readable_status === "Waiting"
                                          ? "bg-pink-400"
                                          : item.readable_status === "Complete"
                                            ? "bg-green-500"
                                            : "bg-amber-500"
                                    } text-white px-4 py-1 inline-flex`}
                                >
                                  {item.readable_status === "Waiting"
                                    ? "Waiting For Seller Response"
                                    : item.readable_status}
                                </p>
                              </div>
                            </div>
                            {item.approved === false &&
                              !rfqDetail?.approvedParticipant?.some(
                                (ap) => ap.product_id == product.id
                              )
                              ? !item.blocked && (
                                <>
                                  <div className="flex items-center justify-center gap-x-3">
                                    <Buttonlink
                                      disabled={
                                        !!(
                                          item.approved ||
                                          item.isRevision ||
                                          item.blocked ||
                                          state?.offerAcceptLoader
                                        )
                                      }
                                      onClick={() =>
                                        item.expected_date !== null
                                          ? setIsOpen(true)
                                          : quotationApproved(
                                            item.id,
                                            product.id
                                          )
                                      }
                                      text="Approve"
                                      className={
                                        item.isRevision || item.blocked
                                          ? "h-10 px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow opacity-70 bg--primary white hover:text-white"
                                          : "h-10 px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white"
                                      }
                                    />

                                    <Menu
                                      key={item.id}
                                      as="div"
                                      className="relative inline-block text-left"
                                    >
                                      <div className="inline-flex items-center h-10 overflow-hidden leading-5 rounded-lg shadow-md fs-15 Regular hover:opacity-70 bg--lightGray darkGray">
                                        {state.itemId === item.id ? (
                                          <>
                                            {state.is_revision &&
                                              !item.isRevision &&
                                              !item.blocked && (
                                                <p
                                                  onClick={() => {
                                                    setTimeout(() => {
                                                      updateParentState(
                                                        isRejectToastVisible,
                                                        false
                                                      );
                                                    }, 10000);
                                                    {
                                                      !state.isRejectToastVisible &&
                                                        toast.warn(
                                                          <div>
                                                            <p>
                                                              Are you certain
                                                              that you want to
                                                              proceed with the
                                                              Revision? This
                                                              action cannot be
                                                              undone.
                                                            </p>
                                                            {updateParentState(
                                                              isRejectToastVisible,
                                                              true
                                                            )}
                                                            <button
                                                              className={
                                                                "h-10 px-3 ml-auto py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white"
                                                              }
                                                              onClick={() => {
                                                                updateParentState(
                                                                  isRejectToastVisible,
                                                                  false
                                                                );
                                                                quotationRevised(
                                                                  item.id
                                                                );
                                                              }}
                                                            >
                                                              Yes
                                                            </button>
                                                            <button
                                                              className={
                                                                "inline-flex px-3 ml-2 items-center h-10 overflow-hidden leading-5 rounded-lg shadow-md fs-15 Regular hover:opacity-70 bg--lightGray darkGray"
                                                              }
                                                              onClick={() => {
                                                                updateParentState(
                                                                  isRejectToastVisible,
                                                                  false
                                                                );
                                                                toast.dismiss();
                                                              }}
                                                            >
                                                              No
                                                            </button>
                                                          </div>
                                                        );
                                                    }
                                                  }}
                                                  className={`darkGray pr-2 pl-6 py-2.5 ${item.isRevision ||
                                                    item.blocked
                                                    ? "opacity-75 cursor-default"
                                                    : ""
                                                    }`}
                                                >
                                                  Revision
                                                </p>
                                              )}

                                            {state.is_reject &&
                                              !item.blocked && (
                                                <p
                                                  onClick={() => {
                                                    setTimeout(() => {
                                                      updateParentState(
                                                        isReviseToastVisible,
                                                        false
                                                      );
                                                    }, 10000);
                                                    {
                                                      !state.isReviseToastVisible &&
                                                        toast.warn(
                                                          <div>
                                                            <p>
                                                              Are you certain
                                                              that you want to
                                                              proceed with the
                                                              rejection? This
                                                              action cannot be
                                                              undone.
                                                            </p>
                                                            {updateParentState(
                                                              isReviseToastVisible,
                                                              true
                                                            )}
                                                            <button
                                                              className={
                                                                "h-10 px-3 ml-auto py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white"
                                                              }
                                                              onClick={() => {
                                                                updateParentState(
                                                                  isReviseToastVisible,
                                                                  false
                                                                );
                                                                rejectRfqReq(
                                                                  item.id
                                                                );
                                                                toast.dismiss();
                                                              }}
                                                            >
                                                              Yes
                                                            </button>
                                                            <button
                                                              className={
                                                                "inline-flex px-3 ml-2 items-center h-10 overflow-hidden leading-5 rounded-lg shadow-md fs-15 Regular hover:opacity-70 bg--lightGray darkGray"
                                                              }
                                                              onClick={() => {
                                                                updateParentState(
                                                                  isReviseToastVisible,
                                                                  false
                                                                );
                                                                toast.dismiss();
                                                              }}
                                                            >
                                                              No
                                                            </button>
                                                          </div>
                                                        );
                                                    }
                                                  }}
                                                  className={`darkGray pr-2 pl-6 py-2.5 ${item.isRevision ||
                                                    item.blocked
                                                    ? "opacity-75 cursor-default"
                                                    : ""
                                                    }`}
                                                >
                                                  Reject
                                                </p>
                                              )}

                                            {state.is_message && (
                                              <p
                                                onClick={() =>
                                                  openChatPopup(item.user.id)
                                                }
                                                className="darkGray pr-2 pl-6 py-2.5 cursor-pointer"
                                              >
                                                Message
                                              </p>
                                            )}
                                          </>
                                        ) : (
                                          <p className="darkGray pr-2 pl-6 py-2.5 cursor-pointer">
                                            options
                                          </p>
                                        )}

                                        <Menu.Button
                                          key={item.id}
                                          className="pr-6 py-2.5 fs-15 leading-5 bg--lightGray inline-flex items-center cursor-default"
                                        >
                                          <FiChevronDown className="w-5 h-5 ml-2 -mr-1" />
                                        </Menu.Button>
                                      </div>

                                      <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                        key={item.id}
                                      >
                                        <Menu.Items
                                          key={item.id}
                                          className="absolute right-0 w-64 p-2 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg z-xlfull ring-1 ring-black ring-opacity-5 focus:outline-none"
                                        >
                                          <div className="px-1 py-1 ">
                                            <Menu.Item
                                              key={item.id}
                                              onClick={() =>
                                                handleMenuItemClick(
                                                  1,
                                                  item.id
                                                )
                                              }
                                              disabled={
                                                item.isRevision ||
                                                  item.blocked
                                                  ? true
                                                  : false
                                              }
                                              className={`w-full text-left p-2.5 fs-15 leading-5 Regular rounded-lg hover:bg-slate-200 darkGray ${item.isRevision ||
                                                item.blocked
                                                ? "opacity-75"
                                                : ""
                                                }`}
                                            >
                                              <p>Revision</p>
                                            </Menu.Item>
                                            <Menu.Item
                                              key={item.id}
                                              onClick={() =>
                                                handleMenuItemClick(
                                                  2,
                                                  item.id
                                                )
                                              }
                                              disabled={
                                                item.isRevision ||
                                                  item.blocked
                                                  ? true
                                                  : false
                                              }
                                              className={`w-full text-left p-2.5 fs-15 leading-5 Regular rounded-lg hover:bg-slate-200 darkGray ${item.isRevision ||
                                                item.blocked
                                                ? "opacity-75"
                                                : ""
                                                }`}
                                            >
                                              <p>Reject</p>
                                            </Menu.Item>
                                            <Menu.Item
                                              key={item.id}
                                              onClick={() =>
                                                handleMenuItemClick(
                                                  3,
                                                  item.id
                                                )
                                              }
                                            >
                                              <Buttonlink
                                                text="Message"
                                                className="w-full text-left p-2.5 fs-15 leading-5 Regular rounded-lg hover:bg-slate-200 darkGray"
                                              />
                                            </Menu.Item>
                                          </div>
                                        </Menu.Items>
                                      </Transition>
                                    </Menu>
                                  </div>
                                  {item?.userSignature !== "false" && (
                                    <div>
                                      {item?.userSignature === "true" ? (
                                        <a
                                          href={`${process.env.REACT_APP_MAIN_URL
                                            }/non-disclosure-agreement/${encryptfunction(
                                              rfqDetail?.id +
                                              "," +
                                              item?.user?.id
                                            )}`}
                                          target="_blank"
                                        >
                                          <VscFilePdf
                                            size={24}
                                            className="primary"
                                          />
                                        </a>
                                      ) : (
                                        <a
                                          download
                                          href={item?.userSignature}
                                          target="_blank"
                                        >
                                          <VscFilePdf
                                            size={24}
                                            className="primary"
                                          />
                                        </a>
                                      )}
                                    </div>
                                  )}
                                  {/*Dialog*/}
                                  <div>
                                    <Dialog
                                      fullWidth={true}
                                      maxWidth={"md"}
                                      scroll="body"
                                      open={isOpen}
                                      onClose={() => setIsOpen(false)}
                                      id="scrollbar"
                                      className="rounded--xl"
                                    >
                                      <div className="shadow-none bg-gray-100">
                                        <Toolbar className="flex items-center justify-between">
                                          <p className="text-lg font-medium leading-6 text-gray-900">
                                            Expected delivery date:
                                          </p>
                                          <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={() => setIsOpen(false)}
                                            aria-label="close"
                                            className="ml-auto"
                                          >
                                            <IoCloseCircleOutline
                                              size={32}
                                              className="primary"
                                            />
                                          </IconButton>
                                        </Toolbar>
                                      </div>
                                      <div className="p-4">
                                        <div className="my-4">
                                          {item?.expected_date && (
                                            <p>
                                              <Moment
                                                date={item?.expected_date}
                                                format={"LL"}
                                                className="text-center primary"
                                              />
                                            </p>
                                          )}
                                        </div>

                                        <div className="mt-4 text-right">
                                          <button
                                            type="button"
                                            className="h-10 w-[150px] ml-auto py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white"
                                            onClick={() => {
                                              // quotationCanceled(item.id);
                                              quotationApproved(
                                                item.id,
                                                product.id
                                              );
                                              setIsOpen(false);
                                            }}
                                          >
                                            Accept
                                          </button>
                                          <button
                                            type="button"
                                            className="h-10 w-[150px] ml-5 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--gray white hover:text-white"
                                            onClick={() => {
                                              quotationApproved(
                                                item.id,
                                                product.id
                                              );
                                              setIsOpen(false);
                                            }}
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                    </Dialog>
                                  </div>
                                </>
                              )
                              : null}
                          </>
                        )}
                        {(item?.readable_status === "Complete" ||
                          item.action_user_id !== null ||
                          item.comment !== null) && (
                            <div
                              onClick={() => {
                                setIsOpenProductModal({
                                  open: true,
                                  item: item,
                                  product: product,
                                });
                              }}
                              className="grow flex justify-end items-center"
                            >
                              <HiDotsHorizontal
                                size={24}
                                className="text-gray-400 hover:text-gray-900 cursor-pointer "
                              />
                            </div>
                          )}
                      </div>
                    ) : (
                      "No Offer found."
                    )
                  )
              ) : (
                <>
                  {!matchingExecutive ? (
                    <p className="text-center my-7">
                      No Offer found. You can either try a new vendor or choose
                      following options.
                    </p>
                  ) : state?.rfqDetail?.isOwner ? (
                    <p className="text-center my-7">
                      No Offer found. You can either try a new vendor or choose
                      following options.
                    </p>
                  ) : null}
                  <div className="flex flex-wrap items-center justify-center gap-3 xs:gap-x-5">
                    {state?.rfqDetail?.isOwner && !shareOptions ? (
                      <Buttonlink
                        onClick={() => {
                          updateParentState(shareOptions, true);
                        }}
                        text="Share"
                        className="px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white"
                      />
                    ) : matchingExecutive || shareOptions === true ? (
                      false
                    ) : (
                      <Buttonlink
                        onClick={() => {
                          updateParentState(shareOptions, true);
                        }}
                        text="Share"
                        className="px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white"
                      />
                    )}

                    {shareOptions && (
                      <>
                        <SocialShare
                          size={30}
                          url={`${process.env.REACT_APP_MAIN_URL}/share/rfq/${rfqDetail.id}`}
                          title={rfqDetail.title}
                          subject={rfqDetail.title}
                          quote={rfqDetail.title}
                          source={process.env.REACT_APP_MAIN_URL}
                          via={process.env.REACT_APP_MAIN_URL}
                          summary={{ __html: rfqDetail.description }}
                          hashtags={categoryNames}
                        />
                        <FiX
                          className="cursor-pointer"
                          size={24}
                          onClick={() => {
                            updateParentState(shareOptions, false);
                          }}
                        />
                      </>
                    )}
                  </div>
                  {rfqDetail.menu_id === 6 || rfqDetail.menu_id === 7 ? (
                    <div className="text-center mt-7">
                      <Buttonlink
                        onClick={() => OfferClosed(rfqDetail.id)}
                        text="Close Q&A?"
                        disabled={state.is_closed ? true : false}
                        className={`px-28 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 ${state.is_closed
                          ? "bg-gray-200 text-black"
                          : "bg--primary text-white"
                          } `}
                      />
                    </div>
                  ) : null}
                </>
              )}
            </div>
          ) : null
        )
      ) : (
        <p className="text-center mt-5">No Offer Found</p>
      )}
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        scroll="body"
        open={isOpenProductModal.open}
        id="scrollbar"
        className="rounded--xl"
      >
        <div className="shadow-none bg-gray-100 p-3">
          <h1 className="text-lg font-medium leading-6 text-gray-900 Medium">
            {isOpenProductModal.product?.title} Submission Detail
          </h1>
        </div>
        <div className="p-4">
          {isOpenProductModal.item?.action_user_id !== null && (
            <div className="py-2 border-b space-y-1">
              <p className="font-semibold text-base">Rejection Reason</p>
              <strong>Reason:</strong> {isOpenProductModal?.item?.reason ?? ""}
            </div>
          )}
          {isOpenProductModal?.item?.comment && (
            <div className="py-2 border-b space-y-1">
              <p className="font-semibold text-base">Comments</p>
              <p className="Regular text-md">
                {isOpenProductModal?.item?.comment}
              </p>
            </div>
          )}
          {isOpenProductModal?.item?.readable_status === "Complete" &&
            sellerratting.length > 0 && (
              <div className={"py-2 border-b"}>
                <p className="font-semibold text-base pb-2">
                  Rating and reviews
                </p>
                {sellerratting?.map((rate) => (
                  <>
                    {rate.product_id == isOpenProductModal.product?.id && (
                      <div className="space-y-1">
                        <p className={"Regular"}>
                          {" "}
                          {capitalizeFirst(
                            isOpenProductModal.item?.user?.firstname
                          )}{" "}
                          {capitalizeFirst(
                            isOpenProductModal.item?.user?.lastname
                          )}{" "}
                          Leaves A Review
                        </p>
                        <div className="flex items-center gap-x-3">
                          <p className="Medium text-md">Ratings:</p>
                          <ReactStars
                            count={5}
                            edit={false}
                            color={"#d1d5db"}
                            activeColor={"#ffd700"}
                            value={parseInt(rate?.rate)}
                            size={24}
                            emptyIcon={<i className="far fa-star"></i>}
                            halfIcon={<i className="fa fa-star-half-alt"></i>}
                            fullIcon={<i className="fa fa-star"></i>}
                          />
                        </div>
                        <div className="flex items-center gap-x-3">
                          <p className="Medium text-md">Review:</p>
                          <p>{rate?.comment}</p>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            )}

          <div className="mt-4 text-right">
            <button
              onClick={() =>
                setIsOpenProductModal((prevState) => ({
                  ...prevState,
                  open: false,
                }))
              }
              type="button"
              className="h-10 w-[150px] ml-5 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--gray white hover:text-white"
            >
              Close
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ProductParticipents;
