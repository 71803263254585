import { gql } from "@apollo/client";

export default gql`
  query ($first: Int!, $page: Int!, $key: String) {
    quick_search_faqs(first: $first, page: $page, key: $key) {
      data {
        id
        title
        description
      }
      paginatorInfo {
        hasMorePages
        total
        perPage
        lastItem
        currentPage
      }
    }
  }
`;
