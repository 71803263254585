import { Link } from "react-router-dom";
import React, { Component } from "react";
import { BsBookmark } from "react-icons/bs";
import client from "graphql/client";
import offerSaveBookmark from "../../../graphql/mutations/offerSaveBookmark";
import offerUnSaveBookmark from "../../../graphql/mutations/offerUnSaveBookmark";
import { connect } from "react-redux";
import { gql } from "@apollo/client";
import { convertToSlug, encryptfunction } from "helpers";
import NoImage from "../../../assets/images/no-photo.jpg";
import Auth from "../../../Auth";
import { LoadingButton } from "@mui/lab";
import { BiLoaderAlt } from "react-icons/bi";
import { Box } from "@mui/material";
const auth = new Auth();

class RFQcard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.company = null;
  }

  removeBookmark = (id, bookMark) => {
    client.mutate({
      mutation: offerUnSaveBookmark,
      variables: {
        offer_id: id,
      },
      optimisticResponse: {
        __typename: "Mutation",
        offerUnSaveBookmark: {
          __typename: "offerUnSaveBookmark",
          id: id,
          isBookmarked: false,
        },
      },
      update: (store, { data: { offerUnSaveBookmark } }) => {
        try {
          // const data = store.readQuery({
          //   query: getOffer,
          //   variables: { id: offerSaveBookmark.id },
          // });
          store.writeQuery({
            query: gql`
              query Offer($id: ID) {
                offer(id: $id) {
                  id
                  isBookmarked
                }
              }
            `,
            data: {
              offer: {
                __typename: "Offer",
                id: offerUnSaveBookmark.id,
                isBookmarked: offerUnSaveBookmark.isBookmarked,
              },
            },
            variables: {
              id: id,
            },
          });
        } catch (e) {
          console.log("writeQuery error removeBookmark", e);
        }
      },
    });
  };

  addBookmark = (id) => {
    client.mutate({
      mutation: offerSaveBookmark,
      variables: {
        offer_id: id,
      },
      optimisticResponse: {
        __typename: "Mutation",
        offerSaveBookmark: {
          __typename: "offerSaveBookmark",
          id: id,
          isBookmarked: true,
        },
      },
      update: (store, { data: { offerSaveBookmark } }) => {
        try {
          // const data = store.readQuery({
          //   query: getOffer,
          //   variables: { id: offerSaveBookmark.id },
          // });
          store.writeQuery({
            query: gql`
              query Offer($id: ID) {
                offer(id: $id) {
                  id
                  isBookmarked
                }
              }
            `,
            data: {
              offer: {
                __typename: "Offer",
                id: offerSaveBookmark.id,
                isBookmarked: offerSaveBookmark.isBookmarked,
              },
            },
            variables: {
              id: id,
            },
          });
        } catch (e) {
          console.log("writeQuery error removeBookmark", e);
        }
      },
    });
  };

  renderComponent = (item) => {
    const {
      column,
      imageHeight,
      cardSpacing,
      imageRounded,
      contentSpacing,
      cardBorder,
      selectedIndex,
    } = this.props;

    let background_url = "general.jpg";
    let arrayForSort = [...item.categories];
    let category = arrayForSort
      .sort(() => Math.random() - Math.random())
      .find(() => true);
    if (category) {
      background_url = category.image_url;
    }
    return (
      <div className="relative" key={item.id}>
        {/* {auth.isAuthenticated() && this.props.home && (
          <div
            onClick={() =>
              item?.isBookmarked
                ? this.removeBookmark(parseInt(item.id))
                : this.addBookmark(parseInt(item.id))
            }
            className={`${item && item.isBookmarked ? "bg-[#ef553b]" : "bg-gray-200"
              } h-9 w-9 z-50 cursor-pointer absolute top-0 m-2 right-0 rounded-full flex items-center justify-center`}
          >
            <BsBookmark className="text-white" />
          </div>
        )} */}
        {/* commented because it might be needed in future */}
        {item?.isBookmarked && !this.props.home ? (
          null
          // <div
          //   onClick={() => this.removeBookmark(item.id, item.isBookmarked)}
          //   className="h-9 w-9 cursor-pointer absolute top-0 right-0 z-50 m-2 bg-[#ef553b] rounded-full flex items-center justify-center"
          // >
          //   <BsBookmark className="text-white" />
          // </div>
        ) : !this.props.home ? (
          null
          // <div
          //   onClick={() => this.addBookmark(item.id, item.isBookmarked)}
          //   className="h-9 w-9 cursor-pointer absolute top-0 right-0 z-50 m-2 bg-gray-200 rounded-full flex items-center justify-center"
          // >
          //   <BsBookmark className="gray" />
          // </div>
        ) : auth.isAuthenticated() ? (
          <div
            onClick={() =>
              item?.isBookmarked
                ? this.removeBookmark(parseInt(item.id))
                : this.addBookmark(parseInt(item.id))
            }
            className={`${item && item.isBookmarked ? "bg-[#ef553b]" : "bg-gray-200"
              } h-9 w-9 z-50 cursor-pointer absolute top-0 m-2 right-0 rounded-full flex items-center justify-center`}
          >
            <BsBookmark className="text-white" />
          </div>
        ) : null}

        <Link
          rel="nofollow"
          to={`/rfq/${encryptfunction(item.id)}/${convertToSlug(item.title)}`}
          state={{ alert: null, selectedIndex: selectedIndex }}
          className="intro-y hover:primary-only-text"
        >
          <div className={`box overflow-hidden ${cardSpacing}`}>
            <div className={`${column} flex items-center gap-x-3`}>
              <div
                className={`${imageHeight} ${cardBorder} bg-gray-100 rounded-xl border-gray-50`}
              >
                <img
                  alt={item.title}
                  src={
                    "https://d2pjglp4tgg94c.cloudfront.net/" + background_url
                  }
                  className={`h-full object-cover w-full ${imageRounded}`}
                  onError={(e) => {
                    const img = new Image();
                    // img.src = `${process.env.REACT_APP_MAIN_URL}/assets/images/categories/${background_url}`;
                    img.src = background_url;
                    img.onload = () => {
                      e.target.src = img.src;
                    };
                    img.onerror = () => {
                      e.target.src = NoImage;
                    };
                  }}
                />
              </div>
              <div className={`${contentSpacing} space-y-2`}>
                <p className="Medium anchor">{item.title}</p>
                <p className="fs-12 darkGray">
                  Required {item.hasProducts ? `${item.productsQuantity} products` : item.uom !== null ? `${item.uom}:${item.quantity}` : 'files'}
                </p>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  };

  render() {
    const { publicRfqs } = this.props;

    return (
      <>
        {publicRfqs?.offers?.data
          .filter((o) => !o.isRecommendation)
          .map((items) => this.renderComponent(items))}
        {this.props.paginatorInfo?.hasMorePages && (
          <Box
            sx={{ "& > button": { m: 1 } }}
            className="lg:col-span-3 md:col-span-3 text-center sm:col-span-2"
          >
            <LoadingButton
              className="relative mx-auto mt-10 hover:white white hover:opacity-80 flex justify-center py-2.5 px-5 border border-transparent rounded-lg white h-11 w-44 important:Medium important:bg--primary  hover:bg--primary focus:outline-none"
              onClick={() => this.props.onLoadMore()}
              loadingIndicator={
                <BiLoaderAlt className="animate-spin white" size={16} />
              }
              variant="contained"
            >
              Load More
            </LoadingButton>
          </Box>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ Rfq, Auth }) => {
  return {
    recallRef: Rfq.recallRef,
    authUser: Auth.authUserInformation,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("store/redux/RFQRedux");
  return {
    ...ownProps,
    ...stateProps,
    rfqPublishorUnpublished: (data) => {
      dispatch(actions.rfqPublishorUnpublished(data));
    },
  };
};

export default connect(mapStateToProps, undefined, mergeProps)(RFQcard);
