import React, { useEffect, useState, useMemo, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { IoCloseCircleOutline } from "react-icons/io5";
import avatar from "../../assets/images/banner.jpg";
import { FaPaperclip, FaPaperPlane } from "react-icons/fa";
import { Buttonlink, Loader } from "components";
import "./styles.css";
import Moment from "react-moment";
import Profileavatar from "../profileavatar";
import OFFER_USER_CHAT from "../../graphql/mutations/offerUserChat";
import { gql, useMutation } from "@apollo/client";
import { connect } from "react-redux";
import getOffer from "graphql/queries/getOffer";


const GET_OFFER = gql`
  query Offer($id: Int!) {
    offer(id: $id) {
      id
      messages {
        message_id
        room_id
        user_id
        receiver_id
        is_buyer
        message
        user {
          id
          username
          firstname
          lastname
          profile_photo
        }
        receiver {
          id
          username
          firstname
          lastname
          profile_photo
        }
		is_seen
        created_at
      }
    }
  }
`;

function ChatPopup(props) {
	const scrollRef = useRef(null);
	const [open, setopen] = useState(props.chatPopup);
	const [user_id, setUserId] = useState("");
	const [room_id, setRoomId] = useState("");
	const [receiver_id, setReceiver_id] = useState(0);
	const [message, setMessage] = useState("");
	const [joined, setJoined] = useState(false);
	const [messageList, setMessageList] = useState([]);
	let dataa = "";
	let userid = props.is_seller ? props.authUser.id : props.rfq.company.id;
	let recieverid = props.is_seller ? props.rfq.company.id : props.receiver_id;

	const memoizedMessageList = useMemo(() => {
		let msgs = props.rfq.messages.map((element) => {
			dataa = "";
			if (
				(element.user_id == userid ||
					element.user_id == recieverid) &&
				(element.receiver_id == recieverid ||
					element.receiver_id == userid)
			) {
				dataa = JSON.parse(element.message);
			}
			return dataa[0] ?? "";
		});
		return msgs;
	}, [props.rfq.messages, userid, recieverid]);

	// Update the messageList state when memoizedMessageList changes
	useEffect(() => {
		setMessageList(memoizedMessageList);
		joinRoom();
	}, [props.chatPopup, memoizedMessageList]);

	useEffect(() => {
		setUserId(props.authUser?.id);
		setRoomId(props.rfq?.id);
		setReceiver_id(props.receiver_id);
		setopen(props.chatPopup);
		// let userid = props.is_seller ? props.authUser.id : props.rfq.company.id;
		// let recieverid = props.is_seller ? props.rfq.company.id : props.receiver_id;
		let msgs = props.rfq.messages.map((element) => {
			dataa = "";
			if (
				(element.user_id == userid ||
					element.user_id == recieverid) &&
				(element.receiver_id == recieverid ||
					element.receiver_id == userid)
			) {
				dataa = JSON.parse(element.message);
			}
			return dataa[0] ?? "";
		});
		setMessageList(msgs);

		joinRoom();
	}, [props.chatPopup]);

	const joinRoom = () => {
		if (props.authUser?.id && props.rfq?.id) {
			if (props.socket?.connected) {
				props.socket.emit("join_room", props.rfq.id);
				setJoined(true);
			} else {
				// Handle the case when props.socket is not available or not working
				console.error("Socket is not available or not working.");
				// You can add error handling logic here, such as displaying a message to the user.
			}
		}
	};

	const sendMessage = async () => {

		if (message !== "" || message.trim() !== "") {
			let newMsg = JSON.stringify([
				{
					text: message,
					user: { _id: userid },
					receiver: recieverid,
					createdAt: new Date(),
					_id: room_id,
				},
			]);

			setMessage("");

			let data = {
				room_id: room_id,
				user_id: userid,
				receiver_id: recieverid,
				created_at: new Date(),
				message: newMsg,
				is_buyer: props.authUser.is_seller,
			};
			if (props.socket?.connected) {
				await props?.socket?.emit("send_mesage", data);
			}
			data.type = 0;

			// let offerId = data.room_id;

			mutateFunction({
				variables: {
					user_id: data.user_id,
					room_id: data.room_id,
					receiver_id: data.receiver_id,
					message: data.message,
					is_buyer: data.is_buyer,
				},
				update: (cache, { data }) => {
					cache.writeQuery({
						query: GET_OFFER,
						variables: { id: props.rfq?.id },
						data: {
							offer: {
								id: props.rfq?.id,
								__typename: "offer",
								messages: data.offerUserChat.messages,
							},
						},
					});
				},
			}).then((r) => {
				if (props.socket?.connected) {
					props?.socket?.emit("notify", "Notification data");
				}

				// Update the messageList state
				const newMessage = {
					text: message,
					user: { _id: userid },
					receiver_id: recieverid,
					createdAt: new Date(),
					_id: room_id,
				};
				setMessageList((list) => [...list, newMessage]);
			});

		}
	};

	const [mutateFunction, { data, loading, error }] =
		useMutation(OFFER_USER_CHAT);
	useEffect(() => {
		if (props.socket.connected) {
			props?.socket?.on("receive_message", (data) => {
				console.log("receive_message", data);
				if (props.is_seller) {
					let newMsg = JSON.parse(data.message);
					let payload = {
						text: newMsg[0].text,
						user: { _id: data.user_id, to: data.receiver_id },
						createdAt: new Date(),
						_id: data.room_id,
					};
					setMessageList((list) => [...list, payload]);
				}
				else {
					let newMsg = JSON.parse(data.message);
					let payload = {
						text: newMsg[0].text,
						user: { _id: data.user_id, to: data.receiver_id },
						createdAt: new Date(),
						_id: data.room_id,
					};
					setMessageList((list) => [...list, payload]);
				}
			});
		}
	}, [props?.socket]);

	const openPopup = () => {
		setopen(true);
	};

	const closePopup = () => {
		props.onClose();
		props.openChatPopup();
		setopen(false);
		setJoined(false);
	};
	const adjustTextareaHeight = (textarea) => {
		textarea.style.height = 'auto';
		textarea.style.height = (textarea.scrollHeight) + 'px';
	}
	let matchUserId = props.is_seller ? user_id : props.rfq.company.id;

	useEffect(() => {
		setTimeout(() => {
			scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
		}, 100);
	}, [open, messageList]);

	const handleFileUpload = (event) => {
		const file = event.target.files[0]
	}

	return (
		<div>
			<Dialog
				fullWidth={true}
				maxWidth={"md"}
				scroll="body"
				open={open}
				onClose={closePopup}
				id="scrollbar"
				className="rounded--xl"
			>
				<div className="shadow-none bg-gray-100">
					<Toolbar className="flex items-center justify-between">
						{props.socket?.connected && <p className="fs-15 Regular">Private Message</p>}
						{!props.socket?.connected &&
							<p className="text-center">Server Is Down, you may get difficulty in live messages, please reload the screen to get new messages. Sorry for the inconvenience; we are working to get it fixed.</p>
						}
						<IconButton
							edge="start"
							color="inherit"
							onClick={closePopup}
							aria-label="close"
							className="ml-auto"
						>
							<IoCloseCircleOutline size={32} className="primary" />
						</IconButton>
					</Toolbar>
				</div>
				<div className="">
					<div className="min-h-[70vh] rounded-lg shadow-lg flex flex-col">
						{/* {joined ? ( */}
						{joined ? (
							<div className="p-3 chat-body overflow-y-auto" id="chatscroll">
								{messageList?.map((item, index) => {
									if (!item.text) {
										return; // Skip rendering if item.text is empty
									}
									if (room_id === item._id) {
										// If there's a "to" field in the item.user object
										if (item.user?.to !== undefined) {

											if (
												item.user._id !== userid &&
												item.user.to !== userid
											) {
												return;
											}
											if (
												item.user?.to === userid &&
												item.user?._id !== recieverid
											) {
												return;
											}
											if (
												item.user?.to !== recieverid &&
												item.user?._id !== recieverid
											) {
												return;
											}

											// If the "to" field matches user_id, but the _id field does not match receiver_id

										}
									}
									// console.log('item', item);

									const isCurrentUser =
										item.user && item.user._id && item.user._id === userid;
									return (
										<div
											className={
												isCurrentUser ? "otherChat mb-3" : "meChat mb-3"
											}
											key={item.message_id}
										>

											{isCurrentUser ? (
												// Other user's chat
												<div className="flex flex-row-reverse items-end justify-start">
													<div className="w-10 h-10 rounded-full shadow--lg">
														<Profileavatar
															singleUser={props.authUser}
															className="rounded-full object-cover shrink-0 w-10 h-10 overflow-hidden"
														/>
													</div>
													<div className="mr-4 max-w-sm bg-gray-400 p-3 rounded-lg relative">
														<p className="fs-12 Regular text-white">
															{item.text}
														</p>
														<div className="flex items-center justify-end text-sky-400">
															<Moment
																date={item.created_at}
																format={"LL"}
																className="fs-10 text-white text-right -mt-1 mr-3"
															/>
														</div>
													</div>
												</div>
											) : (
												// Current user's chat
												<div className="flex flex-row-reverse items-end justify-end">
													<div className="ml-4 max-w-sm bg--primary p-3 rounded-lg relative">
														<p className="fs-12 Regular text-white">
															{item.text}
														</p>
														<div className="flex items-center justify-end text-sky-400">
															<Moment
																date={item.created_at}
																format={"LL"}
																className="fs-10 text-white text-right -mt-1 mr-3"
															/>
														</div>
													</div>
													<div className="w-10 h-10 rounded-full shadow--lg">
														<img
															src={avatar}
															alt="avatar"
															className="rounded-full object-cover shrink-0 w-10 h-10 overflow-hidden"
														/>
													</div>
												</div>
											)}
											<div ref={scrollRef} />
										</div>
									);
								})}
							</div>
						) : (
							<Loader />
						)}
						<div className="chat-footer flex-grow flex items-end">
							<div className="bg--lightGray w-full rounded-b-lg p-3 flex items-center">
								<textarea
									// value={this.state.text}
									// onChange={this.handleTextAreaChange}
									rows={1}
									onChange={(event) => {
										const inputValue = event.target.value; // Trim leading and trailing spaces
										setMessage(inputValue);
										adjustTextareaHeight(event.target);
									}}
									value={message}
									onKeyPress={(e) => {
										if (e.key === "Enter") {
											sendMessage();
											setMessage("");
										}
									}}
									maxLength="250"
									cols={50}
									placeholder="Type your message here..."
									className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm resize-none overflow-hidden"
								/>
								{/* <input
									type="text"
									onChange={(event) => {
										setMessage(event.target.value);
									}}
									value={message}
									onKeyPress={(e) => {
										if (e.key === "Enter") {
											sendMessage();
											setMessage("");
										}
									}}
									maxLength="250"
									placeholder="Type your message here..."
									className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
								/> */}
								<div className="flex items-center ml-2">
									<div>
										{message.length}/{250}
									</div>
								</div>
								{/* <div
									className="image-upload relative w-14 h-12 flex items-center justify-center cursor-pointer">
									<label htmlFor="file-input">
										<FaPaperclip className="attachment"/>
									</label>
									<input onChange={handleFileUpload} id="file-input" type="file" className="opacity-0 absolute inset-0 cursor-pointer"/>
                                </div> */}
								<Buttonlink
									prefix={<FaPaperPlane />}
									onClick={sendMessage}
									className="flex items-center justify-center fs-15 w-14 h-12 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white ml-3"
								/>
							</div>
						</div>
					</div>
				</div>
			</Dialog >
		</div >
	);
}

const mapStateToProps = ({ Auth }) => {
	return {
		socket: Auth.socket,
	};
};

export default connect(mapStateToProps, null)(ChatPopup);
