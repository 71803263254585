import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { Buttonlink } from "../index";
import client from "../../graphql/client";
import GET_ALL_COMPANIES from "../../graphql/queries/getAllComapnies";
import JOIN_COMPANY_REQUEST from "../../graphql/mutations/joinCompanyRequestMutation";
import { connect } from "react-redux";

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

function CompanySearch(props) {
  const [open, setOpen] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [companies, setCompanies] = React.useState([]);
  const [text, setText] = React.useState("");
  const [selectedId, setSelectedId] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  let variables = {};

  React.useEffect(() => {
    get_companies();
  }, []);
  React.useEffect(() => {
    if (text === "") {
      setDisabled(true);
    }
  }, [text]);
  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }
    setOptions(companies);
    // (async () => {
    //   await sleep(1e3); // For demo purposes.
    //   if (active) {
    //     setOptions(companies);
    //   }
    // })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
    get_companies();
  }, [open]);
  const get_companies = () => {
    /*        if (text.length > 0){
            variables.slug = '%'+text+'%'
        }*/
    client
      .query({
        query: GET_ALL_COMPANIES,
        variables,
      })
      .then((result) => {
        setCompanies(result.data.nonAdminCompanies);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const sendJoingRequest = () => {
    client
      .mutate({
        mutation: JOIN_COMPANY_REQUEST,
        variables: {
          title: text,
          company_id: selectedId,
        },
      })
      .then((res) => {
        if (props.socket?.connected) {
          props?.socket?.emit("notify", "Notification data");
        }
        setDisabled(false);
        props.message(res.data.joinCompanyRequest?.message, "success");
        props.closePopup();
        // console.log("Add Address Response", res);
      })
      .catch((errors) => {
        props.message("Something Went Wrong, Team was deleted by the owner", "error");
        setDisabled(false);
        console.log("Add Address Error", errors);
      });
  };
  return (
    <>
      <Autocomplete
        id="asynchronous-demo"
        open={open}
        size="small"
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        aria-required={true}
        isOptionEqualToValue={(option, value) => option.title === value.title}
        getOptionLabel={(option) => option.title}
        onInputChange={(e, v) => {
          setText(v)
        }}
        onChange={(event, newValue) => {
          if (newValue) {
            setSelectedId(newValue.id);
            setDisabled(false)// Update the selectedId state
          }
        }}
        options={companies}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search Company"
            //onChange={(value)=> setText(value.target.value)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      <div className="h-5" />
      <div className="flex items-end justify-end intro-y">
        <Buttonlink
          onClick={() => {
            setDisabled(true);
            sendJoingRequest();

            //props.closePopup();
          }}
          text="Send Request"
          className={`px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 white hover:text-white whitespace-nowrap ${disabled
            ? "pointer-events-none cursor-none bg--gray"
            : "bg--primary "
            }`}
          disabled={disabled}
        />
      </div>
    </>
  );
}
const mapStateToProps = ({ Auth }) => {
  return {
    socket: Auth.socket,
  };
};

export default connect(mapStateToProps, null)(CompanySearch);
