import { Buttonlink } from "components";
import React, { Component } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

class Disclaimer extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="container mt-10">
        <div className="box xl:py-10 xl:px-20 px-10 py-10">
          <div className="flex lg:items-center lg:justify-center justify-between flex-col lg:flex-row relative mb-20">
            <Buttonlink
              text="Back"
              prefix={<IoIosArrowBack size={16} />}
              onClick={() => this.props.navigate(-1)}
              className="relative bg-gray-200 hover:bg-gray-300 fs-13 flex items-center justify-center gap-x-1 h-9 rounded-full darkGray w-24 hover:opacity-80 border-0 shadow-none"
            />
            <h1 className="Medium text-4xl text-center lg:my-0 my-5 flex-grow">
              Disclaimer
            </h1>
            <Buttonlink
              text="Terms of Service"
              suffix={<IoIosArrowForward />}
              to="/term-of-service"
              className="flex items-center justify-center fs-15 primary hover:primary Regular rounded--xl border-0 shadow-none "
            />
          </div>

          <p className="mb-4">
            The use of the Website is at user’s sole risk. The services are
            provided to you on “IS AS” basis without warranty of any kind either
            express or implied. Without limiting the foregoing,{" "}
            <Link to="/" className="primary">
              Sourcinggenie.io
            </Link>{" "}
            Inc. and its partners disclaim any warranties, express or implied,
            of merchantability, fitness for a particular purpose, or
            non-infringement.{" "}
            <Link to="/" className="primary">
              Sourcinggenie.io
            </Link>{" "}
            Inc. does not warrant that the services provided by the Website will
            be uninterrupted or error free or that the Website or the server
            that makes it available are free from viruses or other harmful
            components. Further,{" "}
            <Link to="/" className="primary">
              Sourcinggenie.io
            </Link>{" "}
            Inc. would not be held responsible for non-availability of the
            Website that may occur during any periodic maintenance or technical
            issue beyond the control of{" "}
            <Link to="/" className="primary">
              Sourcinggenie.io
            </Link>{" "}
            Inc., its partners and vendors. We are not responsible for any
            technical failure of internet or the Website or any damage or injury
            caused to the user or their device as a result of or relating to
            their use of the Website. Furthermore,{" "}
            <Link to="/" className="primary">
              Sourcinggenie.io
            </Link>{" "}
            Inc. does not warrant or make any representation regarding the use
            or results of use of the Website in terms of their correctness,
            accuracy, and reliability or otherwise.
          </p>
          <p className="mb-4">
            User is solely responsible for all the user contents uploaded,
            posted or submitted by the user.{" "}
            <Link to="/" className="primary">
              Sourcinggenie.io
            </Link>{" "}
            Inc. is not in a position to monitor, filter or control the user
            content posted by the user to the Website. Any use of or reliance on
            any content/information or materials posted or obtained from the
            Website that is submitted by users is at your own risk. We do not
            endorse, support, represent or guarantee the completeness,
            truthfulness, accuracy, or reliability of any Content or
            communications posted via the Service or endorse any opinions
            expressed via the Service.
          </p>
          <p className="mb-4">
            There may be some instances, where users are likely to encounter
            contents which are offensive, indecent or objectionable to some
            person (the contents may or may not be identified as such). User
            agrees to use the site or such content at its sole risk and{" "}
            <Link to="/" className="primary">
              Sourcinggenie.io
            </Link>{" "}
            Inc. shall not be held liable for the contents that appear
            objectionable or indecent to you.
          </p>
          <p className="mb-4">
            The user content submitted to the Website are not treated as
            confidential and is publicly available which can been seen and
            accessed by the other users of the Website.{" "}
            <Link to="/" className="primary">
              Sourcinggenie.io
            </Link>{" "}
            Inc. will be not held liable if user content submitted to the
            Website is copied, used or modified in any manner whatsoever, by any
            other user.
          </p>
          <p className="mb-4">
            Under no circumstances will{" "}
            <Link to="/" className="primary">
              Sourcinggenie.io
            </Link>{" "}
            Inc. be liable in any way for any Content, including, but not
            limited to, any errors or omissions in any Content, or any loss or
            damage of any kind incurred as a result of the use of any Content
            made available via the Service or broadcast elsewhere.
          </p>
        </div>
      </div>
    );
  }
}

export default Disclaimer;
