import React, { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import MyOrders from "./MyOrders";
import OrdersDeliveries from "./OrderDeliveries";
import OrdersPayment from "./Payments";
import { gql, useQuery } from "@apollo/client";
import PriceRangeSlider from "./PriceRangeSlider";
import OrderDateRange from "./OrderDateRange";
import {Loader} from "../../../../components";

const GET_OFFER_ORDER_TRACK = gql`
  query GetOfferOrderTrack(
    $userId: ID!
    $priceFrom: String!
    $priceTo: String!
    $dateFrom: Date!
    $dateTo: Date!
    $title:String
  ) {
    getOfferOrderTrack(
      user_id: $userId
      pricefrom: $priceFrom
      priceto: $priceTo
      datefrom: $dateFrom
      dateto: $dateTo
      title: $title
    ) {
      id
      buyer
      seller
      title
      lastdate
      total
      status_1
      status_2
      status_3
      status_4
      status_5
      status_6
      status_7
      status_8
      status_9
      status_10
      status_11
      status_12
    }
  }
`;

const Orders = ({ authUser, dateRange, priceRange,copiedSearch }) => {

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [selectedIndex, setSelectedIndex] = useState(0);
  const array = [];
  const variables = {
    userId: parseInt(authUser.id),
    priceFrom: priceRange[0],
    priceTo: priceRange[1],
    dateFrom: dateRange[0],
    dateTo: dateRange[1],
    title:copiedSearch
  };

  const { loading, error, data } = useQuery(GET_OFFER_ORDER_TRACK, {
    variables,
  });

  if (loading) return <Loader/>;

  if (error) {
    console.error(error); // Handle error state if needed
    return <p>{error.message}</p>;
  }

  if (data) {
    // console.log("!st Data", data);
    // console.log("variables", variables);
    const statusArray = [];
    for (let i = 1; i <= 12; i++) {
      statusArray.push(`status_${i}`);
    }
    // console.log("status_array", statusArray);

    statusArray?.forEach((statusKey, index) => {
      const statuses = data.getOfferOrderTrack.filter((o) => o[statusKey]);
      const innerArray = statuses.map((statusData) => ({
        offer_id: statusData.id,
        title: statusData.title,
        total: statusData.total,
        [statusKey]: statuses.length,
      }));

      array.push(innerArray);
    });

    // console.log("arrayyyyy", array);
    return (
      <>
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
          <div className="flex">
            <div className="w-1/4 bg--lightPrimary">
              <Tab.List className={"flex flex-col gap-5 py-10"}>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "w-full lg:py-2.5 py-4 fs-15 leading-5 Regular hover:bg--primary hover:white",
                      selected ? "bg--primary white" : ""
                    )
                  }
                >
                  Orders
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "w-full lg:py-2.5 py-4 fs-15 leading-5 Regular hover:bg--primary hover:white",
                      selected ? "bg--primary white" : ""
                    )
                  }
                >
                  Order Deliveries
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "w-full lg:py-2.5 py-4 fs-15 leading-5 Regular hover:bg--primary hover:white",
                      selected ? "bg--primary white" : ""
                    )
                  }
                >
                  Payments
                </Tab>
              </Tab.List>
            </div>

            <div className="w-3/4">
              <Tab.Panels>
                <Tab.Panel className="bg-transparent rounded-xl">
                  <MyOrders data={array} />
                </Tab.Panel>
                <Tab.Panel className="bg-transparent rounded-xl">
                  <OrdersDeliveries data={array} />
                </Tab.Panel>
                <Tab.Panel className="bg-transparent rounded-xl">
                  <OrdersPayment data={array} />
                </Tab.Panel>
              </Tab.Panels>
            </div>
          </div>
        </Tab.Group>
      </>
    );
  }
};

export default Orders;
