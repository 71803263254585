import React, { Fragment, useState } from "react";
import { Buttonlink } from "components";
import { FiChevronsRight, FiMessageCircle } from "react-icons/fi";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { IoIosArrowDown } from "react-icons/io";
import { HiOutlineSearch } from "react-icons/hi";
import { FiMinus } from "react-icons/fi";
import "./style.css";
import { Popover, Transition } from "@headlessui/react";
import { gql, useQuery } from "@apollo/client";
import { Loader } from "../index";
import { connect } from "react-redux";
import { FaFilter } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

const GET_FAQS = gql`
  query FAQS {
    faqs {
      id
      title
      description
    }
  }
`;

function ChatBox(props) {
  const { loading, error, data } = useQuery(GET_FAQS);

  const [expanded, setExpanded] = React.useState("");
  const [search, setsearch] = React.useState("");
  const [tabname, settabname] = useState("");
  const [searchInBtns, setsearchInBtns] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  let navigate = useNavigate();
  const handleIncludes = (value) => {
    settabname(value);
  };

  function moveToAnotherScreen() {
    if (search.length > 0 || tabname.length > 0) {
      navigate("/quick-help-detail", {
        state: { tabname, search },
        replace: true,
      });
    } else {
      navigate("/quick-help-detail");
    }
  }

  const reset = () => {
    setsearch("");
    settabname("");
  };

  const searchBtnHandle = () => {
    setsearchInBtns(!searchInBtns);
  };

  if (loading) return <Loader spinner={true} />;
  if (error) return `Error! ${error.message}`;
  const user = props.authUser;
  // console.log("search", search);
  // console.log("tabname", tabname);
  return (
    <div
      className={`chatbot z-[9999] ${
        window.location.pathname === "/quick-help-detail" ? "hidden" : ""
      } `}
    >
      <Popover className="relative">
        <Popover.Button className="animate-bounce inline-block chatbot">
          <div className="w-16 h-16 bg--primary relative rounded-full flex items-center justify-center text-white">
            <FiMessageCircle size={28} />
          </div>
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-6"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-6"
        >
          <Popover.Panel className="absolute bottom-0 mb-[75px] right-0 transform w-screen sm:max-w-md max-w-xs sm:px-0">
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white overflow-y-auto p-6 h-[500px]">
              <div className="mb-5 flex items-center justify-between">
                <p className="fs-20 text-black Medium">Quick help</p>
                <Popover.Button>
                  <FiMinus size={18} />
                </Popover.Button>
              </div>
              <div className="mt-8">
                <div className="flex items-center">
                  <div className="flex-grow bg--lightGray rounded-l-xl flex items-center h-11 pl-3 pr-1.5">
                    <HiOutlineSearch className="darkGray" />
                    <input
                      placeholder="Search"
                      className="bg-transparent h-full w-full pl-3"
                      value={search}
                      onChange={(e) => setsearch(e.target.value)}
                    />
                    <div
                      onClick={searchBtnHandle}
                      className={`${
                        searchInBtns ? "bg--primary" : "bg-gray-300"
                      } flex items-center justify-center w-10 h-8 rounded-lg cursor-pointer`}
                    >
                      <FaFilter
                        className={`${searchInBtns ? "white" : "black"}`}
                        size={11}
                      />
                    </div>
                  </div>
                  <div>
                    <Buttonlink
                      prefix={<HiOutlineSearch className="white" />}
                      onClick={moveToAnotherScreen}
                      text="Search"
                      title="Search"
                      className="px-2 h-11 fs-13 leading-5 Regular hover:bg-[#e0482d] bg--primary white hover:white rounded-r-xl flex items-center gap-x-1"
                    />
                  </div>
                </div>
              </div>
              {searchInBtns ? (
                <>
                  <div className="selected-tags flex items-center justify-center mt-6 mx-auto rounded-lg overflow-hidden">
                    <div className="relative flex-grow">
                      <input
                        type="radio"
                        className="z-0 absolute left-0 invisible"
                        id="1"
                        name={"filter"}
                        checked={tabname === "category"}
                        value={"category"}
                        onChange={(e) => handleIncludes(e.target.value)}
                      />
                      <label
                        htmlFor="1"
                        className="bg-gray-200 w-full hover:opacity-70 text-center py-2 cursor-pointer inline-block"
                      >
                        <p className="fs-13">Category & Tags</p>
                      </label>
                    </div>
                    <div className="relative flex-grow">
                      <input
                        type="radio"
                        className="z-0 absolute left-0 invisible"
                        id="2"
                        name={"filter"}
                        checked={tabname === "rfq"}
                        value={"rfq"}
                        onChange={(e) => handleIncludes(e.target.value)}
                      />
                      <label
                        htmlFor="2"
                        className="bg-gray-200 w-full hover:opacity-70 text-center py-2 cursor-pointer inline-block"
                      >
                        <p className="fs-13">Rfqs</p>
                      </label>
                    </div>
                    <div className="relative flex-grow">
                      <input
                        type="radio"
                        className="z-0 absolute left-0 invisible"
                        name={"filter"}
                        id="3"
                        checked={tabname === "user"}
                        value={"user"}
                        onChange={(e) => handleIncludes(e.target.value)}
                      />
                      <label
                        htmlFor="3"
                        className="bg-gray-200 w-full hover:opacity-70 text-center py-2 cursor-pointer inline-block"
                      >
                        <p className="fs-13">Supplier</p>
                      </label>
                    </div>
                    <div className="relative flex-grow">
                      <input
                        type="radio"
                        className="z-0 absolute left-0 invisible"
                        name={"filter"}
                        checked={tabname === "faq"}
                        id="4"
                        value={"faq"}
                        onChange={(e) => handleIncludes(e.target.value)}
                      />
                      <label
                        htmlFor="4"
                        className="bg-gray-200 w-full hover:opacity-70 text-center py-2 cursor-pointer inline-block"
                      >
                        <p className="fs-13">Faq's</p>
                      </label>
                    </div>
                  </div>
                  <div className="flex items-center justify-between mb-6">
                    <p className="fs-10 gray">
                      *select & search in specific options
                    </p>
                    <p
                      className="primary fs-12 cursor-pointer flex justify-end"
                      onClick={reset}
                    >
                      clear all
                    </p>
                  </div>
                </>
              ) : (
                <div className="flex bg--primary border--primary items-center justify-center my-6 mx-auto rounded-lg shadow-md overflow-hidden">
                  <Buttonlink
                    to={"/contact-us"}
                    text="Help Desk"
                    title="Help Desk"
                    className="px-3 flex-grow py-2.5 fs-13 leading-5 Regular hover:bg-[#e0482d] bg--primary white hover:white text-center w-full sm:w-auto"
                  />
                  <Buttonlink
                    to={"/categories"}
                    text="Category & Tags"
                    title="Category"
                    className="px-3 flex-grow py-2.5 fs-13 leading-5 Regular hover:bg-[#e0482d] bg--primary white hover:white text-center w-full sm:w-auto"
                  />
                  {/*<Buttonlink*/}
                  {/*  to={'/categories'}*/}
                  {/*  text="Tags"*/}
                  {/*  title="Tags"*/}
                  {/*  className="px-3 py-2.5 fs-13 leading-5 Regular hover:bg-[#e0482d] bg--primary white hover:white text-center w-full sm:w-auto"*/}
                  {/*/>*/}
                  <Buttonlink
                    to={"/rfq/1"}
                    state={{ cardId: 1 }}
                    text="Rfqs"
                    title="Rfqs"
                    className="px-3 flex-grow py-2.5 fs-13 leading-5 Regular hover:bg-[#e0482d] bg--primary white hover:white text-center w-full sm:w-auto"
                  />
                  {user && !user.is_seller && (
                    <Buttonlink
                      to={"/preferred-vendor"}
                      text="Supplier"
                      title="Supplier"
                      className="px-3 flex-grow py-2.5 fs-13 leading-5 Regular hover:bg-[#e0482d] bg--primary white hover:white text-center w-full sm:w-auto"
                    />
                  )}
                </div>
              )}
              <div className="quick-help">
                {search === ""
                  ? data.faqs.map((faq) => (
                      <Accordion
                        className="shadow--none"
                        key={faq.id}
                        expanded={expanded === `panel${faq.id}`}
                        onChange={handleChange(`panel${faq.id}`)}
                      >
                        <AccordionSummary expandIcon={<IoIosArrowDown />}>
                          <Typography>{faq.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="bg-white">
                          <Typography>{faq.description}</Typography>
                        </AccordionDetails>
                      </Accordion>
                    ))
                  : data.faqs
                      .filter((o) => {
                        if (
                          o.title.toLowerCase().includes(search.toLowerCase())
                        ) {
                          return o;
                        }
                      })
                      .map((faq) => (
                        <Accordion
                          className="shadow--none"
                          key={faq.id}
                          expanded={expanded === `panel${faq.id}`}
                          onChange={handleChange(`panel${faq.id}`)}
                        >
                          <AccordionSummary expandIcon={<IoIosArrowDown />}>
                            <Typography>{faq.title}</Typography>
                          </AccordionSummary>
                          <AccordionDetails className="bg-white">
                            <Typography>{faq.description}</Typography>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                <Popover.Button
                  className={
                    "flex justify-end items-center mt-2 w-full cursor-default"
                  }
                >
                  <Link
                    className="primary fs-12 cursor-pointer flex justify-end items-center mt-2"
                    to="/quick-help-detail"
                  >
                    see more
                    <FiChevronsRight />
                  </Link>
                </Popover.Button>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
}

const mapStateToProps = ({ Auth }) => {
  return {
    authUser: Auth.authUserInformation,
  };
};
export default connect(mapStateToProps, null)(ChatBox);
