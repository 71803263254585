import React, { useState } from "react";
import Slider from "@mui/material/Slider";
const PriceRangeSlider = ({ onSubmit, price }) => {
    const [pricee, setPricee] = useState([0, 5000]);
    const handlePriceRangeChange = (event, newValue) => {
        onSubmit(newValue); // Pass the new value directly to onSubmit
    };

    return (
        <div className="w-full flex flex-col items-center">
            <label htmlFor="price-range-slider" className="mb-2">
                Select Price Range
            </label>
            <div className="w-3/4">
                <Slider
                    id="price-range-slider"
                    value={price}
                    style={{ color: "#ef553b" }}
                    onChange={handlePriceRangeChange}
                    valueLabelDisplay="on"
                    min={0}
                    max={5000}
                />
            </div>
        </div>
    );
};

export default PriceRangeSlider;
