import React, { useState, useCallback, useEffect } from "react";
import { Tab } from "@headlessui/react";
import WorkSpaceData from "./Components/WorkSpaceData";
import Orders from "./Orders/Orders";
import PriceRangeSlider from "./Orders/PriceRangeSlider";
import OrderDateRange from "./Orders/OrderDateRange";
import { Buttonlink } from "../../../components";
import moment from "moment";
import { FaFilter } from "react-icons/fa";

const MyWorkSpace = ({ authUser }) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [price, setPrice] = useState([0, 5000]);
  const [search, setSearch] = useState("");
  const [copiedSearch, setCopiedSearch] = useState("");
  const [selectedPrice, setSelectedPrice] = useState([0, 5000]);
  const [date, setDate] = useState("");
  const [toggleFilters, setToggleFilters] = useState(false);

  const fromDate = moment().subtract(6, "month").format("YYYY-MM-DD");
  const toDate = moment().add(6, "month").format("YYYY-MM-DD");

  const [dateCopyed, setDateCopyed] = useState([fromDate, toDate]);

  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    document.title = "DoBuying | SourcingGenie";
    setPrice([0, 5000]);
    setSearch("");
  }, [selectedIndex]);

  const handlePriceRangeSubmit = useCallback((priceRange) => {
    // console.log("priceRange", priceRange);
    setPrice(priceRange);
  }, []);

  const handleDateRangeSubmit = useCallback((dateRange) => {
    setDate(dateRange);
  }, []);

  const handleSearchClick = () => {
    setSelectedPrice(price);
    setDateCopyed(date.split(","));
    setCopiedSearch(search);
    // console.log("POOOO", dateCopyed, selectedPrice, copiedSearch);
  };
  // const handleSearchChange = (event) => {
  //   setSearch(event.target.value);
  //   console.log("jjjjjj", search);
  // };
  const commonElements = (
    <div className="w-full intro-y inline-grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mb-5 items-center gap-10">
      <input
        type="text"
        placeholder="Search..."
        className="appearance-none rounded-md relative block w-full pr-20 px-3 py-3 border border-gray-200 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <PriceRangeSlider
        selectedIndex={selectedIndex}
        onSubmit={handlePriceRangeSubmit}
        price={price}
      />
      <OrderDateRange
        onSubmit={handleDateRangeSubmit}
        fromDate={dateCopyed[0]}
        toDate={dateCopyed[1]}
      />
      <Buttonlink
        text="Search"
        onClick={handleSearchClick}
        className="bg--primary text-white hover:text-white hover:opacity-80 flex items-center justify-center h-12 w-[200px] white rounded-full text-[15px]"
      />
    </div>
  );

  return (
    <>
      <div className="container mt-20 intro-y">
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
          <Tab.List className="z-full lg:flex relative lg:p-2 md:p-3 p-4 lg:h-16 lg:space-y-0 space-y-3 lg:space-x-3 bg-gray-300 rounded-xl">
            <Tab
              className={({ selected }) =>
                classNames(
                  "w-full lg:py-2.5 py-4 fs-15 leading-5 Regular rounded-lg shadow-md hover:bg--primary hover:white",
                  selected ? "bg--primary white" : "bg-white"
                )
              }
            >
              My Workspace
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  "w-full lg:py-2.5 py-4 fs-15 leading-5 Regular rounded-lg shadow-md hover:bg--primary hover:white",
                  selected ? "bg--primary white" : "bg-white"
                )
              }
            >
              Spend Analytics
            </Tab>
            {/*<Tab
              className={({ selected }) =>
                classNames(
                  "w-full lg:py-2.5 py-4 fs-15 leading-5 Regular rounded-lg shadow-md hover:bg--primary hover:white",
                  selected ? "bg--primary white" : "bg-white"
                )
              }
            >
              Message
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  "w-full lg:py-2.5 py-4 fs-15 leading-5 Regular rounded-lg shadow-md hover:bg--primary hover:white",
                  selected ? "bg--primary white" : "bg-white"
                )
              }
            >
              Notification
            </Tab>*/}
          </Tab.List>
          <Tab.Panels className="mt-2">
            <Tab.Panel className="bg-transparent rounded-xl">
              <div className="intro-y flex items-center justify-end mb-3">
                <h2 className="text-lg Medium truncate mr-3">Filters:</h2>
                <div
                  onClick={() => setToggleFilters(!toggleFilters)}
                  className="bg--primary h-12 w-12 flex items-center justify-center rounded-full cursor-pointer"
                >
                  <FaFilter className="white" />
                </div>
              </div>
              {toggleFilters && commonElements}
              <WorkSpaceData
                authUser={authUser}
                dateRange={dateCopyed}
                my_price={selectedPrice}
                copiedSearch={copiedSearch}
              />
            </Tab.Panel>
            <Tab.Panel className="bg-transparent rounded-xl">
              <div className="intro-y flex items-center justify-end mb-3">
                <h2 className="text-lg Medium truncate mr-3">Filters:</h2>
                <div
                  onClick={() => setToggleFilters(!toggleFilters)}
                  className="bg--primary h-12 w-12 flex items-center justify-center rounded-full cursor-pointer"
                >
                  <FaFilter className="white" />
                </div>
              </div>
              {toggleFilters && commonElements}
              <Orders
                selectedIndex={selectedIndex}
                authUser={authUser}
                priceRange={selectedPrice}
                dateRange={dateCopyed}
                copiedSearch={copiedSearch}
              />
            </Tab.Panel>
            {/*<Tab.Panel className="bg-transparent rounded-xl">
              {commonElements}
              <WorkSpaceData selectedIndex={selectedIndex} />
            </Tab.Panel>
            <Tab.Panel className="bg-transparent rounded-xl">
              {commonElements}
              <WorkSpaceData selectedIndex={selectedIndex} />
            </Tab.Panel>*/}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </>
  );
};

export default MyWorkSpace;
