import { gql } from "@apollo/client";

export const CREATE_OR_UPDATE_RFQ = gql`
  mutation createOffer(
    $offer_id: Int
    $menu_id: Int
    $products: [Object]
    $company: ID
    $status: String
    $is_public : Boolean
    $fields: Object
  ) {
    createOffer(
      offer_id: $offer_id
      menu_id: $menu_id
      products: $products
      company: $company
      status: $status
      is_public:$is_public
      fields: $fields
    ) {
      id
      title
      description
      last_date
      is_public
      reference_number
      quantity
      products{
            id
            offer_id
            quantity
            uom
            title
            description
            last_date
            status
            created_at
          }
      offerHasRoles
      executives {
        id
        firstname
        lastname
        email
        username
        profile_photo
        userCompanyOfferRole(company_id: $company, offer_id: $offer_id)
      }
    }
  }
`;
