import { AllRFQ } from "..";
import { useLocation, useParams } from "react-router-dom";

export default function AllRFQScreen(props) {
  const { state } = useLocation();
  if (state && state.path === "quoteSubmitted") {
    window.history.forward();
  }
  const params = useParams();
  document.title = "MyRFQs | SourcingGenie";
  return (
    <AllRFQ
      cardId={state?.cardId ? parseInt(state?.cardId) : props?.authUser?.is_seller ? 1 : 0}
      authUser={props.authUser}
      params={params}
    />
  );
}
