import { gql } from "@apollo/client";

export default gql`
    mutation offerParticipantCancel($id: ID!) {
        offerParticipantCancel(id: $id) {
            id
            is_approve
        }
    }
`;
