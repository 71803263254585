import React from "react";
import { Link } from "react-router-dom";

export default function Buttonlink(props) {
  const {
    className,
    prefix,
    text,
    to,
    onClick,
    suffix,
    href,
    disabled,
    title,
    rel,
    state,
  } = props;
  return (
    <>
      {to && (
        <Link
          to={to}
          state={state}
          className={className}
          title={title}
          rel={rel}>
          {prefix}
          {text}
          {suffix}
        </Link>
      )}
      {onClick && (
        <button onClick={onClick} disabled={disabled} className={className}>
          {prefix}
          {text}
          {suffix}
        </button>
      )}
      {href && (
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className={className}>
          {prefix}
          {text}
          {suffix}
        </a>
      )}
    </>
  );
}
