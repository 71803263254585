import {gql} from "@apollo/client";

export default gql`
    mutation autoLoginByEmailAddress(
        $token: String!
        $role:String!
        $id:ID
    ) {
        autoLoginByEmailAddress(
            token:$token
            role:$role
            id:$id
        ) {
            token
            error
            message
            user{
                id
                username
                firstname
                lastname
                phone_number
                is_verified
                profile_photo
                plan_expire_date
                plan_id
                is_plan_expired
                plan_status
                role_allowed
                tagline
                plan_id
                plan_expire_date
                email
                timestamp
                is_seller
                become_seller
                isSellerProfileCompleted
            }
            expiry
        }
    }
`;
