import React, { Fragment, PureComponent } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Getquotation } from "components";
import Bannergif from '../../../assets/images/banner-loader.gif';
import banner_img from '../../../assets/images/banner_gif.gif'
import client from "graphql/client";
import GET_MENU from "../../../graphql/queries/mro/menu";

class Homebanner extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      menu: {},
      btnmark: false,
      rfqclick: props.open
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    this.fetchMenuData();
  }

  fetchMenuData = () => {
    if (this.state.rfqclick) {
      setTimeout(() => {
        this.setState({ btnmark: true });
      }, 1000);

      setTimeout(() => {
        this.setState({ btnmark: false });
        this.myRef.current.click();
      }, 2000);
    }
  };

  render() {
    return (
      <div className="home-banner">
        <div className="overlay:bg--gray-600 h-full relative overflow-hidden">
          <div className="container h-full py-20">
            <div className="xl:grid xl:grid-cols-5 gap-4 h-full">
              <div className="xl:col-span-3 space-y-3 h-full">
                {/* <h4 className="Regular black lg:text-xl text-xl opacity-0">
                  <span className="mr-4 before:block  before:absolute before:-inset-1 before:skew-y-12 before:bg-pink-500 relative inline-block">
                    <div className="relative text-blackitalic text-xl Light rotateZ white">
                      Sourcing Genie
                    </div>
                  </span>
                  Request for Quotation
                </h4> */}
                <h1 className="black 2xl:text-8xl  xl:text-7xl sm:text-6xl text-4xl intro-y pt-11">
                  Make sourcing easy with RFQ
                </h1>
                {/* <h4 className="Regular black lg:text-3xl text-xl intro-y">
                  Access suppliers during Republic Day
                </h4> */}
                <div className="h-14" />
                {this.props.is_seller ? (
                  false
                ) : (
                  <div>
                    <Popover.Group className="hidden lg:flex items-center justify-between relative xl:top-[30px] xl:left-[90px]">
                      <Popover
                        className={`relative h-full flex mark-area ${this.state.btnmark ? "mark-area-hover" : ""
                          }`}
                      >
                        <Popover.Button
                          ref={this.myRef}
                          // onClick={this.checkClick}
                          className="bg--primary xl:h-16 h-12 px-20 white rounded-full xl:text-2xl text-lg"
                        >
                          Create an RFQ
                        </Popover.Button>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-300"
                          enterFrom="opacity-0 translate-y-0"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-250"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-0"
                        >
                          <Popover.Panel className="absolute z-2xlfull left-full bottom-0 transform w-screen max-w-lg sm:px-0">
                            <div className="p-6 rounded-lg shadow-lg bg-white relative ring-1 ring-black ring-opacity-5 overflow-hidden">
                              <h2 className="Medium text-2xl mb-4">
                                How can we help you today?
                              </h2>
                              <Getquotation rfqclick={this.state.rfqclick} />
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </Popover>
                    </Popover.Group>
                  </div>
                )}
              </div>
            </div>
          </div>
          <img src={banner_img} alt="banner_img" width="380" className="absolute 2xl:top-12 2xl:left-1/2 xl:top-12 xl:left-1/2 lg:top-0 lg:left-1/2 md:top-0 md:left-1/4 top-0 left-0" />
          <img
            src={Bannergif}
            alt="avatar"
            width="380"
            className="banner-gif absolute right-0 bottom-10 xl:block hidden"
          />
        </div>
      </div>
    );
  }
}

export default Homebanner;

// const [menu, setMenu] = useState(null);

// useEffect(() => {
//   client
//     .query({
//       query: GET_MENU,
//     })
//     .then((result) => {
//       setMenu(result);
//     })
//     .catch((error) => {
//     });
// });
