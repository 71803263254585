import React, { Component } from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { IoCloseCircle, IoCloseCircleOutline } from "react-icons/io5";
import {
  Avatarimage,
  Buttonlink,
  Fullscreenpopup,
  Loader,
  ConfirmationDialog,
  SelectCountry,
} from "components";
import { Dropdown } from "semantic-ui-react";
import { Myteams } from "navigation";
import { FiPlus } from "react-icons/fi";
import { connect } from "react-redux";
import { BsAsterisk } from "react-icons/bs";
import { HiOutlineSearch } from "react-icons/hi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import client from "graphql/client";
import createCompanyMutation from "graphql/mutations/createCompanyMutation";
import axios from "axios";
import Profileavatar from "components/profileavatar";
import getExecutiveRoles from "graphql/queries/getExecutiveRoles";
import setExecutivesRole from "graphql/mutations/setExecutivesRole";
import myCompany from "../../graphql/queries/myCompany";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import GET_COMPANY from "../../graphql/queries/getCompany";
import checkCompanyExist from "../../graphql/queries/companyUniqueName";
import CompanySearch from "./companySearch";
// import GET_COMPANY from "../../../graphql/queries/getCompany";
import getAddressById from "../../graphql/queries/Address/getAddressById";
import { gql } from "@apollo/client";
import { extractValidationErrors } from "helpers/extractValidationErrors ";
import { checkPlan } from "helpers/checkPlan";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const GET_Countries = gql`
  query getCountries($searchText: String) {
    countries(first: 246, name: $searchText) {
      data {
        id
        sortname
        name
        phonecode
      }
    }
  }
`;

class TeamsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      addTeam: true,
      company_id: "",
      companyName: "",
      company: {},
      executive_roles: [],
      executives: [],
      logo: "",
      email: "",
      phoneNum: "",
      address: "",
      country: "",
      addState: false,
      city: "",
      postalCode: "",
      website: "",
      modalType: "",
      stateName: "",
      addRecrute: false,
      enabled: false,
      responibility: false,
      role: false,
      teamType: "",
      logoErr: false,
      companyNameErr: false,
      executivesErr: false,
      emailErr: false,
      phoneNumErr: false,
      addressErr: false,
      cityErr: false,
      stateErr: false,
      postalCodeErr: false,
      websiteErr: false,
      loading: false,
      companies: {},
      addMore: false,
      editCompany: false,
      updateRecode: true,
      value: 0,
      country_id: null,
      country_code: null,
      isRoleChanged: false,
      executiveRolesUpdate: [],
      showSave: 0,
      executiveRole: this.props?.executives?.length !== 0 ? this.props?.executives : this.props?.company?.executives,
      loader: false,
      disable: false,
    };
    // console.log('first');
    this.photoRef = React.createRef();
    this.selectFullPopup = React.createRef();
    this.OpenConfirmation = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    const { company, editCompany } = this.props;
    // console.log('company', company)
    if (company?.id && this.state.editCompany && prevState.updateRecode) {
      // const country = client.readQuery({
      //   query: GET_Countries,
      // });
      // console.log("country",country)
      this.setState({
        companyName: company.title,
        email: company.email,
        phoneNum: company.phone,
        address: company.address,
        city: company.city,
        //stateName: company.state && company.state.name,
        postalCode: company.postal_code,
        website: company.linked_in_profile,
        executives: company.executives,
        country_id: company.country_id,
        country_code: company.country_code,
        logo: company.logo,

        updateRecode: false,
      });
      this.props.selectMembers(company.executives);
      //console.log("company.state", company.state);

      this.props.selectState(company.state);
    }
  }

  componentDidMount() {
    // console.log('this.propsttttttttttt', this.props, this.state.executiveRole);
    if (this.state.executiveRole === undefined) {
      this.setState({ executiveRole: this.props?.copmany?.executives })
    }
    if (this.props.from === "post-rfq") {
      this.setState({ addTeam: true });
    } else {
      this.getCompaniesList();
    }
    this.getExecutive();
    this.getCompaniesRole();

  }

  getExecutive = () => {
    client
      .query({
        query: getExecutiveRoles,
        fetchPolicy: "network-only",
      })
      .then((res) => {
        // console.log("RES", res);
        this.setState({
          executive_roles: res.data.executive_roles,
        });
      })
      .catch((err) => {
        console.log("executives roles error", err);
      });
  };

  openPopupModal = () => {
    this.selectFullPopup.current.openPopup();
    this.setState({ modalType: "Add Members" });
  };

  openStatesModal = () => {
    this.selectFullPopup.current.openPopup();
    this.setState({ modalType: "Select State" });
  };

  OpenConfirmationDialog = (rfqType, rfqId, rfqVisibility) => {
    this.OpenConfirmation.current.openPopup(rfqType, rfqId, rfqVisibility);
  };

  callBack = (value) => {
    this.setState({
      currentStatus: value,
    });
  };

  openPopup = (params) => {
    //console.log("params", params);
    this.setState({
      teamType: params[0],
      open: true,
      editCompany: params[2] ?? false,
      addTeam: params[1],
      showSave: params[2] ?? 0,
    });
  };

  closePopup = () => {
    this.setState({
      open: false,
      editCompany: false,
      addTeam: false,
      companyName: "",
      email: "",
      phoneNum: "",
      address: "",
      city: "",
      stateName: "",
      postalCode: "",
      website: "",
      executives: "",
      logo: "",
      country_code: "",
      country_id: "",
      updateRecode: true,
      logoErr: false,
      companyNameErr: false,
      executivesErr: false,
      emailErr: false,
      phoneNumErr: false,
      addressErr: false,
      cityErr: false,
      stateErr: false,
      postalCodeErr: false,
      websiteErr: false,
      loading: false,
      offerHasRoles: false,
      executiveRole: null,
      executiveRolesUpdate: [],
      value: 0,
      disable: false,
      companyNameTimer: null,
    });
    this.props.selectMembers([]);
    this.props.selectState({});
  };

  addTeamClick = () => {
    this.setState({ addTeam: !this.state.addTeam });
  };

  addTeamNotAllowed = () => {
    this.notify("You don't have the Credits to perform this action, Please purchase a new Plan", "error");
    this.setState({ cannotAddTeam: true });
  };

  onPhotoClick = () => {
    this.photoRef.current.click();
  };

  onPhotoChange = async (e) => {
    let data = e.target.files[0];

    if (data) {
      const file = await this.handleUploadPhoto(data);
    }
  };

  createFormData = (photo, body) => {
    const data = new FormData();
    data.append("file", photo);
    Object.keys(body).forEach((key) => {
      data.append(key, body[key]);
    });

    return data;
  };

  handleUploadPhoto = async (file) => {
    axios({
      method: "POST",
      url: process.env.REACT_APP_MAIN_URL + "/api/attachment/upload",
      data: this.createFormData(file, { userId: "123" }),
    })
      .then((res) => {
        this.setState({ logo: res.data.path });
      })
      .catch((error) => { });
  };

  addCompany = () => {
    const {
      companyName,
      email,
      phoneNum,
      address,
      city,
      logo,
      postalCode,
      website,
      country_code,
      country_id,
    } = this.state;
    const { colloborators, _state, company } = this.props;

    let executives = [];
    let roles = [];
    colloborators?.length > 0 &&
      colloborators.forEach((element) => {
        executives.push(parseInt(element.id));
      });
    executives = executives.filter(
      (item) => item !== parseInt(this.props.authUser?.id)
    );

    for (let i = 0; i < executives?.length; i++) {
      roles.push(0);
    }

    if (logo) {
      this.setState({ logoErr: false });
    }
    if (companyName) {
      this.setState({ companyNameErr: false });
    }
    /*if (executives && executives.length > 0) {
      this.setState({ executivesErr: false });
    }*/
    if (email) {
      this.setState({ emailErr: false });
    }
    if (phoneNum) {
      this.setState({ phoneNumErr: false });
    }
    if (address) {
      this.setState({ addressErr: false });
    }
    if (city) {
      this.setState({ cityErr: false });
    }
    if (_state.name) {
      this.setState({ stateErr: false });
    }
    if (postalCode) {
      this.setState({ postalCodeErr: false });
    }
    if (website) {
      this.setState({ websiteErr: false });
    }

    //console.log("logo", country_id);
    if (this.state.companyNameErr) {
      return this.notify("Please assign a unique company name", "error");
    }
    if (
      companyName &&
      email &&
      phoneNum &&
      address &&
      city &&
      _state.name &&
      postalCode &&
      website
    ) {
      this.setState({ loading: true });
      client
        .mutate({
          mutation: createCompanyMutation,
          variables: {
            company_id: this.state.editCompany
              ? company.id
              : this.state.company_id,
            title: companyName,
            link: website,
            logo: logo,
            address: address,
            email: email,
            phone: phoneNum,
            city: city,
            zip_code: postalCode,
            state_id: _state.id,
            country_code: country_code,
            country_id: country_id,
            executives: executives,
            roles: roles,
          },
        })
        .then((res) => {
          if (this.props.socket?.connected) {
            this.props?.socket?.emit("notify", "Notification data");
          }
          // console.log("res",res)
          this.props.selectState({});
          this.props.selectMembers([]);
          this.setState({
            logo: "",
            companyName: "",
            email: "",
            phoneNum: "",
            address: "",
            city: "",
            postalCode: "",
            website: "",
            loading: false,
          });
          this.props.companyCreated("success");
          this.closePopup();
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.log("error...", err);
          extractValidationErrors(err).forEach((err) => this.notify(err, "error"));
          this.props.companyCreated("success");
          this.closePopup();
        });
    } else {
      // console.log("not okay");
      /*if (!logo) {
        this.setState({ logoErr: true });
      }*/
      if (!companyName) {
        this.setState({ companyNameErr: true });
      }
      /*if (executives && executives.length === 0) {
        this.setState({ executivesErr: true });
      }*/
      if (!email) {
        this.setState({ emailErr: true });
      }
      if (!phoneNum) {
        this.setState({ phoneNumErr: true });
      }
      if (!address) {
        this.setState({ addressErr: true });
      }
      if (!city) {
        this.setState({ cityErr: true });
      }
      if (!_state.name) {
        this.setState({ stateErr: true });
      }
      if (!postalCode) {
        this.setState({ postalCodeErr: true });
      }
      if (!website) {
        this.setState({ websiteErr: true });
      }

      this.OpenConfirmationDialog("unfilledForm", 1);
    }
  };

  notify = (message = "", type = "error") => {
    // Dismiss any existing toasts before showing a new one
    toast.dismiss();
    if (type === "error") {
      return toast.error(message, { position: "top-right" });
    }
    if (type === "success") {
      return toast.success(message, { position: "top-right" });
    }
    if (type === "warning") {
      return toast.warn(message, { position: "top-right" });
    }
  };

  newRecruiter = () => {
    this.setState({ addRecrute: !this.state.addRecrute });
  };

  addRecruiter = () => {
    this.setState({ role: true });
  };

  selectRole = (event, data, userId) => {
    let options = this.state.executive_roles.map((e, index) => ({
      key: index,
      text: e.name,
      value: e.id,
    }));
    this.state.executiveRolesUpdate?.forEach((currentValue, index, array) => {
      let foundOption = options.find((e) => currentValue.role_id == e.text);
      if (foundOption) {
        currentValue.role_id = foundOption.value;
      } else if (currentValue.role_id === "none") {
        currentValue.role_id = null;
      }

      if (currentValue.user_id === userId) {
        currentValue.role_id = data.value;
      }
    });

    this.setState({
      executiveRolesUpdate: [...this.state.executiveRolesUpdate],
      disable: false,
    });
  };

  getCompaniesList = (e) => {
    client
      .query({
        query: myCompany,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        this.setState({ companies: response.data.me.companies.data });
      })
      .catch((err) => { });
    if (e === "delete") {
      return this.notify("Team Removed Successfully", "success");
    }
    else if (e === "switch") {
      return this.notify("Team Switched Successfully", "success");
    }
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };
  handleSave = () => {
    this.setState({ disable: true });
    // console.log('first', this.state.executiveRolesUpdate);
    let validator = true;
    this.state.executiveRolesUpdate?.forEach((currentValue, index, array) => {
      if (currentValue.role_id == null || currentValue.role_id == "none") {
        validator = false;
      }
    });
    if (!validator) {
      return this.notify(
        "You must assign a role to every team member",
        "error"
      );

    } else {
      //this.props.clearCompany("Team roles has been set");
      client
        .mutate({
          mutation: setExecutivesRole,
          variables: {
            userRoles: this.state?.executiveRolesUpdate,
            company_id: this.props.company.id,
            // user_id: userId,
            // role_id: parseInt(data.value),
            offer_id: this.props.fields?.offer ?? this.props.offer_id,
          },
        })
        .then((res) => {
          // console.log('PPPPPPPPPPPPPPPPPP', res);
          this.notify("Team roles has been set", "success");
          this.setState({ loader: true })
          this.props.companyCreated("update");
          this.getCompaniesRole();
          this.setState({ offerHasRoles: true }, function () {
            this.props.offerHasRoles(this.state.offerHasRoles);
          });
          this.setState({ executiveRolesUpdate: [], executiveRole: null, disable: false });
          this.closePopup();
        })
        .catch((err) => {
          this.setState({ disable: true });
          extractValidationErrors(err).forEach((err) => this.notify(err, "error"));
          console.log("...error...", err);
        });
    }
  };
  getCompaniesRole() {
    // console.log('this.props.fields?.offer', this.props.fields?.offer, this.props.offer_id);
    if (this.props.form.company) {
      client
        .query({
          query: GET_COMPANY,
          variables: {
            id: this.props.form.company,
            offer_id: this.props.fields?.offer ? this.props.fields?.offer : this.props.offer_id,
          },
          fetchPolicy: "network-only",
        })
        .then((response) => {
          //console.log('JJJJJJJJJJ', response)
          this.setState({ executiveRole: response.data.company.executives })
          this.setState({ loader: false })
          // setExecutives(response.data.company.executives);
          // setEnabled(response.data.company);
        })
        .catch((err) => {
          console.log("error", err);
        });

    }

  }
  handleCompanyNameChange = (e) => {
    const { companyNameTimer } = this.state;
    const companyName = e.target.value.replace(/[^a-zA-Z ]/gi, '');
    this.setState({ companyName, companyNameErr: false, loading: true });

    // Clear the previous timer, if it exists
    if (companyNameTimer) {
      clearTimeout(companyNameTimer);
    }

    // Set a new timer to send the request after 2000 milliseconds (2 seconds)
    const newTimer = setTimeout(() => {
      //this.setState({ loading: true });
      client
        .query({
          query: checkCompanyExist,
          variables: {
            title: companyName,
            id: this.props.company?.id,
          },
          fetchPolicy: "network-only",
        })
        .then((response) => {
          this.setState({ companyNameErr: false, loading: false });
        })
        .catch((err) => {
          this.setState({ companyNameErr: true, loading: false });
        });
    }, 2000); // 2000 milliseconds (2 seconds)

    // Save the new timer in the state
    this.setState({ companyNameTimer: newTimer });
  }
  render() {
    const {
      open,
      modalType,
      teamType,
      logoErr,
      companyNameErr,
      executivesErr,
      emailErr,
      phoneNumErr,
      addressErr,
      cityErr,
      stateErr,
      postalCodeErr,
      websiteErr,
      companies,
      value,
      country_id,
      showSave,
    } = this.state;
    //console.log('country_id', country_id);

    let options = this.state.executive_roles.map((e, index) => ({
      key: index,
      text: e.name,
      value: e.id,
    }));
    if (this.props.company && this.props.company.executives) {
      const { executives } = this.props.company;
      const existingUserIds = this.state.executiveRolesUpdate?.map(
        (item) => item.user_id
      );

      this.state.executiveRole?.forEach((e) => {
        let foundOption = options.find(
          (opt) => e.offer_executive_user_roles ?
            opt.text === e.userCompanyOfferRole || opt.text === e.offer_executive_user_roles[0]?.name :
            opt.text === e.userCompanyOfferRole
        );
        let role_id = foundOption ? foundOption.value : null;
        if (!existingUserIds.includes(e.id)) {
          this.setState((prevState) => ({
            executiveRolesUpdate: [
              ...prevState.executiveRolesUpdate,
              {
                user_id: e.id,
                role_id: role_id,
                company_id: this.props.company.id,
                offer_id: this.props.fields?.offer ?? this.props.offer_id,
              },
            ],
          }));
        }
      });
    }
    const teamsAllowed = checkPlan("sum", "No. of Teams", this.props.authUser?.payments);
    let hitMaxLimit = teamsAllowed <= 0 ? true : false;
    // this.getCompaniesRole();
    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          scroll="body"
          open={open}
          onClose={this.closePopup}
          className="rounded--xl"
        >
          <div className="shadow-none bg-gray-100">
            <Toolbar className="flex items-center justify-between">
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.closePopup}
                aria-label="close"
              >
                <IoCloseCircleOutline size={32} className="primary" />
              </IconButton>
              <h3 className="black Medium text-xl">My Teams</h3>
              <div>
                {this.state.addTeam && value === 0 && (
                  <Buttonlink
                    disabled={this.state.loading}
                    onClick={
                      teamType === "Team work"
                        ? this.closePopup
                        : this.addCompany
                    }
                    prefix={
                      this.state.loading ? <Loader spinner={true} /> : false
                    }
                    text={
                      `${teamType} === "Team work"` && showSave === 1
                        ? "Close"
                        : "Save"
                    }
                    rel="nofollow"
                    className={`${this.state.loading
                      ? "bg-gray-100 hover:bg-gray-200"
                      : "bg-primary-100 hover:bg-primary-200"
                      } w-20 primary flex items-center justify-center py-3 rounded-xl`}
                  />
                )}
              </div>
            </Toolbar>
          </div>
          <Tabs
            value={value}
            onChange={this.handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label={`${teamType === "Team" ? "Create New Team" : "Assign Roles"
                }`}
              {...a11yProps(0)}
            />
            <Tab label="Attached with existing company" {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            {teamType === "Team work" ? (
              <>

                <div className="grid grid-cols-6 gap-4 mt-6">
                  <div className="sm:col-start-2 sm:col-span-4 col-span-6">
                    {/* <div className="bg--lightGray rounded-xl flex items-center h-11 px-3 mb-6">
                    <HiOutlineSearch className="darkGray" />
                    <input
                      placeholder="Enter to search and select"
                      className="bg-transparent h-full w-full pl-3"
                    />
                  </div> */}
                    {!this.state.loader && this.state?.executiveRole ? (
                      this.state.executiveRole?.map((e) => (
                        <div
                          className="flex items-center space-x-3 mb-4"
                          id={e.username}
                        >
                          <Profileavatar singleUser={e} />
                          <div>
                            <p className="Medium">{`${e.firstname} ${e.lastname}`}</p>
                          </div>
                          <div className="flex-grow flex items-end flex-col justify-center scroll_dropdown">
                            <Dropdown
                              placeholder={e.userCompanyOfferRole ?? e.offer_executive_user_roles[0].name}
                              options={options}
                              selection
                              upward={true}
                              className="z-full"
                              onChange={(event, data) => {
                                this.selectRole(event, data, e.id);
                                //this.state.isRoleChanged = true;
                              }}
                            />
                          </div>
                        </div>
                      ))
                    ) : (
                      <>
                        {this.getCompaniesRole()}
                        < Loader />
                      </>
                    )}


                  </div>
                </div>

                <div className="flex justify-center items-center">
                  <button
                    className="relative hover:white hover:opacity-80 flex justify-center py-2.5 px-4 border border-transparent rounded-lg primary bg--lightPrimary  hover:bg--primary focus:outline-none "
                    onClick={this.handleSave}
                    disabled={this.state.disable}
                  >
                    Save
                  </button>
                </div>
              </>
            ) : (
              teamType === "Team" && (
                <div className="p-6">
                  {this.props.from === "profile" && !this.state.addTeam && (
                    <>
                      <div className="grid grid-cols-6 gap-4">
                        <div className="sm:col-start-2 sm:col-span-4 col-span-6">
                          <h3 className="text-xl Medium">Choose Your Team</h3>
                          <p className="darkGray Light fs-13">
                            Your team will be able to see changes.
                          </p>
                          <div className="space-y-5 py-5">
                            <Myteams
                              from={"profile"}
                              id={2}
                              companyLoader={(v, x) => {
                                // this.companyChangeLoader(v, x);
                              }}
                              load_again={(val) =>
                                this.getCompaniesList(val)
                              }
                              addNewCompany={this.state.addMore}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center gap-x-5">
                        <div className="divider w-full" />
                        <p className="darkGray Light fs-12 whitespace-nowrap">
                          Add Your Team
                        </p>
                        <div className="divider w-full" />
                      </div>
                      <div className="h-5" />
                      <div className="items-center flex justify-center">
                        <Buttonlink
                          //disabled={hitMaxLimit}
                          onClick={(e) => {
                            this.getCompaniesList(e);
                            hitMaxLimit
                              ? this.addTeamNotAllowed()
                              : this.addTeamClick();
                          }}
                          prefix={<FiPlus size={22} />}
                          text={`${hitMaxLimit
                            ? "Add Team"
                            : "Add Team"
                            }`}
                          className="bg-gray-100 hover:bg-gray-200 w-62 primary flex items-center justify-center py-4 px-4 rounded-xl"
                        />
                      </div>
                    </>
                  )}
                  {this.state.addTeam && (
                    <div className="space-y-4">
                      <div className="grid grid-cols-2 gap-4">
                        <div className="col-span-2 sm:flex items-center justify-center gap-x-10 mb-10">
                          <div
                            className="h-40 w-40 rounded-full mx-auto relative cursor-pointer sm:mb-0 mb-8"
                            onClick={this.onPhotoClick}
                          >
                            <Avatarimage
                              imagesrc={
                                process.env.REACT_APP_MAIN_URL +
                                `/${this.state.logo}`
                              }
                              imagesize={{ width: 148, height: 148 }}
                              className={`shadow-xl border ${logoErr ? "border-red-500" : ""
                                }`}
                              iscompany="true"
                            />
                            <div
                              className={`bg--lightGray border ${logoErr ? "border-red-500" : ""
                                } shadow-xl w-10 h-10 rounded-full flex items-center justify-center absolute bottom-0 right-0 z-10`}
                            >
                              <FiPlus size={20} />
                            </div>
                          </div>
                          <div className="flex-grow">
                            <label
                              htmlFor="company-name"
                              className="Medium flex items-center gap-x-1"
                            >
                              Team Name
                              <BsAsterisk size={9} className="primary" />
                            </label>
                            <input
                              id="company-name"
                              name="Team Name"
                              type="text"
                              autoComplete="company-name"
                              required
                              className={`appearance-none rounded-md relative block w-full px-3 py-3 border ${companyNameErr
                                ? "border-red-500"
                                : "border-gray-300"
                                } placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm`}
                              placeholder="Enter team name"
                              value={this.state.companyName}
                              onChange={this.handleCompanyNameChange}
                              maxLength={50}
                            />
                            {companyNameErr && (
                              <div className="text-red-500">Team name is already taken</div>
                            )}
                          </div>
                        </div>


                        <div className="col-span-2 sm:col-span-1">
                          <label
                            htmlFor="email-address"
                            className="Medium flex items-center gap-x-1"
                          >
                            Members
                            <small>(optional)</small>
                            <small>(Max limit 5)</small>
                            {/*<BsAsterisk size={9} className="primary"/>*/}
                          </label>
                          <div className="items-center flex justify-center">
                            <Buttonlink
                              onClick={this.openPopupModal}
                              prefix={<FiPlus size={18} />}
                              text={
                                (this.props?.colloborators &&
                                  this.props.colloborators.length > 0) ||
                                  this.state.executives.length > 0
                                  ? "Edit Members"
                                  : "Add Members"
                              }
                              className={`appearance-none Light rounded-md relative primary w-full flex items-center justify-center px-3 py-2 border ${executivesErr
                                ? "border-red-500"
                                : "border-gray-300"
                                } placeholder-gray-500 focus:outline-none shadow-sm`}
                            />
                          </div>
                        </div>

                        <div className="col-span-2 sm:col-span-1">
                          <label
                            htmlFor="email-address"
                            className="Medium flex items-center gap-x-1"
                          >
                            Business Email
                            <BsAsterisk size={9} className="primary" />
                          </label>
                          <input
                            id="email-address"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            pattern=".+@globex\.com"
                            maxLength={64}
                            placeholder="Enter business email"
                            className={`appearance-none rounded-md relative block w-full px-3 py-3 border ${emailErr ? "border-red-500" : "border-gray-300"
                              } placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm`}
                            value={this.state.email}
                            onChange={(e) =>
                              this.setState({ email: e.target.value })
                            }
                          />
                        </div>

                        <div className="col-span-2">
                          <div className="flex flex-wrap gap-3 items-center">
                            {this.props?.colloborators && this.props?.colloborators?.map((member) => (
                              <div
                                className="flex items-center gap-x-2 bg-gray-100 hover:bg-gray-200 rounded-full p-2"
                                id={member.id}
                              >
                                <Avatarimage
                                  imagesize={{ height: 22, width: 22 }}
                                  firstname={member.firstname}
                                  imagesrc={member.profile_photo}
                                />
                                {`${member.firstname} ${member.lastname}`}
                                <IoCloseCircle
                                  size={22}
                                  className="primary cursor-pointer"
                                  onClick={() => {
                                    let updatedMembers =
                                      this.props.colloborators.filter(
                                        (data) => data.id !== member.id
                                      );

                                    this.props.selectMembers(updatedMembers);
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        </div>

                        <div className="col-span-2 sm:col-span-1">
                          <label
                            htmlFor="email-address"
                            className="Medium flex items-center gap-x-1"
                          >
                            Business Phone
                            <BsAsterisk size={9} className="primary" />
                          </label>
                          <input
                            id="phone-number"
                            name="phone-number"
                            type="phone-number"
                            autoComplete="phone-number"
                            required
                            maxLength={15}
                            placeholder="Enter business phone"
                            className={`appearance-none rounded-md relative block w-full px-3 py-3 border ${phoneNumErr ? "border-red-500" : "border-gray-300"
                              } placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm`}
                            value={this.state.phoneNum}
                            onChange={(e) =>
                              this.setState({
                                phoneNum: e.target.value.replace(/[^\d]/g, ""),
                              })
                            }
                          />
                        </div>

                        <div className="col-span-2 sm:col-span-1">
                          <label
                            htmlFor="Street"
                            className="Medium flex items-center gap-x-1"
                          >
                            Street
                            <BsAsterisk size={9} className="primary" />
                          </label>
                          <input
                            id="Street"
                            name="Street"
                            type="text"
                            autoComplete="Street"
                            required
                            maxLength={64}
                            placeholder="Enter your street address"
                            className={`appearance-none rounded-md relative block w-full px-3 py-3 border ${addressErr ? "border-red-500" : "border-gray-300"
                              } placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm`}
                            value={this.state.address}
                            onChange={(e) =>
                              this.setState({
                                address: e.target.value.replace(
                                  /[^A-Za-z0-9]+/g,
                                  " "
                                ),
                              })
                            }
                          />
                        </div>
                        <div className="grid grid-cols-2 gap-4 col-span-2 mt-5">
                          <SelectCountry
                            country_id={(value) => {
                              if (value !== 0) {
                                this.setState({ country_id: value });
                              }
                            }}
                            //sortname={(v) => this.setState({ country_code: v })}
                            sortname={(v) => {
                              // console.log('first', v, this.props?.company);
                              if (v !== undefined && v !== this.state.country_code) {
                                this.setState({ country_code: v });
                                this.props.selectState({});
                              }
                            }}
                            seleced_country_object={this.state.editCompany ?
                              this.props?.company?.country : null
                            }
                          />
                          {/*<label
                                    htmlFor="Postal_Code"
                                    className="Medium flex items-center gap-x-1"
                                >
                                  Country
                                </label>
                                <input
                                    id="Country"
                                    name="Country"
                                    type="text"
                                    autoComplete="Country"
                                    className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                                    defaultValue={this.state.country}
                                    contentEditable={false}
                                    maxLength={32}
                                />*/}
                        </div>

                        <div className="col-span-2 sm:col-span-1">
                          <label
                            htmlFor="City"
                            className="Medium flex items-center gap-x-1"
                          >
                            City
                            <BsAsterisk size={9} className="primary" />
                          </label>
                          <input
                            id="City"
                            name="City"
                            type="text"
                            autoComplete="City"
                            required
                            maxLength={191}
                            placeholder="Enter your City"
                            className={`appearance-none rounded-md relative block w-full px-3 py-3 border ${cityErr ? "border-red-500" : "border-gray-300"
                              } placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm`}
                            value={this.state.city}
                            onChange={(e) =>
                              this.setState({
                                city: e.target.value.replace(/[^a-z]/gi, ""),
                              })
                            }
                          />
                        </div>

                        <div className="col-span-2 sm:col-span-1">
                          <label
                            htmlFor="Postal_Code"
                            className="Medium flex items-center gap-x-1"
                          >
                            State
                            <BsAsterisk size={9} className="primary" />
                          </label>
                          <div className="shadow-sm">
                            <input
                              type="text"
                              readOnly
                              placeholder={
                                this.props._state && this.props._state.name
                                  ? this.props._state.name
                                  : "State"
                              }
                              className={`appearance-none rounded-md relative block w-full px-3 py-3 border ${stateErr ? "border-red-500" : "border-gray-300"
                                } placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm`}
                              onClick={() => {
                                if (this.state.country_id) {
                                  this.openStatesModal();
                                  this.setState({ addState: true });
                                } else {
                                  this.notify("Please select Country First", "error");
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                          <label
                            htmlFor="Street"
                            className="Medium flex items-center gap-x-1"
                          >
                            Postal Code
                            <BsAsterisk size={9} className="primary" />
                          </label>
                          <input
                            id="Street"
                            name="Street"
                            type="text"
                            autoComplete="Street"
                            required
                            maxLength={6}
                            placeholder="Enter postal code"
                            className={`appearance-none rounded-md relative block w-full px-3 py-3 border ${postalCodeErr
                              ? "border-red-500"
                              : "border-gray-300"
                              } placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm`}
                            value={this.state.postalCode}
                            onChange={(e) =>
                              this.setState({
                                postalCode: e.target.value.replace(
                                  /[^\d]/g,
                                  ""
                                ),
                              })
                            }
                          />
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                          <label
                            htmlFor="Street"
                            className="Medium flex items-center gap-x-1"
                          >
                            Website
                            <BsAsterisk size={9} className="primary" />
                          </label>
                          <input
                            id="Street"
                            name="Street"
                            type="text"
                            autoComplete="Street"
                            required
                            maxLength={64}
                            placeholder="Enter website link"
                            className={`appearance-none rounded-md relative block w-full px-3 py-3 border ${websiteErr ? "border-red-500" : "border-gray-300"
                              } placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm`}
                            value={this.state.website}
                            onChange={(e) =>
                              this.setState({
                                website: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <CompanySearch
              closePopup={() => this.closePopup()}
              message={(value, type) => this.notify(value, type)}
            />
          </TabPanel>
        </Dialog>
        <input
          className="hidden"
          accept="image/*"
          type="file"
          onChange={this.onPhotoChange}
          ref={this.photoRef}
        />
        <Fullscreenpopup
          ref={this.selectFullPopup}
          type={modalType}
          country_id={country_id}
          clearState={(value) => console.log("value", value)}
        />
        <ConfirmationDialog
          ref={this.OpenConfirmation}
          callBack={this.callBack}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ Rfq, Auth }) => {
  return {
    form: Rfq.form,
    fields: Rfq.fields,
    colloborators: Rfq.members,
    _state: Rfq._state,
    authUser: Auth.authUserInformation,
    socket: Auth.socket,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("store/redux/RFQRedux");
  return {
    ...ownProps,
    ...stateProps,

    selectState: (data) => {
      dispatch(actions.selectState(data));
    },
    selectMembers: (data) => {
      dispatch(actions.selectMembers(data));
    },
  };
};
export default connect(mapStateToProps, undefined, mergeProps, {
  forwardRef: true,
})(TeamsDialog);
