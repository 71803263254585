import { gql } from "@apollo/client";

export default gql`
  mutation createStripePayment(
    $plan_id: ID
    $cardholder_name: String
    $payment_method_id: String
    $email: String
  ) {
    createStripePayment(
      plan_id: $plan_id
      cardholder_name: $cardholder_name
      payment_method_id: $payment_method_id
      email:$email
    ) {
      response
      transaction_id
      user_id
    }
  }
`;
