import React, { Component, Fragment } from "react";
import {
  Fullscreenpopup,
  Tooltips,
  Buttonlink,
  Loader,
  Noconnection,
} from "components";
import { DateRange } from "react-date-range";
import { Dropdown } from "semantic-ui-react";
import { BsFillCalendarCheckFill } from "react-icons/bs";
import { SiLeanpub } from "react-icons/si";
import { connect } from "react-redux";
import {
  FiAward,
  FiChevronDown,
  FiChevronUp,
  FiEdit2,
  FiLayers,
  FiPower,
} from "react-icons/fi";
import { CgMathPercent } from "react-icons/cg";
import { GiMoneyStack } from "react-icons/gi";
import { BiReset, BiUser } from "react-icons/bi";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { FaFilter } from "react-icons/fa";
import { Popover, Transition } from "@headlessui/react";
import GET_ANA from "../../graphql/queries/allAnalytics";
import moment from "moment";
import { Query } from "@apollo/client/react/components";
import { IoIosCloseCircle } from "react-icons/io";
import { actions } from "../../store/redux/RFQRedux";

const options = [
  { key: 1, text: "Last 30 days", value: 1 },
  {
    key: 2,
    text: "Last 3 Months",
    value: 3,
  },
  {
    key: 3,
    text: "Last 6 Months",
    value: 6,
  },
];

class BuyerAnalytic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: "",
      datePicker: [
        {
          startDate: new Date(),
          endDate: null,
          key: "selection",
        },
      ],
      analyticsData: [],
      catData: [],
      type: "current",
      monthValue: "",
      setstates: {
        startDate: null,
        endDate: null,
        key: "selection",
      },
      reloadStats: false,
    };
    this.baseState = this.state;
    this.selectFullPopup = React.createRef();
    this.addressDialogRef = React.createRef();
    this.teamsDialogRef = React.createRef();
    this.SelectPurchaseHistory = React.createRef();
    this.amount = React.createRef();
    this.pricingPopups = React.createRef();
    this.MoreCategoryPopup = React.createRef();
  }

  componentDidMount() {
    this.props.addRfqForm({});
    this.props.selectCategories([]);
    this.props.selectTags({});
  }

  opencategorymodal = () => {
    this.selectFullPopup.current.openPopup();
    this.setState({
      open: false,
    });
  };

  scrollToAmount = () => window.scrollTo(0, this.amount.current.offsetTop);

  handleDateRange = (item) => {
    this.setState({
      datePicker: [item.selection],
    });
  };
  handleMonthValue = (event, data) => {
    this.setState({
      monthValue: data.value,
    });
  };

  resetVariabales = () => {
    this.setState({
      reloadStats: true,
      monthValue: this.baseState,
      datePicker: [
        {
          startDate: new Date(),
          endDate: null,
          key: "selection",
        },
      ],
    });
  };

  render() {
    const { type, datePicker, monthValue } = this.state;
    var variables = {};
    var endTime = "";
    var startTime = "";
    let final = "";
    var ids = [];
    if (
      datePicker[0].startDate !== datePicker[0].endDate &&
      datePicker[0].endDate !== null
    ) {
      endTime = moment(datePicker[0].endDate).format("YYYY/MM/DD");
      startTime = moment(datePicker[0].startDate).format("YYYY/MM/DD");
      final = startTime + "," + endTime;
      variables.dateRange = final;
    }

    if (this.props.form && this.props.form.category) {
      this.props.form.category.map((category) => {
        ids.push(parseInt(category.id));
      });
      variables.categories = ids;
    }
    if (monthValue.length !== 0) {
      variables.month = parseInt(monthValue);
    }

    if (datePicker[0].endDate !== null) {
      delete variables.month;
    } else {
      // console.log('nhi mila')
    }

    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 2); // Set the min date to one year ago

    let canChooseAdvancedFilters = false;
    if (this.props?.auth_user?.payments) {
      this.props.auth_user.payments.forEach((plan) => {
        if (plan.plan.plan_features) {
          plan.plan.plan_features.forEach((plan_feature) => {
            if (plan_feature.name === "Advanced Filter Analytics") {
              canChooseAdvancedFilters = true;
            }
          });
        }
      });
    }
    return (
      <>
        <Fullscreenpopup
          ref={this.selectFullPopup}
          type={this.state.open ? "Add Members" : "Choose Category"}
          clearState={(value) => console.log("value", value)}
        />
        <div className="my-20 space-y-5 container">
          {this.state.reloadStats && (
            canChooseAdvancedFilters ? (
              <div className="grid lg:grid-cols-5 grid-cols-1 lg:gap-x-5 lg:gap-y-0 gap-y-5 intro-y z-full">
                <Buttonlink
                  onClick={this.opencategorymodal}
                  className="bg--primary white hover:opacity-80 flex-1 grow w-full flex items-center justify-center h-11 rounded-xl"
                  text="Choose Category"
                />
                <Popover.Group className="flex items-center justify-between grow h-11 lg:col-span-2">
                  <Popover className="relative h-full flex grow">
                    <Popover.Button className="bg--primary py-1 rounded-lg grow xl:h-auto h-11 px-5 ">
                      <p className="white gap-x-2 text-left fs-13 hover:primary-all-text hover:primary--text flex items-center justify-start relative">
                        <div className="w-9 h-9 flex items-center justify-center bg-white rounded-md">
                          <BsFillCalendarCheckFill
                            size={18}
                            className="primary"
                          />
                        </div>
                        {datePicker[0].startDate !== datePicker[0].endDate &&
                          datePicker[0].endDate !== null
                          ? final
                          : "Choose Date Range"}

                        <div className="grow flex justify-end">
                          <FiChevronDown size={18} className="white" />
                        </div>
                      </p>
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-300"
                      enterFrom="opacity-0 translate-y-0"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-250"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-0"
                    >
                      <Popover.Panel className="absolute z-10 right-0 transform mt-10 w-full md:w-screen max-w-lg sm:px-0">
                        <div className="p-6 rounded-lg shadow-lg bg-white relative ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <DateRange
                            editableDateInputs={true}
                            onChange={this.handleDateRange}
                            moveRangeOnFirstSelection={false}
                            ranges={datePicker}
                            minDate={minDate}
                            maxDate={new Date()}
                            rangeColors={["#ef553b", "#ef553b"]}
                            color={"#ef553b"}
                          />
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </Popover>
                </Popover.Group>
                <Dropdown
                  options={options}
                  selection
                  value={monthValue && monthValue}
                  className="h-11 rounded--xl z-full border--0"
                  placeholder="Select Option"
                  disabled={datePicker[0].endDate !== null}
                  onChange={this.handleMonthValue}
                />
                <div
                  onClick={this.resetVariabales}
                  className="flex items-center lg:justify-center justify-end gap-x-2"
                >
                  <p className="primary cursor-pointer hover:opacity-70 flex items-center gap-x-2">
                    <BiReset className="primary" />
                    Reset Filter
                  </p>
                </div>
              </div>
            ) : <p>You can't add filters with current plan, Please Upgrade your Plan to select filters</p>)}
          <div className="intro-y flex items-center h-10 justify-between">
            <h2 className="text-2xl Medium truncate">Dashboard</h2>
            <div className="intro-y flex items-center justify-end">
              <h2 className="text-lg Medium truncate mr-3">Advance Filters:</h2>
              <div
                onClick={() =>

                  this.setState({
                    reloadStats: !this.state.reloadStats,
                  })
                }
                className="bg--primary h-12 w-12 flex items-center justify-center rounded-full cursor-pointer"
              >
                <FaFilter className="white" />
              </div>
            </div>
          </div>
          <Query query={GET_ANA} variables={variables}>
            {({ loading, error, data }) => {
              if (loading) return <Loader />;
              if (error) return <Noconnection />;
              if (data) {
                // console.log("datePicker", datePicker[0].endDate)
                // console.log("monthValue", monthValue)
                return (
                  <div className="space-y-10">
                    <div className="gap-2 flex items-center flex-wrap mt-5">
                      {this.props?.categories?.category?.map((item) => {
                        return (
                          <div className="bg--lightPrimary intro-x rounded-lg border--primary hover:opacity-80 px-4 py-1.5 cursor-pointer flex items-center gap-x-2">
                            <p className="fs-11">{item.name}</p>
                            <div
                              onClick={() => {
                                let updatedCat =
                                  this.props.categories.category.filter(
                                    (data) => data.id != item.id
                                  );

                                let form = Object.assign({}, this.props.form);

                                form["category"] = updatedCat;

                                this.props.selectCategories(form);
                                this.props.addRfqForm(form);
                              }}
                            >
                              <IoIosCloseCircle size={20} className="primary" />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div>
                      <div className="grid grid-cols-12 gap-6 mt-5">
                        <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                          <div className="report-box zoom-in">
                            <div className="box p-5">
                              <div className="flex">
                                <FiLayers className="success" size={28} />
                                <div className="ml-auto">
                                  <Tooltips
                                    trigger={
                                      <div className="report-box__indicator fs-14 px-2 py-1 bg--success  cursor-pointer">
                                        {
                                          data.analytics
                                            .offer_past_month_percentage
                                        }
                                        <CgMathPercent />
                                        <FiChevronUp />
                                      </div>
                                    }
                                    toolcontent={`${data.analytics.offer_past_month_percentage} % Higher than last month`}
                                  />
                                </div>
                              </div>
                              <div className="text-3xl Regular leading-8 mt-6">
                                {data.analytics.offer_count}
                              </div>
                              <div className="text-base text-gray-500 mt-1">
                                Total RFQs
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                          <div
                            className="report-box zoom-in"
                            onClick={this.scrollToAmount}
                          >
                            <div className="box p-5">
                              <div className="flex">
                                <GiMoneyStack className="warning" size={32} />
                                <div className="ml-auto">
                                  <Tooltips
                                    trigger={
                                      <div className="report-box__indicator fs-14 px-2 py-1 bg--warning  cursor-pointer">
                                        {data.analytics.offer_spendPercentage}
                                        <CgMathPercent />
                                        <FiChevronUp />
                                      </div>
                                    }
                                    toolcontent={`${data.analytics.offer_spendPercentage} % Higher than last month`}
                                  />
                                </div>
                              </div>
                              <div className="text-3xl Regular leading-8 mt-6">
                                {data.analytics.offer_spend}
                              </div>
                              <div className="text-base text-gray-500 mt-1">
                                Total Number of RFQs Participants
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                          <div className="report-box zoom-in">
                            <div className="box p-5">
                              <div className="flex">
                                <BiUser className="warning" size={32} />
                                <div className="ml-auto">
                                  <Tooltips
                                    trigger={
                                      <div className="report-box__indicator fs-14 px-2 py-1 bg--warning  cursor-pointer">
                                        {
                                          data.analytics
                                            .offer_suppliersPercentage
                                        }
                                        <CgMathPercent />
                                        <FiChevronUp />
                                      </div>
                                    }
                                    toolcontent={`${data.analytics.offer_suppliersPercentage} % Heigher than last month`}
                                  />
                                </div>
                              </div>
                              <div className="text-3xl Regular leading-8 mt-6">
                                {data.analytics.offer_suppliersCount}
                              </div>
                              <div className="text-base text-gray-500 mt-1">
                                Conversation Partners
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="intro-y flex items-center h-10">
                        <h2 className="text-2xl Medium truncate">RFQs</h2>
                      </div>
                      <div className="grid grid-cols-12 gap-6 mt-5">
                        <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                          <div className="report-box zoom-in">
                            <div className="box p-5">
                              <div className="flex">
                                <SiLeanpub className="warning" size={32} />
                              </div>
                              <div className="text-3xl Regular leading-8 mt-6">
                                {data.analytics.published_offer_count}
                              </div>
                              <div className="text-base text-gray-500 mt-1">
                                RFQs Published
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                          <div className="report-box zoom-in">
                            <div className="box p-5">
                              <div className="flex">
                                <FiLayers className="success" size={28} />
                              </div>
                              <div className="text-3xl Regular leading-8 mt-6">
                                {data.analytics.draft_offer_count}
                              </div>
                              <div className="text-base text-gray-500 mt-1">
                                RFQs Draft
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                          <div className="report-box zoom-in">
                            <div className="box p-5">
                              <div className="flex">
                                <FiLayers className="success" size={28} />
                              </div>
                              <div className="text-3xl Regular leading-8 mt-6">
                                {data.analytics.expired_offer_count}
                              </div>
                              <div className="text-base text-gray-500 mt-1">
                                RFQs Expired
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                          <div className="report-box zoom-in">
                            <div className="box p-5">
                              <div className="flex">
                                <FiAward className="warning" size={32} />
                              </div>
                              <div className="text-3xl Regular leading-8 mt-6">
                                {data.analytics.awarded_offer_count}
                              </div>
                              <div className="text-base text-gray-500 mt-1">
                                RFQs Awarded
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                          <div className="report-box zoom-in">
                            <div className="box p-5">
                              <div className="flex">
                                <BiUser className="warning" size={32} />
                              </div>
                              <div className="text-3xl Regular leading-8 mt-6">
                                {data.analytics.executive_exist_count}
                              </div>
                              <div className="text-base text-gray-500 mt-1">
                                RFQ member as Executive
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        className="intro-y flex items-center h-10"
                        ref={this.amount}
                      >
                        <h2 className="text-2xl Medium truncate">
                          Amount of RFQs
                        </h2>
                      </div>
                      <div className="grid grid-cols-12 gap-6 mt-5">
                        <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                          <div className="report-box zoom-in">
                            <div className="box p-5">
                              <div className="flex">
                                <SiLeanpub className="warning" size={32} />
                              </div>
                              <div className="text-3xl Regular leading-8 mt-6">
                                {data.analytics.published_offer_amount}
                              </div>
                              <div className="text-base text-gray-500 mt-1">
                                Amount of RFQs Published
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
                          <div className="report-box zoom-in">
                            <div className="box p-5">
                              <div className="flex">
                                <FiAward className="warning" size={32} />
                              </div>
                              <div className="text-3xl Regular leading-8 mt-6">
                                {data.analytics.awarded_offer_amount}
                              </div>
                              <div className="text-base text-gray-500 mt-1">
                                Amount of RFQs Awarded
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            }}
          </Query>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ Auth, Rfq }) => {
  return {
    auth_user: Auth.authUserInformation,
    form: Rfq.form,
    members: Rfq.members,
    categories: Rfq.categories,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("store/redux/RFQRedux");
  return {
    ...ownProps,
    ...stateProps,
    selectCategories: (data) => {
      dispatch(actions.selectCategories(data));
    },
    addRfqForm: (data) => {
      dispatch(actions.addRfqForm(data));
    },
    selectTags: (data) => {
      dispatch(actions.selectTags(data));
    },
  };
};
export default connect(mapStateToProps, null, mergeProps)(BuyerAnalytic);
