import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Buttonlink } from "components";
import "./styles.css";
import { FiX } from "react-icons/fi";
import LOGIN from "../../graphql/mutations/loginMutation";
import cookie from "js-cookie";
import {
  ANDROID_APP_URL,
  GRAPH_MRO_USER_AUTH_TOKEN,
  IOS_APP_URL,
} from "../../config/constants";
import Alert from "@mui/material/Alert";
import { AiFillAndroid, AiFillApple } from "react-icons/ai";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { Link } from "react-router-dom";
import client from "../../graphql/client";

class Signinpopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loading: false,
      error: false,
      open: this.props.open,
      message: "",
      pswdType: true,
    };
  }

  handleLoginSubmit = event => {
    event.preventDefault();
    const { email, password } = this.state;

    if (email === "" || password === "") {
      this.setState({
        ...this.state,
        loading: false,
        error: true,
        message: "Email or password cannot be empty.",
      });
      return;
    }

    this.setState({
      ...this.state,
      loading: true,
      error: false,
      message: "",
    });

    client
      .mutate({
        mutation: LOGIN,
        variables: {
          email: email,
          password: password,
        },
      })
      .then(response => {
        let { error, message, token } = response.data.login;
        if (error === true) {
          this.setState({
            ...this.state,
            loading: false,
            error: error,
            message: message,
          });
        } else {
          this.setState({
            ...this.state,
            loading: false,
            error: false,
            message: "",
          });

          cookie.set(GRAPH_MRO_USER_AUTH_TOKEN, token);
          window.location.href = window.location.href;
        }
      })
      .catch(err => {
        this.setState({
          ...this.state,
          loading: false,
          error: true,
          message: "Oops something went wrong please try again later",
        });
      });
  };

  openPopup = () => {
    this.setState({ open: true });
  };

  closePopup() {
    this.setState({ open: false });
  }

  showEyePswd = () => {
    this.setState({ pswdType: !this.state.pswdType });
  };

  render() {
    const { email, password, error, message, open } = this.state;
    const { protect, closePopup } = this.props;
    return (
      <Dialog
        maxWidth="xs"
        fullWidth
        open={open}
        className="relative overflow-auto sign-in-popup"
      >
        <div
          onClick={() => (protect ? this.closePopup() : closePopup())}
          className="absolute z-10 -right-2 -top-2 cursor-pointer h-6 w-6 bg-gray-200 rounded-full darkGray flex items-center justify-center"
        >
          <FiX />
        </div>
        <DialogContent className="p-10 rounded--xl">
          <div className="space-y-4">
            <h1 className="Medium text-3xl">Sign in</h1>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  maxLength={64}
                  className="appearance-none rounded-none relative block h-12 w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                  value={email}
                  onChange={e => this.setState({ email: e.target.value })}
                />
              </div>
              <div className="relative">
                <input
                  id="password"
                  name="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none rounded-none relative block h-12 w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                  type={this.state.pswdType ? "password" : "text"}
                  variant="standard"
                  value={password}
                  onChange={e => this.setState({ password: e.target.value })}
                />
                <div
                  onClick={this.showEyePswd}
                  className="absolute z-10 right-0 top-0 h-full w-12 cursor-pointer flex items-center justify-center"
                >
                  {this.state.pswdType ? (
                    <BsEye size={20} />
                  ) : (
                    <BsEyeSlash size={20} />
                  )}
                </div>
              </div>
            </div>

            <div className="flex items-center justify-end">
              <Link
                to={"/forgot-password"}
                className="primary hover:opacity-70"
              >
                Forgot Password?
              </Link>
            </div>

            <div className="flex items-end justify-end">
              <Buttonlink
                onClick={this.handleLoginSubmit}
                Text="Login"
                className="bg--primary text-white hover:opacity-70 hover:bg--primary focus:bg--primary rounded-full lg:w-2/4 w-full  h-11 fs-14 Regular border-0 shadow-none"
              />
            </div>
            <div>
              <div className="flex items-center">
                <div className="divider flex-grow rounded-full" />
                <p className="gray fs-13 mx-2 Light">Or</p>
                <div className="divider flex-grow rounded-full" />
              </div>
              <div className="flex lg:flex-row flex-col items-center gap-3 mt-3">
                <Buttonlink
                  href={ANDROID_APP_URL}
                  Text="Continue with"
                  className="items-center justify-center flex bg-white hover:black darkGray focus:black hover:opacity-70 hover:bg-white focus:bg-white rounded-full h-10 fs-12 px-4 rounded--full Regular border-gary border shadow-none w-full"
                  Lasticon={
                    <AiFillAndroid color="#8bc34a" size={20} className="ml-2" />
                  }
                />
                <Buttonlink
                  href={IOS_APP_URL}
                  Text="Continue with"
                  className="items-center justify-center flex bg-white hover:black darkGray focus:black hover:opacity-70 hover:bg-white focus:bg-white rounded-full h-10 fs-12 px-4 rounded--full Regular border-gary border shadow-none w-full"
                  Lasticon={<AiFillApple size={20} className="ml-2" />}
                />
              </div>
              <Buttonlink
                to={"/signup"}
                Text="Sign up"
                className="items-center justify-center flex bg--lightGray black hover:black focus:black hover:opacity-70 hover:bg--lightGray focus:bg--lightGray rounded-full h-11 px-4 rounded--full Regular border-0 shadow-none w-full mt-4"
              />
            </div>
          </div>
          <div>
            {error ? <div style={{ height: 15 }} /> : false}
            {error ? <Alert severity="error">{message}</Alert> : ""}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default Signinpopup;
