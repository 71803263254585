// import { Pricing } from "..";
import { Pricing } from "navigation";
import BuyerSidePlan from "./components/buyersideplan";
export default function PricingScreen() {
  return (
    <>
      <Pricing />
      {/* <BuyerSidePlan /> */}
    </>
  );
}
