import { gql } from "@apollo/client";

export default gql`
  mutation createCompany(
    $company_id: Int
    $title: String
    $link: String
    $logo: String
    $address: String
    $city: String
    $state_id: Int
    $zip_code: Int
    $country_code: String
    $country_id: Int
    $phone: String
    $email: String
    $executives: [Int]
    $roles: [Int]
  ) {
    createCompany(
      company_id: $company_id
      title: $title
      link: $link
      logo: $logo
      address: $address
      city: $city
      state_id: $state_id
      zip_code: $zip_code
      country_code: $country_code
      country_id: $country_id
      phone: $phone
      email: $email
      executives: $executives
      roles: $roles
    ) {
      id
      title
      slug
      address
      city
      state_id
      latitude
      longitude
      country
      country_code
      country_id
      postal_code
      phone
      email
      history
      linked_in_profile
      logo
      is_active
      is_deleted
      added_from
      is_primary
      state {
        id
        name
      }
      executives {
        id
        firstname
        lastname
        email
        username
        profile_photo
      }
    }
  }
`;
