import { gql } from "@apollo/client";

export default gql`
  mutation update(
    $linkedin_profile: String
    $firstname: String!
    $lastname: String!
    $username: String!
    $tagline: String
    $phone_number: String
    $country_id:Int
  ) {
    updateUser(
      linkedin_profile: $linkedin_profile
      firstname: $firstname
      lastname: $lastname
      username: $username
      tagline: $tagline
      phone_number: $phone_number
      country_id:$country_id
    ) {
      id
      username
      firstname
      lastname
      profile_photo
      tagline
      email
      timestamp
      country {
        id
        name
        phonecode
      }
    }
  }
`;
