import React from 'react'

const RFQProducts = ({ product }) => {
    return (
        <div className="border p-4">
            <h2 className="">{product?.title}</h2>
            {/* <h2>{product?.description}</h2> */}
            {
                product?.quantity > 0 && (
                    <div className="flex items-center gap-x-3">
                        <p className="primary">Required {product.uom}:</p>
                        <p className="Medium">{product.quantity}</p>
                    </div>
                )
            }

            {
                product?.reference_number != null && (
                    <div className="flex items-center gap-x-3">
                        <p className="primary">Reference number:</p>
                        <p className="Medium">{product.reference_number}</p>
                    </div>
                )
            }

            {
                product?.last_date != null && (
                    <div className="flex items-center gap-x-3">
                        <p className="primary">Last date:</p>
                        <p className="Medium">{product?.last_date}</p>
                    </div>
                )
            }
            <div>
                {/* {this.renderAttachmentComponent(rfqDetail)} */}
                {/* <div
                    className="leading-normal text-color whitespace-pre-wrap"
                    dangerouslySetInnerHTML={{ __html: rfqDetail.description }}
                      /> */}
                {product?.description &&
                    <h2>{product?.description}</h2>
                }
                {/* <div className="space-y-2">
                    <h3 className="text-xl Medium">Industry Tags:</h3>
                    <div className="flex flex-wrap items-center gap-2 selected-tags ">
                        {product?.tags?.map((tag) => (
                            <label
                                className="bg-gray-200 hover:opacity-70 rounded-lg px-5 py-1.5 cursor-pointer inline-block  intro-x"
                                key={tag.id}
                            >
                                <p className="fs-13">{tag}</p>
                            </label>
                        ))}
                    </div> */}
                {/* </div> */}
            </div>
        </div>
    )
}

export default RFQProducts