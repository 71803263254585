import { gql } from "@apollo/client";

export const SAVE_RFQ_DESCRIPTION = gql`
  mutation saveOfferDescription(
  $offer_id:Int
  $description:String
  $status:String
  $tags:[Int!]!
  $attachments:[Object]
  $platform:Int!
  $preferredVendor:Boolean
  $category:[Int!]!
) {
    saveOfferDescription(
      offer_id:$offer_id
      description: $description
      status:$status
      tags:$tags
      attachments:$attachments
      platform:$platform
      category: $category
      preferredVendor:$preferredVendor
    ) {
      id
    }
  }
`;

