import React from 'react'
import Checkbox from "@mui/material/Checkbox";

const LearnMore = () => {
    return (
        <>
            <div className='lg:px-20 px-5'>
                <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:mt-20 mt-5 gap-8'>
                    <div className='px-4 py-10 bg-white bg-opacity-50 backdrop-blur-lg rounded drop-shadow-lg'>
                        <div>
                            <h1 className='primary fs-20 font-bold Medium'>DObuying - Your buying Concierge</h1>
                            <h1 className='text-[#594427] fs-15 pt-4 font-bold Medium'>We provide value in day to day procurement</h1>
                        </div>
                        <div className="flex items-center mt-4">
                            <Checkbox
                                className="primary-checkbox"
                            />
                            <p className="fs-12 font-bold">
                                Buying simplified
                            </p>
                        </div>
                        <div className="flex items-center">
                            <Checkbox
                                className="primary-checkbox"
                            />
                            <p className="fs-12 font-bold">
                                Maximized value
                            </p>
                        </div>
                        <div className="flex items-center">
                            <Checkbox
                                className="primary-checkbox"
                            />
                            <p className="fs-12 font-bold">
                                Stress free just for You
                            </p>
                        </div>
                        <div className='mt-4'>
                            <button className='bg--primary h-12 px-10 white rounded-md text-lg'>Get started</button>
                        </div>
                    </div>
                    <div className='px-4 py-10 bg-white bg-opacity-50 backdrop-blur-lg rounded drop-shadow-lg'>
                        <div className="flex items-center mt-4">
                            <Checkbox
                                className="primary-checkbox"
                            />
                            <p className="fs-12 font-bold pt-4">
                                MAKE YOUR CHOICE <br /> Let us know what you need and when you need it
                            </p>
                        </div>
                        <div className="flex items-center">
                            <Checkbox
                                className="primary-checkbox"
                            />
                            <p className="fs-12 font-bold pt-4">
                                DISCOVER <br /> Uncover new suppliers with competitive low rates
                            </p>
                        </div>
                        <div className="flex items-center">
                            <Checkbox
                                className="primary-checkbox"
                            />
                            <p className="fs-12 font-bold pt-4">
                                WE PAY YOUR INVOICE <br /> Sit back and while we pay your
                                invoices and track your spending
                            </p>
                        </div>
                        <div className='mt-[43px]'>
                            <button className='bg--primary h-12 px-10 white rounded-md text-lg'>Start Now</button>
                        </div>
                    </div>
                    <div className='px-4 py-10 bg-white bg-opacity-50 backdrop-blur-lg rounded drop-shadow-lg'>
                        <div className="flex items-center mt-4">
                            <Checkbox
                                className="primary-checkbox"
                            />
                            <p className="fs-12 font-bold pt-4">
                                INCREASE TOP LINE <br /> Zero costs of managing suppliers
                            </p>
                        </div>
                        <div className="flex items-center">
                            <Checkbox
                                className="primary-checkbox"
                            />
                            <p className="fs-12 font-bold pt-4">
                                BETTER DATA <br /> Identify opportunities to increase spending leverage
                            </p>
                        </div>
                        <div className="flex items-center">
                            <Checkbox
                                className="primary-checkbox"
                            />
                            <p className="fs-12 font-bold pt-4">
                                EMPOWERED YOU <br /> Simple three step process for
                                Buying and Paying
                            </p>
                        </div>
                        <div className='mt-[43px]'>
                            <button className='bg--primary h-12 px-10 white rounded-md text-lg'>Start Now</button>
                        </div>
                    </div>
                </div>

                <div className='grid md:grid-cols-2 grid-cols-1 gap-8 mt-10'>
                    <div>
                        <div className="h-full p-8 bg-gray-100 rounded">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="block w-5 h-5 text-gray-400 mb-4" viewBox="0 0 975.036 975.036">
                                <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                            </svg>
                            <p className="leading-relaxed mb-6">Synth chartreuse iPhone lomo cray raw denim brunch everyday carry neutra before they sold out fixie 90's microdosing. Tacos pinterest fanny pack venmo, post-ironic heirloom try-hard pabst authentic iceland.</p>
                            <div className="flex justify-end items-center gap-3">
                                <div className='h-px w-5 bg-black' />
                                <span className="font-medium Medium">Holden Caulfield</span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="h-full p-8 bg-gray-100 rounded">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="block w-5 h-5 text-gray-400 mb-4" viewBox="0 0 975.036 975.036">
                                <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                            </svg>
                            <p className="leading-relaxed mb-6">Synth chartreuse iPhone lomo cray raw denim brunch everyday carry neutra before they sold out fixie 90's microdosing. Tacos pinterest fanny pack venmo, post-ironic heirloom try-hard pabst authentic iceland.</p>
                            <div className="flex justify-end items-center gap-3">
                                <div className='h-px w-5 bg-black' />
                                <span className="font-medium Medium">Holden Caulfield</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LearnMore