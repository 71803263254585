import { gql } from "@apollo/client";

export default gql`
  query getPayment{
    me {
      id
      payments {
      id
      plan_id
      user_id
      status
      transaction_id
      currency_code
      total_payment
      is_active
      plan_expire_date
      created_at
      plan {
        plan_id
        title
        subtitle
        price
        validity
        plan_features {
          plan_feature_id
          name
          feature_type
          quantity
        }
        user_plan_features {
          plan_feature_id
          name
          feature_type
          payment_id
          quantity
        }
      }
    }
    }
  }
`;
