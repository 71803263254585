import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { BiLoaderAlt } from "react-icons/bi";
import { Box } from "@mui/material";

export default function LoadMoreData(props) {
  const [loader, setLoading] = useState(false);

  useEffect(() => {
    let timeoutId;

    if (props.loading && loader) {
      setLoading(true);

      // Show loader for 2 seconds
      timeoutId = setTimeout(() => {
        setLoading(false);
      }, 3000);
    }

    // Clear the timeout if the component unmounts or props.loading changes
    return () => clearTimeout(timeoutId);
  }, [props.loading]);

  function loadMore(data, fetchMore, refetch) {
    props.callback(data, fetchMore, refetch);
  }

  return (
    <Box sx={{ "& > button": { m: 1 } }}>
      <LoadingButton
        className="relative mx-auto mt-10 hover:white white hover:opacity-80 flex justify-center py-2.5 px-5 border border-transparent rounded-lg white h-11 w-44 important:Medium important:bg--primary  hover:bg--primary focus:outline-none"
        onClick={() => loadMore(props.data, props.fetchMore, props.refetch)}
        loading={loader}
        loadingIndicator={
          <BiLoaderAlt className="animate-spin white" size={16} />
        }
        variant="contained"
      >
        {props.title}
      </LoadingButton>
    </Box>
  );
}
