// import * as React from "react";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import "./styles.css";
import { connect } from "react-redux";
import { format } from "date-fns";
import moment from "moment";

function CustomDatePicker(props) {
  const today = new Date();
  const [selectedDate, setSelectedDate] = useState(
    props.fields.last_date
      ? new Date(props.fields.last_date)
      : new Date(today.getFullYear(), today.getMonth(), today.getDate() + 14)
  );
  //console.log("Formatt", props.fields.last_date);

  useEffect(() => {
    const key = "last_date";
    const value = format(selectedDate, "yyyy-MM-dd");
    const form = { ...props.fields, [key]: value };
    props.addRfqFields(form);
  }, [selectedDate]);

  const addFields = (key, value) => {
    setSelectedDate(value);
    if (value > new Date()) {
      const form = { ...props.fields, [key]: format(value, "yyyy-MM-dd") };
      props.addRfqFields(form);
    } else {
      const form = { ...props.fields, [key]: "Invalid date" };
      props.addRfqFields(form);
    }
  };
  const minDate = new Date(today);
  minDate.setDate(today.getDate() + 14);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <input
        type="date"
        min={minDate.toISOString().split("T")[0]}
        value={selectedDate.toISOString().split("T")[0]}
        onChange={(event) => {
          if (event.target.value !== '') {
            const newDate = new Date(event.target.value)
            setSelectedDate(newDate);
            addFields("last_date", newDate);
          } else {
            let currentDate = new Date();
            let newDate = new Date(currentDate.getTime() + 14 * 24 * 60 * 60 * 1000);
            setSelectedDate(newDate);
          }

        }}
        onKeyDown={(event) => {
          const inputDate = new Date(event.target.value);
          if (inputDate < minDate) {
            event.preventDefault(); // Prevent typing dates before the minimum date
          }
          // You can also add logic to restrict dates beyond a maximum date if needed.
        }}
        placeholder="End date"
        className="bg-transparent h-full w-max border rounded-lg border-gray-300 py-1 shadow-sm px-3"
        style={{ lineHeight: "inherit" }}
      />

    </LocalizationProvider>
  );
}

const mapStateToProps = ({ Rfq }) => {
  return {
    form: Rfq.form,
    fields: Rfq.fields,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("store/redux/RFQRedux");
  return {
    ...ownProps,
    ...stateProps,
    addRfqForm: (data) => {
      dispatch(actions.addRfqForm(data));
    },
    addRfqFields: (data) => {
      dispatch(actions.addRfqFields(data));
    },
  };
};
export default connect(
  mapStateToProps,
  undefined,
  mergeProps
)(CustomDatePicker);
