import React, { Component } from "react";
import { connect } from "react-redux";
import { IoIosCloseCircle } from "react-icons/io";
import { toast } from "react-toastify";

class ChooseRFQCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      expanded: false,
      showCategories: this.props.inPage === false ? this.props.inPage : true,
    };
    if (!this.state.showCategories) {
      this.props.selectCategories([]);
      this.props.clearForm(() => { });
      this.setState({ showCategories: true });
    }

  }

  componentDidMount = () => {


    if (!this.state.showCategories) {
      this.props.selectCategories([]);
      this.props.clearForm(() => { });
      this.setState({ showCategories: true });
    }

    // setTimeout(() => {
    //   console.log("this.props.form", this.props.form);
    // }, 3000);
  };

  selectCategory = (event, category) => {
    this.setState({ expanded: !this.state.expanded });
    const isChecked = event.target.checked;
    let categories = Object.assign([], this.props.form.category);


    if (isChecked) {
      if (categories?.length < this.props.maxValue) {
        categories.push(category);
        this.addForm("category", categories);
      } else {
        this.notify(`You can only select ${this.props.maxValue} categories`, "error");
        this.props.closePopup();
        // if (this.props.showCurrentPage !== 'profile'){
        //   this.notify('you can only select 5 categories', 'error');
        //   this.props.closePopup()
        // }else {
        //   categories.push(category);
        //   this.addForm("category", categories);
        //   this.notify('Adding more than 5 categories', 'warning');
        // }
      }
    } else {
      const index = categories.indexOf(category);
      categories.splice(index, 1);
      this.addForm("category", categories);
    }
  };
  notify = (message = "", type = "error") => {
    // Dismiss any existing toasts before showing a new one
    toast.dismiss();
    if (type === "error") {
      return toast.error(message, { position: "top-right" });
    } else if (type === "warning") {
      return toast.warning(message, { position: "top-right" });
    } else {
      return toast.success(message, { position: "top-right" });
    }
  };
  selectSubCategory = (event, category) => {
    const isChecked = event.target.checked;
    let categories = Object.assign([], this.props.form.category);

    if (isChecked) {
      if (categories?.length < this.props.maxValue) {
        categories.push(category);
        this.addForm("category", categories);
      } else {
        this.notify(`You can only select ${this.props.maxValue} categories`, "error");
        this.props.closePopup();
        // if (this.props.showCurrentPage !== 'profile'){
        //   this.notify('you can only select 5 categories', 'error');
        //   this.props.closePopup()
        // }else {
        //   categories.push(category);
        //   this.addForm("category", categories);
        //   this.notify('Adding more than 5 categories', 'warning');
        // }
      }
    } else {
      const index = categories.indexOf(category);
      categories.splice(index, 1);
      this.addForm("category", categories);
    }
  };

  addForm = (key, value) => {
    let form = Object.assign({}, this.props.form);
    form[key] = value;
    //console.log("KKKKKK", form);
    this.props.addRfqForm(form);
    // console.log('this.props.form', this.props.form);
    this.props.selectCategories(form);
  };

  render() {
    const { subCategory, form, categories } = this.props;
    return (
      <>
        <div className="flex items-center flex-wrap gap-2 selected-tags">
          {subCategory ? (
            <div className="gap-2 flex items-center flex-wrap">
              <div className="relative intro-x">
                <input
                  type="checkbox"
                  onChange={(e) => this.selectCategory(e, subCategory)}
                  className="z-0 absolute left-0 invisible"
                  id={subCategory.id}
                  defaultChecked={
                    !!this.props?.categories?.category?.find(
                      (data) => data.id == subCategory.id
                    )
                  }
                />
                <label
                  onClick={() => { }}
                  htmlFor={subCategory.id}
                  className="bg-gray-200 hover:opacity-70 rounded-lg px-4 py-1.5 cursor-pointer inline-block"
                >
                  <p className="fs-13">{subCategory.name}</p>
                </label>
              </div>
            </div>
          ) : (
            categories?.category?.length > 0 &&
            categories.category.map((r_cat) => (
              <div key={r_cat.id} className="relative intro-x">
                <label className="bg--lightPrimary rounded-lg border--primary hover:opacity-80 px-4 py-1.5 cursor-pointer flex items-center gap-x-2">
                  <p className="fs-13">{r_cat.name}</p>
                  <div
                    onClick={() => {
                      let updatedCat = this.props.categories?.category?.filter(
                        (data) => data.id != r_cat.id
                      );

                      let form = Object.assign({}, this.props.form);

                      // console.log("updatedCat", updatedCat);

                      form["category"] = updatedCat;
                      this.props.selectCategories(form);
                      this.props.addRfqForm(form);
                    }}
                  >
                    <IoIosCloseCircle size={20} className="primary" />
                  </div>
                </label>
              </div>
            ))
          )}

          {/* /3rd place subcategories/ */}
          {this.state.expanded &&
            subCategory.items &&
            subCategory.items.map((item) => (
              <div key={item.id} className="relative intro-x">
                <input
                  type="checkbox"
                  onClick={(e) => this.selectSubCategory(e, item)}
                  className="z-0 absolute left-0 invisible"
                  id={item.id}
                  defaultChecked={
                    !!this.props?.categories?.category?.find(
                      (data) => data.id == item.id
                    )
                  }
                />
                <label
                  htmlFor={item.id}
                  className="bg-gray-200 hover:opacity-70 rounded-lg px-4 py-1.5 cursor-pointer inline-block"
                >
                  <p className="fs-13">{item.name}</p>
                </label>
              </div>
            ))}
        </div>
      </>
    );
  }
}
const mapStateToProps = ({ Rfq, Auth, Page }) => {
  return {
    user: Auth,
    form: Rfq.form,
    categories: Rfq.categories,
    showCurrentPage: Page.url,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("store/redux/RFQRedux");
  return {
    ...ownProps,
    ...stateProps,
    addRfqForm: (data) => {
      dispatch(actions.addRfqForm(data));
    },
    clearForm: () => {
      dispatch(actions.clearForm());
    },
    selectCategories: (data) => {
      dispatch(actions.selectCategories(data));
    },
  };
};
export default connect(
  mapStateToProps,
  undefined,
  mergeProps
)(ChooseRFQCategories);
