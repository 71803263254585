import * as React from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "assets/images/logo.png";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { connect } from "react-redux";
import { encryptfunction } from "helpers";

function Footer(props) {
  const { authUser } = props;
  const currentYear = new Date().getFullYear();
  return (
    <div className="bg-white">
      <div className="container py-16">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-4">
          <div className="my-auto w-full pl-4 md:pl-0">
            <NavLink to="/" className="items-center flex rounded-full">
              <img src={logo} alt="logo" className="h-32 object-contain" />
            </NavLink>
          </div>
          <div className="md:col-span-2">
            <div className="grid md:grid-cols-3 grid-cols-2 gap-3">
              <div className="gap-4 flex flex-col">
                <Link to={"/"} className="darkGray hover:primary fs-14 block">
                  Home
                </Link>
                <Link
                  to={`/${encryptfunction(authUser.id)}/profile`}
                  state={{ value: "edit", id: authUser.id }}
                  className="darkGray hover:primary fs-14 block"
                >
                  Profile
                </Link>
                <Link
                  to={"/leaders"}
                  className="darkGray hover:primary fs-14 block"
                >
                  Leaders
                </Link>
                {/* <Link
                  to={"/recommendation"}
                  state={{ selectedIndex: 0 }}
                  className="darkGray hover:primary fs-14 block "
                >
                  Recommendation
                </Link> */}
              </div>
              <div className="gap-4 flex flex-col">
                {/* <Link
                  to={"/rfq"}
                  className="darkGray hover:primary fs-14 block"
                >
                  Find RFQs
                </Link> */}
                <Link
                  to={"/rfq/1"}
                  state={{ cardId: 1 }}
                  className="darkGray hover:primary fs-14 block"
                >
                  My RFQs
                </Link>

                {props.authUser.is_seller ? (
                  <Link
                    to="/analytics"
                    state={{
                      title: "",
                      type: "seller",
                    }}
                    className="darkGray hover:primary fs-14 block "
                  >
                    analytics
                  </Link>
                ) : (
                  <Link
                    to="/analytics"
                    state={{
                      value: "analytics",
                      id: props.authUser.id,
                    }}
                    className="darkGray hover:primary fs-14 block "
                  >
                    analytics
                  </Link>
                )}
                <Link
                  to={"/rfq-ledger"}
                  state={{ cardId: 1 }}
                  className="darkGray hover:primary fs-14 block"
                >
                  My RFQ Ledger
                </Link>
              </div>
              <div className="gap-4 flex flex-col">
                {/* <Link to="/" className="darkGray hover:primary fs-14 block">
                  Help &#38; Faq's
                </Link> */}
                <Link
                  to="/contact-us"
                  className="darkGray hover:primary fs-14 block"
                >
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="divider" />
      <div className="container">
        <div className="grid xl:grid-cols-4 gap-3 py-3">
          <div className="flex flex-row items-center xl:justify-start justify-center xl:col-span-2">
            <p className="darkGray  fs-13 flex items-center tracking-wide">
              Copyright © {currentYear} Sourcing Genie All Rights Reserved
            </p>
          </div>
          <div className="gap-4 flex items-center xl:justify-end justify-center xl:col-span-2">
            <Link
              to="/privacy-policy"
              className="darkGray hover:primary fs-14 flex items-center tracking-wide "
            >
              Privacy
            </Link>
            <Link
              to="/disclaimer"
              className="darkGray hover:primary fs-14 flex items-center tracking-wide "
            >
              Disclaimer
            </Link>
            <Link
              to="/term-of-service"
              className="darkGray hover:primary fs-14 flex items-center tracking-wide"
            >
              Terms of Service
            </Link>
          </div>
          <div className="gap-4 flex flex-row items-center xl:justify-end justify-center">
            {/* <a
              className="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 black hover:bg-gray-200 hover:primary-all bg--primary w-10 h-10 rounded-full flex items-center justify-center"
              href="https://www.linkedin.com/company/procurement-league"
              target={"_blank"}
              rel="noreferrer"
            >
              <FaLinkedinIn size={18} className="white hover cursor-pointer" />
            </a>
            <a
              className="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 black hover:bg-gray-200 hover:primary-all bg--primary w-10 h-10 rounded-full flex items-center justify-center"
              href="https://www.facebook.com/procurementLeague"
              target={"_blank"}
              rel="noreferrer"
            >
              <FaFacebookF size={18} className="white hover cursor-pointer" />
            </a>
            <a
              className="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 black hover:bg-gray-200 hover:primary-all bg--primary w-10 h-10 rounded-full flex items-center justify-center"
              href="https://twitter.com/procurementlea"
              target={"_blank"}
              rel="noreferrer"
            >
              <FaTwitter size={18} className="white hover cursor-pointer" />
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ Auth }) => {
  return {
    authUser: Auth.authUserInformation,
  };
};
export default connect(mapStateToProps, null)(Footer);
