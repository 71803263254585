import { gql } from "@apollo/client";

export default gql`
  query {
    me {
      id
      companies(first: 10, page: 0) {
        data {
          id
          title
          slug
          address
          city
          state_id
          latitude
          longitude
          country
          country_code
          country_id
          postal_code
          phone
          email
          history
          linked_in_profile
          logo
          is_active
          is_deleted
          added_from
          is_primary
          state {
            id
            name
          }
          executives {
            id
            firstname
            lastname
            email
            username
            profile_photo
          }
        }
      }
    }
  }
`;
