import React, { Component } from "react";
import {
  Buttonlink,
  Fullscreenpopup,
  Loader,
  Noconnection,
  Nocontent,
  Subbanner,
} from "components";
import { Popup } from "semantic-ui-react";
import { Dropdown } from "semantic-ui-react";
import { BsHash } from "react-icons/bs";
import { Link } from "react-router-dom";
import { seo } from "../../../helpers";
import moment from "moment/moment";
import GET_LEADER_BOARD from "../../../graphql/queries/getLeaderBoard";
import { Alert, AlertTitle } from "@mui/material";
import { Query } from "@apollo/client/react/components";
import Auth from "../../../Auth";
import Profileavatar from "../../../components/profileavatar";
import { connect } from "react-redux";
import { encryptfunction } from "helpers";
import "./styles.css";
import { IoIosCloseCircle } from "react-icons/io";
import { FaHeart } from "react-icons/fa";
import client from "../../../graphql/client";
import PREFERRED_VENDOR from "../../../graphql/queries/me";
import MySelectedRFQsAction from "../../../store/actions/MySelectedRFQsAction";
import addPrefferedVendorMutation from "../../../graphql/mutations/addPrefferedVendoMutation";
import { checkPlan } from "helpers/checkPlan";
import { toast } from "react-toastify";
const auth = new Auth();

const year_options = [
  { key: "2023", text: "2023", value: "2023" },
  { key: "2022", text: "2022", value: "2022" },
  { key: "2021", text: "2021", value: "2021" },
  { key: "2020", text: "2020", value: "2020" },
  { key: "2019", text: "2019", value: "2019" },
  { key: "2018", text: "2018", value: "2018" },
  { key: "2017", text: "2017", value: "2017" },
];
const month_options = [
  { key: 1, text: "January", value: "01" },
  { key: 2, text: "February", value: "02" },
  { key: 3, text: "March", value: "03" },
  { key: 4, text: "April", value: "04" },
  { key: 5, text: "May", value: "05" },
  { key: 6, text: "June", value: "06" },
  { key: 7, text: "July", value: "07" },
  { key: 8, text: "August", value: "08" },
  { key: 9, text: "September", value: "09" },
  { key: 10, text: "October", value: "10" },
  { key: 11, text: "November", value: "11" },
  { key: 12, text: "December", value: "12" },
];

class Leaders extends Component {
  constructor(props) {
    super(props);
    this.selectFullPopup = React.createRef();
    this.SelectFullPopup = React.createRef();
    const currentmonthIntiger = moment().month();
    this.state = {
      tagsSelected: [],
      openModal: false,
      currentmonth: moment().month(currentmonthIntiger).format("MM"),
      currentyear: moment().year(),
      Selectedmonth: "",
      Selectedyear: "",
      modalType: "Tags",
      toastVisibility: true,
    };
  }

  componentDidMount() {
    seo({
      title: "LeaderBoard | Sourcing Genie",
    });
    this.setState({
      Selectedmonth: this.state.currentmonth.toString(),
      Selectedyear: this.state.currentyear.toString(),
    });
    this.props.addRfqForm({});
    this.props.selectCategories([]);
    this.props.selectTags({});
    this.getPreferredVendor();
  }

  getPreferredVendor = () => {
    client
      .query({
        query: PREFERRED_VENDOR,
        fetchPolicy: "network-only",
      })
      .then((responce) => {
        MySelectedRFQsAction.prefferedVendors(
          responce.data.me.preffered_vendors
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  openPopupModal = () => {
    this.selectFullPopup.current.openPopup();
  };

  handleInputYearChange = (event, data) => {
    this.setState({
      ...this.state,
      Selectedyear: data.value,
    });
  };

  handleInputMonthChange = (event, data) => {
    this.setState({
      ...this.state,
      Selectedmonth: data.value,
    });
  };
  ChooseCategores = () => {
    this.SelectFullPopup.current.openPopup();
  };

  likeVendor = (id) => {
    const canLikeVendor = checkPlan("bool", "Select Preferred Vendor", this.props?.authUser?.payments);
    if (!canLikeVendor) {
      if (this.state.toastVisibility) {
        this.setState({ toastVisibility: false });
        this.notify("You are not allowed for this feature. Please upgrade your plan");

        setTimeout(() => {
          this.setState({ toastVisibility: true });
        }, 5000);
      }
    } else {
      client
        .mutate({
          mutation: addPrefferedVendorMutation,
          variables: {
            seller_id: [parseInt(id)],
            action: "like",
          },
        })
        .then((res) => {
          // console.log("like", res);
          this.getPreferredVendor();
        })
        .catch((e) => {
          console.log(e);
        });
    }

  };

  dislikeVendor = (id) => {
    console.log("dislike");
    client
      .mutate({
        mutation: addPrefferedVendorMutation,
        variables: {
          seller_id: [parseInt(id)],
          action: "dislike",
        },
      })
      .then((res) => {
        // console.log("dislike", res);
        MySelectedRFQsAction.removedVendor(parseInt(id));
      })
      .catch((e) => {
        console.log(e);
      });
  };
  notify = (message = "", type = "error") => {
    // Dismiss any existing toasts before showing a new one
    toast.dismiss();
    if (type === "error") {
      return toast.error(message, { position: "top-right" });
    }
    toast.success(message, { position: "top-right" });
  };

  render() {
    let variables = {};
    const { Selectedyear, Selectedmonth, modalType } = this.state;

    let idscat =
      this.props.form?.category?.map((category) => parseInt(category.id)) || [];
    variables.category_id = idscat;

    if (Selectedyear !== "") {
      variables.year = Selectedyear.toString();
    }

    if (Selectedmonth !== "") {
      variables.month = Selectedmonth;
    }

    // if (this.props.tags) {

    //   let ids = [];
    //   Object.entries(this.props.tags).forEach(([key, value]) => {
    //     console.log('value', value);
    //     //value?.map((tag) => ids.push(tag.id));
    //   });
    //   variables.tags = ids;
    // }

    const { authUser } = this.props;
    const { open } = this.state;

    // console.log("this.props.preffered_vendors", this.props.preffered_vendors);
    return (
      <div>
        <Subbanner title="Top Leaders" />
        <div className="container -mt-28">
          <div className="grid xl:grid-cols-3 grid-cols-1 gap-4">
            <div className="intro-y">
              <div className="box md:max-w-md max-w-full mx-auto rounded--xl shadow-lg">
                <div className="px-5 py-8 space-y-5">
                  {!this.props.is_seller && (
                    <div className="">
                      <Buttonlink
                        onClick={this.ChooseCategores}
                        text="Choose Categories"
                        title="Choose Categories"
                        className=" w-full hover:white flex justify-center items-center border border-transparent rounded-lg primary bg--lightPrimary hover:bg--primary focus:outline-none h-11"
                      />
                    </div>
                  )}

                  {this.props.categories?.category && (
                    <div className="flex items-center flex-wrap gap-x-4 selected-tags">
                      <div className="gap-2 flex items-center flex-wrap">
                        {this.props.categories?.category?.map((item) => {
                          return (
                            <div className="relative">
                              <div className="bg--lightPrimary hover:opacity-70 rounded-lg px-4 py-1.5 cursor-pointer flex items-center gap-x-2">
                                <p className="fs-13">{item.name}</p>
                                <div
                                  onClick={() => {
                                    let updatedCat =
                                      this.props.categories.category.filter(
                                        (data) => data.id != item.id
                                      );

                                    let form = Object.assign({}, this.props.form);

                                    console.log("updatedCat", updatedCat);

                                    form["category"] = updatedCat;

                                    this.props.selectCategories(form);
                                    this.props.addRfqForm(form);
                                  }}
                                >
                                  <IoIosCloseCircle
                                    size={20}
                                    className="primary"
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  <div className="shadow-sm rounded-lg">
                    <Dropdown
                      className="Light-all"
                      placeholder="Select Year"
                      fluid
                      selection
                      value={Selectedyear}
                      options={year_options}
                      onChange={this.handleInputYearChange}
                    />
                  </div>
                  <div className="shadow-sm rounded-lg">
                    <Dropdown
                      className="Light-all darkGray"
                      placeholder="Select Month"
                      fluid
                      selection
                      value={Selectedmonth}
                      options={month_options}
                      onChange={this.handleInputMonthChange}
                    />
                  </div>
                  {/*{
                      !this.props.is_seller &&
                      <div
                          onClick={this.openPopupModal}
                          className="cursor-pointer appearance-none rounded-md relative block w-full px-4 py-2.5 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                      >
                        <div className="flex items-center justify-between">
                          <p className="Light darkGray">Select Tags</p>
                          <BsFillCaretRightFill className="darkGray" size={10} />
                        </div>
                      </div>
                  }

                  <div className="flex items-center flex-wrap gap-2 selected-tags">
                    {this.props.tags &&
                      this.props.tags.tags &&
                      this.props.tags.tags.map((tag, index) => (
                        <div className="relative">
                          <div className="border-2 rounded-full border--primary hover:opacity-70 px-4 py-1.5 cursor-pointer flex items-center gap-x-2">
                            <p className="fs-13" key={index}>
                              {tag.name}
                            </p>
                            <div
                              onClick={() => {
                                let updatedTags = this.props.tags.tags.filter(
                                  data => data.id !== tag.id
                                );

                                let form = {};

                                form["tags"] = updatedTags;

                                this.props.selectTags(form);
                                this.props.addRfqFields(form);
                              }}
                            >
                              <IoIosCloseCircle size={20} className="primary" />
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>*/}
                </div>
              </div>
            </div>
            <Query
              query={GET_LEADER_BOARD}
              variables={variables}
              fetchPolicy={"cache-and-network"}
            >
              {({ loading, error, data, fetchMore, refetch }) => {
                if (loading) {
                  return (
                    <div className="my-10">
                      <Loader />
                    </div>
                  );
                }
                // console.log("data.leaderboard", data.leaderboard);
                if (error) {
                  return (
                    // <div className="flex justify-center text-center flex-col m-auto">
                    <div>
                      {auth.isAuthenticated() ? (
                        <Noconnection />
                      ) : (
                        <div style={{ fontSize: 19 }}>
                          <Alert severity="warning">
                            <AlertTitle>
                              <strong>Unauthenticated</strong>
                            </AlertTitle>
                            Please <button className={"primary"}>Login</button>{" "}
                            To Connect With Other Procurement Practitioners And
                            View LeaderBoard.
                          </Alert>
                        </div>
                      )}
                    </div>
                  );
                }
                if (data !== undefined && data.leaderboard.length > 0) {
                  // console.log("data.leaderboard",data.leaderboard)
                  return (
                    <div className="col-span-2 box overflow-hidden intro-y">
                      <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50 intro-y">
                            <tr>
                              <th scope="col" className="px-6 py-3">
                                <div className=" flex items-center">
                                  <div className="w-20">
                                    <BsHash size={18} className="darkGray" />
                                  </div>
                                  <p className="darkGray fs-12 Light uppercase tracking-wider">
                                    Name
                                  </p>
                                </div>
                              </th>
                              <th scope="col" className="px-6 py-3">
                                <p className="darkGray fs-12 Light uppercase tracking-wider  text-left">
                                  {authUser.is_seller ? "Points" : "Average"}
                                </p>
                              </th>
                              {!this.props.is_seller && (
                                <th scope="col" className="px-6 py-3">
                                  <p className="darkGray fs-12 Light uppercase tracking-wider  text-left">
                                    Preferred Vendor
                                  </p>
                                </th>
                              )}
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {data.leaderboard
                              .filter((o) => o.total_offers !== 0)
                              .map((person, index) => (
                                <tr key={person.id} className="intro-y zoom-in">
                                  <td className="px-6 py-3 whitespace-nowrap">
                                    <div className=" flex items-center">
                                      <div className="w-20">
                                        <div className="w-7 h-7 bg--primary rounded-full flex items-center justify-center">
                                          <p className="white fs-12 Light uppercase tracking-wider">
                                            {index + 1}
                                          </p>
                                        </div>
                                      </div>

                                      {authUser.is_seller ? (
                                        <Popup
                                          trigger={
                                            <Link
                                              to={`/${encryptfunction(
                                                person.id
                                              )}/profile`}
                                              state={{
                                                value: "edit",
                                                id: person.id,
                                              }}
                                              className="flex items-center gap-x-3"
                                            >
                                              <div className="flex-shrink-0">
                                                <Profileavatar
                                                  singleUser={person}
                                                  imagesize={{
                                                    width: 42,
                                                    height: 42,
                                                  }}
                                                />
                                              </div>
                                              <div>
                                                <p className="fs-13 Medium">
                                                  {person.firstname}{" "}
                                                  {person.lastname}
                                                </p>
                                                <p className="fs-10 gray">
                                                  @{person.username}
                                                </p>
                                              </div>
                                            </Link>
                                          }
                                          content={
                                            <div className="profile-stats">
                                              <p className="Medium fs-20 black">
                                                Buyer Quality Rating System
                                              </p>
                                              <p className="Medium fs-16 rounded-sm bg-black py-1 px-3 text-white">
                                                Rating Scale
                                              </p>
                                              <div className="lg:grid hidden grid-cols-5 bg-gray-300 py-1 px-3 gap-3 rounded-sm">
                                                <p className="Medium fs-14 black">
                                                  5 - Excellent
                                                </p>
                                                <p className="Medium fs-14 black">
                                                  4 - Good
                                                </p>
                                                <p className="Medium fs-14 black">
                                                  3 - Fair
                                                </p>
                                                <p className="Medium fs-14 black">
                                                  2 - Poor
                                                </p>
                                                <p className="Medium fs-14 black">
                                                  1 - Extremely Poor
                                                </p>
                                              </div>
                                              <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-3 mt-2">
                                                <div className="text-center bg-gray-300 pt-4 pb-5 px-3 rounded-md">
                                                  <p className="mb-3 fs-14 min-h-[60px] flex items-center justify-center">
                                                    Responsiveness to RFQs
                                                  </p>
                                                  <p className="fs-14 mb-2">
                                                    Average Rating:
                                                  </p>
                                                  <p className="bg-gray-200 rounded-md py-1 px-4 inline-flex items-center justify-center mx-auto mb-4 w-[50px] h-[32px]">
                                                    {person.buyer_reviews_avg_rate ===
                                                      null
                                                      ? "--"
                                                      : Math.round(
                                                        (person.buyer_reviews_avg_rate +
                                                          Number.EPSILON) *
                                                        100
                                                      ) / 100}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          }
                                          className="profile-tooltip shadow-lg"
                                          inverted
                                        />
                                      ) : (
                                        <Popup
                                          trigger={
                                            <Link
                                              to={`/${encryptfunction(
                                                person.id
                                              )}/profile`}
                                              state={{
                                                value: "edit",
                                                id: person.id,
                                              }}
                                              className="flex items-center gap-x-3"
                                            >
                                              <div className="flex-shrink-0">
                                                <Profileavatar
                                                  singleUser={person}
                                                  imagesize={{
                                                    width: 42,
                                                    height: 42,
                                                  }}
                                                />
                                              </div>
                                              <div>
                                                <p className="fs-13 Medium">
                                                  {person.firstname}{" "}
                                                  {person.lastname}
                                                </p>
                                                <p className="fs-10 gray">
                                                  @{person.username}
                                                </p>
                                              </div>
                                            </Link>
                                          }
                                          content={
                                            <div className="profile-stats">
                                              <p className="Medium fs-20 black">
                                                Seller Quality Rating System
                                              </p>
                                              <p className="Medium fs-16 rounded-sm bg-black py-1 px-3 text-white">
                                                Rating Scale
                                              </p>
                                              <div className="lg:grid hidden grid-cols-5 bg-gray-300 py-1 px-3 gap-3 rounded-sm">
                                                <p className="Medium fs-14 black">
                                                  5 - Excellent
                                                </p>
                                                <p className="Medium fs-14 black">
                                                  4 - Good
                                                </p>
                                                <p className="Medium fs-14 black">
                                                  3 - Fair
                                                </p>
                                                <p className="Medium fs-14 black">
                                                  2 - Poor
                                                </p>
                                                <p className="Medium fs-14 black">
                                                  1 - Extremely Poor
                                                </p>
                                              </div>
                                              <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-3 mt-2">
                                                <div className="text-center bg-gray-300 pt-4 pb-5 px-3 rounded-md">
                                                  <p className="mb-3 fs-14 min-h-[60px] flex items-center justify-center">
                                                    Responsiveness to RFQs
                                                  </p>
                                                  <p className="fs-14 mb-2">
                                                    Average Rating:
                                                  </p>
                                                  <p className="bg-gray-200 rounded-md py-1 px-4 inline-flex items-center justify-center mx-auto mb-4 w-[50px] h-[32px]">
                                                    {Math.round(
                                                      (person.seller_reviews_avg_responsiveness +
                                                        Number.EPSILON) *
                                                      100
                                                    ) / 100}
                                                  </p>
                                                </div>
                                                <div className="text-center bg-gray-300 pt-4 pb-5 px-3 rounded-md">
                                                  <p className="mb-3 fs-14 min-h-[60px] flex items-center justify-center">
                                                    Ability to Qoute Parts
                                                    Listed
                                                  </p>
                                                  <p className="fs-14 mb-2">
                                                    Average Rating:
                                                  </p>
                                                  <p className="bg-gray-200 rounded-md py-1 px-4 inline-flex items-center justify-center mx-auto mb-4 w-[50px] h-[32px]">
                                                    {Math.round(
                                                      (person.seller_reviews_avg_shipment +
                                                        Number.EPSILON) *
                                                      100
                                                    ) / 100}
                                                  </p>
                                                </div>
                                                <div className="text-center bg-gray-300 pt-4 pb-5 px-3 rounded-md">
                                                  <p className="mb-3 fs-14 min-h-[60px] flex items-center justify-center">
                                                    Ability to Ship Parts
                                                    Ordered
                                                  </p>
                                                  <p className="fs-14 mb-2">
                                                    Average Rating:
                                                  </p>
                                                  <p className="bg-gray-200 rounded-md py-1 px-4 inline-flex items-center justify-center mx-auto mb-4 w-[50px] h-[32px]">
                                                    {Math.round(
                                                      (person.seller_reviews_avg_lead_time +
                                                        Number.EPSILON) *
                                                      100
                                                    ) / 100}
                                                  </p>
                                                </div>
                                                <div className="text-center bg-gray-300 pt-4 pb-5 px-3 rounded-md">
                                                  <p className="mb-3 fs-14 min-h-[60px] flex items-center justify-center">
                                                    Delivered Parts Met Order
                                                    Specification
                                                  </p>
                                                  <p className="fs-14 mb-2">
                                                    Average Rating:
                                                  </p>
                                                  <p className="bg-gray-200 rounded-md py-1 px-4 inline-flex items-center justify-center mx-auto mb-4 w-[50px] h-[32px]">
                                                    {Math.round(
                                                      (person.seller_reviews_avg_delivered +
                                                        Number.EPSILON) *
                                                      100
                                                    ) / 100}
                                                  </p>
                                                </div>
                                                <div className="text-center bg-gray-300 pt-4 pb-5 px-3 rounded-md">
                                                  <p className="mb-3 fs-14 min-h-[60px] flex items-center justify-center">
                                                    Handling of Return Requests
                                                  </p>
                                                  <p className="fs-14 mb-2">
                                                    Average Rating:
                                                  </p>
                                                  <p className="bg-gray-200 rounded-md py-1 px-4 inline-flex items-center justify-center mx-auto mb-4 w-[50px] h-[32px]">
                                                    {Math.round(
                                                      (person.seller_reviews_avg_compliance +
                                                        Number.EPSILON) *
                                                      100
                                                    ) / 100}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          }
                                          className="profile-tooltip shadow-lg"
                                          inverted
                                        />
                                      )}
                                    </div>
                                  </td>
                                  <td className="px-6 py-3 whitespace-nowrap">
                                    <span className="px-3 inline-flex text-xs Regular leading-5 rounded-full bg-green-100 text-green-800">
                                      {authUser.is_seller
                                        ? person.total_offers
                                        : Math.round(
                                          (person.seller_reviews_avg_rate +
                                            Number.EPSILON) *
                                          100
                                        ) / 100}
                                    </span>
                                  </td>
                                  {!this.props.is_seller && (
                                    <td>
                                      <FaHeart
                                        size={18}
                                        className="ml-20 cursor-pointer"
                                        onClick={() =>
                                          this.props.preffered_vendors.find(
                                            (o) =>
                                              o.seller_id ===
                                              parseInt(person.id)
                                          )
                                            ? this.dislikeVendor(person.id)
                                            : this.likeVendor(person.id)
                                        }
                                        color={`${this.props.preffered_vendors.find(
                                          (o) =>
                                            o.seller_id == parseInt(person.id)
                                        )
                                          ? "#ef553b"
                                          : "#9da4b4"
                                          }`}
                                      />
                                    </td>
                                  )}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="col-span-2 box overflow-hidden intro-y flex items-center justify-center">
                      <Nocontent text="It's quite empty out here, engage with more RFQs to become a leader" />
                    </div>
                  );
                }
              }}
            </Query>
          </div>
        </div>
        {/*<Fullscreenpopup ref={this.selectFullPopup} type={modalType} />*/}
        <Fullscreenpopup
          ref={this.SelectFullPopup}
          type={"Choose Category"}
          clearState={(value) => console.log("value", value)}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ Rfq, Auth, RFQs }) => {
  return {
    tags: Rfq.selectedTags,
    authUser: Auth.authUserInformation,
    form: Rfq.form,
    categories: Rfq.categories,
    preffered_vendors: RFQs.prefferedVendors,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("store/redux/RFQRedux");
  return {
    ...ownProps,
    ...stateProps,

    selectTags: (data) => {
      dispatch(actions.selectTags(data));
    },
    selectCategories: (data) => {
      dispatch(actions.selectCategories(data));
    },
    addRfqForm: (data) => {
      dispatch(actions.addRfqForm(data));
    },
    addRfqFields: (data) => {
      dispatch(actions.addRfqFields(data));
    },
  };
};

export default connect(mapStateToProps, undefined, mergeProps)(Leaders);
