import { gql } from "@apollo/client";

export default gql`
  mutation addPrefferedVendor($seller_id: [Int]!, $action: String!) {
    addPrefferedVendor(seller_id: $seller_id, action: $action) {
      error
      message
    }
  }
`;
