import { gql } from "@apollo/client";

export default gql`
  query (
    $first: Int!
    $page: Int!
    $is_tags: Boolean
    $is_rfqs: Boolean
    $key: String
  ) {
    quick_search_rfqs(
      first: $first
      page: $page
      is_tags: $is_tags
      is_rfqs: $is_rfqs
      key: $key
    ) {
      data {
        id
        title
        readable_status
        categories {
          id
          name
          code
        }
        tags {
          id
          name
        }
      }
      paginatorInfo {
        hasMorePages
        total
        perPage
        lastItem
        currentPage
      }
    }
  }
`;
