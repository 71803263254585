// import * as React from "react";
import React, { Component } from "react";
import { Popover } from "@headlessui/react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { IoIosArrowDown, IoIosPaper } from "react-icons/io";
import { Buttonlink } from "components";
import { BsChatRightQuote } from "react-icons/bs";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import client from "../../../graphql/client";
import GET_MENU from "../../../graphql/queries/mro/menu";
import { Loader } from "components";
import { checkPlan } from "helpers/checkPlan";

class Getquotation extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      loader: true,
      expanded: false,
      redirect: false,
      pData: [],
      quot: [],
      rec: [],
    };
  }

  componentDidMount() {

    client
      .query({
        query: GET_MENU,
      })
      .then((result) => {
        // console.log("result",result)
        this.setState({ loader: false });
        let parentNames = result.data?.menu.filter((i) => i.parent === 0);
        let getQuotData = result.data?.menu.filter((i) => i.parent === 1);
        let getRecData = result.data?.menu.filter((i) => i.parent === 2);
        this.setLocalData(parentNames, getQuotData, getRecData);
        if (this.props.rfqclick) {
          setTimeout(() => {
            this.myRef.current.click();
          }, 1000);
          setTimeout(() => {
            document
              .querySelector(".get-qoute-opt")
              .children[0].children[0].classList.add("bg--primary");
            document
              .querySelector(".get-qoute-opt")
              .children[0].children[0].classList.add("white");
            setTimeout(() => {
              document
                .querySelector(".get-qoute-opt")
                .children[0].children[0].classList.remove("bg--primary");
              document
                .querySelector(".get-qoute-opt")
                .children[0].children[0].classList.remove("white");
              document
                .querySelector(".get-qoute-opt")
                .children[0].children[0].click();
            }, 1000);
          }, 2000);
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the query
        console.error(error);
      });
  }

  setLocalData = (parent, quot, rec) => {
    parent?.forEach((element) => {
      this.setState((state) => {
        state.pData.push(element);
      });
    });
    quot?.forEach((element) => {
      this.setState((state) => {
        state.quot.push(element);
      });
    });

    rec?.forEach((element) => {
      this.setState((state) => {
        state.rec.push(element);
      });
    });
  };

  handleChange = (panel) => (event, isExpanded) => {
    this.setState({
      expanded: isExpanded ? panel : false,
    });
  };

  setQuotId = (menu) => {
    this.setState({
      redirect: true,
    });
    this.props.addQuotId(menu);
  };

  render() {
    // const canPostRFQ = checkPlan("sum", "No. of RFQs", this.props.user?.authUserInformation?.payments);
    const { redirect, expanded, pData, quot, rec } = this.state;
    const { menus } = this.props;
    if (this.state.loader) {
      // Display a loader while data is loading
      return <Loader />;
    }
    if (redirect === true) {
      return <Navigate replace to="/post-rfq" />;
    }
    return (
      <div className="get-quotation">
        <Accordion
          expanded={expanded === "panel1"}
          onChange={this.handleChange("panel1")}
          className="shadow--none"
          square={true}
        >
          <AccordionSummary
            expandIcon={
              <div className="bg--primary white rounded p-1">
                <IoIosArrowDown size={18} />
              </div>
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className="bg--lightGray"
            square={true}
          >
            <p
              ref={this.myRef}
              // onClick={this.checkClick}
              className="text-xl flex items-center gap-x-3"
            >
              <BsChatRightQuote size={18} className="primary" />
              Get Quotation
            </p>
          </AccordionSummary>
          <AccordionDetails>
            <div className="space-y-3 get-qoute-opt">
              {quot.map((data) => (
                <Popover.Button as="div" id={data.id}>
                  <Buttonlink
                    to={`/post-rfq/${data?.code}`}
                    state={{ menu: data, data: {}, editable: false }}
                    text={data.name}
                    className="bg-gray-100 hover:bg--primary hover:white cursor-pointer flex py-2 px-4 rounded-lg"
                  />
                </Popover.Button>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>

        <div className="h-3" />
        {/* <Accordion
          expanded={expanded === "panel2"}
          onChange={this.handleChange("panel2")}
          className="shadow--none"
        >
          <AccordionSummary
            expandIcon={
              <div className="bg--primary white rounded p-1">
                <IoIosArrowDown size={18} />
              </div>
            }
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <p className="text-xl flex items-center gap-x-3">
              <IoIosPaper size={20} className="primary" />
              Get Recommendation
            </p>
          </AccordionSummary>
          <AccordionDetails>
            <div className="space-y-3">
              {rec.map((data) => (
                <Popover.Button as="div" id={data.id} key={data.id}>
                  <Buttonlink
                    to={`/post-rfq/${data?.code}`}
                    state={{ menu: data, data: {}, editable: false }}
                    text={data.name}
                    className="bg-gray-100 hover:bg--primary hover:white cursor-pointer flex py-2 px-4 rounded-lg"
                  />
                </Popover.Button>
              ))}
            </div>
          </AccordionDetails>
        </Accordion> */}
      </div>
    );
  }
}
const mapStateToProps = ({ Rfq, Auth }) => {
  return {
    user: Auth,
    form: Rfq.form,
    // getQuotId : Rfq.getQuotId
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("store/redux/RFQRedux");
  return {
    ...ownProps,
    ...stateProps,
    addQuotId: (data) => {
      dispatch(actions.addQuotId(data));
    },
  };
};
export default connect(mapStateToProps, undefined, mergeProps)(Getquotation);
