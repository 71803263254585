import React, { Component } from "react";
import { BiLockAlt } from "react-icons/bi";
import { BiLoaderAlt } from "react-icons/bi";
import logo from "../../../../assets/images/logo.png";
import { IoIosArrowBack } from "react-icons/io";
import { Buttonlink } from "components";
import { Link } from "react-router-dom";
import { seo } from "helpers";
import { connect } from "react-redux";
import client from "../../../../graphql/client";
import LOGIN from "../../../../graphql/mutations/loginMutation";
import cookie from "js-cookie";
import { GRAPH_MRO_USER_AUTH_TOKEN } from "../../../../config/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import { IoCloseCircleOutline } from "react-icons/io5";
import MyAuthUserAction from "../../../../store/actions/MyAuthUserAction";
import MyCurrentPageAction from "../../../../store/actions/MyCurrentPageAction";
import Alert from "@mui/material/Alert";

class Body extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            loading: false,
            error: false,
            message: props.RedirectTo?.state?.message?.length > 0 ? props.RedirectTo?.state?.message : "",
            pswdType: true,
            disabled: true,
            open: props.RedirectTo?.state?.message?.length > 0,
            longitude: null,
            latitude: null,
            isLoacationPermitted: false,
        };
    }

    componentDidMount() {
        MyCurrentPageAction.currentPageUrl('login')
        seo({
            title: "Login | Sourcing League",
        });
        if (this.props.RedirectTo?.state?.newUser) {
            return toast.success(this.props.RedirectTo?.state?.newUser);
        }

        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;

                    this.setState(
                        {
                            isLocationPermitted: true,
                            latitude: latitude,
                            longitude: longitude,
                        },
                        () => {
                            console.log('Latitude: ' + this.state.latitude);
                            console.log('Longitude: ' + this.state.longitude);
                        }
                    );

                    // You can do something with the latitude and longitude here, like displaying it on a map or using it in your application.
                },
                (error) => {
                    // Handle errors
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            console.error("User denied the request for Geolocation.");
                            this.setState({ isLocationPermitted: false });
                            break;
                        case error.POSITION_UNAVAILABLE:
                            this.setState({ isLocationPermitted: false });
                            console.error("Location information is unavailable.");
                            break;
                        case error.TIMEOUT:
                            console.error("The request to get user location timed out.");
                            this.setState({ isLocationPermitted: false });
                            break;
                        case error.UNKNOWN_ERROR:
                            console.error("An unknown error occurred.");
                            break;
                    }
                }
            );
        } else {
            // Geolocation is not available in this browser
            this.setState({ isLocationPermitted: false });
            console.error("Geolocation is not supported by your browser.");
        }
    }

    simulateNetworkRequest = () => {
        return new Promise((resolve) => setTimeout(resolve, 2000));
    };

    notify = (type, message) => {
        // Dismiss any existing toasts before showing a new one
        toast.dismiss();
        if (type === "error") {
            return toast.error(message);
        }
        if (type === "success") {
            return toast.success(message);
        }
        if (type === "warn") {
            return toast.warn(message);
        }
    };

    promise = (error, message) => {
        const resolveAfter3Sec = new Promise((resolve, reject) => {
            if (error) {
                setTimeout(reject, 500);
            } else {
                setTimeout(resolve, 1);
            }
        });
        toast.promise(resolveAfter3Sec, {
            pending: "Please Wait...",
            success: "Welcome to Sourcing League",
            error: message,
        });
    };

    handleInfo = (e) => {
        e.preventDefault();
        this.setState(
            {
                ...this.state,
                [e.target.name]: e.target.value,
                disabled: false,
                loading: false,
            },
            () => {
                if (this.state.email.length === 0 || this.state.password.length === 0) {
                    this.setState({
                        disabled: true,
                        loading: false,
                    });
                } else {
                    this.setState({
                        disabled: false,
                        loading: false,
                    });
                }
            }
        );
    };

    handleLoginSubmit = async (event) => {
        event.preventDefault();
        this.setState({
            disabled: true,
            loading: true,
        });
        const { email, password, longitude, latitude } = this.state;

        if (email === "") {
            await this.simulateNetworkRequest();
            this.setState({
                disabled: false,
                loading: false,
            });
            return this.promise(true, "Email cannot be empty.");
        }

        if (email) {
            const emailRegex = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            if (!emailRegex.test(email)) {
                await this.simulateNetworkRequest();
                this.setState({
                    disabled: false,
                    loading: false,
                    error: false
                });
                return this.promise(true, "Please enter a valid email.");
            }
        }

        if (password === "") {
            await this.simulateNetworkRequest();
            this.setState({
                disabled: false,
                loading: false,
                error: false
            });
            return this.promise(true, "Password cannot be empty.");
        }

        try {
            const response = await client.mutate({
                mutation: LOGIN,
                variables: {
                    email: email,
                    password: password,
                    longitude: longitude,
                    latitude: latitude,
                },
            });

            const { error, message, token } = response.data.login;

            if (error === true) {
                await this.simulateNetworkRequest();
                this.setState({
                    disabled: false,
                    loading: false,
                    password: "",
                    error: true,
                });
                return this.promise(true, message);
            } else {
                cookie.set(GRAPH_MRO_USER_AUTH_TOKEN, token);
                this.props.handleAuthUser(token);
                this.setState(
                    {
                        error: false,
                    },
                    () => {
                        setTimeout(() => {
                            MyAuthUserAction.getAuthUserInformation().then((res) => {
                                if (res) {
                                    const { RedirectTo } = this.props;
                                    const params = RedirectTo?.state?.params;

                                    if (params) {
                                        const { id, details } = params;
                                        this.props.navigate(`/rfq/${id}/${details}`);
                                    } else {
                                        this.props.navigate("/");
                                    }
                                }
                            });
                        });
                    }
                );
            }
        } catch (err) {
            this.setState({
                disabled: false,
                loading: false,
            });
            return this.promise(
                true,
                "Oops, something went wrong. Please try again later."
            );
        }
    };

    showEyePswd = () => {
        this.setState({ pswdType: !this.state.pswdType });
    };
    closePopup = () => {
        this.setState({ open: false });
    };

    render() {
        const { email, password, disabled, loading, error } = this.state;
        const { open } = this.state;
        return (
            <div className="min-h-full  container px-4 sm:px-6 lg:px-8">
                <ToastContainer position="top-right" autoClose={1500} />
                <div className="flex items-center flex-grow min-h-[100vh]">
                    <div className="md:grid md:grid-cols-2 flex-grow ">
                        <div className="intro-y mb-10 md:mb-0 flex flex-col justify-center items-center relative">
                            {/*<div className="absolute left-0 top-0 pt-10 md:pt-0">
                                <Buttonlink
                                    text="Back"
                                    prefix={<IoIosArrowBack size={16}/>}
                                    to="/"
                                    className="bg-gray-300 hover:opacity-80 fs-13 flex items-center justify-center gap-x-1 h-9 rounded-full darkGray w-24 border-0 shadow-none"
                                />
                            </div>*/}
                            <img
                                src={logo}
                                alt="logo"
                                onClick={()=>this.props.navigate("/")}
                                className="w-72 object-contain cursor-pointer mx-auto md:mt-0 mt-[100px]"
                            />
                            {/*<h2 className="mt-5 md:mt-14 text-center lg:text-6xl text-3xl Medium text-[#5C0632] font-bold italic">
                                Welcome
                            </h2>*/}
                        </div>
                        <form
                            className="bg-white p-10 rounded-xl intro-y"
                            onSubmit={this.handleLoginSubmit}
                        >
                            <input type="hidden" name="remember" defaultValue="true" />
                            <div className="space-y-6">
                                <div className="intro-x">
                                    <label htmlFor="email-address" className="Regular">
                                        Email address
                                    </label>
                                    <input
                                        id="email-address"
                                        name="email"
                                        type="email"
                                        maxLength={64}
                                        autoComplete="email"
                                        value={email}
                                        onChange={this.handleInfo}
                                        className={`appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-200 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm ${this.state.email.length === 0 ? "border-red-600" : null
                                            }`}
                                    />
                                </div>
                                <div className="intro-x">
                                    <label htmlFor="password" className="Regular">
                                        Password
                                    </label>
                                    <div className="flex items-center relative">
                                        <input
                                            type={this.state.pswdType ? "password" : "text"}
                                            id="password"
                                            name="password"
                                            maxLength={32}
                                            autoComplete="current-password"
                                            value={password}
                                            onChange={this.handleInfo}
                                            className={`appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-200 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm ${this.state.password.length === 0
                                                ? "border-red-600"
                                                : null
                                                }`}
                                        />
                                        <div
                                            onClick={this.showEyePswd}
                                            className={`cursor-pointer h-12 w-12 flex items-center justify-center absolute right-0 ${this.state.password.length === 0 ? "text-red-600" : null
                                                }`}
                                        >
                                            {this.state.pswdType ? (
                                                <BsEye size={20} />
                                            ) : (
                                                <BsEyeSlash size={20} />
                                            )}
                                        </div>
                                    </div>

                                    <div className="flex flex-wrap items-center justify-between">
                                        <div className="flex items-center -ml-3">
                                            {/*<Checkbox
                        className="primary-checkbox"
                        id="agree"
                        name="agree"
                      />
                      <label htmlFor="remember-me" className="Regular fs-13">
                        Remember me
                      </label>*/}
                                        </div>

                                        <div className="text-sm">
                                            <Link
                                                to="/reset-password"
                                                className="primary"
                                                title="forgot password"
                                                rel="nofollow"
                                            >
                                                Forgot your password?
                                            </Link>
                                        </div>
                                    </div>
                                    <div>
                                        {error ? <div style={{ height: 15 }} /> : false}
                                        {error ? <Alert severity="error">Wrong password / wrong email id. Try again or click forgot password to reset it.</Alert> : ""}
                                    </div>
                                </div>
                                <button
                                    onClick={this.handleLoginSubmit}
                                    disabled={disabled}
                                    rel="nofollow"
                                    className={`relative w-full hover:white hover:opacity-80 h-12 flex items-center justify-center px-4 border border-transparent rounded-lg text-white bg--primary hover:bg--primary ${disabled ? "opacity-50" : ""
                                        } focus:outline-none`}
                                >
                                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                        <BiLockAlt className="h-5 w-5 white" aria-hidden="true" />
                                    </span>
                                    {loading ? (
                                        <BiLoaderAlt className="animate-spin white" size={20} />
                                    ) : (
                                        <>Login</>
                                    )}
                                </button>
                                {/* <div className="flex items-center gap-x-4 intro-x">
                    <div className="divider w-full" />
                    <p className="whitespace-nowrap Light fs-13 darkGray">
                      or continue with
                    </p>
                    <div className="divider w-full" />
                  </div>
                  <div className="flex items-center justify-center gap-x-10   intro-x">
                    <Buttonlink
                        onClick={true}
                        prefix={<FcGoogle size={28} />}
                        className="relative w-full hover:white hover:opacity-80 hover:bg-slate-100 flex justify-center py-2.5 px-4 border rounded-lg text-white bg-transparent hover:bg-transparent focus:outline-none "
                    />
                    <Buttonlink
                        onClick={true}
                        prefix={<FaLinkedin size={28} color="#0966C2" />}
                        className="relative w-full hover:white hover:opacity-80 hover:bg-slate-100 flex justify-center py-2.5 px-4 border rounded-lg text-white bg-transparent hover:bg-transparent focus:outline-none "
                    />
                  </div>
                  <div className="h-5   intro-x" /> */}
                                <div className="flex items-center gap-x-4   intro-x">
                                    <div className="divider w-full" />
                                    <p className="whitespace-nowrap Light fs-13 darkGray">
                                        Don't have an account?
                                    </p>
                                    <div className="divider w-full" />
                                </div>
                                <Buttonlink
                                    to="/registration"
                                    text="Create Account"
                                    title="Create Account"
                                    rel="nofollow"
                                    className="relative intro-x w-full hover:white hover:opacity-80 flex justify-center py-2.5 px-4 border border-transparent rounded-lg primary bg--lightPrimary  hover:bg--primary focus:outline-none "
                                />
                            </div>
                        </form>
                    </div>
                </div>
                <Dialog
                    fullWidth={false}
                    maxWidth={"sm"}
                    open={open}
                    onClose={this.closePopup}
                    className="rounded--xl"
                >
                    <div className="p-10 mt-5 relative flex items-center flex-col gap-5">
                        <div className="absolute right-3 -top-2 bg-white">
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={this.closePopup}
                                aria-label="close"
                            >
                                <IoCloseCircleOutline size={32} className="primary" />
                            </IconButton>
                        </div>
                        <p className="text-center Medium">
                            {this.state.message}.
                        </p>
                        {/* <Buttonlink
              className="w-32 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:bg--primary hover:opacity-70 bg--primary white hover:white gap-x-3 flex items-center justify-center"
              to="/pricing"
              text="Buy Now"
            ></Buttonlink> */}
                    </div>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = ({ Auth }) => {
    return {
        authUserToken: Auth.authUserToken,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleAuthUser: (data) =>
            dispatch({ type: "AUTH_USER_TOKEN", payload: data }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Body);
