import * as React from "react";
import { connect } from "react-redux";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Buttonlink from "../buttonlink";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function ImportUserDialog(props) {
    const location = useLocation();
    let path = location.pathname;
    const [open, setOpen] = React.useState(props.open);
    useEffect(() => {
        if (path !== '/change-password') {
            openPopup()
        } else {
            closePopup();
        }
    }, [open]);
    useEffect(() => {
        if (path === '/change-password') {
            closePopup();
        }
    }, [open]);
    const openPopup = () => {
        setOpen(true);
    };

    const closePopup = () => {
        setOpen(false);
    };
    return (
        <div>
            <Button variant="outlined" onClick={openPopup}>
                Open alert dialog
            </Button>
            <Dialog
                open={open}
                onClose={closePopup}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Important Instruction "}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please Change Your Password And Complete Your Profile
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {path === '/change-password' ? (
                        open ? closePopup() : null
                    )

                        // <Buttonlink onClick={closePopup} text={'Close'} className="bg-gray-100 hover:bg--primary hover:white cursor-pointer flex py-2 px-4 rounded-lg mr-2" /> 
                        :
                        (
                            <Buttonlink to={'/change-password'} text={'Change Password'} className="bg-gray-100 hover:bg--primary hover:white cursor-pointer flex py-2 px-4 rounded-lg" />
                        )

                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}

const mapStateToProps = ({ Auth }) => {
    return {
        authUser: Auth.authUserInformation,
    };
};

export default connect(mapStateToProps, null)(ImportUserDialog);