import { gql } from "@apollo/client";
export default gql`
query($q: String, $cursor: Int $country_id:Int) {
    states(first: 10, page:$cursor, name: $q, country_id: $country_id) {
      data {
        id
        name
        code
        country_id
        country{
            id
            name
            sortname
        }
      }
      paginatorInfo {
        total
        perPage
        lastItem
        currentPage
        hasMorePages
      }
    }
  }
`;
