import { GET_AUTH_USER_INFORMATION } from "./types";
import { SET_SOCKET_ID } from "./types";
import store from "../index";
import client from "../../graphql/client";
import ME from "../../graphql/queries/me";
import cookie from "js-cookie";
import io from "socket.io-client";
import { AUTH_USER_MRO } from "../../config/constants";
let socket = null;
class AuthUserAction {
  constructor() {
    this.getAuthUserInformation = () => {
      return new Promise((resolve, reject) => {
        client
          .query({
            query: ME,
          })
          .then((res) => {
            store.dispatch({
              type: GET_AUTH_USER_INFORMATION,
              payload: res.data.me,
            });
            if (res?.data?.me?.id){
              try {
                socket = io.connect(process.env.REACT_APP_SOCKET_WEB_URL);
                store.dispatch({
                  type: SET_SOCKET_ID,
                  payload: socket,
                });
              }catch (e) {
                console.log("User Not Found",e)
              }
            }
            cookie.set(AUTH_USER_MRO, res.data.me);
            localStorage.setItem(AUTH_USER_MRO, JSON.stringify(res.data.me));
            resolve(true);
          })
          .catch((err) => {
            console.log("error", err);
            reject(false);
          });
      });
    };
  }
}

const MyAuthUserAction = new AuthUserAction();
export default MyAuthUserAction;
