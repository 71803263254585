import { gql } from "@apollo/client";

export default gql`
  query ($category_id: [Int], $month: String, $year: String, $tags: [Int]) {
    leaderboard(
      category_id: $category_id
      month: $month
      year: $year
      tags: $tags
    ) {
      id
      username
      firstname
      total_offers
      buyer_reviews_avg_rate
      seller_reviews_avg_rate
      seller_reviews_avg_responsiveness
      seller_reviews_avg_shipment
      seller_reviews_avg_lead_time
      seller_reviews_avg_delivered
      seller_reviews_avg_compliance
    }
  }
`;
