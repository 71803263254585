import React, { Fragment, Component } from "react";
import { Popover, Transition } from "@headlessui/react";
import { BsBookmark, BsTrash } from "react-icons/bs";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { IoPeople } from "react-icons/io5";
import { FcLock } from "react-icons/fc";
import { Pagenotfound, RFQdetailstabs } from "navigation";
import { Popup } from "semantic-ui-react";
import client from "graphql/client";
import getOffer from "graphql/queries/getOffer";
import {
  Buttonlink,
  Loader,
  Avatarimage,
  ChatPopup,
  ConfirmationDialog,
  VerifyIcon,
  Tooltips,
} from "components";
import { IoIosArrowBack } from "react-icons/io";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import messages from "../../../assets/images/messages.png";
import { connect } from "react-redux";
import amountFormat from "components/amountFormat/amountFormat";
import offerParticipantAcceptAndRejectMutation from "graphql/mutations/offerParticipantAcceptAndRejectMutation";
import offerPermissionMutation from "graphql/mutations/offerPermissionMutation";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import offerUnSaveBookmark from "../../../graphql/mutations/offerUnSaveBookmark";
import offerSaveBookmark from "../../../graphql/mutations/offerSaveBookmark";
import { gql } from "@apollo/client";
import { convertToSlug, encryptfunction } from "helpers";
import ReactStars from "react-rating-stars-component";
import { FaStar, FaRegStar, FaStarHalfAlt } from "react-icons/fa";
import GET_RATTING from "../../../graphql/mutations/createReviewMutation";
import "./styles.css";
import { Link } from "react-router-dom";
import Profileavatar from "../../../components/profileavatar";
import { MdOutlineUnpublished } from "react-icons/md";
import { FiCheckCircle, FiEdit } from "react-icons/fi";
import Auth from "../../../Auth";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import CustomMessage from "../../../components/CustomMessage";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import { IoCloseCircleOutline } from "react-icons/io5";
import updateCategoriesMutation from "../../../graphql/mutations/updateCategoriesMutation";
import AuthUserAction from "../../../store/actions/MyAuthUserAction";
import Alert from "@mui/material/Alert";
import GET_COMPANY from "../../../graphql/queries/getCompany";
import AlertsAction from "../../../store/actions/MyAlertsAction";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { extractValidationErrors } from "helpers/extractValidationErrors ";
import { checkPlan } from "helpers/checkPlan";
import RFQProducts from "./Products";
import { VscFilePdf } from "react-icons/vsc";
import ImageDisplay from "components/Imagedisplay";

const auth = new Auth();

class RFQdetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rfqDetail: [],
      loading: true,
      ratingValues: 0,
      id: props?.id,
      isPermission: false,
      offerAcceptLoader: false,
      offerRejectLoader: false,
      isOpen: false,
      photoIndex: 0,
      showComments: false,
      chatPopup: false,
      openModal:
        props?.alert?.message === "Send you new message" ? true : false,
      Reviews: "",
      showoffer: false,
      disabled: false,
      submitDisabled: true,
      readable_status: "",
      open: false,
      modalOpen: false,
      executives: [],
      isRecomendation: false,
      chatLoader: false,
      isExecutive: false,
      rejectionReason: "I am out of Stock",
      otherReason: "",
      disableSubmit: false,
      disabledAccept: false,
      unreadMessage: false,
    };

    this.OpenConfirmation = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getOfferByID();
  }

  componentDidUpdate() {
    if (this.state?.id != this.props?.id) {
      this.setState({ id: this.props?.id });
      this.getOfferByID();
    }
    if (this.state.openModal && this.props.authUser.is_seller) {
      this.openChatPopup();
      this.setState({ openModal: false });
    }
  }

  getCompaniesRole(id, offer_id) {
    client
      .query({
        query: GET_COMPANY,
        variables: {
          id: id,
          offer_id: offer_id,
        },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        this.setState({ executives: response.data?.company?.executives });
        // setEnabled(response.data.company);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  getOfferByID = () => {
    // debugger;
    this.setState({
      chatLoader: true,
    });
    client
      .query({
        query: getOffer,
        variables: { id: parseInt(this.props.id) },
        fetchPolicy: "network-only",
      })
      .then((result) => {
        if (
          convertToSlug(result?.data?.offer?.title) !==
          this.props.params.details
        ) {
          this.setState({
            loading: false,
            chatLoader: false,
          });
          return <Pagenotfound />;
        } else {
          this.setState(
            {
              loading: false,
              rfqDetail: result.data.offer,
              readable_status: result?.data?.offer?.readable_status,
              isPermission: false,
              chatLoader: false,
            },
            function () {
              this.getCompaniesRole(
                result.data.offer?.company?.id,
                result.data.offer?.id
              );
            }
          );
        }
      })
      .catch((error) => {
        // console.log("rfqDetail_error", error);
        this.setState({
          loading: false,
        });
        this.notify("Something went wrong", "error");
      });
  };

  displayError = (error) => {
    let validationErrors = [];
    try {
      if (error) {
        let { graphQLErrors } = error;
        if (graphQLErrors[0].extensions.category === "validation") {
          validationErrors = graphQLErrors[0].extensions.validation;
        }
      }
      let errorMessage = [];
      for (var key in validationErrors) {
        var value = validationErrors[key];
        errorMessage.push(value[0]);
      }
      return errorMessage;
    } catch (e) { }
  };

  // handleDownloadFile = async (url) => {
  //   try {
  //     const response = await axios.get(url);
  //     const blob = await response.blob();
  //     const urlDownload = window.URL.createObjectURL(blob);
  //     let a = document.createElement('a');
  //     a.href = urlDownload;
  //     a.download = 'download';
  //     document.body.appendChild(a);
  //     a.click();
  //     window.URL.revokeObjectURL(urlDownload);
  //   } catch (error) {
  //     console.log(error, 'err')
  //   }
  // }

  renderAttachmentComponent = (rfqDetail) => {
    if (rfqDetail.files.length === 0) {
      return;
    }

    return rfqDetail.files.map((attachment, index) => (
      <ImageDisplay
        key={index}
        attachment={attachment}
        index={index}
        altText={rfqDetail.title}
        notify={this.notify}
      />
    ));
  };


  rejectOffer = (id, product_id) => {
    if (
      this.state.rejectionReason === "" ||
      (this.state.rejectionReason === "other" && this.state.otherReason === "")
    ) {
      this.setState({ disableSubmit: true });
      this.notify("Please Select a reason of Rejection", "error");
      return; // Exit the function to prevent further execution
    }

    // If the condition is not met, you can proceed with your logic here

    this.setState({ disabled: false });
    this.setState({ disableSubmit: true });
    let reason = null;
    if (this.state.rejectionReason !== "") {
      if (this.state.rejectionReason === "other") {
        reason = this.state.otherReason;
      } else {
        reason = this.state.rejectionReason;
      }
    }
    client
      .mutate({
        mutation: offerParticipantAcceptAndRejectMutation,
        variables: {
          offer_id: id,
          product_id: product_id,
          status: "rejected",
          reason: reason,
        },
        optimisticResponse: {
          __typename: "Mutation",
          offerParticipantAcceptAndReject: {
            __typename: "offerParticipantAcceptAndReject",
            id: id,
          },
        },

        update: (cache, { data: { offerParticipantAcceptAndReject } }) => {
          try {
            const data = cache.readQuery({
              query: getOffer,
              variables: { id: id },
            });

            data.offer.isApproved = false;

            cache.writeQuery({
              query: getOffer,
              variables: { id: id },
              data,
            });
          } catch (e) { }
        },
      })
      .then((res) => {
        if (this.props.socket?.connected) {
          this.props?.socket?.emit("notify", "Notification data");
        }
        this.setState({ disabled: false });
        this.notify("You have rejected this offer!", "success");
        this.getOfferByID();
      })
      .catch((err) => {
        this.notify("Error in rejecting this offer!", "error");
        console.log("Offer Reject Error", err);
      });
  };

  acceptOffer = (id, product_id) => {
    this.setState({ disabledAccept: true });

    client
      .mutate({
        mutation: offerParticipantAcceptAndRejectMutation,
        variables: {
          offer_id: id,
          product_id: product_id,
          status: "accepted",
        },
        optimisticResponse: {
          __typename: "Mutation",
          offerParticipantAcceptAndReject: {
            __typename: "offerParticipantAcceptAndReject",
            id: id,
          },
        },

        update: (cache, { data: { offerParticipantAcceptAndReject } }) => {
          try {
            const data = cache.readQuery({
              query: getOffer,
              variables: { id: id },
            });

            // Create a new object with updated properties
            const updatedOffer = {
              ...data.offer,
              isAccepted: true,
            };

            cache.writeQuery({
              query: getOffer,
              variables: { id: id },
              data: {
                offer: updatedOffer, // Use the new object here
              },
            });
            this.setState({ disabledAccept: false });
          } catch (e) {
            console.log("acceptOffer", e);
          }
        },
      })
      .then((res) => {
        if (this.props.socket?.connected) {
          this.props?.socket?.emit("notify", "Notification data");
        }
        this.notify("You have accepted this offer!", "success");
        this.getOfferByID();
      })
      .catch((err) => {
        console.log("Offer Reject Error", err);
        this.notify("Error in accepting this offer!", "error");
      });
  };

  AddBookmark = (id, bookMark) => {
    client.mutate({
      mutation: offerSaveBookmark,
      variables: {
        offer_id: id,
      },
      optimisticResponse: {
        __typename: "Mutation",
        offerSaveBookmark: {
          __typename: "offerSaveBookmark",
          id: id,
          isBookmarked: true,
        },
      },
      update: (store, { data: { offerSaveBookmark } }) => {
        try {
          // const data = store.readQuery({
          //   query: getOffer,
          //   variables: { id: offerSaveBookmark.id },
          // });
          store.writeQuery({
            query: gql`
              query Offer($id: ID) {
                offer(id: $id) {
                  id
                  isBookmarked
                }
              }
            `,
            data: {
              offer: {
                __typename: "Offer",
                id: offerSaveBookmark.id,
                isBookmarked: offerSaveBookmark.isBookmarked,
              },
            },
            variables: {
              id: id,
            },
          });
        } catch (e) {
          console.log("writeQuery error removeBookmark", e);
        }
      },
    });

    let update_object_single_key_based_value = {
      ...this.state.rfqDetail,
      isBookmarked: true,
    };
    this.setState({
      rfqDetail: update_object_single_key_based_value,
    });
  };
  removeBookmark = (id, bookMark) => {
    client.mutate({
      mutation: offerUnSaveBookmark,
      variables: {
        offer_id: id,
      },
      optimisticResponse: {
        __typename: "Mutation",
        offerUnSaveBookmark: {
          __typename: "offerUnSaveBookmark",
          id: id,
          isBookmarked: false,
        },
      },
      update: (store, { data: { offerUnSaveBookmark } }) => {
        try {
          // const data = store.readQuery({
          //   query: getOffer,
          //   variables: { id: offerSaveBookmark.id },
          // });
          store.writeQuery({
            query: gql`
              query Offer($id: ID) {
                offer(id: $id) {
                  id
                  isBookmarked
                }
              }
            `,
            data: {
              offer: {
                __typename: "Offer",
                id: offerUnSaveBookmark.id,
                isBookmarked: offerUnSaveBookmark.isBookmarked,
              },
            },
            variables: {
              id: id,
            },
          });
        } catch (e) {
          console.log("writeQuery error removeBookmark", e);
        }
      },
    });

    let update_object_single_key_based_value = {
      ...this.state.rfqDetail,
      isBookmarked: false,
    };
    this.setState({
      rfqDetail: update_object_single_key_based_value,
    });
  };

  notify = (message = "", type = "error") => {
    // Dismiss any existing toasts before showing a new one
    toast.dismiss();
    if (type === "error") {
      return toast.error(message, { position: "top-right" });
    }
    toast.success(message, { position: "top-right" });
  };

  getOfferPermission = (id) => {
    const { authUser } = this.props;
    this.setState({ isPermission: true });
    client
      .mutate({
        mutation: offerPermissionMutation,
        variables: {
          user_id: authUser.id,
          offer_id: id,
          is_pending: true,
          is_permitted: false,
        },
        optimisticResponse: {
          __typename: "Mutation",
          generalOfferPermission: {
            __typename: "GeneralPermission",
            is_pending: true,
            is_permitted: false,
            offer_id: id,
            user_id: authUser.id,
          },
        },
        update: (store, { data: { generalOfferPermission } }) => {
          try {
            const data = store.readQuery({
              query: gql`
                query Offer($id: ID) {
                  offer(id: $id) {
                    id
                    isPending
                  }
                }
              `,
              variables: { id: id },
            });
            store.writeQuery(data, {
              query: gql`
                query Offer($id: ID) {
                  offer(id: $id) {
                    id
                    isPending
                  }
                }
              `,
              data: {
                offer: {
                  __typename: "Offer",
                  id: generalOfferPermission.id,
                  isPending: generalOfferPermission.is_pending,
                },
              },
              variables: {
                id: id,
              },
            });
            let update_object_single_key_based_value = {
              ...this.state.rfqDetail,
              isPending: generalOfferPermission.is_pending,
            };
            this.setState({
              rfqDetail: update_object_single_key_based_value,
            });
          } catch (e) {
            console.log("writeQuery error getOfferPermission", e);
          }
        },
      })
      .then((res) => {
        if (this.props.socket?.connected) {
          this.props?.socket?.emit("notify", "Notification data");
        }
        this.notify("Your request has been sent!", "success");
        this.getOfferByID();

        // window.location.reload(false);
      })
      .catch((err) => {
        console.log("Get Offer Permission Error", err);
        this.notify("Error in sending request!", "error");
        this.setState({ isPermission: false });
      });
  };

  openChatPopup = () => {
    this.setState(
      {
        chatPopup: !this.state.chatPopup,
      },
      () => {
        if (!this.state.chatPopup) {
          this.getOfferByID();
        }
      }
    );
    AlertsAction.getAuthUserAlerts({ page: 1 });
  };

  OpenConfirmationDialog = (rfqType, rfqId, rfqVisibility, readable_status) => {
    this.OpenConfirmation.current.openPopup(
      rfqType,
      rfqId,
      rfqVisibility,
      readable_status
    );
  };

  SubmitRating = (product) => {
    this.setState({ submitDisabled: true });
    client
      .mutate({
        mutation: GET_RATTING,
        variables: {
          seller_id: this.props.authUser.id,
          buyer_id: this.state.rfqDetail.user.id,
          product_id: product ?? null,
          offer_id: this.state.rfqDetail.id,
          comment: this.state.Reviews,
          rate: this.state.ratingValues,
          is_buyer: this.props.authUser.is_seller,
          responsiveness: 0,
          shipment: 0,
          lead_time: 0,
          delivered: 0,
          compliance: 0,
        },
      })
      .then((res) => {
        if (this.props.socket?.connected) {
          this.props?.socket?.emit("notify", "Notification data");
        }
        toast.success("Your Review has been Submitted", {
          position: "top-right",
          autoClose: 2500,
        });
        setTimeout(() => {
          this.getOfferByID();
        }, 10);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  ReviewsRatting = (e) => {
    this.setState({
      Reviews: e.target.value,
    });
    setTimeout(() => {
      if (
        this.state.submitDisabled &&
        this.state.Reviews.length > 0 &&
        this.state.ratingValues !== 0
      ) {
        this.setState({
          submitDisabled: false,
        });
      }
      if (this.state.Reviews.length === 0 || this.state.ratingValues === 0) {
        this.setState({
          submitDisabled: true,
        });
      }
    }, 10);
  };

  callBack = (value) => {
    this.getOfferByID();
  };
  closePopup = () => {
    this.setState({ open: false });
  };

  userHasCategories = (cate) => {
    if (this.state?.rfqDetail?.menu?.code !== "send_non_rfq_category") {
      let check = [];
      let found = false;
      this.props.authUser?.categories.forEach((e) => {
        check.push(cate.some((o) => o.id === e.id));
      });
      found = check.includes(true);
      if (found) {
        this.props.navigate("/submit-rfq-quote", {
          state: { rfq: this.state.rfqDetail },
          replace: true,
        });
      } else {
        if (this.state.rfqDetail.uom == null) {
          this.setState({ modalOpen: true });
        } else {
          this.setState({ open: true });
        }
      }
    } else {
      this.props.navigate("/submit-rfq-quote", {
        state: { rfq: this.state.rfqDetail },
        replace: true,
      });
    }
  };

  addCategoriesToAdmin = (categories) => {
    let admin_categories_ids = this.props.authUser?.categories;
    admin_categories_ids = admin_categories_ids.concat(categories[0]);
    // Array.prototype.push.apply(admin_categories_ids,categories);
    let categories_ids = [];
    setTimeout(() => {
      admin_categories_ids.forEach((ids) => {
        categories_ids.push(parseInt(ids.id));
      });
      client
        .mutate({
          mutation: updateCategoriesMutation,
          variables: {
            categories: categories_ids,
          },
        })
        .then((response) => {
          AuthUserAction.getAuthUserInformation();
          setTimeout(() => {
            this.setState({ open: false });
            // this.notify("New categories are added", 'success')
            this.props.navigate("/submit-rfq-quote", {
              state: { rfq: this.state.rfqDetail },
              replace: true,
            });
          });
        })
        .catch((err) => {
          console.error("err", err);
          extractValidationErrors(err).forEach((err) =>
            this.notify(err, "error")
          );
        });
    });
  };

  CustomHtmlRenderer = ({ htmlContent }) => {
    // Split the HTML content into lines
    const lines = htmlContent?.split("\n");

    // Process each line to generate JSX elements
    const renderedLines = lines?.map((line, index) => {
      if (line?.trim().startsWith("<li")) {
        // If the line starts with an <li> tag, create a bullet point
        return (
          <div key={index} className="flex items-start">
            <div
              className="leading-snug"
              dangerouslySetInnerHTML={{ __html: line }}
            />
          </div>
        );
      } else {
        // Otherwise, render the line as is
        return <div key={index} dangerouslySetInnerHTML={{ __html: line }} />;
      }
    });

    return <div>{renderedLines}</div>;
  };
  adjustTextareaHeight = (textarea) => {
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
  };
  render() {
    const { params, authUser, alert } = this.props;
    const { rfqDetail, loading, isPermission, open, modalOpen } = this.state;
    let executivePub_Unpub = false;
    const is_executive = rfqDetail?.executives?.some(
      (e) => e.id === authUser.id
    );

    rfqDetail?.executive_permissions?.forEach((e) => {
      //if (rfqDetail.executives)
      if (e.name === "publish-or-update") {
        executivePub_Unpub = true;
      }
    });

    const supplierReview = {
      count: 5,
      color: "#d1d5db",
      activeColor: "#ffd700",
      edit: false,
      a11y: true,
      isHalf: true,
      emptyIcon: <FaRegStar size={22} />,
      halfIcon: <FaStarHalfAlt size={22} />,
      filledIcon: <FaStar size={22} />,
    };

    const ratingSetting = {
      count: 5,
      color: "#d1d5db",
      activeColor: "#ffd700",
      value: this.state.ratingValues,
      a11y: true,
      isHalf: true,
      emptyIcon: <FaRegStar size={26} />,
      halfIcon: <FaStarHalfAlt size={26} />,
      filledIcon: <FaStar size={26} />,
      onChange: (newValue) => {
        this.setState({ ratingValues: newValue });
        if (this.state.Reviews.length > 0) {
          this.setState({ submitDisabled: false });
        }
      },
    };
    const sellerratting = rfqDetail?.review?.filter((r) => r.is_buyer === true);
    const BuyerRatting = rfqDetail?.review?.filter((r) => r.is_buyer === false);
    let date = new Date(rfqDetail?.last_date);
    let formatted = moment(date).format("D MMMM YYYY");
    const hasExecutivesRole =
      rfqDetail?.executives &&
      rfqDetail.executives.length > 0 &&
      rfqDetail.executives.some((user) => user.id === this.props.authUser?.id);
    let matchingExecutive = rfqDetail?.executives?.find(
      (executive) => executive.id === this.props?.authUser?.id
    );
    let permissionNames = [];

    if (matchingExecutive) {
      permissionNames =
        matchingExecutive?.offer_executive_user_roles[0]?.permissions?.map(
          (permission) => permission.name
        );
    }
    const isAllBlocked = rfqDetail.participants?.data?.every(
      (user) => user.blocked === true
    );
    let sellerParticipent = rfqDetail?.participants?.data?.find(
      (o) => o.action_user_id == authUser?.id
    );
    const maxCategoriesProfile = checkPlan(
      "number",
      "Categories on profile",
      this.props?.authUser?.payments
    );
    const seeRFQViews = checkPlan(
      "bool",
      "No. of views of sellers on a RFQ",
      this.props?.authUser?.payments
    );
    const executiveIdsWithCanMessage = rfqDetail.executives
      ?.filter((executive) =>
        executive.offer_executive_user_roles[0].permissions.some((permission) =>
          permission.name.includes("canMessage")
        )
      )
      .map((executive) => executive.id);
    const hasUnapprovedParticipant = rfqDetail.participants?.data.every(
      (participant) =>
        participant.user?.id == authUser?.id &&
        !(
          participant.accepted ||
          participant.approved ||
          participant.is_approve ||
          participant.isRevision ||
          participant.blocked
        )
    );
    const participantCount =
      rfqDetail?.participants?.data?.filter(
        (dat) => parseInt(dat?.user?.id) === parseInt(this.props.authUser.id)
      )?.length || 0;
    const showReSubmit =
      participantCount > 0 && participantCount < rfqDetail.products.length;
    return (
      <>
        {loading ? (
          <Loader />
        ) : rfqDetail?.id === undefined ||
          (rfqDetail?.readable_status === "Draft" &&
            !rfqDetail.isOwner &&
            !hasExecutivesRole) ? (
          <CustomMessage message={"RFQ Not Found"} />
        ) : (
          <div className="container mt-10" key={rfqDetail.id}>
            {/* <ToastContainer position="top-right" autoClose={5000} /> */}
            <div className="mb-5 intro-y">
              {this.props.selectedIndex != undefined ? (
                <Buttonlink
                  text="Back"
                  prefix={<IoIosArrowBack size={16} />}
                  onClick={() =>
                    this.props.navigate(-1, {
                      state: { cardId: this.props.selectedIndex },
                    })
                  }
                  className="flex items-center justify-center w-24 bg-gray-200 border-0 rounded-full shadow-none hover:bg-gray-300 fs-13 gap-x-1 h-9 darkGray hover:opacity-80"
                />
              ) : (
                <Buttonlink
                  text="Back"
                  prefix={<IoIosArrowBack size={16} />}
                  onClick={() => this.props.navigate(-1)}
                  className="flex items-center justify-center w-24 bg-gray-200 border-0 rounded-full shadow-none hover:bg-gray-300 fs-13 gap-x-1 h-9 darkGray hover:opacity-80"
                />
              )}
              <h2 className="mt-4 text-3xl Medium">RFQ Details</h2>
            </div>
            <div className={`grid grid-cols-1 gap-4 lg:grid-cols-3 relative`}>
              <div className="col-span-2 space-y-4 overflow-hidden intro-y">
                <div className="col-span-2 p-5 space-y-4 overflow-hidden box intro-y">
                  <div className="flex items-center justify-between">
                    <div className="grow">
                      <div className="flex justify-between mb-2">
                        {rfqDetail.user.id !== authUser.id && (
                          <div className="flex gap-x-3 grow">
                            <div>
                              <Profileavatar singleUser={rfqDetail.user} />
                            </div>
                            <div>
                              <div className="flex items-center">
                                <p>
                                  {rfqDetail.user.firstname}{" "}
                                  {rfqDetail.user.lastname}
                                </p>
                                <div className="w-4 h-4 ml-1">
                                  <VerifyIcon user={rfqDetail.user} />
                                </div>
                              </div>
                              <p className="Light gray fs-11">
                                @{rfqDetail.user.username}
                              </p>
                            </div>
                          </div>
                        )}
                        {/*{!auth.isAuthenticated() && (
                          <Buttonlink
                            text="Submit Quotation"
                            to="/registration"
                            className="flex items-center justify-center px-10 leading-5 rounded-lg shadow-md h-11 fs-15 Regular hover:opacity-70 bg--primary white hover:text-white"
                          />
                        )}*/}
                      </div>

                      <h3 className="flex items-center text-2xl Medium">
                        {rfqDetail?.title}
                        {rfqDetail?.isOwner ? (
                          <Popup
                            trigger={
                              <IoPeople size={22} className="ml-2 gray" />
                            }
                            hoverable
                            content={
                              seeRFQViews ? (
                                <div className="pt-3 rfq-views-info">
                                  <div>
                                    <div className="flex items-center">
                                      <h6 className="fs-22 black">
                                        {
                                          rfqDetail?.offer_views?.paginatorInfo
                                            ?.total
                                        }{" "}
                                        <span className="fs-20 Regular darkGray">
                                          RFQ views
                                        </span>
                                      </h6>
                                      <Buttonlink
                                        to="/see-all-views"
                                        state={rfqDetail}
                                        text="See All Views"
                                        title="Views"
                                        rel="nofollow"
                                        className="flex justify-center mt-1 ml-auto hover:opacity-80 primary"
                                      />
                                    </div>
                                    <div className="w-full h-px mt-6 mb-3 bg--lightGray" />
                                    <div className="mt-6">
                                      {rfqDetail?.offer_views?.data?.map(
                                        (user) => {
                                          return (
                                            <>
                                              {!user.is_seller &&
                                                user.become_seller ? (
                                                <button
                                                  className="flex items-center cursor-pointer gap-x-3"
                                                  onClick={() =>
                                                    this.notify(
                                                      "This seller profile is currently not available",
                                                      "error"
                                                    )
                                                  }
                                                >
                                                  <div className="flex items-center mb-4">
                                                    <div className="overflow-hidden rounded-full w-11 h-11">
                                                      <Profileavatar
                                                        singleUser={user}
                                                        className="object-cover w-11 h-11"
                                                      />
                                                    </div>
                                                    <div className="w-4 h-4 ml-1">
                                                      <VerifyIcon user={user} />
                                                    </div>
                                                    <div className="ml-3">
                                                      <h4 className="mb-1 fs-16 Medium black">
                                                        {user.firstname}{" "}
                                                        {user.lastname}
                                                      </h4>
                                                      <p className="darkGray Regular fs-13">
                                                        Viewed your RFQ
                                                      </p>
                                                    </div>
                                                  </div>
                                                </button>
                                              ) : (
                                                <Link
                                                  to={`/${encryptfunction(
                                                    user.id
                                                  )}/profile`}
                                                  state={{
                                                    value: "edit",
                                                    id: user.id,
                                                  }}
                                                  className="flex items-center cursor-pointer gap-x-3"
                                                >
                                                  <div className="flex items-center mb-4">
                                                    <div className="overflow-hidden rounded-full w-11 h-11">
                                                      <Profileavatar
                                                        singleUser={user}
                                                        className="object-cover w-11 h-11"
                                                      />
                                                    </div>
                                                    <div className="w-4 h-4 ml-1">
                                                      <VerifyIcon user={user} />
                                                    </div>
                                                    <div className="ml-3">
                                                      <h4 className="mb-1 fs-16 Medium black">
                                                        {user.firstname}{" "}
                                                        {user.lastname}
                                                      </h4>
                                                      <p className="darkGray Regular fs-13">
                                                        Viewed your RFQ
                                                      </p>
                                                    </div>
                                                  </div>
                                                </Link>
                                              )}
                                            </>
                                          );
                                        }
                                      )}

                                      <div className="w-full h-px my-3 bg--lightGray" />
                                      {this.props.authUser && !seeRFQViews ? (
                                        <div className="flex items-center">
                                          <div className="w-11 h-11">
                                            <FcLock size={40} />
                                          </div>
                                          <div className="ml-3">
                                            <h4 className="mb-2 fs-16 Medium black">
                                              Unlock the rest of the list with
                                              Premium
                                            </h4>
                                            <div className="flex -space-x-1 overflow-hidden">
                                              <img
                                                className="inline-block w-8 h-8 rounded-full ring-2 ring-white"
                                                src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                alt=""
                                              />
                                              <img
                                                className="inline-block w-8 h-8 rounded-full ring-2 ring-white"
                                                src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                alt=""
                                              />
                                              <img
                                                className="inline-block w-8 h-8 rounded-full ring-2 ring-white"
                                                src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                          <Buttonlink
                                            to="/pricing"
                                            text="Switch Premium"
                                            title="Premium"
                                            rel="nofollow"
                                            className="relative flex justify-center px-6 py-1 ml-auto border border-transparent rounded-lg hover:white hover:opacity-80 primary bg--lightPrimary hover:bg--primary focus:outline-none whitespace-nowrap"
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="flex items-center">
                                  <div className="w-11 h-11">
                                    <FcLock size={40} />
                                  </div>
                                  <div className="ml-3">
                                    <h4 className="mb-2 fs-16 Medium black">
                                      Unlock the rest of the list with Premium
                                    </h4>
                                    <div className="flex -space-x-1 overflow-hidden">
                                      <img
                                        className="inline-block w-8 h-8 rounded-full ring-2 ring-white"
                                        src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                        alt=""
                                      />
                                      <img
                                        className="inline-block w-8 h-8 rounded-full ring-2 ring-white"
                                        src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                        alt=""
                                      />
                                      <img
                                        className="inline-block w-8 h-8 rounded-full ring-2 ring-white"
                                        src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <Buttonlink
                                    to="/pricing/system"
                                    text="Switch Premium"
                                    title="Premium"
                                    rel="nofollow"
                                    className="relative flex justify-center px-6 py-1 ml-auto border border-transparent rounded-lg hover:white hover:opacity-80 primary bg--lightPrimary hover:bg--primary focus:outline-none whitespace-nowrap"
                                  />
                                </div>
                              )
                            }
                            className="bg-white shadow-lg rfq-views"
                            inverted
                          />
                        ) : null}
                      </h3>
                      <div className="flex items-center gap-x-1">
                        <p className="text-sm gray">Status:</p>

                        <p className="text-sm darkGray">
                          {rfqDetail.readable_status}
                        </p>
                      </div>
                    </div>
                    {(rfqDetail.isOwner ||
                      permissionNames.includes("publish-or-update")) &&
                      rfqDetail?.readable_status !== "Completed" ? (
                      <>
                        <Popover.Group className="items-center justify-between hidden xl:flex">
                          <Popover className="relative ">
                            <Popover.Button>
                              <div className="flex items-center justify-center w-8 h-8 m-auto bg-gray-100 rounded-full shadow-sm">
                                <HiOutlineDotsVertical size={18} />
                              </div>
                            </Popover.Button>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-300"
                              enterFrom="opacity-0 translate-y-0"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-250"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 translate-y-0"
                            >
                              <Popover.Panel className="absolute right-0 z-10 w-screen max-w-sm transform sm:px-0">
                                <div className="relative p-3 bg-white shadow-lg rounded-xl ring-1 ring-black ring-opacity-5">
                                  {this.state.rfqDetail?.menu?.code ===
                                    "help_expert" ? null : rfqDetail?.readable_status ===
                                      "Expired" &&
                                      rfqDetail?.participants?.data?.length ===
                                      0 ? null : rfqDetail.participants
                                        ?.paginatorInfo.total > 0 &&
                                        !isAllBlocked ? (
                                    <p>
                                      Once a seller has submitted a quotation,
                                      editing the RFQ is not possible. Please
                                      address all existing submissions or
                                      initiate a new RFQ
                                    </p>
                                  ) : rfqDetail.uom !== null ||
                                    rfqDetail.uom === null ? (
                                    <Link
                                      to={`/post-rfq/${rfqDetail.menu?.code}`}
                                      state={{
                                        menu: rfqDetail.menu,
                                        data: rfqDetail,
                                        editable: true,
                                      }}
                                      className="flex items-center px-2 py-2 cursor-pointer gap-x-3 hover:bg-gray-100 rounded-xl"
                                    >
                                      <FiEdit size={20} className="black" />
                                      <div>
                                        <p className="fs-14">Edit</p>
                                        <p className="Light darkGray fs-10">
                                          Edit RFQ
                                        </p>
                                      </div>
                                    </Link>
                                  ) : null}
                                  {this.state.rfqDetail?.menu?.code !==
                                    "help_expert" &&
                                    rfqDetail?.participants?.data?.length ===
                                    0 &&
                                    rfqDetail?.readable_status !==
                                    "Expired" && (
                                      <div
                                        className="px-2 py-2 cursor-pointer hover:bg-gray-100 rounded-xl"
                                        onClick={() =>
                                          this.OpenConfirmationDialog(
                                            rfqDetail.visibility === "1"
                                              ? "unpublished"
                                              : "published",
                                            rfqDetail.id,
                                            rfqDetail.visibility,
                                            rfqDetail.readable_status
                                          )
                                        }
                                      >
                                        {rfqDetail.visibility === "1" ? (
                                          <div className="flex items-center gap-x-3">
                                            <MdOutlineUnpublished size={20} />
                                            <div>
                                              <p className="fs-12">Unpublish</p>
                                              <p className="Light darkGray fs-10">
                                                Unpublish RFQ
                                              </p>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="flex items-center gap-x-3">
                                            <FiCheckCircle size={20} />
                                            <div>
                                              <p className="fs-12">Publish</p>
                                              <p className="Light darkGray fs-10">
                                                Publish RFQ
                                              </p>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  {rfqDetail?.participants?.data?.length ===
                                    0 &&
                                    rfqDetail.user?.id == authUser?.id && (
                                      <div
                                        className="flex items-center px-2 py-2 cursor-pointer gap-x-3 hover:bg-gray-100 rounded-xl"
                                        onClick={() =>
                                          this.OpenConfirmationDialog(
                                            "delete",
                                            rfqDetail.id,
                                            rfqDetail.visibility,
                                            rfqDetail.readable_status
                                          )
                                        }
                                      >
                                        <BsTrash size={20} />
                                        <div>
                                          <p className="fs-12">Delete</p>
                                          <p className="Light darkGray fs-10">
                                            Delete RFQ
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </Popover.Panel>
                            </Transition>
                          </Popover>
                        </Popover.Group>
                      </>
                    ) : null}
                  </div>
                  <div className="grid lg:grid-cols-2 gap-y-5 gap-x-3">
                    {rfqDetail?.products?.length > 0 ? (
                      rfqDetail?.products?.map((product, index) => (
                        <RFQProducts product={product} key={index} />
                      ))
                    ) : (
                      <div>
                        {rfqDetail.quantity > 0 && (
                          <div className="flex items-center gap-x-3">
                            <p className="primary">Required {rfqDetail.uom}:</p>
                            <p className="Medium">{rfqDetail.quantity}</p>
                          </div>
                        )}

                        {rfqDetail.reference_number != null && (
                          <div className="flex items-center gap-x-3">
                            <p className="primary">Reference number:</p>
                            <p className="Medium">
                              {rfqDetail.reference_number}
                            </p>
                          </div>
                        )}

                        {rfqDetail.last_date != null && (
                          <div className="flex items-center gap-x-3">
                            <p className="primary">Last date:</p>
                            <p className="Medium">{formatted}</p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <div>
                    <div className="grid gap-5 grid-cols-4">
                      {this.renderAttachmentComponent(rfqDetail)}
                    </div>
                    {rfqDetail?.description &&
                      this.CustomHtmlRenderer({
                        htmlContent: rfqDetail.description,
                      })}
                    <div className="space-y-2">
                      <h3 className="text-xl Medium">Industry Tags:</h3>
                      <div className="flex flex-wrap items-center gap-2 selected-tags ">
                        {rfqDetail &&
                          rfqDetail.tags &&
                          Array.from(
                            new Set(rfqDetail.tags.map((tags) => tags))
                          ).map((tag, index) => (
                            <label
                              className="bg-gray-200 hover:opacity-70 rounded-lg px-5 py-1.5 cursor-pointer inline-block  intro-x"
                              key={tag.id}
                            >
                              <p className="fs-13">{tag.name}</p>
                            </label>
                          ))}
                      </div>
                    </div>
                  </div>

                  <div className="w-full my-20 divider" />
                  <RFQdetailstabs
                    key={this.props.id}
                    rfqDetail={rfqDetail}
                    alert={auth.isAuthenticated() && alert}
                    onBlock={this.getOfferByID}
                    params={this.props.params}
                    id={this.props.id}
                    authUser={authUser}
                  />
                  {authUser && authUser.is_seller ? (
                    !rfqDetail.offer_permission ? (
                      rfqDetail.isPending ? (
                        <>
                          <div>Wait for Buyer response.</div>
                          <div className="flex justify-end items-end">
                            {rfqDetail.isBookmarked ? (
                              <div
                                onClick={() =>
                                  this.removeBookmark(
                                    rfqDetail.id,
                                    rfqDetail.isBookmarked
                                  )
                                }
                                className="h-9 w-9 cursor-pointer z-10 bg-[#ef553b] rounded-full flex items-center justify-center"
                              >
                                <BsBookmark className="text-white" />
                              </div>
                            ) : (
                              <div
                                onClick={() =>
                                  this.AddBookmark(
                                    rfqDetail.id,
                                    rfqDetail.isBookmarked
                                  )
                                }
                                className="z-10 flex items-center justify-center bg-gray-200 rounded-full cursor-pointer h-9 w-9"
                              >
                                <BsBookmark className="gray" />
                              </div>
                            )}
                          </div>
                        </>
                      ) : rfqDetail?.user?.id === authUser?.id ||
                        matchingExecutive ? (
                        <div className="text-left">
                          You Can't Participate to this RFQ
                        </div>
                      ) : rfqDetail.readable_status === "Expired" ||
                        rfqDetail.readable_status === "Completed" ? (
                        <div className="py-6 text-center">
                          <Buttonlink
                            disabled={true}
                            to="#"
                            text={
                              rfqDetail.readable_status === "Expired"
                                ? "RFQ Is Expired Can't Send Quote"
                                : "RFQ Is Completed Can't Send Quote"
                            }
                            className="w-full px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--gray white hover:text-white"
                          />
                        </div>
                      ) : (
                        <>
                          <Buttonlink
                            disabled={isPermission && rfqDetail.isBlocked}
                            onClick={() =>
                              this.getOfferPermission(rfqDetail.id)
                            }
                            prefix={
                              isPermission ? <Loader spinner={true} /> : false
                            }
                            text={
                              isPermission &&
                                rfqDetail.offer_permission &&
                                !rfqDetail.isPending &&
                                !rfqDetail.is_permitted
                                ? "Your Request was Rejected by user"
                                : "Get Permission"
                            }
                            className="flex items-center justify-center leading-5 rounded-lg shadow-md h-11 fs-15 Regular hover:opacity-70 bg--primary white hover:text-white w-44"
                          />
                          <div className="flex justify-end items-end">
                            {rfqDetail.isBookmarked ? (
                              <div
                                onClick={() =>
                                  this.removeBookmark(
                                    rfqDetail.id,
                                    rfqDetail.isBookmarked
                                  )
                                }
                                className="h-9 w-9 cursor-pointer z-10 bg-[#ef553b] rounded-full flex items-center justify-center"
                              >
                                <BsBookmark className="text-white" />
                              </div>
                            ) : (
                              <div
                                onClick={() =>
                                  this.AddBookmark(
                                    rfqDetail.id,
                                    rfqDetail.isBookmarked
                                  )
                                }
                                className="z-10 flex items-center justify-center bg-gray-200 rounded-full cursor-pointer h-9 w-9"
                              >
                                <BsBookmark className="gray" />
                              </div>
                            )}
                          </div>
                        </>
                      )
                    ) : !rfqDetail.products.length > 0 && 1 == 2 ? (
                      <div>
                        {rfqDetail?.participants?.data.map((participant) =>
                          participant.user.id === authUser.id &&
                            participant.isRevision ? (
                            <div className="flex">
                              <div className="leading-10">
                                Buyer send revision on your previous quotation
                                of ${participant.total}.
                              </div>
                              {rfqDetail?.messages?.some(
                                (message) =>
                                  message?.receiver_id == authUser?.id &&
                                  message.is_buyer === 0
                              ) ? (
                                <Tooltips
                                  trigger={
                                    <div className="flex justify-end relative ml-20">
                                      <Buttonlink
                                        onClick={() => this.openChatPopup()}
                                        text="Message"
                                        className="px-6 py-2.5 fs-15 ml-auto leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--lightGray darkGray"
                                      />
                                      <div className="absolute right-0 top-[7px] w-2 h-2 bg-green-500 rounded-full" />
                                    </div>
                                  }
                                  toolcontent={
                                    "You might have some unread message"
                                  }
                                />
                              ) : (
                                <Buttonlink
                                  onClick={() => this.openChatPopup()}
                                  text="Message"
                                  className="px-6 py-2.5 fs-15 ml-auto leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--lightGray darkGray"
                                />
                              )}
                            </div>
                          ) : participant.user.id === authUser.id &&
                            participant.readable_status == "Pending" ? (
                            <div className="flex">
                              <div className="leading-10">
                                RFQ has been Submitted Waiting for Seller
                                Response
                              </div>
                              {rfqDetail?.messages?.some(
                                (message) =>
                                  message?.receiver_id == authUser.id &&
                                  message?.is_buyer === 0
                              ) ? (
                                <Tooltips
                                  trigger={
                                    <div className="flex justify-end relative ml-20">
                                      <Buttonlink
                                        onClick={() => this.openChatPopup()}
                                        text="Message"
                                        className="px-6 py-2.5 fs-15 ml-auto leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--lightGray darkGray"
                                      />
                                      <div className="absolute right-0 top-[7px] w-2 h-2 bg-green-500 rounded-full" />
                                    </div>
                                  }
                                  toolcontent={
                                    "You might have some unread message"
                                  }
                                />
                              ) : (
                                <Buttonlink
                                  onClick={() => this.openChatPopup()}
                                  text="Message"
                                  className="px-6 py-2.5 fs-15 ml-auto leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--lightGray darkGray"
                                />
                              )}
                            </div>
                          ) : null
                        )}
                        {!rfqDetail.participants.data.some(
                          (participant) => participant.user.id == authUser.id
                        ) && (
                            <div className="py-6 text-center">
                              <Buttonlink
                                // to="/submit-rfq-quote"
                                onClick={() =>
                                  this.userHasCategories(rfqDetail.categories)
                                }
                                state={{ rfq: rfqDetail }}
                                text="Submit your RFQ quote"
                                className="px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white"
                              />
                            </div>
                          )}
                        <div className="flex justify-end items-end">
                          {rfqDetail.isBookmarked ? (
                            <div
                              onClick={() =>
                                this.removeBookmark(
                                  rfqDetail.id,
                                  rfqDetail.isBookmarked
                                )
                              }
                              className="h-9 w-9 cursor-pointer z-10 bg-[#ef553b] rounded-full flex items-center justify-center"
                            >
                              <BsBookmark className="text-white" />
                            </div>
                          ) : (
                            <div
                              onClick={() =>
                                this.AddBookmark(
                                  rfqDetail.id,
                                  rfqDetail.isBookmarked
                                )
                              }
                              className="z-10 flex items-center justify-center bg-gray-200 rounded-full cursor-pointer h-9 w-9"
                            >
                              <BsBookmark className="gray" />
                            </div>
                          )}
                        </div>
                      </div>
                    ) : this.state.rfqDetail.isParticipant ? (
                      this.state.rfqDetail.products.length > 0 ? (
                        <div className="divide-y">
                          {rfqDetail?.participants?.data?.length > 0 &&
                            rfqDetail.participants?.data.map(
                              (participant, index) => {
                                const product = rfqDetail.products.find(
                                  (p) => p.id == participant.product_id
                                );
                                return !participant.accepted &&
                                  participant.user?.id == authUser?.id ? (
                                  participant.approved ? (
                                    <div className=" py-3" key={index}>
                                      <p className="Medium fs-16">
                                        {product?.title}
                                      </p>
                                      <p>
                                        {rfqDetail.company
                                          ? rfqDetail.company?.title
                                          : "anonymous"}{" "}
                                        has accepted your offer for supplying{" "}
                                        {rfqDetail.quantity} {rfqDetail.uom} of{" "}
                                        {rfqDetail?.title} at{" "}
                                        {amountFormat(
                                          (
                                            participant.total /
                                            rfqDetail.quantity
                                          ).toFixed(2)
                                        )}{" "}
                                        and overall offer price of{" "}
                                        {amountFormat(participant.total)} and
                                        other terms of delivery and quality as
                                        stated in the offer. Please confirm
                                        acceptance by clicking accept and
                                        confirm button.
                                      </p>
                                      <div className="flex items-center justify-center mt-6 gap-x-5">
                                        {this.state.disabled ===
                                          participant.product_id ? (
                                          <div>
                                            <FormControl>
                                              <FormLabel id="demo-radio-buttons-group-label">
                                                Please Select Reason of
                                                Rejection(Required)
                                              </FormLabel>
                                              <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue="I am out of Stock"
                                                name="radio-buttons-group"
                                                value={
                                                  this.state.rejectionReason
                                                } // Add a value prop to track the selected reason
                                                onChange={(event) => {
                                                  this.setState({
                                                    rejectionReason:
                                                      event.target.value,
                                                    disableSubmit: false,
                                                  });
                                                }}
                                              >
                                                <FormControlLabel
                                                  value="I am out of Stock"
                                                  control={<Radio />}
                                                  label="I am out of Stock"
                                                />
                                                <FormControlLabel
                                                  value="I have found something suspicious about buyer"
                                                  control={<Radio />}
                                                  label="I have found something suspicious about buyer"
                                                />
                                                <FormControlLabel
                                                  value="Buyer changed Requirements"
                                                  control={<Radio />}
                                                  label="Buyer changed Requirements"
                                                />
                                                <FormControlLabel
                                                  value="I am not interested anymore"
                                                  control={<Radio />}
                                                  label="I am not interested anymore"
                                                />
                                                <FormControlLabel
                                                  value="other"
                                                  control={<Radio />}
                                                  label="other"
                                                />
                                              </RadioGroup>
                                              {/* Show a textbox if "other" is selected */}
                                              {this.state.rejectionReason ===
                                                "other" && (
                                                  <>
                                                    <div className="flex items-end">
                                                      <textarea
                                                        value={
                                                          this.state.otherReason
                                                        }
                                                        // onChange={this.handleTextAreaChange}
                                                        rows={1}
                                                        onChange={(event) => {
                                                          // Limit input to 50 characters
                                                          const inputText =
                                                            event.target.value.slice(
                                                              0,
                                                              100
                                                            );
                                                          this.adjustTextareaHeight(
                                                            event.target
                                                          );
                                                          this.setState({
                                                            otherReason:
                                                              inputText,
                                                            disableSubmit: false,
                                                          });
                                                        }}
                                                        //value={message}
                                                        maxLength="100"
                                                        cols={50}
                                                        placeholder="Mention your Reason..."
                                                        className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm resize-none overflow-hidden"
                                                      />
                                                      <p>
                                                        {
                                                          this.state.otherReason
                                                            .length
                                                        }
                                                        /{100}
                                                      </p>
                                                    </div>
                                                  </>
                                                )}
                                            </FormControl>
                                            <div className="flex justify-center space-x-4">
                                              <Buttonlink
                                                disabled={
                                                  this.state.disableSubmit
                                                }
                                                onClick={() =>
                                                  this.rejectOffer(
                                                    rfqDetail.id,
                                                    participant.product_id
                                                  )
                                                }
                                                text="Reject"
                                                className="px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white"
                                              />
                                              <Buttonlink
                                                disabled={false}
                                                onClick={() =>
                                                  this.setState({
                                                    disabled: false,
                                                  })
                                                }
                                                text="Cancel"
                                                className="px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white"
                                              />
                                            </div>
                                          </div>
                                        ) : (
                                          <>
                                            <Buttonlink
                                              disabled={
                                                this.state.disabledAccept
                                              }
                                              onClick={() =>
                                                this.acceptOffer(
                                                  rfqDetail.id,
                                                  participant.product_id
                                                )
                                              }
                                              text="Accept & Confirm"
                                              className="px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70  bg--primary white"
                                            />
                                            <Buttonlink
                                              disabled={this.state.disabled}
                                              onClick={() =>
                                                this.setState({
                                                  disabled:
                                                    participant.product_id,
                                                })
                                              }
                                              text="Reject"
                                              className={
                                                "px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--lightGray darkGray"
                                              }
                                            />
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  ) : participant.revision ? (
                                    <div className="flex flex-col py-3">
                                      <p className="Medium fs-16">
                                        {product?.title}
                                      </p>
                                      <p className="Regular fs-14">
                                        Buyer send revision on your previous
                                        quotation of ${participant.total}.
                                      </p>
                                      {rfqDetail?.messages?.some(
                                        (message) =>
                                          message?.receiver_id == authUser.id &&
                                          message?.is_buyer === 0
                                      ) ? (
                                        <Tooltips
                                          trigger={
                                            <div className="flex justify-end relative ml-20">
                                              <Buttonlink
                                                onClick={() =>
                                                  this.openChatPopup()
                                                }
                                                text="Message"
                                                className="px-6 py-2.5 fs-15 ml-auto leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--lightGray darkGray"
                                              />
                                              <div className="absolute right-0 top-[7px] w-2 h-2 bg-green-500 rounded-full" />
                                            </div>
                                          }
                                          toolcontent={
                                            "You might have some unread message"
                                          }
                                        />
                                      ) : (
                                        <Buttonlink
                                          onClick={() => this.openChatPopup()}
                                          text="Message"
                                          className="px-6 py-2.5 fs-15 ml-auto leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--lightGray darkGray"
                                        />
                                      )}
                                      <div className="py-6 text-center">
                                        <Buttonlink
                                          to="/submit-rfq-quote"
                                          state={{
                                            rfq: rfqDetail,
                                            isRivision: true,
                                          }}
                                          text="Submit your RFQ quote"
                                          className="w-full px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white"
                                        />
                                      </div>
                                    </div>
                                  ) : participant.action_user_id ? (
                                    <div className="flex gap-y-2 flex-col py-3">
                                      <p className="Medium fs-16">
                                        {product?.title}
                                      </p>
                                      <p className="Regular fs-14">
                                        You Rejected this Quote!!
                                      </p>
                                      <p className="Regular fs-14">
                                        <span className="font-bold Bold">
                                          Reason:
                                        </span>{" "}
                                        {participant.reason}
                                      </p>
                                    </div>
                                  ) : participant.readable_status ==
                                    "Pending" ? (
                                    <div className="flex flex-col py-3">
                                      <p className="Medium fs-16">
                                        {product?.title}
                                      </p>
                                      <p className="Regular fs-14">
                                        Your Quotation has been Submitted,
                                        Waiting for buyer's response!!
                                      </p>
                                    </div>
                                  ) : null
                                ) : participant.accepted &&
                                  participant.user?.id == authUser?.id ? (
                                  <>
                                    <div className="flex flex-col justify-center intro-y">
                                      <p className="Medium text-lg">
                                        {product?.title}
                                      </p>
                                      <h3 className="text-xl Medium">
                                        {rfqDetail.company?.title}
                                      </h3>
                                      <div className="mt-5 space-y-3">
                                        <div className="flex items-center gap-x-5">
                                          <p className="w-28 gray">Email:</p>
                                          <p>{rfqDetail.company?.email}</p>
                                        </div>
                                        <div className="flex items-center gap-x-5">
                                          <p className="w-28 gray">Phone:</p>
                                          <p>{rfqDetail.company?.phone}</p>
                                        </div>
                                        <div className="flex items-center gap-x-5">
                                          <p className="w-28 gray">Address:</p>
                                          <p>{rfqDetail.company?.address}</p>
                                        </div>
                                        <div className="flex items-center gap-x-5">
                                          <p className="w-28 gray">State:</p>
                                          <p>
                                            {rfqDetail.company?.state?.name}
                                          </p>
                                        </div>
                                        <div className="flex items-center gap-x-5">
                                          <p className="w-28 gray">City:</p>
                                          <p>{rfqDetail.company?.city}</p>
                                        </div>
                                        <div className="flex items-center gap-x-5">
                                          <p className="w-28 gray">
                                            Postal Code:
                                          </p>
                                          <p>{rfqDetail.company.postal_code}</p>
                                        </div>
                                        <div className="flex items-center gap-x-5">
                                          <p className="w-28 gray">Country:</p>
                                          <p>{rfqDetail?.company?.country}</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mb-8">
                                      {sellerratting &&
                                        sellerratting.length > 0 ? (
                                        sellerratting.map((data) => (
                                          <>
                                            <div className="my-5">
                                              <p className="text-xl Medium">
                                                Ratings
                                              </p>
                                              <div className="flex items-end">
                                                <ReactStars
                                                  classNames="mt-2"
                                                  count={5}
                                                  edit={false}
                                                  color={"#d1d5db"}
                                                  activeColor={"#ffd700"}
                                                  value={parseInt(data.rate)}
                                                  size={24}
                                                  emptyIcon={
                                                    <i className="far fa-star"></i>
                                                  }
                                                  halfIcon={
                                                    <i className="fa fa-star-half-alt"></i>
                                                  }
                                                  fullIcon={
                                                    <i className="fa fa-star"></i>
                                                  }
                                                />
                                                <p className="ml-3 fs-18">
                                                  {data.rate}
                                                </p>
                                              </div>
                                            </div>
                                            <div
                                              className="mt-5"
                                              key={data.toString()}
                                            >
                                              <h2 className="text-xl Medium">
                                                Reviews
                                              </h2>
                                              <p className="mb-2">
                                                {data.comment}
                                              </p>
                                            </div>
                                          </>
                                        ))
                                      ) : (
                                        <>
                                          <div className="my-5">
                                            <p className="text-xl Medium">
                                              Ratings
                                            </p>
                                            <div className="flex items-end">
                                              <ReactStars
                                                classNames="mt-2"
                                                {...ratingSetting}
                                              />
                                              <p className="ml-3 fs-18">
                                                {this.state.ratingValues}
                                              </p>
                                            </div>
                                          </div>
                                          <textarea
                                            rows={3}
                                            className="block w-full px-3 py-3 mt-2 mb-3 placeholder-gray-500 border border-gray-300 rounded-md shadow-sm darkGray Light"
                                            placeholder="Add Reviews"
                                            maxLength={200}
                                            onChange={this.ReviewsRatting}
                                          />
                                          <Buttonlink
                                            disabled={this.state.submitDisabled}
                                            onClick={() =>
                                              this.SubmitRating(
                                                participant.product_id
                                              )
                                            }
                                            text="Submit"
                                            className={`${this.state.submitDisabled
                                              ? "gray bg--lightGray hover:bg--gray"
                                              : "primary bg--lightPrimary hover:bg--primary"
                                              }w-52 hover:white hover:opacity-80 flex justify-center py-2.5 px-4 border border-transparent rounded-lg focus:outline-none`}
                                          />
                                        </>
                                      )}
                                    </div>
                                  </>
                                ) : null;
                              }
                            )}
                          {showReSubmit && (
                            <div className="py-6 text-center mt-10">
                              <Buttonlink
                                to="/submit-rfq-quote"
                                state={{ rfq: rfqDetail, submitAgain: true }}
                                text="Submit RFQ quote again on other Products"
                                className="w-full px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white"
                              />
                            </div>
                          )}
                          {hasUnapprovedParticipant && (
                            <p>Your quote has been submitted</p>
                          )}
                        </div>
                      ) : !this.state.rfqDetail.isAccepted &&
                        this.state.rfqDetail.isApproved ? (
                        <>
                          {rfqDetail?.approvedParticipant?.length > 0 &&
                            rfqDetail.approvedParticipant.map(
                              (participant, index) =>
                                participant.expected_date === null ? (
                                  participant.is_approve ? (
                                    <div key={index}>
                                      <p>
                                        {participant.approved &&
                                          rfqDetail.company
                                          ? rfqDetail.company?.title
                                          : "anonymous"}{" "}
                                        has accepted your offer for supplying{" "}
                                        {rfqDetail.quantity} {rfqDetail.uom} of{" "}
                                        {rfqDetail?.title} at{" "}
                                        {amountFormat(
                                          (
                                            participant.total /
                                            rfqDetail.quantity
                                          ).toFixed(2)
                                        )}{" "}
                                        and overall offer price of{" "}
                                        {amountFormat(participant.total)} and
                                        other terms of delivery and quality as
                                        stated in the offer. Please confirm
                                        acceptance by clicking accept and
                                        confirm button.
                                      </p>
                                    </div>
                                  ) : (
                                    <div key={index}>
                                      {/* <p>
                                        Buyer rejected your quotation with
                                        expected delivery date{" "}
                                        {participant.expected_date}. You can
                                        also reject the quotation if you are not
                                        able to deliver on the given last date.
                                      </p> */}
                                      <p>
                                        {rfqDetail.company
                                          ? rfqDetail.company?.title
                                          : "anonymous"}{" "}
                                        has accepted your offer, for supplying{" "}
                                        {/* {rfqDetail.quantity}
                                        {rfqDetail.uom} of {rfqDetail?.title} at{" "}
                                        {amountFormat(
                                          (
                                            participant.total /
                                            rfqDetail.quantity
                                          ).toFixed(2)
                                        )}{" "}
                                        and overall offer price of{" "}
                                          {amountFormat(participant.total)} */}
                                        and other terms of delivery and quality
                                        as stated in the offer. Please confirm
                                        acceptance by clicking accept and
                                        confirm button.
                                      </p>
                                      <div className="flex items-center justify-center mt-6 gap-x-5">
                                        {this.state.disabled ===
                                          participant.product_id ? (
                                          <div>
                                            <FormControl>
                                              <FormLabel id="demo-radio-buttons-group-label">
                                                Please Select Reason of
                                                Rejection(Required)
                                              </FormLabel>
                                              <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue="I am out of Stock"
                                                name="radio-buttons-group"
                                                value={
                                                  this.state.rejectionReason
                                                } // Add a value prop to track the selected reason
                                                onChange={(event) => {
                                                  this.setState({
                                                    rejectionReason:
                                                      event.target.value,
                                                    disableSubmit: false,
                                                  });
                                                }}
                                              >
                                                <FormControlLabel
                                                  value="I am out of Stock"
                                                  control={<Radio />}
                                                  label="I am out of Stock"
                                                />
                                                <FormControlLabel
                                                  value="I have found something suspicious about buyer"
                                                  control={<Radio />}
                                                  label="I have found something suspicious about buyer"
                                                />
                                                <FormControlLabel
                                                  value="Buyer changed Requirements"
                                                  control={<Radio />}
                                                  label="Buyer changed Requirements"
                                                />
                                                <FormControlLabel
                                                  value="I am not interested anymore"
                                                  control={<Radio />}
                                                  label="I am not interested anymore"
                                                />
                                                <FormControlLabel
                                                  value="other"
                                                  control={<Radio />}
                                                  label="other"
                                                />
                                              </RadioGroup>
                                              {/* Show a textbox if "other" is selected */}
                                              {this.state.rejectionReason ===
                                                "other" && (
                                                  <>
                                                    <div className="flex items-end">
                                                      <textarea
                                                        value={
                                                          this.state.otherReason
                                                        }
                                                        // onChange={this.handleTextAreaChange}
                                                        rows={1}
                                                        onChange={(event) => {
                                                          // Limit input to 50 characters
                                                          const inputText =
                                                            event.target.value.slice(
                                                              0,
                                                              100
                                                            );
                                                          this.adjustTextareaHeight(
                                                            event.target
                                                          );
                                                          this.setState({
                                                            otherReason:
                                                              inputText,
                                                            disableSubmit: false,
                                                          });
                                                        }}
                                                        //value={message}
                                                        maxLength="100"
                                                        cols={50}
                                                        placeholder="Mention your Reason..."
                                                        className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm resize-none overflow-hidden"
                                                      />
                                                      <p>
                                                        {
                                                          this.state.otherReason
                                                            .length
                                                        }
                                                        /{100}
                                                      </p>
                                                    </div>
                                                  </>
                                                )}
                                            </FormControl>
                                            <div className="flex justify-center space-x-4">
                                              <Buttonlink
                                                disabled={
                                                  this.state.disableSubmit
                                                }
                                                onClick={() =>
                                                  this.rejectOffer(
                                                    rfqDetail.id,
                                                    participant.product_id
                                                  )
                                                }
                                                text="Reject"
                                                className="px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white"
                                              />
                                              <Buttonlink
                                                disabled={false}
                                                onClick={() =>
                                                  this.setState({
                                                    disabled: false,
                                                  })
                                                }
                                                text="Cancel"
                                                className="px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white"
                                              />
                                            </div>
                                          </div>
                                        ) : (
                                          <>
                                            <Buttonlink
                                              disabled={
                                                this.state.disabledAccept
                                              }
                                              onClick={() =>
                                                this.acceptOffer(
                                                  rfqDetail.id,
                                                  participant.product_id
                                                )
                                              }
                                              text="Accept & Confirm"
                                              className="px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70  bg--primary white"
                                            />
                                            <Buttonlink
                                              disabled={this.state.disabled}
                                              onClick={() =>
                                                this.setState({
                                                  disabled:
                                                    participant.product_id,
                                                })
                                              }
                                              text="Reject"
                                              className={
                                                "px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--lightGray darkGray"
                                              }
                                            />
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  )
                                ) : null
                            )}
                        </>
                      ) : rfqDetail.isAccepted &&
                        rfqDetail.isApproved &&
                        rfqDetail.company &&
                        rfqDetail.company?.title ? (
                        <>
                          <div className="flex flex-col justify-center intro-y">
                            <h3 className="text-xl Medium">
                              {rfqDetail.company?.title}
                            </h3>
                            <div className="mt-5 space-y-3">
                              <div className="flex items-center gap-x-5">
                                <p className="w-28 gray">Email:</p>
                                <p>{rfqDetail.company?.email}</p>
                              </div>
                              <div className="flex items-center gap-x-5">
                                <p className="w-28 gray">Phone:</p>
                                <p>{rfqDetail.company?.phone}</p>
                              </div>
                              <div className="flex items-center gap-x-5">
                                <p className="w-28 gray">Address:</p>
                                <p>{rfqDetail.company?.address}</p>
                              </div>
                              <div className="flex items-center gap-x-5">
                                <p className="w-28 gray">State:</p>
                                <p>{rfqDetail.company?.state?.name}</p>
                              </div>
                              <div className="flex items-center gap-x-5">
                                <p className="w-28 gray">City:</p>
                                <p>{rfqDetail.company?.city}</p>
                              </div>
                              <div className="flex items-center gap-x-5">
                                <p className="w-28 gray">Postal Code:</p>
                                <p>{rfqDetail.company.postal_code}</p>
                              </div>
                              <div className="flex items-center gap-x-5">
                                <p className="w-28 gray">Country:</p>
                                <p>{rfqDetail?.company?.country}</p>
                              </div>
                            </div>
                          </div>
                          <div className="mb-8">
                            {sellerratting && sellerratting.length > 0 ? (
                              sellerratting.map((data) => (
                                <>
                                  <div className="my-5">
                                    <p className="text-xl Medium">Ratings</p>
                                    <div className="flex items-end">
                                      <ReactStars
                                        classNames="mt-2"
                                        count={5}
                                        edit={false}
                                        color={"#d1d5db"}
                                        activeColor={"#ffd700"}
                                        value={parseInt(data.rate)}
                                        size={24}
                                        emptyIcon={
                                          <i className="far fa-star"></i>
                                        }
                                        halfIcon={
                                          <i className="fa fa-star-half-alt"></i>
                                        }
                                        fullIcon={
                                          <i className="fa fa-star"></i>
                                        }
                                      />
                                      <p className="ml-3 fs-18">{data.rate}</p>
                                    </div>
                                  </div>
                                  <div className="mt-5" key={data.toString()}>
                                    <h2 className="text-xl Medium">Reviews</h2>
                                    <p className="mb-2">{data.comment}</p>
                                  </div>
                                </>
                              ))
                            ) : (
                              <>
                                <div className="my-5">
                                  <p className="text-xl Medium">Ratings</p>
                                  <div className="flex items-end">
                                    <ReactStars
                                      classNames="mt-2"
                                      {...ratingSetting}
                                    />
                                    <p className="ml-3 fs-18">
                                      {this.state.ratingValues}
                                    </p>
                                  </div>
                                </div>
                                <textarea
                                  rows={3}
                                  className="block w-full px-3 py-3 mt-2 mb-3 placeholder-gray-500 border border-gray-300 rounded-md shadow-sm darkGray Light"
                                  placeholder="Add Reviews"
                                  maxLength={200}
                                  onChange={this.ReviewsRatting}
                                />
                                <Buttonlink
                                  disabled={this.state.submitDisabled}
                                  onClick={() => this.SubmitRating(null)}
                                  text="Submit"
                                  className={`${this.state.submitDisabled
                                    ? "gray bg--lightGray hover:bg--gray"
                                    : "primary bg--lightPrimary hover:bg--primary"
                                    }w-52 hover:white hover:opacity-80 flex justify-center py-2.5 px-4 border border-transparent rounded-lg focus:outline-none`}
                                />
                              </>
                            )}
                          </div>
                        </>
                      ) : rfqDetail?.participants?.data?.find(
                        (o) => o.action_user_id == authUser?.id
                      ) ? (
                        <div className="flex">
                          <div className="leading-10">
                            {"You Rejected, Reason:  " +
                              sellerParticipent?.reason}
                          </div>
                        </div>
                      ) : (
                        <div className="flex">
                          <div className="leading-10">
                            {rfqDetail.isBlocked ? (
                              "User has rejected you from this RFQ!!"
                            ) : rfqDetail.isRevision ? (
                              <>
                                <div className="flex">
                                  <div className="leading-10">
                                    Buyer sent a revision on your previous
                                    quotation
                                    {/* of ${
                                              rfqDetail?.participants?.data?.find(
                                                (o) => o.user.id == authUser?.id
                                              )?.total
                                            }. */}
                                  </div>
                                </div>

                                <Buttonlink
                                  to="/submit-rfq-quote"
                                  // onClick={() =>
                                  //   this.userHasCategories(rfqDetail.categories)
                                  // }
                                  state={{ rfq: rfqDetail }}
                                  text="Submit Revised Quotation"
                                  className="px-6 py-2.5 fs-15 mt-5 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white"
                                />
                              </>
                            ) : (
                              "Rfq has been submitted succesfully!!"
                            )}
                          </div>
                          {rfqDetail?.messages?.some(
                            (message) =>
                              message?.receiver_id == authUser.id &&
                              message?.is_buyer === 0
                          ) ? (
                            <Tooltips
                              trigger={
                                <div className="flex justify-end relative ml-20">
                                  <Buttonlink
                                    onClick={() => this.openChatPopup()}
                                    text="Message"
                                    className="px-6 py-2.5 fs-15 ml-auto leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--lightGray darkGray"
                                  />
                                  <div className="absolute right-0 top-[7px] w-2 h-2 bg-green-500 rounded-full" />
                                </div>
                              }
                              toolcontent={"You might have some unread message"}
                            />
                          ) : (
                            <Buttonlink
                              onClick={() => this.openChatPopup()}
                              text="Message"
                              className="px-6 py-2.5 fs-15 ml-auto leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--lightGray darkGray"
                            />
                          )}

                          <div className="flex justify-end items-end ml-3">
                            {rfqDetail.isBookmarked ? (
                              <div
                                onClick={() =>
                                  this.removeBookmark(
                                    rfqDetail.id,
                                    rfqDetail.isBookmarked
                                  )
                                }
                                className="h-9 w-9 cursor-pointer z-10 bg-[#ef553b] rounded-full flex items-center justify-center"
                              >
                                <BsBookmark className="text-white" />
                              </div>
                            ) : (
                              <div
                                onClick={() =>
                                  this.AddBookmark(
                                    rfqDetail.id,
                                    rfqDetail.isBookmarked
                                  )
                                }
                                className="z-10 flex items-center justify-center bg-gray-200 rounded-full cursor-pointer h-9 w-9"
                              >
                                <BsBookmark className="gray" />
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    ) : rfqDetail.menu_id === 6 || rfqDetail.menu_id === 7 ? (
                      false
                    ) : rfqDetail.readable_status === "Expired" ||
                      rfqDetail.readable_status === "Completed" ? (
                      <div className="py-6 text-center">
                        <Buttonlink
                          disabled={true}
                          to="#"
                          text={
                            rfqDetail.readable_status === "Expired"
                              ? "RFQ Is Expired Can't Send Quote"
                              : "RFQ Is Completed Can't Send Quote"
                          }
                          className="w-full px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--gray white hover:text-white"
                        />
                      </div>
                    ) : (
                      <div className="py-6 text-center">
                        {!matchingExecutive ? (
                          <>
                            <Buttonlink
                              // to="/submit-rfq-quote"
                              onClick={() =>
                                this.userHasCategories(rfqDetail.categories)
                              }
                              text="Please check your RFQ details"
                              className="max-w-fit px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white"
                            />
                            <div className="flex justify-end items-end">
                              {rfqDetail.isBookmarked ? (
                                <div
                                  onClick={() =>
                                    this.removeBookmark(
                                      rfqDetail.id,
                                      rfqDetail.isBookmarked
                                    )
                                  }
                                  className="h-9 w-9 cursor-pointer z-10 bg-[#ef553b] rounded-full flex items-center justify-center"
                                >
                                  <BsBookmark className="text-white" />
                                </div>
                              ) : (
                                <div
                                  onClick={() =>
                                    this.AddBookmark(
                                      rfqDetail.id,
                                      rfqDetail.isBookmarked
                                    )
                                  }
                                  className="z-10 flex items-center justify-center bg-gray-200 rounded-full cursor-pointer h-9 w-9"
                                >
                                  <BsBookmark className="gray" />
                                </div>
                              )}
                            </div>
                          </>
                        ) : (
                          <div className="text-left">
                            You Can't Participate to this RFQ
                          </div>
                        )}
                      </div>
                    )
                  ) : !auth.isAuthenticated() ? (
                    false
                  ) : (
                    <div className="flex items-center justify-between border-y intro-y">
                      <div
                        className={`${rfqDetail.menu_id === 7
                          ? "visible flex items-center gap-2"
                          : "invisible"
                          }`}
                      >
                        <img
                          alt="messages"
                          src={messages}
                          className="cursor-pointer h-7 w-7"
                          onClick={(e) =>
                            this.setState({
                              showComments: !this.state.showComments,
                            })
                          }
                        />
                        <p className="gray fs-13 Light">
                          {rfqDetail.comments &&
                            rfqDetail.comments.paginatorInfo.total}
                        </p>
                      </div>

                      {rfqDetail.isBookmarked ? (
                        <div
                          onClick={() =>
                            this.removeBookmark(
                              rfqDetail.id,
                              rfqDetail.isBookmarked
                            )
                          }
                          className="h-9 w-9 cursor-pointer z-10 bg-[#ef553b] rounded-full flex items-center justify-center"
                        >
                          <BsBookmark className="text-white" />
                        </div>
                      ) : (
                        <div
                          onClick={() =>
                            this.AddBookmark(
                              rfqDetail.id,
                              rfqDetail.isBookmarked
                            )
                          }
                          className="z-10 flex items-center justify-center bg-gray-200 rounded-full cursor-pointer h-9 w-9"
                        >
                          <BsBookmark className="gray" />
                        </div>
                      )}
                    </div>
                  )}

                  {this.state.showComments === true &&
                    rfqDetail.comments.data.length > 0 &&
                    rfqDetail.comments.data.map((comment) => {
                      return (
                        <div className="pt-3">
                          <div className="flex items-center gap-x-3">
                            <Avatar
                              alt={comment.user.username}
                              src={
                                comment.user?.profile_photo !== null ? (
                                  process.env.REACT_APP_PL_PROFILE_PHOTO_URL +
                                  comment.user.profile_photo
                                ) : (
                                  <Avatar alt={comment.user.firstname} />
                                )
                              }
                              style={{ width: 72, height: 72 }}
                            />
                            <div>
                              <h3 className="leading-5 Medium text-md">
                                {comment.user.firstname +
                                  " " +
                                  comment.user.lastname}
                              </h3>
                              <p className="gray fs-13">
                                {comment.comment_body}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div>
                <div className="p-5 space-y-2 overflow-hidden box">
                  <div>
                    <h3 className="mb-2 text-xl Medium">Categories:</h3>
                    <div className="flex flex-wrap items-center gap-2 selected-tags ">
                      {rfqDetail &&
                        rfqDetail.categories &&
                        Array.from(
                          new Set(
                            rfqDetail.categories.map((categories) => categories)
                          )
                        ).map((category, index) => (
                          <label
                            className="bg-gray-200 hover:opacity-70 rounded-lg px-5 py-1.5 inline-block"
                            key={category.id}
                          >
                            <p className="fs-13">{category.name}</p>
                          </label>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="p-5 mt-5 space-y-2 overflow-hidden box top-3">
                  {rfqDetail?.company && (
                    <div>
                      <h3 className="mb-2 text-xl Medium">Team:</h3>
                      <div className="flex items-center space-x-3 intro-y">
                        <Avatarimage
                          imagesrc={process.env.REACT_APP_MAIN_URL + `/${rfqDetail?.company?.logo}`}
                          firstname={rfqDetail.company?.title}
                          iscompany="true"
                          imagesize={{ width: 52, height: 52 }}
                        />
                        <div>
                          <p className="Medium">{rfqDetail?.company?.title}</p>
                        </div>
                      </div>
                      {auth.isAuthenticated() &&
                        !this.props.authUser.is_seller &&
                        is_executive && (
                          <>
                            <div
                              className={"bg-gray-200 h-[1px] w-full my-3"}
                            />
                            <div>
                              <h3 className="mb-2 text-xl Medium">Members:</h3>
                              <div className={"space-y-4"}>
                                {this.state.executives.length > 0 &&
                                  this.state.executives.map((user, index) => {
                                    const name = `${user.firstname} ${user.lastname}`;
                                    return (
                                      <div
                                        className={"flex items-center gap-x-3"}
                                      >
                                        <div>
                                          <Profileavatar
                                            singleUser={user}
                                            sizeStyle={{
                                              width: 42,
                                              height: 42,
                                            }}
                                          />
                                        </div>
                                        <p className="block primary hover:primary fs-14 hover:opacity-80">
                                          {user.firstname.length +
                                            user.lastname.length <=
                                            20
                                            ? `${user.firstname} ${user.lastname}`
                                            : `${name.substring(0, 17)}...`}
                                        </p>
                                        <div className="w-4 h-4 -ml-2">
                                          <VerifyIcon user={user} />
                                        </div>
                                        <div className="ml-auto">
                                          <p className="hover:bg--gray-200 fs-14 right hover:opacity-80">
                                            {user?.userCompanyOfferRole?.toUpperCase()}
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  })}
                                {/*{rfqDetail.executives.length > 0 ?
                                                        rfqDetail.executives.map((user, index) => {
                                                            return (
                                                                <div className={"flex items-center gap-x-3"}>
                                                                    <div>
                                                                        <Profileavatar
                                                                            singleUser={user}
                                                                            sizeStyle={{width: 42, height: 42}}
                                                                        />
                                                                    </div>
                                                                    <p className="block primary hover:primary fs-14 hover:opacity-80">
                                                                        {user.firstname} {user.lastname}
                                                                    </p>
                                                                </div>
                                                            );
                                                        })
                                                        :
                                                        rfqDetail.company.executives.map((user, index) => {
                                                            return (
                                                                <div className={"flex items-center gap-x-3"}>
                                                                    <div>
                                                                        <Profileavatar
                                                                            singleUser={user}
                                                                            sizeStyle={{width: 42, height: 42}}
                                                                        />
                                                                    </div>
                                                                    <p className="block primary hover:primary fs-14 hover:opacity-80">
                                                                        {user.firstname} {user.lastname}
                                                                    </p>
                                                                </div>
                                                            );
                                                        })
                                                    }*/}
                              </div>
                            </div>
                          </>
                        )}
                    </div>
                  )}
                </div>
                {rfqDetail.offerSignature !== "false" && (
                  <div className="p-5 mt-5 space-y-3 overflow-hidden box top-3">
                    <h3 className="mb-2 text-xl Medium">NDA Agreement</h3>
                    {rfqDetail.offerSignature === "true" ? (
                      <Buttonlink
                        className="flex items-center h-10 px-6 leading-5 rounded-lg shadow bg--lightGray darkGray fs-15 Regular hover:opacity-80 gap-x-2"
                        href={`${process.env.REACT_APP_MAIN_URL
                          }/non-disclosure-agreement/${encryptfunction(
                            rfqDetail?.id + "," + this.props?.authUser?.id
                          )}`}
                        target="_blank"
                        prefix={<VscFilePdf size={16} className="darkGray" />}
                        text="Agreement file"
                      />
                    ) : (
                      <a
                        download
                        className="flex items-center h-10 px-6 leading-5 rounded-lg shadow bg--lightGray darkGray fs-15 Regular hover:opacity-80 gap-x-2"
                        href={rfqDetail?.offerSignature}
                        target="_blank"
                      >
                        Agreement files
                      </a>
                    )}
                  </div>
                )}
                {BuyerRatting?.length > 0 &&
                  this.props?.authUser?.is_seller && (
                    <div className="p-5 mt-5 space-y-2 overflow-hidden box top-3">
                      <div>
                        <h3 className="text-xl Medium">Buyer Review</h3>
                        {BuyerRatting.map((data) => (
                          <div>
                            <div className="mt-5">
                              <div className="flex items-center justify-between">
                                <p className="mb-3 leading-none Medium">
                                  Overall Rating
                                </p>
                                <div className="flex items-center mb-5 ml-auto leading-none">
                                  <ReactStars
                                    {...supplierReview}
                                    value={data.rate}
                                  />
                                  <p className="w-6 ml-3 leading-none fs-16">
                                    {data.rate}
                                  </p>
                                </div>
                              </div>
                              <div className="flex flex-wrap items-center justify-between col-span-12 md:col-span-9">
                                <p className="mb-3 leading-none Medium">
                                  Responsiveness To RFQs
                                </p>
                                <div className="flex items-center mb-5 ml-auto leading-none">
                                  <ReactStars
                                    {...supplierReview}
                                    value={data.responsiveness}
                                  />
                                  <p className="w-6 ml-3 leading-none fs-16">
                                    {data.responsiveness}
                                  </p>
                                </div>
                              </div>
                              <div className="flex flex-wrap items-center justify-between col-span-12 md:col-span-9">
                                <p className="mb-3 leading-none Medium">
                                  Ability To Ship Parts Ordered
                                </p>
                                <div className="flex items-center mb-5 ml-auto leading-none">
                                  <ReactStars
                                    {...supplierReview}
                                    value={data.shipment}
                                  />
                                  <p className="w-6 ml-3 leading-none fs-16">
                                    {data.shipment}
                                  </p>
                                </div>
                              </div>
                              <div className="flex flex-wrap items-center justify-between col-span-12 md:col-span-9">
                                <p className="mb-3 leading-none Medium">
                                  Competitive Lead Time
                                </p>
                                <div className="flex items-center mb-5 ml-auto leading-none">
                                  <ReactStars
                                    {...supplierReview}
                                    value={data.lead_time}
                                  />
                                  <p className="w-6 ml-3 leading-none fs-16">
                                    {data.lead_time}
                                  </p>
                                </div>
                              </div>
                              <div className="flex flex-wrap items-center justify-between col-span-12 md:col-span-9">
                                <p className="mb-3 leading-none Medium">
                                  Delivered Parts
                                </p>
                                <div className="flex items-center mb-5 ml-auto leading-none">
                                  <ReactStars
                                    {...supplierReview}
                                    value={data.delivered}
                                  />
                                  <p className="w-6 ml-3 leading-none fs-16">
                                    {data.delivered}
                                  </p>
                                </div>
                              </div>
                              <div className="flex flex-wrap items-center justify-between col-span-12 md:col-span-9">
                                <p className="mb-3 leading-none Medium">
                                  Compliance Rate
                                </p>
                                <div className="flex items-center mb-5 ml-auto leading-none">
                                  <ReactStars
                                    {...supplierReview}
                                    value={data.compliance}
                                  />
                                  <p className="w-6 ml-3 leading-none fs-16">
                                    {data.compliance}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <p className="text-xl Medium">Reviews</p>
                            <p>{data.comment}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                {authUser?.id && rfqDetail?.id && (
                  <ChatPopup
                    openChatPopup={() => this.openChatPopup()}
                    chatPopup={this.state.chatPopup}
                    authUser={authUser}
                    onClose={() => this.getOfferByID()}
                    loading={this.state.chatLoader}
                    rfq={rfqDetail}
                    is_seller={this.props.authUser.is_seller}
                    canMessage={executiveIdsWithCanMessage}
                    receiver_id={rfqDetail?.user?.id}
                  />
                )}
                <ConfirmationDialog
                  data={rfqDetail}
                  navigate={this.props.navigate}
                  ref={this.OpenConfirmation}
                  callBack={this.callBack}
                />
              </div>
            </div>
          </div>
        )}
        <Dialog
          fullWidth={false}
          maxWidth={"sm"}
          open={open}
          onClose={this.closePopup}
          className="rounded--xl"
        >
          <div className="p-10 mt-5 relative flex items-center flex-col gap-5">
            <div className="absolute right-3 -top-2 bg-white">
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.closePopup}
                aria-label="close"
              >
                <IoCloseCircleOutline size={32} className="primary" />
              </IconButton>
            </div>

            {/* Conditional Rendering */}
            {this.state.rfqDetail?.is_public === true ? (
              <p>you are not entitled to view this RFQ</p>
            ) : (
              <>
                <div className="flex flex-wrap gap-2 justify-center md:justify-start">
                  {this.state.rfqDetail &&
                    this.state.rfqDetail.categories &&
                    this.state.rfqDetail.categories.slice(0, 1).map((category) => (
                      <label
                        className="bg-gray-200 hover:opacity-70 rounded-lg px-5 py-1.5 inline-block"
                        key={category?.id}
                      >
                        <p className="fs-13">{category?.name}</p>
                      </label>
                    ))}
                </div>
                <Buttonlink
                  className="w-32 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:bg--primary hover:opacity-70 bg--primary white hover:white gap-x-3 flex items-center justify-center"
                  disabled={
                    authUser.categories?.length >= maxCategoriesProfile
                      ? true
                      : false
                  }
                  onClick={() =>
                    this.addCategoriesToAdmin(this.state.rfqDetail.categories)
                  }
                  text="Add Categories"
                ></Buttonlink>
                <div>
                  {authUser.categories?.length >= maxCategoriesProfile && (
                    <Alert severity="warning">
                      You can't add more than {maxCategoriesProfile} categories in your profile
                    </Alert>
                  )}
                </div>
              </>
            )}
          </div>
        </Dialog>

        <Dialog
          fullWidth={false}
          maxWidth={"sm"}
          open={modalOpen}
          onClose={this.closePopup}
          className="rounded--xl"
        >
          <div className="p-10 mt-5 relative flex items-center flex-col gap-5">
            <div className="absolute right-3 -top-2 bg-white">
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.closePopup}
                aria-label="close"
              >
                <IoCloseCircleOutline size={32} className="primary" />
              </IconButton>
            </div>
            <div className="flex flex-wrap gap-2 justify-center md:justify-start">
              <p>
                You cannot submit a quote to this RFQ as you don’t have related
                category attached with your profile.
              </p>
              {/* {
                this.state.rfqDetail &&
                this.state.rfqDetail.categories &&
                this.state.rfqDetail.categories.slice(0, 1).map((category) => (
                  <label
                    className="bg-gray-200 hover:opacity-70 rounded-lg px-5 py-1.5 inline-block"
                    key={category?.id}
                  >
                    <p className="fs-13">{category?.name}</p>
                  </label>
                ))
              } */}
            </div>
            <Buttonlink
              className="w-32 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:bg--primary hover:opacity-70 bg--primary white hover:white gap-x-3 flex items-center justify-center"
              // disabled={authUser.categories?.length >= maxCategoriesProfile ? true : false}
              to="/rfq/invitations"
              // onClick={() =>
              //   this.addCategoriesToAdmin(this.state.rfqDetail.categories)
              // }
              text="OK"
            ></Buttonlink>
            {/* <div>
              {authUser.categories?.length >= maxCategoriesProfile && (
                <Alert severity="warning">
                  You can't add more than {maxCategoriesProfile} categories in your profile
                </Alert>
              )}
            </div> */}
            {/*<Buttonlink
              className="w-32 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:bg--primary hover:opacity-70 bg--primary white hover:white gap-x-3 flex items-center justify-center"
              to="/pricing"
              text="Buy Now"
            ></Buttonlink>*/}
          </div>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = ({ Auth, Rfq }) => {
  return {
    authUser: Auth.authUserInformation,
    socket: Auth.socket,
    recallRef: Rfq.recallRef,
  };
};

export default connect(mapStateToProps, null)(RFQdetail);
