import React, { useState } from "react";
import OrderModal from "./OrderModal";

const OrdersDeliveries = ({ data }) => {
  // console.log("data",data);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal(id) {
    setSelectedId(id);
    setIsOpen(true);
  }

  const deliveryStatus = [
    {
      id: 4,
      label: "Order delivered but not acknowledged by user",
    },
    {
      id: 5,
      label: "Order delivered but not confirmed for quality and quantity",
    },
    {
      id: 6,
      label: "Order dispatched for quality and quantity",
    },
    {
      id: 7,
      label: "Order confirmed in payment",
    },
  ];

  return (
    <div className="p-5 bg-white ml-3">
      <h6 className="Medium fs-18 font-semibold pb-6">Order Deliveries</h6>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-10">
        {deliveryStatus.map((status) => (
          <button
            key={status.id}
            onClick={() => openModal(status.id)}
            className="flex gap-4 flex-col items-center justify-center bg--lightPrimary py-3"
          >
            <h6 className="Medium fs-20 font-medium">{data[status.id].length}</h6>
            <p className="Regular fs-14 text-center">{status.label}</p>
          </button>
        ))}
      </div>

      <OrderModal
          isOpen={isOpen}
          closeModal={closeModal}
          data={data[selectedId]}
      />
    </div>
  );
};

export default OrdersDeliveries;
