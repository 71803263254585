import { Subbanner } from "components";

export default function AskQuotation() {
  return (
    <div className="h-full">
      <Subbanner title="Get quotes quickly with RFQ" text="" />
      <div className="container -mt-28">
        <div className="grid lg:grid-cols-6 grid-cols-1 gap-4">
          <div className="lg:col-start-2 lg:col-span-4">
            <div className="p-6 rounded-lg shadow-lg bg-white relative ring-1 ring-black ring-opacity-5 overflow-hidden">
              <h2 className="Medium text-2xl mb-4">
                How can we help you today?
              </h2>
              {/* <Getquotation menus={menu} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
