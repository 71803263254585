import { gql } from "@apollo/client";

export default gql`
    mutation accountActivation($token:String!){
        accountActivation(token:$token){
            status
            message
        }
    }
`;
