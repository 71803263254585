import React, { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import client from "../../../graphql/client";
import CHANGE_ROLE_MUTATION from "../../../graphql/mutations/changeRollMutation";
import AuthUserAction from "../../../store/actions/MyAuthUserAction";
import MyAuthUserAction from "../../../store/actions/MyAuthUserAction";
import { Loader } from "../..";
import cookie from "js-cookie";
import { gql } from "@apollo/client";
import { useNavigate } from "react-router-dom";

export default function SwitchToggle(props) {
  const [toParent, setToParent] = useState('');
  const [type, setType] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const [isChecked, setChecked] = useState(props.is_seller);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let canSwitch = false;
  if (props?.paymentPlan) {
    props.paymentPlan?.forEach((plan) => {
      if (plan.plan.plan_features) {
        plan.plan.plan_features.forEach((plan_feature) => {
          if (plan_feature.name === "Dual Role on same email account") {
            canSwitch = true;
          }
        });
      }
    });
  }

  useEffect(() => {
    if (showNotification) {
      props.valueUpdate(type, toParent);
      setShowNotification(false);
    }

  }, [showNotification]);

  function changeRole() {
    client.mutate({
      mutation: CHANGE_ROLE_MUTATION,
      variables: {
        user_id: props.user_id,
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateRole: {
          __typename: "User",
          id: props.user_id,
          is_seller: !props.is_seller,
          become_seller: props.become_seller,
        },
      },

      update: (store, { data: { updateRole } }) => {
        try {
          /* const data = store.readQuery({
            query: ME
          });
          console.log("data of me User",data)*/
          store.writeQuery({
            query: gql`
              query me {
                me {
                  id
                  is_seller
                  become_seller
                }
              }
            `,
            data: {
              me: {
                __typename: "User",
                id: props.user_id,
                is_seller: updateRole.is_seller,
                become_seller: props.become_seller,
              },
            },
          });
        } catch (e) {
          console.log("error on role switch", e);
        }
      },
    });
    AuthUserAction.getAuthUserInformation();
    setTimeout(() => {
      navigate('/');
    });
  }

  async function change() {
    setChecked(!isChecked);
    setLoading(true);
    if (props.become_seller) {
      // if (canSwitch) {
      const emptyCustomPlan = JSON.stringify({});
      cookie.set("CustomPlan", emptyCustomPlan);
      setType("success");
      setToParent("Your role has been changed");
      setShowNotification(true);
      await changeRole();
      setLoading(false);
    } else {
      setType("error");
      setToParent("You don't have permission to change your role");
      setShowNotification(true);
      setChecked(false);
      setLoading(false);
    }
  }

  return (
    <div className="flex items-center gap-x-2">
      {!loading && props.is_seller !== undefined ? (
        <>
          {props.is_seller ? (
            <p className="fs-12 sm:fs-15 w-[6.5rem]">Seller Mode</p>
          ) : (
            <p className="fs-12 sm:fs-15 w-[6.5rem]">Buyer Mode</p>
          )}
          <Switch
            checked={isChecked}
            onChange={change}
            className={`${props.is_seller ? "bg--primary" : "bg--primary"}
          relative inline-flex flex-shrink-0 h-8 w-[54px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            <span className="sr-only">Use setting</span>
            <span
              className={`${props.is_seller ? "translate-x-7" : "translate-x-0"}
            pointer-events-none inline-block h-7 w-7 rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
            />
          </Switch>

        </>
      ) : (
        <>
          <p>Please wait</p> <Loader spinner={true} />
        </>
      )}
    </div>
  );
}
