import React, { Component } from "react";

class MoreCategories extends Component {

    render() {
        return (
            <div className="relative p-6">
                <div className="gap-2 flex items-center flex-wrap selected-tags">
                    {
                        this.props.data.map((item) => {
                            return (
                                <div className="relative intro-x" key={item.id}>
                                    <label className="bg--lightPrimary rounded-lg border--primary hover:opacity-80 px-4 py-1.5 cursor-pointer flex items-center gap-x-2">
                                        <p className="fs-11">{item.name}</p>
                                    </label>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        );
    }
}

export default MoreCategories;
