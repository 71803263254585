import React from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { Popup } from "semantic-ui-react";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { FiCheck, FiX } from "react-icons/fi";
import { Buttonlink } from "components";
import {IoCloseCircleOutline} from "react-icons/io5";
import './styles.css';


export default class PricingPopup extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      singlePackage: true,
      singlePackage2: false,
      windowSize: window.innerWidth,
    };
  }
  openPopup = () => {
    this.setState({open:true})
  }
  closePopup = () => {
    this.setState({open:false})
  }

  resizeWidth = () => {
    this.setState({ windowSize: window.innerWidth });
    console.log(this.state.windowSize);
  };

  componentDidMount() {
    window.addEventListener('resize', this.resizeWidth);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeWidth);
  }

  singlePackageClick = () => {
    this.setState({ singlePackage: false });
    this.setState({ singlePackage2: true });
  };
  prevSinglePackageClick = () => {
    this.setState({ singlePackage: true });
    this.setState({ singlePackage2: false });
  };

  render() {
    return (
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        scroll="body"
        open={this.state.open}
        onClose={this.closePopup}
        className="rounded--xl"
      >
        <div className="">
          <Toolbar className="justify-between mt-5">
            <p className="Medium fs-26 text-center">Please Sign up to continue</p>
            <IconButton
              edge="start"
              color="inherit"
              onClick={this.closePopup}
              aria-label="close"
              className="ml-auto"
            >
              <IoCloseCircleOutline size={32} className="primary"/>
            </IconButton>
          </Toolbar>
          <div className="container">
            <div className="shadow-none rounded-lg bg-white overflow-hidden p-10">
              <div className="grid lg:grid-cols-4 grid-cols-3 gap-4">
                <div className="col-span-2">
                  <div className="flex items-center border-b h-16 relative">
                    <h1 className="Medium text-lg lg:text-2xl lg:flex hidden">
                      Plan Features & Pricing
                    </h1>
                    <h1 className="Medium text-lg lg:text-2xl lg:hidden flex">
                      Plan Features
                    </h1>
                    {this.state.singlePackage === false ? (
                      <div
                        onClick={this.prevSinglePackageClick}
                        className="absolute right-0 lg:hidden block"
                      >
                        <IoIosArrowBack className="primary" size={24} />
                        <p className="fs-12 primary">prev</p>
                      </div>
                    ) : null}
                    {this.state.singlePackage === true ? (
                      <div
                        onClick={this.singlePackageClick}
                        className="absolute right-0 lg:hidden block"
                      >
                        <IoIosArrowForward className="primary" size={24} />
                        <p className="fs-12 primary">next</p>
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <div className="h-12 lg:h-16 flex items-center">
                      <h4 className="Regular text-sm lg:text-lg mr-1 text-color">
                        Validity
                      </h4>
                      <Popup
                        trigger={
                          <AiOutlineQuestionCircle
                            className="gray cursor-pointer"
                            size={16}
                          />
                        }
                        content="The period for which your ProcureClass Subscription will be valid."
                        inverted
                      />
                    </div>
                    <div className="h-12 lg:h-16 flex items-center">
                      <h4 className="Regular text-sm lg:text-lg mr-1 text-color">
                        Hangouts
                      </h4>
                    </div>
                    <div className="h-12 lg:h-16 flex items-center">
                      <h4 className="Regular text-sm lg:text-lg mr-1 text-color">
                        Case Studies
                      </h4>
                      <Popup
                        trigger={
                          <AiOutlineQuestionCircle
                            className="gray cursor-pointer"
                            size={16}
                          />
                        }
                        content="Case Studies"
                        inverted
                      />
                    </div>
                    <div className="h-12 lg:h-16 flex items-center">
                      <h4 className="Regular text-sm lg:text-lg mr-1 text-color">
                        Special classes on discount
                      </h4>
                    </div>
                  </div>
                </div>
                {this.state.windowSize > 1023 
                  ?
                  <>
                    <div className="relative">
                        <div className="flex items-center justify-center flex-col border-b h-16">
                          <h4 className="Medium text-sm lg:text-lg">
                            All Access
                          </h4>
                          <p className="darkGray text-sm lg:text-lg">
                            $25.00/Monthly
                          </p>
                        </div>
                        <div className="h-12 lg:h-16 flex items-center justify-center border-b">
                          <p>1 month</p>
                        </div>
                        <div className="h-12 lg:h-16 flex items-center justify-center border-b">
                          <FiCheck className="success" size={20} />
                        </div>
                        <div className="h-12 lg:h-16 flex items-center justify-center border-b">
                          <FiCheck className="success" size={20} />
                        </div>
                        <div className="h-12 lg:h-16 flex items-center justify-center border-b">
                          <FiX className="danger" size={20} />
                        </div>
                        <div className="h-20 lg:h-20 flex items-center justify-center">
                          <Buttonlink
                            to="/payment"
                            text="Select"
                            rel="nofollow"
                            className="bg--primary text-white hover:text-white flex-grow flex items-center justify-center w-full Regular hover:bg--primary hover:opacity-70 focus:bg--primary border-0 rounded--full shadow-none h-12 px-5"
                          />
                        </div>
                    </div>
                    <div className="relative">
                      <div className="flex items-center justify-center flex-col border-b h-16">
                        <h4 className="Medium text-sm lg:text-lg">
                          All Access Annual Pro
                        </h4>
                        <p className="darkGray text-sm lg:text-lg">
                          $250.00/Yearly
                        </p>
                      </div>
                      <div className="h-12 lg:h-16 flex items-center justify-center border-b">
                        <p>12 month</p>
                      </div>
                      <div className="h-12 lg:h-16 flex items-center justify-center border-b">
                        <FiCheck className="success" size={20} />
                      </div>
                      <div className="h-12 lg:h-16 flex items-center justify-center border-b">
                        <FiCheck className="success" size={20} />
                      </div>
                      <div className="h-12 lg:h-16 flex items-center justify-center border-b">
                        <FiX className="danger" size={20} />
                      </div>
                      <div className="h-20 lg:h-20 flex items-center justify-center">
                        <Buttonlink
                          to="/payment"
                          text="Select"
                          rel="nofollow"
                          className="bg--primary text-white hover:text-white flex-grow flex items-center justify-center w-full Regular hover:bg--primary hover:opacity-70 focus:bg--primary border-0 rounded--full shadow-none h-12 px-5"
                        />
                      </div>
                    </div>
                  </>
                  :
                  null
                }
                {this.state.singlePackage && this.state.windowSize < 1023 
                  ?
                  <div className="relative">
                    <div className="flex items-center justify-center flex-col border-b h-16">
                      <h4 className="Medium text-sm lg:text-lg">
                        All Access
                      </h4>
                      <p className="darkGray text-sm lg:text-lg">
                        $25.00/Monthly
                      </p>
                    </div>
                    <div className="h-12 lg:h-16 flex items-center justify-center border-b">
                      <p>1 month</p>
                    </div>
                    <div className="h-12 lg:h-16 flex items-center justify-center border-b">
                      <FiCheck className="success" size={20} />
                    </div>
                    <div className="h-12 lg:h-16 flex items-center justify-center border-b">
                      <FiCheck className="success" size={20} />
                    </div>
                    <div className="h-12 lg:h-16 flex items-center justify-center border-b">
                      <FiX className="danger" size={20} />
                    </div>
                    <div className="h-20 lg:h-20 flex items-center justify-center">
                      <Buttonlink
                        to="/payment"
                        text="Select"
                        rel="nofollow"
                        className="bg--primary text-white hover:text-white flex-grow flex items-center justify-center w-full Regular hover:bg--primary hover:opacity-70 focus:bg--primary border-0 rounded--full shadow-none h-12 px-5"
                      />
                    </div>
                  </div>
                  :
                  null
                }
                {this.state.singlePackage2 && this.state.windowSize < 1023 
                  ?
                  <div className="relative">
                    <div className="flex items-center justify-center flex-col border-b h-16">
                      <h4 className="Medium text-sm lg:text-lg">
                        All Access Annual Pro
                      </h4>
                      <p className="darkGray text-sm lg:text-lg">
                        $250.00/Yearly
                      </p>
                    </div>
                    <div className="h-12 lg:h-16 flex items-center justify-center border-b">
                      <p>12 month</p>
                    </div>
                    <div className="h-12 lg:h-16 flex items-center justify-center border-b">
                      <FiCheck className="success" size={20} />
                    </div>
                    <div className="h-12 lg:h-16 flex items-center justify-center border-b">
                      <FiCheck className="success" size={20} />
                    </div>
                    <div className="h-12 lg:h-16 flex items-center justify-center border-b">
                      <FiX className="danger" size={20} />
                    </div>
                    <div className="h-20 lg:h-20 flex items-center justify-center">
                      <Buttonlink
                        to="/payment"
                        text="Select"
                        rel="nofollow"
                        className="bg--primary text-white hover:text-white flex-grow flex items-center justify-center w-full Regular hover:bg--primary hover:opacity-70 focus:bg--primary border-0 rounded--full shadow-none h-12 px-5"
                      />
                    </div>
                  </div>
                  :
                  null
                }
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    )
  }
}
