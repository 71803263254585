import { gql } from "@apollo/client";

export default gql`
  mutation ($username: [String!], $offer_id: Int!, $offer_title: String!) {
    sendEmailAndAlertsToInvitedUsers(
      username: $username
      offer_id: $offer_id
      offer_title: $offer_title
    ) {
      error
      message
    }
  }
`;
