import { RFQdetail } from "..";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { DecryptUrl } from "../../helpers";
export default function RFQdetailScreen() {
  const params = useParams();
  const navigate = useNavigate();

  const { state } = useLocation();
  const id = DecryptUrl(params.id);
  return (
    <RFQdetail
      params={params}
      id={id}
      navigate={navigate}
      alert={state && state.alert}
      selectedIndex={state && state?.selectedIndex}
      openModal={state && state?.openModal}
    />
  );
}
