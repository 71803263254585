import { gql } from "@apollo/client";

export default gql`
    mutation joinCompanyRequest($title:String!,$company_id:Int){
        joinCompanyRequest(title:$title,company_id:$company_id){
            error
            message
        }
    }
`;
