import React, { Component } from "react";
import { Switch } from "@headlessui/react";
import { connect } from "react-redux";

class SwitchToggleTeams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // enabled: null,
      company: {},
    };
  }
  componentDidMount() {
    if (this.props.company.is_primary) {
      this.setEnabled();
      this.addForm("company", this.props.company.id);
    }
  }
  setEnabled = () => {
    this.setState({
      enabled: this.props.enabled
    });

  }
  handleChange = (company) => {
    const { enableCompany, enabled } = this.props;
    if (company.id !== enabled?.id) {
      enableCompany(company);
    }
  };

  addForm = (key, value) => {
    let form = Object.assign({}, this.props.form);
    form[key] = value;
    this.props.addRfqForm(form);
  };

  render() {
    const { company, enabled, index } = this.props;
    return (
      <div className="flex items-center gap-x-2">
        <Switch
          checked={enabled !== null && enabled?.id === company.id ? true : false}
          onChange={() => this.handleChange(company)}
          className={`${(enabled !== null && enabled?.id === company.id ? true : false)
            ? "bg--primary"
            : "bg--gray"
            }
              relative inline-flex flex-shrink-0 h-8 w-[54px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
        >
          <span className="sr-only">Use setting</span>
          <span
            className={`${(enabled !== null && enabled.id === company.id ? true : false)
              ? "translate-x-7"
              : "translate-x-0"
              }
                pointer-events-none inline-block h-7 w-7 rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
          />
        </Switch>
      </div>
    );
  }
}
const mapStateToProps = ({ Rfq, Auth }) => {
  return {
    form: Rfq.form,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("store/redux/RFQRedux");
  return {
    ...ownProps,
    ...stateProps,
    addRfqForm: (data) => {
      dispatch(actions.addRfqForm(data));
    },
  };
};
export default connect(
  mapStateToProps,
  undefined,
  mergeProps
)(SwitchToggleTeams);
