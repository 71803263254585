import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { connect } from "react-redux";
import { Loader } from "components";
import GET_PLANS from "../../../graphql/queries/getPlans";
import client from "graphql/client";
import PaymentPlanNavigation from "./navigation";
import { useParams } from "react-router-dom";
import cookie from "js-cookie";
import SystemPlan from "./SystemPlan";
import CustomPlan from "./CustomPlan";
import Pagenotfound from "components/Pagenotfound";

const GET_FEATURES = gql`
  query ($user_type: Int, $plan_type: Int) {
    getFeatures(user_type: $user_type, plan_type: $plan_type) {
      plan_feature_id
      name
      tooltip_note
      feature_type
      plan_type
      price
      __typename
    }
  }
`;

function Pricing(props) {
  const [state, setState] = useState({
    windowSize: window.innerWidth,
    FeaturesData: [],
    PlansData: [],
    isProceed: false,
  });

  const params = useParams();
  const user_type = props.user.is_seller ? 2 : 1;
  const plan_type = (params?.name === "profile_systemplan" || params?.name === "profile_customplan") && props.user.is_seller ? 2 : 0;
  const feature_type = (params?.name === "profile_systemplan" || params?.name === "profile_customplan") && props.user.is_seller ? 2 : 0;

  useEffect(() => {
    const resizeWidth = () => {
      setState((prevState) => ({
        ...prevState,
        windowSize: window.innerWidth,
      }));
    };

    window.addEventListener("resize", resizeWidth);

    const fetchData = async () => {
      try {
        const featuresResponse = await client.query({
          query: GET_FEATURES,
          variables: { user_type, plan_type: feature_type },
        });

        const plansResponse = await client.query({
          query: GET_PLANS,
          variables: { user_type: user_type, plan_type: plan_type },
        });

        setState((prevState) => ({
          ...prevState,
          FeaturesData: featuresResponse.data.getFeatures,
          PlansData: plansResponse.data.getPlans,
        }));
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();

    return () => {
      window.removeEventListener("resize", resizeWidth);
    };
  }, [user_type, params.name]);

  useEffect(() => {
    let retrievedCustomPlan = cookie.get("CustomPlan");
    retrievedCustomPlan = retrievedCustomPlan ? JSON.parse(retrievedCustomPlan) : {};

    setState((prevState) => ({
      ...prevState,
      isProceed: retrievedCustomPlan !== null && Object.keys(retrievedCustomPlan).length > 0,
    }));
  }, [state.isProceed]);

  const { loading, error, data } = useQuery(GET_FEATURES, {
    variables: { user_type, plan_type },
  });

  if (loading) return <Loader spinner={true} />;
  if (error) return `Error! ${error.message}`;

  const renderPlanComponent = () => {
    if (params.name === "system" || params.name === "custom" || params.name === "profile_systemplan" || params.name === "profile_customplan") {
      return (
        <>
          <div className="relative mb-10">
            <h2 className="Medium text-4xl text-center font-bold">Payment Plan</h2>
          </div>
          <div className="relative mb-10 mt-10">
            <PaymentPlanNavigation type={params?.name} />
          </div>
        </>
      );
    } else {
      return (
        <div className="relative mb-10">
          <PaymentPlanNavigation type={params?.name} />
        </div>
      );
    }
  };

  const renderContent = () => {
    switch (params?.name) {
      case "system":
      case "profile_systemplan":
        return <SystemPlan FeaturesData={state.FeaturesData} PlansData={state.PlansData} />;
      case "custom":
      case "profile_customplan":
        return <CustomPlan FeaturesData={state.FeaturesData} PlansData={state.PlansData} for={params?.name.includes("profile") ? "ProfileCustomPlan" : "CustomPlan"} />;
      default:
        return <Pagenotfound />;
    }
  };

  return (

    <div className="container mt-10">
      {renderPlanComponent()}
      {renderContent()}
    </div>
  );
}

const mapStateToProps = ({ Auth }) => {
  return {
    user: Auth.authUserInformation,
  };
};

export default connect(mapStateToProps, null)(Pricing);
