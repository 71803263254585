import React, { Component } from "react";
import { Popover } from "@headlessui/react";
import "./styles.css";
import { Link } from "react-router-dom";
import { Avatarimage } from "components";
import offerPermissionMutation from "../../../../graphql/mutations/offerPermissionMutation";
import readAlert from "../../../../graphql/mutations/readAlertMutation";
import deleteAlert from "../../../../graphql/mutations/deleteAlertMutation";
import moment from "moment";
import client from "graphql/client";
import { IoTrashOutline } from "react-icons/io5";
import { IoCheckmarkDone } from "react-icons/io5";
import Buttonlink from "components/buttonlink";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { convertToSlug, encryptfunction } from "../../../../helpers";
import { connect } from "react-redux";
import AlertsAction from "../../../../store/actions/MyAlertsAction";
import GET_ALERTS from "../../../../graphql/queries/getAlerts";
import store from "../../../../store/index";
import { Icon, Loader } from "semantic-ui-react";
import PaginationCompact from "../../../PaginationCompact";
import AddCompanyExecutives from "../../../../graphql/mutations/addCompanyExecutives";
import { extractValidationErrors } from "helpers/extractValidationErrors ";
import getOffer from "graphql/queries/getOffer";

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allAlerts: false,
      deletedAlerts: [],
      showMessage: false,
      // reloadData: false,
      // newAlerts:{},
      loading: false,
      loadMore: false,
      count: 1,
      page: 1,
      total:
        this.props?.alertsPaginatorInfo?.total === undefined
          ? 0
          : parseInt(this.props?.alertsPaginatorInfo?.total),
      stopLoop: true,
      seeAll: false,
    };
  }

  // componentDidMount() {
  //   //this.getNewAlerts()
  //   // console.log('first', this.props?.alerts);
  // }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.notif !== this.props.notif) {
      AlertsAction.getAuthUserAlerts({ page: this.state.page });
    }
    // if (prevProps.alertsPaginatorInfo.total === undefined && (this.state.total == 0 || isNaN(this.state.total))) {
    //   this.setState({
    //     total: parseInt(this.props?.alertsPaginatorInfo?.total),
    //   });
    // }
    // if (prevProps.authUser !== this.props.authUser) {
    //   // debugger;
    //   AlertsAction.getAuthUserAlerts({ page: this.state.page });
    // }
    if (prevState.page !== this.state.page) {
      AlertsAction.getAuthUserAlerts({ page: this.state.page });
    }
    this.timer = setTimeout(() => {
      this.setState({ showMessage: true });
    }, 5000);
  }

  onLoadMore = () => {
    this.setState(
      {
        loading: true,
        loadMore: true,
        count: this.state.count + 1,
      },
      () => {
        // console.log("this.state.count", this.state.count);
        client
          .query({
            query: GET_ALERTS,
            variables: { me: true, page: this.state.page + this.state.count },
            fetchPolicy: "no-cache",
          })
          .then((result) => {
            store.dispatch({
              type: "GET_AUTH_USER_ALERTS",
              payload: result.data.alerts,
            });
            this.setState({
              loading: false,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    );
  };

  respondOfferPermission = (offer_id, type, user_id, id) => {
    this.setState({
      loading: true,
    });
    let variables = {};
    variables.offer_id = offer_id;
    variables.user_id = user_id;
    variables.is_pending = false;
    if (type === "Accept") {
      variables.is_permitted = true;
    } else if (type === "Reject") {
      variables.is_permitted = false;
    }

    client
      .mutate({
        mutation: offerPermissionMutation,
        variables: variables,

        optimisticResponse: {
          __typename: "Mutation",
          generalOfferPermission: {
            __typename: "GeneralPermission",
            is_pending: false,
            is_permitted: variables.is_permitted,
            offer_id: offer_id,
            user_id: user_id,
          },
        },
      })
      .then((res) => {
        AlertsAction.getAuthUserAlerts({ page: this.state.page });
        if (variables.is_permitted) {
          this.notify("You have accepted this offer!", "success");
        } else {
          this.notify("You have rejected this offer!", "success");
        }
        this.setState({
          loading: false,
        });
        // window.location.reload(false);

        // console.log("offer response success", res);
      })
      .catch((err) => {
        // this.notify("Error in responding this offer!", "error");
        extractValidationErrors(err).forEach((error) =>
          this.notify(error, "error")
        );
        console.log("offer response error", err);
      });
  };

  clickOnAlerts = (id) => {
    this.setState({
      loading: true,
    });
    client
      .mutate({
        mutation: readAlert,
        variables: {
          id,
        },
      })
      .then((response) => {
        if (response) {
          AlertsAction.getAuthUserAlerts({ page: 1 });
        }
        this.setState({
          loading: false,
        });
        // console.log("response", response);
      })
      .catch((err) => {
        // console.log("err", err);
      });
  };

  addCompanyExecutives = (alert_id, sender_id, message) => {
    this.setState({
      loading: true,
    });
    client
      .mutate({
        mutation: AddCompanyExecutives,
        variables: {
          alert_id: alert_id,
          request_by: sender_id,
          company_title: message,
        },
      })
      .then((response) => {
        this.clickOnAlerts(alert_id);
        if (response.data?.addCompanyExecutives?.error) {
          return this.notify(
            response.data?.addCompanyExecutives?.message,
            "error"
          );
          this.setState({
            loading: false,
          });
        }
        return this.notify(
          response.data?.addCompanyExecutives?.message,
          "success"
        );
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  alertDelete = (id) => {
    this.setState({
      loading: true,
    });
    this.props.deleteAlerts(id);
    this.state.deletedAlerts.push(id);
    this.setState({ total: this.state.total - 1 });
    client
      .mutate({
        mutation: deleteAlert,
        variables: {
          id,
        },
      })
      .then((response) => {
        // console.log("delete",response)
        // if (response) {
        //   this.setState({ reloadData: true });
        // }
        // console.log("response",response)
        this.setState({
          loading: false,
        });
      })
      .catch((err) => {
        // console.log("err",err)
      });
  };
  formatDateTime(date) {
    const hoursDifference = new Date().getTimezoneOffset() / 60;
    const inputDate = moment(date, "YYYY-MM-DD HH:mm:ss").subtract(
      hoursDifference,
      "hours"
    );
    const today = moment();
    const diffHours = today.diff(inputDate, "hours");

    if (diffHours < 1) {
      // Less than 1 hour ago
      const diffMinutes = today.diff(inputDate, "minutes");
      const diffSeconds = today.diff(inputDate, "seconds");
      if (diffMinutes < 1 && diffSeconds > 0) {
        return `${diffSeconds} second${diffSeconds !== 1 ? "s" : ""} ago`;
      } else {
        return `${diffMinutes} minute${diffMinutes !== 1 ? "s" : ""} ago`;
      }
    } else if (diffHours < 24) {
      // Today: X hours ago
      return `${diffHours} hour${diffHours !== 1 ? "s" : ""} ago`;
    } else if (diffHours < 48) {
      // Yesterday: Yesterday at HH:mm:ss
      return `Yesterday at ${inputDate.format("HH:mm:ss")}`;
    } else if (inputDate.year() !== today.year()) {
      // Previous year: D MMM YYYY at HH:mm:ss
      return inputDate.format("D MMM YYYY [at] HH:mm:ss");
    } else {
      // Current year, more than 2 days ago: D MMM at HH:mm:ss
      return inputDate.format("D MMM [at] HH:mm:ss");
    }
  }

  // formatDateTime(date) {
  //   console.log(
  //     "(Intl.DateTimeFormat().resolvedOptions().timeZone)",
  //     Intl.DateTimeFormat().resolvedOptions().timeZone,
  //     new Date().getTimezoneOffset()
  //   );
  //   const hoursDiffernce = new Date().getTimezoneOffset() / 60;
  //   console.log("2222222222", hoursDiffernce);
  //   const inputDate = moment(date, "YYYY-MM-DD HH:mm:ss");
  //   inputDate.subtract(hoursDiffernce, "hours"); // Add 5 hours to the input date
  //   console.log("111111111", date);
  //   console.log("first", inputDate);
  //   const today = moment();
  //   const diffHours = today.diff(inputDate, "hours");

  //   if (diffHours < 1) {
  //     // Less than 1 hour ago
  //     const diffMinutes = today.diff(inputDate, "minutes");
  //     const diffSeconds = today.diff(inputDate, "seconds");
  //     if (diffMinutes < 1) {
  //       return `${diffSeconds} second${diffSeconds > 1 ? "s" : ""} ago`;
  //     } else {
  //       return `${diffMinutes} minute${diffMinutes > 1 ? "s" : ""} ago`;
  //     }
  //   } else if (diffHours < 24) {
  //     // Today: X hours ago
  //     return `${diffHours} hour${diffHours > 1 ? "s" : ""} ago`;
  //   } else if (diffHours < 48) {
  //     // Yesterday: Yesterday at HH:mm:ss
  //     return `Yesterday at ${inputDate.format("HH:mm:ss")}`;
  //   } else if (inputDate.year() !== today.year()) {
  //     // Previous year: D MMM YYYY at HH:mm:ss
  //     return inputDate.format("D MMM YYYY [at] HH:mm:ss");
  //   } else {
  //     // Current year, more than 2 days ago: D MMM at HH:mm:ss
  //     return inputDate.format("D MMM [at] HH:mm:ss");
  //   }
  // }

  renderSingleAlertComponent = () => {
    let items = this.props.alerts.filter((alert) => alert.read_at == null);
    if (window.location.href.includes("/notifications") && this.state.seeAll) {
      return (
        <div class="text-center">
          <p class="mx-auto">See From Page</p>
        </div>
      );
    }
    return (
      <div className="-mx-3 space-y-1">
        {items.length > 0 ? (
          items
            // .filter((o) => !this.state.deletedAlerts.includes(+o.id))
            .map((alert) => {
              const formattedDateTime = this.formatDateTime(alert.created_at);
              return (
                <div
                  key={alert?.id}
                  className={`${alert.read_at === null ? "bg-gray-50" : null
                    } flex items-center hover:bg-gray-100 rounded-lg py-2 px-3 notifi_items relative`}
                >
                  {alert?.alert_model_type === "COMPANY" ? (
                    <>
                      <Avatarimage
                        imagesrc={process.env.REACT_APP_MAIN_URL + `/${alert.sender?.profile_photo}`}
                        firstname={alert.sender.firstname}
                        iscompany="true"
                        className="h-16 w-16 rounded-full object-cover mr-2"
                        imagesize={{ width: 52, height: 52 }}
                        userId={alert.sender.id}
                      />
                    </>
                  ) : (
                    <Avatarimage
                      imagesrc={alert.sender?.profile_photo}
                      firstname={alert.sender.firstname}
                      imagesize={{ height: 52, width: 52 }}
                      className="h-16 w-16 rounded-full object-cover mr-2"
                      alert_model_type={alert?.alert_model_type}
                      userId={alert.sender.id}
                    />
                  )}
                  {alert?.offer?.id ? (
                    <div className="relative flex-grow">
                      <Link
                        to={`/rfq/${encryptfunction(
                          alert.offer.id
                        )}/${convertToSlug(alert.offer.title)}`}
                        state={{ alert: alert }}
                        onClick={() => this.clickOnAlerts(alert.id)}
                      >
                        <div className="flex flex-wrap items-center">
                          <strong className="Medium black text-base mr-1">
                            {`${alert.sender.firstname} ${alert.sender.lastname}`}
                          </strong>
                          <p className="darkGray fs-12 Light ml-auto">
                            {formattedDateTime}
                          </p>
                        </div>
                      </Link>
                      <div className="flex items-center justify-between">
                        <Link
                          to={`/rfq/${encryptfunction(
                            alert.offer.id
                          )}/${convertToSlug(alert.offer.title)}`}
                          state={{ alert: alert }}
                          className="grow"
                          onClick={() => this.clickOnAlerts(alert.id)}
                        >
                          <p className="fs-11 gray">{`${alert.message}`}</p>
                        </Link>
                        {!alert.is_pending && alert.read_at !== null && (
                          <>
                            {/*<IoTrashOutline*/}
                            {/*  className="danger cursor-pointer"*/}
                            {/*  size={18}*/}
                            {/*  onClick={() => this.alertDelete(alert.id)}*/}
                            {/*/>*/}
                            <IoCheckmarkDone
                              size={20}
                              className="text-sky-500 ml-2 mt-1"
                            />
                          </>
                        )}
                      </div>
                      {alert.is_pending === true ? (
                        <>
                          <div className="mt-1 z-20 relative gap-x-2 flex">
                            <Link
                              to={`/rfq/${encryptfunction(
                                alert.offer.id
                              )}/${convertToSlug(alert.offer.title)}`}
                              state={{ alert: alert }}
                              className="absolute top-0 right-0 left-0 bottom-0 z-10"
                            />
                            <Buttonlink
                              onClick={() =>
                                this.respondOfferPermission(
                                  alert.offer.id,
                                  "Reject",
                                  alert.sender.id,
                                  alert.id
                                )
                              }
                              text="Reject"
                              className="px-6 py-1 fs-11 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--lightGray darkGray z-20 relative"
                            />
                            <Buttonlink
                              onClick={() =>
                                this.respondOfferPermission(
                                  alert.offer.id,
                                  "Accept",
                                  alert.sender.id,
                                  alert.id
                                )
                              }
                              text="Accept"
                              className="px-6 py-1 fs-11 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white z-20 relative"
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                  ) : (
                    <div className="relative flex-grow">
                      <div className="flex items-center justify-between">
                        <strong className="Medium black text-base mr-1">
                          {`${alert.sender.firstname} ${alert.sender.lastname}`}
                        </strong>
                        <p className="darkGray fs-12 Light">
                          {formattedDateTime}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <p className="fs-11 gray">{`${alert.message}`}</p>
                        {!alert.is_pending && alert.read_at !== null && (
                          <div className="flex">
                            <IoTrashOutline
                              className="danger cursor-pointer"
                              size={18}
                              onClick={() =>
                                this.alertDelete(alert && alert.id)
                              }
                            />
                            <IoCheckmarkDone
                              size={20}
                              className="text-sky-500 ml-2 mt-1"
                            />
                          </div>
                        )}
                      </div>
                      {alert?.is_pending && (
                        <div className="text-center z-20 relative">
                          <Buttonlink
                            onClick={() => this.alertDelete(alert && alert.id)}
                            text="Reject"
                            className="px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--lightGray darkGray z-20 relative mr-5"
                          />
                          <Buttonlink
                            onClick={() =>
                              this.addCompanyExecutives(
                                alert.id,
                                alert.sender.id,
                                alert.message
                              )
                            }
                            text="Accept"
                            className="px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white z-20 relative mr-5"
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })
        ) : (
          <div className="flex items-center justify-center h-25">
            <p className="items-center lg:text-2xl text-3xl Medium gray">
              No New Message
            </p>
          </div>
        )}
      </div>
    );
  };

  renderAllAlertComponent = () => {
    return (
      <>
        {this.props.alerts
          .filter((o) => !this.state.deletedAlerts.includes(+o.id))
          .map((alert) => {
            const formattedDateTime = this.formatDateTime(alert.created_at);
            return (
              <div className="-mx-3" key={alert && alert.id}>
                <div
                  className={`${alert.read_at === null ? "bg-gray-50" : null
                    } flex items-center hover:bg-gray-100 rounded-lg py-2 px-3 notifi_items relative`}
                >
                  {alert?.alert_model_type === "COMPANY" ? (
                    <>
                      <Avatarimage
                        imagesrc={process.env.REACT_APP_MAIN_URL + `/${alert.sender?.profile_photo}`}
                        firstname={alert.sender.firstname}
                        iscompany="true"
                        className="h-16 w-16 rounded-full object-cover mr-2"
                        imagesize={{ width: 52, height: 52 }}
                        userid={alert.sender.id}
                      />
                    </>
                  ) : (
                    <Avatarimage
                      imagesrc={alert.sender?.profile_photo}
                      firstname={alert.sender.firstname}
                      imagesize={{ height: 52, width: 52 }}
                      className="h-16 w-16 rounded-full object-cover mr-2"
                      userid={alert.sender.id}
                    />
                  )}
                  {alert?.offer?.id ? (
                    <div className="relative flex-grow">
                      <Link
                        to={`/rfq/${encryptfunction(
                          alert.offer.id
                        )}/${convertToSlug(alert.offer.title)}`}
                        state={{
                          alert: alert,
                          openModal:
                            alert.message === "Send you new message"
                              ? true
                              : false,
                        }}
                        onClick={() => this.clickOnAlerts(alert && alert.id)}
                      >
                        <div className="flex items-center justify-between">
                          <strong className="Medium black text-base mr-1">
                            {`${alert.sender.firstname} ${alert.sender.lastname}`}
                          </strong>
                          <p className="darkGray fs-12 Light text-right">
                            {formattedDateTime}
                          </p>
                        </div>
                      </Link>
                      <div className="flex items-center justify-between">
                        <Link
                          to={`/rfq/${encryptfunction(
                            alert.offer.id
                          )}/${convertToSlug(alert.offer.title)}`}
                          state={{ alert: alert }}
                          className="grow"
                          onClick={() => this.clickOnAlerts(alert && alert.id)}
                        >
                          <p className="fs-11 gray">{`${alert.message}`}</p>
                        </Link>
                        {!alert.offer.isPending && alert.read_at !== null && (
                          <>
                            <IoTrashOutline
                              className="danger cursor-pointer"
                              size={18}
                              onClick={() =>
                                this.alertDelete(alert && alert.id)
                              }
                            />
                            <IoCheckmarkDone
                              size={20}
                              className="text-sky-500 ml-2 mt-1"
                            />
                          </>
                        )}
                      </div>
                      {alert.is_pending && (
                        <>
                          <div className="text-center z-20 relative">
                            <Link
                              to={`/rfq/${encryptfunction(
                                alert.offer.id
                              )}/${convertToSlug(alert.offer.title)}`}
                              state={{ alert: alert, loading: false }}
                              className="absolute top-0 right-0 left-0 bottom-0 z-10"
                            />
                            <Buttonlink
                              onClick={() =>
                                this.respondOfferPermission(
                                  alert.offer.id,
                                  "Reject",
                                  alert.sender.id,
                                  alert && alert.id
                                )
                              }
                              text="Reject"
                              className="px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--lightGray darkGray z-20 relative mr-5"
                            />
                            <Buttonlink
                              onClick={() =>
                                this.respondOfferPermission(
                                  alert.offer.id,
                                  "Accept",
                                  alert.sender.id,
                                  alert.id
                                )
                              }
                              text="Accept"
                              className="px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white z-20 relative mr-5"
                            />
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="relative flex-grow">
                      <div className="flex items-center justify-between">
                        <strong className="Medium black text-base mr-1">
                          {`${alert.sender.firstname} ${alert.sender.lastname}`}
                        </strong>
                        <p className="darkGray fs-12 Light">
                          {formattedDateTime}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        <p className="fs-11 gray">{`${alert.message}`}</p>
                        {!alert.is_pending && alert.read_at !== null && (
                          <div className="flex">
                            <IoTrashOutline
                              className="danger cursor-pointer"
                              size={18}
                              onClick={() =>
                                this.alertDelete(alert && alert.id)
                              }
                            />
                            <IoCheckmarkDone
                              size={20}
                              className="text-sky-500 ml-2 mt-1"
                            />
                          </div>
                        )}
                      </div>
                      {alert?.is_pending && (
                        <div className="text-center z-20 relative">
                          <Buttonlink
                            onClick={() => this.alertDelete(alert && alert.id)}
                            text="Reject"
                            className="px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--lightGray darkGray z-20 relative mr-5"
                          />
                          <Buttonlink
                            onClick={() =>
                              this.addCompanyExecutives(
                                alert.id,
                                alert.sender.id,
                                alert.message
                              )
                            }
                            text="Accept"
                            className="px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white z-20 relative mr-5"
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        <div className="flex justify-center">
          <PaginationCompact
            payload={this.props.alertsPaginatorInfo}
            callback={(value) => this.setState({ page: value })}
          />
        </div>
      </>
    );
  };

  notify = (message = "", type = "error") => {
    // Dismiss any existing toasts before showing a new one
    toast.dismiss();
    if (type === "error") {
      return toast.error(message, { position: "top-right" });
    }
    toast.success(message, { position: "top-right" });
  };

  getNewAlerts = () => {
    this.setState({ loading: true, page: 1 });
    AlertsAction.getAuthUserAlerts({ page: this.state.page }).then((res) => {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000);
    });
    // this.setState({newAlerts:this.props.alerts});
  };
  yourClickHandlerFunction = () => {
    if (!this.state.seeAll) {
      this.setState({ seeAll: true });
    }
  };

  render() {
    if (isNaN(this.state.total)) {
      this.setState({ total: this.props?.alertsPaginatorInfo.total });
    }
    const { loading, showMessage } = this.state;
    return (
      <div className="Notification space-y-1">
        <div className="flex items-center justify-between mb-4">
          <p className={`${this.props.size} Medium`}>Notification</p>
          <div className="flex items-center">
            {this.props?.alertsPaginatorInfo &&
              this.props?.alertsPaginatorInfo.total > 0 &&
              !this.props.showAllAlerts ? (
              // <Popover.Button>
              <Link
                to="/notifications"
                className="primary text-sm hover:bg-gray-100 p-1 rounded-lg"
                onClick={this.yourClickHandlerFunction()}
              >
                See All
              </Link>
            ) : (
              // </Popover.Button>
              <>
                {this.props?.alertsPaginatorInfo.total > 0 && (
                  <Buttonlink
                    className={
                      "primary text-sm hover:bg-gray-100 p-1 rounded-lg"
                    }
                    suffix={
                      this.state.loading && <Icon loading name="spinner" />
                    }
                    onClick={() => {
                      this.getNewAlerts();
                    }}
                    text={`Total : ${this.state.total === undefined ? 0 : this.state.total
                      }, ${this.state.loading ? "" : "Refresh"}`}
                  />
                )}
              </>
            )}
          </div>
        </div>

        <Popover>
          <Popover.Button as="div" className={"space-y-2"}>
            {loading ? (
              <Loader />
            ) : this.props.alertsPaginatorInfo.total > 0 ? (
              this.props.showAllAlerts ? (
                this.renderAllAlertComponent()
              ) : (
                this.renderSingleAlertComponent()
              )
            ) : showMessage ? (
              <div className="flex items-center justify-center h-25">
                <p className="items-center lg:text-2xl text-3xl Medium gray">
                  Your Inbox Is Empty.
                </p>
              </div>
            ) : (
              <Loader />
            )}
          </Popover.Button>
        </Popover>
      </div>
    );
  }
}

const mapStateToProps = ({ Alerts, Auth }) => {
  return {
    authUser: Auth.socket,
    alerts: Alerts.alerts,
    alertsPaginatorInfo: Alerts.paginatorInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteAlerts: (id) => dispatch({ type: "DELETE_ALERT", payload: id }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
