import { gql } from "@apollo/client";

export default gql`
    mutation CreateNewUser(
        $username:String!,
        $firstname:String!
        $lastname:String!
        $phone_number:String
        $password:String
        $email:String!
        $is_seller:String
    ){
        createUser(
            username:$username,
            firstname:$firstname,
            lastname:$lastname,
            phone_number:$phone_number,
            password:$password,
            email:$email,
            is_seller:$is_seller
        ){
            id
            username
            firstname
            lastname
            phone_number
            profile_photo
            tagline
            email
            timestamp
            is_seller
            isSellerProfileCompleted
        }
    }
`;
