import React, { useEffect, useState } from "react";
import {
  Subbanner,
  Buttonlink,
  Loader,
  Nocontent,
  Noconnection,
} from "components";
import direction from "../../../assets/images/direction.png";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import { HiOutlineSearch } from "react-icons/hi";
import { MdScreenSearchDesktop } from "react-icons/md";
import { Query } from "@apollo/client/react/components";
import GET_FAQS from "../../../graphql/queries/getSearchQuickFaqs";
import GET_SUPPLIER from "../../../graphql/queries/getQuickSearchSuppliers";
import GET_RFQS from "../../../graphql/queries/getQuickSearchRfqs";
import Faqs from "./faqs";
import { convertToSlug, encryptfunction } from "../../../helpers";
import Profileavatar from "../../../components/profileavatar";
import { GoLocation } from "react-icons/go";

export default function HelpSearch(props) {
  const [tabname, settabname] = useState("");
  const [search, setsearch] = useState("");

  useEffect(() => {
    if (props.location && props.location.tabname) {
      settabname(props.location.tabname);
    }
    if (props.location && props.location.search) {
      setsearch(props.location.search);
    }
  }, []);

  const handleIncludes = (value) => {
    settabname(value);
  };

  const reset = () => {
    setsearch("");
    settabname("");
  };

  return (
    <div>
      <Subbanner title="Quick Help Details" />
      <div className="container mt-[-100px] relative">
        <div className="grid xl:grid-cols-4 lg:grid-cols-1 gap-4">
          <div className="hidden xl:block">
            <div className="h-12 mt-2" />
            <div className="overflow-hidden intro-y">
              <div className="px-5 py-20 space-y-5 ">
                <div className="flex justify-center animate-bounce">
                  <MdScreenSearchDesktop size={200} className="primary" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2 rounded--xl overflow-hidden intro-y">
            <div className="relative mb-5">
              <Buttonlink
                text="Back"
                prefix={<IoIosArrowBack size={16} />}
                onClick={() => props.navigate(-1)}
                className="bg-gray-300 hover:opacity-80 fs-13 flex items-center justify-center gap-x-1 h-9 rounded-full darkGray w-24 border-0 shadow-none"
              />
            </div>
            <div className="space-y-5">
              <div className="box overflow-hidden mx-1 intro-y">
                <div className="p-5 space-y-5">
                  <h4 className="text-center text-2xl black">Search</h4>
                  <div>
                    <div className="bg--lightGray rounded-xl flex items-center h-11 px-3">
                      <HiOutlineSearch className="darkGray" />
                      <input
                        placeholder="Search"
                        className="bg-transparent h-full w-full pl-3"
                        value={search}
                        onChange={(e) => setsearch(e.target.value)}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="selected-tags flex items-center justify-center mt-6 mx-auto rounded-lg overflow-hidden">
                      <div className="relative flex-grow">
                        <input
                          type="radio"
                          className="z-0 absolute left-0 invisible"
                          id="1"
                          name={"filter"}
                          checked={tabname === "category"}
                          value={"category"}
                          onChange={(e) => handleIncludes(e.target.value)}
                        />
                        <label
                          htmlFor="1"
                          className="bg-gray-200 w-full hover:opacity-70 text-center py-2 cursor-pointer inline-block"
                        >
                          <p className="fs-13 cursor-pointer">
                            Category & Tags
                          </p>
                        </label>
                      </div>
                      <div className="relative flex-grow">
                        <input
                          type="radio"
                          className="z-0 absolute left-0 invisible"
                          id="2"
                          name={"filter"}
                          checked={tabname === "rfq"}
                          value={"rfq"}
                          onChange={(e) => handleIncludes(e.target.value)}
                        />
                        <label
                          htmlFor="2"
                          className="bg-gray-200 w-full hover:opacity-70 text-center py-2 cursor-pointer inline-block"
                        >
                          <p className="fs-13 cursor-pointer">Rfqs</p>
                        </label>
                      </div>
                      <div className="relative flex-grow">
                        <input
                          type="radio"
                          className="z-0 absolute left-0 invisible"
                          name={"filter"}
                          id="3"
                          checked={tabname === "user"}
                          value={"user"}
                          onChange={(e) => handleIncludes(e.target.value)}
                        />
                        <label
                          htmlFor="3"
                          className="bg-gray-200 w-full hover:opacity-70 text-center py-2 cursor-pointer inline-block"
                        >
                          <p className="fs-13 cursor-pointer">Supplier</p>
                        </label>
                      </div>
                      <div className="relative flex-grow">
                        <input
                          type="radio"
                          className="z-0 absolute left-0 invisible"
                          name={"filter"}
                          checked={tabname === "faq"}
                          id="4"
                          value={"faq"}
                          onChange={(e) => handleIncludes(e.target.value)}
                        />
                        <label
                          htmlFor="4"
                          className="bg-gray-200 w-full hover:opacity-70 text-center py-2 cursor-pointer inline-block"
                        >
                          <p className="fs-13 cursor-pointer">Faq's</p>
                        </label>
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="fs-10 gray">
                        *select & search in specific options
                      </p>
                      <p
                        className="primary fs-12 cursor-pointer flex justify-end"
                        onClick={reset}
                      >
                        clear all
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {search === "" ? (
                <Nocontent text="its quite empty out here, lets crowd it with RFQs" />
              ) : tabname && tabname === "category" ? (
                <Query
                  key={1}
                  query={GET_RFQS}
                  variables={{
                    is_rfqs: false,
                    is_tags: true,
                    key: search,
                    first: 5,
                    page: 1,
                  }}
                >
                  {({ loading, error, data }) => {
                    if (loading) return <Loader />;
                    if (error) return <Noconnection />;
                    if (data.quick_search_rfqs.data.length <= 0)
                      return <Nocontent />;
                    if (data) {
                      // console.log("data", data);
                      return (
                        <>
                          <div className="box overflow-hidden mx-1 intro-y bg--lightPrimary">
                            <div className="p-5">
                              <h4 className="text-center text-2xl black">
                                Categories & Tags Results
                              </h4>
                            </div>
                          </div>
                          {data.quick_search_rfqs.data.map((rfq) => {
                            return (
                              <div
                                className="box overflow-hidden mx-1 intro-y"
                                key={rfq.id}
                              >
                                <div className="p-5 space-y-5">
                                  <div>
                                    <Link
                                      to={`/rfq/${encryptfunction(
                                        rfq.id
                                      )}/${convertToSlug(rfq.title)}`}
                                      className="Medium fs-16 block  intro-y"
                                    >
                                      {rfq.title}
                                    </Link>
                                  </div>
                                  <p className="text-gray-500 fs-14">
                                    Categories:
                                  </p>
                                  <div className="flex items-center flex-wrap gap-2 ">
                                    {rfq.categories.map((category) => {
                                      return (
                                        <div
                                          key={category.id}
                                          className="bg--lightPrimary hover:opacity-70 rounded-lg px-5 py-1.5 inline-block intro-x"
                                        >
                                          <p className="fs-13">
                                            {category.name}{" "}
                                          </p>
                                        </div>
                                      );
                                    })}
                                  </div>
                                  <p className="text-gray-500 fs-14">Tags:</p>
                                  <div className="flex items-center flex-wrap gap-2 ">
                                    {rfq.tags.map((tag) => {
                                      return (
                                        <div
                                          className="bg-gray-100 hover:opacity-70 rounded-lg px-5 py-1.5 inline-block intro-x"
                                          key={tag.id}
                                        >
                                          <p className="fs-13"> {tag.name} </p>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                          {/*<div className="text-center">*/}
                          {/*    <LoadMoreData title="Load More"/>*/}
                          {/*</div>*/}
                        </>
                      );
                    }
                  }}
                </Query>
              ) : tabname === "rfq" ? (
                <>
                  <Query
                    key={2}
                    query={GET_RFQS}
                    variables={{
                      is_tags: false,
                      is_rfqs: true,
                      key: search,
                      page: 1,
                      first: 10,
                    }}
                  >
                    {({ loading, error, data }) => {
                      if (loading) return <Loader />;
                      if (error) return <Noconnection />;
                      if (data.quick_search_rfqs.data.length <= 0)
                        return <Nocontent />;
                      if (data) {
                        return (
                          <>
                            <div className="box overflow-hidden mx-1 intro-y bg--lightPrimary">
                              <div className="p-5">
                                <h4 className="text-center text-2xl black">
                                  RFQ's Result
                                </h4>
                              </div>
                            </div>
                            {data.quick_search_rfqs.data.map((rfq) => {
                              return (
                                <div
                                  className="box overflow-hidden mx-1 intro-y"
                                  key={rfq.id}
                                >
                                  <div className="p-5 space-y-5">
                                    <div>
                                      <Link
                                        to={`/rfq/${encryptfunction(
                                          rfq.id
                                        )}/${convertToSlug(rfq.title)}`}
                                        className="Medium fs-16 block  intro-y"
                                      >
                                        {rfq.title}
                                      </Link>
                                    </div>
                                    {/*<p className="text-gray-500 fs-14">
                                      Categories:
                                    </p>
                                    <div className="flex items-center flex-wrap gap-2 ">
                                      {rfq.categories.map(category => {
                                        return (
                                          <div
                                            key={category.id}
                                            className="bg--lightPrimary hover:opacity-70 rounded-lg px-5 py-1.5 inline-block intro-x"
                                          >
                                            <p className="fs-13">
                                              {category.name}{" "}
                                            </p>
                                          </div>
                                        );
                                      })}
                                    </div>
                                    <p className="text-gray-500 fs-14">Tags:</p>
                                    <div className="flex items-center flex-wrap gap-2 ">
                                      {rfq.tags.map(tag => {
                                        return (
                                          <div
                                            className="bg-gray-100 hover:opacity-70 rounded-lg px-5 py-1.5 inline-block intro-x"
                                            key={tag.id}
                                          >
                                            <p className="fs-13">
                                              {" "}
                                              {tag.name}{" "}
                                            </p>
                                          </div>
                                        );
                                      })}
                                    </div>*/}
                                  </div>
                                </div>
                              );
                            })}
                            {/* <div className="text-center">
                                                            <LoadMoreData title="Load More"/>
                                                        </div>*/}
                          </>
                        );
                      }
                    }}
                  </Query>
                </>
              ) : tabname === "user" ? (
                <Query
                  key={3}
                  query={GET_SUPPLIER}
                  variables={{ key: search, page: 1, first: 10 }}
                >
                  {({ loading, error, data }) => {
                    if (loading) return <Loader />;
                    if (error) return <Noconnection />;
                    if (data.quick_search_suppliers.data.length <= 0)
                      return <Nocontent />;
                    if (data) {
                      // console.log("data", data);
                      return (
                        <>
                          <div className="box overflow-hidden mx-1 intro-y bg--lightPrimary">
                            <div className="p-5">
                              <h4 className="text-center text-2xl black">
                                Supplier Results
                              </h4>
                            </div>
                          </div>
                          {data.quick_search_suppliers.data.map((user) => {
                            return (
                              <div
                                className="box overflow-hidden mx-1 intro-y"
                                key={user.id}
                              >
                                <div className="p-5 space-y-5">
                                  <div className="flex items-centet gap-x-4">
                                    <Profileavatar
                                      singleUser={user && user}
                                      sizeStyle={{ width: 52, height: 52 }}
                                    />
                                    <div>
                                      <Link
                                        to={`/${encryptfunction(
                                          user.id
                                        )}/profile`}
                                        state={{
                                          value: "edit",
                                          id: user.id,
                                        }}
                                        className="text-lg Medium"
                                      >
                                        {user && user.firstname
                                          ? user.firstname
                                          : "Not Provided"}{" "}
                                        {user && user.lastname
                                          ? user.lastname
                                          : "Not Provided"}
                                      </Link>
                                      <div className="truncate sm:whitespace-normal Regular text-sm text-gray-500">
                                        @{user && user.username}
                                      </div>
                                      <div className="flex items-center gap-x-1">
                                        <p className="truncate sm:whitespace-normal Regular text-sm text-gray-400">
                                          Role:
                                        </p>
                                        <p className="truncate sm:whitespace-normal Regular text-sm black">
                                          Seller
                                        </p>
                                      </div>
                                      {user &&
                                        user.state !== null &&
                                        user.country !== null ? (
                                        <div className="flex items-center gap-x-1">
                                          <GoLocation
                                            className="darkGray"
                                            size={14}
                                          />
                                          <p className="darkGray fs-11">
                                            {user.state.name},
                                            {user.country.name}
                                          </p>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                          {/*    <div className="text-center">
                                                        <LoadMoreData title="Load More"/>
                                                    </div>*/}
                        </>
                      );
                    }
                  }}
                </Query>
              ) : tabname === "faq" ? (
                <Query
                  key={4}
                  query={GET_FAQS}
                  variables={{ key: search, page: 1, first: 5 }}
                >
                  {({ loading, error, data }) => {
                    if (loading) return <Loader />;
                    if (error) return <Noconnection />;
                    if (data.quick_search_faqs.data.length <= 0)
                      return <Nocontent />;
                    if (data) {
                      // console.log("data", data);
                      return (
                        <>
                          <div className="box overflow-hidden mx-1 intro-y bg--lightPrimary">
                            <div className="p-5">
                              <h4 className="text-center text-2xl black">
                                FAQ's
                              </h4>
                            </div>
                          </div>
                          <div>
                            <div className="space-y-5">
                              <Faqs
                                data={data && data.quick_search_faqs.data}
                              />
                            </div>
                          </div>
                        </>
                      );
                    }
                  }}
                </Query>
              ) : null}
            </div>
          </div>

          <div className="hidden xl:block">
            <div className="h-12 mt-2" />
            <div className="box overflow-hidden intro-y">
              <div className="px-5 py-10 space-y-5">
                <div className="flex justify-center">
                  <img alt="direction" src={direction} className="w-28 h-28" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
