import React, { useState, useEffect } from "react";
import { Buttonlink, Loader, ProfileAvatar, Subbanner } from "components";
import logo from "../../../assets/images/banner.jpg";
import { FcLock } from "react-icons/fc";
import { IoIosArrowBack } from "react-icons/io";
import GET_VIEWS from "../../../graphql/queries/offerViewsAll";
import client from "graphql/client";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { encryptfunction } from "helpers";
import moment from "moment";
import { checkPlan } from "helpers/checkPlan";

const SeeAllViews = ({ state, authUser, navigate }) => {
  const [offerview, setOfferview] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    client
      .query({
        query: GET_VIEWS,
        variables: {
          offer_id: state.id,
        },
      })
      .then((res) => {
        setOfferview(res.data.offerViews);
        setLoader(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [state.id]);
  const setTime = (timestamp) => {
    const createdAt = moment(timestamp);
    const daysAgo = moment().diff(createdAt, "days");
    if (daysAgo === 0) {
      return "today";
    } else {
      return `${daysAgo} ${daysAgo === 1 ? "day" : "days"} ago`;
    }
  };

  const seeRFQViews = checkPlan("bool", "No. of views of sellers on a RFQ", authUser?.payments);
  // let seeRFQViews = false;
  // if (authUser?.payments) {
  //   authUser?.payments?.forEach((plan) => {
  //     if (plan.plan.plan_features) {
  //       plan.plan.plan_features.forEach((plan_feature) => {
  //         if (plan_feature.name === "No. of views of sellers on a RFQ") {
  //           seeRFQViews = false;
  //         }
  //       });
  //     }
  //   });
  // }
  return (
    <div>
      <Subbanner title="Rfq Views" />
      <div className="container -mt-28">
        <div className="grid grid-cols-12 gap-4">
          <div className="lg:col-start-3 lg:col-span-8">
            <div className="mb-5">
              <Buttonlink
                text="Back"
                prefix={<IoIosArrowBack size={16} />}
                onClick={() => navigate(-1)}
                className="relative bg-gray-200 hover:bg-gray-300 fs-13 flex items-center justify-center gap-x-1 h-9 rounded-full darkGray w-24 hover:opacity-80 border-0 shadow-none"
              />
            </div>
            {loader ? <Loader /> :
              <div className="box overflow-hidden pt-6 intro-y">
                <div className="px-6">
                  <h6 className="Medium fs-20 black mb-2">
                    Who's viewed your profile
                  </h6>
                  <p className="Regular fs-14 darkGray">
                    People who viewed your profile in the past 90 days
                  </p>
                </div>
                <div className="h-px w-full my-6 bg--lightGray" />
                <div className="px-6">
                  <h6 className="Medium fs-20 black mb-2">{offerview.length}</h6>
                  <p className="Regular fs-14 darkGray">Rfq viewers</p>
                </div>
                <div className="h-px w-full my-6 bg--lightGray" />
                {!seeRFQViews ? (
                  <div className="flex items-center px-6 pb-2">
                    <div className="w-11 h-11">
                      <FcLock size={40} />
                    </div>
                    <div className="ml-3">
                      <h4 className="mb-2 fs-16 Medium black">
                        Unlock the rest of the list with Premium
                      </h4>
                      <div class="flex -space-x-1 overflow-hidden">
                        <img
                          class="inline-block h-8 w-8 rounded-full ring-2 ring-white"
                          src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                          alt=""
                        />
                        <img
                          class="inline-block h-8 w-8 rounded-full ring-2 ring-white"
                          src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                          alt=""
                        />
                        <img
                          class="inline-block h-8 w-8 rounded-full ring-2 ring-white"
                          src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                          alt=""
                        />
                      </div>
                    </div>
                    <Buttonlink
                      to="/pricing/system"
                      text="Switch Premium"
                      title="Premium"
                      rel="nofollow"
                      className="relative px-6 rounded-full ml-auto hover:white hover:opacity-80 flex justify-center py-1 border border-transparent rounded-lg primary bg--lightPrimary  hover:bg--primary focus:outline-none whitespace-nowrap"
                    />
                  </div>
                ) : null}
                <div className="h-px w-full my-6 bg--lightGray" />
                <div className="pb-9">
                  {!seeRFQViews
                    ? offerview.slice(0, 0).map((view) => {
                      return (
                        <>
                          <Link
                            to={`/${encryptfunction(view.user.id)}/profile`}
                            state={{
                              value: "edit",
                              id: view.user.id,
                            }}
                          >
                            <div className="flex items-center mb-5 px-6">
                              <div className="w-11 h-11 overflow-hidden rounded-full">
                                <ProfileAvatar
                                  singleUser={view.user}
                                  sizeStyle={{ width: 42, height: 42 }}
                                  className="shrink-0 rounded-full"
                                />
                              </div>
                              <div className="ml-3">
                                <h4 className="mb-1 fs-16 Medium black">
                                  {view.user.firstname} {view.user.lastname}
                                </h4>
                                <p className="darkGray Regular fs-13">
                                  Viewed your RFQ {setTime(view.created_at)}
                                </p>
                              </div>
                            </div>
                          </Link>
                        </>
                      );
                    })
                    : offerview.map((view) => {
                      return (
                        <Link
                          to={`/${encryptfunction(view.user.id)}/profile`}
                          state={{
                            value: "edit",
                            id: view.user.id,
                          }}
                        >
                          <div className="flex items-center mb-5 px-6">
                            <div className="w-11 h-11 overflow-hidden rounded-full">
                              <ProfileAvatar
                                singleUser={view.user}
                                sizeStyle={{ width: 42, height: 42 }}
                                className="shrink-0 rounded-full"
                              />
                            </div>
                            <div className="ml-3">
                              <h4 className="mb-1 fs-16 Medium black">
                                {view.user.firstname} {view.user.lastname}
                              </h4>
                              <p className="darkGray Regular fs-13">
                                Viewed your RFQ {setTime(view.created_at)}
                              </p>
                            </div>
                          </div>
                        </Link>
                      );
                    })}
                </div>
              </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ Auth }) => {
  return {
    authUser: Auth.authUserInformation,
  };
};

export default connect(mapStateToProps, null)(SeeAllViews);
