import React from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { IoCloseCircleOutline } from "react-icons/io5";
import { Nocontent } from "components";
import {convertToSlug, encryptfunction} from "../../../../helpers";
import {Link} from "react-router-dom";

const OrderModal = ({ isOpen, closeModal, data }) => {
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={isOpen}
        onClose={closeModal}
        id="scrollbar"
      >
        <div className="shadow-none bg-gray-100 relative">
          <Toolbar className="flex items-end justify-end">
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeModal}
              aria-label="close"
              className="rounded--lg hover:bg--gray"
            >
              <IoCloseCircleOutline size={32} className="primary" />
            </IconButton>
          </Toolbar>
        </div>
        <div className="flex flex-col overflow-hidden">
          <div className="sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-x-hidden overflow-y-auto max-h-[550px] h-full">
                {data && data.length > 0 ? (
                  <table className="min-w-full text-left text-sm font-light">
                    <thead className="border-b bg-white font-medium">
                      <tr>
                        <th scope="col" className="px-6 py-4">
                          ID
                        </th>
                        <th scope="col" className="px-6 py-4">
                          Title
                        </th>
                        <th scope="col" className="px-6 py-4">
                          Price
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    {data.map((status1) => (
                        <tr key={status1.offer_id} className="border bg-neutral-100">
                          <td className="whitespace-nowrap border px-6 py-4 font-medium">
                            <Link
                                to={`/rfq/${encryptfunction(status1.offer_id)}/${convertToSlug(status1.title)}`}
                                className="intro-y hover:primary-only-text"
                            >
                              {status1.offer_id}
                            </Link>
                          </td>
                          <td className="whitespace-nowrap border px-6 py-4">
                            <Link
                                to={`/rfq/${encryptfunction(status1.offer_id)}/${convertToSlug(status1.title)}`}
                                className="intro-y hover:primary-only-text"
                            >
                            {status1.title}
                            </Link>
                          </td>
                          <td className="whitespace-nowrap border px-6 py-4">
                            {status1.total}
                          </td>
                        </tr>
                    ))}

                    </tbody>
                  </table>
                ) : (
                  <Nocontent />
                )}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default OrderModal;
