import React, { Fragment, Component } from "react";
import { Popover, Transition } from "@headlessui/react";
import { FiMenu } from "react-icons/fi";
import { SiKnowledgebase } from "react-icons/si";
import { IoDocumentTextOutline } from "react-icons/io5";
import { Link, NavLink } from "react-router-dom";
import { IoPersonOutline } from "react-icons/io5";
import { BiConversation } from "react-icons/bi";
import "./styles.css";
import logo from "../../assets/images/logo.png";
import { Buttonlink, Notification, Search, SwitchToggle, VerifyIcon } from "..";
import { IoIosNotifications } from "react-icons/io";
import { IoHomeOutline } from "react-icons/io5";
import Auth from "../../Auth";
import AuthUserAction from "../../store/actions/MyAuthUserAction";
import AlertsAction from "../../store/actions/MyAlertsAction";
import { connect } from "react-redux";
import cookie from "js-cookie";
import {
  AUTH_USER_MRO,
  GRAPH_MRO_USER_AUTH_TOKEN,
} from "../../config/constants";
import Profileavatar from "../profileavatar";
import { BsBarChartLine, BsListTask } from "react-icons/bs";
import { RiInfraredThermometerLine } from "react-icons/ri";
import { toast, ToastContainer } from "react-toastify";
import MySelectedRFQsAction from "store/actions/MySelectedRFQsAction";
import store from "../../store";
import { encryptfunction } from "helpers";
import { SET_SOCKET_ID } from "store/actions/types";
const auth = new Auth();

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alerts: null,
      searchingCategory: "",
      searchingTag: "",
      clickedAlerts: [],
    };
  }

  componentDidMount = () => {
    if (auth.isAuthenticated()) {
      AuthUserAction.getAuthUserInformation();
      AlertsAction.getAuthUserAlerts({ page: 1 });
    } else {
      console.log("Not Login");
    }
  };

  _handleLogout = () => {
    cookie.remove(GRAPH_MRO_USER_AUTH_TOKEN);
    cookie.remove(AUTH_USER_MRO);
    localStorage.removeItem("AUTH_USER_MRO");
    store.dispatch({
      type: SET_SOCKET_ID,
      payload: {},
    });
    this.props?.socket?.on("disconnect", (data) => {
      // console.log("disconnect", data);
    });
    window.location.href = "/";
  };

  notify = (type, message) => {
    // Dismiss any existing toasts before showing a new one
    toast.dismiss();
    if (type === "error") {
      return toast.error(message);
    }
    if (type === "success") {
      return toast.success(message);
    }
    if (type === "warn") {
      return toast.warn(message);
    }
  };

  roleChanged = (type, message) => {
    if (type) {
      // Dismiss any existing toasts before showing a new one
      toast.dismiss();
      this.notify(type, message);
      AuthUserAction.getAuthUserInformation();
      // setTimeout(() => {
      //   window.location.reload(false);
      // }, 2000);
    } else {
      this.notify("error", message);
    }
  };

  handleClicked = (item) => {
    let checkedTags = Object.assign([], this.props.selectedTags);

    if (checkedTags.length > 0) {
      let found = checkedTags.find((data) => data.id === item.id);
      if (!found) {
        checkedTags.push(item);
      } else {
        checkedTags = checkedTags.filter((data) => data.id !== item.id);
      }

      MySelectedRFQsAction.selectTags(checkedTags);
    } else {
      checkedTags.push(item);
      MySelectedRFQsAction.selectTags(checkedTags);
    }
  };

  render() {
    let count =
      this.props.alerts.alerts &&
      this.props.alerts.alerts.filter((alert) => alert.read_at == null).length;

    const { authUser } = this.props;
    const currentRoute = window.location.pathname === "/" ? "hidden" : null;
    return (
      <div className="tail-header bg-white w-full border-b">
        <div
          className="container-fluid px-3 mx-auto"
          style={{ maxWidth: "1500px" }}
        >
          <ToastContainer position="top-right" autoClose={5000} />
          <div className="flex justify-between xs:items-center items-start py-4 md:space-x-10 relative">
            <div className="flex justify-between lg:w-0 lg:flex-1 gap-x-10 shrink-0 mr-3">
              <NavLink
                to="/"
                className="items-center flex rounded-full black Medium fs-13 -intro-x shrink-0"
              >
                <img src={logo} alt="logo" className="w-20 object-contain" />
              </NavLink>
              <div className="hidden xxl:flex w-full">
                {/* Search bar only visible for home screen */}
                <NavLink
                  to=""
                  className={({ isActive }) => (isActive ? "" : "hidden plus")}
                ></NavLink>
                <Search />
                {/* Search bar only visible for my rfq screen */}
                <NavLink
                  to="/rfq/0"
                  state="0"
                  className={({ isActive }) => (isActive ? "" : "hidden plus")}
                ></NavLink>
                <Search />
                <NavLink
                  to="/rfq/my-rfqs"
                  state="0"
                  className={({ isActive }) => (isActive ? "" : "hidden plus")}
                ></NavLink>
                <Search />
                <NavLink
                  to="/rfq/latest-action"
                  state="0"
                  className={({ isActive }) => (isActive ? "" : "hidden plus")}
                ></NavLink>
                <Search />
                <NavLink
                  to="/rfq/most-reponded"
                  state="0"
                  className={({ isActive }) => (isActive ? "" : "hidden plus")}
                ></NavLink>
                <Search />
                <NavLink
                  to="/rfq/no-respone"
                  state="0"
                  className={({ isActive }) => (isActive ? "" : "hidden plus")}
                ></NavLink>
                <Search />
                <NavLink
                  to="/rfq/view-as"
                  state="0"
                  className={({ isActive }) => (isActive ? "" : "hidden plus")}
                ></NavLink>
                <Search />
                <NavLink
                  to="/rfq/general"
                  state="0"
                  className={({ isActive }) => (isActive ? "" : "hidden plus")}
                ></NavLink>
                <Search />
                <NavLink
                  to="/rfq/invitations"
                  state="0"
                  className={({ isActive }) => (isActive ? "" : "hidden plus")}
                ></NavLink>
                <Search />
                <NavLink
                  to="/rfq/approved"
                  state="0"
                  className={({ isActive }) => (isActive ? "" : "hidden plus")}
                ></NavLink>
                <Search />
              </div>
            </div>
            <Popover.Group
              as="nav"
              className="xs:flex flex-row-reverse items-center justify-between w-full xs:max-w-sm grow flex-wrap"
            >
              {auth.isAuthenticated() ? (
                <>
                  <div className="flex xs:justify-between items-center grow">
                    <Popover className="relative intro-x z-2xlfull xs:ml-0 ml-auto">
                      <Popover.Button className="w-20">
                        <span className="relative inline-block">
                          <div className="w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center">
                            <IoIosNotifications size={22} />
                          </div>
                          {count > 0 && (
                            <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg--primary rounded-full">
                              {count}
                            </span>
                          )}
                        </span>
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-300"
                        enterFrom="opacity-0 translate-y-0"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-250"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-0"
                      >
                        <Popover.Panel className="absolute z-10 right-0 transform sm:translate-x-0 xs:translate-x-20 xxs:translate-x-32 translate-x-32 w-screen xs:max-w-md xxs:max-w-sm max-w-[20rem] sm:px-0 top-full mt-5">
                          <div className="rounded-lg relative bg-white p-6 shadow-lg ring-1 ring-black ring-opacity-5">
                            <Notification
                              showAllAlerts={false}
                              size="text-lg"
                            />
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </Popover>

                    <Popover className="relative intro-x z-2xlfull xs:mx-0 mx-4">
                      <Popover.Button className=" inline-block">
                        <Profileavatar
                          singleUser={authUser}
                          sizeStyle={{ width: 42, height: 42 }}
                          className="shrink-0 rounded-full"
                        />
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-300"
                        enterFrom="opacity-0 translate-y-0"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-250"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-0"
                      >
                        <Popover.Panel className="absolute z-xlfull right-0 transform sm:translate-x-0 translate-x-16 mt-3 w-screen lg:max-w-xl sm:max-w-lg xs:max-w-md xxs:max-w-sm max-w-[20rem] sm:px-0">
                          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="relative bg-white p-6  space-y-5">
                              <div className="flex flex-wrap gap-0 lg:gap-4">
                                <div className="flex grow">
                                  <Profileavatar
                                    singleUser={authUser}
                                    sizeStyle={{ width: 62, height: 62 }}
                                    className="shrink-0 rounded-full"
                                  />
                                  <div className="ml-3 mt-2.5">
                                    <Popover.Button as="div">
                                      <Link
                                        to={`/${encryptfunction(
                                          authUser.id
                                        )}/profile`}
                                        state={{
                                          value: "edit",
                                          id: authUser.id,
                                        }}
                                        className="hover:primary-all"
                                      >
                                        <div className="flex items-center gap-1">
                                          <h4 className="Medium text-lg">
                                            {authUser.firstname}{" "}
                                            {authUser.lastname}
                                          </h4>
                                          {
                                            <div className="w-[18px] h-[18px]">
                                              <VerifyIcon user={authUser} />
                                            </div>
                                          }
                                        </div>
                                        <p className="fs-12 gray">
                                          @{authUser.username}
                                        </p>
                                      </Link>
                                    </Popover.Button>
                                    <div className="mt-4 space-y-3" />
                                  </div>
                                </div>
                                <div className="flex justify-end grow">
                                  <Popover.Button as="div" className="ml-auto">
                                    {authUser.plan_status !==
                                      "Payment history" && (
                                        <NavLink
                                          to="/pricing/system"
                                          className="p-2.5 rounded-lg flex items-center justify-center primary hover:opacity-70 cursor-pointer underline hover:underline"
                                        >
                                          {authUser.plan_status}
                                        </NavLink>
                                      )}
                                  </Popover.Button>
                                </div>
                              </div>
                              <div className="h-5 lg:h-0" />
                              <div className="flex items-end justify-between">
                                <div className="w-28">
                                  <Popover.Button as="div">
                                    <NavLink
                                      to={`/${encryptfunction(
                                        authUser.id
                                      )}/profile`}
                                      state={{ value: "edit", id: authUser.id }}
                                      activeclassname="active--link"
                                      className="p-2.5 rounded-lg flex items-center justify-center hover:text-white white-all hover:opacity-70 hover:bg--primary bg--primary cursor-pointer"
                                    >
                                      <p className="white">Profile</p>
                                    </NavLink>
                                  </Popover.Button>
                                </div>
                                <div className="w-28">
                                  <Popover.Button as="div">
                                    <NavLink
                                      to="/"
                                      onClick={this._handleLogout}
                                      activeclassname="active--link"
                                      className="p-2.5 rounded-lg flex items-center justify-center hover:text-white white-all hover:opacity-70 hover:bg--primary bg--primary cursor-pointer"
                                    >
                                      <p className="white">Logout</p>
                                    </NavLink>
                                  </Popover.Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </Popover>

                    <div className="xxl:hidden flex items-center justify-evenly">
                      <Popover className="bg-white">
                        <Popover.Button className="bg-whte rounded-md p-2 inline-flex items-center justify-center text-gray-500 hover:text-gray-900 hover:bg-gray-100">
                          <FiMenu size={32} />
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          enter="duration-200 ease-out"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="duration-100 ease-in"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <Popover.Panel className="absolute top-20 z-2xlfull inset-x-0 py-2 transition transform origin-top-right xxl:hidden">
                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                              <div className="py-10 px-5 space-y-10">
                                <Popover.Button as="div">
                                  <NavLink
                                    to="/"
                                    className="cursor-pointer intro-y black gap-x-2 text-left fs-13 hover:primary-all-text hover:primary--text flex xl:items-center xl:justify-start relative"
                                  >
                                    <IoHomeOutline size={18} />
                                    Home
                                  </NavLink>
                                </Popover.Button>

                                <Popover.Button as="div">
                                  <NavLink
                                    to="/rfq/0"
                                    state="0"
                                    className="cursor-pointer intro-y black gap-x-2 text-left fs-13 hover:primary-all-text hover:primary--text flex xl:items-center xl:justify-start relative"
                                  >
                                    <IoDocumentTextOutline size={18} />
                                    My RFQ
                                  </NavLink>
                                </Popover.Button>

                                <Popover.Button as="div">
                                  <NavLink
                                    to="/rfq-ledger"
                                    className="cursor-pointer intro-y black gap-x-2 text-left fs-13 hover:primary-all-text hover:primary--text flex xl:items-center xl:justify-start relative"
                                  >
                                    <SiKnowledgebase size={16} />
                                    My RFQ Ledger
                                  </NavLink>
                                </Popover.Button>

                                {this.props.authUser.is_seller ? null : (
                                  <Popover.Button as="div">
                                    <NavLink
                                      to="/analytics"
                                      state={{ value: "analytics" }}
                                      className="cursor-pointer intro-y black gap-x-2 text-left fs-13 hover:primary-all-text hover:primary--text flex xl:items-center xl:justify-start relative"
                                    >
                                      <BsBarChartLine size={18} />
                                      Analytics
                                    </NavLink>
                                  </Popover.Button>
                                )}

                                <Popover.Button as="div">
                                  <NavLink
                                    to="/categories"
                                    className="cursor-pointer intro-y black gap-x-2 text-left fs-13 hover:primary-all-text hover:primary--text flex xl:items-center xl:justify-start relative"
                                  >
                                    <BsListTask size={20} />
                                    Categories
                                  </NavLink>
                                </Popover.Button>

                                {/* <Popover.Group className="flex items-center justify-between">
                                  <Popover className="relative h-full flex intro-y z-xlfull">
                                    <Popover.Button>
                                      <p className="cursor-pointer black gap-x-2 text-left fs-13 hover:primary-all hover:primary flex xl:items-center xl:justify-start relative">
                                        <BsListTask size={20} />
                                        Categories
                                        <IoIosArrowDown
                                          size={18}
                                          className="gray"
                                        />
                                      </p>
                                    </Popover.Button>

                                    <Transition
                                      as={Fragment}
                                      enter="transition ease-out duration-300"
                                      enterFrom="opacity-0 translate-y-0"
                                      enterTo="opacity-100 translate-y-0"
                                      leave="transition ease-in duration-250"
                                      leaveFrom="opacity-100 translate-y-0"
                                      leaveTo="opacity-0 translate-y-0"
                                    >
                                      <Popover.Panel className="absolute z-10 left-0 transform mt-10 w-screen sm:max-w-md max-w-xs sm:px-0">
                                        <div className=" rounded-lg shadow-lg bg-white relative ring-1 ring-black ring-opacity-5 overflow-hidden">
                                          <div className="p-6">
                                            <div className="bg--lightGray rounded-xl flex items-center h-10 px-3">
                                              <HiOutlineSearch className="darkGray" />
                                              <input
                                                placeholder="Search categories"
                                                className="bg-transparent h-full w-full pl-3"
                                                value={
                                                  this.state.searchingCategory
                                                }
                                                onChange={(e) =>
                                                  this.setState({
                                                    searchingCategory:
                                                      e.target.value,
                                                  })
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="md:h-[20rem] h-[15rem] overflow-y-auto">
                                            <Query
                                              query={GET_CATEGORIES}
                                              variables={{
                                                search:
                                                  this.state.searchingCategory,
                                              }}
                                            >
                                              {({
                                                loading,
                                                error,
                                                data,
                                                fetchMore,
                                                refetch,
                                              }) => {
                                                if (loading) return <Loader />;
                                                if (error)
                                                  return <Noconnection />;
                                                if (data) {
                                                  return (
                                                    data.categories &&
                                                    data.categories.data &&
                                                    data.categories.data.map(
                                                      (item) => (
                                                        <Popover.Button as="div">
                                                          <Link
                                                            to={`/categories/${item.id}`}
                                                            state={{
                                                              selectedCategory:
                                                                item,
                                                            }}
                                                            className="cursor-pointer hover:bg-gray-100 border-b py-3.5 px-5 hover:primary-all flex items-center justify-between"
                                                          >
                                                            {item.name}
                                                            <IoIosArrowForward className="gray" />
                                                          </Link>
                                                        </Popover.Button>
                                                      )
                                                    )
                                                  );
                                                } else {
                                                  return null;
                                                }
                                              }}
                                            </Query>
                                          </div>
                                        </div>
                                      </Popover.Panel>
                                    </Transition>
                                  </Popover>
                                </Popover.Group> */}

                                {/* //// */}

                                {/* <Popover.Group className="flex items-center justify-between">
                                  <Popover className="relative h-full flex intro-y z-full">
                                    <>
                                      <Popover.Button>
                                        <p className="cursor-pointer black gap-x-2 text-left fs-13 hover:primary-all hover:primary flex xl:items-center xl:justify-start relative whitespace-nowrap">
                                          <BiTag size={20} />
                                          Industry Tag
                                          <IoIosArrowDown
                                            size={18}
                                            className="gray"
                                          />
                                        </p>
                                      </Popover.Button>

                                      <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-300"
                                        enterFrom="opacity-0 translate-y-0"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in duration-250"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 translate-y-0"
                                      >
                                        <Popover.Panel className="absolute z-10 left-0 transform mt-10 w-screen sm:max-w-md max-w-xs sm:px-0">
                                          <div className="rounded-lg shadow-lg bg-white relative ring-1 ring-black ring-opacity-5">
                                            <div className="p-6">
                                              <div className="bg--lightGray rounded-xl flex items-center h-10 px-3">
                                                <HiOutlineSearch className="darkGray" />
                                                <input
                                                  placeholder="Enter to search and select"
                                                  className="bg-transparent h-full w-full pl-3"
                                                  value={this.state.searchingTag}
                                                  onChange={(e) =>
                                                    this.setState({
                                                      searchingTag:
                                                        e.target.value,
                                                    })
                                                  }
                                                />
                                              </div>
                                            </div>

                                            <div className="md:h-[20rem] h-[15rem] overflow-y-auto">
                                              <Query
                                                query={SEARCH_TAGS}
                                                variables={{
                                                  q:
                                                    "%" +
                                                    this.state.searchingTag +
                                                    "%",
                                                  orderBy: [
                                                    {
                                                      field: "name",
                                                      order: "ASC",
                                                    },
                                                  ],
                                                }}
                                                fetchPolicy="network-only"
                                              >
                                                {({
                                                  loading,
                                                  error,
                                                  data,
                                                  fetchMore,
                                                }) => {
                                                  if (loading) return <Loader />;

                                                  if (error)
                                                    return <Noconnection />;

                                                  if (data) {
                                                    return (
                                                      data.tagSearch &&
                                                      data.tagSearch.data &&
                                                      data.tagSearch.data.map(
                                                        (tag) => (
                                                          <ListItem
                                                            disablePadding
                                                            className="rounded-none overflow-hidden border-b"
                                                          >
                                                            <ListItemButton
                                                              dense
                                                              className="hover:primary-all"
                                                            >
                                                              <Link
                                                                to={`/categories/${tag.id}`}
                                                                state={{
                                                                  selectedCategory:
                                                                    tag,
                                                                }}
                                                                className="flex-grow"
                                                              >
                                                                <div className="darkGray">
                                                                  {tag.name}
                                                                </div>
                                                              </Link>
                                                              <ListItemIcon
                                                                onClick={() =>
                                                                  this.handleClicked(
                                                                    tag
                                                                  )
                                                                }
                                                                className="min-w-auto"
                                                              >
                                                                <Checkbox
                                                                  checked={
                                                                    this.props.selectedTags.find(
                                                                      (data) =>
                                                                        data.id ===
                                                                        tag.id
                                                                    )
                                                                      ? true
                                                                      : false
                                                                  }
                                                                  className="primary-checkbox"
                                                                />
                                                              </ListItemIcon>
                                                            </ListItemButton>
                                                          </ListItem>
                                                        )
                                                      )
                                                    );
                                                  }
                                                }}
                                              </Query>
                                            </div>
                                          </div>
                                        </Popover.Panel>
                                      </Transition>
                                    </>
                                  </Popover>
                                </Popover.Group> */}

                                {/*{this.props.authUser.is_seller ? (
                                  <Popover.Button as="div">
                                    <NavLink
                                      to="/analytics"
                                      state={{ title: "", type: "seller" }}
                                      className="cursor-pointer intro-y black gap-x-2 text-left fs-13 hover:primary-all-text hover:primary--text flex xl:items-center xl:justify-start relative"
                                    >
                                      <BsBarChartLine size={18} />
                                      Analytics
                                    </NavLink>
                                  </Popover.Button>
                                ) : null}*/}

                                {this.props.authUser.is_seller ? null : (
                                  <Popover.Button as="div">
                                    <NavLink
                                      to="/recommendation"
                                      state={{ selectedIndex: 0 }}
                                      className="cursor-pointer intro-y black gap-x-2 text-left fs-13 hover:primary-all-text hover:primary--text flex xl:items-center xl:justify-start relative"
                                    >
                                      <BiConversation size={18} />
                                      Recommendation
                                    </NavLink>
                                  </Popover.Button>
                                )}

                                <Popover.Button as="div">
                                  <NavLink
                                    to="/leaders"
                                    className="cursor-pointer intro-y black gap-x-2 text-left fs-13 hover:primary-all-text hover:primary--text flex xl:items-center xl:justify-start relative"
                                  >
                                    <RiInfraredThermometerLine size={18} />
                                    Leaders
                                  </NavLink>
                                </Popover.Button>
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </Popover>
                    </div>
                  </div>
                  <div className="intro-x grow xs:my-0 xs:ml-0 xxs:ml-[20%] ml-[10%] ml-5 my-3">
                    <SwitchToggle
                      user_id={authUser.id}
                      is_seller={authUser.is_seller}
                      paymentPlan={authUser?.payments}
                      become_seller={authUser.become_seller}
                      valueUpdate={this.roleChanged}
                    />
                  </div>
                </>
              ) : (
                <div className="relative intro-x gap-3 z-xlfull w-full flex justify-end ml-auto">
                  <Buttonlink
                    to="/login"
                    text="Sign In"
                    title="Sign In"
                    rel="nofollow"
                    className="bg-white primary hover:primary hover:opacity-80 border-2 border--primary h-10 px-7 flex items-center justify-center rounded-full"
                  />
                  <Buttonlink
                    to="/registration"
                    text="Join Free"
                    title="Registration"
                    rel="nofollow"
                    className="bg--primary white hover:white hover:opacity-80 hover:bg--primary focus:bg--primary h-10 px-7 flex items-center justify-center rounded-full"
                  />
                </div>
                // <Popover className="relative intro-x z-xlfull w-full flex md:justify-center justify-end ml-auto md:pr-0 pr-3">
                //   <Popover.Button className=" inline-block">
                //     <div className="w-10 h-10 bg-gray-200 relative rounded-full flex items-center justify-center">
                //       <IoPersonOutline size={22} />
                //     </div>
                //   </Popover.Button>

                //   <Transition
                //     as={Fragment}
                //     enter="transition ease-out duration-300"
                //     enterFrom="opacity-0 translate-y-0"
                //     enterTo="opacity-100 translate-y-0"
                //     leave="transition ease-in duration-250"
                //     leaveFrom="opacity-100 translate-y-0"
                //     leaveTo="opacity-0 translate-y-0"
                //   >
                //     <Popover.Panel className="absolute z-10 md:right-2/4 right-0 transform mt-10 md:translate-x-5 -translate-x-3 w-screen sm:max-w-sm max-w-xs sm:px-0">
                //       <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden mt-6">
                //         <div className="relative bg-white p-6  space-y-5">
                //           {/*<p className="text-xl Medium">Welcome</p>*/}
                //           <Popover.Button as="div">
                //             <Buttonlink
                //               to="/login"
                //               text="Sign In"
                //               title="Sign In"
                //               rel="nofollow"
                //               className="bg--primary white hover:white hover:opacity-80 hover:bg--primary focus:bg--primary h-10 w-full flex items-center justify-center rounded-full"
                //             />
                //           </Popover.Button>
                //           <Popover.Button as="div">
                //             <Buttonlink
                //               to="/registration"
                //               text="Join Free"
                //               title="Registration"
                //               rel="nofollow"
                //               className="bg-white primary hover:primary hover:opacity-80 border-2 border--primary mt-5 h-10 w-full flex items-center justify-center rounded-full"
                //             />
                //           </Popover.Button>
                //           {/*<div className="h-12" />
                //           <div className="flex items-center gap-x-4">
                //             <div className="divider w-full" />
                //             <p className="whitespace-nowrap Light fs-13 darkGray">
                //               or continue with
                //             </p>
                //             <div className="divider w-full" />
                //           </div>*/}
                //           {/*<div className="flex items-center justify-center gap-x-10">
                //             <Buttonlink
                //               onClick={true}
                //               prefix={<FcGoogle size={22} />}
                //               className="relative w-full hover:white hover:opacity-80 hover:bg-slate-100 flex justify-center py-2 px-4 border rounded-lg text-white bg-transparent hover:bg-transparent focus:outline-none "
                //             />
                //             <Buttonlink
                //               onClick={true}
                //               prefix={<FaLinkedin size={22} color="#0966C2" />}
                //               className="relative w-full hover:white hover:opacity-80 hover:bg-slate-100 flex justify-center py-2 px-4 border rounded-lg text-white bg-transparent hover:bg-transparent focus:outline-none "
                //             />
                //           </div>*/}
                //         </div>
                //       </div>
                //     </Popover.Panel>
                //   </Transition>
                // </Popover>
              )}
            </Popover.Group>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ Auth, RFQs, Alerts }) => {
  return {
    authUser: Auth.authUserInformation,
    socket: Auth.socket,
    selectedTags: RFQs.selectedTags,
    alertsPaginatorInfo: Alerts.paginatorInfo,
    alerts: Alerts,
  };
};
export default connect(mapStateToProps, null)(Header);
