import { RfqLedger } from "..";
import { useLocation, useNavigate } from "react-router-dom";
export default function RfqLedgerScreen() {
  const location = useLocation();
  document.title = "RFQ-Ledger | SourcingGenie";
  const searchParams = new URLSearchParams(location.search);

  // Get the value of a specific parameter
  const paramValue = searchParams.get("searched");
  const navigation = useNavigate();
  return <RfqLedger navigate={navigation} params={paramValue} />;
}
