import React, { useState } from "react";
import { LoadMoreData, Loader, Noconnection, Nocontent, Subbanner } from "components";
import direction from "../../../assets/images/direction.png";
import { BsChatSquareQuoteFill } from "react-icons/bs";
import GET_OFFERS from "../../../graphql/queries/getOffers";
import { toast } from "react-toastify";
import RecommendationQuestion from "../../BookmarksScreen/components/rfqquestion";
import { Query } from "@apollo/client/react/components";

export default function Recommendation(props) {
  const [loadMore, setLoadMore] = useState(false);
  const orderBy = [{ field: "updated_at", order: "DESC" }]
  let variables = { orderBy };
  variables.help_expert = true;
  const loadMoreRFQ = (data, fetchMore, refetch) => {
    fetchMore({
      variables: {
        cursor: data.offers.paginatorInfo.currentPage + 1,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newQuestions = fetchMoreResult.offers.data;
        const pageInfo = fetchMoreResult.offers.paginatorInfo;
        if (data.offers.paginatorInfo.total !== pageInfo.total) {
          refetch();
        }
        if (pageInfo.hasMorePages) {
          setLoadMore(true);
        } else {
          setLoadMore(false);
        }

        return newQuestions.length
          ? {
            offers: {
              __typename: previousResult.offers.__typename,
              data: [...previousResult.offers.data, ...newQuestions],
              paginatorInfo: pageInfo,
            },
          }
          : previousResult;
      },
    });
  };
  // console.log("variables", variables);
  return (
    <div>
      <Subbanner title="Recommendation" />
      <div className="container -mt-20">
        <div className="h-10" />
        <div className="grid xl:grid-cols-4 lg:grid-cols-1 gap-4">
          <div className="hidden xl:block">
            <div className="overflow-hidden intro-y">
              <div className="px-5 py-10 space-y-5">
                <div className="flex justify-center animate-bounce">
                  <BsChatSquareQuoteFill size={200} className="primary" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2 rounded--xl overflow-hidden">
            <div className="mb-1 intro-y">
              <Query
                query={GET_OFFERS}
                variables={variables}
                fetchPolicy={"cache-and-network"}
              >
                {({ loading, error, data, fetchMore, refetch }) => {
                  //this.refetch = refetch;
                  if (loading) return <Loader />;
                  if (error) return <Noconnection />;
                  if (data?.offers?.paginatorInfo?.total > 0) {
                    //console.log("data", data);
                    return (
                      <>
                        <RecommendationQuestion questions={data.offers.data} />
                        <div className="mt-5 text-center">
                          {data?.offers?.paginatorInfo?.hasMorePages && (
                            <LoadMoreData
                              title="Load More RFQ"
                              data={data}
                              fetchMore={fetchMore}
                              refetch={refetch}
                              callback={loadMoreRFQ}
                              loading={loading}
                            />
                          )}
                        </div>
                      </>
                      // <RecommendationQuestion questions={data.offers.data} />
                    );
                  } else {
                    return <Nocontent />;
                  }
                }}
              </Query >
            </div>
          </div>
          {/* <div className="hidden xl:block">
            <div className="box overflow-hidden intro-y">
              <div className="px-5 py-10 space-y-5">
                <div className="flex justify-center">
                  <img alt="direction" src={direction} className="w-28 h-28" />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
