import React, { useState, useEffect } from "react";
import {
    IoIosCloseCircle,
} from "react-icons/io";
import { gql } from "@apollo/client";
import { Buttonlink } from "components";
import client from "graphql/client";
import { toast } from "react-toastify";
import cookie from "js-cookie";

const CREATE__CUSTOM_PLANS = gql`
  mutation customPlan(
    $title: String!
    $price: Int!
    $validity: Int!
    $plan_features: [Object]
  ) {
    customPlan(
      title: $title
      price: $price
      validity: $validity
      plan_features: $plan_features
    ) {
      plan_id
      title
      subtitle
      price
      validity
      plan_features {
        plan_feature_id
        name
        price
        plan_type
        feature_type
        user_type
        quantity
      }
    }
  }
`;

const CustomPlan = (props) => {

    const [windowSize, setWindowSize] = useState(window.innerWidth);
    // const [FeaturesData, setFeaturesData] = useState(props.FeaturesData);
    const [PlansData, setPlansData] = useState([]);
    const [customPlan, setCustomPlan] = useState([]);
    const [customPlanData, setCustomPlanData] = useState({});
    const [enabledStates, setEnabledStates] = useState(1);
    const [inputValues, setInputValues] = useState([]);
    const [isProceed, setIsProceed] = useState(false);
    const [paramsRender, setParamsRender] = useState('');
    const cookieCustomPlan = props.for;
    const planName = props.for === "CustomPlan" ? "Custom Plan" : "Profile Custom Plan";

    useEffect(() => {
        let retrievedCustomPlan = cookie.get(cookieCustomPlan);

        retrievedCustomPlan = retrievedCustomPlan
            ? JSON.parse(retrievedCustomPlan)
            : {};

        if (
            retrievedCustomPlan !== null &&
            Object.keys(retrievedCustomPlan).length > 0
        ) {
            setIsProceed(true);
        } else {
            setIsProceed(false);
        }
    }, [isProceed]);
    const addCustomPlan = (feature) => {
        setCustomPlan([...customPlan, feature]);
    };

    const removeCustomPlan = (id, index) => {
        const updatedInputValues = [...inputValues];
        updatedInputValues.splice(index, 1);
        setInputValues(updatedInputValues);

        const updatedCustomPlan = customPlan.filter(
            (plan) => plan.plan_feature_id !== id
        );
        setCustomPlan(updatedCustomPlan);
    };

    const calculateTotalPrice = () => {
        const totalPrice = customPlan.reduce((total, plan) => {
            const price = parseFloat(plan.price);
            const quantity = parseInt(plan.quantity || 0); // Use 0 if quantity is undefined
            if (plan.feature_type === 2 && Number.isFinite(quantity)) {
                return total + price * quantity;
            } else {
                return total + price;
            }
        }, 0);

        return totalPrice.toFixed(2); // Format to 2 decimal places if needed
    };


    const monthsOption = [
        { name: "Monthly", value: 1 },
        { name: "Quarterly", value: 3 },
        { name: "Semi Annual", value: 6 },
        { name: "Annual", value: 12 },
    ];

    const handleButtonClick = () => {
        const customPlanCookie = cookie.get(cookieCustomPlan);
        try {
            const customPlanArray = JSON.parse(customPlanCookie); // Parse the JSON string to an array
            setCustomPlan(customPlanArray.plan_features);
        } catch (error) {
            console.error("Error parsing CustomPlan cookie:", error);
        }

        setIsProceed(false);
        const emptyCustomPlan = JSON.stringify({});
        cookie.set(cookieCustomPlan, emptyCustomPlan); // Set the CustomPlan cookie to null
    };

    const handleNumberInputChange = (index, value) => {
        // Remove any spaces from the input value
        value = value.replace(/\s+/g, "");

        // Ensure the value is a non-empty string and can be parsed as a number
        if (value !== "" && !isNaN(Number(value))) {
            // Remove leading zeros and prevent 0 as first digit
            if (value === "0" || value[0] === "0") {
                value = "";
            } else {
                value = String(Number(value));
            }
        } else {
            value = "";
        }

        // Update the state with the new value for inputValues
        const updatedInputValues = [...inputValues];
        updatedInputValues[index] = value;
        setInputValues(updatedInputValues);

        customPlan[index] = {
            ...customPlan[index],
            quantity: value,
        };
    };

    const notify = (message = "", type = "error") => {
        // Dismiss any existing toasts before showing a new one
        toast.dismiss();
        if (type === "error") {
            return toast.error(message, { position: "top-right" });
        }
        toast.success(message, { position: "top-right" });
    };

    const createCustomPlan = () => {
        let hasInvalidQuantity = false;

        customPlan.forEach((plan) => {
            if (
                plan.quantity === 0 ||
                plan.quantity === "" ||
                plan.quantity === undefined
            ) {
                hasInvalidQuantity = true;
                return;
            }
        });
        if (hasInvalidQuantity) {
            notify("Please Insert a number in every input field", "error");

            return;
        }
        let variables = {
            title: planName,
            price: calculateTotalPrice() * enabledStates,
            validity: enabledStates,
            plan_features: customPlan,
        };
        client
            .mutate({
                mutation: CREATE__CUSTOM_PLANS,
                variables,
            })
            .then((res) => {
                // console.log('res.data.customPlan', res.data.customPlan);
                setCustomPlanData(res.data.customPlan);
                const expirationDate = new Date();
                expirationDate.setTime(expirationDate.getTime() + 60 * 60 * 1000); // 1 hour in milliseconds
                const serializedObject = JSON.stringify(res.data.customPlan);
                // Set the cookie using js-cookie

                cookie.set(cookieCustomPlan, serializedObject, {
                    expires: expirationDate,
                });
                setIsProceed(true);
            })
            .catch((errors) => {
                console.error(errors);
            });
    };
    return (
        <>
            {
                !isProceed ? (
                    <div className="shadow--sm rounded-lg bg-white overflow-hidden border sm:p-10">
                        <div className="text-center">
                            <h1 className="Medium text-lg lg:text-2xl">
                                What kind of plan are you interested in?
                            </h1>
                        </div>
                        <div className="flex items-center flex-wrap gap-3 mt-5">
                            {!isProceed &&
                                props.FeaturesData.map((features, index) => {
                                    const isFind = customPlan?.some(
                                        (plan) => plan.plan_feature_id === features.plan_feature_id
                                    );
                                    return (
                                        <div key={index}>
                                            <div
                                                className={`${isFind
                                                    ? "bg--primary pointer-events-none text-white"
                                                    : "bg-gray-200"
                                                    } hover:opacity-70 rounded-lg px-4 py-1.5 cursor-pointer inline-block`}
                                                onClick={() => addCustomPlan(features)}
                                            >
                                                <h4 className="Regular text-sm lg:text-lg mr-1 text-color">
                                                    {features.name}
                                                </h4>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                        <div className="h-px my-5 bg-gray-400" />
                        <div className="flex flex-col gap-3">
                            {!isProceed &&
                                customPlan?.length !== 0 &&
                                customPlan.map((plan, index) => {
                                    if (plan.feature_type === 1) {
                                        customPlan[index] = {
                                            ...customPlan[index],
                                            quantity: "Check Box",
                                        };
                                    }
                                    return (
                                        <div
                                            key={index}
                                            className="flex items-center justify-between gap-5"
                                        >
                                            <div className="intro-x bg--lightPrimary hover:opacity-70 rounded-lg px-4 py-1.5 cursor-pointer max-w-lg flex justify-between items-center w-full">
                                                <h4 className="Regular text-sm lg:text-lg mr-1 text-color">
                                                    {plan.name}
                                                </h4>
                                                <div
                                                    onClick={() =>
                                                        removeCustomPlan(plan.plan_feature_id, index)
                                                    }
                                                >
                                                    <IoIosCloseCircle size={20} className="primary" />
                                                </div>
                                            </div>
                                            <div>
                                                {plan.feature_type === 1 ? (
                                                    <p>Unlimited</p>
                                                ) : (
                                                    <input
                                                        type="text"
                                                        required
                                                        maxLength={4}
                                                        value={plan?.quantity ? plan?.quantity : ""}
                                                        onChange={(e) =>
                                                            handleNumberInputChange(index, e.target.value)
                                                        }
                                                        className="rounded-lg px-4 py-1.5 border border-gray-300"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>

                        {customPlan && customPlan.length !== 0 && (
                            <>
                                <div className="h-px my-5 bg-gray-400" />
                                <div className="flex items-center gap-5">
                                    {monthsOption.map((option, index) => (
                                        <label
                                            key={option.name}
                                            htmlFor={option.name}
                                            className="cursor-pointer flex items-center"
                                        >
                                            <input
                                                type="radio"
                                                id={option.name}
                                                name="Validity"
                                                defaultValue={enabledStates}
                                                checked={enabledStates === option.value}
                                                onChange={() => setEnabledStates(option.value)}
                                                className="hidden peer"
                                            />
                                            <span className=" peer-checked:bg-[#ef553b] border-[1.2px] border-primary w-[14.46px] h-[14.46px] inline-block rounded-full shrink-0 z-[10]"></span>
                                            <span className="text-black Medium text-[16px] font-normal leading-[21px] ml-2">
                                                {option.name.charAt(0).toUpperCase() +
                                                    option.name.slice(1)}
                                            </span>
                                        </label>
                                    ))}
                                </div>
                                <div className="flex flex-col justify-end items-end gap-3">
                                    <h1 className="Medium text-lg">
                                        Total Price : ${calculateTotalPrice() * enabledStates}
                                    </h1>
                                    <button
                                        onClick={createCustomPlan}
                                        className="text-white bg--primary hover:bg--primary relative focus:outline-none text-center hover:opacity-80 flex items-center justify-center py-2.5 px-4 border border-transparent rounded-lg"
                                    >
                                        Proceed
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                ) : isProceed ? (
                    <div className="flex flex-col items-center">
                        <h1 className="Medium font-semibold text-xl">
                            Great! you are one step away from upgrading your plan
                        </h1>
                        <h2 className="Medium font-normal pt-3 text-lg">
                            You have 1 hour to pay otherwise your custom plan will be lost
                        </h2>
                        <div className="flex mt-4 items-center gap-5">
                            <Buttonlink
                                to={`/payment`}
                                state={{ plan: customPlanData }}
                                title={"Pay Now"}
                                text={"Pay Now"}
                                rel="nofollow"
                                className={`bg-[#EF553B] text-white hover:text-white rounded-lg flex items-center justify-center max-w-[179px] w-full Regular hover:bg--primary focus:bg--primary border-0 shadow-none h-12 px-5`}
                            />
                            <button
                                onClick={handleButtonClick}
                                className="text-white bg--primary hover:bg--primary relative focus:outline-none text-center hover:opacity-80 flex whitespace-nowrap items-center justify-center py-2.5 px-4 border border-transparent rounded-lg"
                            >
                                Change Plan
                            </button>
                        </div>
                    </div>
                ) : null
            }
        </>
    )
}

export default CustomPlan