import React, { Component } from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { IoCloseCircleOutline, IoTrashOutline } from "react-icons/io5";
import { Buttonlink, SelectCountry } from "components";
import { FiPlus } from "react-icons/fi";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import SwitchToggleTeams from "../../components/listings/rfqcard/SwitchToggleTeams";
import { connect } from "react-redux";
import client from "graphql/client";
import Fullscreenpopup from "components/fullscreenpopup";
import createAddressMutatuion from "graphql/mutations/createAddressMutatuion";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import getAddressById from "graphql/queries/Address/getAddressById";
import DEL_ADDRESS from "graphql/mutations/deleteBusinessAddressMutation";
import setPrimaryAddress from "graphql/mutations/setPrimaryAddress";
import AuthUserAction from "../../store/actions/MyAuthUserAction";
import { MdOutlineEditLocationAlt } from "react-icons/md";
import { ImLocation2 } from "react-icons/im";
import displayError from "../../helpers/displayError";
import Alert from "@mui/material/Alert";
import me from "graphql/queries/me";
import { actions } from "../../store/redux/RFQRedux";
import MyAuthUserAction from "../../store/actions/MyAuthUserAction";

class AddressDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      addAddress: false,
      addresses: [],
      countryName: "",
      businessName: "",
      email: "",
      phoneNum: "",
      address_1: "",
      address_2: "",
      city: "",
      postalCode: "",
      addState: false,
      modalType: "",
      addressID: "",
      addressType: 0,
      enabled: null,
      disable: true,
      stop: false,
      country_id: null,
      country: null,
      state_id: null,
      isDeleted: false,
      loading: false,
    };
    this.selectFullPopup = React.createRef();
  }

  componentDidUpdate(prevState, prevProps) {

    if (prevProps.authUser != this.props?.authUser) {
      if (this.props?.authUser) {
        this.getAddress();
      }
    }
  }
  componentDidMount() {
    this.getAddress();
  }
  getAddress = () => {
    if (this.props.authUser?.id) {
      client
        .query({
          query: getAddressById,
          variables: {
            user_id: this.props?.authUser
              ? this.props.authUser?.id
              : this.props?.user_id,
          },
          fetchPolicy: "network-only",
        })
        .then((result) => {
          this.setState(
            {
              addresses: result.data.addressByUser,
            },
            function () {
              let company = result?.data?.addressByUser?.filter(
                (i) => i.is_primary
              );
              if (company && company.length > 0) {
                this.setState({
                  enabled: company[0],
                });
              }
            }
          );
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.businessName !== "" &&
      prevState.email !== "" &&
      prevState.phoneNum !== "" &&
      prevState.address_1 !== "" &&
      prevState.postalCode !== "" &&
      prevState.address_2 !== "" &&
      prevState.city !== "" &&
      !this.state.stop
    ) {
      this.setState({
        disable: false,
        stop: true,
      });
    }
  }

  openPopup = () => {
    this.setState({ open: true });
  };

  closePopup = () => {
    this.setState({ open: false, disable: true, loading: false });
    this.setState({ addAddress: false, countryName: "", country: null })

  };

  addAddressClick = () => {
    this.setState({
      addAddress: !this.state.addAddress,
      disable: this.state.addAddress,
      countryName: "",
      country: null,
      businessName: "",
      email: "",
      phoneNum: "",
      // addressType: "",
      address_1: "",
      address_2: "",
      city: "",
      postalCode: "",
      addressID: "",
    });
    this.props.selectState({});
  };

  openStatesModal = () => {
    this.setState({ modalType: "Select State" }, function () {
      this.selectFullPopup.current.openPopup();
    });
  };

  selectAddressType = (e) => {
    this.setState({ addressType: e.target.value });
  };

  addAddress = () => {
    this.setState({ loading: true })
    const {
      countryName,
      businessName,
      email,
      phoneNum,
      address_1,
      address_2,
      city,
      postalCode,
      addressType,
      addressID,
      country_id,
    } = this.state;
    const { _state } = this.props;
    var pattern = new RegExp(/^[0-9\b]+$/);
    if (!countryName) {
      this.setState({ disable: false, loading: false });
      return this.notify("Country name is required!!", "error");
    } else if (!businessName) {
      this.setState({ disable: false, loading: false });
      return this.notify("Business name is required!!", "error");
    } else if (!email) {
      this.setState({ disable: false, loading: false });
      return this.notify("Email is required!", "error");
    } else if (!phoneNum || phoneNum.length < 7) {
      this.setState({ disable: false, loading: false });
      return this.notify("Valid Phone Number is Required!", "error");
    } else if (!address_1) {
      this.setState({ disable: false, loading: false });
      return this.notify("Primary Address is required!", "error");
    } else if (!address_2) {
      this.setState({ disable: false, loading: false });
      return this.notify("Secondary Address is required!", "error");
    } else if (!city) {
      this.setState({ disable: false, loading: false });
      return this.notify("City is required!", "error");
    } else if (!_state.name) {
      this.setState({ disable: false, loading: false });
      return this.notify("Select your state!", "error");
    } else if (!postalCode) {
      this.setState({ disable: false, loading: false });
      return this.notify("Postal code is required!", "error");
    } else if (!pattern.test(postalCode)) {
      this.setState({ disable: false, loading: false, });
      return this.notify("Postal code is not valid", "error");
    } else {
      let variables = {};
      variables.id = addressID !== "" ? addressID : null;
      variables.name = businessName;
      variables.address_1 = address_1;
      variables.address_2 = address_2;
      variables.city = city;
      variables.zip_code = parseInt(postalCode);
      variables.state_id = parseInt(_state.id);
      variables.country_code = countryName;
      variables.phone = phoneNum.toString();
      variables.email = email;
      variables.addressType = addressType != 0;
      variables.is_primary = this.props?.location === "route" && true;
      variables.country_id = country_id;
      client
        .mutate({
          mutation: createAddressMutatuion,
          variables,
        })
        .then((res) => {
          this.setState(
            {
              open: false,
              disable: false,
              loading: false,
            },
            () => {
              this.props.selectState({});
              this.addAddressClick();
              this.getAddress();
              MyAuthUserAction.getAuthUserInformation()
                .then((res) => {
                  this.notify("Your address has been saved!", "success");
                })
                .catch((error) => {
                  console.log("error", error);
                });
              this.props.onClose();
            }
          );
        })
        .catch((errors) => {
          this.setState(
            {
              disable: false,
            },
            () => {
              displayError(errors).map((error) =>
                this.notify(error, "error")
              );
            }
          );
        });
    }
    ;
  };

  setPrimaryAddress = (address) => {
    client
      .mutate({
        mutation: setPrimaryAddress,
        variables: {
          id: parseInt(address.id),
        },
        refetchQueries: [
          {
            query: getAddressById,
            variables: { user_id: this.props.authUser.id },
          }, // DocumentNode object parsed with gql
          "addressByUser", // Query name
        ],
        optimisticResponse: {
          __typename: "Mutation",
          setPrimaryAddress: {
            __typename: "setPrimaryAddress",
            id: address.id,
            is_primary: true,
          },
        },
        update: (cache, { data: { setPrimaryAddress } }) => {
          try {
            const data = cache.readQuery({
              query: getAddressById,
              variables: { user_id: this.props.authUser.id },
            });
            let result = Object.assign(
              {},
              data.addressByUser.find((data) => data.id === address.id)
            );
            result.is_primary = true;

            cache.writeQuery({
              query: getAddressById,
              variables: { user_id: this.props.authUser.id },
              data,
            });
          } catch (e) {
            console.log("Catch Error", e);
          }
        },
      })
      .then((res) => {
        this.getAddress();
        this.notify("Your Primary address has been changed!", "success");
        this.props.onClose();
        // setTimeout(() => {
        //     window.location.reload(false);
        // }, 1500);
        // MySelectedRFQsAction.setPrimaryAddress(
        //   parseInt(res.data.setPrimaryAddress.id)
        // );
      })
      .catch((err) => {
        console.log("Error", err);
        this.notify("Error in changing Primary address!", "error");
      });
  };

  notify = (message = "", type = "error") => {
    // Dismiss any existing toasts before showing a new one
    toast.dismiss();
    if (type === "error") {
      return toast.error(message, { position: "top-right" });
    }
    toast.success(message, { position: "top-right" });
  };

  delAddress = (id) => {
    let ids = this.state.addresses.filter((o) => o.id !== id);
    this.setState({
      addresses: ids,
    });
    client
      .mutate({
        mutation: DEL_ADDRESS,
        variables: {
          id: parseInt(id),
        },
        refetchQueries: [
          { query: me }, // DocumentNode object parsed with gql
          "Me", // Query name
        ],
      })
      .then((res) => {
        this.notify("Bussines Address Deleted Successfully", "success");
        this.props.onClose();
        //this.setState({ disable: false, isDeleted: true });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  editAddress = (data) => {
    this.setState(
      {
        disable: false,
        addAddress: true,
        countryName: data.country_code,
        businessName: data.name,
        email: data.email,
        phoneNum: data.phone,
        addressType: data.addressType == false ? 0 : 1,
        address_1: data.address_1,
        address_2: data.address_2,
        city: data.city,
        postalCode: data.zip_code,
        addressID: parseInt(data.id),
        country_id: data?.country?.id,
        country: data?.country,
        addState: true,
      },
      () => {
        this.props.selectState(data.state);
      }
    );

  };

  render() {
    const {
      open,
      countryName,
      businessName,
      email,
      phoneNum,
      address_1,
      address_2,
      city,
      postalCode,
      modalType,
      addressType,
      enabled,
      addresses,
      country,
      country_id,
      state_id,
      loading
    } = this.state;
    let maxbusinessAddress = 1;
    this.props?.authUser?.payments?.forEach((plan) => {
      plan.plan.plan_features.forEach((plan_feature) => {
        if (plan_feature.name === "No. of addresses") {
          if (plan_feature.quantity > maxbusinessAddress) {
            maxbusinessAddress = plan_feature.quantity;
          }
        }
      })
    });
    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          scroll="body"
          open={open}
          onClose={this.props?.location === "route" ? null : this.closePopup}
          id="scrollbar"
          className="rounded--xl"
        >
          <div className="shadow-none bg-gray-100">
            <Toolbar className="flex items-center justify-between">
              <div>
                {this.props?.location !== "route" && (
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={this.closePopup}
                    aria-label="close"
                  >
                    <IoCloseCircleOutline size={32} className="primary" />
                  </IconButton>
                )}
              </div>
              <h3 className="black Medium text-xl">Business Address</h3>
              {!this.state.disable ?
                <Buttonlink
                  disabled={loading}
                  onClick={this.addAddress}
                  text="Save"
                  title="Save"
                  rel="nofollow"
                  className={`relative w-24 hover:white hover:opacity-80 flex justify-center py-1 border border-transparent rounded-lg ${this.state.loading
                    ? "bg-gray-200 hover:ring-offset-gray-500"
                    : "primary bg--lightPrimary  hover:bg--primary"
                    } focus:outline-none`}
                /> :
                <Buttonlink
                  onClick={this.closePopup}
                  text="Close"
                  title="Close"
                  rel="nofollow"
                  className={`relative w-24 hover:white hover:opacity-80 flex justify-center py-1 border border-transparent rounded-lg primary bg--lightPrimary  hover:bg--primary focus:outline-none`}
                />
              }

            </Toolbar>
          </div>
          <div className="p-3">
            {!this.state.addAddress &&
              addresses?.map((address, index) => (
                <div
                  className="flex items-center justify-between my-4"
                  key={address.id}
                >
                  <div className="flex items-center gap-x-3">
                    <ImLocation2 className="darkGray" />
                    <p className="darkGray break-all fs-14">
                      {address.name}, {address.address_1}, {address.city},{" "}
                      {address.zip_code}
                    </p>
                  </div>
                  <div className="flex-grow flex items-center justify-end gap-x-3 ml-4">
                    {address.is_primary ? (
                      <>
                        <p className="fs-14 primary">Enabled Address</p>
                        <div className="flex items-center justify-center h-8 w-8 bg-gray-100 rounded-full">
                          <MdOutlineEditLocationAlt
                            className="danger cursor-pointer"
                            size={15}
                            onClick={() => this.editAddress(address, index)}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <SwitchToggleTeams
                          companies={addresses}
                          company={address}
                          enabled={enabled}
                          enableCompany={() => this.setPrimaryAddress(address)}
                        />
                        <div>
                          <div className="w-24">
                            <div className="flex items-center justify-center gap-x-3">
                              <div className="flex items-center justify-center h-8 w-8 bg-gray-100 rounded-full">
                                <IoTrashOutline
                                  className="danger cursor-pointer"
                                  size={15}
                                  onClick={() => this.delAddress(address.id)}
                                />
                              </div>
                              <div className="flex items-center justify-center h-8 w-8 bg-gray-100 rounded-full">
                                <MdOutlineEditLocationAlt
                                  className="danger cursor-pointer"
                                  size={15}
                                  onClick={() =>
                                    this.editAddress(address, index)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))}

            {/* <Mutation mutation={}>


            </Mutation> */}

            {addresses && addresses.length >= maxbusinessAddress && !this.state.addAddress ? (
              <>
                <div className="divider my-5" />
                <div className="items-center flex justify-center">
                  <Alert severity="warning">
                    You can't add more addresses
                    {/* You can't add more than {maxbusinessAddress} addresses */}
                  </Alert>
                </div>
              </>
            ) : !this.state.addAddress ? (
              <>
                <div className="divider my-5" />
                <div className="items-center flex justify-center">
                  <Buttonlink
                    onClick={this.addAddressClick}
                    prefix={<FiPlus size={22} />}
                    text="Add New Address"
                    className="relative w-72 gap-x-2 hover:white hover:opacity-80  flex items-center justify-center py-2.5 px-4 border border-transparent rounded-lg primary bg--lightPrimary  hover:bg--primary focus:outline-none "
                  />
                </div>
              </>
            ) : (
              <>
                <div className="divider my-5" />
                <div className="items-center flex justify-center">
                  <Buttonlink
                    onClick={this.addAddressClick}
                    text="Back To Addresses"
                    className="relative w-72 gap-x-2 hover:white hover:opacity-80  flex items-center justify-center py-2.5 px-4 border border-transparent rounded-lg primary bg--lightPrimary  hover:bg--primary focus:outline-none "
                  />
                </div>
              </>
            )}

            <div className="divider my-5" />
            {this.state.addAddress && (
              <div className="bg-white p-10 rounded-xl">
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="space-y-4">
                  <div className="grid grid-cols-2 gap-4">
                    <SelectCountry
                      country_id={(value) => this.setState({ country_id: value })}
                      sortname={(v) => {
                        if (v !== undefined) {
                          this.setState({ countryName: v });
                          this.props.selectState({});
                        }
                      }}
                      seleced_country_object={country}
                    />
                    <div>
                      <label htmlFor="Country" className="Medium">
                        Country Code
                      </label>
                      <input
                        id="Country"
                        name="Country"
                        type="text"
                        placeholder="Sortname"
                        autoComplete="Country"
                        value={countryName}
                        onChange={(e) =>
                          this.setState({ countryName: e.target.value })
                        }
                        readOnly
                        contentEditable={false}
                        className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                      />
                    </div>
                    <div>
                      <label htmlFor="email-address" className="Medium">
                        Name
                      </label>
                      <input
                        id="last-name"
                        name="Name"
                        autoComplete="Last Name"
                        required
                        value={businessName}
                        onChange={(e) =>
                          this.setState({ businessName: e.target.value.replace(/[^a-zA-Z\s]/g, "") })
                        }
                        className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                        placeholder="Enter business name"
                      />
                    </div>
                    <div>
                      <label htmlFor="email-address" className="Medium">
                        Email
                      </label>
                      <input
                        id="email-address"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        value={email}
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                        maxLength={64}
                        placeholder="Enter business email address"
                        className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                      />
                    </div>
                    <div>
                      <label htmlFor="email-address" className="Medium">
                        Phone Number
                      </label>
                      <input
                        id="tel"
                        name="tel"
                        type="tel"
                        autoComplete="tel"
                        required
                        minLength={7}
                        maxLength={14}
                        value={phoneNum}
                        onChange={(e) =>
                          this.setState({
                            phoneNum: e.target.value.replace(/[^\d]/g, ""),
                          })
                        }
                        placeholder="Enter business phone number"
                        className="appearance-none rounded-md relative block w-full pr-20 px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                      />
                    </div>
                    <div className="col-span-2">
                      <label htmlFor="email-address" className="Medium">
                        Address Type
                      </label>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="Draft"
                        name="radio-buttons-group"
                        className="primary-all w-full space-x-5 flex important:flex-row"
                      >
                        <FormControlLabel
                          onChange={this.selectAddressType}
                          value={0}
                          control={<Radio />}
                          label={
                            <div>
                              <p className="black">Corporate</p>
                            </div>
                          }
                          checked={addressType == 0 ? true : false}
                        />
                        <FormControlLabel
                          onChange={this.selectAddressType}
                          value={1}
                          control={<Radio />}
                          label={
                            <div>
                              <p className="black">Registered</p>
                            </div>
                          }
                          checked={addressType == 1 ? true : false}
                        />
                      </RadioGroup>
                    </div>
                    <div>
                      <label htmlFor="Street" className="Medium">
                        Address 1
                      </label>
                      <input
                        id="Street"
                        name="Street"
                        type="text"
                        autoComplete="Street"
                        required
                        value={address_1}
                        onChange={(e) =>
                          this.setState({ address_1: e.target.value })
                        }
                        maxLength={200}
                        placeholder="Enter your primary address"
                        className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                      />
                    </div>
                    <div>
                      <label htmlFor="Street" className="Medium">
                        Address 2
                      </label>
                      <input
                        id="Street"
                        name="Street"
                        type="text"
                        autoComplete="Street"
                        required
                        value={address_2}
                        onChange={(e) =>
                          this.setState({ address_2: e.target.value })
                        }
                        maxLength={200}
                        placeholder="Enter your secondary address"
                        className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                      />
                    </div>
                    <div>
                      <label htmlFor="City" className="Medium">
                        City
                      </label>
                      <input
                        id="City"
                        name="City"
                        type="text"
                        autoComplete="City"
                        required
                        value={city}
                        onChange={(e) =>
                          this.setState({
                            city: e.target.value.replace(/[^a-z]/gi, ""),
                          })
                        }
                        maxLength={26}
                        placeholder="Enter your City"
                        className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                      />
                    </div>
                    <div>
                      <label htmlFor="Postal_Code" className="Medium">
                        Postal Code
                      </label>
                      <input
                        id="Postal_Code"
                        name="Postal Code"
                        type="text"
                        autoComplete="PostalCode"
                        required
                        value={postalCode}
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /\D/g,
                            ""
                          ); // Remove non-numeric characters
                          this.setState({ postalCode: numericValue });
                        }}
                        pattern="[0-9]*" // Only allow numbers
                        maxLength={6}
                        placeholder="Postal Code"
                        className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                      />
                    </div>
                    <div className="col-span-2">
                      <label htmlFor="Postal_Code" className="Medium">
                        State
                      </label>
                      <div className="shadow-sm">
                        <input
                          type="text"
                          readOnly
                          placeholder={
                            this.props._state && this.props._state.name
                              ? this.props._state.name
                              : "State"
                          }
                          className="appearance-none cursor-pointer rounded-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                          onClick={() => {
                            this.openStatesModal();
                            this.setState({ addState: true });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Dialog>
        <Fullscreenpopup
          ref={this.selectFullPopup}
          type={modalType}
          country_id={country_id}
          state_id={state_id}
          clearState={(value) => console.log("value", value)}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ Auth, Rfq, RFQs }) => {
  return {
    authUser: Auth.authUserInformation,
    companies: Auth.authUserInformation.companies,
    _state: Rfq._state,
    addresses: RFQs.addresses,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("store/redux/RFQRedux");
  return {
    ...ownProps,
    ...stateProps,

    selectState: (data) => {
      dispatch(actions.selectState(data));
    },
  };
};

export default connect(mapStateToProps, null, mergeProps, {
  forwardRef: true,
})(AddressDialog);
