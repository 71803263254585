import React, { Component } from "react";
import { Buttonlink } from "components";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import DELETE_OFFER from "../../graphql/mutations/deleteOfferMutation";
import { IoCloseCircleOutline } from "react-icons/io5";
import client from "graphql/client";
import PUBLISH_OR_UNPUBLISH from "../../graphql/mutations/offerUpdateStatusMutation";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class ConfirmationDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      rfqtype: "",
      rfqId: "",
      rfqVisibility: "",
      readable_status: "",
    };
  }

  openPopup = (rfqType, rfqId, rfqVisibility, readable_status) => {
    this.setState({ open: true });
    this.setState({ rfqtype: rfqType });
    this.setState({ rfqId: rfqId });
    this.setState({ rfqVisibility: rfqVisibility });
    this.setState({ readable_status: readable_status });
    // console.log(rfqType, rfqId, rfqVisibility);
  };

  notify = (message = "", type = "error") => {
    // Dismiss any existing toasts before showing a new one
    toast.dismiss();
    if (type === "error") {
      return toast.error(message, { position: "top-right" });
    }
    toast.success(message, { position: "top-right" });
  };

  deleterfq = () => {
    if (this.state.rfqtype === "delete") {
      // client
      //   .mutate({
      //     mutation: DELETE_OFFER,
      //     variables: {
      //       offer_id: this.state.rfqId,
      //     },
      //   })
      //   .then((response) => {
      //     // console.log("response", response);
      //     this.notify("RFQ Has Been Deleted Successfully", "success");

      //     setTimeout(() => {
      //       window.location.href = "/";
      //     }, 1000);
      //   })
      //   .catch((errors) => {
      //     console.log(errors);
      //   });
      client
        .mutate({
          mutation: DELETE_OFFER,
          variables: {
            offer_id: this.state.rfqId,
          },
          optimisticResponse: () => {
            return {
              deleteOffer: {
                id: this.state.rfqId,
                __typename: "Offer",
              },
            };
          },
          update: (cache, result) => {
            // console.log("result", result)
            const removeObject = cache.identify(result.data.deleteOffer);
            cache.modify({
              fields: {
                Offers: (existingObjectRefs) => {
                  // console.log("existingObjectRefs", existingObjectRefs);
                  return existingObjectRefs?.data?.filter((ObjectRef) => {
                    return cache.identify(ObjectRef) !== removeObject;
                  });
                },
              },
            });
            cache.evict({ id: removeObject });
          },
        })
        .then((response) => {
          this.setState({ rfqId: "" }, () => {
            this.props.navigate("/rfq/0");
            this.notify("Deleted Successfully", "success");
          });

          // setTimeout(() => {
          //   window.location.reload();
          // }, 500);
        })
        .catch((errors) => {
          console.log(errors);
          this.notify("OOPSS Some Error Occured", "error");
        });
      this.setState({ open: false });
    } else if (
      this.state.rfqtype === "published" ||
      this.state.rfqtype === "unpublished"
    ) {
      let status = "";
      if (this.state.readable_status == "Incomplete") {
        this.setState({ open: false });
        return this.notify(
          "RFQ is not complete please edit to publish",
          "error"
        );
      }
      if (this.state.readable_status == "Expired") {
        this.setState({ open: false });
        return this.notify("RFQ is expired please create now one", "error");
      }
      if (this.state.rfqVisibility == "0") {
        status = "1";
      } else {
        status = "0";
      }
      if (this.props.data.status !== 1 && this.state.rfqtype === "published") {
        this.closePopup();
        return this.notify("Required fields are not filled, please edit and complete first", "error");

      }
      client
        .mutate({
          mutation: PUBLISH_OR_UNPUBLISH,
          variables: {
            offer_id: this.state.rfqId,
            status: status,
          },
        })
        .then((response) => {
          // console.log("lool", response);
          this.props.callBack(response.data.offerUpdateStatus.visibility);

          if (this.state.rfqVisibility == "1") {
            this.notify("RFQ has been unpublished successfully", "success");
          }

          if (this.state.rfqVisibility == "0") {
            this.notify("RFQ has been published successfully", "success");
          }
          //   setTimeout(() => {
          //     window.location.href = "/";
          //   }, 500);
        })
        .catch((errors) => {
          if (this.state.rfqVisibility == "1") {
            this.notify(
              "Once Participant Joined RFQ Cannot Unpublished",
              "error"
            );
          }

          if (this.state.rfqVisibility == "0") {
            this.notify("Error in publishing RFQ", "error");
          }

          console.log(errors);
        });
      this.setState({ open: false });
    }
    // console.log(this.state.rfqType, this.state.rfqId, this.state.rfqVisibility);
  };

  closePopup = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    return (
      <Dialog
        fullWidth={false}
        maxWidth={"sm"}
        open={open}
        onClose={this.closePopup}
        className="rounded--xl"
      >
        <div className="p-10 mt-5 relative">
          <div className="absolute right-3 -top-2 bg-white">
            <IconButton
              edge="start"
              color="inherit"
              onClick={this.closePopup}
              aria-label="close"
            >
              <IoCloseCircleOutline size={32} className="primary" />
            </IconButton>
          </div>
          <h2 className="fs-28 text-center Medium">
            {this.state.readable_status == "Expired" ||
              this.state.readable_status == "Incomplete"
              ? "Your deadline has already passed. Need to create new RFQ."
              : this.state.rfqtype == "unfilledForm"
                ? "Please fill the required fields."
                : "Are you sure to perform this action"}
          </h2>
          {this.state.rfqtype == "expired" ||
            this.state.rfqtype == "unfilledForm" ? (
            false
          ) : (
            <div className="flex items-center justify-center gap-x-3 mt-6">
              <Buttonlink
                onClick={this.deleterfq}
                text="Yes"
                title="Yes"
                className="w-32 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:bg--primary hover:opacity-70 bg--primary white hover:white gap-x-3 flex items-center justify-center"
              />
              <Buttonlink
                onClick={this.closePopup}
                text="Cancel"
                title="Cancel"
                className="w-32 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover: bg--lightGray hover:opacity-70 hover:black gap-x-3 flex items-center justify-center bg--lightGray"
              />
            </div>
          )}
        </div>
      </Dialog>
    );
  }
}

export default ConfirmationDialog;
