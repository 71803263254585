import React, { useState } from "react";
import { Tab } from "@headlessui/react";
import SpendAnalytics from "./SpendAnalytics";
import RecentPayment from "./RecentPayment";
import RecentPurchase from "./RecentPurchase";
import PriceRangeSlider from "../Orders/PriceRangeSlider";
import OrderDateRange from "../Orders/OrderDateRange";

const WorkSpaceData = ({ dateRange, my_price,copiedSearch }) => {
    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }

    const [selectedIndex, setSelectedIndex] = useState(0);
    return (
        <>
            <Tab.Group
                selectedIndex={selectedIndex}
                onChange={setSelectedIndex}
            >
                <div className="flex">
                    <div className="w-1/4 bg--lightPrimary">
                        <Tab.List className={"flex flex-col gap-5 py-10 h-max"}>
                            <Tab
                                className={({ selected }) =>
                                    classNames(
                                        "w-full lg:py-2.5 py-4 fs-15 leading-5 Regular hover:bg--primary hover:white",
                                        selected ? "bg--primary white" : ""
                                    )
                                }
                            >
                                Analytics By Categories
                            </Tab>
                            <Tab
                                className={({ selected }) =>
                                    classNames(
                                        "w-full lg:py-2.5 py-4 fs-15 leading-5 Regular hover:bg--primary hover:white",
                                        selected ? "bg--primary white" : ""
                                    )
                                }
                            >
                                Analytics By Supplier
                            </Tab>
                        </Tab.List>
                        {/*{selectedIndex === 0 ? (
                            <div className="pt-12">
                                <h6 className="text-center font-bold">
                                    Total Gross
                                </h6>
                                <div className="grid gap-x-2 gap-y-6 grid-cols-2 pt-8">
                                    <div className="Medium text-md text-center">
                                        <p>$ 123</p>
                                        <p className="Regular font-semibold text-sm">
                                            Total Spend
                                        </p>
                                    </div>
                                    <div className="Medium text-md text-center">
                                        <p>$ 123</p>
                                        <p className="Regular font-semibold text-sm">
                                            Total Transactions
                                        </p>
                                    </div>
                                    <div className="Medium text-md text-center">
                                        <p>$ 123</p>
                                        <p className="Regular font-semibold text-sm">
                                            Total Saving
                                        </p>
                                    </div>
                                    <div className="Medium text-md text-center">
                                        <p>$ 123</p>
                                        <p className="Regular font-semibold text-sm">
                                            Total Amount Owned
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : selectedIndex === 1 ? (
                            <>
                                <div className="flex relative items-start w-3/4 mx-auto">
                                    <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                                        <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                                    </div>
                                    <div className="flex-shrink-0 w-6 h-6 rounded-full inline-flex items-center justify-center bg--primary text-white relative z-10 font-medium text-sm">
                                        1
                                    </div>
                                    <div className="flex-grow flex sm:items-center items-start flex-col sm:flex-row">
                                        <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                                            <h2 className="font-medium Medium text-gray-900 mb-1 text-md">
                                                Item description 1
                                            </h2>
                                            <p className="leading-relaxed truncate w-48 text-sm">
                                                VHS cornhole pop-up, try-hard
                                                8-bit iceland helvetica. Kinfolk
                                                bespoke try-hard cliche palo
                                                santo offal.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex relative items-start w-3/4 mx-auto py-8">
                                    <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                                        <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                                    </div>
                                    <div className="flex-shrink-0 w-6 h-6 rounded-full inline-flex items-center justify-center bg--primary text-white relative z-10 font-medium text-sm">
                                        2
                                    </div>
                                    <div className="flex-grow flex sm:items-center items-start flex-col sm:flex-row">
                                        <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                                            <h2 className="font-medium Medium text-gray-900 mb-1 text-md">
                                                Item description 1
                                            </h2>
                                            <p className="leading-relaxed truncate w-48 text-sm">
                                                VHS cornhole pop-up, try-hard
                                                8-bit iceland helvetica. Kinfolk
                                                bespoke try-hard cliche palo
                                                santo offal.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex relative items-start w-3/4 mx-auto">
                                    <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                                        <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                                    </div>
                                    <div className="flex-shrink-0 w-6 h-6 rounded-full inline-flex items-center justify-center bg--primary text-white relative z-10 font-medium text-sm">
                                        2
                                    </div>
                                    <div className="flex-grow flex sm:items-center items-start flex-col sm:flex-row">
                                        <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                                            <h2 className="font-medium Medium text-gray-900 mb-1 text-md">
                                                Item description 1
                                            </h2>
                                            <p className="leading-relaxed truncate w-48 text-sm">
                                                VHS cornhole pop-up, try-hard
                                                8-bit iceland helvetica. Kinfolk
                                                bespoke try-hard cliche palo
                                                santo offal.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : selectedIndex === 2 ? (
                            <>
                                <div className="flex relative items-start w-3/4 mx-auto">
                                    <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                                        <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                                    </div>
                                    <div className="flex-shrink-0 w-6 h-6 rounded-full inline-flex items-center justify-center bg--primary text-white relative z-10 font-medium text-sm">
                                        1
                                    </div>
                                    <div className="flex-grow flex sm:items-center items-start flex-col sm:flex-row">
                                        <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                                            <h2 className="font-medium Medium text-gray-900 mb-1 text-md">
                                                Item description 1
                                            </h2>
                                            <p className="leading-relaxed truncate w-48 text-sm">
                                                VHS cornhole pop-up, try-hard
                                                8-bit iceland helvetica. Kinfolk
                                                bespoke try-hard cliche palo
                                                santo offal.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex relative items-start w-3/4 mx-auto py-8">
                                    <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                                        <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                                    </div>
                                    <div className="flex-shrink-0 w-6 h-6 rounded-full inline-flex items-center justify-center bg--primary text-white relative z-10 font-medium text-sm">
                                        2
                                    </div>
                                    <div className="flex-grow flex sm:items-center items-start flex-col sm:flex-row">
                                        <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                                            <h2 className="font-medium Medium text-gray-900 mb-1 text-md">
                                                Item description 1
                                            </h2>
                                            <p className="leading-relaxed truncate w-48 text-sm">
                                                VHS cornhole pop-up, try-hard
                                                8-bit iceland helvetica. Kinfolk
                                                bespoke try-hard cliche palo
                                                santo offal.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex relative items-start w-3/4 mx-auto">
                                    <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                                        <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                                    </div>
                                    <div className="flex-shrink-0 w-6 h-6 rounded-full inline-flex items-center justify-center bg--primary text-white relative z-10 font-medium text-sm">
                                        2
                                    </div>
                                    <div className="flex-grow flex sm:items-center items-start flex-col sm:flex-row">
                                        <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                                            <h2 className="font-medium Medium text-gray-900 mb-1 text-md">
                                                Item description 1
                                            </h2>
                                            <p className="leading-relaxed truncate w-48 text-sm">
                                                VHS cornhole pop-up, try-hard
                                                8-bit iceland helvetica. Kinfolk
                                                bespoke try-hard cliche palo
                                                santo offal.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            ""
                        )}*/}
                    </div>
                    <div className="w-3/4">
                        <Tab.Panels>
                            <Tab.Panel className="bg-transparent rounded-xl">
                                <SpendAnalytics
                                    classNames={classNames}
                                    queryType={"categories"}
                                    dateRange={dateRange}
                                    my_price={my_price}
                                    copiedSearch={copiedSearch}
                                />
                            </Tab.Panel>
                            <Tab.Panel className="bg-transparent rounded-xl">
                                <SpendAnalytics
                                    classNames={classNames}
                                    queryType={"suppliers"}
                                    dateRange={dateRange}
                                    my_price={my_price}
                                    copiedSearch={copiedSearch}
                                />
                                {/*<RecentPurchase />*/}
                            </Tab.Panel>
                        </Tab.Panels>
                    </div>
                </div>
            </Tab.Group>
        </>
    );
};

export default WorkSpaceData;
