import { gql } from "@apollo/client";

export default gql`
  mutation changeRFQType($status: Boolean!) {
    changeRFQType(status: $status) {
      id
      username
      file_type
    }
    __typename
  }
`;
