import React, { useState } from "react";
import OrderModal from "./OrderModal";

const OrdersPayment = (data) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal(id) {
    setSelectedId(id);
    setIsOpen(true);
  }

  const paymentStatus = [
    {
      id: 8,
      label: "Order waiting to pay by client to aggregate",
    },
    {
      id: 9,
      label: "Order paid by client to aggregate",
    },
    {
      id: 10,
      label: "Order paid to vendor by aggregate",
    },
    {
      id: 11,
      label: "Orders waiting to be paid by the aggregate",
    },
  ];
  return (
    <div className="p-5 bg-white ml-3">
      <h6 className="Medium fs-18 font-semibold pb-6">Order Deliveries</h6>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-10">
        {paymentStatus.map((status) => (
          <button
            key={status.id}
            onClick={() => openModal(status.id)}
            className="flex gap-4 flex-col items-center justify-center bg--lightPrimary py-3"
          >
            <h6 className="Medium fs-20 font-medium">{data.data[status.id].length}</h6>
            <p className="Regular fs-14 text-center">{status.label}</p>
          </button>
        ))}
      </div>

      <OrderModal
          isOpen={isOpen}
          closeModal={closeModal}
          data={data.data[selectedId]}
      />
    </div>
  );
};

export default OrdersPayment;
