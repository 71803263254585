import React from "react";
import { AiFillCaretDown } from "react-icons/ai";

function Tooltips(props) {
  const { trigger, toolcontent } = props;
  return (
    <>
      <div className="ml-auto">
        <div className="relative flex flex-col items-center group ">
          {trigger}
          <div className="absolute bottom-0 flex-col items-center hidden mb-4 group-hover:flex ">
            <span className="relative z-10 px-5 py-2 w-max rounded-lg fs-13 Light leading-none text-white whitespace-no-wrap overlay:bg--gray-800 shadow-lg">
              {toolcontent}
            </span>
            <AiFillCaretDown size={18} className="-mt-1.5 text--gray-800" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Tooltips;
