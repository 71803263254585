import React, { useEffect, useState } from "react";
import { Avatarimage, Buttonlink, TeamsDialog } from "components";
import SwitchToggleTeams from "../../../components/listings/rfqcard/SwitchToggleTeams";
import { connect } from "react-redux";
import client from "graphql/client";
import setPrimaryCompany from "graphql/mutations/setPrimaryCompany";
import myCompany from "graphql/queries/myCompany";
import { IoTrashOutline } from "react-icons/io5";
import { FiEdit, FiPlus } from "react-icons/fi";
import DEL_COM from "../../../graphql/mutations/removeUserCompanyMutation";
import GET_COMPANY from "../../../graphql/queries/getCompany";
import Profileavatar from "../../../components/profileavatar";
import { checkPlan } from "helpers/checkPlan";

const DeleteConfirmation = ({ onDelete }) => {
  const [open, setOpen] = useState(false);



  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    onDelete();
    handleClose();
  };

  return (
    <div>
      <div className="bg-gray-100 w-8 h-8 flex items-center justify-center rounded-full">
        <IoTrashOutline
          className="danger cursor-pointer"
          size={15}
          onClick={handleOpen}
        />
      </div>
      {open && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-md">
            <p>
              Your Team credit will lose, and it will also remove
              related RFQs.
            </p>
            <div className="flex justify-end mt-4 gap-3">
              <Buttonlink
                onClick={handleDelete}
                className="relative w-26 hover:white hover:opacity-80 flex justify-center border border-transparent rounded-lg primary bg--lightPrimary  hover:bg--primary focus:outline-none p-1 "
                text={"Delete"}
              />
              <Buttonlink
                onClick={handleClose}
                className="relative w-26 hover:white hover:opacity-80 flex justify-center border border-transparent rounded-lg primary bg--lightPrimary  hover:bg--primary focus:outline-none p-1 "
                text={"Cancel"}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function Myteams(props) {
  const [enabled, setEnabled] = useState(null);
  const [companiesdata, setCompaniesdata] = useState([]);
  const [editCompany, setEditCompany] = useState(false);
  const [Company, setCompany] = useState([]);
  const [executives, setExecutives] = useState(props.executives ?? []);
  const [loader, setLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [executiveLength, setExecutiveLength] = useState(-1);
  const AddTeamsPopup = React.createRef();

  useEffect(() => {
    //console.log('PROPSSS', props);
    if (!props.edit) {
      getCompaniesList();
    }
    else {
      setEnabled(props.company);
      setCompaniesdata(props.company)
    }
  }, []);

  function getCompaniesList() {
    if (!props.edit) {
      setLoader(true);
      client
        .query({
          query: myCompany,
          fetchPolicy: "network-only",
        })
        .then((response) => {
          // console.log('first111', response, props);
          let company_created_primary =
            response?.data?.me?.companies?.data?.filter((i) => i.is_primary);
          let company = response?.data?.me?.companies?.data;
          if (company.length > 0) {
            if (company_created_primary.length > 0) {
              setEnabled(company_created_primary[0]);
              //setExecutives(company_created_primary[0]?.executives);
              //console.log('MMMMMM', enabled);
              if (props?.editable) {
                props.loader(true);

              }
              if (!props?.edit) {

                addForm("company", company_created_primary[0]?.id);
                setExecutiveLength(company_created_primary[0]?.executives?.length);
                props.companyLoader(company_created_primary[0]?.executives?.length, false);
              }

              //getCompaniesRole(company_created_primary[0].id);
            }
            setCompaniesdata(response?.data?.me?.companies?.data);
          }
          setLoader(false);
        })
        .catch((err) => { });
    }

  }

  const addForm = (key, value) => {
    let form = Object.assign({}, props.form);
    form[key] = value;
    props.addRfqForm(form);
  };

  const setEnabledFunction = (company) => {
    props.companyLoader(-1, true);
    setEnabled(company);
    client
      .mutate({
        mutation: setPrimaryCompany,
        variables: {
          id: company.id,
        },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        props.load_again("switch");
        //this.addForm("company", company.id);
        if (!props.edit) {
          getCompaniesList()
          getCompaniesRole(company.id);
        }

      })
      .catch((err) => { });
  };

  const delCompany = (id) => {
    setIsOpen(true);
    let ids = companiesdata.filter((o) => o.id !== id);
    // console.log(ids);
    setCompaniesdata(ids);

    client
      .mutate({
        mutation: DEL_COM,
        variables: {
          company_id: parseInt(id),
        },
        fetchPolicy: "network-only",
      })
      .then((res) => {
        if (res.data.removeUserCompany.status) {
          props.load_again("delete");
          getCompaniesList();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getCompaniesRole = (id) => {
    setLoader(true);
    if (id) {
      client
        .query({
          query: GET_COMPANY,
          variables: {
            id: id,
            offer_id: props.offer_id ? props.offer_id : props.fields?.offer,
          },
          fetchPolicy: "network-only",
        })
        .then((response) => {
          setExecutives(response.data?.company?.executives);
          setEnabled(response.data.company);
          setEditCompany(response.data.company?.executives);
          setLoader(false);
          setExecutiveLength(response.data.company?.executives?.length);
          props.companyLoader(response.data.company?.executives?.length, false);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }

  const EditCompany = (company) => {
    setCompany(company);
    //setExecutives(company);
    setEditCompany(true);
    // if (props.from === "post-rfq" && editCompany) {
    //   AddTeamsPopup.current.openPopup(["Team", editCompany]);
    // }
    //3rd parameter telling whether to its Editing or not
    AddTeamsPopup.current.openPopup(["Team", true, true]);
  };
  const truncate = (input) =>
    input?.length > 20 ? `${input.substring(0, 15)}...` : input;
  const canAssignRole = checkPlan("bool", "Assigned Role to team", props.user?.payments);
  const teamsAllowed = checkPlan("sum", "No. of Teams", props.user?.payments);
  // console.log('teamsAllowed', teamsAllowed);
  return (
    <>
      <TeamsDialog
        //key={JSON.stringify(Company.id)}
        ref={AddTeamsPopup}
        companyCreated={(e) => getCompaniesList()}
        editCompany={editCompany}
        company={Company}
        loader={loader}
        //executives={props.executives ?? []}
        executives={executives ?? []}
        offer_id={props?.offer_id}
        from="post-rfq"
        clearCompany={(v) => {
          props.showTagsModal ? props.getAlert(v) : setCompany([]);
          setEditCompany(false);
        }}
        offerHasRoles={(v) => {
          props.offerHasRoles(v)
          getCompaniesRole(props.company?.id ?? props.form.company)
        }}
      />

      {props?.edit ?
        <>
          <div
            className="flex items-center space-x-3 intro-y"
          >
            <Avatarimage
              imagesrc={process.env.REACT_APP_MAIN_URL + `/${companiesdata?.logo}`}
              firstname={companiesdata?.title}
              iscompany="true"
              imagesize={{ width: 52, height: 52 }}
            />
            <div>
              <p className="Medium">{truncate(companiesdata.title)}</p>
            </div>
          </div>
        </>

        : !props?.showTagsModal && !props.nextClicked ? companiesdata?.map((items) => (
          <div className="flex items-center space-x-3 intro-y" key={items.id}>
            <Avatarimage
              imagesrc={process.env.REACT_APP_MAIN_URL + `/${items.logo}`}
              firstname={items.title}
              iscompany="true"
              imagesize={{ width: 52, height: 52 }}
            />
            <div>
              <p className="Medium">{truncate(items.title)}</p>
            </div>
            <div className="flex-grow flex items-end flex-col justify-center">
              {items.is_primary ? (
                <p className="fs-11 primary pr-2">Primary</p>
              ) : (
                ""
              )}
              {companiesdata?.length > 1 && items.is_primary ? (
                <SwitchToggleTeams
                  companies={companiesdata}
                  company={items}
                  enabled={enabled}
                  enableCompany={setEnabledFunction}
                />
              ) : !items.is_primary && companiesdata.length > 1 ? (
                <SwitchToggleTeams
                  companies={companiesdata}
                  company={items}
                  enabled={enabled}
                  enableCompany={setEnabledFunction}
                />
              ) : (
                <div className="bg-gray-100 w-8 h-8 flex items-center justify-center rounded-full">
                  <FiEdit
                    className="danger cursor-pointer"
                    size={15}
                    onClick={() => EditCompany(items)}
                  />
                </div>
              )}
            </div>
            <div>
              {enabled !== null && enabled.id !== items.id && (
                <div className="bg-gray-100 w-8 h-8 flex items-center justify-center rounded-full">
                  <DeleteConfirmation onDelete={() => {
                    delCompany(items.id)
                  }} />

                  {/*<IoTrashOutline*/}
                  {/*    className="danger cursor-pointer"*/}
                  {/*    size={15}*/}
                  {/*    onClick={() => delCompany(items.id)}*/}
                  {/*/>*/}
                </div>
              )}
            </div>
            <div>
              {enabled !== null && enabled.id !== items.id && (
                <div className="bg-gray-100 w-8 h-8 flex items-center justify-center rounded-full">
                  <FiEdit
                    className="danger cursor-pointer"
                    size={15}
                    onClick={() => EditCompany(items)}
                  />
                </div>
              )}
            </div>
          </div>
        )) : companiesdata
          .filter((o) => o.is_primary)
          .map((items) => (
            <div
              className="flex items-center space-x-3 intro-y"
              key={items.id}
            >
              <Avatarimage
                imagesrc={process.env.REACT_APP_MAIN_URL + `/${items.logo}`}
                firstname={items.title}
                iscompany="true"
                imagesize={{ width: 52, height: 52 }}
              />
              <div>
                <p className="Medium">{truncate(items.title)}</p>
              </div>
              <div>
                {enabled !== null && enabled.id !== items.id && (
                  <div className="bg-gray-100 w-8 h-8 flex items-center justify-center rounded-full">
                    <FiEdit
                      className="danger cursor-pointer"
                      size={15}
                      onClick={() => EditCompany(items)}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
      {props?.from === "post-rfq" &&
        // companiesdata.length < teamsAllowed &&
        teamsAllowed > 0 &&
        !props.showTagsModal && !props.edit && !props.nextClicked && (
          <Buttonlink
            onClick={() => AddTeamsPopup.current.openPopup(["Team", true, false])}
            className={`bg-gray-100 intro-y ${props.companyErr ? "border-red-500 border" : ""
              } hover:bg-gray-200 w-full primary flex items-center justify-center py-3 rounded-xl`}
            text="Add Team"
            prefix={<FiPlus size={22} />}
          />
        )}
      {props?.from === "post-rfq" &&
        props?.menu?.id !== "6" &&
        props?.menu?.id !== "7" &&
        props.showTagsModal && canAssignRole && executiveLength > 1 && (
          <Buttonlink
            onClick={() => {
              AddTeamsPopup.current.openPopup(["Team work", true, 1]);
              setCompany(enabled);
            }}
            className="bg-gray-100 intro-y hover:bg-gray-200 w-full primary flex items-center justify-center py-3 rounded-xl"
            text="Add Team Roles"
            prefix={<FiPlus size={22} />}
          />
        )}
      {enabled && props.showTagsModal && executives.length > 1 && props.menu?.id !== "7" && (
        <div>
          <h3 className="text-xl Medium mb-1">Selected Company Members :</h3>
          <div className="space-y-4">
            {executives?.map((user, index) => {

              return (
                <div className="flex items-center gap-x-3">
                  <div>
                    <Profileavatar
                      singleUser={user}
                      sizeStyle={{ width: 42, height: 42 }}
                    />
                  </div>
                  <p className="primary hover:primary fs-14 block hover:opacity-80">
                    {user?.firstname} {user?.lastname}
                  </p>
                  <div className="ml-auto">
                    <p className="hover:bg--gray-200 fs-14 right hover:opacity-80">
                      {user?.userCompanyOfferRole
                        ? user.userCompanyOfferRole.toUpperCase()
                        : (user?.offer_executive_user_roles && user.offer_executive_user_roles.length > 0)
                          ? user.offer_executive_user_roles[0]?.name.toUpperCase()
                          : "none"}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}
const mapStateToProps = ({ Rfq, Auth }) => {
  return {
    form: Rfq.form,
    fields: Rfq.fields,
    user: Auth.authUserInformation,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("store/redux/RFQRedux");
  return {
    ...ownProps,
    ...stateProps,
    addRfqForm: (data) => {
      dispatch(actions.addRfqForm(data));
    },
  };
};

export default connect(mapStateToProps, undefined, mergeProps)(Myteams);
