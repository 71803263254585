import cookie from "js-cookie";
import { GRAPH_MRO_USER_AUTH_TOKEN } from "./config/constants";

export default class Auth {
  isAuthenticated = () => {
    let token = cookie.get(GRAPH_MRO_USER_AUTH_TOKEN);
    let auth = false;
    if (token) {
      auth = true;
    }
    return auth;
  };
}
