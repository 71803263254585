import { gql } from "@apollo/client";

export default gql`
mutation offerParticipantAcceptAndReject(
    $offer_id:Int
    $status:String
    $product_id:Int
    $reason:String
  ) {
    offerParticipantAcceptAndReject(
        offer_id: $offer_id
        status:$status
        product_id:$product_id
        reason:$reason
      ) {
        id
      }
    }
`;
