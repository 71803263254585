import { gql } from "@apollo/client";

export default gql`
  query Menu{
    menu {
        id
         name
         code
         parent
         reference_number
         fields {
          id
          name
          code
          placeholder
          type
        }
     }
  }
`;

